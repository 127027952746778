import { ItemCombinationService } from 'src/app/services/item-combination.service';
import { ItemCombinationRecallAlertService } from 'src/app/services/item-combination-recall-alert.service';
import { ItemCombinationRecallAlertExceptionService } from 'src/app/services/item-combination-recall-alert-exception.service';
import { of, zip } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./item-combination.service";
import * as i2 from "./item-combination-recall-alert.service";
import * as i3 from "./item-combination-recall-alert-exception.service";
var RecallOracleService = /** @class */ (function () {
    function RecallOracleService(_itemCombinationService, _itemCombinationRecallAlertService, _itemCombinationRecallAlertExceptionService) {
        this._itemCombinationService = _itemCombinationService;
        this._itemCombinationRecallAlertService = _itemCombinationRecallAlertService;
        this._itemCombinationRecallAlertExceptionService = _itemCombinationRecallAlertExceptionService;
        this.cache = {};
        this.itemCombinationRecallAlertActiveStatus = 0;
        this.itemCombinationRecallAlertClosedStatus = 1;
    }
    RecallOracleService.prototype.id = function (itemId, lot, serial) {
        return (itemId.toString() + "_" + lot.toString() + "_" + serial.toString()).toLowerCase();
    };
    RecallOracleService.prototype.isItemCombinationRecalled = function (itemCombination) {
        var that = this;
        var itemCombinationRecallAlertMatches = that.itemCombinationRecallAlerts.filter(function (icra) {
            return icra.item_combination_id == itemCombination.id;
        });
        var itemCombinationRecallAlertExceptionMatches = itemCombinationRecallAlertMatches.map(function (icra) {
            return that.itemCombinationRecallAlertExceptions.find(function (icrae) {
                return icrae.item_combination_recall_alert_id == icra.id;
            });
        }).filter(function (n) { return n; });
        return !!itemCombinationRecallAlertMatches.find(function (icra) {
            var isActive = [that.itemCombinationRecallAlertActiveStatus, that.itemCombinationRecallAlertClosedStatus].indexOf(icra.status_id) != -1;
            var hasException = !!itemCombinationRecallAlertExceptionMatches.find(function (icrae) {
                return icrae.item_combination_recall_alert_id == icra.id;
            });
            return isActive && !hasException;
        });
    };
    RecallOracleService.prototype.getItemCombinations = function (alreadyCached) {
        if (alreadyCached === void 0) { alreadyCached = null; }
        if (alreadyCached) {
            return of(alreadyCached);
        }
        return this._itemCombinationService.get();
    };
    RecallOracleService.prototype.getItemCombinationRecallAlerts = function (alreadyCached) {
        if (alreadyCached === void 0) { alreadyCached = null; }
        if (alreadyCached) {
            return of(alreadyCached);
        }
        return this._itemCombinationRecallAlertService.get();
    };
    RecallOracleService.prototype.getItemCombinationRecallAlertExceptions = function (auditId, alreadyCached) {
        if (alreadyCached === void 0) { alreadyCached = null; }
        if (alreadyCached) {
            return of(alreadyCached);
        }
        return this._itemCombinationRecallAlertExceptionService.get(auditId);
    };
    RecallOracleService.prototype.prime = function (auditId, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var that = this;
        that.cache = {};
        return new Promise(function (resolve, reject) {
            zip(that.getItemCombinations(options.itemCombinations), that.getItemCombinationRecallAlerts(options.itemCombinationRecallAlerts), that.getItemCombinationRecallAlertExceptions(auditId, options.itemCombinationRecallAlertExceptions)).subscribe(function (data) {
                //faster if we only keep item combination recall alerts which are active or closed, filter to just those for performance
                that.itemCombinationRecallAlerts = data[1].filter(function (icra) {
                    return [that.itemCombinationRecallAlertActiveStatus, that.itemCombinationRecallAlertClosedStatus].indexOf(icra.status_id) != -1;
                });
                //faster searching if the array is smaller, recalled oracle doesnt care about item combinations which we know arent recalled
                var itemCombinationsToKeep = _this.itemCombinationRecallAlerts.map(function (icra) {
                    return icra.item_combination_id;
                });
                that.itemCombinations = data[0].filter(function (cs) {
                    return itemCombinationsToKeep.indexOf(cs.id) != -1;
                });
                that.itemCombinationRecallAlertExceptions = data[2];
                resolve(true);
            });
        });
    };
    RecallOracleService.prototype._get = function (itemId, lot, serial) {
        var _this = this;
        var null_lot_and_serial_item_combination = this.itemCombinations.find(function (ic) {
            return ic.item_id == itemId && ic.lot_number.value == null && ic.serial_number.value == null;
        });
        if (null_lot_and_serial_item_combination && this.isItemCombinationRecalled(null_lot_and_serial_item_combination)) {
            return true;
        }
        var null_serial_item_combination = this.itemCombinations.find(function (ic) {
            return ic.item_id == itemId && ic.lot_number.value.toString().toLowerCase() == lot.toString().toLowerCase() && ic.serial_number.value == null;
        });
        if (null_serial_item_combination && this.isItemCombinationRecalled(null_serial_item_combination)) {
            return true;
        }
        if (itemId && lot && serial) {
            var item_combination = this.itemCombinations.find(function (ic) {
                return ic.item_id == itemId && ic.lot_number.value.toString().toLowerCase() == lot.toString().toLowerCase() && ic.serial_number.value.toString().toLowerCase() == serial.toString().toLowerCase();
            });
            if (item_combination && this.isItemCombinationRecalled(item_combination)) {
                return true;
            }
        }
        else if (itemId && lot) {
            var lotLevelItemCombinationMatches = this.itemCombinations.filter(function (ic) {
                return ic.item_id == itemId && ic.lot_number.value.toString().toLowerCase() == lot.toString().toLowerCase();
            });
            return !!lotLevelItemCombinationMatches.find(function (ic) {
                return _this.isItemCombinationRecalled(ic);
            });
        }
        else {
            var itemLevelItemCombinationMatches = this.itemCombinations.filter(function (ic) {
                return ic.item_id == itemId;
            });
            return !!itemLevelItemCombinationMatches.find(function (ic) {
                return _this.isItemCombinationRecalled(ic);
            });
        }
        return false;
    };
    RecallOracleService.prototype.get = function (itemId, lot, serial) {
        lot = lot || '';
        serial = serial || '';
        var key = this.id(itemId, lot, serial);
        if (this.cache[key] != undefined) {
            return this.cache[key];
        }
        this.cache[key] = this._get(itemId, lot, serial);
        return this.cache[key];
    };
    RecallOracleService.ngInjectableDef = i0.defineInjectable({ factory: function RecallOracleService_Factory() { return new RecallOracleService(i0.inject(i1.ItemCombinationService), i0.inject(i2.ItemCombinationRecallAlertService), i0.inject(i3.ItemCombinationRecallAlertExceptionService)); }, token: RecallOracleService, providedIn: "root" });
    return RecallOracleService;
}());
export { RecallOracleService };
