import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { IAuditLocation } from '../interfaces/iaudit-location';
import { urls } from './../helpers/urls';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuditLocationService {

  constructor(private http: HttpClient) { }

  getLocation(auditId: number, auditLocationId: number) {
    return this.getLocations(auditId.toString()).pipe(map(function (data) {
      return data.find((l: any) => l.id === auditLocationId);
    }));
  }

  getLocations(auditId: string) {
    return this.http.get<any>(`${environment.serverPath}${urls.auditLocations}?audit_id=${auditId}`, {});
  }

  createLocation(url: string, newAuditLocation: IAuditLocation) {
    const formData = new FormData();
    formData.append('location', JSON.stringify(newAuditLocation));
    return this.http.post<any>(environment.serverPath + url, formData);
  }

  updateLocation(url: string, location: IAuditLocation) {
    const formData = new FormData();
    formData.append('location', JSON.stringify(location));
    return this.http.put<any>(environment.serverPath + url, formData);
  }

  deleteLocation(url: string, id: number) {
    const params = new HttpParams().set('id', id.toString());
    return this.http.delete<any>(environment.serverPath + url, { params });
  }

  getWarehousesForSite(id: number | string) {
    const path = environment.serverPath + urls.getWarehousesForSite;
    const params = new HttpParams().set('site_id', id.toString());
    return this.http.get<any>(path, { params });
  }

  removeWarehouseFromSite(siteWarehouseId, site_id) {
    const formData = new FormData();
    formData.append('id', JSON.stringify(siteWarehouseId));
    formData.append('site_id', JSON.stringify(site_id));
    return this.http.post<any>(environment.serverPath + urls.removeWarehouseFromSite, formData);
  }

  finishSite(site_id, warehouses) {
    const formData = new FormData();
    formData.append('site_id', JSON.stringify(site_id));
    formData.append('warehouses', JSON.stringify(warehouses));
    return this.http.post<any>(environment.serverPath + urls.finishSite, formData);
  }
}
