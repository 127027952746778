import { ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { ICountSheetData } from 'src/app/interfaces/icount-sheet-data';
import { IDialogData } from 'src/app/interfaces/idialog-data';
import { FileSystemService } from 'src/app/services/file-system.service';
import { CountSheetAttachmentService } from 'src/app/services/count-sheet-attachment.service';
import { FileOptionUtils } from 'src/app/helpers/file-option-utils';
import { environment } from 'src/environments/environment';
import { AttachmentFileListComponent } from '../attachment-file-list/attachment-file-list.component';
import { OnlineService } from 'src/app/services/online.service';
import { Subscription } from 'rxjs';
import { IAttachment } from 'src/app/interfaces/i-attachment';
import { DomSanitizer } from '@angular/platform-browser';
import { TrackLoadingService } from 'src/app/services/track-loading.service';
import { MaterialIcons } from 'src/app/helpers/material-icons';
import { FileDatabaseService } from 'src/app/services/file-database.service';
import { AttachmentDatabaseService } from 'src/app/services/attachment-database.service';
import * as moment from 'moment';
import { FileCacheService } from 'src/app/services/file-cache.service';
import { CameraService } from 'src/app/services/camera.service';
import { IDeviceInfo } from 'src/app/interfaces/i-device-info';
import { FeatureSupportService } from 'src/app/services/feature-support.service';
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';
import { ISnackBarConfig } from 'src/app/interfaces/isnack-bar-config';
import { Messages } from 'src/app/helpers/messages';
import { ErrorMessages } from 'src/app/helpers/error-messages';

@Component({
  selector: 'app-attachment-dialog',
  templateUrl: './attachment-dialog.component.html',
  styleUrls: ['./attachment-dialog.component.css']
})
export class AttachmentDialogComponent implements OnInit, OnDestroy {
  attachments: IAttachment[] = [];
  cameraDevices: IDeviceInfo[] = []
  selectedCamera: IDeviceInfo;
  showFileList = false;
  showFileView = false;
  showSingleFileAttacher = true;
  countSheet: ICountSheetData;
  imageUrl: any;
  pdfUrl: any;
  currentFile: IAttachment;
  currentlySelectedFileName: string;
  noLocalHandle = false;
  fileNotViewable = false;
  currentFileHasLocalHandle = false;
  cameraEnabled: boolean = false;
  canTakePicture: boolean = false;
  camerasAvailable: boolean = false
  online: boolean;
  takenImageFile: any;
  zoomingEnabled: boolean = false;
  zoom: number = 2;
  imageBox:any;

  pdfOptions: { icon: string, text: string } = {
    icon: MaterialIcons.fileDownload,
    text: 'Download'
  };

  topButtonOptions: { icon: string, text: string } = {
    icon: MaterialIcons.fileDownload,
    text: 'Download'
  };

  glass: any;

  // subscription
  onlineSubscription: Subscription;
  getImageSubscription: Subscription;

  @ViewChild('attachmentList') attachmentList: AttachmentFileListComponent;
  @ViewChild('videoElement') videoElement: any
  video: any;
  imageTaken: boolean;

  constructor(
    public dialogRef: MatDialogRef<AttachmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData,
    private _fs: FileSystemService,
    private _countSheetAttachmentService: CountSheetAttachmentService,
    private _fileOptionUtils: FileOptionUtils,
    private _onlineService: OnlineService,
    private _trackLoadingService: TrackLoadingService,
    private _sanitizer: DomSanitizer,
    private _changeDetector: ChangeDetectorRef,
    private _fileDatabaseService: FileDatabaseService,
    private _attachmentDatabaseService: AttachmentDatabaseService,
    private _fileCacheService: FileCacheService,
    private _cameraService: CameraService,
    private _featureSupport: FeatureSupportService,
    public snackBar: MatSnackBar,
  ) {
    const that = this;
    this.cameraDevices = data.devices
    if(this.cameraDevices.length > 0){
      this.camerasAvailable = true
    }

    that.countSheet = data.countsheet;
    that._trackLoadingService.startLoading('attachment-dialog', 'Loading Attachments');
    that.getAttachments(that.countSheet.data.audit_id, that.countSheet.id).then((attachments: IAttachment[]) => {
      that._trackLoadingService.stopLoading('attachment-dialog');

      that.attachments = attachments;

      that.setLayout();

      that.attachments.forEach((f, index) => {
        if (index === 0) {
          f.selected = true;
        } else {
          f.selected = false;
        }
      });
    });
  }

  getAttachments(auditId, countSheetClientId) {
    const that = this;
    return new Promise((resolve, reject) => {
      if (that._onlineService.isOnline() && !that._onlineService.getTestOfflineBool()) {
        that._countSheetAttachmentService.getAttachments(auditId, { count_sheet_client_id: countSheetClientId }).subscribe((data) => {
          resolve(data);
          that._countSheetAttachmentService.refreshOfflineDataForCountSheet(auditId, countSheetClientId, data).then(function (attachments: IAttachment[]) {
            that.attachments = attachments
          });
        });
      }
      else {
        that._attachmentDatabaseService.find(that.countSheet.data.audit_id, { count_sheet_client_id: that.countSheet.id, toRemove: false }).then((attachments: any[]) => {
          resolve(attachments);
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.onlineSubscription) {
      this.onlineSubscription.unsubscribe();
    }
    if (this.getImageSubscription) {
      this.getImageSubscription.unsubscribe();
    }

    this._cameraService.closeCamera();
  }

  setLayout() {
    if (!this.cameraEnabled) {
      if (this.attachments.length === 0) {
        this.showFileList = false;
        this.showFileView = false;
        this.showSingleFileAttacher = true;
        this.currentFile = null;
      } else {
        this.showFileList = true;
        this.showFileView = true;
        this.showSingleFileAttacher = false;
      }
    } else {
      this.showFileList = false;
      this.showFileView = true;
      this.showSingleFileAttacher = false;
      this.currentFile = null;
      this.pdfUrl = null
      this.imageUrl = null
    }

    if(this.zoomingEnabled){
      this.zoomingEnabled = false;
      this._changeDetector.detectChanges();
    }
  }

  createFileInput() {
    let input = document.createElement('input')
    input.type = 'file'
    return input;
  }

  async openFilePicker() {
    const that = this;
    const options = this._fileOptionUtils.auditOptions();
    let fileInput = this.createFileInput();

    let fileHandle = null
    if (this._featureSupport.isFireFox()) {
      fileInput.onchange = async (event:any) => {
        fileHandle = fileInput.files

        if (!fileHandle) {
          return;
        }
        if (fileHandle.length === 0) {
          return;
        }

        const file: any = event.target.files[0]
        if (!file) {
          return;
        }

        this.uploadFile(fileHandle, file)
      }
      fileInput.click()

    } else {
      fileHandle = await that._fs.pickFile(options);

      if (!fileHandle) {
        return;
      }
      if (fileHandle.length === 0) {
        return;
      }

      const file: any = await that._fs.getFile(fileHandle[0]);
      if (!file) {
        return;
      }

      this.uploadFile(fileHandle, file);
    }
  }

  async uploadFile(fileHandle: any, file: any){
    let that = this;
    that._trackLoadingService.startLoading('attachment-dialog', 'Adding Attachment');
      that.onlineSubscription = that._onlineService.isOnlineSubscription().subscribe(online => {
        that.online = online && !that._onlineService.getTestOfflineBool();
        if (that.online) {
          that._countSheetAttachmentService.addAttachment(file, that.countSheet.id).subscribe(
            (data: IAttachment) => {
              const attachment = that._attachmentDatabaseService.new(data);
              that._attachmentDatabaseService.add(attachment).then((id: number | string) => {
              that._fileDatabaseService.add({ local_file_id: id, fileHandle: fileHandle[0], file: file}).then(() => {
                // cache filehandle
                that._fileCacheService.cache[id.toString()] ={handle: fileHandle[0], file: file};
                that.attachments.push(attachment);
                that.setLayout();
                that._changeDetector.detectChanges();
                that.attachmentList.attachments = this.attachments;
                that.attachmentList.ngOnInit();
                that._trackLoadingService.stopLoading('attachment-dialog');
                this.viewFile(attachment);
              });
            })
          },
          (error) => {
            that._trackLoadingService.stopLoading('attachment-dialog');
            if(error.error.toLowerCase() === ErrorMessages.badFileType){
              that.openSnackBar(that.makeConfig(Messages.unsupportedFileType, false))
            }
          });
        }
        else {
          if(this._fileOptionUtils.isSupported(file.name)){
            const now = moment();
            const created_date = now.format();
            const formatted_created_at = now.format('MM/DD/YYYY LT');
            const formatted_created_at_date = now.format('MM/DD/YYYY');
            const attachment = that._attachmentDatabaseService.new({
              attachment_content_type: file.type,
              attachment_file_name: file.name,
              attachment_file_size: file.size,
              attachment_updated_at: file.lastModified.toString(),
              formatted_created_at: formatted_created_at,
              formatted_created_at_date: formatted_created_at_date,
              count_sheet_id: that.countSheet.dbId,
              count_sheet_client_id: that.countSheet.id,
              audit_id: that.countSheet.data.audit_id,
              created_at: created_date,
              is_active: true,
              synchronized: 0
            });

            that._attachmentDatabaseService.add(attachment).then((id: number | string) => {
              that._fileDatabaseService.add({ local_file_id: id, fileHandle: fileHandle[0], file: file}).then(() => {
                // cache filehandle
                that._fileCacheService.cache[id] = {handle: fileHandle[0], file: file};
                that.attachments.push(attachment);
                that.setLayout();
                that._changeDetector.detectChanges();
                that.attachmentList.attachments = this.attachments;
                that.attachmentList.ngOnInit();
                that._trackLoadingService.stopLoading('attachment-dialog');
                this.viewFile(attachment)
              });
            });
          }else{
            this._trackLoadingService.stopLoading('attachment-dialog');
            this.openSnackBar(this.makeConfig(Messages.unsupportedFileType, false))
          }
        }
      });
      this.onlineSubscription.unsubscribe();
  }

  ngOnInit() {
    this._changeDetector.detectChanges()
    if(this.camerasAvailable && !this.cameraEnabled){
      this.openCamera()
    }
  }

  async viewFile(file: IAttachment) {
    this.setFileListSelection(file);
    this.zoomingEnabled = false;
    this.noLocalHandle = false;
    this.fileNotViewable = false;
    this.currentFile = file;
    const extension = this._fileOptionUtils.getExtensionFromName(file.attachment_file_name);
    const isImage = this._fileOptionUtils.isImage(extension);
    const isPdf = this._fileOptionUtils.isPDF(extension);

    this.onlineSubscription = this._onlineService.isOnlineSubscription().subscribe(async online => {
      this.online = online && !this._onlineService.getTestOfflineBool();
      if (online && !this._onlineService.getTestOfflineBool()) {
        this.topButtonOptions.icon = MaterialIcons.fileDownload;
        this.topButtonOptions.text = 'Download';
        if (isImage) {
          this.pdfUrl = '';
          this.currentlySelectedFileName = file.attachment_file_name;
          this._trackLoadingService.startLoading('attachment-dialog', `Loading ${file.attachment_file_name}`);
          this.getImageSubscription = this._countSheetAttachmentService.getImage(file).subscribe((response) => {
            this.imageUrl = this._sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(response.body));
            this._trackLoadingService.stopLoading('attachment-dialog');
          });
        } else {
          this.imageUrl = '';
          this.pdfOptions.icon = MaterialIcons.fileDownload;
          this.pdfOptions.text = 'Download';
          this.currentlySelectedFileName = file.attachment_file_name;
          this.pdfUrl = environment.serverPath + 'attachments/' + file.id;
        }
      } else {
        this.topButtonOptions.icon = MaterialIcons.removeRedEye;
        this.topButtonOptions.text = 'View';
        this.hasLocalHandle();
        if (isImage) {
          this.pdfUrl = '';
          this.currentlySelectedFileName = file.attachment_file_name;
          const that = this;

          if (file.local_file_id) {
            this._trackLoadingService.startLoading('attachment-dialog', `Loading ${file.attachment_file_name}`);
            let handle = this._fileCacheService.getHandle(file.local_file_id);
            if (handle) {
              const fileBlob = handle.file;
              const downloadUrl = URL.createObjectURL(fileBlob);
              this.imageUrl = this._sanitizer.bypassSecurityTrustResourceUrl(downloadUrl);
              this._trackLoadingService.stopLoading('attachment-dialog');
            } else {
              this._fileDatabaseService.get(file.local_file_id).then(async (fileHandle: any) => {
                if (fileHandle) {
                  const fileBlob = fileHandle.file;
                  const downloadUrl = URL.createObjectURL(fileBlob);
                  this.imageUrl = this._sanitizer.bypassSecurityTrustResourceUrl(downloadUrl);
                  this._trackLoadingService.stopLoading('attachment-dialog');
                } else {
                  this.pdfUrl = '';
                  this.imageUrl = '';
                  this.noLocalHandle = true;
                  this._trackLoadingService.stopLoading('attachment-dialog');
                }
              }).catch((error) => {
                this.pdfUrl = '';
                this.imageUrl = '';
                this.noLocalHandle = true;
                this._trackLoadingService.stopLoading('attachment-dialog');
              });
            }
          } else {
            this.pdfUrl = '';
            this.imageUrl = '';
            this.noLocalHandle = true;
          }
        } else if (isPdf) {
          this.imageUrl = '';
          this.currentlySelectedFileName = file.attachment_file_name;
          const that = this;
          this._trackLoadingService.startLoading('attachment-dialog', `Loading ${file.attachment_file_name}`);
          if (file.local_file_id) {
            let handle = this._fileCacheService.getHandle(file.local_file_id);
            if (handle) {

              this.pdfOptions.icon = MaterialIcons.removeRedEye;
              this.pdfOptions.text = 'View';
              const fileBlob = handle.file;
              this.pdfUrl = URL.createObjectURL(fileBlob);
              this._trackLoadingService.stopLoading('attachment-dialog');

            } else {
              this._fileDatabaseService.get(file.local_file_id).then(async (fileHandle: any) => {
                if (fileHandle) {
                  this.pdfOptions.icon = MaterialIcons.removeRedEye;
                  this.pdfOptions.text = 'View';
                  const fileBlob = fileHandle.file;
                  this.pdfUrl = URL.createObjectURL(fileBlob);
                  this._trackLoadingService.stopLoading('attachment-dialog');
                } else {
                  this.pdfUrl = '';
                  this.imageUrl = '';
                  this.noLocalHandle = true;
                  this._trackLoadingService.stopLoading('attachment-dialog');
                }
              }).catch((error) => {
                this.pdfUrl = '';
                this.imageUrl = '';
                this.noLocalHandle = true;
                this._trackLoadingService.stopLoading('attachment-dialog');
              });
            }
          } else {
            this.pdfUrl = '';
            this.imageUrl = '';
            this.noLocalHandle = true;
          }
        } else {
          this.imageUrl = '';
          this.pdfUrl = '';
          this.currentlySelectedFileName = file.attachment_file_name;
          const that = this;
          if (file.local_file_id) {
            this.fileNotViewable = true;
          } else {
            this.noLocalHandle = true;
            this._trackLoadingService.stopLoading('attachment-dialog');
          }
        }
      }
    });

    this.onlineSubscription.unsubscribe();
  }

  async download() {
    this.onlineSubscription = this._onlineService.isOnlineSubscription().subscribe(async online => {
      this.online = online && !this._onlineService.getTestOfflineBool();
      if (online && !this._onlineService.getTestOfflineBool()) {
        this._countSheetAttachmentService.getAttachment(this.currentFile);

      } else {
        // Offline, if no handle, cannot download
        if (this.currentFile.local_file_id) {
          const extension = this._fileOptionUtils.getExtensionFromName(this.currentFile.attachment_file_name);
          const isImage = this._fileOptionUtils.isImage(extension);
          const isPdf = this._fileOptionUtils.isPDF(extension);
          if (isPdf) {
            let handle = this._fileCacheService.getHandle(this.currentFile.local_file_id.toString())
            if (handle) {
              const fileBlob = handle.file;
              const downloadUrl = URL.createObjectURL(fileBlob);
              window.open(downloadUrl, '_blank');
            } else {
              this._fileDatabaseService.get(this.currentFile.local_file_id).then(async (fileHandle: any) => {
                if (fileHandle) {
                  const fileBlob = fileHandle.file;
                  const downloadUrl = URL.createObjectURL(fileBlob);
                  window.open(downloadUrl, '_blank');
                } else {
                }
              });
            }
          }

          if (isImage) {
            let handle = this._fileCacheService.getHandle(this.currentFile.local_file_id)
            if (handle) {
              const imageBlob = handle.file;
              const downloadUrl = URL.createObjectURL(imageBlob);
              window.open(downloadUrl, '_blank');
            } else {
              this._fileDatabaseService.get(this.currentFile.local_file_id).then(async (fileHandle: any) => {
                if (fileHandle) {
                  const imageBlob = fileHandle.file;
                  const downloadUrl = URL.createObjectURL(imageBlob);
                  window.open(downloadUrl, '_blank');
                } else {
                }
              });
            }
          } else {
          }
        }
      }
    });
    this.onlineSubscription.unsubscribe();
  }

  async deleteAttachment(file: IAttachment) {
    const that = this;
    that.onlineSubscription = that._onlineService.isOnlineSubscription().subscribe(async online => {
      that.online = online && !that._onlineService.getTestOfflineBool();
      if (online && !that._onlineService.getTestOfflineBool()) {
        that._countSheetAttachmentService.deleteAttachment(file).subscribe((d: any) => {
          that.attachments = this.attachments.filter((a: IAttachment) => {
            return a.local_file_id !== file.local_file_id;
          });
          that._attachmentDatabaseService.delete(file.local_file_id).then(() => {
            that._fileDatabaseService.delete(file.local_file_id).then(() => {
              that.attachmentList.attachments = that.attachments;
              that.attachmentList.ngOnInit();
              that.setLayout();
              if (this.attachments.length > 0) {
                this.viewFile(this.attachments[0])
              }
            });
          });

        });
      } else if (file.id) {
        that.attachments = this.attachments.filter((a: IAttachment) => {
          return a.local_file_id !== file.local_file_id;
        });
        file.toRemove = true;
        file.synchronized = 0;
        that._attachmentDatabaseService.update(file).then(() => {
          that.attachmentList.ngOnInit();
          that.setLayout();

          let canbeViewed = this.attachments.filter((a) => {
            return !a.toRemove
          })
          if (canbeViewed.length > 0) {
            this.viewFile(canbeViewed[0])
          }

        })
      }
      else {
        that.attachments = this.attachments.filter((a: IAttachment) => {
          return a.local_file_id !== file.local_file_id;
        });
        that._attachmentDatabaseService.delete(file.local_file_id).then(() => {
          that._fileDatabaseService.delete(file.local_file_id).then(() => {
            that.attachmentList.attachments = that.attachments;
            that.attachmentList.ngOnInit();
            that.setLayout();

            let canbeViewed = this.attachments.filter((a) => {
              return !a.toRemove
            })
            if (canbeViewed.length > 0) {
              this.viewFile(canbeViewed[0])
            }
          });
        });
      }
    });

    this.onlineSubscription.unsubscribe();

  }

  hasLocalHandle() {
    if (this.currentFile.local_file_id) {
      this._fileDatabaseService.get(this.currentFile.local_file_id).then((fileHandle) => {
        if (fileHandle) {
          this.currentFileHasLocalHandle = true;
        } else {
          this.currentFileHasLocalHandle = false;
        }
      }).catch((error) => {
        this.currentFileHasLocalHandle = false;
      });
    } else {
      this.currentFileHasLocalHandle = false;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openCamera() {

    this.pdfUrl = null;
    this.imageUrl = null;
    this.currentFile = null;
    this.cameraEnabled = true;
    this.noLocalHandle = false;
    this.fileNotViewable = false;
    this.setLayout();
    this._changeDetector.detectChanges()
    this.video = this.videoElement.nativeElement;
    this._cameraService.initCamera(this.video, (device: IDeviceInfo) => {
      this.selectedCamera = device;
      this.canTakePicture = true;
    })
  }

  closeCamera(attachment: IAttachment = null) {
    this.cameraEnabled = false;
    this.canTakePicture = false;
    this.setLayout()
    this._cameraService.closeCamera(() => {

      if (attachment) {
        this.viewFile(attachment)
      } else {
        let canbeViewed = this.attachments.filter((a) => {
          return !a.toRemove
        })
        if (canbeViewed.length > 0) {
          this.viewFile(canbeViewed[0])
        }
      }
    })
  }

  dec2hex(dec: number):string {
    return dec.toString(16).padStart(2, "0")
  }

  makeImageFileName() {
    let auditId = this.countSheet.data.audit_id;
    var arr = new Uint8Array((8 || 40) / 2)
    let randomArray:any = window.crypto.getRandomValues(arr)    
    let id = Array.from(randomArray, this.dec2hex).join('')
    let name = ""
    if (this.countSheet.data.isKitted) {
      let kitReference = this.countSheet.data.reference;
      let kitLot = this.countSheet.data.lot_number
      if (kitLot){
        kitReference += `_${kitLot}`
      }
      // AUDITID_KITREF_KITLOT_RANDOMSTRING
      name += `${auditId}_${kitReference}_${id}.png`
    } else {
      // AUDITID_AREA_RANDOMSTRING
      name += `${auditId}_${this.countSheet.data.area}_${id}.png`
    }
    return name.toLowerCase();
  }

  takePicture() {
    let imageName = this.makeImageFileName()
    this._cameraService.takePic(imageName, (file) => {
      if (file) {
        this.imageTaken = true;
        this.takenImageFile = file;
      }
    })
  }

  async saveImage() {

    let that = this;
    this._trackLoadingService.startLoading('attachment-dialog', 'Adding Attachment');
    this.onlineSubscription = this._onlineService.isOnlineSubscription().subscribe(online => {
      this.online = online && !this._onlineService.getTestOfflineBool();
      if (this.online) {
        that._countSheetAttachmentService.addAttachment(this.takenImageFile, that.countSheet.id).subscribe((data: IAttachment) => {
          const attachment = that._attachmentDatabaseService.new(data);
          that._attachmentDatabaseService.add(attachment).then((id: number | string) => {
            that._fileDatabaseService.add({ local_file_id: id, file: this.takenImageFile }).then(() => {
              // cache filehandle
              that._fileCacheService.cache[id.toString()] = {file: this.takenImageFile} ;
              that.attachments.push(attachment);
              that.setLayout();
              that._changeDetector.detectChanges();
              that._trackLoadingService.stopLoading('attachment-dialog');

              // close camera
              this.closeCamera(attachment);
              this.imageTaken = false;
            });
          });
        });
      }
      else {
        const now = moment();
        const created_date = now.format();
        const formatted_created_at = now.format('MM/DD/YYYY LT');
        const formatted_created_at_date = now.format('MM/DD/YYYY');
        const attachment = that._attachmentDatabaseService.new({
          attachment_content_type: this.takenImageFile.type,
          attachment_file_name: this.takenImageFile.name,
          attachment_file_size: this.takenImageFile.size,
          attachment_updated_at: this.takenImageFile.lastModified.toString(),
          formatted_created_at: formatted_created_at,
          formatted_created_at_date: formatted_created_at_date,
          count_sheet_id: that.countSheet.dbId,
          count_sheet_client_id: that.countSheet.id,
          audit_id: that.countSheet.data.audit_id,
          created_at: created_date,
          is_active: true,
          synchronized: 0,
        });

        that._attachmentDatabaseService.add(attachment).then((id: number | string) => {
          that._fileDatabaseService.add({ local_file_id: id, file: this.takenImageFile }).then(() => {
            // cache filehandle
            that._fileCacheService.cache[id] = {file: this.takenImageFile};
            that.attachments.push(attachment);
            that.setLayout();
            that._changeDetector.detectChanges();
            that._trackLoadingService.stopLoading('attachment-dialog');

            this.closeCamera(attachment);
            this.imageTaken = false;
          });
        });
      }
    });
    this.onlineSubscription.unsubscribe();
  }

  retakeImage() {
    this.imageTaken = false;
    this.takenImageFile = null;
    this._cameraService.play()
  }

  pickCamera(camera: IDeviceInfo) {
    let config = this._cameraService.getConfigforCamera(camera)
    this._cameraService.initSpecificCamera(this.video, config, (device: IDeviceInfo) => {
      this.selectedCamera = device;
      this.canTakePicture = true;
    })
  }

  setFileListSelection(row:IAttachment) {
    if(this.attachmentList){
      this.attachmentList.resetFileAttachmentSelection(row)
    }
  }

  makeConfig(message: string, success: boolean):ISnackBarConfig {
    return {
      message: message,
      duration: 3000,
      success: success,
      snackBarClass: ""
    };
  }

  openSnackBar(config: ISnackBarConfig) {
    this.snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: config,
      duration: config.duration
    });
  }
  getCursorPos(img: HTMLElement, e: any) {
    /*get the x and y positions of the image:*/
    let a = img.getBoundingClientRect();
    return {
      x : e.pageX - a.left - window.pageXOffset,
      y : e.pageY - a.top - window.pageYOffset
    };
  }

  showLens(){
    if(this.zoomingEnabled){
      this.zoomingEnabled = false
    }else{
      this.zoomingEnabled = true

      setTimeout(()=>{
        this.imageBox = document.getElementById('image-box');
        this.glass = document.getElementById('img-magnifier-glass');
      }, 1000)
    }
  }

  @HostListener('mousemove', ['$event'])
  @HostListener('touchmove', ['$event'])
  handleMouseMovement(event:any){

    let target = event.target;
    if(['img-magnifier-glass', 'image-box'].includes(target.id) && this.zoomingEnabled){
      if(this.imageBox){
        let pos = this.getCursorPos(this.imageBox, event);

        // paint magnifier
        this.glass.style.backgroundImage = "url('" + this.imageBox.src + "')";
        this.glass.style.backgroundSize = (this.imageBox.width * this.zoom) + "px " + (this.imageBox.height * this.zoom) + "px";

        let bw = 3;
        let w = this.glass.offsetWidth / 2;
        let h = this.glass.offsetHeight / 2;
        let widthOverZoom = w/this.zoom
        let heightOverZoom = h/this.zoom

        let possibleX = this.imageBox.width - widthOverZoom
        let possibleY = this.imageBox.height - heightOverZoom

        /*prevent the magnifier glass from being positioned outside the image:*/
        if (pos.x > possibleX) {pos.x = possibleX;}
        if (pos.x < widthOverZoom) {pos.x = widthOverZoom;}
        if (pos.y > possibleY) {pos.y = possibleY;}
        if (pos.y < heightOverZoom) {pos.y = heightOverZoom;}

        /*set the position of the magnifier glass:*/
        this.glass.style.left = (pos.x-w) + "px";
        this.glass.style.top  = (pos.y-h) + "px";

        /*display what the magnifier glass "sees":*/
        this.glass.style.backgroundPosition = "-" + ((pos.x * this.zoom) - w + bw) + "px -" + ((pos.y * this.zoom) - h + bw) + "px";
      }
    }
  }
}
