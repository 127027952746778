var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatSnackBar, MatCheckboxChange } from '@angular/material';
import { zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { CountSheetService } from '../../services/count-sheet.service';
import { AuditService } from '../../services/audit.service';
import { CountSheetItemService } from '../../services/count-sheet-item.service';
import { RedspotInventoryImportService } from '../../services/redspot-inventory-import.service';
import { ConsignmentInventoryImportService } from '../../services/consignment-inventory-import.service';
import { KitInstanceCountSheetDataSource } from './kit-instance-count-sheet-datasource';
import { ItemSettingService } from '../../services/item-setting.service';
import { OnlineService } from './../../services/online.service';
import { CountSheetItemHelper } from './../../helpers/countsheet-item-helper';
import { KitInstanceHelper } from '../../helpers/kit-instance-helper';
import { ItemCombinationService } from '../../services/item-combination.service';
import { KitDefinitionItemService } from '../../services/kit-definition-item.service';
import { KitService } from '../../services/kit.service';
import { UserDataHelper } from '../../helpers/user-data-helper';
import * as moment from 'moment';
import { ExclusionService } from '../../services/exclusion.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { PrintPageComponent } from '../print-page/print-page.component';
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';
import { HistoryServiceService } from 'src/app/services/history-service.service';
import { OfflineDataSynchronizerService } from 'src/app/services/offline-data-synchronizer.service';
import { WarningDialogComponent } from '../warning-dialog/warning-dialog.component';
import { TrackLoadingService } from '../../services/track-loading.service';
import { ImportCountSheetItemComponent } from '../import-count-sheet-item/import-count-sheet-item.component';
import { PositiveIntegerHelper } from '../../helpers/positive-integer-helper';
import { ExportToExcelService } from 'src/app/services/export-to-excel.service';
import { CountSheetExportHelper } from 'src/app/helpers/countsheet-export-helper';
import { LotPoolManagedItemService } from '../../services/lot-pool-managed-item.service';
import { AudioPlayerService } from 'src/app/services/audio-player.service';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';
import { ItemService } from 'src/app/services/item.service';
import { DatabaseService } from 'src/app/services/database.service';
import { CountSheetDatabaseService } from 'src/app/services/count-sheet-database.service';
import { CountSheetItemDatabaseService } from 'src/app/services/count-sheet-item-database.service';
import { AuditKitInstanceDatabaseService } from 'src/app/services/audit-kit-instance-database.service';
import { AuditDatabaseService } from 'src/app/services/audit-database.service';
import { AttachmentDialogComponent } from '../attachment-dialog/attachment-dialog.component';
import { Messages } from 'src/app/helpers/messages';
import { CameraService } from 'src/app/services/camera.service';
import { RecallOracleService } from 'src/app/services/recall-oracle.service';
var KitInstanceCountSheetComponent = /** @class */ (function () {
    function KitInstanceCountSheetComponent(_route, _router, _countSheetService, _countSheetItemService, _redspotInventoryImportService, _consignmentInventoryImportService, _itemSettingService, _onlineService, _dialog, _itemCombinationService, _kitDefinitionItemService, _exclusionService, _snackBar, _historyService, _auditService, _synchronizer, _kitService, _trackLoading, _excelService, _lotPoolManagedItemService, _audioPlayerService, _itemService, _databaseService, _countSheetDatabaseService, _countSheetItemDatabaseService, _auditKitInstanceDatabaseService, _auditDatabaseService, _cameraService, _recallOracleService) {
        var _this = this;
        this._route = _route;
        this._router = _router;
        this._countSheetService = _countSheetService;
        this._countSheetItemService = _countSheetItemService;
        this._redspotInventoryImportService = _redspotInventoryImportService;
        this._consignmentInventoryImportService = _consignmentInventoryImportService;
        this._itemSettingService = _itemSettingService;
        this._onlineService = _onlineService;
        this._dialog = _dialog;
        this._itemCombinationService = _itemCombinationService;
        this._kitDefinitionItemService = _kitDefinitionItemService;
        this._exclusionService = _exclusionService;
        this._snackBar = _snackBar;
        this._historyService = _historyService;
        this._auditService = _auditService;
        this._synchronizer = _synchronizer;
        this._kitService = _kitService;
        this._trackLoading = _trackLoading;
        this._excelService = _excelService;
        this._lotPoolManagedItemService = _lotPoolManagedItemService;
        this._audioPlayerService = _audioPlayerService;
        this._itemService = _itemService;
        this._databaseService = _databaseService;
        this._countSheetDatabaseService = _countSheetDatabaseService;
        this._countSheetItemDatabaseService = _countSheetItemDatabaseService;
        this._auditKitInstanceDatabaseService = _auditKitInstanceDatabaseService;
        this._auditDatabaseService = _auditDatabaseService;
        this._cameraService = _cameraService;
        this._recallOracleService = _recallOracleService;
        this.checkboxColor = 'warn';
        this.iconColor = 'warn';
        this.loading = false;
        this.warehouses = [];
        this.expectedItemCount = 0;
        this.actualItemCount = 0;
        this.showOrderFilter = false;
        this.statsComplete = false;
        this.snackBarConfig = {
            message: '',
            duration: 3000,
            success: false,
            snackBarClass: ''
        };
        this.focusFrom = "";
        this.focusFromId = "";
        this.focusTo = "";
        // helpers
        this.kitInstanceHelper = new KitInstanceHelper();
        this.countSheetItemHelper = new CountSheetItemHelper();
        this.userDataHelper = new UserDataHelper();
        this.positiveIntegerHelper = new PositiveIntegerHelper();
        this.dataSource = new KitInstanceCountSheetDataSource(this.countSheetItemHelper);
        this.getItems().subscribe(function (data) {
            _this.items = data;
            _this.countSheetItemHelper.cacheItems(_this.items);
        });
        this._cameraService.getDevices(function (devices) {
            _this.cameras = devices;
        });
    }
    KitInstanceCountSheetComponent.prototype.ngOnDestroy = function () {
        if (this.onlineSubscription) {
            this.onlineSubscription.unsubscribe();
        }
        if (this.reloadSubscription) {
            this.reloadSubscription.unsubscribe();
        }
        if (this.startReloadingSubscription) {
            this.startReloadingSubscription.unsubscribe();
        }
        if (this.previousUrlSubscription) {
            this.previousUrlSubscription.unsubscribe();
        }
        if (this.isOnlineSubscription) {
            this.isOnlineSubscription.unsubscribe();
        }
        if (this.isOfflineSubscription) {
            this.isOfflineSubscription.unsubscribe();
        }
        if (this.trackLoadingSubscription) {
            this.trackLoadingSubscription.unsubscribe();
        }
        this.printer = null;
        this.virtualScroller = null;
    };
    KitInstanceCountSheetComponent.prototype.ngOnInit = function () {
        var _this = this;
        var that = this;
        this.auditId = +this._route.snapshot.paramMap.get('audit_id');
        this.previousUrl = this._historyService.findLastCountSheetSummaryLink();
        this.userData = this.userDataHelper.getUserData();
        this.permissions = JSON.parse(localStorage.getItem('permissions'));
        this.reloadSubscription = this._synchronizer.allSynchronizedEvent.subscribe(function () {
            _this.reloadWhenBackOnline();
        });
        //keep this.online up to date
        this.isOnline = this._onlineService.isOnline() && !this._onlineService.testingOffline;
        this.isOnlineSubscription = this._onlineService.isOnlineSubscription().subscribe(function (online) {
            _this.isOnline = online && !_this._onlineService.getTestOfflineBool();
        });
        this.isOfflineSubscription = this._onlineService.goOfflineEvent.subscribe(function (data) {
            _this.isOnline = !data;
        });
        //end of keep this.online up to date
        this.viewOnly = this._route.snapshot.paramMap.get('isViewOnly') === 'true';
        this.status = 'All';
        this._route.queryParams.subscribe(function (params) {
            if (params.status) {
                _this.status = params.status;
            }
        });
        this.dataSource.filters = { orderType: 'consigned', exclusions: 'hide' };
        this._synchronizer.needsSyncing().then(function (hasUnsynced) {
            var wouldSync = that._onlineService.isOnline() && !that._onlineService.testingOffline;
            var currentlySyncing = !that._synchronizer.doneProcessing;
            if (wouldSync && (hasUnsynced || currentlySyncing)) {
                return;
            }
            that.getKits();
            that._trackLoading.startLoading('consignment-quantities', 'Loading SAP Consignment');
            that._trackLoading.startLoading('item-settings', 'Loading Item Settings');
            that._trackLoading.startLoading('item-combinations', 'Loading Item Batch Verification');
            that._trackLoading.startLoading('item-exclusions', 'Loading Exclusions');
            zip(that.getConsignmentQuantities(that.auditId), that.getItemSettingsByAudit(that.auditId), that.getItemCombinations(), that.getExclusions(that.auditId), that.getLotPoolManagedItems(), that._auditDatabaseService.get(that.auditId)).subscribe(function (data) {
                that.dataSource.sapQuantities = data[0];
                that.dataSource.itemSettings = data[1];
                that.dataSource.itemCombinations = data[2];
                that.dataSource.exclusions = data[3];
                that.dataSource.lotPoolManagedItems = data[4];
                that.audit = data[5];
                that._trackLoading.stopLoading('consignment-quantities');
                that._trackLoading.stopLoading('item-settings');
                that._trackLoading.stopLoading('item-combinations');
                that._trackLoading.stopLoading('item-exclusions');
                var online = that._onlineService.isOnline() && !that._onlineService.testingOffline;
                that.countSheetItemHelper.cacheItemsFromSapQuantities(that.dataSource.sapQuantities);
                that.countSheetItemHelper.cacheItemsForExclusions(that.dataSource.exclusions);
                that.countSheetItemHelper.cacheItemsForItemSettings(that.dataSource.itemSettings);
                that._trackLoading.startLoading('kit-instance-count-sheet-recall-oracle', 'Loading Recall Oracle');
                that._recallOracleService.prime(that.auditId, { itemCombinations: that.dataSource.itemCombinations }).then(function (_) {
                    that.dataSource.recallOracleService = that._recallOracleService;
                    that._trackLoading.stopLoading('kit-instance-count-sheet-recall-oracle');
                    that._trackLoading.startLoading('count-sheet', 'Loading Count Sheet');
                    that.getCountSheet(online, that._route.snapshot.paramMap.get('id')).then(function (countSheetData) {
                        that._trackLoading.stopLoading('count-sheet');
                        that.dataSource.countSheet = countSheetData;
                        that.getWarehouses(that.auditId, that.dataSource.countSheet.data.warehouse_id);
                        that._trackLoading.startLoading('kit-instances', 'Loading Kit Instance');
                        that._trackLoading.startLoading('rot-kit-definition-items', 'Loading Kit BOMs');
                        zip(that.getKitInstance(that.dataSource.countSheet), that.getRootKitDefinitionItems(that.dataSource.countSheet.data.kit_id)).subscribe(function (kitInstanceData) {
                            that._trackLoading.stopLoading('kit-instances');
                            that._trackLoading.stopLoading('rot-kit-definition-items');
                            that.kitInstance = kitInstanceData[0];
                            that.dataSource.rootKitDefinitionItems = kitInstanceData[1];
                            that._trackLoading.startLoading('count-sheet-items', 'Loading Count Sheet Items');
                            that._trackLoading.startLoading('redspot-inventory', 'Loading Redspot Inventory');
                            zip(that.getCountSheetItemsInAnyState(online, that.auditId, that.dataSource.countSheet), that.getRedspotQuantities(that.auditId, that.kitInstance.data.item_instance_id)).subscribe(function (countsheetItemsData) {
                                that._trackLoading.stopLoading('count-sheet-items');
                                that._trackLoading.stopLoading('redspot-inventory');
                                that.dataSource.countSheetItems = countsheetItemsData[0];
                                that.setAuditedByValue(that.dataSource.countSheetItems);
                                that.dataSource.redspotQuantities = countsheetItemsData[1];
                                that.refreshDataSource();
                            });
                        });
                    });
                });
            });
        });
        this.trackLoadingSubscription = this._trackLoading.stopLoadingEvent.subscribe(function () {
            if ('scrollRestoration' in history) {
                history.scrollRestoration = 'manual';
            }
            document.getElementById('top').scrollIntoView();
        });
    };
    KitInstanceCountSheetComponent.prototype.OrderFilterChanged = function (event) {
        var filters = this.dataSource.filters;
        filters['orderType'] = event.value;
        this.dataSource.filters = filters;
        this.refreshDataSource();
    };
    KitInstanceCountSheetComponent.prototype.refreshDataSource = function () {
        this.dataSource.refresh();
        this.setCountSheetStats();
        this.setAuditedByValue(this.dataSource.countSheetItems);
        if (!this.dataSource.countSheet.data.show_expected_item_list) {
            this.scrollToBottom();
        }
        var height = 58;
        document.getElementsByTagName('virtual-scroller')[0].style.height = Math.min(height + height * this.dataSource.data.length, height * 11).toString() + 'px';
        var currentFocus = this.focusFromId;
        if (currentFocus) {
            setTimeout(function () {
                var element = document.getElementById(currentFocus);
                if (element) {
                    element.focus();
                    element.select();
                }
            }, 300);
        }
    };
    /**
     * copy countsheet item online / offline
     * @param element
     */
    KitInstanceCountSheetComponent.prototype.copyCountSheetItem = function (oldElement) {
        var that = this;
        if (this.dataSource.countSheetItems.find(function (csi) { return !csi.data.hide && !csi._destroy && csi.data.item_id === oldElement.data.item_id && !csi.data.lot_number && !csi.data.serial; })) {
            return;
        }
        var element = JSON.parse(JSON.stringify(oldElement));
        element.id = '';
        element.dbId = 0;
        element.data.isKitted = true;
        element._destroy = false;
        element.data.quantity = 1;
        element.data.checked = true;
        element.data.lot_number = '';
        element.data.serial = '';
        element.data.expected_quantity = 0;
        element.data.modified_by = this.userData.name;
        element.data.audit_id = this.auditId;
        element.data.rank = 0;
        element.isSynchronized = 0;
        if (this.dataSource.countSheet.id) {
            element.data.count_sheet_client_id = this.dataSource.countSheet.id;
        }
        if (this.dataSource.countSheet.dbId) {
            element.data.count_sheet_id = this.dataSource.countSheet.dbId;
        }
        this._countSheetItemDatabaseService.add(element).then(function () {
            zip(that._countSheetDatabaseService.get(that.dataSource.countSheet.id), that._countSheetItemDatabaseService.find(that.auditId, { count_sheet_client_id: that.dataSource.countSheet.id, _destroy: false })).subscribe(function (data) {
                that.dataSource.countSheet = data[0];
                that.dataSource.countSheetItems = data[1];
                that.setAuditedByValue(that.dataSource.countSheetItems);
                that.refreshDataSource();
            });
        });
    };
    KitInstanceCountSheetComponent.prototype.updateCountSheetItems = function () {
        var _this = this;
        var that = this;
        zip(that._countSheetDatabaseService.get(that.dataSource.countSheet.id), that._countSheetItemDatabaseService.find(that.auditId, { count_sheet_client_id: that.dataSource.countSheet.id, _destroy: false })).subscribe(function (data) {
            that.dataSource.countSheet = data[0];
            that.dataSource.countSheetItems = data[1];
            _this.setAuditedByValue(_this.dataSource.countSheetItems);
            _this.refreshDataSource();
        });
    };
    /**
     * click check all online / offline
     */
    KitInstanceCountSheetComponent.prototype.checkedAllClicked = function () {
        var _this = this;
        this._trackLoading.startLoading('check-all-clicked', 'Updating Count Sheet');
        var expiredItemsPresent = false;
        var recalledItemsPresent = false;
        var that = this;
        var promises = [];
        this.dataSource.data.forEach(function (row) {
            if (!that.anyChecked(row)) {
                var records = [];
                if (row.count_sheet_items) {
                    if (row.count_sheet_items.length === 0) {
                        that.createLotPoolManangedCountedItems(row);
                        records = row.count_sheet_items;
                    }
                    else {
                        records = row.count_sheet_items;
                    }
                }
                else {
                    records = [row];
                }
                records.forEach(function (element) {
                    element._destroy = false;
                    element.isSynchronized = 0;
                    element.data.count_sheet_id = _this.dataSource.countSheet.dbId;
                    element.data.count_sheet_client_id = _this.dataSource.countSheet.id;
                    element.data.audit_id = _this.dataSource.countSheet.data.audit_id;
                    if (!(element.id || element.dbId)) {
                        element.data.quantity = element.data.expected_quantity;
                    }
                    element.data.checked = true;
                    element.data.modified_by = _this.userData.name;
                    if (!element.id) {
                        promises.push(_this._countSheetItemDatabaseService.add(element));
                    }
                    else {
                        promises.push(_this._countSheetItemDatabaseService.update(element));
                    }
                });
            }
        });
        zip.apply(void 0, promises.concat(new Promise(function (r) { r(null); }))).subscribe(function (_) {
            zip(that._countSheetDatabaseService.get(that.dataSource.countSheet.id), that._countSheetItemDatabaseService.find(that.auditId, { count_sheet_client_id: that.dataSource.countSheet.id, _destroy: false })).subscribe(function (data) {
                that.dataSource.countSheet = data[0];
                that.dataSource.countSheetItems = data[1];
                that.setAuditedByValue(_this.dataSource.countSheetItems);
                that.refreshDataSource();
                recalledItemsPresent = that.dataSource.countSheetItems.find(function (csi) {
                    var result = csi.data.show_recalled_warning ? true : false;
                    return result;
                });
                expiredItemsPresent = that.dataSource.countSheetItems.find(function (csi) {
                    var result = csi.data.show_expired_warning ? true : false;
                    return result;
                });
                if (recalledItemsPresent) {
                    that.openWarningDialog('Recalled Product - This kit contains an item that has been recalled. Please place the product aside before you continue.');
                }
                if (expiredItemsPresent) {
                    that.openWarningDialog('Expired Product - This kit contains an item that has expired. Please place the product aside before you continue.');
                }
                that._trackLoading.stopLoading('check-all-clicked');
            });
        });
    };
    KitInstanceCountSheetComponent.prototype.deleteAll = function () {
        var _this = this;
        var dialogRef = this._dialog.open(ConfirmationDialogComponent, {
            width: '30%',
            data: 'Are you sure you want to clear the countsheet?',
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                var that_1 = _this;
                that_1._trackLoading.startLoading('delete-all-clicked', 'Updating Count Sheet');
                that_1._countSheetItemService.deleteCountSheetItemsOffline(that_1.dataSource.countSheet.data.audit_id, that_1.dataSource.countSheet.id).then(function (_) {
                    that_1.dataSource.countSheetItems = [];
                    that_1.dataSource.countSheet.isSynchronized = 0;
                    that_1.dataSource.countSheet.data.total_items_counted = 0;
                    that_1.refreshDataSource();
                    that_1.setAuditedByValue(that_1.dataSource.countSheetItems);
                    that_1._trackLoading.stopLoading('delete-all-clicked');
                });
            }
        });
    };
    KitInstanceCountSheetComponent.prototype.lotPoolManagedItemRemoveAddedItem = function (element) {
        var _this = this;
        var that = this;
        var promises = element.count_sheet_items.map(function (csi) {
            if (csi.dbId) {
                csi.data.quantity = 0;
                csi.data.checked = false;
                csi._destroy = true;
                csi.isSynchronized = 0;
                return that._countSheetItemDatabaseService.update(csi);
            }
            return that._countSheetItemDatabaseService.delete(csi.id);
        });
        zip.apply(void 0, promises.concat(new Promise(function (r) { r(null); }))).subscribe(function (_) {
            zip(that._countSheetDatabaseService.get(that.dataSource.countSheet.id), that._countSheetItemDatabaseService.find(that.auditId, { count_sheet_client_id: that.dataSource.countSheet.id, _destroy: false })).subscribe(function (data) {
                that.dataSource.countSheet = data[0];
                that.dataSource.countSheetItems = data[1];
                _this.setAuditedByValue(_this.dataSource.countSheetItems);
                _this.refreshDataSource();
            });
        });
    };
    KitInstanceCountSheetComponent.prototype.createLotPoolManangedCountedItems = function (element) {
        var _this = this;
        var countSheetItems = element.count_sheet_items;
        if (countSheetItems.length === 0) {
            countSheetItems = element.available_redspot_inventory.map(function (rq) {
                var row = {
                    data: {
                        item_id: element.data.item_id,
                        reference: element.data.reference,
                        description: element.data.description,
                        lot_number: rq.lot_number.value,
                        serial: '',
                        expected_quantity: rq.quantity,
                        quantity: rq.quantity,
                        count_sheet_id: _this.dataSource.countSheet.dbId,
                        count_sheet_client_id: _this.dataSource.countSheet.id,
                        audit_id: _this.dataSource.countSheet.data.audit_id,
                        is_consigned: element.data.is_consigned,
                        manually_entered: true,
                        show_recalled_warning: _this._recallOracleService.get(element.data.item_id, null, null)
                    },
                    id: '',
                    dbId: 0,
                    isSynchronized: 1
                };
                return row;
            });
            element.count_sheet_items = countSheetItems;
        }
        if (countSheetItems.length === 0) {
            countSheetItems = [{
                    data: {
                        item_id: element.data.item_id,
                        reference: element.data.reference,
                        description: element.data.description,
                        lot_number: '',
                        serial: '',
                        expected_quantity: 0,
                        quantity: 0,
                        count_sheet_id: this.dataSource.countSheet.dbId,
                        count_sheet_client_id: this.dataSource.countSheet.id,
                        audit_id: this.dataSource.countSheet.data.audit_id,
                        is_consigned: element.data.is_consigned,
                        manually_entered: true
                    },
                    id: 0,
                    dbId: 0,
                    isSynchronized: 1
                }];
            element.count_sheet_items = countSheetItems;
        }
    };
    KitInstanceCountSheetComponent.prototype.lotPoolManagedCheckedClicked = function (event, element) {
        var needsUpdate = false;
        this.createLotPoolManangedCountedItems(element);
        if (event instanceof MatCheckboxChange) {
            element.count_sheet_items.forEach(function (csi) {
                csi.data.checked = event.checked;
            });
            needsUpdate = true;
        }
        else if (event instanceof KeyboardEvent && event.code === 'Space') {
            element.count_sheet_items.forEach(function (csi) {
                csi.data.checked = true;
            });
            needsUpdate = true;
        }
        element.count_sheet_items.forEach(function (csi) {
            csi._destroy = false;
            csi.data.isKitted = true;
        });
        element.data.quantity = element.count_sheet_items.reduce(function (total, csi) { return total + csi.data.quantity; }, 0);
        if (needsUpdate) {
            this.updateCountSheetItem(element.count_sheet_items);
        }
    };
    KitInstanceCountSheetComponent.prototype.lotPoolManagedQuantityChanged = function (event, element) {
        var newTotalQty = +event.target.value;
        var oldTotalQty = element.count_sheet_items.filter(function (csi) { return csi.data.checked; }).reduce(function (total, csi) { return total + csi.data.quantity; }, 0);
        element.count_sheet_items = this.countSheetItemHelper.lotPoolManagedQuantityChanged(newTotalQty - oldTotalQty, element.count_sheet_items, element.available_redspot_inventory, this.dataSource.sapQuantities.filter(function (sap) { return sap.item_id === element.data.item_id; }), element.data, this.dataSource.countSheet, { isKitted: true })[0];
        element.data.quantity = element.count_sheet_items.reduce(function (total, csi) { return total + csi.data.quantity; }, 0);
        this.updateCountSheetItem(element.count_sheet_items);
    };
    /**
     * click check online / offline
     * @param event
     * @param element
     */
    KitInstanceCountSheetComponent.prototype.checkedClicked = function (event, element) {
        var needsUpdate = false;
        if (event instanceof MatCheckboxChange) {
            element.data.checked = event.checked;
            if (!event.checked) {
                element.data.quantity = 0;
            }
            needsUpdate = true;
        }
        else if (event instanceof KeyboardEvent && !element.data.checked) {
            element.data.checked = true;
            needsUpdate = true;
        }
        element._destroy = false;
        element.data.isKitted = true;
        if (needsUpdate) {
            if (!element.id && !element.dbId) {
                element.data.quantity = element.data.expected_quantity;
            }
            this.countSheetItemHelper.setRecallWarning(element, this.dataSource.countSheet, this.dataSource.itemSettings, this.dataSource.itemCombinations, this.dataSource.lotPoolManagedItems, this.dataSource.recallOracleService);
            this.countSheetItemHelper.setExpirationWarning(element, this.dataSource.sapQuantities);
            this.updateCountSheetItem(element);
        }
    };
    /**
     *
     * @param event
     * @param element
     */
    KitInstanceCountSheetComponent.prototype.removeAddedItem = function (element) {
        var _this = this;
        var that = this;
        var promise;
        if (element.dbId) {
            element.data.quantity = 0;
            element.data.checked = false;
            element._destroy = true;
            element.isSynchronized = 0;
            promise = this._countSheetItemDatabaseService.update(element);
        }
        else {
            promise = this._countSheetItemDatabaseService.delete(element.id);
        }
        promise.then(function (_) {
            zip(that._countSheetDatabaseService.get(that.dataSource.countSheet.id), that._countSheetItemDatabaseService.find(that.auditId, { count_sheet_client_id: that.dataSource.countSheet.id, _destroy: false })).subscribe(function (data) {
                that.dataSource.countSheet = data[0];
                that.dataSource.countSheetItems = data[1];
                _this.setAuditedByValue(_this.dataSource.countSheetItems);
                _this.refreshDataSource();
            });
        });
    };
    KitInstanceCountSheetComponent.prototype.checkAddedItem = function (event, element) {
        var _this = this;
        var updateNeeded = false;
        if (event instanceof MatCheckboxChange) {
            element.data.checked = event.checked;
            updateNeeded = true;
        }
        else if (event instanceof KeyboardEvent && !element.data.checked) {
            element.data.checked = true;
            updateNeeded = true;
        }
        element._destroy = false;
        element.data.isKitted = true;
        if (updateNeeded) {
            if (!element.id && !element.dbId) {
                element.data.quantity = element.data.expected_quantity;
            }
            this.updateCountSheetItem(element, undefined, function () { return _this.refreshDataSource(); });
        }
    };
    /**
     * change quantity online / offline
     * @param event
     * @param element
     */
    KitInstanceCountSheetComponent.prototype.quantityChanged = function (event, element) {
        element._destroy = false;
        element.data.isKitted = true;
        element.data.checked = true;
        element.data.quantity = +event.target.value;
        this.countSheetItemHelper.setRecallWarning(element, this.dataSource.countSheet, this.dataSource.itemSettings, this.dataSource.itemCombinations, this.dataSource.lotPoolManagedItems, this.dataSource.recallOracleService);
        this.countSheetItemHelper.setExpirationWarning(element, this.dataSource.sapQuantities);
        this.updateCountSheetItem(element);
    };
    KitInstanceCountSheetComponent.prototype.updateCountSheetItem = function (primaryElements, secondaryElements, callback) {
        var _this = this;
        if (secondaryElements === void 0) { secondaryElements = []; }
        if (callback === void 0) { callback = null; }
        primaryElements = [].concat.apply([], [primaryElements]);
        secondaryElements = [].concat.apply([], [secondaryElements]);
        primaryElements.forEach(function (e) {
            if (_this.dataSource.countSheet.dbId) {
                e.data.count_sheet_id = _this.dataSource.countSheet.dbId;
            }
            e.data.count_sheet_client_id = _this.dataSource.countSheet.id;
            e.data.audit_id = _this.dataSource.countSheet.data.audit_id;
            e.isSynchronized = 0;
        });
        secondaryElements.forEach(function (e) {
            if (_this.dataSource.countSheet.dbId) {
                e.data.count_sheet_id = _this.dataSource.countSheet.dbId;
            }
            e.data.count_sheet_client_id = _this.dataSource.countSheet.id;
            e.data.audit_id = _this.dataSource.countSheet.data.audit_id;
            e.isSynchronized = 0;
        });
        var elements = [].concat.apply([], [primaryElements, secondaryElements]);
        callback = callback || function () { };
        var that = this;
        var errors = primaryElements.map(function (e) {
            return that.countSheetItemHelper.validateCountSheetItem(e);
        }).reduce(function (total, internalErrors) { return __assign({}, total, internalErrors); });
        if (Object.keys(errors).length > 0) {
            that.showSnackbar(Object.values(errors).join('.  '), false);
            zip(that._countSheetDatabaseService.get(that.dataSource.countSheet.id), that._countSheetItemDatabaseService.find(that.auditId, { count_sheet_client_id: that.dataSource.countSheet.id, _destroy: false })).subscribe(function (data) {
                that.dataSource.countSheet = data[0];
                that.dataSource.countSheetItems = data[1];
                _this.setAuditedByValue(_this.dataSource.countSheetItems);
                _this.refreshDataSource();
            });
            return;
        }
        var promises = elements.map(function (e) {
            e.isSynchronized = 0;
            if (e.id) {
                return that._countSheetItemDatabaseService.update(e);
            }
            return that._countSheetItemDatabaseService.add(e);
        });
        zip.apply(void 0, promises.concat(new Promise(function (r) { r(null); }))).subscribe(function (data) {
            if (_this.focusFrom === "lot_number") {
                if (elements[0].data.is_serial_tracked) {
                    _this.focusFromId = "serial_number_" + data[0];
                }
                else {
                    _this.focusFromId = "quantity_" + data[0];
                }
            }
            else if (_this.focusFrom === "serial_number") {
                _this.focusFromId = "quantity_" + data[0];
            }
            zip(that._countSheetDatabaseService.get(that.dataSource.countSheet.id), that._countSheetItemDatabaseService.find(that.auditId, { count_sheet_client_id: that.dataSource.countSheet.id, _destroy: false })).subscribe(function (count_sheet_data) {
                that.dataSource.countSheet = count_sheet_data[0];
                that.dataSource.countSheetItems = count_sheet_data[1];
                _this.setAuditedByValue(_this.dataSource.countSheetItems);
                that.setCountSheetStats();
                Array.from(new Set(primaryElements.map(function (e) { return that.countSheetItemHelper.checkForWarnings(e, that.dataSource.recallOracleService); }).reduce(function (total, message) {
                    return total.concat(message.warnings);
                }, []))).slice().forEach(function (m) {
                    that.openWarningDialog(m.toString());
                });
                callback();
            });
        });
    };
    /**
     * change lot number online / offline
     * @param event
     * @param element
     */
    KitInstanceCountSheetComponent.prototype.lotNumberChanged = function (event, element) {
        var _this = this;
        var secondaryElement;
        var newlotNumber = (event.target.value || '').toUpperCase();
        if (!newlotNumber && this.isLotPoolManaged(element)) {
            //this is the released lot which isn't yet represented in the current count sheeet items array, if its in redspot quantities we want to add it back in
            var readded = [];
            if (element.data.checked && this.dataSource.redspotQuantities.filter(function (rq) { return rq.item_id === element.data.item_id; }).map(function (rq) { return rq.lot_number.value; }).indexOf(element.data.lot_number) !== -1) {
                readded.push({
                    item_id: element.data.item_id,
                    lot_number: { value: element.data.lot_number },
                    quantity: element.data.quantity
                });
            }
            newlotNumber = this.countSheetItemHelper.getAvailablelotPoolManagedLotNumber(this.dataSource.countSheetItems.filter(function (csi) { return csi.data.item_id === element.data.item_id; }), (_a = this.dataSource.redspotQuantities.filter(function (rq) { return rq.item_id === element.data.item_id; })).concat.apply(_a, readded), this.dataSource.sapQuantities.filter(function (sap) { return sap.item_id === element.data.item_id; }));
            event.target.value = newlotNumber;
        }
        //if row wasn't saved before set the quantity
        if (!element.dbId && !element.id) {
            element.data.quantity = element.data.expected_quantity;
        }
        element._destroy = false;
        element.data.isKitted = true;
        element.data.checked = true;
        element.data.manually_entered = true;
        secondaryElement = JSON.parse(JSON.stringify(element));
        secondaryElement.data.quantity = 0;
        secondaryElement.data.hide = true;
        element.id = '';
        element.dbId = 0;
        element.data.lot_number = newlotNumber;
        this.countSheetItemHelper.setRecallWarning(element, this.dataSource.countSheet, this.dataSource.itemSettings, this.dataSource.itemCombinations, this.dataSource.lotPoolManagedItems, this.dataSource.recallOracleService);
        this.countSheetItemHelper.setExpirationWarning(element, this.dataSource.sapQuantities);
        this.focusFrom = "lot_number";
        this.updateCountSheetItem(element, secondaryElement, function () { return _this.refreshDataSource(); });
        var _a;
    };
    /**
     * change serial number online/ offline
     * @param event
     * @param element
     */
    KitInstanceCountSheetComponent.prototype.serialNumberChanged = function (event, element) {
        var _this = this;
        this.focusFrom = "serial_number";
        var secondaryElement;
        //if row wasn't saved before set the quantity
        if (!element.dbId && !element.id) {
            element.data.quantity = element.data.expected_quantity;
        }
        element._destroy = false;
        element.data.isKitted = true;
        element.data.checked = true;
        element.data.manually_entered = true;
        secondaryElement = JSON.parse(JSON.stringify(element));
        secondaryElement.data.quantity = 0;
        secondaryElement.data.hide = true;
        element.id = '';
        element.dbId = 0;
        element.data.serial = event.target.value;
        this.countSheetItemHelper.setRecallWarning(element, this.dataSource.countSheet, this.dataSource.itemSettings, this.dataSource.itemCombinations, this.dataSource.lotPoolManagedItems, this.dataSource.recallOracleService);
        this.countSheetItemHelper.setExpirationWarning(element, this.dataSource.sapQuantities);
        this.updateCountSheetItem(element, secondaryElement, function () { return _this.refreshDataSource(); });
    };
    /**
     * change reference for manually entered items online/ offline
     * @param event
     * @param element
     */
    KitInstanceCountSheetComponent.prototype.referenceChanged = function (event, element) {
        var _this = this;
        var secondaryElement;
        if (this.dataSource.countSheet.dbId) {
            element.data.count_sheet_id = this.dataSource.countSheet.dbId;
        }
        element.data.count_sheet_client_id = this.dataSource.countSheet.id;
        element.data.audit_id = this.dataSource.countSheet.data.audit_id;
        var match = this.items.filter(function (item) {
            return (item.reference.toUpperCase() === event.target.value.toUpperCase()) || (item.unpunctuated_reference.toUpperCase() === event.target.value.toUpperCase());
        });
        if (match.length === 0) {
            this.showSnackbar('\'' + event.target.value.toUpperCase() + '\' is not a valid reference.', false);
            event.target.value = element.data.reference;
        }
        else {
            secondaryElement = JSON.parse(JSON.stringify(element));
            secondaryElement.data.quantity = 0;
            secondaryElement.data.hide = true;
            element.id = '';
            element.dbId = 0;
            element.data.reference = match[0].reference;
            element.data.item_id = match[0].id;
            element.data.description = match[0].description;
            element.data.is_lot_tracked = match[0].is_lot_number_tracked;
            element.data.is_lot_pool_managed = match[0].is_lot_pool_managed;
            element.data.is_serial_tracked = match[0].is_serial_tracked;
            element.data.min_order_quantity = match[0].minimum_order_quantity;
            element.data.is_consigned = match[0].is_consigned;
            this.countSheetItemHelper.setRecallWarning(element, this.dataSource.countSheet, this.dataSource.itemSettings, this.dataSource.itemCombinations, this.dataSource.lotPoolManagedItems, this.dataSource.recallOracleService);
            this.countSheetItemHelper.setExpirationWarning(element, this.dataSource.sapQuantities);
            this.updateCountSheetItem(element, secondaryElement, function () { return _this.refreshDataSource(); });
        }
    };
    KitInstanceCountSheetComponent.prototype.getRedspotQuantities = function (auditId, itemInstanceId) {
        return this._redspotInventoryImportService.getInventory(auditId).pipe(map(function (data) { return data.filter(function (rq) { return rq.kit_instance_id === itemInstanceId; }); }));
    };
    KitInstanceCountSheetComponent.prototype.getConsignmentQuantities = function (auditId) {
        return this._consignmentInventoryImportService.getInventory(auditId, true);
    };
    KitInstanceCountSheetComponent.prototype.getItemCombinations = function () {
        return this._itemCombinationService.get();
    };
    KitInstanceCountSheetComponent.prototype.getItems = function () {
        return this._itemService.getItems();
    };
    KitInstanceCountSheetComponent.prototype.getLotPoolManagedItems = function () {
        return this._lotPoolManagedItemService.getLotPoolManagedItems();
    };
    KitInstanceCountSheetComponent.prototype.getRootKitDefinitionItems = function (kitId) {
        return this._kitDefinitionItemService.getKitDefinitionItems().pipe(map(function (data) { return data.filter(function (kdi) { return kdi.kit_definition_id === kitId; }); }));
    };
    KitInstanceCountSheetComponent.prototype.getKits = function () {
        var _this = this;
        this._kitService.getKits().subscribe(function (data) {
            _this.kits = data;
        });
    };
    KitInstanceCountSheetComponent.prototype.getItemSettingsByAudit = function (auditId) {
        return this._itemSettingService.getItemSettingsByAudit(auditId);
    };
    KitInstanceCountSheetComponent.prototype.getExclusions = function (auditId) {
        return this._exclusionService.getExclusions(auditId);
    };
    KitInstanceCountSheetComponent.prototype.getWarehouses = function (auditId, warehouseId) {
        var _this = this;
        this._auditService.getWarehouses(auditId).subscribe(function (data) {
            _this.warehouses = data;
            _this.dropdownWarehouseId = warehouseId;
        });
    };
    KitInstanceCountSheetComponent.prototype.getCountSheet = function (online, countSheetClientId) {
        var _this = this;
        return new Promise(function (resolve) {
            var that = _this;
            if (online) {
                _this._countSheetService.getCountSheet(countSheetClientId).subscribe(function (data) {
                    that.createdBy = data.created_by;
                    that.location = data.audit_location;
                    var newCountSheet = that._countSheetDatabaseService.new(data);
                    that._countSheetDatabaseService.update(newCountSheet).then(function (_) {
                        resolve(newCountSheet);
                    });
                });
            }
            else {
                _this._countSheetDatabaseService.get(countSheetClientId).then(function (cs) {
                    resolve(cs);
                });
            }
        });
    };
    KitInstanceCountSheetComponent.prototype.getCountSheetItems = function (auditId, countSheetClientId) {
        return this._countSheetItemService.getCountSheetItems(auditId, { count_sheet_client_id: countSheetClientId });
    };
    KitInstanceCountSheetComponent.prototype.isMinOrderQuantityGreaterThanOne = function (countSheetItem) {
        var minOrderQuantity = countSheetItem.data.min_order_quantity;
        if (minOrderQuantity > 1) {
            return true;
        }
        return false;
    };
    // todo: merge these two getcountsheetitem methods
    KitInstanceCountSheetComponent.prototype.getCountSheetItemsInAnyState = function (online, auditId, countSheet) {
        var _this = this;
        var that = this;
        return new Promise(function (resolve) {
            if (online) {
                _this._countSheetItemService.getCountSheetItems(auditId, { count_sheet_client_id: countSheet.id }).subscribe(function (data) {
                    that._countSheetItemDatabaseService.bulkUpdate(data.map(function (cs) { return that._countSheetItemDatabaseService.new(cs); })).then(function (_) {
                        that._countSheetItemDatabaseService.find(auditId, { count_sheet_client_id: countSheet.id, _destroy: false }).then(function (matches) {
                            resolve(matches);
                        });
                    });
                });
            }
            else {
                that._countSheetItemDatabaseService.find(auditId, { count_sheet_client_id: countSheet.id, _destroy: false }).then(function (existingCountSheetItems) {
                    //we must have already cached these into indexeddb
                    if (existingCountSheetItems.length > 0) {
                        return resolve(existingCountSheetItems);
                    }
                    //get the data from the cached serviceworker
                    that._countSheetItemService.getCountSheetItems(auditId).subscribe(function (auditCountSheetItems) {
                        var matches = auditCountSheetItems.filter(function (cs) { return cs.count_sheet_client_id === countSheet.id; });
                        that._countSheetItemService.refreshOfflineDataForCountSheet(auditId, countSheet.id, matches).then(function (csis) {
                            resolve(csis);
                        });
                    });
                });
            }
        });
    };
    KitInstanceCountSheetComponent.prototype.getKitInstance = function (countSheet) {
        return this._auditKitInstanceDatabaseService.get(countSheet.data.audit_kit_instance_id);
    };
    /**
     * Complete count sheet
     */
    KitInstanceCountSheetComponent.prototype.completeCountSheet = function () {
        var _this = this;
        var countedMetadata = {
            counted_by_id: this.userData.user_id,
            counted_time: moment().toDate()
        };
        var countedStatus = 3;
        var result = this.areAllCountSheetItemsChecked();
        if (!result.allChecked) {
            this.snackBarConfig.message = 'Report the quantity of all unchecked items';
            this.snackBarConfig.success = false;
            this.openSnackBar();
            return;
        }
        this.onlineSubscription = this._onlineService.isOnlineSubscription().subscribe(function (online) {
            if (online && !_this._onlineService.getTestOfflineBool()) {
                // set status to counted
                _this._countSheetService.updateCountSheetStatus(_this.dataSource.countSheet.dbId, countedStatus, countedMetadata).subscribe(function (data) {
                    if (data.success) {
                        _this._countSheetDatabaseService.update(_this._countSheetDatabaseService.new(data.count_sheet)).then(function (_) {
                            _this.snackBarConfig.message = 'Count Sheet Complete';
                            _this.snackBarConfig.success = true;
                            _this.openSnackBar();
                            _this.goToCountSheets();
                            _this.removeRankDataFromLocalStorage();
                        });
                    }
                    else {
                        _this.snackBarConfig.message = 'Failed';
                        _this.snackBarConfig.success = false;
                        _this.openSnackBar();
                    }
                });
            }
            else {
                _this.dataSource.countSheet.data.count_sheet_status = countedStatus;
                _this.dataSource.countSheet.data.counted_by = _this.userData;
                _this.dataSource.countSheet.data.counted_by_id = _this.userData.user_id;
                _this.dataSource.countSheet.data.counted_time = moment().format('lll');
                _this.dataSource.countSheet.isSynchronized = 0;
                _this._countSheetDatabaseService.update(_this.dataSource.countSheet).then(function (_) {
                    _this.snackBarConfig.message = 'Count Sheet Complete';
                    _this.snackBarConfig.success = true;
                    _this.openSnackBar();
                    _this.goToCountSheets();
                    _this.removeRankDataFromLocalStorage();
                });
            }
        });
        this.onlineSubscription.unsubscribe();
    };
    KitInstanceCountSheetComponent.prototype.reopenCountSheet = function () {
        var _this = this;
        var countedMetadata = {
            counted_by_id: this.userData.user_id,
            counted_time: moment().toDate()
        };
        var countedStatus = 3;
        this.onlineSubscription = this._onlineService.isOnlineSubscription().subscribe(function (online) {
            if (online && !_this._onlineService.getTestOfflineBool()) {
                // set status to counted
                _this._countSheetService.updateCountSheetStatus(_this.dataSource.countSheet.dbId, countedStatus, countedMetadata).subscribe(function (data) {
                    if (data.success) {
                        _this._countSheetDatabaseService.update(_this._countSheetDatabaseService.new(data.count_sheet)).then(function (_) {
                            _this.snackBarConfig.message = 'Reopening Countsheet!';
                            _this.snackBarConfig.success = true;
                            _this.openSnackBar();
                            _this.reloadPage(false);
                        });
                    }
                    else {
                        _this.snackBarConfig.message = 'Failed';
                        _this.snackBarConfig.success = false;
                        _this.openSnackBar();
                    }
                });
            }
            else {
                _this.dataSource.countSheet.data.count_sheet_status = countedStatus;
                _this.dataSource.countSheet.data.counted_by = _this.userData;
                _this.dataSource.countSheet.data.counted_time = moment().format('lll');
                _this.dataSource.countSheet.isSynchronized = 0;
                _this._countSheetDatabaseService.update(_this.dataSource.countSheet).then(function (_) {
                    _this.snackBarConfig.message = 'Reopening Countsheet!';
                    _this.snackBarConfig.success = true;
                    _this.openSnackBar();
                    _this.reloadPage(false);
                });
            }
        });
        this.onlineSubscription.unsubscribe();
    };
    KitInstanceCountSheetComponent.prototype.printAndComplete = function () {
        var that = this;
        var result = this.areAllCountSheetItemsChecked();
        if (!result.allChecked) {
            that.snackBarConfig.message = 'Report the quantity of all unchecked items';
            that.snackBarConfig.success = false;
            that.openSnackBar();
            return;
        }
        new Promise(function (resolve, reject) {
            if (that.dataSource.countSheet.data.attachments_count > 0) {
                return resolve(null);
            }
            var dialogRef = that._dialog.open(ConfirmationDialogComponent, {
                width: '30%',
                data: Messages.finishWithoutAttachment,
            });
            dialogRef.afterClosed().subscribe(function (answer) {
                if (answer) {
                    return resolve(null);
                }
                reject();
            });
        }).then(function () {
            var countSheet = that.dataSource.countSheet;
            countSheet.data.count_sheet_status = 3;
            countSheet.data.status_text = 'Complete';
            that.dataSource.countSheet = countSheet;
            that.printer.print();
            that._databaseService.add('user_actions', {
                audit_id: that.auditId.toString(),
                auditor: that.userData.first_name + " " + that.userData.last_name,
                timestamp: "" + (new Date()).toUTCString(),
                area: '',
                kit_reference: "" + that.dataSource.countSheet.data.reference,
                kit_lot_number: "" + that.dataSource.countSheet.data.lot_number,
                action: 'Finished count sheet',
                total_items_counted: that.dataSource.countSheet.data.total_items_counted,
                site_id: that.dataSource.countSheet.data.audit_location_id,
                warehouse_id: that.dataSource.countSheet.data.warehouse_id
            });
            that.completeCountSheet();
        }, function () {
            console.log('they didnt want to finish without an attachment');
        });
    };
    KitInstanceCountSheetComponent.prototype.cancelCounting = function () {
        var _this = this;
        var dialogRef = this._dialog.open(ConfirmationDialogComponent, {
            width: '30%',
            data: 'Are you sure you want to cancel counting?',
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this._databaseService.add('user_actions', {
                    audit_id: _this.auditId.toString(),
                    auditor: _this.userData.first_name + " " + _this.userData.last_name,
                    timestamp: "" + (new Date()).toUTCString(),
                    area: '',
                    kit_reference: "" + _this.dataSource.countSheet.data.reference,
                    kit_lot_number: "" + _this.dataSource.countSheet.data.lot_number,
                    action: 'Canceled count sheet',
                    total_items_counted: _this.dataSource.countSheet.data.total_items_counted,
                    site_id: _this.dataSource.countSheet.data.audit_location_id,
                    warehouse_id: _this.dataSource.countSheet.data.warehouse_id
                });
                _this.dataSource.countSheet.data.archived = true;
                _this.dataSource.countSheet.isSynchronized = 0;
                _this._countSheetDatabaseService.update(_this.dataSource.countSheet).then(function () {
                    _this._countSheetDatabaseService.archiveCountSheetItems(_this.dataSource.countSheet).then(function () {
                        _this.goToCountSheets();
                        _this.removeRankDataFromLocalStorage();
                    });
                });
            }
        });
    };
    KitInstanceCountSheetComponent.prototype.clearLocal = function () {
        var _this = this;
        var dialogRef = this._dialog.open(ConfirmationDialogComponent, {
            width: '30%',
            data: 'Are you sure you want to clear this count?',
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this._databaseService.add('user_actions', {
                    audit_id: _this.auditId.toString(),
                    auditor: _this.userData.first_name + " " + _this.userData.last_name,
                    timestamp: "" + (new Date()).toUTCString(),
                    area: '',
                    kit_reference: "" + _this.dataSource.countSheet.data.reference,
                    kit_lot_number: "" + _this.dataSource.countSheet.data.lot_number,
                    action: 'Clear Current Count',
                    total_items_counted: _this.dataSource.countSheet.data.total_items_counted,
                    site_id: _this.dataSource.countSheet.data.audit_location_id,
                    warehouse_id: _this.dataSource.countSheet.data.warehouse_id
                });
                _this.dataSource.countSheet.isSynchronized = 0;
                _this._countSheetDatabaseService.update(_this.dataSource.countSheet).then(function () {
                    _this._countSheetDatabaseService.clearUnsyncedItems(_this.dataSource.countSheet).then(function () {
                        _this.goToCountSheets();
                        _this.removeRankDataFromLocalStorage();
                    });
                });
            }
        });
    };
    KitInstanceCountSheetComponent.prototype.areAllCountSheetItemsChecked = function () {
        this.dataSource.filters = { orderType: 'consigned', exclusions: 'hide' };
        this.refreshDataSource();
        var data = this.dataSource.data;
        var result = { allChecked: true, uncheckedElements: [] };
        var that = this;
        data.forEach(function (element) {
            var checked = that.anyChecked(element);
            result.allChecked = result.allChecked && checked;
            if (!checked) {
                element.data.verification_status = 'NEEDED';
                result.uncheckedElements.push(element);
            }
        });
        return result;
    };
    KitInstanceCountSheetComponent.prototype.goToCountSheets = function () {
        if (this.previousUrl.indexOf('siteID') !== -1) {
            this._router.navigate([this.removeQueryStrings(this.previousUrl)], { queryParams: { status: this.status, orderType: 'all', siteID: this.location.id } });
        }
        else {
            if (this.viewOnly) {
                this._router.navigate([this.previousUrl], { queryParams: { status: this.status, orderType: 'consigned' } });
            }
            else {
                this._router.navigate([this.previousUrl], { queryParams: { status: this.status, orderType: 'consigned' } });
            }
        }
    };
    KitInstanceCountSheetComponent.prototype.removeQueryStrings = function (url) {
        var res = '';
        var qsStart = url.indexOf('?');
        if (qsStart !== -1) {
            res = url.substr(0, qsStart);
        }
        else {
            res = url;
        }
        return res;
    };
    KitInstanceCountSheetComponent.prototype.goToAuditDashboard = function () {
        this._router.navigate(['audit_dashboard', this.auditId]);
    };
    KitInstanceCountSheetComponent.prototype.updateBom = function (value) {
        var _this = this;
        return new Promise(function (resolve) {
            value = value || 'null'; //so we have a set value, but it will get set to nil in the backend
            _this.dataSource.countSheet.data.kit_id = value;
            _this.dataSource.countSheet.isSynchronized = 0;
            _this._countSheetDatabaseService.update(_this.dataSource.countSheet).then(function (_) {
                resolve(null);
            });
        });
    };
    KitInstanceCountSheetComponent.prototype.exclusionChanged = function (event) {
        var filters = this.dataSource.filters;
        filters['exclusions'] = event.value;
        this.dataSource.filters = filters;
        this.refreshDataSource();
    };
    KitInstanceCountSheetComponent.prototype.bomChanged = function (event) {
        var _this = this;
        this._trackLoading.startLoading('bom-changed', 'Updating BOM');
        this.updateBom(+event.value).then(function () {
            _this.getRootKitDefinitionItems(+event.value).subscribe(function (kdis) {
                _this.dataSource.rootKitDefinitionItems = kdis;
                _this.refreshDataSource();
                _this._trackLoading.stopLoading('bom-changed');
            });
        });
    };
    KitInstanceCountSheetComponent.prototype.viewChanged = function (event) {
        var _this = this;
        this._trackLoading.startLoading('view-changed', 'Updating View');
        var value = [1, '1', 'true', true].includes(event.value);
        this.dataSource.countSheet.isSynchronized = 0;
        this.dataSource.countSheet.data.show_expected_item_list = value;
        var that = this;
        this._countSheetDatabaseService.update(this.dataSource.countSheet).then(function (_) {
            zip(that._countSheetDatabaseService.get(that.dataSource.countSheet.id), that._countSheetItemDatabaseService.find(that.auditId, { count_sheet_client_id: that.dataSource.countSheet.id, _destroy: false })).subscribe(function (data) {
                that.dataSource.countSheet = data[0];
                that.dataSource.countSheetItems = data[1];
                _this.refreshDataSource();
                _this._trackLoading.stopLoading('view-changed');
            });
        });
    };
    KitInstanceCountSheetComponent.prototype.warehouseChanged = function (event) {
        var warehouse = this.warehouses.find(function (w) {
            return w.account_id === event.value;
        });
        this.dataSource.countSheet.data.warehouse_id = warehouse.id;
        this.dataSource.countSheet.data.warehouse_name = warehouse.name;
        this.dataSource.countSheet.isSynchronized = 0;
        this._countSheetDatabaseService.update(this.dataSource.countSheet);
        this.dropdownWarehouseId = warehouse.id;
    };
    KitInstanceCountSheetComponent.prototype.showSnackbar = function (message, success) {
        this.snackBarConfig.message = message;
        this.snackBarConfig.success = success;
        this.openSnackBar();
    };
    KitInstanceCountSheetComponent.prototype.reloadWhenBackOnline = function () {
        this.snackBarConfig.message = 'Connected!! Reloading...';
        this.snackBarConfig.success = true;
        this.openSnackBar();
        this._router.navigate([this.auditId, 'kit_instance_count_sheet', this._route.snapshot.paramMap.get('id'), this.viewOnly], { queryParams: { status: this.status } });
    };
    KitInstanceCountSheetComponent.prototype.reloadPage = function (isSynchronized) {
        this._router.navigate([this.auditId, 'kit_instance_count_sheet', this._route.snapshot.paramMap.get('id'), false], { queryParams: { status: this.status } });
    };
    KitInstanceCountSheetComponent.prototype.openSnackBar = function () {
        this._snackBar.openFromComponent(MaterialSnackbarComponent, {
            data: this.snackBarConfig,
            duration: this.snackBarConfig.duration
        });
    };
    KitInstanceCountSheetComponent.prototype.openWarningDialog = function (message) {
        this.countSheetItemHelper.playErrorSound(this._audioPlayerService.setting);
        var dialogRef = this._dialog.open(WarningDialogComponent, {
            width: '50%',
            disableClose: true,
            role: 'alertdialog',
            data: {
                message: message
            },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
            }
        });
    };
    KitInstanceCountSheetComponent.prototype.setAuditedByValue = function (countSheetItems) {
        if (countSheetItems) {
            var names = countSheetItems.map(function (csi) { return csi.data.modified_by; }).filter(function (v, i, a) { return a.indexOf(v) === i; });
            this.auditedBy = names.join(' / ');
        }
        else {
            this.auditedBy = '';
        }
    };
    KitInstanceCountSheetComponent.prototype.setCountSheetStats = function () {
        var items = this.dataSource.countSheetItems;
        if (items.length > 0) {
            switch (this.dataSource.filters.orderType) {
                case 'consigned':
                    if (this.dataSource.filters.exclusions === 'hide') {
                        this.actualItemCount = items.filter(function (cs) { return cs.data.checked && cs.data.is_consigned && !cs.data.is_excluded; }).reduce(function (a, b) { return a + b.data.quantity; }, 0);
                    }
                    else {
                        this.actualItemCount = items.filter(function (cs) { return cs.data.checked && cs.data.is_consigned; }).reduce(function (a, b) { return a + b.data.quantity; }, 0);
                    }
                    break;
                case 'owned':
                    if (this.dataSource.filters.exclusions === 'hide') {
                        this.actualItemCount = items.filter(function (csi) { return csi.data.is_consigned === false && !csi.data.is_excluded; }).map(function (csi) { return csi.data.quantity; }).reduce(function (a, b) { return a + b; });
                    }
                    else {
                        this.actualItemCount = items.filter(function (csi) { return csi.data.is_consigned === false; }).map(function (csi) { return csi.data.quantity; }).reduce(function (a, b) { return a + b; });
                    }
                    break;
                default:
                    if (this.dataSource.filters.exclusions === 'hide') {
                        this.actualItemCount = items.filter(function (csi) { return !csi.data.is_excluded; }).map(function (csi) { return csi.data.quantity; }).reduce(function (a, b) { return a + b; });
                    }
                    else {
                        this.actualItemCount = items.map(function (csi) { return csi.data.quantity; }).reduce(function (a, b) { return a + b; });
                    }
            }
        }
        else {
            this.actualItemCount = 0;
        }
        this.expectedItemCount = this.kitInstance.data.item_count;
        this.statsComplete = true;
    };
    KitInstanceCountSheetComponent.prototype.getColor = function (current, max) {
        var color = 'orange';
        var percentage = (current / max) * 100;
        if (percentage >= 100) {
            color = 'green';
        }
        else if (!percentage) {
            color = '#eaeaea';
        }
        return color;
    };
    KitInstanceCountSheetComponent.prototype.continueInTable = function (event, element, callback) {
        var lastVisible = this.virtualScroller.viewPortItems.indexOf(element) === this.virtualScroller.viewPortItems.length - 1;
        var notLast = this.dataSource.data.indexOf(element) !== this.dataSource.data.length - 1;
        event.preventDefault();
        if (lastVisible && notLast) {
            var lastAvailablePosition = this.dataSource.data.length - 1;
            var wantedPosition = this.dataSource.data.indexOf(element) + Math.ceil(this.virtualScroller.viewPortItems.length / 2) + 1;
            this.virtualScroller.scrollToIndex(Math.min(lastAvailablePosition, wantedPosition), false, 0, undefined, callback);
        }
        else {
            callback();
        }
    };
    KitInstanceCountSheetComponent.prototype.keyDown = function (event, element) {
        if (event.code === 'Space') {
            event.preventDefault();
            var el_1 = event.srcElement;
            if (el_1.classList.contains('mat-checkbox-input')) {
                if (element.count_sheet_items) {
                    this.lotPoolManagedCheckedClicked(event, element);
                }
                else {
                    this.checkedClicked(event, element);
                }
                this.continueInTable(event, element, function () {
                    while (el_1.nodeName !== 'TR') {
                        el_1 = el_1.parentElement;
                    }
                    var node = el_1.nextElementSibling;
                    if (node) {
                        var input_1 = node.querySelector('input[type="number"]');
                        if (input_1) {
                            setTimeout(function () {
                                input_1.focus();
                                input_1.select();
                            }, 300);
                        }
                    }
                });
            }
        }
        else if (event.code === 'Tab') {
            var el_2 = event.srcElement;
            //currently viewing the last item
            //we don't want to move down while they aren't done with the row
            if (el_2.classList.contains('mat-checkbox-input')) {
                this.continueInTable(event, element, function () {
                    while (el_2.nodeName !== 'TR') {
                        el_2 = el_2.parentElement;
                    }
                    var node = el_2.nextElementSibling;
                    if (node) {
                        var input_2 = node.querySelector('input[type="number"]');
                        if (input_2) {
                            setTimeout(function () {
                                input_2.focus();
                                input_2.select();
                            }, 300);
                        }
                    }
                });
            }
        }
        else {
            if (!this.positiveIntegerHelper.isNumeric(event)) {
                event.preventDefault();
            }
        }
    };
    KitInstanceCountSheetComponent.prototype.anyUnsynched = function (row) {
        return (row.count_sheet_items || [row]).filter(function (r) { return !r.isSynchronized; }).length > 0;
    };
    KitInstanceCountSheetComponent.prototype.anyChecked = function (row) {
        return (row.count_sheet_items || [row]).filter(function (r) { return r.data.checked; }).length > 0;
    };
    KitInstanceCountSheetComponent.prototype.isLotPoolManaged = function (row) {
        return this.countSheetItemHelper.getIsLotPoolManaged(row.data.item_id, this.dataSource.lotPoolManagedItems);
    };
    KitInstanceCountSheetComponent.prototype.lotPoolManagedQuantity = function (row) {
        if (row.count_sheet_items.length > 0) {
            var checkedCountSheetItems = row.count_sheet_items.filter(function (csi) { return csi.data.checked; });
            if (checkedCountSheetItems.length > 0) {
                return checkedCountSheetItems.reduce(function (a, b) { return a + b.data.quantity; }, 0);
            }
            return row.count_sheet_items.reduce(function (a, b) { return a + b.data.quantity; }, 0);
        }
        return row.data.expected_quantity;
    };
    KitInstanceCountSheetComponent.prototype.openCountSheetItemImportDialog = function () {
        var _this = this;
        var dialogRef = this._dialog.open(ImportCountSheetItemComponent, {
            width: '30%',
            data: {
                countSheet: this.dataSource.countSheet,
                lotPoolManagedItems: this.dataSource.lotPoolManagedItems,
                redspotItems: this.dataSource.redspotQuantities,
                sapItems: this.dataSource.sapQuantities
            }
        });
        var that = this;
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.getCountSheetItems(_this.auditId, _this.dataSource.countSheet.id).subscribe(function (countSheetItems) {
                    zip.apply(void 0, countSheetItems.map(function (csi) { return that._countSheetItemDatabaseService.update(that._countSheetItemDatabaseService.new(csi)); }).concat(new Promise(function (r) { r(null); }))).subscribe(function (_) {
                        zip(that._countSheetDatabaseService.get(that.dataSource.countSheet.id), that._countSheetItemDatabaseService.find(that.auditId, { count_sheet_client_id: that.dataSource.countSheet.id, _destroy: false })).subscribe(function (data) {
                            _this.dataSource.countSheet = data[0];
                            _this.dataSource.countSheetItems = data[1];
                            _this.refreshDataSource();
                        });
                    });
                });
            }
        });
    };
    KitInstanceCountSheetComponent.prototype.scrollToBottom = function () {
        document.getElementById('bottom').scrollIntoView();
        this.virtualScroller.scrollToIndex(this.dataSource.data.length - 1);
    };
    KitInstanceCountSheetComponent.prototype.exportCountsheetItems = function () {
        this.refreshDataSource();
        var exportOptions = { kit: true, blank: !this.dataSource.countSheet.data.show_expected_item_list };
        var exportHelper = new CountSheetExportHelper(this._excelService);
        exportHelper.exportCountsheetItemsToExcel(this.dataSource.data, exportOptions);
    };
    KitInstanceCountSheetComponent.prototype.attachFile = function () {
        this.openAttachmentDialog();
    };
    KitInstanceCountSheetComponent.prototype.openAttachmentDialog = function () {
        var that = this;
        var dialogRef = that._dialog.open(AttachmentDialogComponent, {
            width: '70%',
            data: {
                countsheet: that.dataSource.countSheet,
                devices: that.cameras
            }
        });
        dialogRef.afterClosed().subscribe(function () {
            that._countSheetDatabaseService.get(that.dataSource.countSheet.id).then(function (cs) {
                that.dataSource.countSheet = cs;
            });
        });
    };
    KitInstanceCountSheetComponent.prototype.removeRankDataFromLocalStorage = function () {
        var keys = this.dataSource.redspotInventoryIds;
        keys.forEach(function (k) {
            localStorage.removeItem(k);
        });
    };
    return KitInstanceCountSheetComponent;
}());
export { KitInstanceCountSheetComponent };
