var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DataSource } from '@angular/cdk/collections';
import { map } from 'rxjs/operators';
import { of as observableOf, merge } from 'rxjs';
/**
 * Data source for the WarehouseUnkittedShortages view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
var WarehouseShortagesDataSource = /** @class */ (function (_super) {
    __extends(WarehouseShortagesDataSource, _super);
    function WarehouseShortagesDataSource(paginator, sort, shortages, filters) {
        var _this = _super.call(this) || this;
        _this.paginator = paginator;
        _this.sort = sort;
        _this.shortages = shortages;
        _this.filters = filters;
        _this.data = shortages;
        return _this;
    }
    /**
     * Connect this data source to the table. The table will only update when
     * the returned stream emits new items.
     * @returns A stream of the items to be rendered.
     */
    WarehouseShortagesDataSource.prototype.connect = function () {
        var _this = this;
        // Combine everything that affects the rendered data into one update
        // stream for the data-table to consume.
        var dataMutations = [
            observableOf(this.data),
            this.paginator.page,
            this.sort.sortChange
        ];
        // Set the paginators length
        this.paginator.length = this.data.length;
        return merge.apply(void 0, dataMutations).pipe(map(function () {
            var filteredData = _this.getPagedData(_this.getSortedData(_this.getFilteredData(_this.data.slice())));
            return filteredData;
        }));
    };
    /**
     *  Called when the table is being destroyed. Use this function, to clean up
     * any open connections or free any held resources that were set up during connect.
     */
    WarehouseShortagesDataSource.prototype.disconnect = function () { };
    /**
     * Paginate the data (client-side). If you're using server-side pagination,
     * this would be replaced by requesting the appropriate data from the server.
     */
    WarehouseShortagesDataSource.prototype.getPagedData = function (data) {
        var startIndex = this.paginator.pageIndex * this.paginator.pageSize;
        return data.splice(startIndex, this.paginator.pageSize);
    };
    /**
     * Sort the data (client-side). If you're using server-side sorting,
     * this would be replaced by requesting the appropriate data from the server.
     */
    WarehouseShortagesDataSource.prototype.getSortedData = function (data) {
        var _this = this;
        if (!this.sort.active || this.sort.direction === '') {
            return data;
        }
        return data.sort(function (a, b) {
            var isAsc = _this.sort.direction === 'asc';
            switch (_this.sort.active) {
                case 'reference': return compare(a.item.reference, b.item.reference, isAsc);
                case 'description': return compare(a.item.description, b.item.description, isAsc);
                case 'kit': return compare(a.kit_reference, b.kit_reference, isAsc);
                case 'kit_lot_number': return compare(a.kit_lot_number, b.kit_lot_number, isAsc);
                case 'lot': return compare(a.lot_number, b.lot_number, isAsc);
                case 'serial_number': return compare(a.serial_number, b.serial_number, isAsc);
                case 'actual': return compare(a.variance, b.variance, isAsc);
                default: return 0;
            }
        });
    };
    WarehouseShortagesDataSource.prototype.filteredData = function () {
        var filteredData = this.getPagedData(this.getSortedData(this.getFilteredData(this.data.slice())));
        return filteredData;
    };
    WarehouseShortagesDataSource.prototype.getFilteredData = function (data) {
        var _this = this;
        var filteredData = data.filter(function (el) {
            var condition = true;
            if (_this.filters.kitSearchString) {
                condition = el.item.reference.toLowerCase().includes(_this.filters.kitSearchString.toLowerCase()) ||
                    el.item.unpunctuated_reference.toLowerCase().includes(_this.filters.kitSearchString.toLowerCase()) ||
                    el.kit_reference.toLowerCase().includes(_this.filters.kitSearchString.toLowerCase());
            }
            return condition;
        });
        this.paginator.length = filteredData.length;
        return filteredData;
    };
    return WarehouseShortagesDataSource;
}(DataSource));
export { WarehouseShortagesDataSource };
/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
