var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DataSource } from '@angular/cdk/collections';
import { of as observableOf, merge } from 'rxjs';
/**
 * Data source for the UnkittedItemsCountsheet view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
var UnkittedItemsCountsheetDataSource = /** @class */ (function (_super) {
    __extends(UnkittedItemsCountsheetDataSource, _super);
    function UnkittedItemsCountsheetDataSource(countSheetItemHelper, _countSheetItemDatabaseService) {
        var _this = _super.call(this) || this;
        _this.countSheetItemHelper = countSheetItemHelper;
        _this._countSheetItemDatabaseService = _countSheetItemDatabaseService;
        _this.data = [];
        return _this;
    }
    Object.defineProperty(UnkittedItemsCountsheetDataSource.prototype, "recallOracleService", {
        get: function () {
            return this._recallOracleService;
        },
        set: function (value) {
            this._recallOracleService = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UnkittedItemsCountsheetDataSource.prototype, "lotPoolManagedItems", {
        get: function () {
            return this._lotPoolManagedItems;
        },
        set: function (value) {
            this._lotPoolManagedItems = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UnkittedItemsCountsheetDataSource.prototype, "exclusions", {
        get: function () {
            return this._exclusions;
        },
        set: function (value) {
            this._exclusions = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UnkittedItemsCountsheetDataSource.prototype, "itemCombinations", {
        get: function () {
            return this._itemCombinations;
        },
        set: function (value) {
            this._itemCombinations = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UnkittedItemsCountsheetDataSource.prototype, "itemSettings", {
        get: function () {
            return this._itemSettings;
        },
        set: function (value) {
            this._itemSettings = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UnkittedItemsCountsheetDataSource.prototype, "rootKitDefinitionItems", {
        get: function () {
            return this._rootKitDefinitionItems;
        },
        set: function (value) {
            this._rootKitDefinitionItems = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UnkittedItemsCountsheetDataSource.prototype, "sapQuantities", {
        get: function () {
            return this._sapQuantities;
        },
        set: function (value) {
            this._sapQuantities = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UnkittedItemsCountsheetDataSource.prototype, "redspotQuantities", {
        get: function () {
            return this._redspotQuantities;
        },
        set: function (value) {
            this._redspotQuantities = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UnkittedItemsCountsheetDataSource.prototype, "countSheet", {
        get: function () {
            return this._countSheet;
        },
        set: function (value) {
            this._countSheet = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UnkittedItemsCountsheetDataSource.prototype, "countSheetItems", {
        get: function () {
            return this._countSheetItems;
        },
        set: function (value) {
            this._countSheetItems = value;
        },
        enumerable: true,
        configurable: true
    });
    UnkittedItemsCountsheetDataSource.prototype.refresh = function () {
        var now = new Date();
        var that = this;
        var countSheetItems = this.countSheetItems.slice().filter(function (csi) { return !csi._destroy; });
        if (this.countSheet.data.show_expected_item_list) {
            var lotPoolManagedCountSheetItems = countSheetItems.filter(function (csi) { return that.countSheetItemHelper.getIsLotPoolManaged(csi.data.item_id, that.lotPoolManagedItems); });
            var notLotPoolManagedCountSheetItems_1 = countSheetItems.filter(function (csi) { return !that.countSheetItemHelper.getIsLotPoolManaged(csi.data.item_id, that.lotPoolManagedItems); });
            var lotPoolManagedCountSheetItemGroup_1 = [];
            lotPoolManagedCountSheetItems.filter(function (csi) { return !csi.data.hide; }).forEach(function (csi) {
                var match = lotPoolManagedCountSheetItemGroup_1.find(function (csig) {
                    return csig.data.item_id === csi.data.item_id;
                });
                if (!match) {
                    match = {
                        count_sheet_items: [],
                        available_redspot_inventory: [],
                        data: {
                            item_id: csi.data.item_id,
                            reference: csi.data.reference,
                            description: csi.data.description,
                            is_consigned: csi.data.is_consigned,
                            expected_quantity: 0,
                            quantity: 0
                        }
                    };
                    lotPoolManagedCountSheetItemGroup_1.push(match);
                }
                match.count_sheet_items.push(csi);
                match.data.quantity += csi.data.quantity;
            });
            this.rootKitDefinitionItems.forEach(function (rkdi) {
                if (!that.countSheetItemHelper.getIsLotPoolManaged(rkdi.item.id, that.lotPoolManagedItems)) {
                    if (!notLotPoolManagedCountSheetItems_1.find(function (d) { return d.data.item_id === rkdi.item_id; })) {
                        var row = {
                            id: 0,
                            dbId: 0,
                            data: {
                                reference: rkdi.item.reference,
                                item_id: rkdi.item.id,
                                description: rkdi.item.description,
                                count_sheet_id: that.countSheet.dbId,
                                count_sheet_client_id: that.countSheet.id,
                                audit_id: that.countSheet.data.audit_id,
                                is_consigned: rkdi.item.is_consigned,
                                min_order_quantity: rkdi.item.minimum_order_quantity,
                                is_lot_tracked: rkdi.item.is_lot_number_tracked,
                                is_serial_tracked: rkdi.item.is_serial_tracked,
                                checked: false,
                                lot_number: '',
                                serial: '',
                                quantity: null,
                                expiration_date: null,
                                redspot_expiration_date: null,
                                isKitted: false
                            }
                        };
                        notLotPoolManagedCountSheetItems_1.push(row);
                    }
                }
                else if (that.countSheetItemHelper.getIsLotPoolManaged(rkdi.item.id, that.lotPoolManagedItems)) {
                    if (!lotPoolManagedCountSheetItemGroup_1.find(function (d) { return d.data.item_id === rkdi.item_id; })) {
                        var row = {
                            count_sheet_items: [],
                            available_redspot_inventory: [],
                            data: {
                                item_id: rkdi.item.id,
                                reference: rkdi.item.reference,
                                description: rkdi.item.description,
                                is_consigned: rkdi.item.is_consigned,
                                expected_quantity: 0,
                                quantity: 0
                            }
                        };
                        lotPoolManagedCountSheetItemGroup_1.push(row);
                    }
                }
            });
            countSheetItems = notLotPoolManagedCountSheetItems_1.concat(lotPoolManagedCountSheetItemGroup_1);
        }
        //final processing
        countSheetItems.forEach(function (kics) {
            if (kics.count_sheet_items) {
                kics.data.show_recalled_warning = that.recallOracleService.get(kics.data.item_id, kics.data.lot_number, kics.data.serial);
                kics.data.is_excluded = that.countSheetItemHelper.getExclusion(kics.data.item_id, that.exclusions);
                kics.data.in_definition = !!that.countSheetItemHelper.matchingRootKitDefinitionItem(kics.data.item_id, that.rootKitDefinitionItems);
            }
            else {
                if (kics.data.checked) {
                    kics.data.show_recalled_warning = that.recallOracleService.get(kics.data.item_id, kics.data.lot_number, kics.data.serial);
                    kics.data.show_invalid_warning = !that.countSheetItemHelper.checkIfValidItemCombination(kics.data.item_id, kics.data.lot_number, kics.data.serial, false, kics.data.is_lot_tracked, kics.data.is_serial_tracked, that.itemSettings, that.itemCombinations);
                    var rqLookupOptions = { itemId: kics.data.item_id, lotNumber: kics.data.lot_number };
                    var redspotQuantity = that.countSheetItemHelper.getRedspotQuantity(rqLookupOptions, that.redspotQuantities);
                    var sqLookupOptions = { itemId: kics.data.item_id, lotNumber: kics.data.lot_number, serialNumber: kics.data.serial };
                    var sapQuantity = that.countSheetItemHelper.getSapQuantity(sqLookupOptions, that.sapQuantities);
                    if (!kics.data.expiration_date) {
                        if (sapQuantity && sapQuantity.expiry_date) {
                            kics.data.expiration_date = sapQuantity.expiry_date;
                            kics.data.show_expired_warning = new Date(sapQuantity.expiry_date) < now;
                        }
                        else if (redspotQuantity && redspotQuantity.expiry_date) {
                            kics.data.expiration_date = redspotQuantity.expiry_date;
                            kics.data.show_expired_warning = new Date(redspotQuantity.expiry_date) < now;
                        }
                    }
                    else {
                        kics.data.show_expired_warning = new Date(kics.data.expiration_date) < now;
                    }
                }
                kics.data.is_excluded = that.countSheetItemHelper.getExclusion(kics.data.item_id, that.exclusions);
                kics.data.in_definition = !!that.countSheetItemHelper.matchingRootKitDefinitionItem(kics.data.item_id, that.rootKitDefinitionItems);
            }
        });
        var sortFunction = function (a, b) {
            var aMatch = that.countSheetItemHelper.matchingRootKitDefinitionItem(a.data.item_id, that.rootKitDefinitionItems);
            var bMatch = that.countSheetItemHelper.matchingRootKitDefinitionItem(b.data.item_id, that.rootKitDefinitionItems);
            if (aMatch && bMatch) {
                if (aMatch['position'] > bMatch['position']) {
                    return 1;
                }
                else if (aMatch['position'] < bMatch['position']) {
                    return -1;
                }
                else {
                    return 0;
                }
            }
            else if (aMatch) {
                return -1;
            }
            else if (bMatch) {
                return 1;
            }
            else {
                if (a.data.rank > b.data.rank) {
                    return 1;
                }
                else if (a.data.rank < b.data.rank) {
                    return -1;
                }
                return 0;
            }
        };
        this.data = countSheetItems.filter(function (csi) { return !csi.data.hide; }).sort(sortFunction);
    };
    /**
     * Connect this data source to the table. The table will only update when
     * the returned stream emits new items.
     * @returns A stream of the items to be rendered.
     */
    UnkittedItemsCountsheetDataSource.prototype.connect = function () {
        // Combine everything that affects the rendered data into one update
        // stream for the data-table to consume.
        var dataMutations = [
            observableOf(this.data),
        ];
        // Set the paginators length
        // this.paginator.length = this.data.length;
        return merge.apply(void 0, dataMutations);
    };
    /**
     *  Called when the table is being destroyed. Use this function, to clean up
     * any open connections or free any held resources that were set up during connect.
     */
    UnkittedItemsCountsheetDataSource.prototype.disconnect = function () { };
    return UnkittedItemsCountsheetDataSource;
}(DataSource));
export { UnkittedItemsCountsheetDataSource };
