<div class="col-md-12">
  <h1 mat-dialog-title class="full-width header-title">Add A Site</h1>
  <div mat-dialog-content>
    <div class="row">
        <div class="col-md-8">
            <mat-form-field class="full-width">
              <input matInput [(ngModel)]="data.site" placeholder="Site" id="site" name="site" required cdkFocusInitial>
            </mat-form-field>
      
            <mat-form-field class="full-width">
              <mat-select [(ngModel)]="data.warehouse_ids" class="filter-select" name="warehouse" placeholder="Warehouse"
                #warehouseSelector="ngModel" multiple>
                <mat-checkbox class="mat-option" [disableRipple]="true" (change)="data.toggle(warehouseSelector, $event, data.listOfWarehouseIDs)">
                  Select All
                </mat-checkbox>
                <mat-option *ngFor="let wh of data.warehouses" [value]="wh.account_id">
                  {{wh.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
      
          <div class="col-md-3 buttons">
            <button mat-button (click)="onNoClick()">Cancel</button>
            <button mat-button (click)="validateName(data)">Save</button>
          </div>
    </div>
  </div>
</div>
