import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { CONSTANTS } from 'src/app/helpers/constants';
import { UserDataHelper } from 'src/app/helpers/user-data-helper';
import { IAccessTokenResponse } from 'src/app/interfaces/i-access-token-response';
import { IOktaConfig } from 'src/app/interfaces/i-okta-config';
import { AuthService } from 'src/app/services/auth.service';
import { TrackLoadingService } from 'src/app/services/track-loading.service';
import { UserRoleService } from 'src/app/services/user-role.service';
import { IOktaLoginParams } from '../../interfaces/i-okta-login-params';
import { Messages } from 'src/app/helpers/messages';
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';
import { MatSnackBar } from '@angular/material';
import { ISnackBarConfig } from 'src/app/interfaces/isnack-bar-config';
import { AccessTokenService } from 'src/app/services/access-token.service';

@Component({
  selector: 'app-okta-login-response',
  templateUrl: './okta-login-response.component.html',
  styleUrls: ['./okta-login-response.component.css']
})
export class OktaLoginResponseComponent implements OnInit {

  snackBarConfig: ISnackBarConfig = {
    message: "",
    duration: 3000,
    success: false,
    snackBarClass: ""
  }
  
  userDataHelper: UserDataHelper = new UserDataHelper();
  loginParams: IOktaLoginParams = {};
  oktaConfigKey: string = CONSTANTS.localStorageKeys.OKTA_CONFIG_DATA
  verifierKey: string = CONSTANTS.localStorageKeys.VERIFIER
  isInternalKey: string = CONSTANTS.localStorageKeys.LOGIN_IS_INTERNAL
  configData: IOktaConfig;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _authService: AuthService,
    private _userRoleService: UserRoleService,
    private _permissionsService: NgxPermissionsService,
    private _trackLoadingService: TrackLoadingService,
    private _accessTokenService: AccessTokenService,
    public snackBar: MatSnackBar) {

    this.configData = JSON.parse(localStorage.getItem(this.oktaConfigKey))
    let isInternal = localStorage.getItem(this.isInternalKey) === "true"
    this._trackLoadingService.startLoading("okta-redirecting", "Redirecting...")
    this._route.queryParams.subscribe((params: IOktaLoginParams)=>{
      
      this.loginParams.grant_type = this.configData.okta_grant_type;
      this.loginParams.code = params.code;
      this.loginParams.client_id = isInternal? this.configData.audit_internal_client_id: this.configData.audit_internal_client_id
      this.loginParams.redirect_uri = this.configData.audit_login_redirect_uri;
      this.loginParams.code_verifier = localStorage.getItem(this.verifierKey);
      this.loginParams.scope = this.configData.audit_scope += " offline_access"
      this.loginParams.state = params.state;
      

      let errorParam = params.error
      let errorDescription = params.error_description

      if (errorParam || errorDescription || !params.code){
        
        this.snackBarConfig.message = errorDescription;
        this.snackBarConfig.success = false;
        this.openSnackBar();
        this._trackLoadingService.stopLoading("okta-redirecting")
        this._router.navigate([CONSTANTS.routes.LOGIN]);
        return;
      }

      this.getAccessTokenVanilla(this.loginParams);
    })
  }

  ngOnInit() {
  }

  getAccessTokenVanilla(params: IOktaLoginParams) {
    let that = this;
    let isInternal = localStorage.getItem(this.isInternalKey) === "true"
    let url = isInternal ? this.configData.arthrex_employee_base_url : this.configData.non_arthrex_employee_base_url

    var request = new XMLHttpRequest();
    request.open(CONSTANTS.http.methods.POST, `${url}${this.configData.get_access_token}`, true);
    request.setRequestHeader(CONSTANTS.http.headerLabel.CONTENT_TYPE, CONSTANTS.http.headers.contentType.URL_ENCODED);
    request.onload = function () {
      let body: IAccessTokenResponse = {};
      try {
        body = JSON.parse(request.response);
      } catch (e) { }

      if (request.status == 200) {

        that._authService.register_token(body, isInternal ? CONSTANTS.tenants.INTERNAL : CONSTANTS.tenants.EXTERNAL).subscribe((data) => {
          if (data.success) {
            let currentUser = {
              first_name: data.user.first_name,
              last_name: data.user.last_name,
              name: `${data.user.first_name} ${data.user.last_name}`,
              email_address: data.user.email_address,
              user_id: data.user.user_id
            }

            that.userDataHelper.setUserData(currentUser);
            that._accessTokenService.storeTokenData(body)
            that._authService.isLoggedIn = true;

            that._userRoleService.getCurrentUserRoles().subscribe(data => {
              localStorage.setItem(CONSTANTS.localStorageKeys.PERMISSIONS, JSON.stringify(data));
              that._permissionsService.loadPermissions(data);
              window.location.href = params.state;

            });
          }
          that._trackLoadingService.stopLoading("okta-redirecting")
        }, (error) => {
          if(error.status == 403){
            if(error.error == Messages.userNotFound){
              that.snackBarConfig.message = Messages.userNotFoundInRedspot;
              that.snackBarConfig.success = false;
              that.openSnackBar();
            }else{
              that.snackBarConfig.message = Messages.loginError;
              that.snackBarConfig.success = false;
              that.openSnackBar();
            }
          }
        })
      } else {
        that._router.navigate([CONSTANTS.routes.LOGIN]);
      }
    }
    request.onerror = function () {
    }
    var body = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    request.send(body);
  }


  openSnackBar() {
    this.snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: this.snackBarConfig,
      duration: this.snackBarConfig.duration
    })
  }

}
