import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { urls } from "../helpers/urls";
import { AuditKitInstanceService } from "../services/audit-kit-instance.service";
import { zip } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RedspotInventoryImportService {

constructor(
  private http: HttpClient,
  private _auditKitInstanceService: AuditKitInstanceService
) { }


  generate(auditId, data): Promise<any> {
    let that = this;
    return new Promise((resolve, reject) => {
      this.http.post<any>(`${environment.serverPath}${urls.redspotInventoryImport.replace(":audit_id", auditId)}/generate`, data, { observe: 'response' }).subscribe(res => {
        window.caches.keys().then(function(cacheNames) {

          let cacheOpeningPromises = [];
          const longTermCountSheetMatch = cacheNames.find(cn => cn.includes("Inventory"));
          const kitsMatch = cacheNames.find(cn => cn.includes("kits"));
          const countSheetsMatch = cacheNames.find(cn => cn.includes("countsheets"));

          if (longTermCountSheetMatch) {
            cacheOpeningPromises.push(caches.open(longTermCountSheetMatch));
          }
          else {
            cacheOpeningPromises.push(new Promise((r, a) => { r(false); }));
          }
          if (kitsMatch) {
            cacheOpeningPromises.push(caches.open(longTermCountSheetMatch));
          }
          else {
            cacheOpeningPromises.push(new Promise((r, a) => { r(false); }));
          }
          if (countSheetsMatch) {
            cacheOpeningPromises.push(caches.open(countSheetsMatch));
          }
          else {
            cacheOpeningPromises.push(new Promise((r, a) => { r(false); }));
          }
          zip(
            ...cacheOpeningPromises
          ).subscribe((openCaches: any[]) => {
            const longTermCountSheetCache = openCaches[0];
            const kitCache = openCaches[1];
            const countSheetCache = openCaches[2];

            let deletingCachePromises = [];
            if (longTermCountSheetCache) {
              deletingCachePromises.push(longTermCountSheetCache.delete(`${environment.serverPath}${urls.redspotInventoryImport.replace(":audit_id", auditId)}`, {ignoreSearch: true}));
            }
            if (kitCache) {
              deletingCachePromises.push(kitCache.delete(`${environment.serverPath}${urls.kit}`, {ignoreSearch: true}));
            }
            if (countSheetCache) {
              deletingCachePromises.push(countSheetCache.delete(`${environment.serverPath}${urls.countSheet}`, {ignoreSearch: true}));
              deletingCachePromises.push(countSheetCache.delete(`${environment.serverPath}${urls.countSheetItem}`, {ignoreSearch: true}));
            }
            zip(
              ...deletingCachePromises.concat(new Promise((r, a) => { r(); }))
            ).subscribe(_ => {
              that._auditKitInstanceService.getAuditKitInstancesAndPopulateOfflineData(auditId).then(_ => {
                resolve(res);
              });
            });
          });
        });
      });
    });
  }

  getURL(auditId) {
    return `${environment.serverPath}${urls.redspotInventoryImport.replace(":audit_id", auditId)}`;
  }

  getInventory(auditId) {
    let that = this;
    return this.http.get<any>(that.getURL(auditId));
  }
}
