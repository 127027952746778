import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-barcode-listener',
  template: ''
})
export class BarcodeListenerComponent implements OnInit {

  private reading = false;
  private data = "";
  private timeoutId;

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === "{") {
      this.reading = true;
      this.data = "";
      this.timeoutId = setTimeout(function () {
        this.reading = false;
      }, 1000);
    }
    if (this.reading) {
      this.data = `${this.data}${event.key}`;
      event.preventDefault();

      if (event.key === "}") {
        this.reading = false;
        clearTimeout(this.timeoutId);
        this.barcodeScanned.emit(this.data.slice(1,-1));
      }
    }

  }
  @Output() barcodeScanned = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {

  }



}
