<h1 mat-dialog-title class="full-width header">New Count Sheet</h1>
<div mat-dialog-content class="full-width">
  <mat-form-field class="full-width" >
      <mat-select  placeholder="Site" id="location" [(ngModel)]="data.location_id" name="location" required cdkFocusInitial>
          <mat-option *ngFor="let location of data.locations" [value]='location.id'>{{location.location_name}}
          </mat-option>
      </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="data.location_id">Save</button>
</div>