import { DatabaseService } from './database.service';
import * as moment from 'moment';
import { CountSheetStatusHelper } from '../helpers/count-sheet-status-helper';
import { zip } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./database.service";
var CountSheetDatabaseService = /** @class */ (function () {
    function CountSheetDatabaseService(_databaseService) {
        this._databaseService = _databaseService;
        this.storeName = 'count_sheets';
    }
    CountSheetDatabaseService.prototype.new = function (data) {
        var cs = {
            dbId: data.id,
            id: data.client_id,
            isSynchronized: 1,
            data: {
                reference: (((data.audit_kit_instance || {}).item_instance || {}).item || {}).reference || '',
                lot_number: ((data.audit_kit_instance || {}).item_instance || {}).lot_number || '',
                isKitted: !!data.audit_kit_instance_id,
                audit_id: data.audit_id,
                area: data.area,
                audit_kit_instance_id: data.audit_kit_instance_id,
                audit_location_id: data.audit_location_id,
                location_name: data.audit_location.location_name,
                count_sheet_status: data.count_sheet_status,
                warehouse_id: data.warehouse_id,
                warehouse_name: data.warehouse.name,
                status_text: CountSheetStatusHelper['NAMES'][data.count_sheet_status],
                created_at: moment(data.created_at).format('ll'),
                has_invalid_items: data.has_invalid_items,
                has_recalled_items: data.has_recalled_items,
                kit_id: data.kit_id,
                total_items_counted: data.total_items_counted,
                attachments_count: data.attachments_count,
                archived: data.archived,
                show_expected_item_list: data.show_expected_item_list,
                created_by_id: data.created_by_id,
                counted_by_id: data.counted_by_id,
                counted_time: data.counted_time
            }
        };
        if (data.audit_kit_instance) {
            cs.data.audit_kit_instance = data.audit_kit_instance;
        }
        if (data.created_by) {
            var user = {
                first_name: data.created_by.first_name,
                last_name: data.created_by.last_name,
                user_id: data.created_by.id,
                name: data.created_by.name,
                email_address: data.created_by.email_address
            };
            cs.data.created_by = user;
            cs.data.created_by_id = data.created_by.id;
        }
        if (data.counted_by) {
            var user = {
                first_name: data.counted_by.first_name,
                last_name: data.counted_by.last_name,
                user_id: data.counted_by.id,
                name: data.counted_by.name,
                email_address: data.counted_by.email_address
            };
            cs.data.counted_by = user;
            cs.data.counted_by_id = data.counted_by.id;
        }
        return cs;
    };
    CountSheetDatabaseService.prototype.initializeValues = function (cs) {
        if (!cs.id || cs.id === "0") {
            cs.id = this.createClientId();
        }
        cs.data.attachments_count = cs.data.attachments_count || 0;
        cs.data.archived = !!cs.data.archived;
        return cs;
    };
    CountSheetDatabaseService.prototype.syncValues = function (cs) {
        cs.data.status_text = CountSheetStatusHelper['NAMES'][cs.data.count_sheet_status];
        return cs;
    };
    CountSheetDatabaseService.prototype.get = function (clientId) {
        return this._databaseService.get(this.storeName, clientId);
    };
    CountSheetDatabaseService.prototype.bulkAdd = function (datas) {
        var that = this;
        return new Promise(function (resolve, reject) {
            if (datas.length === 0) {
                return resolve(true);
            }
            var countSheets = datas.map(function (data) {
                return that.syncValues(that.initializeValues(data));
            });
            that._databaseService.bulkAdd(that.storeName, countSheets).then(function () {
                return resolve(true);
            });
        });
    };
    CountSheetDatabaseService.prototype.add = function (cs) {
        var that = this;
        cs = that.syncValues(that.initializeValues(cs));
        return that._databaseService.add(this.storeName, cs).then(function (_) {
            that.denormalizeAuditKitInstance(cs.data.audit_kit_instance_id, cs);
            return cs.id;
        });
    };
    CountSheetDatabaseService.prototype.update = function (cs) {
        var _this = this;
        var that = this;
        return new Promise(function (resolve, reject) {
            if (!cs.id || cs.id === "0") {
                console.log(cs);
                throw new Error('how can you update without a primary key?');
            }
            cs = that.syncValues(cs);
            _this.get(cs.id).then(function (oldCountSheet) {
                var totalItemsCountUpdated = cs.data.total_items_counted !== oldCountSheet.data.total_items_counted;
                var statusChanged = cs.data.count_sheet_status !== oldCountSheet.data.count_sheet_status;
                var archiveChanged = cs.data.archived && !oldCountSheet.data.archived;
                var kitIdChanged = cs.data.kit_id !== oldCountSheet.data.kit_id;
                var showExpectedItemListChanged = cs.data.show_expected_item_list && !oldCountSheet.data.show_expected_item_list;
                that._databaseService.update(that.storeName, cs).then(function (results) {
                    var promises = [];
                    if (totalItemsCountUpdated || statusChanged) {
                        promises.push(that.denormalizeAuditKitInstance(cs.data.audit_kit_instance_id, cs));
                    }
                    if (kitIdChanged) {
                        promises.push(that.updateKitInstanceItemRootItemId(cs.data.audit_kit_instance_id, cs.data.kit_id));
                    }
                    if (archiveChanged) {
                        promises.push(that.disassociateAuditKitInstance(cs.data.audit_kit_instance_id));
                        promises.push(that.archiveCountSheetItems(cs));
                    }
                    if (showExpectedItemListChanged) {
                        promises.push(that.combineCountSheetItems(cs));
                    }
                    zip.apply(void 0, promises.concat(new Promise(function (r, a) { r(null); }))).subscribe(function (_) {
                        resolve(results);
                    });
                });
            });
        });
    };
    CountSheetDatabaseService.prototype.deleteByIndex = function (index, value) {
        return this._databaseService.deleteByIndex(this.storeName, index, value);
    };
    CountSheetDatabaseService.prototype.getDataByIndex = function (index, value) {
        return this._databaseService.getDataByIndex(this.storeName, index, value);
    };
    CountSheetDatabaseService.prototype.find = function (auditId, params) {
        var that = this;
        return new Promise(function (resolve, reject) {
            that._databaseService.getDataByIndex(that.storeName, 'data.audit_id', parseInt(auditId, 10)).then(function (matches) {
                if (params.audit_location_id && params.audit_location_id !== -1) {
                    matches = matches.filter(function (cs) { return parseInt(cs.data.audit_location_id, 10) === parseInt(params.audit_location_id, 10); });
                }
                if (params.audit_kit_instance_id) {
                    matches = matches.filter(function (cs) { return cs.data.audit_kit_instance_id && (parseInt(cs.data.audit_kit_instance_id, 10) === parseInt(params.audit_kit_instance_id, 10)); });
                }
                if (params.null_audit_kit_instance_id) {
                    matches = matches.filter(function (cs) { return !cs.data.audit_kit_instance_id; });
                }
                if (params.not_null_audit_kit_instance_id) {
                    matches = matches.filter(function (cs) { return cs.data.audit_kit_instance_id; });
                }
                if (params.not_client_id) {
                    matches = matches.filter(function (cs) { return cs.id !== params.not_client_id; });
                }
                if (params.area) {
                    matches = matches.filter(function (cs) { return cs.data.area.trim() === params.area.trim(); });
                }
                if (params.archived !== undefined) {
                    matches = matches.filter(function (cs) { return !!cs.data.archived === !!params.archived; });
                }
                resolve(matches);
            });
        });
    };
    CountSheetDatabaseService.prototype.clear = function () {
        return this._databaseService.clear(this.storeName);
    };
    CountSheetDatabaseService.prototype.createClientId = function () {
        return "1" + Math.random().toString().substring(2, 10) + Math.random().toString().substring(2, 10);
    };
    CountSheetDatabaseService.prototype.updateKitInstanceItemRootItemId = function (auditKitInstanceId, kitId) {
        if (!auditKitInstanceId) {
            return new Promise(function (r, a) { r(null); });
        }
        var that = this;
        return new Promise(function (resolve, reject) {
            that._databaseService.get('audit_kit_instances', auditKitInstanceId).then(function (aki) {
                that._databaseService.getDataByIndex('audit_kit_instances', 'data.item_instance.item_id', aki.data.item_instance.item_id).then(function (matches) {
                    var promises = matches.map(function (match) {
                        match.data.item_instance.root_item_id = kitId;
                        return that._databaseService.update('audit_kit_instances', match);
                    });
                    zip.apply(void 0, promises.concat(new Promise(function (r, a) { r(null); }))).subscribe(function (_) {
                        resolve(null);
                    });
                });
            });
        });
    };
    CountSheetDatabaseService.prototype.disassociateAuditKitInstance = function (auditKitInstanceId) {
        if (!auditKitInstanceId) {
            return new Promise(function (r, a) { r(null); });
        }
        var that = this;
        var status = 1;
        return new Promise(function (resolve, reject) {
            that._databaseService.get('audit_kit_instances', auditKitInstanceId).then(function (aki) {
                aki.data.status = status;
                aki.data.statusText = CountSheetStatusHelper['NAMES'][status];
                aki.data.has_count_sheet = false;
                aki.data.count_sheet = null;
                aki.data.counted_by = null;
                aki.data.counted_time = '';
                that._databaseService.update('audit_kit_instances', aki).then(function (_) {
                    resolve(null);
                });
            });
        });
    };
    CountSheetDatabaseService.prototype.combineCountSheetItems = function (countSheet) {
        var that = this;
        return new Promise(function (resolve, reject) {
            that._databaseService.getDataByIndex('count_sheet_items', 'data.audit_id', parseInt(countSheet.data.audit_id, 10)).then(function (results) {
                var matches = results.filter(function (csi) { return csi.data.count_sheet_client_id === countSheet.id && !csi.data.hide && !csi._destroy; });
                var groupings = [];
                matches.forEach(function (csi) {
                    var match = groupings.find(function (g) {
                        var itemIdMatch = csi.data.item_id === g.item_id;
                        var lotNumberMatch = (csi.data.lot_number || '') === (g.lot_number || '');
                        var serialNumberMatch = (csi.data.serial || '') === (g.serial_number || '');
                        var checkedMatch = !!csi.data.checked === !!g.checked;
                        return itemIdMatch && lotNumberMatch && serialNumberMatch && checkedMatch;
                    });
                    if (!match) {
                        match = {
                            item_id: csi.data.item_id,
                            lot_number: csi.data.lot_number,
                            serial_number: csi.data.serial,
                            checked: csi.data.checked,
                            quantity: 0,
                            expiration_dates: [],
                            csis: []
                        };
                        groupings.push(match);
                    }
                    match.quantity += csi.data.quantity;
                    if (csi.data.expiration_date) {
                        match.expiration_dates.push(csi.data.expiration_date);
                    }
                    match.csis.push(csi);
                });
                var promises = [];
                groupings.forEach(function (group) {
                    if (group.csis.length > 1) {
                        group.csis.forEach(function (csi) {
                            csi.isSynchronized = 0;
                            csi._destroy = true;
                            csi.data.quantity = 0;
                            csi.data.expected_quantity = 0;
                            csi.data.checked = false;
                        });
                        group.csis[0]._destroy = false;
                        group.csis[0].data.checked = true;
                        group.csis[0].data.quantity = group.quantity;
                        group.csis[0].expiration_date = group.expiration_dates[0] || null;
                        group.csis.forEach(function (csi) {
                            if (csi._destroy && !csi.dbId) {
                                promises.push(that._databaseService.delete('count_sheet_items', csi.id));
                            }
                            else {
                                promises.push(that._databaseService.update('count_sheet_items', csi));
                            }
                        });
                    }
                });
                zip.apply(void 0, promises.concat(new Promise(function (r, a) { r(null); }))).subscribe(function (_) {
                    resolve(null);
                });
            });
        });
    };
    CountSheetDatabaseService.prototype.archiveCountSheetItems = function (countSheet) {
        var that = this;
        return new Promise(function (resolve, reject) {
            that._databaseService.getDataByIndex('count_sheet_items', 'data.audit_id', parseInt(countSheet.data.audit_id, 10)).then(function (results) {
                var matches = results.filter(function (csi) { return csi.data.count_sheet_client_id === countSheet.id; });
                var removableCountSheetItems = matches.filter(function (csi) {
                    return !csi.dbId;
                });
                var archivableCountSheetItems = matches.filter(function (csi) {
                    return !!csi.dbId;
                });
                //if they were never saved we can just remove em
                var removablePromises = removableCountSheetItems.map(function (csi) { return that._databaseService.delete('count_sheet_items', csi.id); });
                var archivablePromises = archivableCountSheetItems.map(function (csi) {
                    csi.isSynchronized = 0;
                    csi._destroy = true;
                    csi.data.quantity = 0;
                    csi.data.serial = '';
                    csi.data.lot_number = '';
                    return that._databaseService.update('count_sheet_items', csi);
                });
                zip.apply(void 0, removablePromises.concat(archivablePromises).concat(new Promise(function (r, a) { r(null); }))).subscribe(function (_) {
                    resolve(null);
                });
            });
        });
    };
    CountSheetDatabaseService.prototype.clearUnsyncedItems = function (countSheet) {
        var that = this;
        return new Promise(function (resolve, reject) {
            that._databaseService.getDataByIndex('count_sheet_items', 'data.audit_id', countSheet.data.audit_id).then(function (results) {
                var matches = results.filter(function (csi) { return csi.data.count_sheet_client_id === countSheet.id; });
                var removableCountSheetItems = matches.filter(function (csi) {
                    return !csi.isSynchronized;
                });
                //if they were never saved we can just remove em
                var removablePromises = removableCountSheetItems.map(function (csi) { return that._databaseService.delete('count_sheet_items', csi.id); });
                zip.apply(void 0, removablePromises.concat(new Promise(function (r, a) { r(null); }))).subscribe(function (_) {
                    resolve(null);
                });
            });
        });
    };
    CountSheetDatabaseService.prototype.denormalizeAuditKitInstance = function (auditKitInstanceId, cs) {
        if (!auditKitInstanceId) {
            return new Promise(function (r, a) { r(null); });
        }
        var that = this;
        return new Promise(function (resolve, reject) {
            that._databaseService.get('audit_kit_instances', auditKitInstanceId).then(function (aki) {
                if (!cs.data.archived) {
                    aki.data.has_count_sheet = true;
                    aki.data.status = cs.data.count_sheet_status;
                    aki.data.statusText = cs.data.status_text;
                    aki.data.counted_by = cs.data.counted_by;
                    aki.data.counted_time = cs.data.counted_time;
                }
                that._databaseService.update('audit_kit_instances', aki).then(function (_) {
                    resolve(null);
                });
            });
        });
    };
    CountSheetDatabaseService.ngInjectableDef = i0.defineInjectable({ factory: function CountSheetDatabaseService_Factory() { return new CountSheetDatabaseService(i0.inject(i1.DatabaseService)); }, token: CountSheetDatabaseService, providedIn: "root" });
    return CountSheetDatabaseService;
}());
export { CountSheetDatabaseService };
