import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ItemPackageService } from '../../../services/item-package.service';
import { ISnackBarConfig } from '../../../interfaces/isnack-bar-config';
import { MaterialSnackbarComponent } from '../../material-snackbar/material-snackbar.component';
import { MatSnackBar } from '@angular/material';
import { TrackLoadingService } from '../../../services/track-loading.service';
import { Assets } from 'src/app/helpers/assets';

@Component({
  selector: 'app-barcode-gs1-format',
  templateUrl: './barcode-gs1-format.component.html',
  styleUrls: ['./barcode-gs1-format.component.css']
})
export class BarcodeGs1FormatComponent implements OnInit {
  itemPackages: any[];
  groupSeperator: string = "\]?";
  @Output() itemNotFound = new EventEmitter<any>();

  snackBarConfig: ISnackBarConfig = {
    message: "",
    duration: 3000,
    success: false,
    snackBarClass: ""
  }

  ApplicationIdentifiers = {
    0: {
      0: { name: "sscc", format: /\d{18}/ },
      1: { name: "gtin", format: /\d{14}/ },
      2: { name: "content", format: /\d{14}/ }
    },
    1: {
      0: { name: "batch_lot", format: /([\x21-\x22\x25-\x2F\x30-\x39\x41-\x5A\x5F\x61-\x7A]{0,20})/ },
      1: { name: "prod_date", format: /\d{6}/ },
      3: { name: "pack_date", format: /\d{6}/ },
      7: { name: "use_by_or_expiry", format: /\d{6}/}
    },
    2: {
      1: { name: "serial", format: /([\x21-\x22\x25-\x2F\x30-\x39\x41-\x5A\x5F\x61-\x7A]{0,20})/ },
      4: {
        0: { name: "additional_id", format: /([\x21-\x22\x25-\x2F\x30-\x39\x3A-\x3F\x41-\x5A\x5F\x61-\x7A]{0,30})/ }
      }
    },
    3: {
      0: { name: "var_count", format: /\d{2,8}/ },
      7: { name: "count", format: /\d{8}/ }
    },
    4: {
      0: {
        0: { name: "order_number", format: /\w{1,30}/ },
      },
      1: {
        0: { name: "ship_to_loc", format: /\d{13}/ },
        2: { name: "purchase_from", format: /\d{13}/ },
        4: { name: "loc_no", format: /\d{13}/ }
      },
      2: {
        2: { name: "origin", format: /\d{3}/ }
      }
    },
    9: {
      0: { name: "internal", format: /\w{1,30}/ },
      1: { name: "internal", format: /\w{1,90}/ },
      2: { name: "internal", format: /\w{1,90}/ },
      3: { name: "internal", format: /\w{1,90}/ },
      4: { name: "internal", format: /\w{1,90}/ },
      5: { name: "internal", format: /\w{1,90}/ },
      6: { name: "internal", format: /\w{1,90}/ },
      7: { name: "internal", format: /\w{1,90}/ },
      8: { name: "internal", format: /\w{1,90}/ },
      9: { name: "internal", format: /\w{1,90}/ }
    }
  }
  constructor(
    private itemPackageService: ItemPackageService,
    public snackBar: MatSnackBar,
    private _trackLoadingService: TrackLoadingService
  ) { }

  ngOnInit() {
    this._trackLoadingService.startLoading("gs1-barcode-item-packages", "Loading GS1");
    this.itemPackageService.get().subscribe((itemPackages) => {
      this._trackLoadingService.stopLoading("gs1-barcode-item-packages");
      this.itemPackages = itemPackages;
    });
  }

  match(value) {
    try {
      this.decode(value);
      return true;
    }
    catch (e) {
      return false;
    }
  }

  parse(value) {
    let decoded = this.decode(value);
    var expiry;
    if (decoded["use_by_or_expiry"]) {
      expiry = `${parseInt(decoded["use_by_or_expiry"].substring(2,4), 10)}/${parseInt(decoded["use_by_or_expiry"].substring(4,6), 10)}/${2000 + parseInt(decoded["use_by_or_expiry"].substring(0,2), 10)}`;
    }

    let itemPackage = this.itemPackages.find((ip) => {
      return ip.gtin === decoded["gtin"];
    });
    if (!itemPackage) {
      this.playErrorSound();
      this.itemNotFound.emit();
      this.snackBarConfig.message = 'Matching Item could not be found';
      this.snackBarConfig.success = false;
      this.openSnackBar();
      return {};
    }
    return {
      "reference": itemPackage.item.reference,
      "lot": decoded["batch_lot"],
      "quantity": itemPackage.quantity,
      "expiration": expiry,
      "serial": decoded["serial"],
      "done": true
    };
  }

  Gs1FormatException = function (value) {
    this.value = value;
    this.message = 'does not conform GS1 specifications';
  };


  decode(encoded) {
    var decoded = {};
    var reader = 0;
    var currentAi = this.ApplicationIdentifiers;

    while (reader < encoded.length) {
      // Start decoding the AI
      var aiDigit = encoded[reader];

      var nextAi = currentAi[aiDigit];
      if (nextAi) {
        // Continue decoding the AI
        currentAi = nextAi;
        reader++;
        continue;
      }
      if (currentAi["format"]) {
        var pattern = new RegExp('^(' + currentAi["format"].source + ')' + this.groupSeperator);

        // We have finished decoding the AI.  Find a match
        var capture = encoded.slice(reader).match(pattern);
        if (capture) {
          var value = capture[1];
          if (currentAi["transformation"]) {
            value = currentAi["transformation"](value);
          }
          decoded[currentAi["name"]] = value;
          currentAi = this.ApplicationIdentifiers;
          reader += capture[0].length;
        }
        else {
          throw new this.Gs1FormatException(encoded);
        }
      }
      else {
        throw new this.Gs1FormatException(encoded);
      }
    }

    return decoded;
  }

  playErrorSound() {
    let audio = new Audio();
    audio.src = Assets.sounds.scanError;
    audio.play();
  }

  openSnackBar() {
    this.snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: this.snackBarConfig,
      duration: this.snackBarConfig.duration
    })
  }
}
