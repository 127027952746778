import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var HistoryServiceService = /** @class */ (function () {
    function HistoryServiceService(_router) {
        this._router = _router;
        this.historyKey = 'HISTORY';
        this.history = [];
    }
    HistoryServiceService.prototype.loadRouting = function () {
        var _this = this;
        this.history = this.getHistory();
        this.historySubscription = this._router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function (_a) {
            var urlAfterRedirects = _a.urlAfterRedirects;
            _this.addHistory(urlAfterRedirects);
        });
    };
    HistoryServiceService.prototype.getHistory = function () {
        return this.getLocalHistory();
    };
    HistoryServiceService.prototype.findLastCountSheetSummaryLink = function () {
        return this.findMostRecentLinkOf('countsheets');
    };
    HistoryServiceService.prototype.findLastDashboardLink = function () {
        return this.findMostRecentLinkOf('audit_dashboard');
    };
    HistoryServiceService.prototype.findLastNonKittedCountSheetLink = function () {
        return this.findMostRecentLinkOf('non_kitted_count_sheet');
    };
    HistoryServiceService.prototype.findLastKittedCountSheetLink = function () {
        return this.findMostRecentLinkOf('kit_instance_count_sheet');
    };
    HistoryServiceService.prototype.findMostRecentLinkOf = function (linkPart) {
        var reverseHistory = this.getHistory().reverse();
        var foundLink = reverseHistory.find(function (h) {
            return h.indexOf(linkPart) !== -1;
        });
        return foundLink;
    };
    HistoryServiceService.prototype.removeQueryStrings = function (url) {
        var res = '';
        var qsStart = url.indexOf('?');
        if (qsStart !== -1) {
            res = url.substr(0, qsStart);
        }
        else {
            res = url;
        }
        return res;
    };
    HistoryServiceService.prototype.addHistory = function (url) {
        if (url.indexOf('keepSameSite') === -1) {
            url = this.removeQueryStrings(url);
        }
        this.history = this.getHistory();
        if (this.history.length >= 20) {
            this.history.shift();
            this.history.push(url);
        }
        else {
            this.history.push(url);
        }
        this.saveHistory(this.history);
    };
    HistoryServiceService.prototype.createHistory = function () {
        var history = [];
        this.saveHistory(history);
        return history;
    };
    HistoryServiceService.prototype.saveHistory = function (history) {
        localStorage.setItem(this.historyKey, JSON.stringify(history));
    };
    HistoryServiceService.prototype.getLocalHistory = function () {
        var history = JSON.parse(localStorage.getItem(this.historyKey));
        if (history) {
            return history;
        }
        else {
            return this.createHistory();
        }
    };
    HistoryServiceService.ngInjectableDef = i0.defineInjectable({ factory: function HistoryServiceService_Factory() { return new HistoryServiceService(i0.inject(i1.Router)); }, token: HistoryServiceService, providedIn: "root" });
    return HistoryServiceService;
}());
export { HistoryServiceService };
