import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { urls } from '../helpers/urls';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ManufacturerService = /** @class */ (function () {
    function ManufacturerService(http) {
        this.http = http;
    }
    ManufacturerService.prototype.getManufacturer = function () {
        return this.http.get(environment.serverPath + "api/manufacturers/1", {}).pipe(tap(function (manufacturer) {
            if (manufacturer['audit_lot_pool_managed_last_changed_date']) {
                var currentStamp = new Date(manufacturer['audit_lot_pool_managed_last_changed_date']).getTime();
                var previousStamp = localStorage.getItem("manufacturer[" + manufacturer['id'] + "]lot_pool_managed_items");
                if (previousStamp && currentStamp.toString() !== previousStamp.toString()) {
                    window.caches.keys().then(function (cacheNames) {
                        var match = cacheNames.find(function (cn) { return cn.includes('LongTermCountSheets'); });
                        if (match) {
                            caches.open(match).then(function (cache) {
                                cache.delete("" + environment.serverPath + urls.lotPoolManagedItems, { ignoreSearch: true });
                            });
                        }
                    });
                }
                localStorage.setItem("manufacturer[" + manufacturer['id'] + "]lot_pool_managed_items", currentStamp.toString());
            }
        }));
    };
    ManufacturerService.ngInjectableDef = i0.defineInjectable({ factory: function ManufacturerService_Factory() { return new ManufacturerService(i0.inject(i1.HttpClient)); }, token: ManufacturerService, providedIn: "root" });
    return ManufacturerService;
}());
export { ManufacturerService };
