import { Injectable } from '@angular/core';
import { DatabaseService } from './database.service'
import { UUID } from 'angular2-uuid';
import { zip } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AttachmentDatabaseService {

  storeName = "attachments";

  constructor(private _databaseService: DatabaseService) { }

  new(data) {
    let attachment = {
      attachment_content_type: data.attachment_content_type,
      attachment_file_name: data.attachment_file_name,
      attachment_file_size: data.attachment_file_size,
      attachment_updated_at: data.attachment_updated_at,
      formatted_created_at: data.formatted_created_at,
      formatted_created_at_date: data.formatted_created_at_date,
      count_sheet_id: data.count_sheet_id,
      count_sheet_client_id: data.count_sheet_client_id,
      created_at: data.created_at,
      audit_id: data.audit_id,
      is_active: true,
      synchronized: 1,
      id: data.id,
      local_file_id: data.local_file_id
    };
    if (data.synchronized !== undefined) {
      attachment.synchronized = data.synchronized;
    }
    return attachment;
  }

  initializeValues(a) {
    if (!a.local_file_id) {
      a.local_file_id = this.createLocalFileId();
    }
    return a;
  }

  syncValues(a) {
    return a;
  }

  delete(key) {
    const that = this;
    return new Promise((resolve, reject) => {
      that._databaseService.get(that.storeName, key).then(function (oldAttachment: any) {
        if (!oldAttachment) {
          return resolve();
        }
        that._databaseService.delete(that.storeName, key).then(function (value) {
          let promises = [];

          promises.push(that.denormalizeCountSheet(oldAttachment.audit_id, oldAttachment.count_sheet_client_id));

          zip(
            ...promises
          ).subscribe(_ => {
            resolve(true);
          });
        });
      });
    });
  }


  clear() {
    return this._databaseService.clear(this.storeName);
  }

  get(id) {
    return this._databaseService.get(this.storeName, id);
  }

  add(a) {
    const that = this;
    a = that.syncValues(that.initializeValues(a));
    return new Promise((resolve, reject) => {
      this._databaseService.add(this.storeName, a).then(function (_) {
        let promises = [];

        promises.push(that.denormalizeCountSheet(a.audit_id, a.count_sheet_client_id));

        zip(
          ...promises
        ).subscribe(_ => {
          resolve(a.local_file_id);
        });
      });
    });
  }

  bulkAdd(datas) {
    const that = this;
    return new Promise((resolve, reject) => {
      if (datas.length === 0) {
        return resolve();
      }
      const attachments = datas.map(function (data) {
        return that.syncValues(that.initializeValues(data));
      });
      that._databaseService.bulkAdd(that.storeName, attachments).then(() => {
        return resolve();
      });
    });
  }

  deleteByIndex(index, value) {
    return this._databaseService.deleteByIndex(this.storeName, index, value);
  }

  getDataByIndex(index, value) {
    return this._databaseService.getDataByIndex(this.storeName, index, value);
  }

  update(a) {
    const that = this;
    return new Promise((resolve, reject) => {
      if (!a.local_file_id) {
        console.log(a);
        throw new Error('how can you update without a primary key?');
      }
      a = that.syncValues(a);
      this.get(a.local_file_id).then(function (oldAttachment: any) {
        const archiveChanged = a.toRemove && !oldAttachment.toRemove;
        that._databaseService.update(that.storeName, a).then(function (storeData: any[]) {
          const promises = [];

          if (archiveChanged) {
            promises.push(that.denormalizeCountSheet(a.audit_id, a.count_sheet_client_id));
          }
          zip(
            ...promises.concat(new Promise((r, a) => { r(); }))
          ).subscribe(_ => {
            resolve(storeData);
          });
        });
      });
    }).then(function (data) {
      return data;
    });
  }

  find(auditId, params) {
    let promise = new Promise((resolve, reject) => {
      this._databaseService.getDataByIndex(this.storeName, 'audit_id', parseInt(auditId, 10)).then((matches: any[]) => {

        if (params.toRemove !== undefined) {
          matches = matches.filter(function (a) {
            return !!a.toRemove === !!params.toRemove;
          })
        }

        if (params.count_sheet_client_id) {
          matches = matches.filter(function (a) {
            return parseInt(a.count_sheet_client_id,10) === parseInt(params.count_sheet_client_id,10);
          })
        }

        if (params.synchronized !== undefined) {
          matches = matches.filter(function (a) {
            return !!a.synchronized === !!params.synchronized;
          })
        }

        resolve(matches);
      });
    }).then(function (data) {
      return data;
    });
    return promise;
  }

  denormalizeCountSheet(auditId, countSheetClientId) {
    const that = this;
    return new Promise((resolve, reject) => {
      that.find(auditId, {count_sheet_client_id: countSheetClientId, toRemove: false}).then(function (attachments: any[]) {
        that._databaseService.get('count_sheets', countSheetClientId).then((cs: any) => {
          let count = 0;
          let synched = cs.isSynchronized;
          attachments.forEach(function (a) {
            if (!a.synchronized) {
              synched = 0;
            }
            count = count + 1;
          });
          cs.data.attachments_count = count;
          cs.isSynchronized = synched;
          that._databaseService.update('count_sheets', cs).then(_ => {
            resolve();
          });
        });
      });
    });
  }

  createLocalFileId() {
    return UUID.UUID();
  }
}
