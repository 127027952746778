import { IAgencyStatData } from "../interfaces/i-stat-data";

export class AuditDashboardHelper {


    constructor() {

    }

    parseAgencyDashboardData(data: any) {
        let agencyData: IAgencyStatData = {
            siteStats: { ...data["site"], ...{ "fraction": data["site"].planned === 0 ? 0 : Math.round((data["site"].complete / data["site"].planned) * 100) } },
            kitStats: { ...data["kit"], ...{ "fraction": data["kit"].planned === 0 ? 0 : Math.round((data["kit"].verified / data["kit"].planned) * 100) } },
            warehouseStats: { ...data["warehouse"], ...{ "fraction": data["warehouse"].planned === 0 ? 0 : Math.round((data["warehouse"].complete / data["warehouse"].planned) * 100) } },
            itemStats: { ...data["item"], ...{ "fraction": Math.round((data["item"].complete / data["item"].planned) * 100) } }
        }
        return agencyData;
    }

    saveAgencyDashbordDataLocally(agencyDashBoardData: IAgencyStatData) {
        localStorage.setItem('agency_stats', JSON.stringify(agencyDashBoardData));
    }
}
