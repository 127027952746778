/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loading-feedback.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./loading-feedback.component";
import * as i8 from "../../services/track-loading.service";
var styles_LoadingFeedbackComponent = [i0.styles];
var RenderType_LoadingFeedbackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadingFeedbackComponent, data: {} });
export { RenderType_LoadingFeedbackComponent as RenderType_LoadingFeedbackComponent };
function View_LoadingFeedbackComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_LoadingFeedbackComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "loading-overlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["color", "primary"], ["diameter", "50"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(3, 49152, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { color: [0, "color"], diameter: [1, "diameter"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "loading-overlay-messages-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoadingFeedbackComponent_2)), i1.ɵdid(6, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = "primary"; var currVal_4 = "50"; _ck(_v, 3, 0, currVal_3, currVal_4); var currVal_5 = _co.messages; _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 3).diameter; var currVal_2 = i1.ɵnov(_v, 3).diameter; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_LoadingFeedbackComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoadingFeedbackComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LoadingFeedbackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loading-feedback", [], null, null, null, View_LoadingFeedbackComponent_0, RenderType_LoadingFeedbackComponent)), i1.ɵdid(1, 245760, null, 0, i7.LoadingFeedbackComponent, [i8.TrackLoadingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoadingFeedbackComponentNgFactory = i1.ɵccf("app-loading-feedback", i7.LoadingFeedbackComponent, View_LoadingFeedbackComponent_Host_0, {}, {}, []);
export { LoadingFeedbackComponentNgFactory as LoadingFeedbackComponentNgFactory };
