import { Component, OnInit, Inject } from '@angular/core';
import { OfflineDataSynchronizerService } from 'src/app/services/offline-data-synchronizer.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { CountSheetItemService } from 'src/app/services/count-sheet-item.service';
import { ISnackBarConfig } from 'src/app/interfaces/isnack-bar-config';
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';

@Component({
  selector: 'app-json-data-viewer',
  templateUrl: './json-data-viewer.component.html',
  styleUrls: ['./json-data-viewer.component.css']
})
export class JsonDataViewerComponent implements OnInit {
  message;
  json;
  snackBarConfig: ISnackBarConfig = {
    message: '',
    duration: 3000,
    success: true,
    snackBarClass: ''
  };
  constructor(
    public dialogRef: MatDialogRef<JsonDataViewerComponent>,
    private _offlineDataSynchronizerService: OfflineDataSynchronizerService,
    public csiService: CountSheetItemService,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.message = data.message;
    this.json = JSON.stringify(data.json, null, '\t');
  }

  ngOnInit() {
  }

  cleanRetry() {
    this._offlineDataSynchronizerService.run(true);
    this.dialogRef.close();
  }

  sendEmailToSupport() {
    this.csiService.sendEmailToSupport(this.data.json).subscribe(result => {
      if (result) {
        this.snackBarConfig.message = 'Message has been sent successfully.';
        this.snackBarConfig.success = false;
        this.openSnackBar();
      }
    });
  }

  openSnackBar() {
    this.snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: this.snackBarConfig,
      duration: this.snackBarConfig.duration
    });
  }
}
