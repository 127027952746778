<h5 mat-dialog-title>{{ data.item.reference}}</h5>
<form #CopyCountSheetItemForm="ngForm" (ngSubmit)="onSubmit(CopyCountSheetItemForm.form)">
  <table>
    <tbody>
        <tr>
          <td>
            <mat-form-field>
              <input ngModel matInput placeholder="Lot Number" name="lot_number" [(ngModel)]="data.lot_number">
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field>
              <input ngModel matInput placeholder="Serial Number" name="serial_number"  [(ngModel)]="data.serial_number">
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field>
              <input ngModel matInput placeholder="Quantity" name="quantity"  [(ngModel)]="data.quantity">
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field>
              <input ngModel matInput [matDatepicker]="picker" placeholder="Expiration Date" name="expiration_date"  [(ngModel)]="data.expiration_date">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </td>
        </tr>
    </tbody>
  </table>
  <button type="submit" mat-button>Add</button>
</form>