import { Injectable, EventEmitter, NgZone, ApplicationRef } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  public updateAvailable = false;
  public updateAvailableEmitter: EventEmitter<boolean>;

  constructor(public appRef: ApplicationRef, public swUpdate: SwUpdate, ngZone: NgZone) {
    this.updateAvailableEmitter = new EventEmitter<boolean>();

    //Make sure the app is stable before starting the interval
    // const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
    // const timer$ = interval(60 * 1000);
    // const timerOnceAppIsStable$ = concat(appIsStable$, timer$);

    // if (swUpdate.isEnabled === true && environment.updateCheckEnabled === true) {
    //   ngZone.runOutsideAngular(() => {
    //     timerOnceAppIsStable$.subscribe(() => {
    //       console.log('Checking for updates');
    //       ngZone.run(() => swUpdate.checkForUpdate());
    //     });
    //   });
    // }
  }

  public checkForUpdates(): void {
    if (environment.updateCheckEnabled === true) {
      this.swUpdate.available.subscribe(() => {
        this.updateAvailable = true;
        this.updateAvailableEmitter.emit(true);
      });
    }
  }

  public updateIsAvailable() {
    return this.updateAvailable;
  }

  public ActivateUpdate() {
    //SwUpdate is fickle and if you wait too long the activateUpdate will never return... sigh
    //this.swUpdate.activateUpdate().then(() => window.location.reload());
    window.location.reload();
  }
}
