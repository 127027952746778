import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { urls } from "../helpers/urls";
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ConsignmentInventoryImportService = /** @class */ (function () {
    function ConsignmentInventoryImportService(http) {
        this.http = http;
    }
    ConsignmentInventoryImportService.prototype.upload = function (auditId, formData) {
        var that = this;
        return this.http.post("" + environment.serverPath + urls.consignmentInvImport.replace(":audit_id", auditId) + "/import", formData).pipe(function (res) {
            window.caches.keys().then(function (cacheNames) {
                var match = cacheNames.find(function (cn) { return cn.includes("Inventory"); });
                if (match) {
                    caches.open(match).then(function (cache) {
                        cache.delete(that.getURL(auditId), { ignoreSearch: true });
                    });
                }
            });
            return res;
        });
    };
    ConsignmentInventoryImportService.prototype.getURL = function (auditId) {
        return "" + environment.serverPath + urls.consignmentInvImport.replace(":audit_id", auditId);
    };
    ConsignmentInventoryImportService.prototype.getInventory = function (auditId, success) {
        var that = this;
        return this.http.get(that.getURL(auditId)).pipe(map(function (data) {
            if (success) {
                return data.filter(function (i) { return i.success; });
            }
            return data;
        }));
    };
    ConsignmentInventoryImportService.ngInjectableDef = i0.defineInjectable({ factory: function ConsignmentInventoryImportService_Factory() { return new ConsignmentInventoryImportService(i0.inject(i1.HttpClient)); }, token: ConsignmentInventoryImportService, providedIn: "root" });
    return ConsignmentInventoryImportService;
}());
export { ConsignmentInventoryImportService };
