import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { CountSheetService } from '../../services/count-sheet.service';
import { CountSheetItemService } from '../../services/count-sheet-item.service';
import { AuditKitInstanceService } from '../../services/audit-kit-instance.service';
import { CountSheetAttachmentService } from '../../services/count-sheet-attachment.service';
import { zip } from 'rxjs';

@Component({
  selector: 'app-retry-fetching-audit-count-sheet-data',
  templateUrl: './retry-fetching-audit-count-sheet-data.component.html',
  styleUrls: ['./retry-fetching-audit-count-sheet-data.component.css']
})
export class RetryFetchingAuditCountSheetDataComponent implements OnInit {
  auditIds;
  constructor(
    public dialogRef: MatDialogRef<RetryFetchingAuditCountSheetDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _countSheetService: CountSheetService,
    private _countSheetItemService: CountSheetItemService,
    private _auditKitInstanceService: AuditKitInstanceService,
    private _countSheetAttachmentService: CountSheetAttachmentService
  ) {
    this.auditIds = data.auditIds;
  }

  ngOnInit() {

  }

  refetch() {
    let dataFetches = [];
    let that = this;
    this.auditIds.forEach(auditId => {
      dataFetches.push(that._auditKitInstanceService.getAuditKitInstancesAndPopulateOfflineData(auditId));
      dataFetches.push(that._countSheetService.getCountSheetsAndPopulateOfflineData(auditId));
      dataFetches.push(that._countSheetItemService.getCountSheetItemsAndClearOfflineData(auditId));
      dataFetches.push(that._countSheetAttachmentService.getAttachmentsAndPopulateOfflineData(auditId));
    });
    zip(...dataFetches).subscribe(data => {
      that.dialogRef.close();
    });
  }

}
