<div class="col-md-12 full">
    <div class="row">
        <div class="col-md-12">
          <button mat-raised-button class="back" type="button" (click)="goToAuditDashboard()" ngbTooltip="Go To Dashboard"
            placement="right">
            <mat-icon>dashboard</mat-icon>
            <span>Dashboard</span>
          </button>
        </div>
      </div>
  <div class="mat-elevation-z8 data-table">
    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="warehouse">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Warehouse</th>
        <td mat-cell *matCellDef="let row">{{(row.warehouse || {}).name  || "&mdash;"}}</td>
      </ng-container>


      <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Reference</th>
        <td mat-cell *matCellDef="let row">{{(row.item || {}).reference  || "&mdash;"}}</td>
      </ng-container>

      <ng-container matColumnDef="lot_number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Lot Number</th>
        <td mat-cell *matCellDef="let row">{{(row.lot_number || {}).value  || "&mdash;"}}</td>
      </ng-container>

      <ng-container matColumnDef="serial_number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Serial Number</th>
        <td mat-cell *matCellDef="let row">{{(row.serial_number || {}).value  || "&mdash;"}}</td>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Quantity</th>
        <td mat-cell *matCellDef="let row">{{row.quantity  || "&mdash;"}}</td>
      </ng-container>

      <ng-container matColumnDef="sales_order">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Sales Order</th>
        <td mat-cell *matCellDef="let row">{{(row.sales_order || {}).client_id  || "&mdash;"}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator #paginator [pageSizeOptions]="[10, 20, 50]">
    </mat-paginator>
  </div>

</div>
