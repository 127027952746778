import { Injectable } from '@angular/core';
import { DatabaseService } from './database.service'
import { ICountSheetData } from '../interfaces/icount-sheet-data'
import { zip } from 'rxjs';
import { IcountsheetItemData } from '../interfaces/icountsheet-item';

@Injectable({
  providedIn: 'root'
})
export class CountSheetItemDatabaseService {

    storeName = "count_sheet_items";
    rank = 1;

    constructor(
      private _databaseService: DatabaseService
    ) { }

    new(data) {
      return {
        dbId: data.id,
        isSynchronized: 1,
        _destroy: false,
        id: data.client_id,
        data: {
          is_consigned: data.item.is_consigned,
          reference: data.item.reference,
          description: data.item.description,
          lot_number: data.lot_number,
          serial: data.serial_number,
          quantity: data.quantity,
          isKitted: false,
          expiration_date: data.expiration_date,
          item_id: data.item_id,
          audit_id: data.audit_id,
          count_sheet_id: data.count_sheet_id,
          count_sheet_client_id: data.count_sheet_client_id,
          min_order_quantity: data.item.minimum_order_quantity,
          is_lot_tracked: data.item.is_lot_number_tracked,
          is_serial_tracked: data.item.is_serial_tracked,
          modified_by: data.modified_by.name,
          modified_at: data.modified_at,
          checked: data.checked,
          manually_entered: data.manually_entered,
          hide: data.hide,
          rank: data.rank
        }
      };
    }

    get(clientId) {
      return this._databaseService.get(this.storeName, clientId);
    }

    bulkAdd(datas) {
      const that = this;
      return new Promise((resolve, reject) => {
        if (datas.length === 0) {
          return resolve(null);
        }
        that.rank = Math.max(...datas.map((d) => {
          if (d.data.rank) {
            return d.data.rank;
          }
          return 0;
        }).concat([that.rank]));

        const countSheetItems = datas.map(function (data) {
          if (!data.id || data.id === "0") {
            data.id = that.createClientId();
          }
          if (!data.data.rank) {
            data.data.rank = (that.rank += 1);
          }
          return data;
        });
        that._databaseService.bulkAdd(that.storeName, countSheetItems).then(() => {
          return resolve(true);
        });
      });
    }

    add(data: IcountsheetItemData) {
      if (!data.id) {
        data.id = this.createClientId();
      }

      if (!data.data.rank) {
        data.data.rank = (this.rank += 1);
      }
      else {
        this.rank = Math.max(this.rank, data.data.rank);
      }
      const countSheetClientId = data.data.count_sheet_client_id;
      const auditId = data.data.audit_id;
      const that = this;
      const syncJustAdded = data.data.justAdded;
      return new Promise((resolve, reject) => {
        this._databaseService.add(this.storeName, data).then(function (_) {
          let promises = [];

          promises.push(that.denormalizeCountSheet(auditId, countSheetClientId));
          if (syncJustAdded) {
            promises.push(that.syncJustAdded(data));
          }
          zip(
            ...promises
          ).subscribe(_ => {
            resolve(data.id);
          });
        });
      });
    }

    bulkUpdate(datas) {
      const that = this;
      return new Promise((resolve, reject) => {
        that.rank = Math.max(...datas.map((d) => {
          if (d.data.rank) {
            return d.data.rank;
          }
          return 0;
        }).concat([that.rank]));
        const promises = datas.map(function (data) {
          if (!data.id) {
            console.log(data);
            throw new Error('how can you update without a primary key?');
          }
          if (!data.data.rank) {
            data.data.rank = (that.rank += 1);
          }
          return that._databaseService.update(that.storeName, data);
        });
        zip(...promises.concat(new Promise((r, a) => {r(null); }))).subscribe(_ => {
          resolve(_);
        })
      });
    }

    update(data) {

      if (!data.id) {
        console.log(data);
        throw new Error('how can you update without a primary key?');
      }
      if (!data.data.rank) {
        data.data.rank = (this.rank += 1);
      }
      else {
        this.rank = Math.max(this.rank, data.data.rank);
      }
      const countSheetClientId = data.data.count_sheet_client_id;
      const auditId = data.data.audit_id;
      const that = this;
      return this._databaseService.update(this.storeName, data).then(function (data) {
        that.denormalizeCountSheet(auditId, countSheetClientId);
        return data;
      });
    }

    delete(key) {
      return this._databaseService.delete(this.storeName, key);
    }

    deleteByIndex(index, value) {
      return this._databaseService.deleteByIndex(this.storeName, index, value);
    }

    getDataByIndex(index, value) {
      return this._databaseService.getDataByIndex(this.storeName, index, value);
    }

    find(auditId, params) {
      return new Promise((resolve, reject) => {
        this._databaseService.getDataByIndex(this.storeName, 'data.audit_id', parseInt(auditId, 10)).then((matches: any[]) => {
          if (params.count_sheet_id) {
            matches = matches.filter(function (csi) {
              return parseInt(csi.data.count_sheet_id,10) === parseInt(params.count_sheet_id,10);
            })
          }

          if (params.count_sheet_client_id) {
            matches = matches.filter(function (csi) {
              return csi.data.count_sheet_client_id === params.count_sheet_client_id;
            })
          }
          if (params.isSynchronized !== undefined) {
            matches = matches.filter(function (csi) {
              return !!csi.isSynchronized === !!params.isSynchronized;
            });
          }
          if (params.checked !== undefined) {
            matches = matches.filter(function (csi) {
              return !!csi.data.checked === !!params.checked;
            });
          }
          if (params._destroy !== undefined) {
            matches = matches.filter(function (csi) {
              return !!csi._destroy === !!params._destroy;
            });
          }
          if (params.reference) {
            matches = matches.filter(function (csi) {
              return csi.data.reference.toLowerCase() === params.reference.toLowerCase();
            });
          }
          if (params.lot_number) {
            matches = matches.filter(function (csi) {
              return (csi.data.lot_number || '').toString().toLowerCase() === params.lot_number.toString().toLowerCase();
            });
          }
          if (params.serial) {
            matches = matches.filter(function (csi) {
              return (csi.data.serial || '').toString().toLowerCase() === params.serial.toString().toLowerCase();
            });
          }

          if (params.quantity_greater_than) {
            matches = matches.filter(function (csi) {
              return csi.data.quantity > params.quantity_greater_than;
            });
          }
          resolve(matches);
        });
      });
    }

    clear() {
      return this._databaseService.clear(this.storeName);
    }

    createClientId() {
      return `1${Math.random().toString().substring(2, 10)}${Math.random().toString().substring(2, 10)}`;
    }

    syncJustAdded(countSheetItem) {
      const that = this;
      return new Promise((resolve, reject) => {
        that.find(countSheetItem.data.audit_id, {count_sheet_client_id: countSheetItem.data.count_sheet_client_id, _destroy: false}).then(function (csis: any[]) {
          const oldJustAdded = csis.filter(csi => csi.data.justAdded && parseInt(csi.data.item_id, 10) !== parseInt(countSheetItem.data.item_id, 10));
          if (oldJustAdded.length === 0) {
            return resolve(null);
          }
          let promises = [new Promise((r, a) => { r(null); })];
          oldJustAdded.forEach((csi) => {
            csi.data.justAdded = false;
            promises.push(that._databaseService.update(that.storeName, csi));
          });
          zip(
            ...promises
          ).subscribe(_ => {
            return resolve(null);
          });
        });
      });
    };

    denormalizeCountSheet(auditId, countSheetClientId) {
      const that = this;
      return new Promise((resolve, reject) => {
        that.find(auditId, {count_sheet_client_id: countSheetClientId, _destroy: false}).then(function (csis: any[]) {
          that._databaseService.get('count_sheets', countSheetClientId).then((cs: ICountSheetData) => {
            let count = 0;
            let synched = cs.isSynchronized;
            csis.forEach(function (csi) {
              if (!csi.isSynchronized) {
                synched = 0;
              }
              if (csi.data.checked) {
                count = count + csi.data.quantity;
              }
            });
            cs.data.total_items_counted = count;
            cs.isSynchronized = synched;
            that._databaseService.update('count_sheets', cs).then(_ => {
              resolve(null);
            });
          });
        });
      });
    }
}
