import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '../../../../node_modules/@angular/material';
import { ISnackBarConfig } from '../../interfaces/isnack-bar-config';
import { CountSheetItemService } from '../../services/count-sheet-item.service';
import { IcountsheetItemData } from '../../interfaces/icountsheet-item';
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';

@Component({
  selector: 'app-copy-count-sheet-item',
  templateUrl: './copy-count-sheet-item.component.html',
  styleUrls: ['./copy-count-sheet-item.component.css']
})
export class CopyCountSheetItemComponent implements OnInit {
  snackBarConfig: ISnackBarConfig = {
    message : "",
    duration: 3000,
    success: false,
    snackBarClass : ""
  }
  constructor(
    public dialogRef: MatDialogRef<CopyCountSheetItemComponent>,
    private countSheetItemService: CountSheetItemService,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data
  ) {

  }

  ngOnInit() {

  }

  onSubmit(form) {
    let countSheetItem:IcountsheetItemData = {
      dbId: 0,
      id: '',
      isSynchronized: 0,
      data: {
        lot_number: form.value["lot_number"],
        count_sheet_id: this.data.count_sheet_id,
        item_id: this.data.item_id,
        quantity: form.value["quantity"],
        expiration_date: form.value["expiration_date"],
        serial: form.value["serial_number"],
        reference: null,
        manually_entered: false
      }
    };

    let option = {add_to_quantity: true};
    this.countSheetItemService.updateSingleCountSheetItem(
      countSheetItem,
      option
    ).subscribe(data => {
      this.snackBarConfig.message = "Copied Count Sheet Item Successfully";
      this.snackBarConfig.success = true;
      this.openSnackBar();
      this.dialogRef.close(data);
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openSnackBar() {
    this.snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: this.snackBarConfig,
      duration: this.snackBarConfig.duration
    })
  }

}
