import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { urls } from '../helpers/urls';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ItemCombinationService = /** @class */ (function () {
    function ItemCombinationService(http) {
        this.http = http;
    }
    ItemCombinationService.prototype.get = function () {
        return this.http.get("" + environment.serverPath + urls.getManufacturerItemCombinations);
    };
    ItemCombinationService.prototype.getManufacturerItemCombinations = function () {
        return this.http.get("" + environment.serverPath + urls.getManufacturerItemCombinations);
    };
    ItemCombinationService.prototype.upload = function (formData) {
        return this.http.post("" + environment.serverPath + urls.itemCombinationImport, formData).pipe(function (res) {
            window.caches.keys().then(function (cacheNames) {
                var match = cacheNames.find(function (cn) { return cn.includes("LongTermCountSheets"); });
                if (match) {
                    caches.open(match).then(function (cache) {
                        cache.delete("" + environment.serverPath + urls.manufacturerItemCombinationCount, { ignoreSearch: true });
                        cache.delete("" + environment.serverPath + urls.getManufacturerItemCombinations, { ignoreSearch: true });
                    });
                }
            });
            return res;
        });
    };
    ItemCombinationService.prototype.getManufacturerItemCombinationCount = function () {
        return this.http.get("" + environment.serverPath + urls.manufacturerItemCombinationCount);
    };
    ItemCombinationService.ngInjectableDef = i0.defineInjectable({ factory: function ItemCombinationService_Factory() { return new ItemCombinationService(i0.inject(i1.HttpClient)); }, token: ItemCombinationService, providedIn: "root" });
    return ItemCombinationService;
}());
export { ItemCombinationService };
