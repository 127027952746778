import { Component, ViewChild, EventEmitter, OnInit, OnDestroy, Output, Input, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ISnackBarConfig } from '../../interfaces/isnack-bar-config';
import { CountSheetItemHelper } from '../../helpers/countsheet-item-helper';
import { IcountsheetItemData } from './../../interfaces/icountsheet-item';
import { Subscription } from 'rxjs';
import { ReferenceFieldComponent } from '../reference-field/reference-field.component';
import { WarningDialogComponent } from '../warning-dialog/warning-dialog.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';
import { ICountSheetData } from 'src/app/interfaces/icount-sheet-data';
import { CountSheetHelper } from 'src/app/helpers/countsheet-helper';
import { UserDataHelper } from 'src/app/helpers/user-data-helper';
import { PositiveIntegerHelper } from '../../helpers/positive-integer-helper';
import { AudioPlayerService } from 'src/app/services/audio-player.service';
import { CountSheetItemDatabaseService } from '../../services/count-sheet-item-database.service';
import { zip } from 'rxjs';
import { Iitem } from 'src/app/interfaces/iitem';
import { IuserData } from 'src/app/interfaces/iuser-data';
import { RecallOracleService } from 'src/app/services/recall-oracle.service';



@Component({
  selector: 'app-add-count-sheet-item',
  templateUrl: './add-count-sheet-item.component.html',
  styleUrls: ['./add-count-sheet-item.component.css']
})
export class AddCountSheetItemComponent implements OnInit, OnDestroy {
  form: any;
  auditId: number;
  lotNumber: string;
  serialNumber: string;
  expirationDate: string;
  quantity: string;
  currentUrl: string;
  onlineSubscription: Subscription;
  scannerLocked = false;
  manuallyEntered = true;
  disableSerialNumberInput = false;
  @Input() items: Iitem[];
  @Input() redspotQuantities: any;
  @Input() sapQuantities: any;
  @Input() redspotInventory: any[];
  @Input() sapInventory: any[];
  @Input() lotPoolManagedItems: any[];
  @Input() itemSettings: any;
  @Input() itemCombinations: any;
  @Input() countSheet: ICountSheetData;
  @Input() exclusions: any[];
  @Input() _recallOracleService: RecallOracleService;
  @Input() countsheetItemHelper: CountSheetItemHelper;
  @Input() posIntegerHelper: PositiveIntegerHelper;
  @Input() countsheetHelper: CountSheetHelper;
  @Input() currentlyDisplayedCountSheetItems: IcountsheetItemData[]
  @ViewChild('serial') serialField: ElementRef;
  @ViewChild(ReferenceFieldComponent) referenceField: ReferenceFieldComponent;
  @ViewChild('AddCountSheetItemForm') addCountSheetItemForm: NgForm;
  @Output() countSheetItemUpdated = new EventEmitter<object>();

  userDataHelper: UserDataHelper = new UserDataHelper();
  user: IuserData;

  countUrls = {
    kitted: 'kit_instance_count_sheet',
    unkitted: 'non_kitted_count_sheet'
  };

  snackBarConfig: ISnackBarConfig = {
    message: '',
    duration: 3000,
    success: false,
    snackBarClass: ''
  };

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    public  _dialog: MatDialog,
    public  _snackBar: MatSnackBar,
    private _audioPlayerService: AudioPlayerService,
    private _countSheetItemDatabaseService: CountSheetItemDatabaseService
  ) {
  }

  ngOnDestroy(): void {
    this.serialField = null;
    this.referenceField = null;
    this.addCountSheetItemForm = null;
  }

  ngOnInit() {

    this.currentUrl = this._router.url;
    this.auditId = +this._route.snapshot.paramMap.get('audit_id');
    this.user = this.userDataHelper.getUserData();
    this.reset();
  }

  setReference(value: string) {
    if (!this.scannerLocked) {
      this.referenceField.referenceControl.setValue(value);
    }
  }

  setLot(value: string) {
    if (!this.scannerLocked) {
      this.lotNumber = value;
    }
  }

  setSerial(value: string) {
    if (!this.scannerLocked) {
      this.serialNumber = value;
    }
  }

  setQuantity(value: string) {
    if (!this.scannerLocked) {
      this.quantity = value;
    }
  }

  getQuantity() {
    let quantity: number = +this.quantity;
    if (!quantity || quantity === 0) {
      quantity = 1;
    }
    return quantity;
  }

  setExpiration(value: string) {
    if (!this.scannerLocked) {
      this.expirationDate = value;
    }
  }

  submitForm(itemScanned = false) {
    if (itemScanned) {
      this.manuallyEntered = false;
    }
    if (!this.scannerLocked) {
      this.addCountSheetItemForm.ngSubmit.emit();
    } else {
      this.countsheetItemHelper.playErrorSound(this._audioPlayerService.setting, true);
    }
  }

  getFormData() {

    const iskitted = this.isKittedCountSheet();
    const item = this.countsheetItemHelper.getItemForReference(this.referenceField.referenceControl.value.trim(), this.items) || {};
    const data: IcountsheetItemData = {
      id: '',
      dbId: 0,
      isSynchronized: 0,
      _destroy: false,
      data: {
        checked: true,
        lot_number: this.lotNumber,
        quantity: this.getQuantity(),
        reference: item ? item.reference : this.referenceField.referenceControl.value.trim(),
        description: item.description || null,
        expiration_date: this.expirationDate,
        serial: this.serialNumber,
        item_id: item.id || null,
        isKitted: iskitted,
        manually_entered: this.manuallyEntered,
        count_sheet_id: this.countSheet.dbId,
        count_sheet_client_id: this.countSheet.id,
        audit_id: this.countSheet.data.audit_id,
        modified_by: this.user.name,
        is_lot_tracked: item.is_lot_number_tracked || null,
        min_order_quantity: item.minimum_order_quantity || null,
        is_consigned: item.is_consigned || null,
        is_serial_tracked: item.is_serial_tracked || null
      }
    };
    return data;
  }

  cleanQuantity(event: KeyboardEvent) {
    if (!this.posIntegerHelper.isNumeric(event)) {
      event.preventDefault();
    }
  }

  onSubmit(form: any) {
    this.form = form;
    const that = this;
    const countSheetItem = this.getFormData();
    let displayedMatch = null

    if(this.countSheet.data.show_expected_item_list){
      displayedMatch = this.currentlyDisplayedCountSheetItems.find((item: IcountsheetItemData) => {
        const itemIdMatch = item.data.item_id === countSheetItem.data.item_id;
        const lotMatch = (item.data.lot_number || '') === (countSheetItem.data.lot_number || '');
        const serialMatch = (item.data.serial || '') === (countSheetItem.data.serial || '');
        return  itemIdMatch && lotMatch && serialMatch;
      })
    }
    
    if(displayedMatch){
      countSheetItem.data.redspot_inventory_id = displayedMatch.data.redspot_inventory_id;
      countSheetItem.data.rank = displayedMatch.data.rank
    }

    this.validateCountSheetItem(countSheetItem).then(
      function () {
        that._countSheetItemDatabaseService.find(that.countSheet.data.audit_id, {count_sheet_client_id: that.countSheet.id}).then((matches: any[]) => {
          let countSheetItems = [];
          if (!countSheetItem.data.lot_number && that.lotPoolManagedItems.find(lpmi => lpmi.item_id === countSheetItem.data.item_id)) {
            countSheetItems = that.countsheetItemHelper.lotPoolManagedQuantityChanged(
              countSheetItem.data.quantity,
              matches.filter(csi => csi.data.item_id === countSheetItem.data.item_id),
              that.redspotInventory.filter(ri => ri.item_id === countSheetItem.data.item_id),
              that.sapQuantities.filter(si => si.item_id === countSheetItem.data.item_id),
              countSheetItem.data,
              that.countSheet,
              {isKitted: !!that.countSheet.data.audit_kit_instance_id}
            )[1];
          } else {
            countSheetItems = [countSheetItem];
          }

          zip(...countSheetItems.map((csi) => {
            if (csi.id) {
              return that._countSheetItemDatabaseService.update(csi);
            }
            csi.data.justAdded = true;
            return that._countSheetItemDatabaseService.add(csi);
          })).subscribe(_ => {
            [...Array.from(new Set(
              countSheetItems.map(
                e => that.countsheetItemHelper.checkForWarnings(e, that._recallOracleService)
              ).reduce((total, message) => {
                return total.concat(message.warnings);
              }, [])
            ))].forEach(m => {
              that.openWarningDialog(m.toString());
            });
            that.countsheetItemHelper.playSuccessSound(that._audioPlayerService.setting);
            that.countSheetItemUpdated.emit();
            that.reset();
          });
        });
      },
      function(message) {
        that.countsheetItemHelper.playErrorSound(that._audioPlayerService.setting, true);
        that.snackBarConfig.message = message;
        that.snackBarConfig.success = false;
        that.openSnackBar();
        that.reset();
      });
  }

  validateCountSheetItem(csi: IcountsheetItemData) {
    const that = this;
    return new Promise((resolve, reject) => {
      const redspotItem = csi.data.reference ? that.countsheetItemHelper.getItemForReference(csi.data.reference, that.items) : null;
      if (!redspotItem) {
        reject('Item not found');
      } else if (csi.data.serial && csi.data.quantity > 1) {
        reject('Serial Tracked items can only have a qty of 1');
      } else if (csi.data.serial) {
        const filter: any = {
          serial: csi.data.serial,
          count_sheet_client_id: csi.data.count_sheet_client_id,
          reference: csi.data.reference,
          checked: true,
          quantity_greater_than: 0
        };
        if (csi.data.lot_number) {
          filter.lot_number = csi.data.lot_number;
        }
        this._countSheetItemDatabaseService.find(that.countSheet.data.audit_id, filter).then((matches: any[]) => {
          if (matches.length > 0) {
            reject('Serial Tracked items can only have a qty of 1');
          } else {
            resolve(null);
          }
        });
      } else {
        resolve(null);
      }
    });
  }

  isKittedCountSheet(): boolean {
    return this.currentUrl.indexOf(this.countUrls.kitted) !== -1;
  }

  openWarningDialog(message: string) {
    this.countsheetItemHelper.playErrorSound(this._audioPlayerService.setting);
    this.scannerLocked = true;
    const dialogRef = this._dialog.open(WarningDialogComponent, {
      width: '50%',
      disableClose: true,
      role: 'alertdialog',
      data: {
        message: message
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.reset();
      this.scannerLocked = false;
    });
  }

  openSnackBar() {
    this._snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: this.snackBarConfig,
      duration: this.snackBarConfig.duration
    });
  }

  toggleSerialNumberInput(disableInput) {
    this.disableSerialNumberInput = disableInput;
    if (disableInput) {
      this.serialField.nativeElement.tabIndex = -1;
    } else {
      this.serialField.nativeElement.tabIndex = 0;
    }
  }

  reset() {
    if (this.form) {
      this.form.reset();
    }
    this.disableSerialNumberInput = false;
    this.lotNumber = '';
    this.expirationDate = '';
    this.quantity = '';
    this.serialNumber = '';
    this.serialField.nativeElement.tabIndex = -1;
    this.referenceField.referenceControl.setValue('');
    this.manuallyEntered = true;
    const that = this;
    //if after entering an item you pasted a reference again and hit enter it would act as though the field is blank
    setTimeout(function () {
      if (that.countSheet.data) {
        that.referenceField.refInput.nativeElement.focus();
      }
    }, 0);
  }
}
