import { CONSTANTS } from '../helpers/constants';
import * as i0 from "@angular/core";
var AccessTokenService = /** @class */ (function () {
    function AccessTokenService() {
    }
    AccessTokenService.prototype.storeTokenData = function (data) {
        if (data) {
            data.expiration_date = this.calculateExpiration(data).toString();
            this.currentTokenData = data;
            localStorage.setItem(CONSTANTS.localStorageKeys.ACCESS_TOKEN_KEY, JSON.stringify(data));
        }
    };
    AccessTokenService.prototype.getTokenData = function () {
        var dataString = localStorage.getItem(CONSTANTS.localStorageKeys.ACCESS_TOKEN_KEY);
        if (dataString) {
            var data = JSON.parse(dataString);
            this.currentTokenData = data;
            return data;
        }
        else {
            return { message: CONSTANTS.messages.NO_ACCESS_TOKEN };
        }
    };
    AccessTokenService.prototype.clearTokenData = function () {
        this.currentTokenData = null;
        localStorage.removeItem(CONSTANTS.localStorageKeys.ACCESS_TOKEN_KEY);
    };
    AccessTokenService.prototype.calculateExpiration = function (tokenData) {
        var duration = tokenData.expires_in;
        var now = new Date();
        now.setUTCSeconds(now.getUTCSeconds() + duration);
        return now;
    };
    AccessTokenService.prototype.isExpired = function (tokenData) {
        var expired = false;
        var now = new Date();
        if (tokenData) {
            var expirationDateString = tokenData.expiration_date;
            var expirationDate = new Date(expirationDateString);
            expired = now.getTime() > expirationDate.getTime();
        }
        else {
            tokenData = this.getTokenData();
            var expirationDateString = tokenData.expiration_date;
            var expirationDate = new Date(expirationDateString);
            expired = now.getTime() > expirationDate.getTime();
        }
        return expired;
    };
    AccessTokenService.prototype.getInMemoryTokenDataIfNotNull = function () {
        if (this.currentTokenData) {
            return this.currentTokenData;
        }
        else {
            return this.getTokenData();
        }
    };
    AccessTokenService.prototype.storeTokenDataIfMissing = function (data) {
        var dataString = localStorage.getItem(CONSTANTS.localStorageKeys.ACCESS_TOKEN_KEY);
        if (!dataString) {
            this.storeTokenData(data);
        }
    };
    AccessTokenService.ngInjectableDef = i0.defineInjectable({ factory: function AccessTokenService_Factory() { return new AccessTokenService(); }, token: AccessTokenService, providedIn: "root" });
    return AccessTokenService;
}());
export { AccessTokenService };
