var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DataSource } from '@angular/cdk/collections';
import { map } from 'rxjs/operators';
import { of as observableOf, merge } from 'rxjs';
/**
 * Data source for the UnkittedCountsheet view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
var UnkittedCountsheetDataSource = /** @class */ (function (_super) {
    __extends(UnkittedCountsheetDataSource, _super);
    function UnkittedCountsheetDataSource(paginator, sort, _data, filters) {
        var _this = _super.call(this) || this;
        _this.paginator = paginator;
        _this.sort = sort;
        _this._data = _data;
        _this.filters = filters;
        _this.data = [];
        _this.data = _this._data.filter(function (csi) { return csi.data.archived === false; });
        _this.paginator.length = _this.data.length;
        return _this;
    }
    /**
     * Connect this data source to the table. The table will only update when
     * the returned stream emits new items.
     * @returns A stream of the items to be rendered.
     */
    UnkittedCountsheetDataSource.prototype.connect = function () {
        var _this = this;
        // Combine everything that affects the rendered data into one update
        // stream for the data-table to consume.
        var dataMutations = [
            observableOf(this.data),
            this.paginator.page,
            this.sort.sortChange
        ];
        // Set the paginators length
        this.paginator.length = this.data.length;
        return merge.apply(void 0, dataMutations).pipe(map(function () {
            var filteredData = _this.getPagedData(_this.getSortedData(_this.getFilteredData(_this.data.slice())));
            return filteredData;
        }));
    };
    /**
     *  Called when the table is being destroyed. Use this function, to clean up
     * any open connections or free any held resources that were set up during connect.
     */
    UnkittedCountsheetDataSource.prototype.disconnect = function () { };
    /**
     * Paginate the data (client-side). If you're using server-side pagination,
     * this would be replaced by requesting the appropriate data from the server.
     */
    UnkittedCountsheetDataSource.prototype.getPagedData = function (data) {
        var startIndex = this.paginator.pageIndex * this.paginator.pageSize;
        return data.splice(startIndex, this.paginator.pageSize);
    };
    /**
     * Sort the data (client-side). If you're using server-side sorting,
     * this would be replaced by requesting the appropriate data from the server.
     */
    UnkittedCountsheetDataSource.prototype.getSortedData = function (data) {
        var _this = this;
        if (!this.sort.direction) {
            this.sort.direction = 'asc';
        }
        if (!this.sort.active) {
            return data;
        }
        // "status", 'CreatedBy', 'DateCreated'
        return data.sort(function (a, b) {
            var isAsc = _this.sort.direction === 'asc';
            switch (_this.sort.active) {
                case 'area': return compare(a.data.area.toLowerCase(), b.data.area.toLowerCase(), isAsc);
                case 'count': return compare(a.data.total_items_counted, b.data.total_items_counted, isAsc);
                case 'location': return compare(a.data.location_name.toLowerCase(), b.data.location_name.toLowerCase(), isAsc);
                case 'warehouse': return compare(a.data.warehouse_name.toLowerCase(), b.data.warehouse_name.toLowerCase(), isAsc);
                case 'status': return compare(a.data.status_text.toLowerCase(), b.data.status_text.toLowerCase(), isAsc);
                case 'createdBy': return compare((a.data.created_by.first_name + " " + a.data.created_by.last_name).toLowerCase(), (b.data.created_by.first_name + " " + b.data.created_by.last_name).toLowerCase(), isAsc);
                case 'dateCreated': return compare(a.data.created_at, b.data.created_at, isAsc);
                default: return 0;
            }
        });
    };
    UnkittedCountsheetDataSource.prototype.filteredData = function () {
        return this.getFilteredData(this.data);
    };
    UnkittedCountsheetDataSource.prototype.getFilteredData = function (data) {
        var _this = this;
        var filteredData = data.filter(function (el) {
            var condition = true;
            if (_this.filters.searchString) {
                condition = condition && el.data.area.toLowerCase().includes(_this.filters.searchString.toLowerCase());
            }
            if (_this.filters.status && _this.filters.status !== 'All') {
                condition = condition && el.data.status_text.toLowerCase().startsWith(_this.filters.status.toLowerCase());
            }
            if (_this.filters.recall_status && _this.filters.recall_status !== 'All') {
                condition = condition && (_this.filters.recall_status === 'Recalled' && el.data.has_recalled_items || _this.filters.recall_status === 'Not Recalled' && !el.data.has_recalled_items);
            }
            if (_this.filters.validity_status && _this.filters.validity_status !== 'All') {
                var allValid = !el.data.has_invalid_items;
                var SomeInvalid = el.data.has_invalid_items;
                condition = condition && (_this.filters.validity_status === 'All Valid' && allValid || _this.filters.validity_status === 'Some Invalid' && SomeInvalid);
            }
            if (_this.filters.auditor && _this.filters.auditor !== 'all') {
                condition = condition && el.data.created_by && el.data.created_by.user_id.toString() === _this.filters.auditor.toString();
            }
            if (_this.filters.warehouse && !['all', 'All'].includes(_this.filters.warehouse)) {
                condition = condition && el.data.warehouse_id.toString() === _this.filters.warehouse.toString();
            }
            return condition;
        });
        this.paginator.length = filteredData.length;
        return filteredData;
    };
    return UnkittedCountsheetDataSource;
}(DataSource));
export { UnkittedCountsheetDataSource };
/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
