import { environment } from '../../environments/environment';
import { urls } from '../helpers/urls';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AttachmentDatabaseService } from '../services/attachment-database.service';
import { zip } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./attachment-database.service";
var CountSheetAttachmentService = /** @class */ (function () {
    function CountSheetAttachmentService(_http, _attachmentDatabaseService) {
        this._http = _http;
        this._attachmentDatabaseService = _attachmentDatabaseService;
    }
    CountSheetAttachmentService.prototype.getAttachmentsAndPopulateOfflineData = function (auditId) {
        var that = this;
        var promise = new Promise(function (resolve, reject) {
            that.getAttachments(auditId).subscribe(function (data) {
                that._attachmentDatabaseService.deleteByIndex('audit_id', parseInt(auditId, 10)).then(function () {
                    var as = data.map(function (a) { return that._attachmentDatabaseService.new(a); });
                    that._attachmentDatabaseService.bulkAdd(as).then(function (_) {
                        resolve(as);
                    });
                });
            });
        });
        return promise;
    };
    //find synced ones for the count sheet and replace em, if there is a matching unsynced one they win
    CountSheetAttachmentService.prototype.refreshOfflineDataForCountSheet = function (auditId, countSheetClientId, data) {
        var that = this;
        var attachments = data.map(function (a) {
            return that._attachmentDatabaseService.new(a);
        });
        var promise = new Promise(function (resolve, reject) {
            that._attachmentDatabaseService.find(auditId, { synchronized: true, count_sheet_client_id: countSheetClientId }).then(function (matches) {
                zip.apply(void 0, matches.map(function (a) { return that._attachmentDatabaseService.delete(a.local_file_id); }).concat(new Promise(function (r, a) { r(null); }))).subscribe(function (_) {
                    that._attachmentDatabaseService.find(auditId, { synchronized: false, count_sheet_client_id: countSheetClientId }).then(function (matches) {
                        var unsyncedAttachmentIds = matches.map(function (a) { return a.local_file_id; });
                        var freshAttachments = attachments.filter(function (a) { return unsyncedAttachmentIds.indexOf(a.local_file_id) === -1; });
                        zip.apply(void 0, freshAttachments.map(function (a) { return that._attachmentDatabaseService.add(a); }).concat(new Promise(function (r, a) { r(null); }))).subscribe(function (_) {
                            that._attachmentDatabaseService.find(auditId, { count_sheet_client_id: countSheetClientId, toRemove: false }).then(function (matches) {
                                resolve(matches);
                            });
                        });
                    });
                });
            });
        });
        return promise;
    };
    CountSheetAttachmentService.prototype.getAttachments = function (auditId, options) {
        options = options || {};
        var params = new HttpParams().set('is_active', 'true');
        if (options['count_sheet_id']) {
            params = params.set('count_sheet_id', options['count_sheet_id']);
        }
        if (options['count_sheet_client_id']) {
            params = params.set('count_sheet_client_id', options['count_sheet_client_id']);
        }
        return this._http.get(environment.serverPath + urls.countSheetAttachments.replace(':id', auditId), { params: params });
    };
    CountSheetAttachmentService.prototype.deleteAttachment = function (file) {
        var path = environment.serverPath + urls.deleteAttachment.replace(':id', file.id.toString());
        return this._http.delete(path);
    };
    CountSheetAttachmentService.prototype.addAttachment = function (file, countSheetClientId) {
        var fd = new FormData();
        fd.append('attachment[attachment]', file);
        var path = environment.serverPath + urls.addCountSheetAttachment.replace(':id', countSheetClientId.toString());
        return this._http.post(path, fd);
    };
    CountSheetAttachmentService.prototype.getAttachment = function (file) {
        var path = environment.serverPath + urls.downloadAttachment.replace(':id', file.id.toString());
        window.open(path);
    };
    CountSheetAttachmentService.prototype.getImage = function (file) {
        var path = environment.serverPath + urls.downloadImageAttachment.replace(':id', file.id.toString());
        return this._http.get(path, { responseType: 'blob', observe: 'response' });
    };
    CountSheetAttachmentService.ngInjectableDef = i0.defineInjectable({ factory: function CountSheetAttachmentService_Factory() { return new CountSheetAttachmentService(i0.inject(i1.HttpClient), i0.inject(i2.AttachmentDatabaseService)); }, token: CountSheetAttachmentService, providedIn: "root" });
    return CountSheetAttachmentService;
}());
export { CountSheetAttachmentService };
