<div class="mat-elevation-z8">
  <div class="div">
      <button mat-raised-button class="back" type="button" (click)="goToAuditDashboard()"ngbTooltip="Go To Dashboard" placement="right">
          <mat-icon >dashboard</mat-icon>
          <span>Dashboard</span>
        </button>
  </div>
  <table mat-table #table [dataSource]="dataSource" matSort aria-label="Elements">
    <!-- part number Column -->
    <ng-container matColumnDef="part_number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Part Number</th>
      <td mat-cell *matCellDef="let row">{{row.part_number}}</td>
    </ng-container>

    <!-- lot Column -->
    <ng-container matColumnDef="lot_number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Lot Number</th>
      <td mat-cell *matCellDef="let row">{{row.lot_number}}</td>
    </ng-container>
    <!-- lot Column -->
    <ng-container matColumnDef="serial_number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Serial Number</th>
      <td mat-cell *matCellDef="let row">{{row.serial_number}}</td>
    </ng-container>
    <!-- lot Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
      <td mat-cell *matCellDef="let row">{{row.description}}</td>
    </ng-container>
    <!-- lot Column -->
    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Quantity</th>
      <td mat-cell *matCellDef="let row">{{row.quantity}}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell [ngClass]="{'failed-status': row.status === 'Failure', 'success-status': row.status === 'Success'}" *matCellDef="let row">{{row.status}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator #paginator [pageIndex]="0" [pageSize]="300" [pageSizeOptions]="[300, 600, 1000]">
  </mat-paginator>
</div>
