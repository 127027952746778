import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { urls } from '../helpers/urls';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ItemCombinationRecallAlertExceptionService = /** @class */ (function () {
    function ItemCombinationRecallAlertExceptionService(http) {
        this.http = http;
    }
    ItemCombinationRecallAlertExceptionService.prototype.get = function (auditId) {
        return this.http.get("" + environment.serverPath + urls.getAgencyItemCombinationRecallAlertExceptions.replace(":audit_id", auditId));
    };
    ItemCombinationRecallAlertExceptionService.ngInjectableDef = i0.defineInjectable({ factory: function ItemCombinationRecallAlertExceptionService_Factory() { return new ItemCombinationRecallAlertExceptionService(i0.inject(i1.HttpClient)); }, token: ItemCombinationRecallAlertExceptionService, providedIn: "root" });
    return ItemCombinationRecallAlertExceptionService;
}());
export { ItemCombinationRecallAlertExceptionService };
