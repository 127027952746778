import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { AuditWarehousesDataSource } from './audit-warehouses-datasource';
import { AuditService } from './../../services/audit.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-audit-warehouses',
  templateUrl: './audit-warehouses.component.html',
  styleUrls: ['./audit-warehouses.component.css']
})
export class AuditWarehousesComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() auditID: string;
  @Input() isOnline: boolean;
  dataSource: AuditWarehousesDataSource;
  warehouses = [];
  statuses = ['Planned', 'In-Progress', 'Completed'];
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['name', 'status'];
  constructor(private _auditService: AuditService,  private _router: Router) { }

  ngOnInit() {
    this._auditService.getUsedWarehouses(this.auditID).subscribe(data => {
      this.warehouses = data;
      this.dataSource = new AuditWarehousesDataSource(this.paginator, this.sort, this.warehouses);
    });
  }

  ngOnDestroy(): void {
    
  }

  goToWarehouseResults(row) {
    this._router.navigate([this.auditID, 'warehouse_dashboard', row.id]);

  }

  getStatus(status) {
    return this.statuses[status];
  }

}
