<div *ngIf="loading" class="spinner">
  <mat-spinner diameter="50" color="primary">
  </mat-spinner>
</div>
<div [ngClass]="{'disabled' : loading}">
  <h5 mat-dialog-title>Redspot Inventory Import</h5>
  <div class="content_left">
    <mat-form-field class="drop-down-list">
      <mat-select [(ngModel)]="selectedItems" name="warehouses" placeholder="Warehouses" multiple (selectionChange)="toggleSingleSelection($event)">
        <mat-checkbox class="mat-option" [disableRipple]="true"
          (change)="toggleSelection($event)" [checked]="allSelected">
          Select All
        </mat-checkbox>
          <mat-option *ngFor="let warehouse of warehouses" [value]="warehouse.account_id">
            {{warehouse.name}}
          </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="action_right">
    <button mat-button (click)="createRedspotInventory()" [ngClass]="{'disabled': selectedItems.length == 0 }" >Import</button>
  </div>
  <div class="clear_both"></div>
  <mat-error *ngIf="audit.redspot_inventory_imported_date">
    Inventory has already been imported, importing new inventory may reset counted kits progress.
  </mat-error>

  <div mat-dialog-actions class="pull-right">
      <button mat-button (click)="onNoClick()">Cancel</button>
    </div>
</div>
