import { DataSource } from '@angular/cdk/collections';
import { MatPaginator, MatSort } from '@angular/material';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge } from 'rxjs';

// TODO: Replace this with your own data model type
export interface WarehouseCountedItem {
  item: any;
  kit_instance: any;
  lot_number: any;
  serial_number: any;
  actual_quantity: any;
  expected_quantity: any;
  variance: any;
  kit_reference: any;
  kit_lot_number: any;
}
/**
 * Data source for the WarehouseCounted view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class WarehouseCountedDataSource extends DataSource<WarehouseCountedItem> {
  data: WarehouseCountedItem[] = [];
  dataMutations;
  constructor(private paginator: MatPaginator, private sort: MatSort, private counts, private filters) {
    super();
    this.data = counts;
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<WarehouseCountedItem[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    this.dataMutations = [
      observableOf(this.data),
      this.paginator.page,
      this.sort.sortChange
    ];

    // Set the paginators length
    this.paginator.length = this.data.length;

    return merge(...this.dataMutations).pipe(map(() => {
      const filteredData = this.getPagedData(this.getSortedData(this.getFilteredData([...this.data])));
      return filteredData;
    }));
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {}

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: WarehouseCountedItem[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: WarehouseCountedItem[]) {
    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
      case 'reference': return compare(a.item.reference, b.item.reference, isAsc);
      case 'description': return compare(a.item.description, b.item.description, isAsc);
      case 'kit': return compare(a.kit_reference, b.kit_reference, isAsc);
      case 'kit_lot_number': return compare(a.kit_lot_number, b.kit_lot_number, isAsc);
      case 'lot': return compare(a.lot_number, b.lot_number, isAsc);
      case 'serial_number': return compare(a.serial_number, b.serial_number, isAsc);
      case 'quantity': return compare(a.actual_quantity, b.actual_quantity, isAsc);
      case 'expected': return compare(a.expected_quantity, b.expected_quantity, isAsc);
      case 'overage': return compare((a.actual_quantity - a.expected_quantity), (b.actual_quantity - b.expected_quantity), isAsc);
      case 'shortage': return compare((a.actual_quantity - a.expected_quantity), (b.actual_quantity - b.expected_quantity), isAsc);
      default: return 0;
      }
    });
  }

  public filteredData() {
    return this.getFilteredData(this.data);
  }

  private getFilteredData(data: any[]) {
    const filteredData =  data.filter((el: WarehouseCountedItem) => {
      let condition = true;
      if (this.filters.kitSearchString) {
        condition = el.item.reference.toLowerCase().includes(this.filters.kitSearchString.toLowerCase()) ||
          el.item.unpunctuated_reference.toLowerCase().includes(this.filters.kitSearchString.toLowerCase()) ||
          el.kit_reference.toLowerCase().includes(this.filters.kitSearchString.toLowerCase());
      }
      return condition;
    });
    this.paginator.length = filteredData.length;
    return filteredData;
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
