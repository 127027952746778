import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialogRef } from '@angular/material';
import { ISnackBarConfig } from 'src/app/interfaces/isnack-bar-config';
import { ItemCombinationService } from 'src/app/services/item-combination.service';
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';
import { Observer } from 'rxjs';
import { ExportToExcelService } from 'src/app/services/export-to-excel.service';
import { IitemCombination } from 'src/app/interfaces/iitem-combination';

@Component({
  selector: 'app-batch-validation-import',
  templateUrl: './batch-validation-import.component.html',
  styleUrls: ['./batch-validation-import.component.css']
})
export class BatchValidationImportComponent implements OnInit {

  selectedFile = null;
  pickedFile: any = null;
  loading = false;
  itemCombinationCount = 0;
  tab: any;
  itemCombinations: IitemCombination[];

  snackBarConfig: ISnackBarConfig = {
    message: '',
    duration: 3000,
    success: false,
    snackBarClass: ''
  };

  constructor(
    public snackBar: MatSnackBar,
    private _itemCombinationService: ItemCombinationService,
    public dialogRef: MatDialogRef<BatchValidationImportComponent>,
    private exportToExcelService: ExportToExcelService
  ) { }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
  }
  ngOnInit() {

    this._itemCombinationService.getManufacturerItemCombinationCount().subscribe(data => {
      this.itemCombinationCount = data;
    });
  }

  onUpload() {
    const fd = new FormData();
    fd.append('file', this.selectedFile, this.selectedFile.name);

    this.loading = true;
    this._itemCombinationService.upload(fd).subscribe(res => {
      if (res.success) {
        this.selectedFile = null;
        this.pickedFile = null;
        this.snackBarConfig.message = 'File Uploaded Successfully';
        this.snackBarConfig.success = true;
        this.loading = false;
        setTimeout(() => {
          this.dialogRef.close(res.audit);
        }, 2000);
        this.openSnackBar();
      } else {
        this.loading = false;
        this.snackBarConfig.message = res.message;
        this.snackBarConfig.success = false;
        this.openSnackBar();
      }
    });
  }

  exportFrontEnd() {
    // get the data
    this.loading = true;
    this._itemCombinationService.get().subscribe(data => {
      this.itemCombinations = data;
      this.exportToExcelService.exportAsExcelFile(
        this.itemCombinations.map(function (ic) {
          return {
            reference: ic.item.reference,
            description: ic.item.description,
            lot: ic.lot_number.value,
            serial: ic.serial_number.value
          };
        }),
        'item_combinations', {});
      this.loading = false;
      this.dialogRef.close();
    });
  }

  exportObserver: Observer<any> = {
    next: data => {
      const reader = new FileReader();
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      reader.readAsDataURL(blob);

      reader.onloadend = () => {
        this.tab.location.href = reader.result.toString();
      };
    },
    error: () => {
    },
    complete: () => {}
  };

  templateFrontEnd() {
    this.loading = true;
    this.exportToExcelService.exportAsExcelFile([{reference: '', lot: '', serial: ''}], 'item_combinations', {});
    this.loading = false;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  openSnackBar() {
    this.snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: this.snackBarConfig,
      duration: this.snackBarConfig.duration
    });
  }
}
