import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class KitInstanceService {

  constructor(private http: HttpClient) { }

  getKitInstanceByUUID(uuid: string) {
    const params = new HttpParams().set('uuid', uuid)
    return this.http.get<any>(`${environment.serverPath}api/kit_instances`, {params});
  }

  getKitInstanceById(id: number) {
    return this.http.get<any>(`${environment.serverPath}api/kit_instances/${id}`);
  }
}
