import { detect } from 'detect-browser';
import { BrowserNames } from '../helpers/browser-names';
import * as i0 from "@angular/core";
var FeatureSupportService = /** @class */ (function () {
    function FeatureSupportService() {
        this.browserData = detect();
    }
    FeatureSupportService.prototype.isFireFox = function () {
        return this.browserData.name === BrowserNames.FIREFOX;
    };
    FeatureSupportService.prototype.isChrome = function () {
        return this.browserData.name === BrowserNames.CHROME;
    };
    FeatureSupportService.ngInjectableDef = i0.defineInjectable({ factory: function FeatureSupportService_Factory() { return new FeatureSupportService(); }, token: FeatureSupportService, providedIn: "root" });
    return FeatureSupportService;
}());
export { FeatureSupportService };
