<div class="col-md-12 warehouse-table">
  <h5 class="header-title">
    Warehouses
  </h5>
  <div class="mat-elevation-z8 data-table">
    <table mat-table #table [dataSource]="dataSource" matSort aria-label="Elements">

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Name</th>
        <td mat-cell *matCellDef="let row">{{row.name}}</td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Status</th>
        <td mat-cell *matCellDef="let row">
          <span>{{ getStatus(row.status) }}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="table-row" *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'disabled': !isOnline }"></tr>
    </table>

    <mat-paginator #paginator [pageIndex]="0" [pageSize]="10" [pageSizeOptions]="[10, 15, 20, 25]">
    </mat-paginator>
  </div>

  <!-- <button (click)="ngOnInit()">get results </button> -->
</div>
