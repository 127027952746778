import { Injectable } from '@angular/core';
import { DatabaseService } from './database.service';
import * as moment from 'moment';
import { CountSheetStatusHelper } from '../helpers/count-sheet-status-helper';
import { ICountSheetData } from '../interfaces/icount-sheet-data';
import { IuserData } from '../interfaces/iuser-data';
import { zip } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountSheetDatabaseService {

  constructor(
    private _databaseService: DatabaseService
  ) { }

  storeName = 'count_sheets';

  new(data): ICountSheetData {
    const cs: ICountSheetData = {
      dbId: data.id,
      id: data.client_id,
      isSynchronized: 1,
      data: {
        reference: (((data.audit_kit_instance || {}).item_instance || {}).item || {}).reference || '',
        lot_number: ((data.audit_kit_instance || {}).item_instance || {}).lot_number || '',
        isKitted: !!data.audit_kit_instance_id,
        audit_id: data.audit_id,
        area: data.area,
        audit_kit_instance_id: data.audit_kit_instance_id,
        audit_location_id: data.audit_location_id,
        location_name: data.audit_location.location_name,
        count_sheet_status: data.count_sheet_status,
        warehouse_id: data.warehouse_id,
        warehouse_name: data.warehouse.name,
        status_text: CountSheetStatusHelper['NAMES'][data.count_sheet_status],
        created_at: moment(data.created_at).format('ll'),
        has_invalid_items: data.has_invalid_items,
        has_recalled_items: data.has_recalled_items,
        kit_id: data.kit_id,
        total_items_counted: data.total_items_counted,
        attachments_count: data.attachments_count,
        archived: data.archived,
        show_expected_item_list: data.show_expected_item_list,
        created_by_id: data.created_by_id,
        counted_by_id: data.counted_by_id,
        counted_time: data.counted_time
      }

    };
    if (data.audit_kit_instance) {
      cs.data.audit_kit_instance = data.audit_kit_instance;
    }

    if (data.created_by) {
      const user: IuserData = {
        first_name: data.created_by.first_name,
        last_name: data.created_by.last_name,
        user_id: data.created_by.id,
        name: data.created_by.name,
        email_address: data.created_by.email_address
      };
      cs.data.created_by = user;
      cs.data.created_by_id = data.created_by.id;
    }

    if (data.counted_by) {
      const user: IuserData = {
        first_name: data.counted_by.first_name,
        last_name: data.counted_by.last_name,
        user_id: data.counted_by.id,
        name: data.counted_by.name,
        email_address: data.counted_by.email_address
      };
      cs.data.counted_by = user;
      cs.data.counted_by_id = data.counted_by.id;
    }

    return cs;
  }

  initializeValues(cs) {
    if (!cs.id || cs.id === "0") {
      cs.id = this.createClientId();
    }
    cs.data.attachments_count = cs.data.attachments_count || 0;
    cs.data.archived = !!cs.data.archived;
    return cs;
  }

  syncValues(cs) {
    cs.data.status_text = CountSheetStatusHelper['NAMES'][cs.data.count_sheet_status];
    return cs;
  }

  get(clientId) {
    return this._databaseService.get(this.storeName, clientId);
  }

  bulkAdd(datas) {
    const that = this;
    return new Promise((resolve, reject) => {
      if (datas.length === 0) {
        return resolve(true);
      }

      const countSheets = datas.map(function (data) {
        return that.syncValues(that.initializeValues(data));
      });
      that._databaseService.bulkAdd(that.storeName, countSheets).then(() => {
        return resolve(true);
      });
    });
  }

  add(cs: ICountSheetData) {
    const that = this;
    cs = that.syncValues(that.initializeValues(cs));
    return that._databaseService.add(this.storeName, cs).then(function (_) {
      that.denormalizeAuditKitInstance(cs.data.audit_kit_instance_id, cs);
      return cs.id;
    });
  }

  update(cs: ICountSheetData) {
    const that = this;
    return new Promise((resolve, reject) => {
      if (!cs.id || cs.id === "0") {
        console.log(cs);
        throw new Error('how can you update without a primary key?');
      }
      cs = that.syncValues(cs);
      this.get(cs.id).then(function (oldCountSheet: ICountSheetData) {
        const totalItemsCountUpdated = cs.data.total_items_counted !== oldCountSheet.data.total_items_counted;
        const statusChanged = cs.data.count_sheet_status !== oldCountSheet.data.count_sheet_status;
        const archiveChanged = cs.data.archived && !oldCountSheet.data.archived;
        const kitIdChanged = cs.data.kit_id !== oldCountSheet.data.kit_id;
        const showExpectedItemListChanged = cs.data.show_expected_item_list && !oldCountSheet.data.show_expected_item_list;
        that._databaseService.update(that.storeName, cs).then(function (results: any) {
          const promises = [];
          if (totalItemsCountUpdated || statusChanged) {
            promises.push(that.denormalizeAuditKitInstance(cs.data.audit_kit_instance_id, cs));
          }
          if (kitIdChanged) {
            promises.push(that.updateKitInstanceItemRootItemId(cs.data.audit_kit_instance_id, cs.data.kit_id));
          }
          if (archiveChanged) {
            promises.push(that.disassociateAuditKitInstance(cs.data.audit_kit_instance_id));
            promises.push(that.archiveCountSheetItems(cs));
          }
          if (showExpectedItemListChanged) {
            promises.push(that.combineCountSheetItems(cs));
          }
          zip(
            ...promises.concat(new Promise((r, a) => { r(null); }))
          ).subscribe(_ => {
            resolve(results);
          });
        });
      });
    });
  }

  deleteByIndex(index, value) {
    return this._databaseService.deleteByIndex(this.storeName, index, value);
  }

  getDataByIndex(index, value) {
    return this._databaseService.getDataByIndex(this.storeName, index, value);
  }

  find(auditId, params) {
    const that = this;
    return new Promise((resolve, reject) => {
      that._databaseService.getDataByIndex(that.storeName, 'data.audit_id', parseInt(auditId, 10)).then((matches: any[]) => {

        if (params.audit_location_id && params.audit_location_id !== -1) {
          matches = matches.filter(cs => parseInt(cs.data.audit_location_id, 10) === parseInt(params.audit_location_id, 10));
        }
        if (params.audit_kit_instance_id) {
          matches = matches.filter(cs => cs.data.audit_kit_instance_id && (parseInt(cs.data.audit_kit_instance_id, 10) === parseInt(params.audit_kit_instance_id, 10)));
        }
        if (params.null_audit_kit_instance_id) {
          matches = matches.filter(cs => !cs.data.audit_kit_instance_id);
        }
        if (params.not_null_audit_kit_instance_id) {
          matches = matches.filter(cs => cs.data.audit_kit_instance_id);
        }
        if (params.not_client_id) {
          matches = matches.filter(cs => cs.id !== params.not_client_id);
        }
        if (params.area) {
          matches = matches.filter(cs => cs.data.area.trim() === params.area.trim());
        }
        if (params.archived !== undefined) {
          matches = matches.filter(cs => !!cs.data.archived === !!params.archived);
        }

        resolve(matches);
      });
    });
  }

  clear() {
    return this._databaseService.clear(this.storeName);
  }

  createClientId() {
    return `1${Math.random().toString().substring(2, 10)}${Math.random().toString().substring(2, 10)}`;
  }

  updateKitInstanceItemRootItemId(auditKitInstanceId, kitId) {
    if (!auditKitInstanceId) {
      return new Promise((r, a) => { r(null); });
    }
    const that = this;
    return new Promise((resolve, reject) => {
      that._databaseService.get('audit_kit_instances', auditKitInstanceId).then(function (aki: any) {
        that._databaseService.getDataByIndex('audit_kit_instances', 'data.item_instance.item_id', aki.data.item_instance.item_id).then((matches: any[]) => {
          const promises = matches.map(match => {
            match.data.item_instance.root_item_id = kitId;
            return that._databaseService.update('audit_kit_instances', match);
          });
          zip(
            ...promises.concat(new Promise((r, a) => { r(null); }))
          ).subscribe(_ => {
            resolve(null);
          });
        });
      });
    });
  }

  disassociateAuditKitInstance(auditKitInstanceId) {
    if (!auditKitInstanceId) {
      return new Promise((r, a) => { r(null); });
    }
    const that = this;
    const status = 1;
    return new Promise((resolve, reject) => {
      that._databaseService.get('audit_kit_instances', auditKitInstanceId).then(function (aki: any) {
        aki.data.status = status;
        aki.data.statusText = CountSheetStatusHelper['NAMES'][status];
        aki.data.has_count_sheet = false;
        aki.data.count_sheet = null;
        aki.data.counted_by = null;
        aki.data.counted_time = '';

        that._databaseService.update('audit_kit_instances', aki).then(_ => {
          resolve(null);
        });
      });
    });
  }

  combineCountSheetItems(countSheet) {
    const that = this;
    return new Promise((resolve, reject) => {
      that._databaseService.getDataByIndex('count_sheet_items', 'data.audit_id', parseInt(countSheet.data.audit_id, 10)).then(function (results: any[]) {
        const matches = results.filter(csi => csi.data.count_sheet_client_id === countSheet.id && !csi.data.hide && !csi._destroy);
        const groupings = [];
        matches.forEach(function (csi) {
          let match = groupings.find(function (g) {
            const itemIdMatch = csi.data.item_id === g.item_id;
            const lotNumberMatch = (csi.data.lot_number || '') === (g.lot_number || '');
            const serialNumberMatch = (csi.data.serial || '') === (g.serial_number || '');
            const checkedMatch = !!csi.data.checked === !!g.checked;
            return itemIdMatch && lotNumberMatch && serialNumberMatch && checkedMatch;
          });
          if (!match) {
            match = {
              item_id: csi.data.item_id,
              lot_number: csi.data.lot_number,
              serial_number: csi.data.serial,
              checked: csi.data.checked,
              quantity: 0,
              expiration_dates: [],
              csis: []
            };
            groupings.push(match);
          }
          match.quantity += csi.data.quantity;
          if (csi.data.expiration_date) {
            match.expiration_dates.push(csi.data.expiration_date);
          }
          match.csis.push(csi);
        });
        const promises = [];
        groupings.forEach(function (group) {
          if (group.csis.length > 1) {
            group.csis.forEach(function (csi) {
              csi.isSynchronized = 0;
              csi._destroy = true;
              csi.data.quantity = 0;
              csi.data.expected_quantity = 0;
              csi.data.checked = false;
            });
            group.csis[0]._destroy = false;
            group.csis[0].data.checked = true;
            group.csis[0].data.quantity = group.quantity;
            group.csis[0].expiration_date = group.expiration_dates[0] || null;
            group.csis.forEach(function (csi) {
              if (csi._destroy && !csi.dbId) {
                promises.push(that._databaseService.delete('count_sheet_items', csi.id));
              } else {
                promises.push(that._databaseService.update('count_sheet_items', csi));
              }
            });
          }
        });
        zip(
          ...promises.concat(new Promise((r, a) => { r(null); }))
        ).subscribe(_ => {
          resolve(null);
        });
      });
    });
  }

  archiveCountSheetItems(countSheet) {
    const that = this;
    return new Promise((resolve, reject) => {
      that._databaseService.getDataByIndex('count_sheet_items', 'data.audit_id', parseInt(countSheet.data.audit_id, 10)).then(function (results: any[]) {
        const matches = results.filter(csi => csi.data.count_sheet_client_id === countSheet.id);
        const removableCountSheetItems = matches.filter(csi => {
          return !csi.dbId;
        });
        const archivableCountSheetItems = matches.filter(csi => {
          return !!csi.dbId;
        });
        //if they were never saved we can just remove em
        const removablePromises = removableCountSheetItems.map(csi => that._databaseService.delete('count_sheet_items', csi.id));
        const archivablePromises = archivableCountSheetItems.map(function (csi) {
          csi.isSynchronized = 0;
          csi._destroy = true;
          csi.data.quantity = 0;
          csi.data.serial = '';
          csi.data.lot_number = '';
          return that._databaseService.update('count_sheet_items', csi);
        });
        zip(
          ...removablePromises.concat(archivablePromises).concat(new Promise((r, a) => { r(null); }))
        ).subscribe(_ => {
          resolve(null);
        });
      });
    });
  }

  clearUnsyncedItems(countSheet:ICountSheetData){
    const that = this;
    return new Promise((resolve, reject) => {
      that._databaseService.getDataByIndex('count_sheet_items', 'data.audit_id', countSheet.data.audit_id).then(function (results: any[]) {
        const matches = results.filter(csi => csi.data.count_sheet_client_id === countSheet.id);

        const removableCountSheetItems = matches.filter(csi => {
          return !csi.isSynchronized;
        });
        
        //if they were never saved we can just remove em
        const removablePromises = removableCountSheetItems.map(csi => that._databaseService.delete('count_sheet_items', csi.id));
        
        zip(
          ...removablePromises.concat(new Promise((r, a) => { r(null); }))
        ).subscribe(_ => {
          resolve(null);
        });
      });
    });
  }

  denormalizeAuditKitInstance(auditKitInstanceId, cs) {
    if (!auditKitInstanceId) {
      return new Promise((r, a) => { r(null); });
    }
    const that = this;
    return new Promise((resolve, reject) => {
      that._databaseService.get('audit_kit_instances', auditKitInstanceId).then(function (aki: any) {
        if (!cs.data.archived) {
          aki.data.has_count_sheet = true;
          aki.data.status = cs.data.count_sheet_status;
          aki.data.statusText = cs.data.status_text;
          aki.data.counted_by = cs.data.counted_by;
          aki.data.counted_time = cs.data.counted_time;
        }
        that._databaseService.update('audit_kit_instances', aki).then(_ => {
          resolve(null);
        });
      });
    });
  }
}
