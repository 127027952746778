import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TrackLoadingService {
  loading = {};
  startLoadingEvent: EventEmitter<any>;
  stopLoadingEvent: EventEmitter<any>;
  updateCurrentLoadingEvent: EventEmitter<any>;

  constructor() {
    this.startLoadingEvent = new EventEmitter<any>();
    this.stopLoadingEvent = new EventEmitter<any>();
    this.updateCurrentLoadingEvent = new EventEmitter<any>();
  }

  startLoading(key: string, message: string) {
    let that = this;
    if (Object.keys(that.loading).length === 0) {
      that.startLoadingEvent.emit();
    }
    that.loading[key] = message;
    that.updateCurrentLoadingEvent.emit(that.loading);
  }
  stopLoading(key: string) {
    let that = this;
    delete that.loading[key];
    that.updateCurrentLoadingEvent.emit(that.loading);
    if (Object.keys(that.loading).length === 0) {
      that.stopLoadingEvent.emit();
    }
  }
}
