<div class="col-md-12 location-table">
  <h5 class="header-title">
    Sites
  </h5>
  <div class="mat-elevation-z1 data-table">
    <table mat-table #table [dataSource]="dataSource" matSort aria-label="Elements">
      <!-- Sites Column -->
      <ng-container matColumnDef="site_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Name</th>
        <td mat-cell *matCellDef="let row" class="site-name">{{row.location_name}}</td>
      </ng-container>
      <!--Warehouse Column-->
      <ng-container matColumnDef="warehouses">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Warehouses</th>
        <td mat-cell *matCellDef="let row" style="width: 50%;" class="w-row">
          <mat-chip-list  [ngClass]="{'disabled': !isOnline }">
            <mat-basic-chip [removable]="true" (removed)="removeWarehouse(warehouse)" color="primary" class="chip basic"
              *ngFor="let warehouse of row.site_warehouses;let i=index" selected>
              {{warehouse.warehouse.name}}
              <span *ngxPermissionsOnly="['audit_administrator']">
                <mat-icon matChipRemove class="remove-chip" *ngIf="!warehouse.has_count_sheet">cancel</mat-icon>
                <mat-icon matChipRemove class="cant-remove-chip" *ngIf="warehouse.has_count_sheet">block</mat-icon>
              </span>
            </mat-basic-chip>
          </mat-chip-list>
        </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Status</th>
        <td mat-cell *matCellDef="let row">{{statuses[row.status]}}</td>
      </ng-container>

      <!--Add Sites Button Column-->
      <ng-container matColumnDef="addLocation">
        <th mat-header-cell *matHeaderCellDef class="table-header">
          <span *ngxPermissionsOnly="['audit_administrator']"  [ngClass]="{'disabled': !isOnline || audit.status === 'Completed' }">
            <span class="tooltipWrapper">
              <button *ngIf="warehouses" mat-icon-button type="button" ngbTooltip="Add Site" placement="right" (click)="openAddDialog()">
                <mat-icon class="material-icons">add_circle</mat-icon>
              </button></span>
          </span>
        </th>
        <td class="addLocationButtonColumn" mat-cell *matCellDef="let row">
          <div class="row">
            <!--View CountSheets Button-->
            <span class="tooltipWrapper">
              <button mat-icon-button type="button" style="color: lightgray" (click)="showInventoryWarningMessage()"
                ngbTooltip="Please Import Redspot and SAP Inventory" placement="top" *ngIf="!redspotInventoryImported || !sapInventoryImported">
                <mat-icon class="material-icons">view_list</mat-icon>
              </button>
            </span>
            <span class="tooltipWrapper">
              <button mat-icon-button type="button" (click)="goToCountSheets(row)" ngbTooltip="Create/View Count Sheets"
                placement="top" *ngIf="redspotInventoryImported && sapInventoryImported">
                <mat-icon class="material-icons">view_list</mat-icon>
              </button>
            </span>
            <span *ngxPermissionsOnly="['audit_administrator']"  [ngClass]="{'disabled': !isOnline }">
              <!--Edit Button-->
              <span class="tooltipWrapper">
                <button mat-icon-button [ngClass]="{'disabled': !isOnline || audit.status === 'Completed'}" type="button" (click)="openDialog(row)" ngbTooltip="Edit" placement="top">
                  <mat-icon class="material-icons">edit</mat-icon>
                </button>
              </span>
              <!--Delete Button-->
              <span class="tooltipWrapper">
                <button mat-icon-button type="button" (click)="deleteLocation(row)" ngbTooltip="Remove" placement="top"
                  *ngIf="row.status === 0">
                  <mat-icon class="material-icons">delete</mat-icon>
                </button>
              </span>

              <!--Mark As Complete Button-->
              <span class="tooltipWrapper">
                <button mat-icon-button type="button" (click)="finishLocation(row)" ngbTooltip="Mark Site As Complete"
                  placement="top" *ngIf=" row.status !== 2 && row.status !== 0">
                  <mat-icon class="material-icons">check</mat-icon>
                </button>
              </span>
            </span>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <!-- Paginator -->
    <mat-paginator #paginator [pageSize]="10"  [pageSizeOptions]="[10,50,100,1000]">
    </mat-paginator>
  </div>
</div>
