var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { RouteReuseStrategy } from "@angular/router";
var AuditRouteStrategy = /** @class */ (function (_super) {
    __extends(AuditRouteStrategy, _super);
    function AuditRouteStrategy() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AuditRouteStrategy.prototype.shouldDetach = function (route) {
        return false;
    };
    AuditRouteStrategy.prototype.store = function (route, handle) {
    };
    AuditRouteStrategy.prototype.shouldAttach = function (route) {
        return false;
    };
    AuditRouteStrategy.prototype.retrieve = function (route) {
        return null;
    };
    AuditRouteStrategy.prototype.shouldReuseRoute = function (future, curr) {
        return false; // default is true if configuration of current and future route are the same
    };
    return AuditRouteStrategy;
}(RouteReuseStrategy));
export { AuditRouteStrategy };
