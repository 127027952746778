import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class KitService {

  constructor(private http: HttpClient) { }

  getKits() {
    return this.http.get<any>(`${environment.serverPath}audit_api/manufacturers/1/kits`);
  }
}
