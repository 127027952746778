<div class="header-title">
  {{title}}
</div>
<div class="row">
  <div class="col-md-8">
    <form (ngSubmit)="saveClicked(auditForm.form)" #auditForm="ngForm">
      <div class="row">
        <div class="col-md-12 form-group">
          <mat-form-field class="audit-input">
            <input matInput placeholder="Location" [(ngModel)]="newAudit.location_name" name="location_name" required>
          </mat-form-field>
        </div>
        <div class="col-md-12 form-group" *ngIf="!data.id">
          <mat-form-field class="audit-input">
            <mat-select placeholder="Agency" [(ngModel)]="newAudit.warehouse_id" name="warehouse_id" required>
              <mat-option *ngFor="let agency of distributors" [value]="agency.account_id">{{agency.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6 form-group">
          <mat-form-field class="audit-input">
            <input matInput [matDatepicker]="picker" name="start_date" placeholder="Choose start date" [(ngModel)]="newAudit.start_date"
              required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-6 form-group">
          <mat-form-field class="audit-input">
            <input matInput [matDatepicker]="endDatePicker" name="end_date" placeholder="Choose end date" [(ngModel)]="newAudit.end_date">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <div class="col-md-4 buttons">
      <button mat-button class="cancel-button" (click)="onNoClick()">Cancel</button>
      <button mat-button class="save-button" [disabled]="(!newAudit.location_name || !newAudit.warehouse_id || !newAudit.start_date) || saving" (click)="saveClicked(auditForm.form)" type="submit">Save</button>
      <button mat-button class="serious-button" *ngIf="!creating"  (click)="archiveAudit()">Archive</button>
  </div>
</div>
