import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ResultFileDialogComponent } from '../result-file-dialog/result-file-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { AuditUserActionsLogDialogComponent } from '../audit-user-actions-log-dialog/audit-user-actions-log-dialog.component';


@Component({
  selector: 'app-result-files',
  templateUrl: './result-files.component.html',
  styleUrls: ['./result-files.component.css']
})
export class ResultFilesComponent implements OnInit {
  @Input() isOnline: boolean;
  auditId;
  constructor(
    public dialog: MatDialog,
    private _route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.auditId = this._route.snapshot.paramMap.get('id');
  }

  openAuditTrailDialog() {
    const dialogRef = this.dialog.open(AuditUserActionsLogDialogComponent, {
      width: '30%',
      data: {
        audit_id: this.auditId
      }
    })
  }

  openSAPReconciliationProcessFileDialog() {
    const dialogRef = this.dialog.open(ResultFileDialogComponent, {
      width: '30%',
      data: {
        title: 'Consolidated Inventory File',
        generate_key: 'generate_count_result_file',
        audit_id: this.auditId
      },
    });
  }

  openRedspotUpdateProcessFileDialog() {
    const dialogRef = this.dialog.open(ResultFileDialogComponent, {
      width: '30%',
      data: {
        title: 'Redspot Update Files',
        generate_key: 'generate_redspot_result_file',
        audit_id: this.auditId
      },
    });
  }

  openSAPCompareFileDialog() {
    const dialogRef = this.dialog.open(ResultFileDialogComponent, {
      width: '30%',
      data: {
        title: 'Inventory Variances File',
        generate_key: 'generate_sap_reconciliation_result_file',
        audit_id: this.auditId
      },
    });
  }
}
