import { DataSource } from '@angular/cdk/collections';
import { MatPaginator, MatSort } from '@angular/material';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge } from 'rxjs';
import { ITableFilter } from '../../interfaces/itable-filter';
import { IkitInstance } from '../../interfaces/ikit-instance';

/**
 * Data source for the AuditKitInstancesList view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class AuditKitInstancesListDataSource extends DataSource<any> {
  data: Object[] = [];
  dataAfterFilter: Object[] = [];
  constructor(
    private paginator: MatPaginator,
    private sort: MatSort,
    private _auditKitInstances: IkitInstance[],
    private filters: ITableFilter
  )
    {
      super();
      this.data = this._auditKitInstances;
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<any[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      observableOf(this.data),
      this.paginator.page,
      this.sort.sortChange
    ];


    // Set the paginators length
    this.paginator.length = this.data.length;

    return merge(...dataMutations)
      .pipe(map(() => {
        const filteredData = this.getPagedData(this.getSortedData(this.getFilteredData([...this.data])));
        this.dataAfterFilter = filteredData;
        return filteredData;
      }));
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {
  }

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: Object[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: any[]) {
    if (!this.sort.active) {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
      case 'reference': return compare(a.data.item_instance.reference.toLowerCase(), b.data.item_instance.reference.toLowerCase(), isAsc);
      case 'description': return compare(a.data.item_instance.description.toLowerCase(), b.data.item_instance.description.toLowerCase(), isAsc);
      case 'lot_number': return compare((a.data.item_instance.lot_number || '').toLowerCase(), (b.data.item_instance.lot_number || '').toLowerCase(), isAsc);
      case 'item_count': return compare(+a.data.item_count, +b.data.item_count, isAsc);
      case 'current_count': return compare(a.data.count_sheet ? +a.data.count_sheet.data.total_items_counted : -1, b.data.count_sheet ? +b.data.count_sheet.data.total_items_counted : -1, isAsc);
      case 'warehouse': return compare(a.data.warehouse_name.toLowerCase(), b.data.warehouse_name.toLowerCase(), isAsc);
      case 'site': return compare((((a.data.count_sheet || {}).data || {}).location_name || '').toLowerCase(), (((b.data.count_sheet || {}).data || {}).location_name || '').toLowerCase(), isAsc);
      case 'status': return compare(a.data.status, b.data.status, isAsc);
      default: return 0;
      }
    });
  }

  public filteredData() {
    return this.getFilteredData(this.data);
  }

  private getFilteredData(data: any[]) {
    const filteredData =  data.filter((el: IkitInstance) => {
      let condition = true;
      if (this.filters.kitSearchString) {
        condition = el.data.item_instance.reference.toLowerCase().includes(this.filters.kitSearchString.toLowerCase()) ||
          el.data.item_instance.lot_number.toLowerCase().includes(this.filters.kitSearchString.toLowerCase()) ||
          el.data.item_instance.description.toLowerCase().includes(this.filters.kitSearchString.toLowerCase()) ||
          el.data.item_instance.unpunctuated_reference.toLowerCase().includes(this.filters.kitSearchString.toLowerCase());
        if (el.data.counted_by) {
          condition = condition || el.data.counted_by.name.toLowerCase().includes(this.filters.kitSearchString.toLowerCase());
        }
      }

      if (this.filters.status && this.filters.status !== 'All') {
        condition = condition && el.data.statusText.toLowerCase().startsWith(this.filters.status.toLowerCase());
      }
      if (this.filters.recall_status && this.filters.recall_status !== 'All') {
        condition = condition && (this.filters.recall_status === 'Recalled' && el.data.count_sheet && el.data.count_sheet.data.has_recalled_items || this.filters.recall_status === 'Not Recalled' && (!el.data.count_sheet || !el.data.count_sheet.data.has_recalled_items));
      }

      if (this.filters.orderType && this.filters.orderType !== 'all') {
        condition = condition && (this.filters.orderType === 'consigned' && el.data.has_consigned_items || this.filters.orderType === 'owned' && el.data.has_owned_items);
      }

      if (this.filters.validity_status && this.filters.validity_status !== 'All') {
        const allValid = !el.data.count_sheet || !el.data.count_sheet.data.has_invalid_items;
        const SomeInvalid = el.data.count_sheet && el.data.count_sheet.data.has_invalid_items;
        condition = condition && (this.filters.validity_status === 'All Valid' && allValid || this.filters.validity_status === 'Some Invalid' && SomeInvalid);
      }

      if (this.filters.auditor && this.filters.auditor !== 'all') {
        condition = condition && el.data.count_sheet && el.data.count_sheet.data.created_by_id.toString() === this.filters.auditor.toString();
      }

      if (this.filters.site && this.filters.site !== 'all') {
        condition = condition && el.data.count_sheet && el.data.count_sheet.data.audit_location_id.toString() === this.filters.site.toString();
      }

      if (this.filters.warehouse && !['all', 'All'].includes(this.filters.warehouse)) {

        if (el.data.count_sheet) {
          condition = condition && el.data.count_sheet.data.warehouse_id.toString() === this.filters.warehouse.toString();

        } else {
          condition = condition && el.data.warehouse_id.toString() === this.filters.warehouse.toString();
        }
      }

      return condition;
    });
    this.paginator.length = filteredData.length;
    return filteredData;
  }

  public getDataForExport() {
    return this.getSortedData(this.getFilteredData([...this.data]));
  }
}

function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
