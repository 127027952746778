import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserRoleService = /** @class */ (function () {
    function UserRoleService(http) {
        this.http = http;
    }
    UserRoleService.prototype.getCurrentUserRoles = function () {
        return this.http.get(environment.serverPath + "api/user_roles/current");
    };
    UserRoleService.ngInjectableDef = i0.defineInjectable({ factory: function UserRoleService_Factory() { return new UserRoleService(i0.inject(i1.HttpClient)); }, token: UserRoleService, providedIn: "root" });
    return UserRoleService;
}());
export { UserRoleService };
