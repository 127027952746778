import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
var TrackLoadingService = /** @class */ (function () {
    function TrackLoadingService() {
        this.loading = {};
        this.startLoadingEvent = new EventEmitter();
        this.stopLoadingEvent = new EventEmitter();
        this.updateCurrentLoadingEvent = new EventEmitter();
    }
    TrackLoadingService.prototype.startLoading = function (key, message) {
        var that = this;
        if (Object.keys(that.loading).length === 0) {
            that.startLoadingEvent.emit();
        }
        that.loading[key] = message;
        that.updateCurrentLoadingEvent.emit(that.loading);
    };
    TrackLoadingService.prototype.stopLoading = function (key) {
        var that = this;
        delete that.loading[key];
        that.updateCurrentLoadingEvent.emit(that.loading);
        if (Object.keys(that.loading).length === 0) {
            that.stopLoadingEvent.emit();
        }
    };
    TrackLoadingService.ngInjectableDef = i0.defineInjectable({ factory: function TrackLoadingService_Factory() { return new TrackLoadingService(); }, token: TrackLoadingService, providedIn: "root" });
    return TrackLoadingService;
}());
export { TrackLoadingService };
