/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../audits-data-table/audits-data-table.component.ngfactory";
import * as i3 from "../audits-data-table/audits-data-table.component";
import * as i4 from "../../services/audit.service";
import * as i5 from "../../services/manufacturer.service";
import * as i6 from "@angular/router";
import * as i7 from "../../services/item.service";
import * as i8 from "../../services/item-package.service";
import * as i9 from "../../services/kit-definition-item.service";
import * as i10 from "../../services/kit.service";
import * as i11 from "../../services/lot-pool-managed-item.service";
import * as i12 from "../../services/item-combination.service";
import * as i13 from "../../services/item-combination-recall-alert.service";
import * as i14 from "../../services/track-loading.service";
import * as i15 from "../../services/online.service";
import * as i16 from "@angular/material/snack-bar";
import * as i17 from "@angular/material/dialog";
import * as i18 from "./home.component";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
export function View_HomeComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { auditTable: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "container comp-top-margin"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-audits-data-table", [], null, null, null, i2.View_AuditsDataTableComponent_0, i2.RenderType_AuditsDataTableComponent)), i1.ɵdid(3, 245760, [[1, 4], ["auditTable", 4]], 0, i3.AuditsDataTableComponent, [i4.AuditService, i5.ManufacturerService, i6.Router, i7.ItemService, i8.ItemPackageService, i9.KitDefinitionItemService, i10.KitService, i11.LotPoolManagedItemService, i12.ItemCombinationService, i13.ItemCombinationRecallAlertService, i14.TrackLoadingService, i15.OnlineService, i16.MatSnackBar, i17.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵdid(1, 245760, null, 0, i18.HomeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("app-home", i18.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
