import { Component, OnInit, Inject } from '@angular/core';
import { ISnackBarConfig } from '../../interfaces/isnack-bar-config';
import { ConsignmentInventoryImportService } from '../../services/consignment-inventory-import.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar} from '@angular/material';
import { IDialogData } from '../../interfaces/idialog-data';
import { AuditService } from './../../services/audit.service';
import { IAuditDetail } from '../../interfaces/iaudit-detail';
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';
import { AuditDashboardHelper } from 'src/app/helpers/audit-dashboard-helper';

@Component({
  selector: 'app-consignment-inventory-import',
  templateUrl: './consignment-inventory-import.component.html',
  styleUrls: ['./consignment-inventory-import.component.css']
})
export class ConsignmentInventoryImportComponent implements OnInit {

  selectedFile = null;
  pickedFile: any = null;
  loading = false;
  audit: IAuditDetail = {
    location_name : '',
    id: 0,
    warehouse_id: 0,
    distributorship: {},
    status: ''
  };
  snackBarConfig: ISnackBarConfig = {
    message : '',
    duration: 3000,
    success: false,
    snackBarClass : ''
  };

  auditDashboardHelper: AuditDashboardHelper;
  constructor(
    private consignmentService: ConsignmentInventoryImportService,
    public dialogRef: MatDialogRef<ConsignmentInventoryImportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData,
    private auditService: AuditService,
    public snackBar: MatSnackBar
  ) {

    this.auditDashboardHelper = new AuditDashboardHelper();
  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
  }

  onUpload() {
    const fd = new FormData();
    const fileName = this.selectedFile.name.toLowerCase();
    fd.append('audit_id', this.data.audit_id.toString());
    fd.append('file', this.selectedFile, fileName);
    this.dialogRef.disableClose = true;
    this.loading = true;
    this.consignmentService.upload(this.data.audit_id, fd).subscribe(res => {
      if (res.success) {
        this.selectedFile = null;
        this.pickedFile = null;
        this.snackBarConfig.message = 'File Uploaded Successfully';
        this.snackBarConfig.success = true;
        this.openSnackBar();
        this.loading = false;

        // update agency stats
        this.auditService.getStats(this.data.audit_id).subscribe(data => {
          const statsData = this.auditDashboardHelper.parseAgencyDashboardData(data);
          this.auditDashboardHelper.saveAgencyDashbordDataLocally(statsData);
        });

        setTimeout(() => {
          this.dialogRef.close(res.audit);
        }, 2000);
      } else {
        this.loading = false;
        this.snackBarConfig.message = res.message;
        this.snackBarConfig.success = false;
        this.openSnackBar();
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  ngOnInit() {
    this.loading = true;
    this.auditService.getAuditById(this.data.audit_id).subscribe(data => {
      this.audit = data;
      this.loading = false;
    });
  }

  openSnackBar() {
    this.snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: this.snackBarConfig,
      duration: this.snackBarConfig.duration
    });
  }
}
