import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { urls } from '../helpers/urls';
import { KitInstanceHelper } from '../helpers/kit-instance-helper';
import { AuditKitInstanceDatabaseService } from '../services/audit-kit-instance-database.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./audit-kit-instance-database.service";
var AuditKitInstanceService = /** @class */ (function () {
    function AuditKitInstanceService(http, _auditKitInstanceDatabaseService) {
        this.http = http;
        this._auditKitInstanceDatabaseService = _auditKitInstanceDatabaseService;
        this.kitInstanceHelper = new KitInstanceHelper();
    }
    AuditKitInstanceService.prototype.getAuditKitInstances = function (auditId) {
        var params = new HttpParams().set('audit_id', auditId);
        return this.http.get(environment.serverPath + urls.kit, { params: params });
    };
    AuditKitInstanceService.prototype.getOfflineAuditKitInstances = function (auditId) {
        return this._auditKitInstanceDatabaseService.find(auditId, {});
    };
    AuditKitInstanceService.prototype.getAuditKitInstancesAndPopulateOfflineData = function (auditId) {
        var _this = this;
        var that = this;
        var promise = new Promise(function (resolve, reject) {
            _this.getAuditKitInstances(auditId).subscribe(function (data) {
                that._auditKitInstanceDatabaseService.deleteByIndex('data.audit_id', parseInt(auditId, 10)).then(function () {
                    var akis = data.map(function (csi) { return that._auditKitInstanceDatabaseService.new(csi); });
                    that._auditKitInstanceDatabaseService.bulkAdd(akis).then(function (_) { return resolve(akis); });
                });
            });
        });
        return promise;
    };
    AuditKitInstanceService.prototype.updateKitInstanceStatus = function (kitId, status) {
        var formData = new FormData();
        formData.append('id', JSON.stringify(kitId));
        formData.append('status', JSON.stringify(status));
        return this.http.put(environment.serverPath + urls.kit, formData);
    };
    AuditKitInstanceService.ngInjectableDef = i0.defineInjectable({ factory: function AuditKitInstanceService_Factory() { return new AuditKitInstanceService(i0.inject(i1.HttpClient), i0.inject(i2.AuditKitInstanceDatabaseService)); }, token: AuditKitInstanceService, providedIn: "root" });
    return AuditKitInstanceService;
}());
export { AuditKitInstanceService };
