import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { urls } from "../helpers/urls";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ItemSettingService = /** @class */ (function () {
    function ItemSettingService(http) {
        this.http = http;
    }
    ItemSettingService.prototype.getItemSettingsByAudit = function (auditId) {
        var path = "" + environment.serverPath + urls.itemSettings.replace(":audit_id", auditId.toString());
        return this.http.get(path);
    };
    ItemSettingService.ngInjectableDef = i0.defineInjectable({ factory: function ItemSettingService_Factory() { return new ItemSettingService(i0.inject(i1.HttpClient)); }, token: ItemSettingService, providedIn: "root" });
    return ItemSettingService;
}());
export { ItemSettingService };
