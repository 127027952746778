/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./barcode-parser.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../barcode-listener/barcode-listener.component.ngfactory";
import * as i3 from "../barcode-listener/barcode-listener.component";
import * as i4 from "../barcode-hibc-format/barcode-hibc-format.component.ngfactory";
import * as i5 from "../barcode-hibc-format/barcode-hibc-format.component";
import * as i6 from "@angular/material/snack-bar";
import * as i7 from "../barcode-gs1-format/barcode-gs1-format.component.ngfactory";
import * as i8 from "../barcode-gs1-format/barcode-gs1-format.component";
import * as i9 from "../../../services/item-package.service";
import * as i10 from "../../../services/track-loading.service";
import * as i11 from "../barcode-kit-uuid-format/barcode-kit-uuid-format.component.ngfactory";
import * as i12 from "../barcode-kit-uuid-format/barcode-kit-uuid-format.component";
import * as i13 from "../../../services/kit-instance.service";
import * as i14 from "../barcode-arthrex-new-format/barcode-arthrex-new-format.component.ngfactory";
import * as i15 from "../barcode-arthrex-new-format/barcode-arthrex-new-format.component";
import * as i16 from "./barcode-parser.component";
var styles_BarcodeParserComponent = [i0.styles];
var RenderType_BarcodeParserComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BarcodeParserComponent, data: {} });
export { RenderType_BarcodeParserComponent as RenderType_BarcodeParserComponent };
export function View_BarcodeParserComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { gs1: 0 }), i1.ɵqud(402653184, 2, { hibc: 0 }), i1.ɵqud(402653184, 3, { arthrexNew: 0 }), i1.ɵqud(402653184, 4, { uuid: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-barcode-listener", [], null, [[null, "barcodeScanned"], ["document", "keypress"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:keypress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).handleKeyboardEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("barcodeScanned" === en)) {
        var pd_1 = (_co.barcodeScanned($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_BarcodeListenerComponent_0, i2.RenderType_BarcodeListenerComponent)), i1.ɵdid(5, 114688, null, 0, i3.BarcodeListenerComponent, [], null, { barcodeScanned: "barcodeScanned" }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-barcode-hibc-format", [], null, [[null, "itemNotFound"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("itemNotFound" === en)) {
        var pd_0 = (_co.itemNotFound() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_BarcodeHibcFormatComponent_0, i4.RenderType_BarcodeHibcFormatComponent)), i1.ɵdid(7, 114688, [[2, 4]], 0, i5.BarcodeHibcFormatComponent, [i6.MatSnackBar], { items: [0, "items"], countsheetItemHelper: [1, "countsheetItemHelper"] }, { itemNotFound: "itemNotFound" }), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-barcode-gs1-format", [], null, [[null, "itemNotFound"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("itemNotFound" === en)) {
        var pd_0 = (_co.itemNotFound() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_BarcodeGs1FormatComponent_0, i7.RenderType_BarcodeGs1FormatComponent)), i1.ɵdid(9, 114688, [[1, 4]], 0, i8.BarcodeGs1FormatComponent, [i9.ItemPackageService, i6.MatSnackBar, i10.TrackLoadingService], null, { itemNotFound: "itemNotFound" }), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-barcode-kit-uuid-format", [], null, [[null, "itemNotFound"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("itemNotFound" === en)) {
        var pd_0 = (_co.itemNotFound() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_BarcodeKitUuidFormatComponent_0, i11.RenderType_BarcodeKitUuidFormatComponent)), i1.ɵdid(11, 245760, [[4, 4]], 0, i12.BarcodeKitUuidFormatComponent, [i13.KitInstanceService, i6.MatSnackBar], null, { itemNotFound: "itemNotFound" }), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-barcode-arthrex-new-format", [], null, [[null, "itemNotFound"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("itemNotFound" === en)) {
        var pd_0 = (_co.itemNotFound() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_BarcodeArthrexNewFormatComponent_0, i14.RenderType_BarcodeArthrexNewFormatComponent)), i1.ɵdid(13, 245760, [[3, 4]], 0, i15.BarcodeArthrexNewFormatComponent, [i13.KitInstanceService, i6.MatSnackBar], null, { itemNotFound: "itemNotFound" })], function (_ck, _v) { var _co = _v.component; _ck(_v, 5, 0); var currVal_0 = _co.items; var currVal_1 = _co.countsheetItemHelper; _ck(_v, 7, 0, currVal_0, currVal_1); _ck(_v, 9, 0); _ck(_v, 11, 0); _ck(_v, 13, 0); }, null); }
export function View_BarcodeParserComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-barcode-parser", [], null, null, null, View_BarcodeParserComponent_0, RenderType_BarcodeParserComponent)), i1.ɵdid(1, 245760, null, 0, i16.BarcodeParserComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BarcodeParserComponentNgFactory = i1.ɵccf("app-barcode-parser", i16.BarcodeParserComponent, View_BarcodeParserComponent_Host_0, { countsheetItemHelper: "countsheetItemHelper", items: "items" }, { referenceScanned: "referenceScanned", lotScanned: "lotScanned", serialScanned: "serialScanned", expirationScanned: "expirationScanned", quantityScanned: "quantityScanned", done: "done", itemWasNotFound: "itemWasNotFound" }, []);
export { BarcodeParserComponentNgFactory as BarcodeParserComponentNgFactory };
