import { IcountsheetItemData } from '../interfaces/icountsheet-item';
import { ICountSheetData } from '../interfaces/icount-sheet-data';
import { ICountSheetItemWarnings } from '../interfaces/i-count-sheet-item-warnings';
import { IItemCombinationLookupOptions } from '../interfaces/i-item-combination-lookup-options';
import { Iitem } from '../interfaces/iitem';
import { Assets } from './assets';
import { RecallOracleService } from '../services/recall-oracle.service';

export class CountSheetItemHelper {
  recalledItemsCache = {};
  validItemCombinationCache = {};
  itemSetingsCache = {};
  itemCache = {};
  exclusionCache = {};
  matchingRootKitDefinitionItemCache = {};
  redspotQuantitiesCache = {};
  lotPoolManagedItemCache = {};

  constructor() {

  }


  setExpirationWarning(csid: IcountsheetItemData, sapQuantities: any[]) {
    const now = new Date();
    if (csid.data.show_expired_warning === undefined || csid.data.show_expired_warning === null ) {
      if (!csid.data.expiration_date) {
        if (sapQuantities) {
          const lookupOpt: IItemCombinationLookupOptions = { itemId: csid.data.item_id, lotNumber: csid.data.lot_number, serialNumber: csid.data.serial };
          const sqMatch = this.getSapQuantity(lookupOpt, sapQuantities);
          if (sqMatch) {
            csid.data.expiration_date = sqMatch.expiry_date;
          } else {
            csid.data.expiration_date = csid.data.redspot_expiration_date;
          }
          if (csid.data.expiration_date) {
            csid.data.show_expired_warning = new Date(csid.data.expiration_date) < now ? true : false;
          } else {
            csid.data.show_expired_warning = false;
          }
        }
      } else {
        csid.data.show_expired_warning = new Date(csid.data.expiration_date) < now ? true : false;
      }
    }
  }

  setRecallWarning(csid: IcountsheetItemData, countsheet: ICountSheetData, itemSettings: any[], itemCombinations: any[], lotPoolManagedItems: any[], recallOracleService: RecallOracleService) {
    if (itemSettings && itemCombinations && lotPoolManagedItems) {
      csid.data.show_recalled_warning = recallOracleService.get(csid.data.item_id, csid.data.lot_number, csid.data.serial);
      csid.data.show_invalid_warning = !this.checkIfValidItemCombination(csid.data.item_id, csid.data.lot_number, csid.data.serial, !!lotPoolManagedItems.find(lpmi => lpmi.item_id === csid.data.item_id), csid.data.is_lot_tracked, csid.data.is_serial_tracked, itemSettings, itemCombinations);
    }
  }


  checkIfValidItemCombination(itemId: number, lotNumber: string, serialNumber: string, isLotPooled: boolean, isLotTracked: boolean, isSerialTracked: boolean, itemSettings: any, itemCombinations: any): boolean {
    const cacheId = this.makeCombinationCacheId({ itemId: itemId, lotNumber: lotNumber, serialNumber: serialNumber });

    if (this.validItemCombinationCache[cacheId] !== undefined) {
      return this.validItemCombinationCache[cacheId];
    } else {
      if (!itemId) {
        return null;
      }
      if (isLotPooled) {
        this.validItemCombinationCache[cacheId] = true;
        return true;
      }

      const itemSetting = this.getItemSettings(itemId, itemSettings);

      //override if item setting exists
      if (itemSetting) {
        isLotTracked = itemSetting.is_lot_tracked;
      }
      let result: boolean;
      if (isSerialTracked && isLotTracked) {
        result = itemCombinations.find(function (ic) {
          return ic.item_id === itemId && ic.lot_number.value === lotNumber && ic.serial_number.value === serialNumber;
        });
      } else if (isLotTracked) {
        result = itemCombinations.find(function (ic) {
          return ic.item_id === itemId && ic.lot_number.value === lotNumber;
        });
      } else {
        result = itemCombinations.find(function (ic) {
          return ic.item_id === itemId;
        });
      }
      this.validItemCombinationCache[cacheId] = !!result;
      return result;
    }
  }

  checkIfItemExpired(expiration_date: string) {
    if (expiration_date) {
      const currentDate = new Date();
      const itemDate = new Date(expiration_date);
      if (itemDate < currentDate) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  validateCountSheetItem(data: IcountsheetItemData) {
    const errors = {};
    if (data.data.serial && data.data.quantity > 1) {
      errors['quantity'] = 'Serial Tracked items can only have a qty of 1';
    }
    return errors;
  }

  checkForWarnings(data: IcountsheetItemData, recallOracleService: RecallOracleService): ICountSheetItemWarnings {
    const messages: ICountSheetItemWarnings = {
      warnings: []
    };
    if (this.checkIfItemExpired(data.data.expiration_date)) {
      data.data.show_expired_warning = true;
      messages.warnings.push('Expired Product - You have updated a product that has expired. Please place the product aside before you continue.');
    }

    if (data.data.show_recalled_warning === undefined) {
      if (recallOracleService.get(data.data.item_id,
        data.data.lot_number,
        data.data.serial
      )) {
        data.data.show_recalled_warning = true;
        messages.warnings.push('Recalled Product - You have updated a product that has been recalled. Please place the product aside before you continue.');
      }
    } else {
      if (data.data.show_recalled_warning) {
        messages.warnings.push('Recalled Product - You have updated a product that has been recalled. Please place the product aside before you continue.');
      }
    }

    return messages;
  }

  playErrorSound(setting: string, itemWasScanned?: boolean) {
    if (itemWasScanned) {
      if (setting !== 'all') {
        const audio = new Audio();
        audio.src = Assets.sounds.scanError;
        audio.play();
      }
    } else {
      const audio = new Audio();
      audio.src = Assets.sounds.scanError;
      audio.play();
    }
  }

  playSuccessSound(setting: string, ) {

    if (setting === '' || setting == null) {
      const audio = new Audio();
      audio.src = Assets.sounds.scanSuccess;
      audio.play();
    }

  }

  getItemSettings(itemId: number, itemSettings: any[]) {
    if (this.itemSetingsCache[itemId]) {
      return this.itemSetingsCache[itemId];
    } else {
      const itemSetting = itemSettings.find(function (is) {
        return is.item_id === itemId;
      });
      this.itemSetingsCache[itemId] = itemSetting;
      return itemSetting;
    }
  }

  makeCombinationCacheId(iCombinationOpts: IItemCombinationLookupOptions) {
    let id = '';

    if (iCombinationOpts.itemId) {
      id = iCombinationOpts.itemId.toString();
    } else {
      id += iCombinationOpts.reference;
    }
    if (iCombinationOpts.lotNumber) {
      id += '_' + iCombinationOpts.lotNumber;
    }
    if (iCombinationOpts.serialNumber) {
      id += '_' + iCombinationOpts.serialNumber;
    }
    if (iCombinationOpts.isRecalled !== undefined) {
      id += '_' + iCombinationOpts.isRecalled;
    }
    return id;
  }

  makeCombinationCacheIdFromCountsheetItem(csi: IcountsheetItemData) {
    const iCombinationOpts: IItemCombinationLookupOptions = { itemId: csi.data.item_id, lotNumber: csi.data.lot_number, serialNumber: csi.data.serial };
    return this.makeCombinationCacheId(iCombinationOpts);
  }

  getItemForReference(reference: string, items: any[]) {
    const lookupOpts: IItemCombinationLookupOptions = { reference: reference };
    const cacheId = this.makeCombinationCacheId(lookupOpts);
    if (this.itemCache[cacheId]) {
      return this.itemCache[cacheId];
    } else {
      let item = null;
      if (items) {
        item = items.find(i => {
          return reference.toLowerCase() === i.reference.toLowerCase();
        });

        if (!item) {
          item = items.find(it => {
            return reference.toLowerCase() === it.unpunctuated_reference.toLowerCase();
          });
        }
      }
      this.itemCache[cacheId] = item;
      return item;
    }
  }

  cacheItems(items: Iitem[]){
    items.forEach((item:Iitem) =>{
      const cacheId = this.makeCombinationCacheId({ reference: item.reference });
      this.itemCache[cacheId] = item;
    })
  }

  cacheItemsFromSapQuantities(sapQuantities: any) {
    sapQuantities.forEach((sq: any) => {
      const item = sq.item;
      const cacheId = this.makeCombinationCacheId({ reference: item.reference });
      this.itemCache[cacheId] = item;
    });
  }

  getSapQuantity(ic: IItemCombinationLookupOptions, sapQuantities: any[]) {
    const cacheId = this.makeCombinationCacheId(ic);
    if (this.itemCache[cacheId]) {
      return this.itemCache[cacheId];
    } else {
      const result = sapQuantities.find(function (sq) {
        const itemIdMatch = ic.itemId === sq.item_id;
        const lotNumberMatch = (sq.lot_number || '') === (ic.lotNumber || '');
        const serialNumberMatch = (sq.serial_number || '') === (ic.serialNumber || '');
        return itemIdMatch && lotNumberMatch && serialNumberMatch;
      });
      this.itemCache[cacheId] = result;
      return result;
    }
  }

  getExclusion(itemId: number, exclusions: any[]) {
    const lookupOpt: IItemCombinationLookupOptions = { itemId: itemId };
    const cacheId = this.makeCombinationCacheId(lookupOpt);
    if (this.exclusionCache[cacheId]) {
      return this.exclusionCache[cacheId];
    } else {
      const result = !!exclusions.find(e => e.item_id === itemId);
      this.exclusionCache[cacheId] = result;
      return result;
    }
  }

  getLotPoolManagedItem(itemId: number, lotPoolManagedItems: any[]){
    const result = lotPoolManagedItems.find(lpmi => lpmi.item_id === itemId);
    return result;
  }

  getIsLotPoolManaged(itemId: number, lotPoolManagedItems: any[]) {
    const lookupOpt: IItemCombinationLookupOptions = { itemId: itemId };
    const cacheId = this.makeCombinationCacheId(lookupOpt);
    if (this.lotPoolManagedItemCache[cacheId] === undefined) {
      const result = !!lotPoolManagedItems.find(lpmi => lpmi.item_id === itemId);
      this.lotPoolManagedItemCache[cacheId] = result;
      return result;
    } else {

      return this.lotPoolManagedItemCache[cacheId];
    }
  }

  matchingRootKitDefinitionItem(itemId: number, rootKitDefinitionItems: any[]) {
    const cacheId = this.makeCombinationCacheId({ itemId: itemId });
    if (this.matchingRootKitDefinitionItemCache[cacheId]) {
      return this.matchingRootKitDefinitionItemCache[cacheId];
    } else {
      const result = rootKitDefinitionItems.find(function (kdi: any) {
        return kdi.item_id === itemId;
      });
      this.matchingRootKitDefinitionItemCache[cacheId] = result;
      return result;
    }
  }

  getRedspotQuantity(ic: IItemCombinationLookupOptions, redspotQuantities: any[]) {
    const cacheId = this.makeCombinationCacheId(ic);
    if (this.redspotQuantitiesCache[cacheId]) {
      return this.redspotQuantitiesCache[cacheId];
    } else {
      const result = redspotQuantities.find(function (eq) {
        return ic.itemId === eq['item_id'] && (ic.lotNumber || '') === (eq['lot_number'].value || '');
      });
      this.redspotQuantitiesCache[cacheId] = result;
      return result;
    }
  }

  getMatchingCountSheetItemsFromCollection(data: IcountsheetItemData, collection) {
    return collection.filter(item => {
      let condition = item.data.reference.trim().toLowerCase() === data.data.reference.trim().toLowerCase() && item.data.count_sheet_id === data.data.count_sheet_id;

      const serialNumberIsEmpty = !item.data.serial || !data.data.serial;
      const isLotNumberEmpty = !item.data.lot_number || !data.data.lot_number;

      if (!serialNumberIsEmpty) {
        condition = condition && (item.data.serial || '').trim().toLowerCase() === (data.data.serial || '').trim().toLowerCase();
      }

      if (!isLotNumberEmpty) {
        condition = condition && (item.data.lot_number || '').trim().toLowerCase() === (data.data.lot_number || '').trim().toLowerCase();
      }
      return condition;

    });
  }

  cacheItemsForExclusions(exclusions: any[]) {
    exclusions.forEach((ex) => {
      const lookupOpt: IItemCombinationLookupOptions = { itemId: ex.item_id };
      const cacheId = this.makeCombinationCacheId(lookupOpt);
      this.exclusionCache[cacheId] = true;
    });
  }

  cacheItemsForItemSettings(itemSettings: any[]) {
    itemSettings.forEach((is) => {
      const lookupOpt: IItemCombinationLookupOptions = { itemId: is.item_id };
      const cacheId = this.makeCombinationCacheId(lookupOpt);
      this.itemSetingsCache[cacheId] = is;
    });
  }

  getAvailablelotPoolManagedLotNumber(currentCountSheetItems, redspotInventory, sapInventory) {
    if (currentCountSheetItems.map(csi => csi.data.item_id).filter((value, index, self) => self.indexOf(value) === index).length > 1) {
      throw new Error('Please only pass in count sheet items for a single item');
    }
    if (redspotInventory.map(ri => ri.item_id).filter((value, index, self) => self.indexOf(value) === index).length > 1) {
      throw new Error('Please only pass in redspot inventory for a single item');
    }
    if (sapInventory.map(si => si.item_id).filter((value, index, self) => self.indexOf(value) === index).length > 1) {
      throw new Error('Please only pass in SAP inventory for a single item');
    }

    const inventory = [];
    currentCountSheetItems.filter(csi => csi.data.checked).forEach(csi => {
      let match = inventory.find(i => {
        return i.lot_number === (csi.data.lot_number || '');
      });
      if (!match) {
        match = {
          lot_number: csi.data.lot_number || '',
          available_qty: 0,
          used_qty: 0
        };
        inventory.push(match);
      }
      match.used_qty += csi.data.quantity;
    });
    redspotInventory.forEach(rq => {
      let match = inventory.find(i => {
        return i.lot_number === (rq.lot_number.value || '');
      });
      if (!match) {
        match = {
          lot_number: rq.lot_number.value || '',
          available_qty: 0,
          used_qty: 0
        };
        inventory.push(match);
      }
      match.available_qty += rq.quantity;
    });

    var redspotAvailable = inventory.find(i => i.available_qty > i.used_qty);
    var sapAvailable = sapInventory[0];
    if (redspotAvailable) {
      return redspotAvailable.lot_number || "";
    }
    if (sapAvailable) {
      return sapAvailable.lot_number || "";
    }
    return "0";
  }

  lotPoolManagedQuantityChanged(quantityChange, currentCountSheetItems, redspotInventory, sapInventory, data, countSheet, options) {
    if (currentCountSheetItems.map(csi => csi.data.item_id).filter((value, index, self) => self.indexOf(value) === index).length > 1) {
      throw new Error('Please only pass in count sheet items for a single item');
    }
    if (redspotInventory.map(ri => ri.item_id).filter((value, index, self) => self.indexOf(value) === index).length > 1) {
      throw new Error('Please only pass in redspot inventory for a single item');
    }
    if (sapInventory.map(si => si.item_id).filter((value, index, self) => self.indexOf(value) === index).length > 1) {
      throw new Error('Please only pass in SAP inventory for a single item');
    }
    //mesh together used and available inventory
    const inventory = [];
    const alteredCountSheetItems = [];
    const grouping = countSheet.data.show_expected_item_list;
    currentCountSheetItems.filter(csi => csi.data.checked).forEach(csi => {
      let match = inventory.find(i => {
        return i.lot_number === (csi.data.lot_number || '') && i.serial_number === (csi.data.serial || '');
      });
      if (!match) {
        match = {
          lot_number: csi.data.lot_number || '',
          serial_number: csi.data.serial || '',
          available_qty: 0,
          used_qty: 0
        };
        inventory.push(match);
      }
      match.used_qty += csi.data.quantity;
    });
    redspotInventory.forEach(rq => {
      let match = inventory.find(i => {
        return i.lot_number === (rq.lot_number.value || '') && i.serial_number === '';
      });
      if (!match) {
        match = {
          lot_number: rq.lot_number.value || '',
          serial_number: '',
          available_qty: 0,
          used_qty: 0
        };
        inventory.push(match);
      }
      match.available_qty += rq.quantity;
    });

    //this happens when there are no count sheet items, and you want to make note that you counted zero of an item
    if (quantityChange === 0) {
      if (grouping) {
        if (currentCountSheetItems.length === 0) {
          inventory.forEach(i => {
            const match = {
              data: {
                item_id: data.item_id,
                reference: data.reference,
                description: data.description,
                lot_number: i.lot_number,
                serial: i.serial_number,
                quantity: 0,
                count_sheet_id: countSheet.dbId,
                count_sheet_client_id: countSheet.id,
                audit_id: countSheet.data.audit_id,
                is_consigned: data.is_consigned,
                manually_entered: true,
                isKitted: options.isKitted,
                checked: true

              },
              id: '',
              dbId: 0,
              _destroy: false,
              isSynchronized: 1
            };
            currentCountSheetItems.push(match);
            alteredCountSheetItems.push(match);
          });

        }
        //no expected quantities, just give it an sap lot and call it good
        if (currentCountSheetItems.length === 0) {
          const existingSapInventory = sapInventory[0];
          if (existingSapInventory) {
            const match = {
              data: {
                item_id: data.item_id,
                reference: data.reference,
                description: data.description,
                lot_number: existingSapInventory.lot_number,
                serial: '',
                quantity: 0,
                count_sheet_id: countSheet.dbId,
                count_sheet_client_id: countSheet.id,
                audit_id: countSheet.data.audit_id,
                is_consigned: data.is_consigned,
                manually_entered: true,
                isKitted: options.isKitted,
                checked: true

              },
              id: '',
              dbId: 0,
              _destroy: false,
              isSynchronized: 1
            };
            currentCountSheetItems.push(match);
            alteredCountSheetItems.push(match);
          }
        }
        //unexpected, give it a zero lot
        if (currentCountSheetItems.length === 0) {
          const match = {
            data: {
              item_id: data.item_id,
              reference: data.reference,
              description: data.description,
              lot_number: '0',
              serial: '',
              quantity: 0,
              count_sheet_id: countSheet.dbId,
              count_sheet_client_id: countSheet.id,
              audit_id: countSheet.data.audit_id,
              is_consigned: data.is_consigned,
              manually_entered: true,
              isKitted: true,
              checked: true

            },
            id: '',
            dbId: 0,
            _destroy: false,
            isSynchronized: 1
          };
          currentCountSheetItems.push(match);
          alteredCountSheetItems.push(match);
        }
      } else {
        let addingLotNumber = '0';
        if (redspotInventory.length > 0) {
          addingLotNumber = redspotInventory[0].lot_number.value;
        } else if (sapInventory.length > 0) {
          addingLotNumber = sapInventory[0].lot_number;
        } else if (currentCountSheetItems.length > 0) {
          addingLotNumber = currentCountSheetItems[0].data.lot_number;
        }
        const match = {
          data: {
            item_id: data.item_id,
            reference: data.reference,
            description: data.description,
            lot_number: addingLotNumber,
            serial: '',
            quantity: 0,
            count_sheet_id: countSheet.dbId,
            count_sheet_client_id: countSheet.id,
            audit_id: countSheet.data.audit_id,
            is_consigned: data.is_consigned,
            manually_entered: true,
            isKitted: options.isKitted,
            checked: true

          },
          id: '',
          dbId: 0,
          _destroy: false,
          isSynchronized: 1
        };
        currentCountSheetItems.push(match);
        alteredCountSheetItems.push(match);
      }
    } else if (quantityChange > 0) {
      let totalAdded = 0;

      //if we aren't grouping we will always create new records instead of modifying current
      if (grouping) {
        currentCountSheetItems.filter(csi => csi.data.checked).forEach(csi => {
          const match = inventory.find(i => {
            return i.lot_number === (csi.data.lot_number || '') && i.serial_number === (csi.data.serial || '');
          });

          //start: possibly remove these 3 line of code
          csi._destroy = false;
          csi.data.isKitted = options.isKitted;
          csi.data.checked = true;
          //end: possibly remove these 3 lines of code
          if (match.available_qty > match.used_qty) {
            const lowerQty = Math.min(match.available_qty - match.used_qty, quantityChange - totalAdded);
            if (lowerQty > 0) {
              csi.data.quantity += lowerQty;
              match.used_qty += lowerQty;
              totalAdded += lowerQty;
              alteredCountSheetItems.push(csi);
            }
          }
        });
        if ((quantityChange - totalAdded) > 0) {
          currentCountSheetItems.filter(csi => !csi.data.checked).forEach(csi => {
            const match = inventory.find(i => {
              return i.lot_number === (csi.data.lot_number || '') && i.serial_number === (csi.data.serial || '');
            });
            if (match && match.available_qty > match.used_qty) {
              const lowerQty = Math.min(match.available_qty - match.used_qty, quantityChange - totalAdded);
              if (lowerQty > 0) {
                csi.data.quantity = lowerQty;
                csi.data.checked = true;
                csi.data.hide = false;
                match.used_qty += lowerQty;
                alteredCountSheetItems.push(csi);
                totalAdded += lowerQty;
              }
            }
          });
        }
      }

      if ((quantityChange - totalAdded) > 0) {
        inventory.filter(i => i.available_qty > i.used_qty).forEach(i => {
          const lowerQty = Math.min(i.available_qty - i.used_qty, quantityChange - totalAdded);
          if (lowerQty > 0) {
            const match = {
              data: {
                item_id: data.item_id,
                reference: data.reference,
                description: data.description,
                lot_number: i.lot_number,
                serial: i.serial_number,
                quantity: lowerQty,
                count_sheet_id: countSheet.dbId,
                count_sheet_client_id: countSheet.id,
                audit_id: countSheet.data.audit_id,
                is_consigned: data.is_consigned,
                manually_entered: true,
                isKitted: options.isKitted,
                checked: true

              },
              id: '',
              dbId: 0,
              _destroy: false,
              isSynchronized: 1
            };
            totalAdded += lowerQty;
            i.used_qty += lowerQty;
            currentCountSheetItems.push(match);
            alteredCountSheetItems.push(match);
          }
        });
      }
      if ((quantityChange - totalAdded) > 0 && currentCountSheetItems.filter(csi => csi.data.checked).length === 0) {
        const existingSapInventory = sapInventory.find(sap => sap.item_id === data.item_id);
        if (existingSapInventory) {
          const match = {
            data: {
              item_id: data.item_id,
              reference: data.reference,
              description: data.description,
              lot_number: existingSapInventory.lot_number,
              serial: '',
              quantity: quantityChange - totalAdded,
              count_sheet_id: countSheet.dbId,
              count_sheet_client_id: countSheet.id,
              audit_id: countSheet.data.audit_id,
              is_consigned: data.is_consigned,
              manually_entered: true,
              isKitted: options.isKitted,
              checked: true

            },
            id: '',
            dbId: 0,
            _destroy: false,
            isSynchronized: 1
          };
          currentCountSheetItems.push(match);
          alteredCountSheetItems.push(match);
          totalAdded += quantityChange - totalAdded;
        }
      }
      if ((quantityChange - totalAdded) > 0 && currentCountSheetItems.filter(csi => csi.data.checked).length > 0) {
        if (grouping) {
          const match = currentCountSheetItems.find(csi => csi.data.checked);
          match.data.quantity += quantityChange - totalAdded;
          alteredCountSheetItems.push(match);
        } else {
          const match = {
            data: {
              item_id: data.item_id,
              reference: data.reference,
              description: data.description,
              lot_number: currentCountSheetItems.find(csi => csi.data.checked).data.lot_number,
              serial: '',
              quantity: quantityChange - totalAdded,
              count_sheet_id: countSheet.dbId,
              count_sheet_client_id: countSheet.id,
              audit_id: countSheet.data.audit_id,
              is_consigned: data.is_consigned,
              manually_entered: true,
              isKitted: options.isKitted,
              checked: true

            },
            id: '',
            dbId: 0,
            _destroy: false,
            isSynchronized: 1
          };
          currentCountSheetItems.push(match);
          alteredCountSheetItems.push(match);
        }
        totalAdded += quantityChange - totalAdded;
      }
      if ((quantityChange - totalAdded) > 0) {
        let match = null;
        if (grouping) {
          match = currentCountSheetItems.find(csi => (csi.data.lot_number || '').toString() === '0' && !csi.data.serial);
        }
        if (!match) {
          match = {
            data: {
              item_id: data.item_id,
              reference: data.reference,
              description: data.description,
              lot_number: '0',
              serial: '',
              quantity: 0,
              count_sheet_id: countSheet.dbId,
              count_sheet_client_id: countSheet.id,
              audit_id: countSheet.data.audit_id,
              is_consigned: data.is_consigned,
              manually_entered: true,
              isKitted: true,
              checked: true

            },
            id: '',
            dbId: 0,
            _destroy: false,
            isSynchronized: 1
          };
          currentCountSheetItems.push(match);
        }
        match.data.quantity += quantityChange - totalAdded;
        totalAdded += quantityChange - totalAdded;
        alteredCountSheetItems.push(match);
      }
    } else if (quantityChange < 0) {
      if (grouping) {
        if (currentCountSheetItems.length > 0) {
          let totalSubtracted = 0;
          currentCountSheetItems.forEach(csi => {
            const lowerQty = Math.min(csi.data.quantity, -quantityChange - totalSubtracted);
            csi._destroy = false;
            csi.data.isKitted = true;
            csi.data.checked = true;
            if (lowerQty > 0) {
              totalSubtracted += lowerQty;
              csi.data.quantity -= lowerQty;
              alteredCountSheetItems.push(csi);
            }
          });
        } else { //if it got here the quantity changed on an expected line that hasn't been counted before
          let leftToAdd = -quantityChange; //make the value positive
          inventory.filter(i => i.available_qty > i.used_qty).forEach(i => {
            const lowerQty = Math.min(i.available_qty - i.used_qty, leftToAdd);
            if (lowerQty > 0) {
              const match = {
                data: {
                  item_id: data.item_id,
                  reference: data.reference,
                  description: data.description,
                  lot_number: i.lot_number,
                  serial: i.serial_number,
                  quantity: lowerQty,
                  count_sheet_id: countSheet.dbId,
                  count_sheet_client_id: countSheet.id,
                  audit_id: countSheet.data.audit_id,
                  is_consigned: data.is_consigned,
                  manually_entered: true,
                  isKitted: options.isKitted,
                  checked: true

                },
                id: '',
                dbId: 0,
                _destroy: false,
                isSynchronized: 1
              };
              currentCountSheetItems.push(match);
              alteredCountSheetItems.push(match);
              leftToAdd -= lowerQty;
              i.used_qty += lowerQty;
            }
          });

          if (leftToAdd > 0) {
            const existingSapInventory = sapInventory.find(sap => sap.item_id === data.item_id);
            if (existingSapInventory) {
              let match = null;
              if (grouping) {
                match = currentCountSheetItems.find(csi => (csi.data.lot_number || '').toString() === (existingSapInventory.lot_number || '').toString() && !csi.data.serial);
              }
              if (match) {
                match.data.quantity += leftToAdd;
              } else {
                match = {
                  data: {
                    item_id: data.item_id,
                    reference: data.reference,
                    description: data.description,
                    lot_number: existingSapInventory.lot_number,
                    serial: '',
                    quantity: leftToAdd,
                    count_sheet_id: countSheet.dbId,
                    count_sheet_client_id: countSheet.id,
                    audit_id: countSheet.data.audit_id,
                    is_consigned: data.is_consigned,
                    manually_entered: true,
                    isKitted: options.isKitted,
                    checked: true

                  },
                  id: '',
                  dbId: 0,
                  _destroy: false,
                  isSynchronized: 1
                };
                currentCountSheetItems.push(match);
              }
              alteredCountSheetItems.push(match);
              leftToAdd -= leftToAdd;
            }
          }

          if (leftToAdd > 0) {
            let match = null;
            if (grouping) {
              match = currentCountSheetItems.find(csi => (csi.data.lot_number || '').toString() === '0' && !csi.data.serial);
            }
            if (!match) {
              match = {
                data: {
                  item_id: data.item_id,
                  reference: data.reference,
                  description: data.description,
                  lot_number: '0',
                  serial: '',
                  quantity: 0,
                  count_sheet_id: countSheet.dbId,
                  count_sheet_client_id: countSheet.id,
                  audit_id: countSheet.data.audit_id,
                  is_consigned: data.is_consigned,
                  manually_entered: true,
                  isKitted: options.isKitted,
                  checked: true

                },
                id: '',
                dbId: 0,
                _destroy: false,
                isSynchronized: 1
              };
              currentCountSheetItems.push(match);
            }
            alteredCountSheetItems.push(match);
            match.data.quantity += leftToAdd;
            leftToAdd -= leftToAdd;
          }
        }
      } else {
        console.log('I didn\'t think this was possible');
      }
    }
    //set them all to get synched, this might incorrectly set some not updated ones to sync again, perhaps be more surgical if needed
    currentCountSheetItems.forEach(csi => {
      csi.isSynchronized = 0;
    });
    //some functions care about all the count sheet items, some just about the altered
    //if you want all just call this function()[0]
    return [currentCountSheetItems, alteredCountSheetItems.filter((v, i, a) => a.indexOf(v) === i)];
  }

  getNextElementFromDatasource(element: IcountsheetItemData, dataSource: IcountsheetItemData[] ) {
    const index = this.getElementIndexFromDatasource(element, dataSource) + 1;
    const lastElement = this.isIndexLastInDatasource(index, dataSource);
    const nextElement = dataSource[index];

    return {
      ncsi: nextElement,
      isLast: lastElement
    };
  }

  getElementIndexFromDatasource(element: IcountsheetItemData, dataSource: IcountsheetItemData[]): number {
    const index = dataSource.findIndex((csi: IcountsheetItemData) => {
      return csi.data.reference === element.data.reference
          && csi.data.lot_number === element.data.lot_number
          && csi.id === element.id;
    });
    return index;
  }

  isIndexLastInDatasource(index: number, dataSource: IcountsheetItemData[]): boolean {
    const length = dataSource.length;
    if (index === length) {
      return true;
    } else {
      return false;
    }
  }
}
