<p id="top"></p>
<div class="row">
  <div class="col-12">
    <!-- navigation buttons-->
    <button mat-raised-button class="back-to-dashboard" type="button" (click)="goToAuditDashboard()"
      ngbTooltip="Go To Dashboard" placement="right">
      <mat-icon>dashboard</mat-icon>
      Dashboard
    </button>
    <button mat-raised-button class="back-to-countsheets" type="button" (click)="goToCountSheets()"
      ngbTooltip="Go To Count Sheets" placement="right">
      <mat-icon>view_list</mat-icon>
      Count Sheets
    </button>
    <!-- print and edit buttons-->
    <app-print-page class="print pull-right" [countSheetItems]="dataSource.data" [countSheet]="countSheet"
      [lotPoolManagedItems]="lotPoolManagedItems" *ngIf="dataSource && countSheet && lotPoolManagedItems"
      [audit]="audit" #printer></app-print-page>
    <span class="tooltipWrapper edit pull-right">
      <button mat-raised-button class="edit-button " type="button" (click)="editUnkittedCountSheet()" color="primary"
        ngbTooltip="Edit Count Sheet Details" placement="left">
        <mat-icon class="material-icons">edit</mat-icon>
        Edit
      </button>
    </span>

    <span class="tooltipWrapper edit pull-right">
      <button *ngIf="countSheet && countSheet.data.attachments_count > 0" mat-raised-button class="edit-button" type="button" (click)="attachFile()" color="primary" matBadgeColor="warn" [matBadge]="countSheet.data.attachments_count"
        ngbTooltip="Attach Files" placement="left">
        <mat-icon class="material-icons">attach_file</mat-icon>
        Attach Files
      </button>

      <button *ngIf="countSheet && countSheet.data.attachments_count === 0" mat-raised-button class="edit-button" type="button" (click)="attachFile()" color="primary"
        ngbTooltip="Attach Files" placement="left">
        <mat-icon class="material-icons">attach_file</mat-icon>
        Attach Files
      </button>
    </span>

  </div>
</div>

<div class="row" *ngIf="countSheet">
  <div class="col-md-2">
    <h1 class="header-title title-names">
      Area:
    </h1>
    <h1 class="header-title title-names">
      Site:
    </h1>
    <h1 class="header-title title-names">
      Warehouse:
    </h1>
    <h1 class="header-title title-names">
      Auditor(s):
    </h1>
  </div>
  <div class="col-md-5">
    <h1 class="header-title">
      {{countSheet.data.area.toUpperCase()}}
    </h1>
    <h1 class="header-title">
      {{ countSheet.data.location_name.toUpperCase()}}
    </h1>
    <h1 class="header-title">
      {{countSheet.data.warehouse_name}}
    </h1>
    <h1 class="header-title">
      {{auditedBy}}
    </h1>
  </div>
  <div class="col-md-5">
    <h1 class="table-header" style="margin-top: 60px;margin-right: 20%;text-align: center;">Items Counted: <span
        style="color:green;">{{actualItemCount}}</span></h1>
  </div>
</div>

<div style="margin-top:5%; margin-bottom:3%;" class="col-12 count-sheet-container" *ngIf="countSheetItems && countSheet">
  <div class="col-md-6 filters">
    <mat-form-field class="large_filter" *ngxPermissionsOnly="['audit_administrator', 'auditor']">
      <mat-select [value]="countSheet.data.kit_id" [disabled]="viewOnly" name="BOM" placeholder="BOM"
        (selectionChange)="bomChanged($event)">
        <mat-option *ngIf="kits && countSheet" class="option">
          None
        </mat-option>
        <mat-option class="option" *ngFor="let k of kits" [value]="k.id">
          {{k.reference}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngxPermissionsOnly="['audit_administrator', 'auditor']">
    <button mat-button class="count-sheet-container-cancel-buttons" type="button" ngbTooltip="Cancel Counting"
      (click)="cancelCounting()" *ngIf="([3,4].indexOf(countSheet.data.count_sheet_status) === -1 && countSheet.data.created_by.user_id === userData.user_id) || (countSheet.data.count_sheet_status !== 4 && permissions[0] === 'audit_administrator')">
      Cancel Counting
    </button>
    <button mat-button class="count-sheet-container-buttons" type="button" ngbTooltip="Mark Count Sheet As Completed"
      (click)="printAndComplete()" *ngIf="[3,4].indexOf(countSheet.data.count_sheet_status) === -1">
      Finish Counting
    </button>
    <button mat-button class="count-sheet-container-buttons" type="button" ngbTooltip="Delete All Items"
      (click)="deleteAll()" *ngIf="[3,4].indexOf(countSheet.data.count_sheet_status) === -1">
      Delete All
    </button>
    <button mat-button class="count-sheet-container-buttons" type="button" ngbTooltip="Export To Excel"
      (click)="exportCountsheetItems()" *ngIf="!viewOnly">
      Export
    </button>
    <button mat-button class="count-sheet-container-buttons" type="button" *ngxPermissionsOnly="['audit_administrator']"
      ngbTooltip="Import Counted Items" (click)="openCountSheetItemImportDialog()"
      [ngClass]="{'disabled': !isOnline, 'hidden': viewOnly}">
      Import
    </button>
  </div>
</div>

<div class="mat-elevation-z8 data-table">
  <virtual-scroller #scroll [items]="dataSource.data" *ngIf="dataSource.data">
    <table class="mat-table">
      <thead>
        <tr class="mat-header-row">
          <th class="mat-header-cell"></th>
          <th class="mat-header-cell">Reference</th>
          <th class="mat-header-cell">Description</th>
          <th class="mat-header-cell">Lot Number</th>
          <th class="mat-header-cell">Serial Number</th>
          <th class="mat-header-cell">Quantity</th>
        </tr>
      </thead>
      <tbody #container>
        <tr class="mat-row" *ngFor="let element of scroll.viewPortItems"
          [ngClass]="{'just-added': element.data.justAdded, 'not-synced': anyUnsynched(element), 'in_definition': element.data.in_definition}">
          <td class="mat-cell">
            <span class="tooltipWrapper">
              <mat-icon class="material-icons" *ngIf="element.data.show_expired_warning" style="color:red"
                ngbTooltip="Expired" placement="right">
                hourglass_empty
              </mat-icon>
            </span>
            <span class="tooltipWrapper">
              <mat-icon class="material-icons" ngbTooltip="Min. Order Quantity > 1" color="warn"
                *ngIf="isMinOrderQuantityGreaterThanOne(element)" placement="right">
                warning
              </mat-icon>
            </span>
            <span class="tooltipWrapper">
              <mat-icon class="material-icons" ngbTooltip="Item is Excluded" placement="right" color="warn"
                *ngIf="element.data.is_excluded">
                highlight_off
              </mat-icon>
            </span>
            <span class="tooltipWrapper">
              <mat-icon class="material-icons" *ngIf="element.data.show_invalid_warning"
                ngbTooltip="Invalid Item/Lot/Serial Combination" placement="right" style="color:red">
                close
              </mat-icon>
            </span>
            <span class="tooltipWrapper">
              <mat-icon class="material-icons" *ngIf="element.data.show_recalled_warning" color="warn"
                ngbTooltip="This Item/Lot/Serial combination has been recalled" placement="right">
                not_interested
              </mat-icon>
            </span>
            <span class="tooltipWrapper">
              <mat-icon ngbTooltip="Item is Lot Pool Managed" placement="right" *ngIf="isLotPoolManaged(element)">
                pool</mat-icon>
            </span>
            <span class="tooltipWrapper">
              <span ngbTooltip="Item Manually Entered" placement="right" *ngIf="element.data.manually_entered"
                class='manually-entered'>
                M</span>
            </span>
          </td>
          <td class="mat-cell">
            <mat-form-field class="text-input-in-column" *ngIf="element.data.manually_entered">
              <input matInput tabindex="-1" [disabled]="viewOnly" [value]="element.data.reference"
                (change)="referenceChanged($event, element)">
            </mat-form-field>
            <span *ngIf="!element.data.manually_entered">
              {{element.data.reference}}
            </span>
          </td>
          <td class="mat-cell">{{element.data.description}}</td>
          <td class="mat-cell">
            <div *ngIf="element.count_sheet_items; else lot_number_not_lot_pool_managed"></div>
            <ng-template #lot_number_not_lot_pool_managed>
              <div *ngxPermissionsOnly="['audit_administrator', 'auditor']">
                <mat-form-field class="text-input-in-column">
                  <input matInput tabindex="-1" [value]="element.data.lot_number"
                    (change)="lotNumberChanged($event, element)">
                </mat-form-field>
              </div>
              <div *ngxPermissionsOnly="['audit_viewer']">
                {{ element.data.lot_number || "&mdash;"}}
              </div>
            </ng-template>
          </td>
          <td class="mat-cell">
            <div *ngIf="element.count_sheet_items; else serial_number_not_lot_pool_managed"></div>
            <ng-template #serial_number_not_lot_pool_managed>
              <div *ngxPermissionsOnly="['audit_administrator', 'auditor']">
                <mat-form-field class="text-input-in-column">
                  <input matInput tabindex="-1" [disabled]="!element.data.is_serial_tracked"
                    [value]="element.data.serial" (change)="serialNumberChanged($event, element)">
                </mat-form-field>
              </div>
              <div *ngxPermissionsOnly="['audit_viewer']">
                {{ element.data.serial || "&mdash;"}}
              </div>
            </ng-template>
          </td>
          <td class="mat-cell">
            <div *ngIf="element.count_sheet_items; then quantity_lot_pool_managed else quantity_not_lot_pool_managed">
            </div>
            <ng-template #quantity_lot_pool_managed>
              <div *ngxPermissionsOnly="['audit_administrator', 'auditor']">
                <mat-form-field>
                  <input [disabled]="viewOnly" matInput type='number' min="0" class="quantity"
                    (keydown)="keyDown($event, element)" (click)="$event.target.select()"
                    [value]="element.data.quantity" (change)="lotPoolManagedQuantityChanged($event, element)">
                </mat-form-field>
              </div>
              <div *ngxPermissionsOnly="['audit_viewer']">
                {{element.data.quantity}}
              </div>
            </ng-template>
            <ng-template #quantity_not_lot_pool_managed>
              <div *ngxPermissionsOnly="['audit_administrator', 'auditor']">
                <mat-form-field>
                  <input [disabled]="viewOnly" matInput type='number' min="0" class="quantity"
                    (keydown)="keyDown($event, element)" (click)="$event.target.select()"
                    [value]="element.data.quantity" (change)="quantityChanged($event, element)">
                </mat-form-field>
              </div>
              <div *ngxPermissionsOnly="['audit_viewer']">
                {{element.data.quantity}}
              </div>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>
  </virtual-scroller>
  <mat-table style="display: none">
    <mat-header-row *matHeaderRowDef="[]"></mat-header-row>
  </mat-table>
  <div class="row" *ngxPermissionsOnly="['audit_administrator', 'auditor']">
    <div id="add-item-div" class="col-9" *ngIf="!viewOnly">
      <app-add-count-sheet-item
        [items]="items"
        [countSheet]="countSheet"
        [redspotQuantities]="redspotQuantities"
        [redspotInventory]="[]"
        [itemSettings]="itemSettings"
        [itemCombinations]="itemCombinations"
        [lotPoolManagedItems]="lotPoolManagedItems"
        [currentlyDisplayedCountSheetItems] = "[]"
        [exclusions]="exclusions"
        [countsheetItemHelper]="countSheetItemHelper"
        [posIntegerHelper]="positiveIntegerHelper"
        [countsheetHelper]="countSheetHelper"
        [sapQuantities]="sapQuantities"
        [_recallOracleService]="_recallOracleService"
        (countSheetItemUpdated)=updateCountSheetItems()
        *ngIf="countSheet && redspotQuantities && itemSettings && sapQuantities && itemCombinations && lotPoolManagedItems">
      </app-add-count-sheet-item>
    </div>
    <div *ngIf="!viewOnly" class="col-3 just-added-text">
      Reference: {{justAddedReference}} | Count: {{justAddedCount}} | Total: {{actualItemCount}}
    </div>
  </div>

</div>
<div *ngxPermissionsOnly="['audit_administrator', 'auditor']">
  <div class="count-sheet-container" *ngIf="countSheetItems">
    <button mat-button class="count-sheet-container-cancel-buttons" type="button" ngbTooltip="Cancel Counting"
    (click)="cancelCounting()" *ngIf="([3,4].indexOf(countSheet.data.count_sheet_status) === -1 && countSheet.data.created_by.user_id === userData.user_id) || (countSheet.data.count_sheet_status !== 4 && permissions[0] === 'audit_administrator')">
      Cancel Counting
    </button>
    <button mat-button class="count-sheet-container-buttons" type="button" ngbTooltip="Mark Count Sheet As Completed"
      (click)="printAndComplete()" *ngIf="[3,4].indexOf(countSheet.data.count_sheet_status) === -1">
      Finish Counting
    </button>
    <button mat-button class="count-sheet-container-buttons" type="button" ngbTooltip="Delete All Items"
      (click)="deleteAll()" *ngIf="[3,4].indexOf(countSheet.data.count_sheet_status) === -1">
      Delete All
    </button>
    <button mat-button class="count-sheet-container-buttons" type="button" ngbTooltip="Export To Excel"
      (click)="exportCountsheetItems()" *ngIf="!viewOnly">
      Export
    </button>
    <button mat-button class="count-sheet-container-buttons" type="button" *ngxPermissionsOnly="['audit_administrator']"
      ngbTooltip="Import Counted Items" (click)="openCountSheetItemImportDialog()"
      [ngClass]="{'disabled': !isOnline, 'hidden': viewOnly}">
      Import
    </button>
  </div>
</div>
<p id="bottom"></p>
