<div class="header-div">
  <h5 class="header-title">Attachments
    <button class="pull-right" mat-mini-fab color="primary" placement="auto" ngbTooltip="Close" (click)="onNoClick()">
      <mat-icon>close</mat-icon>
    </button>
    <button class="pull-right" *ngIf="!cameraEnabled" mat-mini-fab color="primary" placement="auto" ngbTooltip="Pick File"
      (click)="openFilePicker()">
      <mat-icon>cloud_upload</mat-icon>
    </button>
    <button class="pull-right" *ngIf="!cameraEnabled && camerasAvailable" mat-mini-fab color="primary" placement="auto" ngbTooltip="Camera"
      (click)="openCamera()">
      <mat-icon>photo_camera</mat-icon>
    </button>
    <button class="pull-right" *ngIf="(currentFile && online) || (!online && currentFile && currentFileHasLocalHandle && !fileNotViewable)" mat-mini-fab color="primary" placement="auto" [ngbTooltip]="topButtonOptions.text" (click)="download()">
      <mat-icon>{{topButtonOptions.icon}}</mat-icon>
    </button>

    <button class="pull-right" *ngIf="imageUrl" mat-mini-fab color="primary" placement="auto" (click)="showLens()">
      <mat-icon>zoom_out_map</mat-icon>
    </button>

    <button class="pull-right" *ngIf="currentFile" mat-button>{{currentFile.formatted_created_at}}</button>

    <button *ngIf="canTakePicture" class="pull-right" mat-button [matMenuTriggerFor]="menu">{{selectedCamera.label}}</button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="pickCamera(camera)" *ngFor="let camera of cameraDevices">{{camera.label}}</button>
    </mat-menu>
  </h5>
</div>

<mat-grid-list cols="3" gutterSize="10px" rowHeight="400px">
  <mat-grid-tile  class=" mat-elevation-z4 file-selection-tile" *ngIf="showFileList" colspan="1" rowspan="1">
    <app-attachment-file-list [attachments]="attachments"  (fileSelected)="viewFile($event)" (fileDeleted)="deleteAttachment($event)" #attachmentList></app-attachment-file-list>
  </mat-grid-tile>

  <mat-grid-tile class=" mat-elevation-z4 camera-controls" *ngIf="cameraEnabled">
    <div class="vertical">
      <button class="camera_buttons" mat-fab color="primary" placement="auto" ngbTooltip="Snap" *ngIf="!imageTaken && canTakePicture" (click)="takePicture()">
        <mat-icon>camera</mat-icon>
      </button>
      <button class="camera_buttons" mat-fab color="primary" placement="auto" ngbTooltip="Close Camera"  *ngIf="!imageTaken && canTakePicture"(click)="closeCamera()">
        <mat-icon>close</mat-icon>
      </button>
    
      <button class="camera_buttons" mat-fab color="primary" placement="auto" ngbTooltip="Save" *ngIf="imageTaken" (click)="saveImage()">
        <mat-icon>save</mat-icon>
      </button>

      <button class="camera_buttons" mat-fab color="primary" placement="auto" ngbTooltip="Retake" *ngIf="imageTaken" (click)="retakeImage()">
        <mat-icon>redo</mat-icon>
      </button>
  </div>
  </mat-grid-tile>

  <mat-grid-tile [ngClass]="{'visible-lens':zoomingEnabled}" class="mat-elevation-z4 file-show-tile" *ngIf="showFileView" colspan="2" rowspan="1">
    <div *ngIf="imageUrl" class="image-tile">
      <div *ngIf="zoomingEnabled" class="img-magnifier-glass" id="img-magnifier-glass"></div>
      <img [src]="imageUrl" alt="" srcset="" id="image-box">
    </div>

    <span *ngIf="pdfUrl" class="pdf-tile">
      <button mat-raised-button color="primary" (click)="download()">
        <mat-icon>{{pdfOptions.icon}}</mat-icon>
        {{pdfOptions.text}} {{currentlySelectedFileName}}
      </button>
    </span>

    <div *ngIf="noLocalHandle" class="pdf-file">
      <p>Go online to view file</p>
      <button mat-button disabled class="no-handle-button">
        <mat-icon class="no-handle">signal_wifi_off</mat-icon>
      </button>
    </div>

    <div *ngIf="cameraEnabled" class="image-tile">
      <video #videoElement></video>
    </div>

    <div *ngIf="fileNotViewable" class="pdf-file">
      <p>file type not viewbale</p>
      <button mat-button disabled class="no-handle-button">
        <mat-icon class="no-handle">visibility_off</mat-icon>
    </button>
  </div>

  </mat-grid-tile>

  <mat-grid-tile *ngIf="showSingleFileAttacher" colspan="3" rowspan="1">
    <button mat-raised-button color="primary"(click)="openFilePicker()"> Add Attachment
    </button>
  </mat-grid-tile>
  
</mat-grid-list>
