import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var KitService = /** @class */ (function () {
    function KitService(http) {
        this.http = http;
    }
    KitService.prototype.getKits = function () {
        return this.http.get(environment.serverPath + "audit_api/manufacturers/1/kits");
    };
    KitService.ngInjectableDef = i0.defineInjectable({ factory: function KitService_Factory() { return new KitService(i0.inject(i1.HttpClient)); }, token: KitService, providedIn: "root" });
    return KitService;
}());
export { KitService };
