import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { IAuditDetail } from '../../interfaces/iaudit-detail';
import { Subscription } from 'rxjs';
import { IStatData } from '../../interfaces/i-stat-data';
import { SapItemTableComponent } from '../sap-item-table/sap-item-table.component';
import { AuditDatabaseService } from '../../services/audit-database.service';

@Component({
  selector: 'app-agency-dashboard',
  templateUrl: './agency-dashboard.component.html',
  styleUrls: ['./agency-dashboard.component.css']
})
export class AgencyDashboardComponent implements OnInit, OnDestroy {

  @ViewChild("group") toggle: ElementRef;
  @ViewChild("itemsTable") itemsTable: SapItemTableComponent;
  tableMode: string = 'counted';

  toggleValue: any;
  // local variables
  auditId: string;
  auditDetails: IAuditDetail;
  auditLocations: any[];
  siteStats: IStatData;
  warehouseStats: IStatData;
  itemStats: IStatData;
  kitStats: IStatData;
  percentColor: string;
  loading:boolean = true;
  loadingMessage: string;

  // subscriptions
  auditDetailSubscription: Subscription;
  auditLocationSubscription: Subscription;
  warehouseDataSubscrition: Subscription;
  agencyDashboardSubscription: Subscription;

  // constructor
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _auditDatabaseService: AuditDatabaseService
  ) { }

  ngOnDestroy(): void {
    this.toggle = null;
    this.itemsTable = null;
  }

  ngOnInit() {
    this.auditId = this._route.snapshot.paramMap.get("audit_id");
    this._auditDatabaseService.get(this.auditId).then((data: any) => {
      this.auditDetails = data;
      this.loadingMessage = 'Loading: ' + this.auditDetails.distributorship.name + ' audit results';
      this.itemsTable.setAgencyName(this.auditDetails.distributorship.name);
      let stats = JSON.parse(localStorage.getItem('agency_stats'));
      this.siteStats = stats.siteStats;
      this.kitStats = stats.kitStats;
      this.warehouseStats = stats.warehouseStats;
      this.itemStats = stats.itemStats;
    });

  }


  loadingComplete() {
    this.loading = false;
  }

  goToAuditDashboard() {
    this._router.navigate(['audit_dashboard', this.auditId]);
  }
}
