import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { urls } from "../helpers/urls";

@Injectable({
  providedIn: 'root'
})
export class ItemSettingService {

  constructor(private http: HttpClient) { }

    getItemSettingsByAudit(auditId: number){
      let path = `${environment.serverPath}${urls.itemSettings.replace(":audit_id", auditId.toString())}`;
      return this.http.get<any>(path);
    }
}
