import { Component, OnInit, ViewChild, OnDestroy, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatSnackBar, MatCheckboxChange } from '@angular/material';
import { zip, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CountSheetService } from '../../services/count-sheet.service';
import { AuditService } from '../../services/audit.service';
import { CountSheetItemService } from '../../services/count-sheet-item.service';
import { RedspotInventoryImportService } from '../../services/redspot-inventory-import.service';
import { ConsignmentInventoryImportService } from '../../services/consignment-inventory-import.service';
import { KitInstanceCountSheetDataSource } from './kit-instance-count-sheet-datasource';
import { ItemSettingService } from '../../services/item-setting.service';
import { ISnackBarConfig } from '../../interfaces/isnack-bar-config';
import { OnlineService } from './../../services/online.service';
import { CountSheetItemHelper } from './../../helpers/countsheet-item-helper';
import { IcountsheetItemData, ILotPoolManagedCountsheetItemGroup } from '../../interfaces/icountsheet-item';
import { IkitInstance } from '../../interfaces/ikit-instance';
import { KitInstanceHelper } from '../../helpers/kit-instance-helper';
import { ItemCombinationService } from '../../services/item-combination.service';
import { KitDefinitionItemService } from '../../services/kit-definition-item.service';
import { KitService } from '../../services/kit.service';
import { UserDataHelper } from '../../helpers/user-data-helper';
import { IuserData } from '../../interfaces/iuser-data';
import * as moment from 'moment';
import { IcountMetadata } from '../../interfaces/icount-metadata';
import { ExclusionService } from '../../services/exclusion.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { PrintPageComponent } from '../print-page/print-page.component';
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';
import { HistoryServiceService } from 'src/app/services/history-service.service';
import { OfflineDataSynchronizerService } from 'src/app/services/offline-data-synchronizer.service';
import { WarningDialogComponent } from '../warning-dialog/warning-dialog.component';
import { IAuditDetail } from 'src/app/interfaces/iaudit-detail';
import { TrackLoadingService } from '../../services/track-loading.service';
import { ImportCountSheetItemComponent } from '../import-count-sheet-item/import-count-sheet-item.component';
import { ICountSheetData } from 'src/app/interfaces/icount-sheet-data';
import { PositiveIntegerHelper } from '../../helpers/positive-integer-helper';
import { ExportToExcelService } from 'src/app/services/export-to-excel.service';
import { CountSheetExportHelper } from 'src/app/helpers/countsheet-export-helper';
import { ICountsheetExportOptions } from 'src/app/interfaces/i-countsheet-export-options';
import { LotPoolManagedItemService } from '../../services/lot-pool-managed-item.service';
import { AudioPlayerService } from 'src/app/services/audio-player.service';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';
import { ItemService } from 'src/app/services/item.service';
import { DatabaseService } from 'src/app/services/database.service';
import { CountSheetDatabaseService } from 'src/app/services/count-sheet-database.service';
import { CountSheetItemDatabaseService } from 'src/app/services/count-sheet-item-database.service';
import { AuditKitInstanceDatabaseService } from 'src/app/services/audit-kit-instance-database.service';
import { AuditDatabaseService } from 'src/app/services/audit-database.service';
import { AttachmentDialogComponent } from '../attachment-dialog/attachment-dialog.component';
import { Messages } from 'src/app/helpers/messages';
import { Iitem } from 'src/app/interfaces/iitem';
import { CameraService } from 'src/app/services/camera.service';
import { IDeviceInfo } from 'src/app/interfaces/i-device-info';
import { RecallOracleService } from 'src/app/services/recall-oracle.service';
@Component({
  selector: 'app-kit-instance-count-sheet',
  templateUrl: './kit-instance-count-sheet.component.html',
  styleUrls: ['./kit-instance-count-sheet.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class KitInstanceCountSheetComponent implements OnInit, OnDestroy {

  @ViewChild('printer') printer: PrintPageComponent;
  @ViewChild(VirtualScrollerComponent) private virtualScroller: VirtualScrollerComponent;
  @Input() location: any;
  permissions: string[];
  previousUrl: string;
  cameras: IDeviceInfo[];
  dataSource: KitInstanceCountSheetDataSource;
  kitInstance: IkitInstance;
  checkboxColor = 'warn';
  iconColor = 'warn';
  loading = false;
  audit: IAuditDetail;
  createdBy: any;
  warehouses: any[] = [];
  dropdownWarehouseId: number;
  csItems: IcountsheetItemData[];
  itemCombinations: any;
  lotPoolManagedItems: any;
  kits: any;
  items: Iitem[];
  auditId: number;
  userData: IuserData;
  viewOnly: boolean;
  status: string;
  auditedBy: string;
  expectedItemCount = 0;
  actualItemCount = 0;
  showOrderFilter = false;
  statsComplete = false;
  isOnline: boolean;
  snackBarConfig: ISnackBarConfig = {
    message: '',
    duration: 3000,
    success: false,
    snackBarClass: ''
  };
  focusFrom: string = ""
  focusFromId: string = ""
  focusTo: string = ""

  // helpers
  kitInstanceHelper: KitInstanceHelper = new KitInstanceHelper();
  countSheetItemHelper: CountSheetItemHelper = new CountSheetItemHelper();
  userDataHelper: UserDataHelper = new UserDataHelper();
  positiveIntegerHelper: PositiveIntegerHelper = new PositiveIntegerHelper();

  // subscriptions
  onlineSubscription: Subscription;
  reloadSubscription: Subscription;
  previousUrlSubscription: Subscription;
  startReloadingSubscription: Subscription;
  isOnlineSubscription: Subscription;
  isOfflineSubscription: Subscription;
  trackLoadingSubscription: Subscription;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _countSheetService: CountSheetService,
    private _countSheetItemService: CountSheetItemService,
    private _redspotInventoryImportService: RedspotInventoryImportService,
    private _consignmentInventoryImportService: ConsignmentInventoryImportService,
    private _itemSettingService: ItemSettingService,
    private _onlineService: OnlineService,
    public _dialog: MatDialog,
    private _itemCombinationService: ItemCombinationService,
    private _kitDefinitionItemService: KitDefinitionItemService,
    private _exclusionService: ExclusionService,
    public _snackBar: MatSnackBar,
    private _historyService: HistoryServiceService,
    private _auditService: AuditService,
    private _synchronizer: OfflineDataSynchronizerService,
    private _kitService: KitService,
    private _trackLoading: TrackLoadingService,
    private _excelService: ExportToExcelService,
    private _lotPoolManagedItemService: LotPoolManagedItemService,
    private _audioPlayerService: AudioPlayerService,
    private _itemService: ItemService,
    private _databaseService: DatabaseService,
    private _countSheetDatabaseService: CountSheetDatabaseService,
    private _countSheetItemDatabaseService: CountSheetItemDatabaseService,
    private _auditKitInstanceDatabaseService: AuditKitInstanceDatabaseService,
    private _auditDatabaseService: AuditDatabaseService,
    private _cameraService: CameraService,
    private _recallOracleService: RecallOracleService
  ) {
    this.dataSource = new KitInstanceCountSheetDataSource(this.countSheetItemHelper);
    this.getItems().subscribe((data) => {
      this.items = data
      this.countSheetItemHelper.cacheItems(this.items)
    });

    this._cameraService.getDevices((devices: IDeviceInfo[]) => {
      this.cameras = devices;
    })

  }

  ngOnDestroy(): void {
    if (this.onlineSubscription) {
      this.onlineSubscription.unsubscribe();
    }

    if (this.reloadSubscription) {
      this.reloadSubscription.unsubscribe();
    }

    if (this.startReloadingSubscription) {
      this.startReloadingSubscription.unsubscribe();
    }

    if (this.previousUrlSubscription) {
      this.previousUrlSubscription.unsubscribe();
    }

    if (this.isOnlineSubscription) {
      this.isOnlineSubscription.unsubscribe();
    }

    if (this.isOfflineSubscription) {
      this.isOfflineSubscription.unsubscribe();
    }
    if (this.trackLoadingSubscription) {
      this.trackLoadingSubscription.unsubscribe()
    }

    this.printer = null;
    this.virtualScroller = null;
  }

  ngOnInit() {
    const that = this;
    this.auditId = +this._route.snapshot.paramMap.get('audit_id');
    this.previousUrl = this._historyService.findLastCountSheetSummaryLink();
    this.userData = this.userDataHelper.getUserData();
    this.permissions = JSON.parse(localStorage.getItem('permissions'));
    this.reloadSubscription = this._synchronizer.allSynchronizedEvent.subscribe(() => {
      this.reloadWhenBackOnline();
    });

    //keep this.online up to date
    this.isOnline = this._onlineService.isOnline() && !this._onlineService.testingOffline;
    this.isOnlineSubscription = this._onlineService.isOnlineSubscription().subscribe(
      online => {
        this.isOnline = online && !this._onlineService.getTestOfflineBool();
      }
    );
    this.isOfflineSubscription = this._onlineService.goOfflineEvent.subscribe((data: boolean) => {
      this.isOnline = !data;
    });
    //end of keep this.online up to date

    this.viewOnly = this._route.snapshot.paramMap.get('isViewOnly') === 'true';
    this.status = 'All';
    this._route.queryParams.subscribe(params => {
      if (params.status) {
        this.status = params.status;
      }
    });
    this.dataSource.filters = { orderType: 'consigned', exclusions: 'hide' };

    this._synchronizer.needsSyncing().then((hasUnsynced) => {
      const wouldSync = that._onlineService.isOnline() && !that._onlineService.testingOffline;
      const currentlySyncing = !that._synchronizer.doneProcessing;
      if (wouldSync && (hasUnsynced || currentlySyncing)) {
        return;
      }
      that.getKits();
      that._trackLoading.startLoading('consignment-quantities', 'Loading SAP Consignment');
      that._trackLoading.startLoading('item-settings', 'Loading Item Settings');
      that._trackLoading.startLoading('item-combinations', 'Loading Item Batch Verification');
      that._trackLoading.startLoading('item-exclusions', 'Loading Exclusions');
      zip(
        that.getConsignmentQuantities(that.auditId),
        that.getItemSettingsByAudit(that.auditId),
        that.getItemCombinations(),
        that.getExclusions(that.auditId),
        that.getLotPoolManagedItems(),
        that._auditDatabaseService.get(that.auditId)
      ).subscribe(data => {
        that.dataSource.sapQuantities = data[0];
        that.dataSource.itemSettings = data[1];
        that.dataSource.itemCombinations = data[2];
        that.dataSource.exclusions = data[3];
        that.dataSource.lotPoolManagedItems = data[4];
        that.audit = data[5];
        that._trackLoading.stopLoading('consignment-quantities');
        that._trackLoading.stopLoading('item-settings');
        that._trackLoading.stopLoading('item-combinations');
        that._trackLoading.stopLoading('item-exclusions');
        const online = that._onlineService.isOnline() && !that._onlineService.testingOffline;

        that.countSheetItemHelper.cacheItemsFromSapQuantities(that.dataSource.sapQuantities);
        that.countSheetItemHelper.cacheItemsForExclusions(that.dataSource.exclusions);
        that.countSheetItemHelper.cacheItemsForItemSettings(that.dataSource.itemSettings);

        that._trackLoading.startLoading('kit-instance-count-sheet-recall-oracle', 'Loading Recall Oracle');
        that._recallOracleService.prime(that.auditId, {itemCombinations: that.dataSource.itemCombinations}).then(_ => {
          that.dataSource.recallOracleService = that._recallOracleService;
          that._trackLoading.stopLoading('kit-instance-count-sheet-recall-oracle');
          that._trackLoading.startLoading('count-sheet', 'Loading Count Sheet');
          that.getCountSheet(online, that._route.snapshot.paramMap.get('id')).then((countSheetData: ICountSheetData) => {
            that._trackLoading.stopLoading('count-sheet');
            that.dataSource.countSheet = countSheetData;
            that.getWarehouses(that.auditId, that.dataSource.countSheet.data.warehouse_id);
  
            that._trackLoading.startLoading('kit-instances', 'Loading Kit Instance');
            that._trackLoading.startLoading('rot-kit-definition-items', 'Loading Kit BOMs');
            zip(
              that.getKitInstance(that.dataSource.countSheet),
              that.getRootKitDefinitionItems(that.dataSource.countSheet.data.kit_id)
            ).subscribe((kitInstanceData: any[]) => {
              that._trackLoading.stopLoading('kit-instances');
              that._trackLoading.stopLoading('rot-kit-definition-items');
              that.kitInstance = kitInstanceData[0];
              that.dataSource.rootKitDefinitionItems = kitInstanceData[1];
              that._trackLoading.startLoading('count-sheet-items', 'Loading Count Sheet Items');
              that._trackLoading.startLoading('redspot-inventory', 'Loading Redspot Inventory');
              zip(
                that.getCountSheetItemsInAnyState(online, that.auditId, that.dataSource.countSheet),
                that.getRedspotQuantities(that.auditId, that.kitInstance.data.item_instance_id),
              ).subscribe(countsheetItemsData => {
                that._trackLoading.stopLoading('count-sheet-items');
                that._trackLoading.stopLoading('redspot-inventory');
                that.dataSource.countSheetItems = countsheetItemsData[0];
                that.setAuditedByValue(that.dataSource.countSheetItems);
                that.dataSource.redspotQuantities = countsheetItemsData[1];
  
                that.refreshDataSource();
              });
            });
          });
        });
      });
    });

    this.trackLoadingSubscription = this._trackLoading.stopLoadingEvent.subscribe(() => {
      if ('scrollRestoration' in history) {
        history.scrollRestoration = 'manual';
      }
      document.getElementById('top').scrollIntoView();
    })
  }

  OrderFilterChanged(event) {
    const filters = this.dataSource.filters;
    filters['orderType'] = event.value;
    this.dataSource.filters = filters;
    this.refreshDataSource();
  }

  refreshDataSource() {
    this.dataSource.refresh();
    this.setCountSheetStats();
    this.setAuditedByValue(this.dataSource.countSheetItems);
    if (!this.dataSource.countSheet.data.show_expected_item_list) {
      this.scrollToBottom();
    }
    const height = 58;
    (<HTMLElement>document.getElementsByTagName('virtual-scroller')[0]).style.height = Math.min(height + height * this.dataSource.data.length, height * 11).toString() + 'px';

    let currentFocus = this.focusFromId;
    if (currentFocus) {
      setTimeout(() => {
        let element: HTMLInputElement = <HTMLInputElement>document.getElementById(currentFocus);
        if (element) {
          element.focus();
          element.select();
        }
      }, 300)
    }
  }

  /**
   * copy countsheet item online / offline
   * @param element
   */
  copyCountSheetItem(oldElement: IcountsheetItemData) {
    const that = this;
    if (this.dataSource.countSheetItems.find(csi => !csi.data.hide && !csi._destroy && csi.data.item_id === oldElement.data.item_id && !csi.data.lot_number && !csi.data.serial)) {
      return;
    }
    const element = JSON.parse(JSON.stringify(oldElement));
    element.id = '';
    element.dbId = 0;
    element.data.isKitted = true;
    element._destroy = false;
    element.data.quantity = 1;
    element.data.checked = true;
    element.data.lot_number = '';
    element.data.serial = '';
    element.data.expected_quantity = 0;
    element.data.modified_by = this.userData.name;
    element.data.audit_id = this.auditId;
    element.data.rank = 0;
    element.isSynchronized = 0;
    if (this.dataSource.countSheet.id) {
      element.data.count_sheet_client_id = this.dataSource.countSheet.id;
    }
    if (this.dataSource.countSheet.dbId) {
      element.data.count_sheet_id = this.dataSource.countSheet.dbId;
    }


    this._countSheetItemDatabaseService.add(element).then(function () {
      zip(
        that._countSheetDatabaseService.get(that.dataSource.countSheet.id),
        that._countSheetItemDatabaseService.find(that.auditId, { count_sheet_client_id: that.dataSource.countSheet.id, _destroy: false })
      ).subscribe((data: any[]) => {
        that.dataSource.countSheet = data[0];
        that.dataSource.countSheetItems = data[1];
        that.setAuditedByValue(that.dataSource.countSheetItems);
        that.refreshDataSource();
      });
    });
  }

  updateCountSheetItems() {
    const that = this;
    zip(
      that._countSheetDatabaseService.get(that.dataSource.countSheet.id),
      that._countSheetItemDatabaseService.find(that.auditId, { count_sheet_client_id: that.dataSource.countSheet.id, _destroy: false })
    ).subscribe((data: any[]) => {
      that.dataSource.countSheet = data[0];
      that.dataSource.countSheetItems = data[1];
      this.setAuditedByValue(this.dataSource.countSheetItems);
      this.refreshDataSource();
    });
  }

  /**
   * click check all online / offline
   */
  checkedAllClicked() {
    this._trackLoading.startLoading('check-all-clicked', 'Updating Count Sheet');
    let expiredItemsPresent = false;
    let recalledItemsPresent = false;
    const that = this;


    const promises = [];
    this.dataSource.data.forEach(row => {
      if (!that.anyChecked(row)) {
        let records = [];
        if (row.count_sheet_items) {
          if (row.count_sheet_items.length === 0) {
            that.createLotPoolManangedCountedItems(row);
            records = row.count_sheet_items;
          } else {
            records = row.count_sheet_items;
          }
        } else {
          records = [row];
        }
        records.forEach(element => {
          element._destroy = false;
          element.isSynchronized = 0;
          element.data.count_sheet_id = this.dataSource.countSheet.dbId;
          element.data.count_sheet_client_id = this.dataSource.countSheet.id;
          element.data.audit_id = this.dataSource.countSheet.data.audit_id;
          if (!(element.id || element.dbId)) {
            element.data.quantity = element.data.expected_quantity;
          }
          element.data.checked = true;
          element.data.modified_by = this.userData.name;
          if (!element.id) {
            promises.push(this._countSheetItemDatabaseService.add(element));
          } else {
            promises.push(this._countSheetItemDatabaseService.update(element));
          }
        });
      }
    });

    zip(
      ...promises.concat(new Promise((r) => { r(null); }))
    ).subscribe(_ => {
      zip(
        that._countSheetDatabaseService.get(that.dataSource.countSheet.id),
        that._countSheetItemDatabaseService.find(that.auditId, { count_sheet_client_id: that.dataSource.countSheet.id, _destroy: false })
      ).subscribe((data: any[]) => {
        that.dataSource.countSheet = data[0];
        that.dataSource.countSheetItems = data[1];
        that.setAuditedByValue(this.dataSource.countSheetItems);
        that.refreshDataSource();
        recalledItemsPresent = that.dataSource.countSheetItems.find(csi => {
          const result = csi.data.show_recalled_warning ? true : false;
          return result;
        });

        expiredItemsPresent = that.dataSource.countSheetItems.find(csi => {
          const result = csi.data.show_expired_warning ? true : false;
          return result;
        });

        if (recalledItemsPresent) {
          that.openWarningDialog('Recalled Product - This kit contains an item that has been recalled. Please place the product aside before you continue.');
        }
        if (expiredItemsPresent) {
          that.openWarningDialog('Expired Product - This kit contains an item that has expired. Please place the product aside before you continue.');
        }
        that._trackLoading.stopLoading('check-all-clicked');
      });
    });
  }

  deleteAll() {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '30%',
      data: 'Are you sure you want to clear the countsheet?',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const that = this;
        that._trackLoading.startLoading('delete-all-clicked', 'Updating Count Sheet');
        that._countSheetItemService.deleteCountSheetItemsOffline(that.dataSource.countSheet.data.audit_id, that.dataSource.countSheet.id).then(_ => {
          that.dataSource.countSheetItems = [];
          that.dataSource.countSheet.isSynchronized = 0;
          that.dataSource.countSheet.data.total_items_counted = 0;
          that.refreshDataSource();
          that.setAuditedByValue(that.dataSource.countSheetItems);
          that._trackLoading.stopLoading('delete-all-clicked');
        });
      }
    });
  }

  lotPoolManagedItemRemoveAddedItem(element: ILotPoolManagedCountsheetItemGroup) {
    const that = this;
    const promises = element.count_sheet_items.map(csi => {
      if (csi.dbId) {
        csi.data.quantity = 0;
        csi.data.checked = false;
        csi._destroy = true;
        csi.isSynchronized = 0;
        return that._countSheetItemDatabaseService.update(csi);
      }
      return that._countSheetItemDatabaseService.delete(csi.id);
    });
    zip(
      ...promises.concat(new Promise((r) => { r(null); }))
    ).subscribe(_ => {
      zip(
        that._countSheetDatabaseService.get(that.dataSource.countSheet.id),
        that._countSheetItemDatabaseService.find(that.auditId, { count_sheet_client_id: that.dataSource.countSheet.id, _destroy: false })
      ).subscribe((data: any[]) => {
        that.dataSource.countSheet = data[0];
        that.dataSource.countSheetItems = data[1];
        this.setAuditedByValue(this.dataSource.countSheetItems);
        this.refreshDataSource();
      });
    });
  }

  createLotPoolManangedCountedItems(element) {
    let countSheetItems = element.count_sheet_items;
    if (countSheetItems.length === 0) {
      countSheetItems = element.available_redspot_inventory.map(rq => {
        const row: IcountsheetItemData = {
          data: {
            item_id: element.data.item_id,
            reference: element.data.reference,
            description: element.data.description,
            lot_number: rq.lot_number.value,
            serial: '',
            expected_quantity: rq.quantity,
            quantity: rq.quantity,
            count_sheet_id: this.dataSource.countSheet.dbId,
            count_sheet_client_id: this.dataSource.countSheet.id,
            audit_id: this.dataSource.countSheet.data.audit_id,
            is_consigned: element.data.is_consigned,
            manually_entered: true,
            show_recalled_warning: this._recallOracleService.get(element.data.item_id, null, null)
          },
          id: '',
          dbId: 0,
          isSynchronized: 1
        };
        return row;
      });
      element.count_sheet_items = countSheetItems;
    }
    if (countSheetItems.length === 0) {
      countSheetItems = [{
        data: {
          item_id: element.data.item_id,
          reference: element.data.reference,
          description: element.data.description,
          lot_number: '',
          serial: '',
          expected_quantity: 0,
          quantity: 0,
          count_sheet_id: this.dataSource.countSheet.dbId,
          count_sheet_client_id: this.dataSource.countSheet.id,
          audit_id: this.dataSource.countSheet.data.audit_id,
          is_consigned: element.data.is_consigned,
          manually_entered: true

        },
        id: 0,
        dbId: 0,
        isSynchronized: 1
      }];
      element.count_sheet_items = countSheetItems;
    }
  }

  lotPoolManagedCheckedClicked(event, element: ILotPoolManagedCountsheetItemGroup) {
    let needsUpdate = false;
    this.createLotPoolManangedCountedItems(element);
    if (event instanceof MatCheckboxChange) {
      element.count_sheet_items.forEach(csi => {
        csi.data.checked = event.checked;
      });
      needsUpdate = true;
    } else if (event instanceof KeyboardEvent && event.code === 'Space') {
      element.count_sheet_items.forEach(csi => {
        csi.data.checked = true;
      });
      needsUpdate = true;
    }
    element.count_sheet_items.forEach(csi => {
      csi._destroy = false;
      csi.data.isKitted = true;
    });
    element.data.quantity = element.count_sheet_items.reduce((total, csi) => total + csi.data.quantity, 0);
    if (needsUpdate) {
      this.updateCountSheetItem(element.count_sheet_items);
    }

  }

  lotPoolManagedQuantityChanged(event, element: ILotPoolManagedCountsheetItemGroup) {
    const newTotalQty = +event.target.value;
    const oldTotalQty = element.count_sheet_items.filter(csi => csi.data.checked).reduce((total, csi) => total + csi.data.quantity, 0);
    element.count_sheet_items = this.countSheetItemHelper.lotPoolManagedQuantityChanged(
      newTotalQty - oldTotalQty,
      element.count_sheet_items,
      element.available_redspot_inventory,
      this.dataSource.sapQuantities.filter(sap => sap.item_id === element.data.item_id),
      element.data,
      this.dataSource.countSheet,
      { isKitted: true }
    )[0];

    element.data.quantity = element.count_sheet_items.reduce((total, csi) => total + csi.data.quantity, 0);
    this.updateCountSheetItem(element.count_sheet_items);
  }

  /**
   * click check online / offline
   * @param event
   * @param element
   */
  checkedClicked(event, element: IcountsheetItemData) {
    let needsUpdate = false;
    if (event instanceof MatCheckboxChange) {
      element.data.checked = event.checked;
      if (!event.checked) {
        element.data.quantity = 0;
      }
      needsUpdate = true;
    } else if (event instanceof KeyboardEvent && !element.data.checked) {
      element.data.checked = true;
      needsUpdate = true;
    }
    element._destroy = false;
    element.data.isKitted = true;

    if (needsUpdate) {
      if (!element.id && !element.dbId) {
        element.data.quantity = element.data.expected_quantity;
      }
      this.countSheetItemHelper.setRecallWarning(element, this.dataSource.countSheet, this.dataSource.itemSettings, this.dataSource.itemCombinations, this.dataSource.lotPoolManagedItems, this.dataSource.recallOracleService);
      this.countSheetItemHelper.setExpirationWarning(element, this.dataSource.sapQuantities);
      this.updateCountSheetItem(element);
    }
  }

  /**
   *
   * @param event
   * @param element
   */
  removeAddedItem(element: IcountsheetItemData) {
    const that = this;

    let promise;
    if (element.dbId) {
      element.data.quantity = 0;
      element.data.checked = false;
      element._destroy = true;
      element.isSynchronized = 0;
      promise = this._countSheetItemDatabaseService.update(element);
    } else {
      promise = this._countSheetItemDatabaseService.delete(element.id);
    }
    promise.then(_ => {
      zip(
        that._countSheetDatabaseService.get(that.dataSource.countSheet.id),
        that._countSheetItemDatabaseService.find(that.auditId, { count_sheet_client_id: that.dataSource.countSheet.id, _destroy: false })
      ).subscribe((data: any[]) => {
        that.dataSource.countSheet = data[0];
        that.dataSource.countSheetItems = data[1];
        this.setAuditedByValue(this.dataSource.countSheetItems);
        this.refreshDataSource();
      });
    });
  }

  checkAddedItem(event, element: IcountsheetItemData) {
    let updateNeeded = false;
    if (event instanceof MatCheckboxChange) {
      element.data.checked = event.checked;
      updateNeeded = true;
    } else if (event instanceof KeyboardEvent && !element.data.checked) {
      element.data.checked = true;
      updateNeeded = true;
    }
    element._destroy = false;
    element.data.isKitted = true;
    if (updateNeeded) {
      if (!element.id && !element.dbId) {
        element.data.quantity = element.data.expected_quantity;
      }
      this.updateCountSheetItem(element, undefined, () => this.refreshDataSource());
    }
  }
  /**
   * change quantity online / offline
   * @param event
   * @param element
   */
  quantityChanged(event, element: IcountsheetItemData) {
    element._destroy = false;
    element.data.isKitted = true;
    element.data.checked = true;
    element.data.quantity = +event.target.value;
    this.countSheetItemHelper.setRecallWarning(element, this.dataSource.countSheet, this.dataSource.itemSettings, this.dataSource.itemCombinations, this.dataSource.lotPoolManagedItems, this.dataSource.recallOracleService);
    this.countSheetItemHelper.setExpirationWarning(element, this.dataSource.sapQuantities);
    this.updateCountSheetItem(element);
  }

  updateCountSheetItem(primaryElements, secondaryElements = [], callback = null) {
    primaryElements = [].concat(...[primaryElements]);
    secondaryElements = [].concat(...[secondaryElements]);
    primaryElements.forEach((e) => {
      if (this.dataSource.countSheet.dbId) {
        e.data.count_sheet_id = this.dataSource.countSheet.dbId;
      }
      e.data.count_sheet_client_id = this.dataSource.countSheet.id;
      e.data.audit_id = this.dataSource.countSheet.data.audit_id;
      e.isSynchronized = 0;
    });
    secondaryElements.forEach((e) => {
      if (this.dataSource.countSheet.dbId) {
        e.data.count_sheet_id = this.dataSource.countSheet.dbId;
      }
      e.data.count_sheet_client_id = this.dataSource.countSheet.id;
      e.data.audit_id = this.dataSource.countSheet.data.audit_id;
      e.isSynchronized = 0;
    });
    const elements = [].concat(...[primaryElements, secondaryElements]);
    callback = callback || function () { };
    const that = this;
    const errors = primaryElements.map(e => {
      return that.countSheetItemHelper.validateCountSheetItem(e);
    }).reduce(function (total, internalErrors) { return { ...total, ...internalErrors }; });
    if (Object.keys(errors).length > 0) {
      that.showSnackbar(Object.values(errors).join('.  '), false);

      zip(
        that._countSheetDatabaseService.get(that.dataSource.countSheet.id),
        that._countSheetItemDatabaseService.find(that.auditId, { count_sheet_client_id: that.dataSource.countSheet.id, _destroy: false })
      ).subscribe((data: any[]) => {
        that.dataSource.countSheet = data[0];
        that.dataSource.countSheetItems = data[1];
        this.setAuditedByValue(this.dataSource.countSheetItems);
        this.refreshDataSource();
      });
      return;
    }
    const promises = elements.map(e => {
      e.isSynchronized = 0;
      if (e.id) {
        return that._countSheetItemDatabaseService.update(e);
      }
      return that._countSheetItemDatabaseService.add(e);
    });
    zip(
      ...promises.concat(new Promise((r) => { r(null); }))
    ).subscribe(data => {
      if (this.focusFrom === "lot_number") {
        if (elements[0].data.is_serial_tracked) {
          this.focusFromId = "serial_number_" + data[0];
        } else {
          this.focusFromId = "quantity_" + data[0];
        }
      } else if (this.focusFrom === "serial_number") {
        this.focusFromId = "quantity_" + data[0]
      }

      zip(
        that._countSheetDatabaseService.get(that.dataSource.countSheet.id),
        that._countSheetItemDatabaseService.find(that.auditId, { count_sheet_client_id: that.dataSource.countSheet.id, _destroy: false })
      ).subscribe((count_sheet_data: any[]) => {
        that.dataSource.countSheet = count_sheet_data[0];
        that.dataSource.countSheetItems = count_sheet_data[1];
        this.setAuditedByValue(this.dataSource.countSheetItems);
        that.setCountSheetStats();
        [...Array.from(new Set(
          primaryElements.map(
            e => that.countSheetItemHelper.checkForWarnings(e, that.dataSource.recallOracleService)
          ).reduce((total, message) => {
            return total.concat(message.warnings);
          }, [])
        ))].forEach(m => {
          that.openWarningDialog(m.toString());
        });
        callback();
      });
    });
  }

  /**
   * change lot number online / offline
   * @param event
   * @param element
   */
  lotNumberChanged(event, element: IcountsheetItemData) {
    let secondaryElement;
    let newlotNumber = (event.target.value || '').toUpperCase();
    if (!newlotNumber && this.isLotPoolManaged(element)) {
      //this is the released lot which isn't yet represented in the current count sheeet items array, if its in redspot quantities we want to add it back in
      const readded = [];
      if (element.data.checked && this.dataSource.redspotQuantities.filter(rq => rq.item_id === element.data.item_id).map(rq => rq.lot_number.value).indexOf(element.data.lot_number) !== -1) {
        readded.push({
          item_id: element.data.item_id,
          lot_number: { value: element.data.lot_number },
          quantity: element.data.quantity
        }
        );
      }
      newlotNumber = this.countSheetItemHelper.getAvailablelotPoolManagedLotNumber(
        this.dataSource.countSheetItems.filter(csi => csi.data.item_id === element.data.item_id),
        this.dataSource.redspotQuantities.filter(rq => rq.item_id === element.data.item_id).concat(...readded),
        this.dataSource.sapQuantities.filter(sap => sap.item_id === element.data.item_id)
      );
      event.target.value = newlotNumber;
    }
    //if row wasn't saved before set the quantity
    if (!element.dbId && !element.id) {
      element.data.quantity = element.data.expected_quantity;
    }
    element._destroy = false;
    element.data.isKitted = true;
    element.data.checked = true;
    element.data.manually_entered = true;

    secondaryElement = JSON.parse(JSON.stringify(element));
    secondaryElement.data.quantity = 0;
    secondaryElement.data.hide = true;

    element.id = '';
    element.dbId = 0;
    element.data.lot_number = newlotNumber;

    this.countSheetItemHelper.setRecallWarning(element, this.dataSource.countSheet, this.dataSource.itemSettings, this.dataSource.itemCombinations, this.dataSource.lotPoolManagedItems, this.dataSource.recallOracleService);
    this.countSheetItemHelper.setExpirationWarning(element, this.dataSource.sapQuantities);
    this.focusFrom = "lot_number";
    this.updateCountSheetItem(element, secondaryElement, () => this.refreshDataSource());
  }

  /**
   * change serial number online/ offline
   * @param event
   * @param element
   */
  serialNumberChanged(event, element: IcountsheetItemData) {
    this.focusFrom = "serial_number";
    let secondaryElement;

    //if row wasn't saved before set the quantity
    if (!element.dbId && !element.id) {
      element.data.quantity = element.data.expected_quantity;
    }
    element._destroy = false;
    element.data.isKitted = true;
    element.data.checked = true;
    element.data.manually_entered = true;

    secondaryElement = JSON.parse(JSON.stringify(element));
    secondaryElement.data.quantity = 0;
    secondaryElement.data.hide = true;


    element.id = '';
    element.dbId = 0;
    element.data.serial = event.target.value;

    this.countSheetItemHelper.setRecallWarning(element, this.dataSource.countSheet, this.dataSource.itemSettings, this.dataSource.itemCombinations, this.dataSource.lotPoolManagedItems, this.dataSource.recallOracleService);
    this.countSheetItemHelper.setExpirationWarning(element, this.dataSource.sapQuantities);
    this.updateCountSheetItem(element, secondaryElement, () => this.refreshDataSource());
  }

  /**
   * change reference for manually entered items online/ offline
   * @param event
   * @param element
   */
  referenceChanged(event, element: IcountsheetItemData) {
    let secondaryElement;
    if (this.dataSource.countSheet.dbId) {
      element.data.count_sheet_id = this.dataSource.countSheet.dbId;
    }
    element.data.count_sheet_client_id = this.dataSource.countSheet.id;
    element.data.audit_id = this.dataSource.countSheet.data.audit_id;

    const match: Iitem[] = this.items.filter(item =>
      (item.reference.toUpperCase() === event.target.value.toUpperCase()) || (item.unpunctuated_reference.toUpperCase() === event.target.value.toUpperCase())
    );

    if (match.length === 0) {
      this.showSnackbar('\'' + event.target.value.toUpperCase() + '\' is not a valid reference.', false);
      event.target.value = element.data.reference;
    } else {
      secondaryElement = JSON.parse(JSON.stringify(element));
      secondaryElement.data.quantity = 0;
      secondaryElement.data.hide = true;

      element.id = '';
      element.dbId = 0;
      element.data.reference = match[0].reference;
      element.data.item_id = match[0].id;
      element.data.description = match[0].description;
      element.data.is_lot_tracked = match[0].is_lot_number_tracked;
      element.data.is_lot_pool_managed = match[0].is_lot_pool_managed;
      element.data.is_serial_tracked = match[0].is_serial_tracked;
      element.data.min_order_quantity = match[0].minimum_order_quantity;
      element.data.is_consigned = match[0].is_consigned;

      this.countSheetItemHelper.setRecallWarning(element, this.dataSource.countSheet, this.dataSource.itemSettings, this.dataSource.itemCombinations, this.dataSource.lotPoolManagedItems, this.dataSource.recallOracleService);
      this.countSheetItemHelper.setExpirationWarning(element, this.dataSource.sapQuantities);
      this.updateCountSheetItem(element, secondaryElement, () => this.refreshDataSource());
    }
  }

  getRedspotQuantities(auditId, itemInstanceId) {
    return this._redspotInventoryImportService.getInventory(auditId).pipe(map(data => data.filter(rq => rq.kit_instance_id === itemInstanceId)));
  }

  getConsignmentQuantities(auditId) {
    return this._consignmentInventoryImportService.getInventory(auditId, true);
  }

  getItemCombinations() {
    return this._itemCombinationService.get();
  }

  getItems() {
    return this._itemService.getItems();
  }

  getLotPoolManagedItems() {
    return this._lotPoolManagedItemService.getLotPoolManagedItems();
  }

  getRootKitDefinitionItems(kitId) {
    return this._kitDefinitionItemService.getKitDefinitionItems().pipe(map(data => data.filter(kdi => kdi.kit_definition_id === kitId)));
  }

  getKits() {
    this._kitService.getKits().subscribe(data => {
      this.kits = data;
    });
  }

  getItemSettingsByAudit(auditId: number) {
    return this._itemSettingService.getItemSettingsByAudit(auditId);
  }

  getExclusions(auditId: number) {
    return this._exclusionService.getExclusions(auditId);
  }

  getWarehouses(auditId: number, warehouseId: number) {
    this._auditService.getWarehouses(auditId).subscribe(data => {
      this.warehouses = data;
      this.dropdownWarehouseId = warehouseId;
    });
  }

  getCountSheet(online: boolean, countSheetClientId: string) {
    return new Promise((resolve) => {
      const that = this;
      if (online) {
        this._countSheetService.getCountSheet(countSheetClientId).subscribe(function (data) {
          that.createdBy = data.created_by;
          that.location = data.audit_location;
          const newCountSheet = that._countSheetDatabaseService.new(data);
          that._countSheetDatabaseService.update(newCountSheet).then(_ => {
            resolve(newCountSheet);
          });
        });
      } else {
        this._countSheetDatabaseService.get(countSheetClientId).then(cs => {
          resolve(cs);
        });
      }
    });

  }

  getCountSheetItems(auditId: number, countSheetClientId: string) {
    return this._countSheetItemService.getCountSheetItems(auditId, { count_sheet_client_id: countSheetClientId });
  }

  isMinOrderQuantityGreaterThanOne(countSheetItem: IcountsheetItemData) {
    const minOrderQuantity = countSheetItem.data.min_order_quantity;
    if (minOrderQuantity > 1) {
      return true;
    }
    return false;
  }

  // todo: merge these two getcountsheetitem methods
  getCountSheetItemsInAnyState(online: boolean, auditId: number, countSheet: ICountSheetData) {
    const that = this;
    return new Promise((resolve) => {
      if (online) {
        this._countSheetItemService.getCountSheetItems(auditId, { count_sheet_client_id: countSheet.id }).subscribe(data => {
          that._countSheetItemDatabaseService.bulkUpdate(data.map(cs => that._countSheetItemDatabaseService.new(cs))).then(_ => {
            that._countSheetItemDatabaseService.find(auditId, { count_sheet_client_id: countSheet.id, _destroy: false }).then((matches: any[]) => {
              resolve(matches);
            });
          });
        });
      } else {
        that._countSheetItemDatabaseService.find(auditId, { count_sheet_client_id: countSheet.id, _destroy: false }).then((existingCountSheetItems: any[]) => {
          //we must have already cached these into indexeddb
          if (existingCountSheetItems.length > 0) {
            return resolve(existingCountSheetItems);
          }
          //get the data from the cached serviceworker
          that._countSheetItemService.getCountSheetItems(auditId).subscribe((auditCountSheetItems) => {
            const matches = auditCountSheetItems.filter(cs => cs.count_sheet_client_id === countSheet.id);
            that._countSheetItemService.refreshOfflineDataForCountSheet(auditId, countSheet.id, matches).then(function (csis) {
              resolve(csis);
            });
          });
        });
      }
    });
  }

  getKitInstance(countSheet: ICountSheetData) {
    return this._auditKitInstanceDatabaseService.get(countSheet.data.audit_kit_instance_id);
  }

  /**
   * Complete count sheet
   */
  completeCountSheet() {
    const countedMetadata: IcountMetadata = {
      counted_by_id: this.userData.user_id,
      counted_time: moment().toDate()
    };
    const countedStatus = 3;
    const result = this.areAllCountSheetItemsChecked();
    if (!result.allChecked) {
      this.snackBarConfig.message = 'Report the quantity of all unchecked items';
      this.snackBarConfig.success = false;
      this.openSnackBar();
      return;
    }
    this.onlineSubscription = this._onlineService.isOnlineSubscription().subscribe(online => {
      if (online && !this._onlineService.getTestOfflineBool()) {
        // set status to counted
        this._countSheetService.updateCountSheetStatus(this.dataSource.countSheet.dbId, countedStatus, countedMetadata).subscribe(data => {
          if (data.success) {
            this._countSheetDatabaseService.update(this._countSheetDatabaseService.new(data.count_sheet)).then(_ => {
              this.snackBarConfig.message = 'Count Sheet Complete';
              this.snackBarConfig.success = true;
              this.openSnackBar();
              this.goToCountSheets();
              this.removeRankDataFromLocalStorage();
            });
          } else {
            this.snackBarConfig.message = 'Failed';
            this.snackBarConfig.success = false;
            this.openSnackBar();
          }
        });
      } else {
        this.dataSource.countSheet.data.count_sheet_status = countedStatus;
        this.dataSource.countSheet.data.counted_by = this.userData;
        this.dataSource.countSheet.data.counted_by_id = this.userData.user_id;
        this.dataSource.countSheet.data.counted_time = moment().format('lll');
        this.dataSource.countSheet.isSynchronized = 0;
        this._countSheetDatabaseService.update(this.dataSource.countSheet).then(_ => {
          this.snackBarConfig.message = 'Count Sheet Complete';
          this.snackBarConfig.success = true;
          this.openSnackBar();
          this.goToCountSheets();
          this.removeRankDataFromLocalStorage();

        });
      }
    });
    this.onlineSubscription.unsubscribe();
  }

  reopenCountSheet() {
    const countedMetadata: IcountMetadata = {
      counted_by_id: this.userData.user_id,
      counted_time: moment().toDate()
    };
    const countedStatus = 3;
    this.onlineSubscription = this._onlineService.isOnlineSubscription().subscribe(online => {
      if (online && !this._onlineService.getTestOfflineBool()) {
        // set status to counted
        this._countSheetService.updateCountSheetStatus(this.dataSource.countSheet.dbId, countedStatus, countedMetadata).subscribe(data => {
          if (data.success) {
            this._countSheetDatabaseService.update(this._countSheetDatabaseService.new(data.count_sheet)).then(_ => {
              this.snackBarConfig.message = 'Reopening Countsheet!';
              this.snackBarConfig.success = true;
              this.openSnackBar();
              this.reloadPage(false);
            });
          } else {
            this.snackBarConfig.message = 'Failed';
            this.snackBarConfig.success = false;
            this.openSnackBar();
          }
        });
      } else {
        this.dataSource.countSheet.data.count_sheet_status = countedStatus;
        this.dataSource.countSheet.data.counted_by = this.userData;
        this.dataSource.countSheet.data.counted_time = moment().format('lll');
        this.dataSource.countSheet.isSynchronized = 0;
        this._countSheetDatabaseService.update(this.dataSource.countSheet).then(_ => {
          this.snackBarConfig.message = 'Reopening Countsheet!';
          this.snackBarConfig.success = true;
          this.openSnackBar();
          this.reloadPage(false);
        });
      }
    });
    this.onlineSubscription.unsubscribe();
  }

  printAndComplete() {
    const that = this;
    const result = this.areAllCountSheetItemsChecked();
    if (!result.allChecked) {
      that.snackBarConfig.message = 'Report the quantity of all unchecked items';
      that.snackBarConfig.success = false;
      that.openSnackBar();
      return;
    }
    new Promise((resolve, reject) => {
      if (that.dataSource.countSheet.data.attachments_count > 0) {
        return resolve(null);
      }
      const dialogRef = that._dialog.open(ConfirmationDialogComponent, {
        width: '30%',
        data: Messages.finishWithoutAttachment,
      });
      dialogRef.afterClosed().subscribe(answer => {
        if (answer) {
          return resolve(null);
        }
        reject();
      });
    }).then(() => {
      const countSheet = that.dataSource.countSheet;
      countSheet.data.count_sheet_status = 3;
      countSheet.data.status_text = 'Complete';
      that.dataSource.countSheet = countSheet;

      that.printer.print();

      that._databaseService.add('user_actions', {
        audit_id: that.auditId.toString(),
        auditor: `${that.userData.first_name} ${that.userData.last_name}`,
        timestamp: `${(new Date()).toUTCString()}`,
        area: '',
        kit_reference: `${that.dataSource.countSheet.data.reference}`,
        kit_lot_number: `${that.dataSource.countSheet.data.lot_number}`,
        action: 'Finished count sheet',
        total_items_counted: that.dataSource.countSheet.data.total_items_counted,
        site_id: that.dataSource.countSheet.data.audit_location_id,
        warehouse_id: that.dataSource.countSheet.data.warehouse_id
      });

      that.completeCountSheet();
    }, () => {
      console.log('they didnt want to finish without an attachment');
    });
  }

  cancelCounting() {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '30%',
      data: 'Are you sure you want to cancel counting?',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._databaseService.add('user_actions', {
          audit_id: this.auditId.toString(),
          auditor: `${this.userData.first_name} ${this.userData.last_name}`,
          timestamp: `${(new Date()).toUTCString()}`,
          area: '',
          kit_reference: `${this.dataSource.countSheet.data.reference}`,
          kit_lot_number: `${this.dataSource.countSheet.data.lot_number}`,
          action: 'Canceled count sheet',
          total_items_counted: this.dataSource.countSheet.data.total_items_counted,
          site_id: this.dataSource.countSheet.data.audit_location_id,
          warehouse_id: this.dataSource.countSheet.data.warehouse_id
        });
        this.dataSource.countSheet.data.archived = true;
        this.dataSource.countSheet.isSynchronized = 0;
        this._countSheetDatabaseService.update(this.dataSource.countSheet).then(() => {
          this._countSheetDatabaseService.archiveCountSheetItems(this.dataSource.countSheet).then(() => {
            this.goToCountSheets();
            this.removeRankDataFromLocalStorage()
          });
        });
      }
    });
  }

  clearLocal() {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '30%',
      data: 'Are you sure you want to clear this count?',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._databaseService.add('user_actions', {
          audit_id: this.auditId.toString(),
          auditor: `${this.userData.first_name} ${this.userData.last_name}`,
          timestamp: `${(new Date()).toUTCString()}`,
          area: '',
          kit_reference: `${this.dataSource.countSheet.data.reference}`,
          kit_lot_number: `${this.dataSource.countSheet.data.lot_number}`,
          action: 'Clear Current Count',
          total_items_counted: this.dataSource.countSheet.data.total_items_counted,
          site_id: this.dataSource.countSheet.data.audit_location_id,
          warehouse_id: this.dataSource.countSheet.data.warehouse_id
        });


        this.dataSource.countSheet.isSynchronized = 0;
        this._countSheetDatabaseService.update(this.dataSource.countSheet).then(() => {
          this._countSheetDatabaseService.clearUnsyncedItems(this.dataSource.countSheet).then(() => {
            this.goToCountSheets();
            this.removeRankDataFromLocalStorage()
          });
        });
      }
    });
  }

  areAllCountSheetItemsChecked() {
    this.dataSource.filters = { orderType: 'consigned', exclusions: 'hide' };
    this.refreshDataSource();
    const data = this.dataSource.data;
    const result = { allChecked: true, uncheckedElements: [] };
    const that = this;
    data.forEach(element => {
      const checked = that.anyChecked(element);
      result.allChecked = result.allChecked && checked;
      if (!checked) {
        element.data.verification_status = 'NEEDED';
        result.uncheckedElements.push(element);
      }
    });

    return result;
  }

  goToCountSheets() {
    if (this.previousUrl.indexOf('siteID') !== -1) {
      this._router.navigate([this.removeQueryStrings(this.previousUrl)], { queryParams: { status: this.status, orderType: 'all', siteID: this.location.id } });
    } else {
      if (this.viewOnly) {
        this._router.navigate([this.previousUrl], { queryParams: { status: this.status, orderType: 'consigned' } });
      } else {
        this._router.navigate([this.previousUrl], { queryParams: { status: this.status, orderType: 'consigned' } });
      }
    }

  }

  removeQueryStrings(url: string) {
    let res = '';
    const qsStart = url.indexOf('?');
    if (qsStart !== -1) {
      res = url.substr(0, qsStart);
    } else {
      res = url;
    }
    return res;
  }

  goToAuditDashboard() {
    this._router.navigate(['audit_dashboard', this.auditId]);
  }

  updateBom(value) {
    return new Promise((resolve) => {
      value = value || 'null'; //so we have a set value, but it will get set to nil in the backend
      this.dataSource.countSheet.data.kit_id = value;
      this.dataSource.countSheet.isSynchronized = 0;
      this._countSheetDatabaseService.update(this.dataSource.countSheet).then(_ => {
        resolve(null);
      });
    });
  }

  exclusionChanged(event) {
    const filters = this.dataSource.filters;
    filters['exclusions'] = event.value;
    this.dataSource.filters = filters;
    this.refreshDataSource();
  }

  bomChanged(event: any) {
    this._trackLoading.startLoading('bom-changed', 'Updating BOM');
    this.updateBom(+event.value).then(() => {
      this.getRootKitDefinitionItems(+event.value).subscribe(kdis => {
        this.dataSource.rootKitDefinitionItems = kdis;
        this.refreshDataSource();
        this._trackLoading.stopLoading('bom-changed');
      });
    });
  }

  viewChanged(event: any) {
    this._trackLoading.startLoading('view-changed', 'Updating View');
    const value = [1, '1', 'true', true].includes(event.value);
    this.dataSource.countSheet.isSynchronized = 0;
    this.dataSource.countSheet.data.show_expected_item_list = value;
    const that = this;
    this._countSheetDatabaseService.update(this.dataSource.countSheet).then(_ => {
      zip(
        that._countSheetDatabaseService.get(that.dataSource.countSheet.id),
        that._countSheetItemDatabaseService.find(that.auditId, { count_sheet_client_id: that.dataSource.countSheet.id, _destroy: false })
      ).subscribe((data: any[]) => {
        that.dataSource.countSheet = data[0];
        that.dataSource.countSheetItems = data[1];
        this.refreshDataSource();
        this._trackLoading.stopLoading('view-changed');
      });
    });
  }

  warehouseChanged(event) {
    const warehouse = this.warehouses.find(w => {
      return w.account_id === event.value;
    });

    this.dataSource.countSheet.data.warehouse_id = warehouse.id;
    this.dataSource.countSheet.data.warehouse_name = warehouse.name;
    this.dataSource.countSheet.isSynchronized = 0;
    this._countSheetDatabaseService.update(this.dataSource.countSheet);
    this.dropdownWarehouseId = warehouse.id;
  }

  showSnackbar(message: string, success: boolean) {
    this.snackBarConfig.message = message;
    this.snackBarConfig.success = success;
    this.openSnackBar();
  }

  reloadWhenBackOnline() {
    this.snackBarConfig.message = 'Connected!! Reloading...';
    this.snackBarConfig.success = true;
    this.openSnackBar();
    this._router.navigate([this.auditId, 'kit_instance_count_sheet', this._route.snapshot.paramMap.get('id'), this.viewOnly], { queryParams: { status: this.status } });
  }

  reloadPage(isSynchronized: boolean) {
    this._router.navigate([this.auditId, 'kit_instance_count_sheet', this._route.snapshot.paramMap.get('id'), false], { queryParams: { status: this.status } });
  }

  openSnackBar() {
    this._snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: this.snackBarConfig,
      duration: this.snackBarConfig.duration
    });
  }

  openWarningDialog(message: string) {
    this.countSheetItemHelper.playErrorSound(this._audioPlayerService.setting);
    const dialogRef = this._dialog.open(WarningDialogComponent, {
      width: '50%',
      disableClose: true,
      role: 'alertdialog',
      data: {
        message: message
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  setAuditedByValue(countSheetItems) {
    if (countSheetItems) {
      const names = countSheetItems.map(csi => csi.data.modified_by).filter((v, i, a) => a.indexOf(v) === i);
      this.auditedBy = names.join(' / ');
    } else {
      this.auditedBy = '';
    }
  }

  setCountSheetStats() {
    const items = this.dataSource.countSheetItems;
    if (items.length > 0) {
      switch (this.dataSource.filters.orderType) {
        case 'consigned':
          if (this.dataSource.filters.exclusions === 'hide') {
            this.actualItemCount = items.filter((cs: IcountsheetItemData) => cs.data.checked && cs.data.is_consigned && !cs.data.is_excluded).reduce((a: number, b: IcountsheetItemData) => a + b.data.quantity, 0);
          } else {
            this.actualItemCount = items.filter((cs: IcountsheetItemData) => cs.data.checked && cs.data.is_consigned).reduce((a: number, b: IcountsheetItemData) => a + b.data.quantity, 0);
          }
          break;
        case 'owned':
          if (this.dataSource.filters.exclusions === 'hide') {
            this.actualItemCount = items.filter(csi => csi.data.is_consigned === false && !csi.data.is_excluded).map(csi => csi.data.quantity).reduce((a, b) => a + b);
          } else {
            this.actualItemCount = items.filter(csi => csi.data.is_consigned === false).map(csi => csi.data.quantity).reduce((a, b) => a + b);
          }
          break;
        default:
          if (this.dataSource.filters.exclusions === 'hide') {
            this.actualItemCount = items.filter(csi => !csi.data.is_excluded).map(csi => csi.data.quantity).reduce((a, b) => a + b);
          } else {
            this.actualItemCount = items.map(csi => csi.data.quantity).reduce((a, b) => a + b);
          }
      }
    } else {
      this.actualItemCount = 0;
    }
    this.expectedItemCount = this.kitInstance.data.item_count;
    this.statsComplete = true;
  }

  getColor(current, max) {
    let color = 'orange';
    const percentage = (current / max) * 100;
    if (percentage >= 100) {
      color = 'green';
    } else if (!percentage) {
      color = '#eaeaea';
    }
    return color;
  }

  continueInTable(event, element, callback) {
    const lastVisible = this.virtualScroller.viewPortItems.indexOf(element) === this.virtualScroller.viewPortItems.length - 1;
    const notLast = this.dataSource.data.indexOf(element) !== this.dataSource.data.length - 1;
    event.preventDefault();
    if (lastVisible && notLast) {
      const lastAvailablePosition = this.dataSource.data.length - 1;
      const wantedPosition = this.dataSource.data.indexOf(element) + Math.ceil(this.virtualScroller.viewPortItems.length / 2) + 1;
      this.virtualScroller.scrollToIndex(
        Math.min(lastAvailablePosition, wantedPosition),
        false,
        0,
        undefined,
        callback
      );
    } else {
      callback();
    }
  }

  keyDown(event: KeyboardEvent, element: any) {
    if (event.code === 'Space') {
      event.preventDefault();
      let el = <HTMLElement>event.srcElement;
      if (el.classList.contains('mat-checkbox-input')) {
        if (element.count_sheet_items) {
          this.lotPoolManagedCheckedClicked(event, element);
        } else {
          this.checkedClicked(event, element);
        }
        this.continueInTable(event, element, function () {
          while (el.nodeName !== 'TR') {
            el = el.parentElement;
          }
          const node = el.nextElementSibling;
          if (node) {
            const input = <HTMLInputElement>node.querySelector('input[type="number"]');
            if (input) {
              setTimeout(() => {
                input.focus();
                input.select();
              }, 300);
            }
          }
        });
      }
    } else if (event.code === 'Tab') {
      let el = <HTMLElement>event.srcElement;
      //currently viewing the last item
      //we don't want to move down while they aren't done with the row
      if (el.classList.contains('mat-checkbox-input')) {
        this.continueInTable(event, element, function () {
          while (el.nodeName !== 'TR') {
            el = el.parentElement;
          }
          const node = el.nextElementSibling;
          if (node) {
            const input = <HTMLInputElement>node.querySelector('input[type="number"]');
            if (input) {
              setTimeout(() => {
                input.focus();
                input.select();
              }, 300);
            }
          }
        });
      }
    } else {
      if (!this.positiveIntegerHelper.isNumeric(event)) {
        event.preventDefault();
      }
    }
  }

  anyUnsynched(row) {
    return (row.count_sheet_items || [row]).filter(r => !r.isSynchronized).length > 0;
  }

  anyChecked(row) {
    return (row.count_sheet_items || [row]).filter(r => r.data.checked).length > 0;
  }

  isLotPoolManaged(row) {
    return this.countSheetItemHelper.getIsLotPoolManaged(row.data.item_id, this.dataSource.lotPoolManagedItems);
  }

  lotPoolManagedQuantity(row) {
    if (row.count_sheet_items.length > 0) {
      const checkedCountSheetItems = row.count_sheet_items.filter(csi => csi.data.checked);
      if (checkedCountSheetItems.length > 0) {
        return checkedCountSheetItems.reduce((a, b) => a + b.data.quantity, 0);
      }
      return row.count_sheet_items.reduce((a, b) => a + b.data.quantity, 0);
    }
    return row.data.expected_quantity;
  }

  openCountSheetItemImportDialog() {
    const dialogRef = this._dialog.open(ImportCountSheetItemComponent, {
      width: '30%',
      data: {
        countSheet: this.dataSource.countSheet,
        lotPoolManagedItems: this.dataSource.lotPoolManagedItems,
        redspotItems: this.dataSource.redspotQuantities,
        sapItems: this.dataSource.sapQuantities
      }
    });
    const that = this;
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getCountSheetItems(this.auditId, this.dataSource.countSheet.id).subscribe(countSheetItems => {
          zip(
            ...countSheetItems.map(function (csi) { return that._countSheetItemDatabaseService.update(that._countSheetItemDatabaseService.new(csi)); }).concat(new Promise((r) => { r(null); }))
          ).subscribe(_ => {
            zip(
              that._countSheetDatabaseService.get(that.dataSource.countSheet.id),
              that._countSheetItemDatabaseService.find(that.auditId, { count_sheet_client_id: that.dataSource.countSheet.id, _destroy: false })
            ).subscribe((data: any[]) => {
              this.dataSource.countSheet = data[0];
              this.dataSource.countSheetItems = data[1];
              this.refreshDataSource();
            });
          });
        });
      }
    });
  }

  scrollToBottom() {
    document.getElementById('bottom').scrollIntoView();
    this.virtualScroller.scrollToIndex(this.dataSource.data.length - 1);
  }

  exportCountsheetItems() {
    this.refreshDataSource();
    const exportOptions: ICountsheetExportOptions = { kit: true, blank: !this.dataSource.countSheet.data.show_expected_item_list };
    const exportHelper = new CountSheetExportHelper(this._excelService);
    exportHelper.exportCountsheetItemsToExcel(this.dataSource.data, exportOptions);
  }

  attachFile() {
    this.openAttachmentDialog();
  }

  openAttachmentDialog() {
    const that = this;
    const dialogRef = that._dialog.open(AttachmentDialogComponent, {
      width: '70%',
      data: {
        countsheet: that.dataSource.countSheet,
        devices: that.cameras
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      that._countSheetDatabaseService.get(that.dataSource.countSheet.id).then((cs: ICountSheetData) => {
        that.dataSource.countSheet = cs;
      });
    });
  }

  removeRankDataFromLocalStorage() {
    let keys = this.dataSource.redspotInventoryIds;
    keys.forEach((k) => {
      localStorage.removeItem(k);
    })
  }
}
