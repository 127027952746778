import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { WarehouseCountedDataSource } from './warehouse-counted-datasource';

@Component({
  selector: 'app-warehouse-counted',
  templateUrl: './warehouse-counted.component.html',
  styleUrls: ['./warehouse-counted.component.css']
})
export class WarehouseCountedComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() counted;
  @Input() filters;
  @Output() onExportToExcel: EventEmitter<any> = new EventEmitter<any>();
  dataSource: WarehouseCountedDataSource;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['reference', 'description', 'kit', 'kit_lot_number', 'lot', 'serial_number', 'expected', 'quantity', 'overage', 'shortage'];

  ngOnInit() {
    this.processData(this.counted);
    this.dataSource = new WarehouseCountedDataSource(this.paginator, this.sort, this.counted, this.filters);
  }

  ngOnDestroy(): void {
    
  }

  processData(countedItems) {
    countedItems.forEach(item => {
      if (item.kit_instance) {
        item['kit_reference'] = item.kit_instance.item.reference;
        item['kit_lot_number'] = item.kit_instance.lot_number ? item.kit_instance.lot_number : '';
      } else {
        item['kit_reference'] = '';
        item['kit_lot_number'] = '';
      }
    });
  }

  getSerial(serial_number) {
    if (serial_number) {
      return serial_number;
    } else {
      return '-';
    }
  }

  exportToExcel() {
    this.onExportToExcel.emit(this.dataSource.filteredData());
  }

  getLot(lot_number) {
    if (lot_number) {
      return lot_number;
    } else {
      return '-';
    }
  }

  applyFilter() {
    this.dataSource = new WarehouseCountedDataSource(this.paginator, this.sort, this.counted, this.filters);
  }
}
