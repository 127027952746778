/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./material-snackbar.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i8 from "@angular/material/icon";
import * as i9 from "@angular/common";
import * as i10 from "./material-snackbar.component";
import * as i11 from "@angular/material/snack-bar";
var styles_MaterialSnackbarComponent = [i0.styles];
var RenderType_MaterialSnackbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MaterialSnackbarComponent, data: {} });
export { RenderType_MaterialSnackbarComponent as RenderType_MaterialSnackbarComponent };
function View_MaterialSnackbarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "mat-icon", [["class", "material-icons mat-icon"], ["role", "img"], ["style", "color:green"]], [[2, "mat-icon-inline", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(3, 638976, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["check_circle"]))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 3).inline; _ck(_v, 2, 0, currVal_2); }); }
function View_MaterialSnackbarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "mat-icon", [["class", "material-icons mat-icon"], ["role", "img"], ["style", "color:red"]], [[2, "mat-icon-inline", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(3, 638976, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["error"]))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 3).inline; _ck(_v, 2, 0, currVal_2); }); }
export function View_MaterialSnackbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MaterialSnackbarComponent_1)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MaterialSnackbarComponent_2)), i1.ɵdid(5, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.config.success; _ck(_v, 3, 0, currVal_1); var currVal_2 = !_co.config.success; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config.message; _ck(_v, 1, 0, currVal_0); }); }
export function View_MaterialSnackbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-material-snackbar", [], null, null, null, View_MaterialSnackbarComponent_0, RenderType_MaterialSnackbarComponent)), i1.ɵdid(1, 114688, null, 0, i10.MaterialSnackbarComponent, [i11.MAT_SNACK_BAR_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MaterialSnackbarComponentNgFactory = i1.ɵccf("app-material-snackbar", i10.MaterialSnackbarComponent, View_MaterialSnackbarComponent_Host_0, {}, {}, []);
export { MaterialSnackbarComponentNgFactory as MaterialSnackbarComponentNgFactory };
