import { DataSource } from '@angular/cdk/collections';
import { Observable, of as observableOf, merge } from 'rxjs';
import { IcountsheetItemData, ILotPoolManagedCountsheetItemGroup } from '../../../interfaces/icountsheet-item';
import { IItemCombinationLookupOptions } from 'src/app/interfaces/i-item-combination-lookup-options';
import { CountSheetItemHelper } from 'src/app/helpers/countsheet-item-helper';
import { ICountSheetData } from 'src/app/interfaces/icount-sheet-data';
import { CountSheetItemDatabaseService } from 'src/app/services/count-sheet-item-database.service';
import { RecallOracleService } from 'src/app/services/recall-oracle.service';

/**
 * Data source for the UnkittedItemsCountsheet view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class UnkittedItemsCountsheetDataSource extends DataSource<IcountsheetItemData> {
  data: IcountsheetItemData[] = [];
  _countSheet: ICountSheetData;
  _redspotQuantities: any;
  _sapQuantities: any;
  _rootKitDefinitionItems: any;
  _itemSettings: any;
  _filters: any;
  _itemCombinations: any;
  _exclusions: any;
  _countSheetItems: any;
  _lotPoolManagedItems: any;
  _recallOracleService: RecallOracleService;

  public set recallOracleService(value) {
    this._recallOracleService = value;
  }

  public get recallOracleService() {
    return this._recallOracleService;
  }

  public set lotPoolManagedItems(value) {
    this._lotPoolManagedItems = value;
  }

  public get lotPoolManagedItems() {
    return this._lotPoolManagedItems;
  }

  public set exclusions(value) {
    this._exclusions = value;
  }

  public get exclusions() {
    return this._exclusions;
  }

  public set itemCombinations(value) {
    this._itemCombinations = value;
  }

  public get itemCombinations() {
    return this._itemCombinations;
  }

  public set itemSettings(value) {
    this._itemSettings = value;
  }

  public get itemSettings() {
    return this._itemSettings;
  }

  public set rootKitDefinitionItems(value: any[]) {
    this._rootKitDefinitionItems = value;
  }

  public get rootKitDefinitionItems() {
    return this._rootKitDefinitionItems;
  }

  public set sapQuantities(value) {
    this._sapQuantities = value;
  }

  public get sapQuantities() {
    return this._sapQuantities;
  }

  public set redspotQuantities(value) {
    this._redspotQuantities = value;
  }

  public get redspotQuantities() {
    return this._redspotQuantities;
  }

  public set countSheet(value) {
    this._countSheet = value;
  }

  public get countSheet() {
    return this._countSheet;
  }

  public set countSheetItems(value) {
    this._countSheetItems = value;
  }

  public get countSheetItems() {
    return this._countSheetItems;
  }

  constructor(
    private countSheetItemHelper: CountSheetItemHelper,
    private _countSheetItemDatabaseService: CountSheetItemDatabaseService
  ) {
    super();
  }

  refresh() {

    const now = new Date();
    const that = this;
    let countSheetItems = this.countSheetItems.slice().filter(csi => !csi._destroy);

    if (this.countSheet.data.show_expected_item_list) {
      const lotPoolManagedCountSheetItems = countSheetItems.filter(csi => that.countSheetItemHelper.getIsLotPoolManaged(csi.data.item_id, that.lotPoolManagedItems));
      const notLotPoolManagedCountSheetItems = countSheetItems.filter(csi => !that.countSheetItemHelper.getIsLotPoolManaged(csi.data.item_id, that.lotPoolManagedItems));

      const lotPoolManagedCountSheetItemGroup = [];
      lotPoolManagedCountSheetItems.filter(csi => !csi.data.hide).forEach(csi => {
        let match: ILotPoolManagedCountsheetItemGroup = lotPoolManagedCountSheetItemGroup.find(csig => {
          return csig.data.item_id === csi.data.item_id;
        });
        if (!match) {
          match = {
            count_sheet_items: [],
            available_redspot_inventory: [],
            data: {
              item_id: csi.data.item_id,
              reference: csi.data.reference,
              description: csi.data.description,
              is_consigned: csi.data.is_consigned,
              expected_quantity: 0,
              quantity: 0
            }
          };
          lotPoolManagedCountSheetItemGroup.push(match);
        }
        match.count_sheet_items.push(csi);
        match.data.quantity += csi.data.quantity;
      });

      this.rootKitDefinitionItems.forEach(rkdi => {
        if (!that.countSheetItemHelper.getIsLotPoolManaged(rkdi.item.id, that.lotPoolManagedItems)) {
          if (!notLotPoolManagedCountSheetItems.find(d => d.data.item_id === rkdi.item_id)) {
            const row = {
              id: 0,
              dbId: 0,
              data: {
                reference: rkdi.item.reference,
                item_id: rkdi.item.id,
                description: rkdi.item.description,
                count_sheet_id: that.countSheet.dbId,
                count_sheet_client_id: that.countSheet.id,
                audit_id: that.countSheet.data.audit_id,
                is_consigned: rkdi.item.is_consigned,
                min_order_quantity: rkdi.item.minimum_order_quantity,
                is_lot_tracked: rkdi.item.is_lot_number_tracked,
                is_serial_tracked: rkdi.item.is_serial_tracked,
                checked: false,
                lot_number: '',
                serial: '',
                quantity: null,
                expiration_date: null,
                redspot_expiration_date: null,
                isKitted: false
              }
            };
            notLotPoolManagedCountSheetItems.push(row);
          }

        } else if (that.countSheetItemHelper.getIsLotPoolManaged(rkdi.item.id, that.lotPoolManagedItems)) {
          if (!lotPoolManagedCountSheetItemGroup.find(d => d.data.item_id === rkdi.item_id)) {
            const row: ILotPoolManagedCountsheetItemGroup = {
              count_sheet_items: [],
              available_redspot_inventory: [],
              data: {
                item_id: rkdi.item.id,
                reference: rkdi.item.reference,
                description: rkdi.item.description,
                is_consigned: rkdi.item.is_consigned,
                expected_quantity: 0,
                quantity: 0
              }
            };
            lotPoolManagedCountSheetItemGroup.push(row);
          }
        }
      });

      countSheetItems = notLotPoolManagedCountSheetItems.concat(lotPoolManagedCountSheetItemGroup);
    }
    //final processing
    countSheetItems.forEach(function (kics) {
      if (kics.count_sheet_items) {
        kics.data.show_recalled_warning = that.recallOracleService.get(kics.data.item_id, kics.data.lot_number, kics.data.serial);
        kics.data.is_excluded =  that.countSheetItemHelper.getExclusion(kics.data.item_id, that.exclusions);
        kics.data.in_definition = !!that.countSheetItemHelper.matchingRootKitDefinitionItem(kics.data.item_id, that.rootKitDefinitionItems);
      } else {
        if (kics.data.checked) {

          kics.data.show_recalled_warning = that.recallOracleService.get(kics.data.item_id, kics.data.lot_number, kics.data.serial)
          kics.data.show_invalid_warning = !that.countSheetItemHelper.checkIfValidItemCombination(kics.data.item_id, kics.data.lot_number, kics.data.serial, false, kics.data.is_lot_tracked, kics.data.is_serial_tracked, that.itemSettings, that.itemCombinations);


          const rqLookupOptions: IItemCombinationLookupOptions = {itemId: kics.data.item_id, lotNumber: kics.data.lot_number};
          const redspotQuantity = that.countSheetItemHelper.getRedspotQuantity(rqLookupOptions, that.redspotQuantities);


          const sqLookupOptions: IItemCombinationLookupOptions = { itemId: kics.data.item_id, lotNumber: kics.data.lot_number, serialNumber: kics.data.serial };
          const sapQuantity = that.countSheetItemHelper.getSapQuantity(sqLookupOptions, that.sapQuantities);

          if (!kics.data.expiration_date) {
            if (sapQuantity && sapQuantity.expiry_date) {
              kics.data.expiration_date = sapQuantity.expiry_date;
              kics.data.show_expired_warning = new Date(sapQuantity.expiry_date) < now;
            } else if (redspotQuantity && redspotQuantity.expiry_date) {
              kics.data.expiration_date = redspotQuantity.expiry_date;
              kics.data.show_expired_warning = new Date(redspotQuantity.expiry_date) < now;
            }
          } else {
            kics.data.show_expired_warning = new Date(kics.data.expiration_date) < now;
          }
        }
        kics.data.is_excluded = that.countSheetItemHelper.getExclusion(kics.data.item_id, that.exclusions);


        kics.data.in_definition = !!that.countSheetItemHelper.matchingRootKitDefinitionItem(kics.data.item_id, that.rootKitDefinitionItems);

      }
    });


    const sortFunction = function (a, b) {
      const aMatch = that.countSheetItemHelper.matchingRootKitDefinitionItem(a.data.item_id, that.rootKitDefinitionItems);
      const bMatch = that.countSheetItemHelper.matchingRootKitDefinitionItem(b.data.item_id, that.rootKitDefinitionItems);

      if (aMatch && bMatch) {
        if (aMatch['position'] > bMatch['position']) {
          return 1;
        } else if (aMatch['position'] < bMatch['position']) {
          return -1;
        } else {
          return 0;
        }
      } else if (aMatch) {
        return -1;
      } else if (bMatch) {
        return 1;
      } else {
        if (a.data.rank > b.data.rank) {
          return 1;
        } else if (a.data.rank < b.data.rank) {
          return -1;
        }
        return 0;
      }
    };

    this.data = countSheetItems.filter(csi => !csi.data.hide).sort(sortFunction);
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<IcountsheetItemData[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      observableOf(this.data),
    ];

    // Set the paginators length
    // this.paginator.length = this.data.length;

    return merge(...dataMutations);
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() { }

}
