import { Component, ViewChild, OnDestroy } from '@angular/core';
import { map } from 'rxjs/operators';
import { ExportToExcelService } from '../../services/export-to-excel.service';
import { IexcelExport } from '../../interfaces/iexcel-export';
import { Router } from '@angular/router';
import { AuditService } from '../../services/audit.service';
import { ExclusionService } from '../../services/exclusion.service';
import { ActivatedRoute } from '@angular/router';
import { WebWorkerService } from '../../../web-worker.service';
import { ITableFilter } from 'src/app/interfaces/itable-filter';
import { WarehouseCountedComponent } from './warehouse-counted/warehouse-counted.component';
import { WarehouseOveragesComponent } from './warehouse-overages/warehouse-overages.component';
import { WarehouseShortagesComponent } from './warehouse-shortages/warehouse-shortages.component';

@Component({
  selector: 'app-warehouse-dashboard',
  templateUrl: './warehouse-dashboard.component.html',
  styleUrls: ['./warehouse-dashboard.component.css']
})
export class WarehouseDashboardComponent implements OnDestroy {
  @ViewChild('countedTable') countedTableComponent: WarehouseCountedComponent;
  @ViewChild('overagesTable') overagesTableComponent: WarehouseOveragesComponent;
  @ViewChild('shortagesTable') shortagesTableComponent: WarehouseShortagesComponent;
  itemPercentage: number;
  kitPercentage: number;
  percentColor = '#51A400';
  color = 'primary';
  mode = 'determinate';
  showCounted = true;
  showOverages = false;
  showShortages = false;
  currentTable = 'counted';
  warehouse;
  lengths;
  counted;
  overages;
  shortages;
  loading = true;
  filters: ITableFilter = {
    kitSearchString: ''
  };

  ngOnDestroy(): void {
    this.countedTableComponent = null;
    this.overagesTableComponent = null;
    this.shortagesTableComponent = null;
  }

  constructor(
    private excelService: ExportToExcelService,
    private _router: Router,
    private _auditService: AuditService,
    private _exclusionService: ExclusionService,
    private _route: ActivatedRoute,
    private _workerService: WebWorkerService
  ) {
    this._auditService.getWarehouseItemsResults(this._route.snapshot.paramMap.get('audit_id'), this._route.snapshot.paramMap.get('warehouse_id')).subscribe(data => {
      this.warehouse = data;
      this.calculateItemPercentageRunner({ aic: this.warehouse.actual_item_count, eic: this.warehouse.expected_item_count });
      this.calculateKitPercentageRunner({ aic: this.warehouse.kit_count_verified, eic: this.warehouse.expected_kit_count });
      this.populateWarehouseDataRunner(data);
      this.loading = false;
    });
  }

  calculateKitPercentage(numbers) {
    let percentage = 0;
    if (numbers.aic === 0) {
      percentage = 0;
    } else {
      percentage = Math.round((numbers.aic / numbers.eic) * 100);
      if (percentage > 100) {
        percentage = 100;
      }
    }
    return percentage;
  }

  calculateKitPercentageRunner(numbers) {
    const promise = this._workerService.run(this.calculateItemPercentage, numbers);
    promise.then(data => {
      this.kitPercentage = data;
    });
  }

  calculateItemPercentage(numbers) {
    let percentage = 0;
    if (numbers.aic === 0) {
      percentage = 0;
    } else {
      percentage = Math.round((numbers.aic / numbers.eic) * 100);
      if (percentage > 100) {
        percentage = 100;
      }
    }
    return percentage;
  }

  calculateItemPercentageRunner(numbers) {
    const promise = this._workerService.run(this.calculateItemPercentage, numbers);
    promise.then(data => {
      this.itemPercentage = data;
    });
  }

  populateWarehouseData(warehouseData) {
    const counted = warehouseData.counts.filter(c => c['actual_quantity'] > 0);
    const overages = warehouseData.counts.filter(c => c['actual_quantity'] > c['expected_quantity']);
    const shortages = warehouseData.counts.filter(c => c['expected_quantity'] > c['actual_quantity']);
    const lengths = {
      counted: counted.map(c => c['actual_quantity']).reduce((total, quantity) => total + quantity, 0),
      overages: overages.map(c => c['actual_quantity'] - c['expected_quantity']).reduce((total, quantity) => total + quantity, 0),
      shortages: shortages.map(c => c['expected_quantity'] - c['actual_quantity']).reduce((total, quantity) => total + quantity, 0)
    };

    return {
      counted: counted,
      overages: overages,
      shortages: shortages,
      lengths: lengths
    };
  }

  populateWarehouseDataRunner(warehouseData) {
    const promise = this._workerService.run(this.populateWarehouseData, warehouseData);
    promise.then(data => {
      this.counted = data.counted;
      this.overages = data.overages;
      this.shortages = data.shortages;
      this.lengths = data.lengths;
    });
  }

  changeTable(value) {
    switch (value) {
    case 'counted':
      this.currentTable = value;
      this.showOverages = false;
      this.showShortages = false;
      this.showCounted = true;
      break;
    case 'shortage':
      this.currentTable = value;
      this.showOverages = false;
      this.showCounted = false;
      this.showShortages = true;
      break;
    case 'overage':
      this.currentTable = value;
      this.showShortages = false;
      this.showCounted = false;
      this.showOverages = true;
      break;
    default:
    }
  }

  exportToExcel(data) {
    // data grouped by reference
    const grouped_data = this.getGroupedData(data);
    //array to send to exel-export service
    const bothTabs = [];
    const tabOneData = [];
    const tabTwoData = [];
    //represents a single row in exel sheet
    let dataRow: IexcelExport;
    let groupedDataRow: IexcelExport;
    const that = this;
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });
    //switching the data to be exported based on the current table that is displayed
    switch (this.currentTable) {
    case 'counted':
      data.forEach(item => {
        const variance = item.actual_quantity - item.expected_quantity;
        const price = parseFloat(item.stocking_price + '0');

        dataRow = {
          reference: item.item.reference,
          description: item.item.description,
          kit_reference: (item.kit_instance_id ? item.kit_instance.item.reference : null),
          kit_lot_number: (item.kit_instance_id ? item.kit_instance.lot_number : null),
          lot_number: item.lot_number,
          serial_number: item.serial_number,
          expected_qty: item.expected_quantity,
          actual_qty: item.actual_quantity,
          overage: variance > 0 ? variance : 0,
          shortage: variance < 0 ? variance : 0,
          stocking_price: formatter.format(price)
        };

        tabOneData.push(dataRow);
      });
      for (const [key, value] of Object.entries(grouped_data)) {
        groupedDataRow = {
          reference: value[0].reference,
          description: value[0].description,
          expected_qty: value[0].expected_qty,
          actual_qty: value[0].actual_qty,
          overage: value[0].overage,
          shortage: value[0].shortage,
          stocking_price: formatter.format(value[0].stocking_price)
        };
        tabTwoData.push(groupedDataRow);
      }

      break;
    case 'shortage':
      data.forEach(item => {
        const variance = item.actual_quantity - item.expected_quantity;
        const price = parseFloat(item.stocking_price + '0');
        dataRow = {
          reference: item.item.reference,
          description: item.item.description,
          kit_reference: (item.kit_instance_id ? item.kit_instance.item.reference : null),
          kit_lot_number: (item.kit_instance_id ? item.kit_instance.lot_number : null),
          lot_number: item.lot_number,
          serial_number: item.serial_number,
          overage: variance > 0 ? variance : 0,
          shortage: variance < 0 ? variance : 0,
          stocking_price: formatter.format(price)
        };

        tabOneData.push(dataRow);
      });

      for (const [key, value] of Object.entries(grouped_data)) {
        groupedDataRow = {
          reference: value[0].reference,
          description: value[0].description,
          expected_qty: value[0].expected_qty,
          actual_qty: value[0].actual_qty,
          overage: value[0].overage,
          shortage: value[0].shortage,
          stocking_price: formatter.format(value[0].stocking_price)
        };
        tabTwoData.push(groupedDataRow);
      }

      break;
    case 'overage':
      data.forEach(item => {
        const variance = item.actual_quantity - item.expected_quantity;
        const price = parseFloat(item.stocking_price + '0');
        dataRow = {
          reference: item.item.reference,
          description: item.item.description,
          kit_reference: (item.kit_instance_id ? item.kit_instance.item.reference : null),
          kit_lot_number: (item.kit_instance_id ? item.kit_instance.lot_number : null),
          lot_number: item.lot_number,
          serial_number: item.serial_number,
          overage: variance > 0 ? variance : 0,
          shortage: variance < 0 ? variance : 0,
          stocking_price: formatter.format(price)
        };

        tabOneData.push(dataRow);
      });

      for (const [key, value] of Object.entries(grouped_data)) {
        groupedDataRow = {
          reference: value[0].reference,
          description: value[0].description,
          expected_qty: value[0].expected_qty,
          actual_qty: value[0].actual_qty,
          overage: value[0].overage,
          shortage: value[0].shortage,
          stocking_price: formatter.format(value[0].stocking_price)
        };
        tabTwoData.push(groupedDataRow);
      }

      break;
    default:
    }
    // passing data to export excel service
    bothTabs.push(tabOneData);
    bothTabs.push(tabTwoData);
    this.excelService.exportDataAsExcelFile(bothTabs, this.warehouse.name + '_Warehouse_Dashboard_' + this.currentTable, {});
  }

  goToAuditDashboard() {
    this._router.navigate(['audit_dashboard', this._route.snapshot.paramMap.get('audit_id')]);
  }

  applyFilter() {
    if (this.countedTableComponent) {
      this.countedTableComponent.applyFilter();
    }

    if (this.overagesTableComponent) {
      this.overagesTableComponent.applyFilter();
    }

    if (this.shortagesTableComponent) {
      this.shortagesTableComponent.applyFilter();
    }
  }

  getGroupedData(data) {
    const grouped_data = [];
    data.forEach(function (o) {
      const key = o.item.reference.toLowerCase();
      grouped_data[key] = grouped_data[key] || [];
      if (grouped_data[key].length === 0) {
        const variance = o.actual_quantity - o.expected_quantity;
        const row = {
          reference: o.item.reference,
          description: o.item.description,
          actual_qty: o.actual_quantity,
          expected_qty: o.expected_quantity,
          overage: variance > 0 ? variance : 0,
          shortage: variance < 0 ? variance : 0,
          stocking_price: parseFloat(o.stocking_price + '0')
        };
        grouped_data[key].push(row);
      } else {
        grouped_data[key][0].actual_qty += o.actual_quantity;
        grouped_data[key][0].expected_qty += o.expected_quantity;
        const variance = grouped_data[key][0].actual_qty - grouped_data[key][0].expected_qty;
        grouped_data[key][0].overage = variance > 0 ? variance : 0;
        grouped_data[key][0].shortage = variance < 0 ? variance : 0;
      }
    });
    return grouped_data;
  }
}
