import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';


export interface TableElement {
  status: string;
  qty: number;
}



@Component({
  selector: 'app-confirm-site-complete-dialog',
  templateUrl: './confirm-site-complete-dialog.component.html',
  styleUrls: ['./confirm-site-complete-dialog.component.css']
})

export class ConfirmSiteCompleteDialogComponent implements OnInit {
  keys: string[] = ['In-Progress', 'Counted'];
  title  = '';
  message = '';
  kittedNeedsVerificationCount = 0;
  nonKittedNeedsVerificationCount = 0;
  kittedInProgressCount = 0;
  nonKittedInProgressCount = 0;
  siteHasUnFinishedCountSheets = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmSiteCompleteDialogComponent>,
    private _router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.kittedNeedsVerificationCount = this.data.countSheets['Counted'] ? this.data.countSheets['Counted'].kittedQty : 0;
    this.kittedInProgressCount = this.data.countSheets['In-Progress'] ? this.data.countSheets['In-Progress'].kittedQty : 0;
    this.nonKittedNeedsVerificationCount = this.data.countSheets['Counted'] ? this.data.countSheets['Counted'].nonKittedQty : 0;
    this.nonKittedInProgressCount = this.data.countSheets['In-Progress'] ? this.data.countSheets['In-Progress'].nonKittedQty : 0;
    this.setTitle();
    this.setMessage();
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  goToCountSheetSummaryPage(status, showNonKittedTab) {
    this._router.navigate([this.data.auditId, 'countsheets', this.data.siteId], { queryParams: { siteID: this.data.siteId, status: status, orderType: 'all', selected_tab: showNonKittedTab ? 1 : 0, keepSameSite: true } });
    this.dialogRef.close();
  }

  setTitle() {
    if (this.kittedInProgressCount > 0 || this.nonKittedInProgressCount > 0 ) {
      this.title = 'Warning';
      this.siteHasUnFinishedCountSheets = true;
    } else {
      this.title = 'Please Confirm';
    }
  }

  setMessage() {
    if (!this.siteHasUnFinishedCountSheets) {
      this.message = '- Site contains un-verified count sheets, do you want to continue?';
    } else if (this.siteHasUnFinishedCountSheets && (this.kittedNeedsVerificationCount > 0 || this.nonKittedNeedsVerificationCount > 0)) {
      this.message = '- Site contains both un-verified count sheets and count sheets still in-progress, unable to complete site.';
    } else {
      this.message = '- Site contains count sheets still in-progress, unable to complete site.';
    }

  }
}
