import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { urls } from '../helpers/urls';
import { IcountMetadata } from '../interfaces/icount-metadata';
import { CountSheetDatabaseService } from '../services/count-sheet-database.service';
import { map } from 'rxjs/operators';
import { ICountSheetData } from '../interfaces/icount-sheet-data';

@Injectable({
  providedIn: 'root'
})
export class CountSheetService {

  constructor(private http: HttpClient, private _countSheetDatabaseService: CountSheetDatabaseService) { }

  getCountSheets(auditId, options?) {
    options = options || {};
    let params = new HttpParams().set('audit_id', auditId);
    if (options['site_id']) {
      params = params.set('site_id', options['site_id']);
    }
    if (options['only_kitted']) {
      params = params.set('only_kitted', options['only_kitted']);
    }
    if (options['only_unkitted']) {
      params = params.set('only_unkitted', options['only_unkitted']);
    }
    return this.http.get<any>(environment.serverPath + urls.countSheet, {params});
  }
  getCountSheet(countSheetId) {
    return this.http.get<any>(environment.serverPath + urls.countSheet + '/' + countSheetId);
  }

  getOfflineCountSheets(auditId) {
    return this._countSheetDatabaseService.find(auditId, {});
  }

  getCountSheetsAndPopulateOfflineData(auditId) {
    const that = this;
    let promise = new Promise((resolve, reject) => {
      that.getCountSheets(auditId).subscribe(data => {
        that._countSheetDatabaseService.deleteByIndex('data.audit_id', parseInt(auditId, 10)).then(function () {
          const css = data.map(cs => that._countSheetDatabaseService.new(cs));
          that._countSheetDatabaseService.bulkAdd(css).then(_ => {
            resolve(css);
          });
        });
      });
    });

    return promise;
  }

  updateCountSheet(countSheetId: number, params: any) {
    const formData = new FormData();
    //add other params as needed
    if (params['kit_id']) {
      formData.append('count_sheet[kit_id]', params['kit_id'].toString());
    }
    if (params.area) {
      formData.append('count_sheet[area]', params.area);
    }
    if (params.warehouse_id) {
      formData.append('count_sheet[warehouse_id]', params.warehouse_id);
    }
    if (params['show_expected_item_list']) {
      formData.append('count_sheet[show_expected_item_list]', params['show_expected_item_list'].toString());
    }

    return this.http.put<any>(`${environment.serverPath}${urls.countSheet}/${countSheetId}`, formData);
  }

  uncountAll(countSheetId: number) {
    const path = environment.serverPath + urls.countSheet + '/' + countSheetId + '/uncount_all';
    return this.http.post<any>(path, {});
  }

  bulkUpdate(countSheets, options) {
    const formData = new FormData();
    if (options['add_to_quantity']) {
      formData.append('add_to_quantity', options['add_to_quantity']);
    }
    countSheets.forEach((cs, countSheetIndex) => {
      Object.entries(cs).forEach((v: any[]) => {
        const key = v[0];
        const value = v[1];
        if (key === "attachments" || key === "count_sheet_items") {
          (value || []).forEach((nestedObject, nestedIndex) => {
            Object.entries(nestedObject).forEach((nestedV: any[]) => {
              const nestedKey = nestedV[0];
              const nestedValue = nestedV[1];
              if (nestedValue instanceof File) {
                formData.append('count_sheets[' + countSheetIndex + '][' + key + '][' + nestedIndex + '][' + nestedKey + ']', nestedValue);
              }
              else {
                formData.append('count_sheets[' + countSheetIndex + '][' + key + '][' + nestedIndex + '][' + nestedKey + ']', String(nestedValue));
              }
            })
          })
        } else {
          formData.append('count_sheets[' + countSheetIndex + '][' + key + ']', String(value));
        }


      })
    })

    return this.http.put<any>(`${environment.serverPath}${urls.countSheet}/bulk_update`, formData);
  }

  createKitCountSheet(newCountSheet: ICountSheetData) {
    const formData = new FormData();
    formData.append('count_sheet', JSON.stringify(newCountSheet));
    return this.http.post<any>(environment.serverPath + urls.countSheet, formData);
  }

  getRootKitDefinitionItems(countSheetId) {
    return this.http.get<any>(environment.serverPath + urls.countSheet + '/' + countSheetId + '/root_kit_definition_items');
  }

  updateCountSheetStatus(id: number, newStatus: number, statusMetadata?: IcountMetadata) {
    const formData = new FormData();
    formData.append('count_sheet', JSON.stringify({
      count_sheet_id: id,
      status: newStatus,
      metadata: statusMetadata
    }));

    const url = urls.updateCountSheetStatus;
    return this.http.post<any>(environment.serverPath + url, formData);
  }

  updateCountSheetStatuses(ids: number[], status: number, statusMetadata: IcountMetadata, siteId: number) {
    const formData = new FormData();
    formData.append('count_sheet', JSON.stringify({
      count_sheet_ids: ids,
      status: status,
      metadata: statusMetadata,
      site_id: siteId
    }));
    const url = environment.serverPath + urls.updateCountSheetStatuses;
    return this.http.post<any>(url, formData);
  }

  updateCountSheetWareHouse(countSheetId: number, warehouseId: number) {
    const formData = new FormData();
    formData.append('data', JSON.stringify({
      count_sheet_id: countSheetId,
      warehouse_id: warehouseId
    }));
    const path = environment.serverPath + urls.updateCountSheetWarehouse;
    return this.http.post<any>(path, formData);
  }

  deleteCountSheet(countSheet: ICountSheetData) {
    const params = new HttpParams()
      .set('count_sheet[id]', countSheet.dbId.toString());
    return this.http.delete(`${environment.serverPath}${urls.countSheet}`, { params });
  }
}
