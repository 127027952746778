import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { OfflineDataSynchronizerService } from 'src/app/services/offline-data-synchronizer.service';
import { MatDialog } from '@angular/material';
import { JsonDataViewerComponent } from '../json-data-viewer/json-data-viewer.component';
import { RetryFetchingAuditCountSheetDataComponent } from '../retry-fetching-audit-count-sheet-data/retry-fetching-audit-count-sheet-data.component';


@Component({
  selector: 'app-offline-data-synchronizer',
  templateUrl: './offline-data-synchronizer.component.html',
  styleUrls: ['./offline-data-synchronizer.component.css']
})
export class OfflineDataSynchronizerComponent implements OnInit, OnDestroy {

  loadingErrorSubscription: Subscription;
  savingErrorSubscription: Subscription;
  constructor(
    public dialog: MatDialog,
    private _synchronizer: OfflineDataSynchronizerService
  ) { }

  ngOnInit() {
    this.loadingErrorSubscription = this._synchronizer.loadingErrorEvent.subscribe((auditIds) => {
      this.dialog.open(RetryFetchingAuditCountSheetDataComponent, {
        width: '50%',
        disableClose: true,
        role: 'alertdialog',
        data: {
          auditIds: auditIds
        }
      });
    });
    this.savingErrorSubscription = this._synchronizer.savingErrorEvent.subscribe(data => {
      this.dialog.open(JsonDataViewerComponent, {
        width: '50%',
        disableClose: true,
        role: 'alertdialog',
        data: {
          message: 'Please send the following JSON to Redspot Support, then feel free to clear your cache and continue working.  Or click retry to send the data again but this time without detectable invalid data.',
          json: data
        },
      });
    });
  }
  ngOnDestroy(): void {
    if (this.loadingErrorSubscription) {
      this.loadingErrorSubscription.unsubscribe();
    }

    if (this.savingErrorSubscription) {
      this.savingErrorSubscription.unsubscribe();
    }
  }

}
