import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('auditTable') auditTable;
  constructor() { }

  ngOnInit() {
  }
  ngOnDestroy(): void {
    this.auditTable = null;
  }

  reloadTable() {
    this.auditTable.ngOnInit();
  }
}
