import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { urls } from '../helpers/urls';
import { EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LotPoolManagedItemService {
  updatedEmitter: EventEmitter<any>;

  constructor(private http: HttpClient) {
    this.updatedEmitter = new EventEmitter<any>();
  }

  getLotPoolManagedItems() {
    return this.http.get<any>(`${environment.serverPath}${urls.lotPoolManagedItems}`);
  }

  getLotPoolManagedItemCount() {
    return this.http.get<any>(`${environment.serverPath}${urls.lotPoolManagedItems}/count`);
  }

  import(formData: FormData) {
    return this.http.post<any>(`${environment.serverPath}${urls.lotPoolManagedItems}/import`, formData).pipe(map(res => {
      window.caches.keys().then(function(cacheNames) {
        const match = cacheNames.find(cn => cn.includes('LongTermCountSheets'));
        if (match) {
          caches.open(match).then(function (cache) {
            cache.delete(`${environment.serverPath}${urls.lotPoolManagedItems}`, {ignoreSearch: true});
          });
        }
      });
      this.updatedEmitter.emit();
      return res;
    }));
  }

  delete(id: number) {
    return this.http.delete<any>(`${environment.serverPath}${urls.lotPoolManagedItems}/${id}`).pipe(map(res => {
      window.caches.keys().then(function(cacheNames) {
        const match = cacheNames.find(cn => cn.includes('LongTermCountSheets'));
        if (match) {
          caches.open(match).then(function (cache) {
            cache.delete(`${environment.serverPath}${urls.lotPoolManagedItems}`, {ignoreSearch: true});
          });
        }
      });
      return res;
    }));
  }
}
