/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./offline-data-synchronizer.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./offline-data-synchronizer.component";
import * as i3 from "@angular/material/dialog";
import * as i4 from "../../services/offline-data-synchronizer.service";
var styles_OfflineDataSynchronizerComponent = [i0.styles];
var RenderType_OfflineDataSynchronizerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OfflineDataSynchronizerComponent, data: {} });
export { RenderType_OfflineDataSynchronizerComponent as RenderType_OfflineDataSynchronizerComponent };
export function View_OfflineDataSynchronizerComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_OfflineDataSynchronizerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-offline-data-synchronizer", [], null, null, null, View_OfflineDataSynchronizerComponent_0, RenderType_OfflineDataSynchronizerComponent)), i1.ɵdid(1, 245760, null, 0, i2.OfflineDataSynchronizerComponent, [i3.MatDialog, i4.OfflineDataSynchronizerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OfflineDataSynchronizerComponentNgFactory = i1.ɵccf("app-offline-data-synchronizer", i2.OfflineDataSynchronizerComponent, View_OfflineDataSynchronizerComponent_Host_0, {}, {}, []);
export { OfflineDataSynchronizerComponentNgFactory as OfflineDataSynchronizerComponentNgFactory };
