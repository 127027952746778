<div class="mat-elevation-z8 data-table">
  <div class="row">
    <div class="col-md-12">

    </div>
  </div>
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="reference">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Reference</th>
      <td mat-cell *matCellDef="let row">{{row.item.reference}}</td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Description</th>
      <td mat-cell *matCellDef="let row">{{row.item.description}}</td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header"></th>
      <td mat-cell *matCellDef="let row">
        <button [ngClass]="{'disabled': !isOnline }" mat-icon-button (click)="deleteLotPoolManagedItem(row.id)" *ngxPermissionsOnly="['audit_administrator']">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator #paginator [pageSizeOptions]="[50, 1000]"></mat-paginator>
</div>
