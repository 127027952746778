import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatSnackBar } from '@angular/material';
import { UnkittedCountsheetDataSource } from './unkitted-countsheet-datasource';
import { CreateUnkittedCountSheetComponent } from '../create-unkitted-count-sheet/create-unkitted-count-sheet.component';
import { Router, ActivatedRoute } from '@angular/router';
import { CountSheetService } from '../../services/count-sheet.service';
import { AuditService } from '../../services/audit.service';
import { ISnackBarConfig } from '../../interfaces/isnack-bar-config';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { ICountSheetData } from '../../interfaces/icount-sheet-data';
import { OnlineService } from './../../services/online.service';
import { Observer, Subscription, of, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { CountSheetHelper } from './../../helpers/countsheet-helper';
import { IcountMetadata } from '../../interfaces/icount-metadata';
import { UserDataHelper } from '../../helpers/user-data-helper';
import { IuserData } from '../../interfaces/iuser-data';
import * as moment from 'moment';
import { ITableFilter } from '../../interfaces/itable-filter';
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';
import { CountSheetItemHelper } from 'src/app/helpers/countsheet-item-helper';
import { IPrintOptions } from 'src/app/interfaces/i-print-options';
import { PrintHelper } from '../../helpers/print-helper';
import { CountSheetItemService } from '../../services/count-sheet-item.service';
import { ItemSettingService } from 'src/app/services/item-setting.service';
import { ItemCombinationService } from 'src/app/services/item-combination.service';
import { KitDefinitionItemService } from 'src/app/services/kit-definition-item.service';
import { ConsignmentInventoryImportService } from 'src/app/services/consignment-inventory-import.service';
import { ExclusionService } from 'src/app/services/exclusion.service';
import { RedspotInventoryImportService } from 'src/app/services/redspot-inventory-import.service';
import { LotPoolManagedItemService } from '../../services/lot-pool-managed-item.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { DatabaseService } from 'src/app/services/database.service';
import { CountSheetItemDatabaseService } from 'src/app/services/count-sheet-item-database.service';
import { CountSheetDatabaseService } from 'src/app/services/count-sheet-database.service';
import { AuditStatus } from 'src/app/helpers/audit-status';
import { RecallOracleService } from 'src/app/services/recall-oracle.service';


@Component({
  selector: 'app-unkitted-countsheet',
  templateUrl: './unkitted-countsheet.component.html',
  styleUrls: ['./unkitted-countsheet.component.css']
})
export class UnkittedCountsheetComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  _dataSource: UnkittedCountsheetDataSource;
  anyCountSheetsCounted = false;

  public set dataSource(newDataSource) {
    this.anyCountSheetsCounted = !!newDataSource.filteredData().find(cs => cs.data.count_sheet_status === 3);
    this._dataSource = newDataSource;
  }

  public get dataSource() {
    return this._dataSource;
  }

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['verify', 'area', 'site', 'warehouse', 'count', 'status', 'createdBy', 'dateCreated', 'addUnkitted'];

  @ViewChild(SnackbarComponent) snack: SnackbarComponent;

  @Input() siteId: number;
  @Input() audit: any;
  @Input() warehouseID: string;
  @Input() filters: ITableFilter;
  @Input() passedUnkittedCountSheets: ICountSheetData[];
  @Input() location: any;
  @Input() warehouses: any[];
  auditId: number;
  unkittedCountSheets: ICountSheetData[];
  permissions: string[];
  userData: IuserData;
  rowToCount: any;

  viewOnly = false;
  status: string;
  loading = false;
  snackBarConfig: ISnackBarConfig = {
    message: '',
    duration: 3000,
    success: false,
    snackBarClass: ''
  };

  itemCombinations: any;
  lotPoolManagedItems: any;
  itemSettings: any;
  redspotQuantities: any;
  kitDefinitionItems: any;
  sapQuantities: any;
  exclusions: any;
  verifyAllChecked = false;

  // helpers
  countSheetHelper: CountSheetHelper = new CountSheetHelper();
  userDataHelper: UserDataHelper = new UserDataHelper();
  countSheetItemHelper: CountSheetItemHelper = new CountSheetItemHelper();
  printHelper: PrintHelper = new PrintHelper();

  // observers
  startCountObserver: Observer<any> = {
    next: data => {
      this._router.navigate([this.audit.id, 'non_kitted_count_sheet', this.rowToCount.id, this.viewOnly], { queryParams: { status: this.status } });
    },
    error: err => {
      this.onlineSubscription = this._onlineService.isOnlineSubscription().subscribe(online => {
        if (!online) {
          const countSheetStatus = 2;
          this.rowToCount.isSynchronized = 0;
          this.rowToCount._destroy = false;
          this.rowToCount.data.count_sheet_status = countSheetStatus;
          this.rowToCount.data.status_text = this.countSheetHelper.getCountSheetStatusText(countSheetStatus);
          this._countSheetDatabaseService.update(this.rowToCount).then(_ => {
            this._router.navigate([this.auditId, 'non_kitted_count_sheet', this.rowToCount.id, this.viewOnly], { queryParams: { status: this.status } });
          });
        }
      });

      this.onlineSubscription.unsubscribe();

    },
    complete: () => {

    }
  };

  // subscription
  onlineSubscription: Subscription;

  constructor(
    public dialog: MatDialog,
    private _countSheetService: CountSheetService,
    private _router: Router,
    private _onlineService: OnlineService,
    private _route: ActivatedRoute,
    public snackBar: MatSnackBar,
    private _auditService: AuditService,
    private _countSheetItemService: CountSheetItemService,
    private itemSettingService: ItemSettingService,
    private itemCombinationService: ItemCombinationService,
    private kitDefinitionItemService: KitDefinitionItemService,
    private consignmentInventoryImportService: ConsignmentInventoryImportService,
    private exclusionService: ExclusionService,
    private redspotInventoryImportService: RedspotInventoryImportService,
    private _lotPoolManagedItemService: LotPoolManagedItemService,
    private _databaseService: DatabaseService,
    private _countSheetItemDatabaseService: CountSheetItemDatabaseService,
    private _countSheetDatabaseService: CountSheetDatabaseService,
    private _recallOracleService: RecallOracleService
  ) { }

  ngOnDestroy(): void {

    if (this.onlineSubscription) {
      this.onlineSubscription.unsubscribe();
    }
    this.snack = null;
  }

  ngOnInit() {
    this.auditId = +this._route.snapshot.paramMap.get('audit_id');
    this.userData = this.userDataHelper.getUserData();
    this.permissions = JSON.parse(localStorage.getItem('permissions'));
    this.status = this.filters['status'];

    if (this.audit.status === AuditStatus.COMPLETED) {
      this.viewOnly = true;
    } else {
      if (this.siteId === -1) {
        this.viewOnly = true;
      } else {
        this.viewOnly = this._route.snapshot.paramMap.get('isViewOnly') === 'true';
        if (this.permissions[0] === 'auditor' || this.permissions[0] === 'audit_administrator') {
          this.viewOnly = false;
        } else if (this.permissions[0] === 'audit_viewer') {
          this.viewOnly = true;
        }
      }
    }
    this.getUnkittedCountSheets();
  }

  getUnkittedCountSheets() {
    if (this.siteId !== -1) {
      this.unkittedCountSheets = this.passedUnkittedCountSheets.filter(puc => {
        return puc.data.audit_location_id === +this.siteId;
      });
    } else {
      this.unkittedCountSheets = this.passedUnkittedCountSheets;
    }
    this.dataSource = new UnkittedCountsheetDataSource(this.paginator, this.sort, this.unkittedCountSheets, this.filters);
    this.loading = false;
  }

  addUnkittedCountSheet() {
    const dialogRef = this.dialog.open(CreateUnkittedCountSheetComponent, {
      width: '50%',
      data: {
        audit_location_id: this.siteId,
        audit_id: this.auditId,
        warehouses: this.warehouses,
        location_name: this.location.location_name,
        title: 'Create Unkitted Countsheet',
        creatingCountsheet: true
      }
    });

    dialogRef.componentInstance.unkittedCountSheetAddedEvent.subscribe((data: ICountSheetData) => {
      this._databaseService.add('user_actions', {
        audit_id: this.auditId.toString(),
        auditor: `${this.userData.first_name} ${this.userData.last_name}`,
        timestamp: `${(new Date()).toUTCString()}`,
        area: `${data.data.area}`,
        kit_reference: '',
        kit_lot_number: '',
        action: 'Created count sheet',
        total_items_counted: data.data.total_items_counted,
        site_id: this.siteId,
        warehouse_id: data.data.warehouse_id
      });
      this.startCounting(data);
    });
  }

  editUnkittedCountSheet(countsheet: ICountSheetData) {
    const dialogRef = this.dialog.open(CreateUnkittedCountSheetComponent, {
      width: '50%',
      data: {
        audit_location_id: this.siteId,
        audit_id: this.auditId,
        warehouses: this.warehouses,
        location_name: this.location.location_name,
        title: 'Edit Unkitted Countsheet',
        creatingCountsheet: false,
        countsheet: countsheet
      }
    });

    dialogRef.componentInstance.unkittedCountSheetEditedEvent.subscribe((data: ICountSheetData) => {
      zip(
        this._countSheetDatabaseService.find(this.auditId, {audit_location_id: this.siteId, null_audit_kit_instance_id: true, archived: false})
      ).subscribe((data: any[]) => {
        this.unkittedCountSheets = data[0];
        this.dataSource = new UnkittedCountsheetDataSource(this.paginator, this.sort, this.unkittedCountSheets, this.filters);
      });
    });
  }

  startCounting(row: ICountSheetData) {
    this.rowToCount = row;
    const countSheetStatus = 2;
    this.onlineSubscription = this._onlineService.isOnlineSubscription().subscribe(online => {
      if (online && !this._onlineService.getTestOfflineBool()) {
        this._countSheetService.updateCountSheetStatus(row.dbId, 2).subscribe(this.startCountObserver);

      } else {
        this.rowToCount.isSynchronized = 0;
        this.rowToCount._destroy = false;
        this.rowToCount.data.count_sheet_status = countSheetStatus;
        this.rowToCount.data.status_text = this.countSheetHelper.getCountSheetStatusText(countSheetStatus);
        this._countSheetDatabaseService.update(this.rowToCount).then(_ => {
          this._router.navigate([this.auditId, 'non_kitted_count_sheet', this.rowToCount.id, this.viewOnly], { queryParams: { status: this.status } });
        });
      }
    });

    this.onlineSubscription.unsubscribe();
  }



  continueCounting(row: ICountSheetData) {
    this.rowToCount = row;
    this._router.navigate([this.audit.id, 'non_kitted_count_sheet', this.rowToCount.id, this.viewOnly], { queryParams: { status: this.status } });

  }

  goToCompletedCountSheet(row: ICountSheetData) {
    this.rowToCount = row;

    this._router.navigate([this.audit.id, 'non_kitted_count_sheet', this.rowToCount.id, this.viewOnly], { queryParams: { status: this.status } });

  }

  goToVerifiedCountSheet(row: ICountSheetData) {
    this.rowToCount = row;

    this._router.navigate([this.audit.id, 'non_kitted_count_sheet', this.rowToCount.id, this.viewOnly], { queryParams: { status: this.status } });

  }

  checkedClicked(row: ICountSheetData) {
    const verifiedStatus = 4;
    const verifiedMetadata: IcountMetadata = {
      verified_by_id: this.userData.user_id,
      verified_time: moment().toDate()
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '30%',
      data: `Verify countsheet for Area: ${row.data.area}?`,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._databaseService.add('user_actions', {
          audit_id: this.audit.id.toString(),
          auditor: `${this.userData.first_name} ${this.userData.last_name}`,
          timestamp: `${(new Date()).toUTCString()}`,
          area: `${row.data.area}`,
          kit_reference: '',
          kit_lot_number: '',
          action: 'Verified count sheet',
          total_items_counted: row.data.total_items_counted,
          site_id: this.siteId,
          warehouse_id: row.data.warehouse_id
        });
        this.onlineSubscription = this._onlineService.isOnlineSubscription().subscribe(online => {
          if (online && !this._onlineService.getTestOfflineBool()) {

            this._countSheetService.updateCountSheetStatus(row.dbId, verifiedStatus, verifiedMetadata).subscribe(res => {
              this._countSheetDatabaseService.update(this._countSheetDatabaseService.new(res.count_sheet)).then(_ => {
                zip(
                  this._countSheetDatabaseService.find(this.auditId, {audit_location_id: this.siteId, null_audit_kit_instance_id: true, archived: false})
                ).subscribe((data: any[]) => {
                  this.unkittedCountSheets = data[0];
                  this.dataSource = new UnkittedCountsheetDataSource(this.paginator, this.sort, this.unkittedCountSheets, this.filters);
                });
              });
            });

          } else {
            row.isSynchronized = 0;
            row.data.count_sheet_status = verifiedStatus;
            row.data.status_text = this.countSheetHelper.getCountSheetStatusText(verifiedStatus);
            row.data.verified_by = this.userData;
            row.data.verified_time = moment().format('lll');
            this._countSheetDatabaseService.update(row).then(_ => {
              zip(
                this._countSheetDatabaseService.find(this.auditId, {audit_location_id: this.siteId, null_audit_kit_instance_id: true, archived: false})
              ).subscribe((data: any[]) => {
                this.unkittedCountSheets = data[0];
                this.dataSource = new UnkittedCountsheetDataSource(this.paginator, this.sort, this.unkittedCountSheets, this.filters);
              });
            });
          }
        });
        this.onlineSubscription.unsubscribe();

      } else {
        row.data.verify_display = false;
      }
    });
  }

  applyFilter() {
    this.dataSource = new UnkittedCountsheetDataSource(this.paginator, this.sort, this.unkittedCountSheets, this.filters);
  }

  checkAllClicked(event) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '30%',
      data: 'Verify all unkitted countsheets?',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const verifiedStatus = 4;
        const verifiedMetadata: IcountMetadata = {
          verified_by_id: this.userData.user_id,
          verified_time: moment().toDate()
        };
        const updatingCountSheets = this.dataSource.filteredData().filter(cs => cs.data.count_sheet_status === 3);
        this.onlineSubscription = this._onlineService.isOnlineSubscription().subscribe(online => {
          if (online && !this._onlineService.getTestOfflineBool()) {
            this._countSheetService.updateCountSheetStatuses(
              updatingCountSheets.map(cs => cs.dbId),
              verifiedStatus,
              verifiedMetadata,
              this.siteId
            ).subscribe(data => {
              updatingCountSheets.forEach(cs => {
                this._databaseService.add('user_actions', {
                  audit_id: this.audit.id.toString(),
                  auditor: `${this.userData.first_name} ${this.userData.last_name}`,
                  timestamp: `${(new Date()).toUTCString()}`,
                  area: `${cs.data.area}`,
                  kit_reference: '',
                  kit_lot_number: '',
                  action: 'Verified count sheet',
                  total_items_counted: cs.data.total_items_counted,
                  site_id: cs.siteId,
                  warehouse_id: cs.data.warehouse_id
                });
              });

              //update offline data
              const promises = data.count_sheets.map((cs) => {
                return this._countSheetDatabaseService.update(this._countSheetDatabaseService.new(cs));
              }).concat(new Promise((r, a) => { r(true); }));
              zip(...promises).subscribe(_ => {
                zip(
                  this._countSheetDatabaseService.find(this.auditId, {audit_location_id: this.siteId, null_audit_kit_instance_id: true, archived: false})
                ).subscribe((data: any[]) => {
                  this.unkittedCountSheets = data[0];
                  this.dataSource = new UnkittedCountsheetDataSource(this.paginator, this.sort, this.unkittedCountSheets, this.filters);
                });
              })
            });
          } else {
            const promises = updatingCountSheets.map(countSheet => {
              this._databaseService.add('user_actions', {
                audit_id: this.audit.id.toString(),
                auditor: `${this.userData.first_name} ${this.userData.last_name}`,
                timestamp: `${(new Date()).toUTCString()}`,
                area: `${countSheet.data.area}`,
                kit_reference: '',
                kit_lot_number: '',
                action: 'Verified count sheet',
                total_items_counted: countSheet.data.total_items_counted,
                site_id: countSheet.siteId,
                warehouse_id: countSheet.data.warehouse_id
              });
              countSheet.isSynchronized = 0;
              countSheet.data.count_sheet_status = verifiedStatus;
              countSheet.data.verified_by = this.userData;
              countSheet.data.verified_time = moment().format('lll');
              return this._countSheetDatabaseService.update(countSheet);
            });
            zip(
              ...promises.concat(new Promise((r, a) => { r(true); }))
            ).subscribe(_ => {
              zip(
                this._countSheetDatabaseService.find(this.auditId, {audit_location_id: this.siteId, null_audit_kit_instance_id: true, archived: false})
              ).subscribe((data: any[]) => {
                this.unkittedCountSheets = data[0];
                this.dataSource = new UnkittedCountsheetDataSource(this.paginator, this.sort, this.unkittedCountSheets, this.filters);
              });
            })
          }
        });
        this.onlineSubscription.unsubscribe();
      } else {
        this.verifyAllChecked = false;
      }
    });
  }

  openSnackBar() {
    this.snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: this.snackBarConfig,
      duration: this.snackBarConfig.duration
    });
  }

  refreshDatasource() {
    this.dataSource = new UnkittedCountsheetDataSource(this.paginator, this.sort, this.unkittedCountSheets, this.filters);
  }

  getLotPoolManagedItems() {
    if (this.lotPoolManagedItems) {
      return of(this.lotPoolManagedItems);
    }
    return this._lotPoolManagedItemService.getLotPoolManagedItems();
  }

  getCountSheetItems(online: boolean, auditId: number, countSheet: ICountSheetData) {
    const that = this;
    return new Promise((resolve, reject) => {
      if (online && !that._onlineService.getTestOfflineBool() && countSheet.isSynchronized) {
        return that._countSheetItemService.getCountSheetItems(auditId, {count_sheet_client_id: countSheet.id}).subscribe(function (data) {
          that._countSheetItemService.refreshOfflineDataForCountSheet(auditId, countSheet.id, data).then((matches: any[]) => {
            resolve(matches);
          });
        });
      } else {
        that._countSheetItemDatabaseService.find(auditId, {count_sheet_client_id: countSheet.id}).then((matches: any[]) => {
          resolve(matches);
        });
      }
    });
  }

  getExclusions(auditId) {
    if (this.exclusions) {
      return of(this.exclusions);
    }
    return this.exclusionService.getExclusions(auditId);
  }

  getRedspotQuantities(auditId, warehouseId) {
    return this.redspotInventoryImportService.getInventory(auditId).pipe(map(data => data.filter(rq => rq.warehouse_id === warehouseId && !rq.kit_instance_id)));
  }

  getSapQuantities(auditId) {
    if (this.sapQuantities) {
      return of(this.sapQuantities);
    }
    return this.consignmentInventoryImportService.getInventory(auditId, true);
  }

  getItemCombinations() {
    if (this.itemCombinations) {
      return of(this.itemCombinations);
    }
    return this.itemCombinationService.get();
  }

  getItemSettingsByAudit(auditId: number) {
    if (this.itemSettings) {
      return of(this.itemSettings);
    }
    return this.itemSettingService.getItemSettingsByAudit(auditId);
  }

  getRootKitDefinitionItems(kitId) {
    return this.kitDefinitionItemService.getKitDefinitionItems().pipe(map(data => {
      return data.filter(kdi => kdi.kit_definition_id === kitId);
    }));
  }

  printCountSheet(row: ICountSheetData) {
    this.filters['exclusions'] = 'hide';
    zip(
      this.getRedspotQuantities(row.data.audit_id, row.data.warehouse_id),
      this.getRootKitDefinitionItems(row.data.kit_id),
      this.getLotPoolManagedItems(),
      this.getItemSettingsByAudit(this.auditId),
      this.getItemCombinations(),
      this.getSapQuantities(this.auditId),
      this.getExclusions(this.auditId)
    ).subscribe(data => {
      this.redspotQuantities = data[0];
      this.kitDefinitionItems = data[1];
      this.lotPoolManagedItems = data[2];
      this.itemSettings = data[3];
      this.itemCombinations = data[4];
      this.sapQuantities = data[5];
      this.exclusions = data[6];
      this._databaseService.add('user_actions', {
        audit_id: this.audit.id.toString(),
        auditor: `${this.userData.first_name} ${this.userData.last_name}`,
        timestamp: `${(new Date()).toUTCString()}`,
        area: `${row.data.area}`,
        kit_reference: '',
        kit_lot_number: '',
        action: 'Printed count sheet',
        total_items_counted: row.data.total_items_counted,
        site_id: this.siteId,
        warehouse_id: row.data.warehouse_id
      });
      this._recallOracleService.prime(this.audit.id, {itemCombinations: this.itemCombinations}).then(_ => {
        this.onlineSubscription = this._onlineService.isOnlineSubscription().subscribe(online => {
          if (online && !this._onlineService.getTestOfflineBool()) {
            zip(this.getCountSheetItems(true, this.auditId, row)).subscribe((countsheetItemsData: any[]) => {
              const csis = countsheetItemsData[0];
              const countsheetItems = this.countSheetHelper.getDataSourceData(row, csis, this.lotPoolManagedItems, this.redspotQuantities, this.sapQuantities, this.itemSettings, this.itemCombinations, this._recallOracleService, this.kitDefinitionItems, this.exclusions, this.filters);
              const printOptions: IPrintOptions = {
                isKitted: false,
                warehouseName: row.data.warehouse_name,
                locationName: this.location.location_name,
                lotNumber: row.data.lot_number,
                statusText: row.data.status_text,
                agencyName: this.audit.agency_name,
                reference: row.data.reference,
                area: row.data.area,
                countSheet: row,
                redspotQuantities: this.redspotQuantities,
                lotPoolManagedItems: this.lotPoolManagedItems,
                kitDefinitionItems: this.kitDefinitionItems,
                filters: this.filters,
                itemSettings: this.itemSettings,
                exclusions: this.exclusions
              };
              this.printHelper.print(countsheetItems, printOptions);
            });
          } else {
            zip(this.getCountSheetItems(false, this.auditId, row)).subscribe((countsheetItemsData: any[]) => {
              const csis = countsheetItemsData[0];
              const countsheetItems = this.countSheetHelper.getDataSourceData(row, csis, this.lotPoolManagedItems, this.redspotQuantities, this.sapQuantities, this.itemSettings, this.itemCombinations, this._recallOracleService, this.kitDefinitionItems, this.exclusions, this.filters);
              const printOptions: IPrintOptions = {
                isKitted: false,
                warehouseName: row.data.warehouse_name,
                locationName: this.location.location_name,
                lotNumber: row.data.lot_number,
                statusText: row.data.status_text,
                agencyName: this.audit.agency_name,
                reference: row.data.reference,
                area: row.data.area,
                countSheet: row,
                redspotQuantities: this.redspotQuantities,
                lotPoolManagedItems: this.lotPoolManagedItems,
                kitDefinitionItems: this.kitDefinitionItems,
                filters: this.filters,
                itemSettings: this.itemSettings,
                exclusions: this.exclusions
              };
              this.printHelper.print(countsheetItems, printOptions);
            });
          }
        });
  
        this.onlineSubscription.unsubscribe();
      });
    });
  }
}
