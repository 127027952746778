var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var AuditDashboardHelper = /** @class */ (function () {
    function AuditDashboardHelper() {
    }
    AuditDashboardHelper.prototype.parseAgencyDashboardData = function (data) {
        var agencyData = {
            siteStats: __assign({}, data["site"], { "fraction": data["site"].planned === 0 ? 0 : Math.round((data["site"].complete / data["site"].planned) * 100) }),
            kitStats: __assign({}, data["kit"], { "fraction": data["kit"].planned === 0 ? 0 : Math.round((data["kit"].verified / data["kit"].planned) * 100) }),
            warehouseStats: __assign({}, data["warehouse"], { "fraction": data["warehouse"].planned === 0 ? 0 : Math.round((data["warehouse"].complete / data["warehouse"].planned) * 100) }),
            itemStats: __assign({}, data["item"], { "fraction": Math.round((data["item"].complete / data["item"].planned) * 100) })
        };
        return agencyData;
    };
    AuditDashboardHelper.prototype.saveAgencyDashbordDataLocally = function (agencyDashBoardData) {
        localStorage.setItem('agency_stats', JSON.stringify(agencyDashBoardData));
    };
    return AuditDashboardHelper;
}());
export { AuditDashboardHelper };
