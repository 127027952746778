<div class="import-comp">
  <h5 class="header-title">Count Sheet Item Import
    <button class="pull-right" mat-icon-button color="primary" placement="auto" ngbTooltip="Pick File" (click)="fileInput.click()">
      <mat-icon>cloud_upload</mat-icon>
    </button>
  </h5>

  <div class="row label">
    <input #fileInput class="hidden-input" type="file" (change)="onFileSelected($event)" name="file">
  </div>

  <div class="row">
    <div class="col-6 half-max-witdh" *ngIf="selectedFile">
      <label mat-button [title]="selectedFile.name" for="">{{selectedFile.name}}</label>
    </div>
    <div class="float_right" *ngIf="selectedFile">
      <button class="pull-right" mat-icon-button color="accent" (click)="onUpload()" ngbTooltip="Send File" placement="auto" [disabled]="saving">
        <i class="material-icons">send</i>
      </button>
    </div>
  </div>
  <div>
      <div mat-dialog-actions class="pull-right">
        <button ngbTooltip="Download Template" placement="top" mat-icon-button (click)="templateFrontEnd()">
          <mat-icon>cloud_download</mat-icon>
        </button>
      </div>
    </div>
  <div mat-dialog-actions class="pull-right">
      <button mat-button (click)="onNoClick()">Cancel</button>
    </div>
</div>
