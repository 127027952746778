import { Component, OnInit, Inject } from '@angular/core';
import { ISnackBarConfig } from 'src/app/interfaces/isnack-bar-config';
import {MAT_SNACK_BAR_DATA} from '@angular/material';

@Component({
  selector: 'app-material-snackbar',
  templateUrl: './material-snackbar.component.html',
  styleUrls: ['./material-snackbar.component.css']
})
export class MaterialSnackbarComponent implements OnInit {
  config: ISnackBarConfig;
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: ISnackBarConfig) { }
  
  
  ngOnInit() {
    this.config = this.data;
  }
}
