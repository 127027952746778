var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { UnkittedItemsCountsheetDataSource } from './unkitted-items-countsheet-datasource';
import { ActivatedRoute, Router } from '@angular/router';
import { CountSheetService } from '../../../services/count-sheet.service';
import { CountSheetItemService } from '../../../services/count-sheet-item.service';
import { RedspotInventoryImportService } from '../../../services/redspot-inventory-import.service';
import { ConsignmentInventoryImportService } from '../../../services/consignment-inventory-import.service';
import { of, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { OnlineService } from './../../../services/online.service';
import { CountSheetItemHelper } from '../../../helpers/countsheet-item-helper';
import { ItemCombinationService } from '../../../services/item-combination.service';
import { ItemSettingService } from '../../../services/item-setting.service';
import { UserDataHelper } from '../../../helpers/user-data-helper';
import * as moment from 'moment';
import { ExclusionService } from '../../../services/exclusion.service';
import { PrintPageComponent } from '../../print-page/print-page.component';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { ItemService } from 'src/app/services/item.service';
import { MaterialSnackbarComponent } from '../../material-snackbar/material-snackbar.component';
import { HistoryServiceService } from 'src/app/services/history-service.service';
import { OfflineDataSynchronizerService } from 'src/app/services/offline-data-synchronizer.service';
import { WarningDialogComponent } from '../../warning-dialog/warning-dialog.component';
import { KitService } from '../../../services/kit.service';
import { KitDefinitionItemService } from '../../../services/kit-definition-item.service';
import { TrackLoadingService } from '../../../services/track-loading.service';
import { ImportCountSheetItemComponent } from '../../import-count-sheet-item/import-count-sheet-item.component';
import { CreateUnkittedCountSheetComponent } from '../../create-unkitted-count-sheet/create-unkitted-count-sheet.component';
import { AuditService } from 'src/app/services/audit.service';
import { PrintHelper } from '../../../helpers/print-helper';
import { PositiveIntegerHelper } from '../../../helpers/positive-integer-helper';
import { LotPoolManagedItemService } from '../../../services/lot-pool-managed-item.service';
import { CountSheetExportHelper } from 'src/app/helpers/countsheet-export-helper';
import { ExportToExcelService } from 'src/app/services/export-to-excel.service';
import { AudioPlayerService } from 'src/app/services/audio-player.service';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';
import { DatabaseService } from '../../../services/database.service';
import { CountSheetItemDatabaseService } from '../../../services/count-sheet-item-database.service';
import { CountSheetDatabaseService } from '../../../services/count-sheet-database.service';
import { AuditDatabaseService } from '../../../services/audit-database.service';
import { AttachmentDialogComponent } from '../../attachment-dialog/attachment-dialog.component';
import { Messages } from 'src/app/helpers/messages';
import { CameraService } from 'src/app/services/camera.service';
import { RecallOracleService } from 'src/app/services/recall-oracle.service';
var UnkittedItemsCountsheetComponent = /** @class */ (function () {
    function UnkittedItemsCountsheetComponent(_route, _historyService, _router, _dialog, _auditService, _countSheetService, _countSheetItemService, _redspotInventoryImportService, _consignmentInventoryImportService, _onlineService, _itemCombinationService, _itemSettingService, _exclusionService, _itemService, _snackBar, _synchronizer, _kitService, _kitDefinitionItemService, _trackLoadingService, _lotPoolManagedItemService, _excelService, _audioPlayerService, _databaseService, _countSheetItemDatabaseService, _countSheetDatabaseService, _auditDatabaseService, _cameraService, _recallOracleService) {
        var _this = this;
        this._route = _route;
        this._historyService = _historyService;
        this._router = _router;
        this._dialog = _dialog;
        this._auditService = _auditService;
        this._countSheetService = _countSheetService;
        this._countSheetItemService = _countSheetItemService;
        this._redspotInventoryImportService = _redspotInventoryImportService;
        this._consignmentInventoryImportService = _consignmentInventoryImportService;
        this._onlineService = _onlineService;
        this._itemCombinationService = _itemCombinationService;
        this._itemSettingService = _itemSettingService;
        this._exclusionService = _exclusionService;
        this._itemService = _itemService;
        this._snackBar = _snackBar;
        this._synchronizer = _synchronizer;
        this._kitService = _kitService;
        this._kitDefinitionItemService = _kitDefinitionItemService;
        this._trackLoadingService = _trackLoadingService;
        this._lotPoolManagedItemService = _lotPoolManagedItemService;
        this._excelService = _excelService;
        this._audioPlayerService = _audioPlayerService;
        this._databaseService = _databaseService;
        this._countSheetItemDatabaseService = _countSheetItemDatabaseService;
        this._countSheetDatabaseService = _countSheetDatabaseService;
        this._auditDatabaseService = _auditDatabaseService;
        this._cameraService = _cameraService;
        this._recallOracleService = _recallOracleService;
        this.snackBarConfig = {
            message: '',
            duration: 3000,
            success: false,
            snackBarClass: ''
        };
        this.justAddedReference = '';
        this.justAddedCount = 0;
        this.kitted = false;
        this.viewOnly = false;
        this.actualItemCount = 0;
        // helpers
        this.countSheetItemHelper = new CountSheetItemHelper();
        this.userDataHelper = new UserDataHelper();
        this.printHelper = new PrintHelper();
        this.positiveIntegerHelper = new PositiveIntegerHelper();
        this.dataSource = new UnkittedItemsCountsheetDataSource(this.countSheetItemHelper, this._countSheetItemDatabaseService);
        this._itemService.getItems().subscribe(function (data) {
            _this.items = data;
            _this.countSheetItemHelper.cacheItems(_this.items);
        });
        this._cameraService.getDevices(function (devices) {
            _this.cameras = devices;
        });
    }
    UnkittedItemsCountsheetComponent.prototype.ngOnInit = function () {
        var _this = this;
        var that = this;
        this.auditDbId = this._route.snapshot.paramMap.get('audit_id');
        this.previousUrl = this._historyService.findLastCountSheetSummaryLink();
        this.userData = this.userDataHelper.getUserData();
        this.viewOnly = this._route.snapshot.paramMap.get('isViewOnly') === 'true';
        this.status = 'All';
        this.permissions = JSON.parse(localStorage.getItem('permissions'));
        this._route.queryParams.subscribe(function (params) {
            if (params.status) {
                _this.status = params.status;
            }
        });
        this.reloadSubscription = this._synchronizer.allSynchronizedEvent.subscribe(function () {
            _this.reloadWhenBackOnline();
        });
        //keep this.online up to date
        this.isOnline = this._onlineService.isOnline() && !this._onlineService.testingOffline;
        this.isOnlineSubscription = this._onlineService.isOnlineSubscription().subscribe(function (online) {
            _this.isOnline = online && !_this._onlineService.getTestOfflineBool();
        });
        this.isOfflineSubscription = this._onlineService.goOfflineEvent.subscribe(function (data) {
            _this.isOnline = !data;
        });
        //end of keep this.online up to date
        this._synchronizer.needsSyncing().then(function (hasUnsynced) {
            var wouldSync = that._onlineService.isOnline() && !that._onlineService.testingOffline;
            var currentlySyncing = !that._synchronizer.doneProcessing;
            if (wouldSync && (hasUnsynced || currentlySyncing)) {
                return;
            }
            _this.getKits();
            _this._trackLoadingService.startLoading('unkitted-count-sheet-items', 'Loading Items');
            _this._trackLoadingService.startLoading('unkitted-count-sheet-lot-pool-managed-items', 'Loading Lot Pool Managed Items');
            zip(_this._lotPoolManagedItemService.getLotPoolManagedItems(), of(_this._onlineService.isOnline()), _this._auditDatabaseService.get(_this.auditDbId)).subscribe(function (data) {
                _this._trackLoadingService.stopLoading('unkitted-count-sheet-items');
                _this._trackLoadingService.stopLoading('unkitted-count-sheet-lot-pool-managed-items');
                _this.lotPoolManagedItems = data[0];
                var online = data[1];
                _this.audit = data[2];
                _this.dataSource.lotPoolManagedItems = _this.lotPoolManagedItems;
                _this._trackLoadingService.startLoading('unkitted-count-sheet-item-count-sheet', 'Loading Count Sheet');
                _this.getCountSheet(online, _this._route.snapshot.paramMap.get('id')).then(function (countsheetData) {
                    _this._trackLoadingService.stopLoading('unkitted-count-sheet-item-count-sheet');
                    _this.countSheet = countsheetData;
                    _this.dataSource.countSheet = _this.countSheet;
                    _this._trackLoadingService.startLoading('unkitted-count-sheet-item-redspot-quantities', 'Loading Redspot Inventory');
                    _this._trackLoadingService.startLoading('unkitted-count-sheet-item-sap-quantities', 'Loading SAP Inventory');
                    _this._trackLoadingService.startLoading('unkitted-count-sheet-item-item-settings', 'Loading Item Settings');
                    _this._trackLoadingService.startLoading('unkitted-count-sheet-item-exclusions', 'Loading Exclusions');
                    _this._trackLoadingService.startLoading('unkitted-count-sheet-item-count-sheet-items', 'Loading Count Sheet Items');
                    _this._trackLoadingService.startLoading('unkitted-count-sheet-item-kit-boms', 'Loading Kit BOMs');
                    _this._trackLoadingService.startLoading('unkitted-count-sheet-item-combinations', 'Loading Item Batch Verification');
                    zip(_this.getRedspotQuantities(_this.countSheet.data.audit_id, _this.countSheet.data.warehouse_id).toPromise().then(function (data) { that._trackLoadingService.stopLoading('unkitted-count-sheet-item-redspot-quantities'); return data; }), _this.getSapQuantities(_this.countSheet.data.audit_id).toPromise().then(function (data) { _this._trackLoadingService.stopLoading('unkitted-count-sheet-item-sap-quantities'); return data; }), _this.getItemSettingsByAudit(_this.countSheet.data.audit_id).toPromise().then(function (data) { _this._trackLoadingService.stopLoading('unkitted-count-sheet-item-item-settings'); return data; }), _this.getExclusions(_this.countSheet.data.audit_id).toPromise().then(function (data) { _this._trackLoadingService.stopLoading('unkitted-count-sheet-item-exclusions'); return data; }), _this.getCountSheetItems(online, _this.auditDbId, _this.countSheet.id).then(function (data) { _this._trackLoadingService.stopLoading('unkitted-count-sheet-item-count-sheet-items'); return data; }), _this.getRootKitDefinitionItems(_this.countSheet.data.kit_id).toPromise().then(function (data) { _this._trackLoadingService.stopLoading('unkitted-count-sheet-item-kit-boms'); return data; }), _this._auditService.getWarehouses(_this.countSheet.data.audit_id), _this.getItemCombinations().toPromise().then(function (data) { _this._trackLoadingService.stopLoading('unkitted-count-sheet-item-combinations'); return data; })).subscribe(function (allUnkittedCountsheetData) {
                        _this.redspotQuantities = allUnkittedCountsheetData[0];
                        _this.dataSource.redspotQuantities = _this.redspotQuantities;
                        _this.sapQuantities = allUnkittedCountsheetData[1];
                        _this.dataSource.sapQuantities = _this.sapQuantities;
                        _this.itemSettings = allUnkittedCountsheetData[2];
                        _this.dataSource.itemSettings = _this.itemSettings;
                        _this.exclusions = allUnkittedCountsheetData[3];
                        _this.dataSource.exclusions = _this.exclusions;
                        _this.countSheetItems = allUnkittedCountsheetData[4];
                        _this.dataSource.countSheetItems = _this.countSheetItems;
                        _this.rootKitDefinitionItems = allUnkittedCountsheetData[5];
                        _this.dataSource.rootKitDefinitionItems = _this.rootKitDefinitionItems;
                        _this.warehouses = allUnkittedCountsheetData[6];
                        _this.itemCombinations = allUnkittedCountsheetData[7];
                        _this.dataSource.itemCombinations = _this.itemCombinations;
                        _this.countSheetItemHelper.cacheItemsFromSapQuantities(_this.sapQuantities);
                        _this.countSheetItemHelper.cacheItemsForExclusions(_this.exclusions);
                        _this.countSheetItemHelper.cacheItemsForItemSettings(_this.itemSettings);
                        _this._trackLoadingService.startLoading('unkitted-count-sheet-recall-oracle', 'Loading Recall Oracle');
                        _this._recallOracleService.prime(_this.auditDbId, { itemCombinations: _this.itemCombinations }).then(function (_) {
                            _this._trackLoadingService.stopLoading('unkitted-count-sheet-recall-oracle');
                            _this.dataSource.recallOracleService = _this._recallOracleService;
                            _this.refreshDataSource();
                        });
                    });
                });
            });
        });
        this.trackLoadingSubscription = this._trackLoadingService.stopLoadingEvent.subscribe(function () {
            if ('scrollRestoration' in history) {
                history.scrollRestoration = 'manual';
            }
            document.getElementById('top').scrollIntoView();
        });
    };
    UnkittedItemsCountsheetComponent.prototype.bomChanged = function (event) {
        var _this = this;
        this._trackLoadingService.startLoading('unkitted-count-sheet-item-bom-changed', 'Updating BOM');
        this.updateBom(+event.value).subscribe(function (data) {
            _this.countSheet = data;
            _this.dataSource.countSheet = _this.countSheet;
            zip(_this.getRootKitDefinitionItems(+event.value)).subscribe(function (kitDefinitionData) {
                _this.rootKitDefinitionItems = kitDefinitionData[0];
                _this.dataSource.rootKitDefinitionItems = _this.rootKitDefinitionItems;
                _this.refreshDataSource();
                _this._trackLoadingService.stopLoading('unkitted-count-sheet-item-bom-changed');
            });
        });
    };
    UnkittedItemsCountsheetComponent.prototype.getRootKitDefinitionItems = function (kitId) {
        return this._kitDefinitionItemService.getKitDefinitionItems().pipe(map(function (data) {
            return data.filter(function (kdi) { return kdi.kit_definition_id === kitId; });
        }));
    };
    UnkittedItemsCountsheetComponent.prototype.updateTotalCount = function () {
        var itemQuantities = this.countSheetItems.map(function (csi) { return parseInt(csi.data.quantity, 10); });
        if (itemQuantities.length > 0) {
            this.actualItemCount = itemQuantities.reduce(function (a, b) { return a + b; });
        }
        else {
            this.actualItemCount = 0;
        }
    };
    UnkittedItemsCountsheetComponent.prototype.refreshDataSource = function () {
        this.dataSource.refresh();
        this.updateTotalCount();
        this.setAuditedByValue(this.countSheetItems);
        var height = 58;
        document.getElementsByTagName('virtual-scroller')[0].style.height = Math.min(height + height * this.dataSource.data.length, height * 11).toString() + 'px';
    };
    UnkittedItemsCountsheetComponent.prototype.updateBom = function (v) {
        var that = this;
        var value = v || 'null'; //so we have a set value, but it will get set to nil in the backend
        var online = this._onlineService.isOnline();
        if (online && !this._onlineService.getTestOfflineBool()) {
            return this._countSheetService.updateCountSheet(this.countSheet.dbId, { kit_id: value, show_expected_item_list: (!!v).toString() }).pipe(map(function (data) {
                var updatedCountSheet = that._countSheetDatabaseService.new(data);
                that._countSheetDatabaseService.update(updatedCountSheet);
                return updatedCountSheet;
            }));
        }
        else {
            var updatedCountSheet = that.countSheet;
            updatedCountSheet.data.kit_id = value;
            updatedCountSheet.data.show_expected_item_list = !!v;
            updatedCountSheet.isSynchronized = 0;
            that._countSheetDatabaseService.update(updatedCountSheet);
            return of(updatedCountSheet);
        }
    };
    UnkittedItemsCountsheetComponent.prototype.getKits = function () {
        var _this = this;
        this._kitService.getKits().subscribe(function (data) {
            _this.kits = data;
        });
    };
    UnkittedItemsCountsheetComponent.prototype.getCountSheet = function (online, countSheetClientId) {
        var _this = this;
        var that = this;
        var promise = new Promise(function (resolve) {
            if (online && !_this._onlineService.getTestOfflineBool()) {
                _this._countSheetService.getCountSheet(countSheetClientId).subscribe(function (data) {
                    that.audit = data.audit;
                    that.createdBy = data.created_by;
                    that.location = data.audit_location;
                    var updatedCountSheet = that._countSheetDatabaseService.new(data);
                    that._countSheetDatabaseService.update(updatedCountSheet);
                    resolve(updatedCountSheet);
                });
            }
            else {
                that._countSheetDatabaseService.get(countSheetClientId).then(function (cs) {
                    resolve(cs);
                });
            }
        });
        return promise;
    };
    UnkittedItemsCountsheetComponent.prototype.getCountSheetItems = function (online, auditId, countSheetClientId) {
        var _this = this;
        var that = this;
        return new Promise(function (resolve) {
            if (online && !_this._onlineService.getTestOfflineBool()) {
                _this._countSheetItemService.getCountSheetItems(auditId, { count_sheet_client_id: countSheetClientId }).subscribe(function (data) {
                    that._countSheetItemService.refreshOfflineDataForCountSheet(auditId, countSheetClientId, data).then(function (csis) {
                        resolve(csis);
                    });
                });
            }
            else {
                that._countSheetItemDatabaseService.find(auditId, { count_sheet_client_id: countSheetClientId, _destroy: false }).then(function (existingCountSheetItems) {
                    //we must have already cached these into indexeddb
                    if (existingCountSheetItems.length > 0) {
                        return resolve(existingCountSheetItems);
                    }
                    //get the data from the cached serviceworker
                    that._countSheetItemService.getCountSheetItems(auditId).subscribe(function (auditCountSheetItems) {
                        var matches = auditCountSheetItems.filter(function (cs) { return cs.count_sheet_client_id === countSheetClientId; });
                        that._countSheetItemService.refreshOfflineDataForCountSheet(auditId, countSheetClientId, matches).then(function (csis) {
                            resolve(csis);
                        });
                    });
                });
            }
        });
    };
    UnkittedItemsCountsheetComponent.prototype.quantityChanged = function (event, element) {
        var that = this;
        this.currentCountSheetItem = element;
        this.currentCountSheetItem.data.modified_by = this.userData.name;
        this.currentCountSheetItem.data.quantity = event.target.value;
        this.currentCountSheetItem.data.checked = true;
        this.currentCountSheetItem.data.quantity = +event.target.value;
        this.currentCountSheetItem.isSynchronized = 0;
        if (this.currentCountSheetItem.data.quantity > 1 && this.currentCountSheetItem.data.serial) {
            this.showSnackbar('Quantity must be less than 2', false);
            return;
        }
        this._countSheetItemService.update(this.currentCountSheetItem).then(function () {
            that.showWarningDialogMessages();
            zip(that.getCountSheet(false, that.countSheet.id), that.getCountSheetItems(false, that.auditDbId, that.countSheet.id)).subscribe(function (data) {
                that.countSheet = data[0];
                that.countSheetItems = data[1];
                that.setAuditedByValue(that.countSheetItems);
                that.updateTotalCount();
            });
        });
    };
    UnkittedItemsCountsheetComponent.prototype.deleteAll = function () {
        var _this = this;
        var dialogRef = this._dialog.open(ConfirmationDialogComponent, {
            width: '30%',
            data: Messages.clearCountSheet,
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                var that_1 = _this;
                that_1._trackLoadingService.startLoading('delete-all-clicked', 'Updating Count Sheet');
                that_1._countSheetItemService.deleteCountSheetItemsOffline(that_1.auditDbId, that_1.countSheet.id).then(function (_) {
                    that_1.countSheetItems = [];
                    that_1.dataSource.countSheetItems = _this.countSheetItems;
                    that_1.updateJustAddedData(0, '');
                    that_1.refreshDataSource();
                    that_1.countSheet.data.total_items_counted = 0;
                    _this._trackLoadingService.stopLoading('delete-all-clicked');
                });
            }
        });
    };
    UnkittedItemsCountsheetComponent.prototype.lotPoolManagedQuantityChanged = function (event, element) {
        var newTotalQty = +event.target.value;
        var oldTotalQty = element.count_sheet_items.reduce(function (total, csi) { return total + csi.data.quantity; }, 0);
        element.count_sheet_items = this.countSheetItemHelper.lotPoolManagedQuantityChanged(newTotalQty - oldTotalQty, element.count_sheet_items, element.available_redspot_inventory, this.sapQuantities.filter(function (sap) { return sap.item_id === element.data.item_id; }), element.data, this.countSheet, { isKitted: false })[0];
        element.data.quantity = element.count_sheet_items.reduce(function (total, csi) { return total + csi.data.quantity; }, 0);
        this.updateCountSheetItem(element.count_sheet_items);
    };
    UnkittedItemsCountsheetComponent.prototype.lotNumberChanged = function (event, element) {
        var secondaryElement;
        var newlotNumber = (event.target.value || '').toUpperCase();
        if (!newlotNumber && this.isLotPoolManaged(element)) {
            newlotNumber = this.countSheetItemHelper.getAvailablelotPoolManagedLotNumber(this.countSheetItems.filter(function (cs) { return cs.data.item_id === element.data.item_id; }), [], this.sapQuantities.filter(function (sap) { return sap.item_id === element.data.item_id; }));
            event.target.value = newlotNumber;
        }
        //if row wasn't checked they changed the lot and expect the quantity to stay the same, as that is what it is visually
        if (!element.data.checked) {
            element.data.quantity = element.data.expected_quantity ? element.data.expected_quantity : 0;
        }
        element._destroy = false;
        element.data.checked = true;
        element.data.manually_entered = true;
        element.data.modified_by = this.userData.name;
        secondaryElement = JSON.parse(JSON.stringify(element));
        secondaryElement.data.quantity = 0;
        secondaryElement.data.hide = true;
        element.id = '';
        element.dbId = 0;
        element.data.lot_number = newlotNumber;
        this.updateCountSheetItem(element, secondaryElement);
    };
    UnkittedItemsCountsheetComponent.prototype.serialNumberChanged = function (event, element) {
        var secondaryElement;
        if (!element.data.checked) {
            element.data.quantity = element.data.expected_quantity;
        }
        element._destroy = false;
        element.data.isKitted = true;
        element.data.checked = true;
        element.data.manually_entered = true;
        element.data.modified_by = this.userData.name;
        secondaryElement = JSON.parse(JSON.stringify(element));
        secondaryElement.data.quantity = 0;
        secondaryElement.data.hide = true;
        element.id = '';
        element.dbId = 0;
        element.data.serial = event.target.value ? event.target.value.toUpperCase() : event.target.value;
        this.updateCountSheetItem(element, secondaryElement);
    };
    /**
     * change reference for manually entered items online/ offline
     * @param event
     * @param element
    */
    UnkittedItemsCountsheetComponent.prototype.referenceChanged = function (event, element) {
        var secondaryElement;
        var match = this.items.filter(function (item) {
            return (item.reference.toUpperCase() === event.target.value.toUpperCase()) || (item.unpunctuated_reference.toUpperCase() === event.target.value.toUpperCase());
        });
        if (match.length === 0) {
            this.showSnackbar('\'' + event.target.value.toUpperCase() + '\' is not a valid reference.', false);
            event.target.value = element.data.reference;
        }
        else {
            secondaryElement = JSON.parse(JSON.stringify(element));
            secondaryElement.data.quantity = 0;
            secondaryElement.data.hide = true;
            element.id = '';
            element.dbId = 0;
            element.data.reference = match[0].reference;
            element.data.item_id = match[0].id;
            element.data.description = match[0].description;
            element.data.is_lot_tracked = match[0].is_lot_number_tracked;
            element.data.is_lot_pool_managed = match[0].is_lot_pool_managed;
            element.data.is_serial_tracked = match[0].is_serial_tracked;
            element.data.min_order_quantity = match[0].minimum_order_quantity;
            element.data.is_consigned = match[0].is_consigned;
            this.updateCountSheetItem(element, secondaryElement);
        }
    };
    UnkittedItemsCountsheetComponent.prototype.updateCountSheetItem = function (primaryElements, secondaryElements, callback) {
        var _this = this;
        if (secondaryElements === void 0) { secondaryElements = []; }
        if (callback === void 0) { callback = null; }
        primaryElements = [].concat.apply([], [primaryElements]);
        secondaryElements = [].concat.apply([], [secondaryElements]);
        primaryElements.forEach(function (e) {
            e.data.count_sheet_id = _this.countSheet.dbId;
            e.data.count_sheet_client_id = _this.countSheet.id;
            e.data.audit_id = _this.countSheet.data.audit_id;
            e.isSynchronized = 0;
        });
        secondaryElements.forEach(function (e) {
            e.data.count_sheet_id = _this.countSheet.dbId;
            e.data.count_sheet_client_id = _this.countSheet.id;
            e.data.audit_id = _this.countSheet.data.audit_id;
            e.isSynchronized = 0;
        });
        var elements = [].concat.apply([], [primaryElements, secondaryElements]);
        callback = callback || function () { };
        var that = this;
        var online = this._onlineService.isOnline() && !this._onlineService.getTestOfflineBool();
        var errors = primaryElements.map(function (e) {
            return that.countSheetItemHelper.validateCountSheetItem(e);
        }).reduce(function (total, internalErrors) { return __assign({}, total, internalErrors); });
        if (Object.keys(errors).length > 0) {
            that.showSnackbar(Object.values(errors).join('.  '), false);
            this.getCountSheetItems(online, this.auditDbId, this.countSheet.id).then(function (data) {
                _this.countSheetItems = data;
                _this.dataSource.countSheetItems = _this.countSheetItems;
                _this.refreshDataSource();
            });
            return;
        }
        zip.apply(void 0, elements.map(function (e) {
            return that._countSheetItemService.update(e);
        })).subscribe(function () {
            zip(that.getCountSheet(false, that.countSheet.id), that.getCountSheetItems(false, that.auditDbId, that.countSheet.id)).subscribe(function (data) {
                that.countSheetItems = data[1];
                that.countSheet = data[0];
                that.setAuditedByValue(this.countSheetItems);
                that.updateTotalCount();
                Array.from(new Set(primaryElements.map(function (e) { return that.countSheetItemHelper.checkForWarnings(e, that._recallOracleService); }).reduce(function (total, message) {
                    return total.concat(message.warnings);
                }, []))).slice().forEach(function (m) {
                    that.openWarningDialog(m.toString());
                });
                callback();
            });
        });
    };
    UnkittedItemsCountsheetComponent.prototype.updateCountSheetItems = function () {
        var _this = this;
        var that = this;
        zip(this.getCountSheet(false, that.countSheet.id), this.getCountSheetItems(false, that.auditDbId, that.countSheet.id)).subscribe(function (data) {
            that.countSheet = data[0];
            that.countSheetItems = data[1];
            that.dataSource.countSheetItems = that.countSheetItems;
            that.refreshDataSource();
            var justAdded = that.countSheetItems.filter(function (cs) { return cs.data.justAdded; });
            that.updateJustAddedData(justAdded.reduce(function (a, b) { return a + b.data.quantity; }, 0), (((justAdded[0] || {}).data || {}).reference || ''));
            _this.scrollToBottom();
        });
    };
    UnkittedItemsCountsheetComponent.prototype.isMinOrderQuantityGreaterThanOne = function (countSheetItem) {
        var minOrderQuantity = countSheetItem.data.min_order_quantity;
        if (minOrderQuantity > 1) {
            return true;
        }
        return false;
    };
    UnkittedItemsCountsheetComponent.prototype.getExclusions = function (auditId) {
        return this._exclusionService.getExclusions(auditId);
    };
    UnkittedItemsCountsheetComponent.prototype.getRedspotQuantities = function (auditId, warehouseId) {
        return this._redspotInventoryImportService.getInventory(auditId).pipe(map(function (data) { return data.filter(function (rq) { return rq.warehouse_id === warehouseId && !rq.kit_instance_id; }); }));
    };
    UnkittedItemsCountsheetComponent.prototype.getSapQuantities = function (auditId) {
        return this._consignmentInventoryImportService.getInventory(auditId, true);
    };
    UnkittedItemsCountsheetComponent.prototype.getItemCombinations = function () {
        return this._itemCombinationService.get();
    };
    UnkittedItemsCountsheetComponent.prototype.getItemSettingsByAudit = function (auditId) {
        return this._itemSettingService.getItemSettingsByAudit(auditId);
    };
    UnkittedItemsCountsheetComponent.prototype.printAndComplete = function () {
        var _this = this;
        var that = this;
        new Promise(function (resolve, reject) {
            if (that.countSheet.data.attachments_count > 0) {
                return resolve(null);
            }
            var dialogRef = that._dialog.open(ConfirmationDialogComponent, {
                width: '30%',
                data: Messages.finishWithoutAttachment,
            });
            dialogRef.afterClosed().subscribe(function (result) {
                if (result) {
                    return resolve(null);
                }
                reject();
            });
        }).then(function () {
            that._databaseService.add('user_actions', {
                audit_id: that.auditDbId.toString(),
                auditor: that.userData.first_name + " " + that.userData.last_name,
                timestamp: "" + (new Date()).toUTCString(),
                area: "" + that.countSheet.data.area,
                kit_reference: '',
                kit_lot_number: '',
                action: 'Printed count sheet',
                total_items_counted: that.countSheet.data.total_items_counted,
                site_id: that.countSheet.data.audit_location_id,
                warehouse_id: that.countSheet.data.warehouse_id
            });
            that._databaseService.add('user_actions', {
                audit_id: _this.auditDbId.toString(),
                auditor: _this.userData.first_name + " " + that.userData.last_name,
                timestamp: "" + (new Date()).toUTCString(),
                area: "" + that.countSheet.data.area,
                kit_reference: '',
                kit_lot_number: '',
                action: 'Finished count sheet',
                total_items_counted: that.countSheet.data.total_items_counted,
                site_id: that.countSheet.data.audit_location_id,
                warehouse_id: that.countSheet.data.warehouse_id
            });
            that.completeCountSheet(true);
        }, function () {
            console.log('they didnt want to finish without an attachment');
        });
    };
    UnkittedItemsCountsheetComponent.prototype.completeCountSheet = function (print) {
        var _this = this;
        if (print === void 0) { print = false; }
        var countedMetadata = {
            counted_by_id: this.userData.user_id,
            counted_time: moment().toDate()
        };
        var countedStatus = 3;
        var that = this;
        of(that._onlineService.isOnline()).subscribe(function (online) {
            if (online && !that._onlineService.getTestOfflineBool()) {
                //set status to counted
                that._countSheetService.updateCountSheetStatus(that.countSheet.dbId, countedStatus, countedMetadata).subscribe(function (data) {
                    if (data.success) {
                        that.countSheet.data.counted_by = _this.userData;
                        that.countSheet.data.counted_by_id = that.userData.user_id;
                        that.countSheet.data.counted_time = moment().format('lll');
                        that.countSheet.data.count_sheet_status = countedStatus;
                        that._countSheetDatabaseService.update(that.countSheet).then(function () {
                            if (print) {
                                that.dataSource.countSheet = that.countSheet;
                                that.dataSource.refresh();
                                var printOptions = {
                                    isKitted: false,
                                    warehouseName: that.countSheet.data.warehouse_name,
                                    locationName: that.countSheet.data.location_name,
                                    lotNumber: that.countSheet.data.lot_number,
                                    statusText: that.countSheet.data.status_text,
                                    agencyName: that.audit.agency_name,
                                    reference: that.countSheet.data.reference,
                                    area: that.countSheet.data.area,
                                    countSheet: that.countSheet,
                                    redspotQuantities: that.redspotQuantities,
                                    lotPoolManagedItems: that.lotPoolManagedItems,
                                    kitDefinitionItems: that.rootKitDefinitionItems,
                                    filters: [],
                                    itemSettings: that.itemSettings,
                                    exclusions: that.exclusions
                                };
                                that.printHelper.print(that.dataSource.data, printOptions);
                            }
                            that.goToCountSheets();
                        });
                    }
                    else {
                        that.snackBarConfig.message = 'Failed';
                        that.snackBarConfig.success = false;
                        that.openSnackBar();
                    }
                });
            }
            else {
                that.countSheet.data.counted_by = _this.userData;
                that.countSheet.data.counted_by_id = that.userData.user_id;
                that.countSheet.data.counted_time = moment().format('lll');
                that.countSheet.data.count_sheet_status = countedStatus;
                that.countSheet.isSynchronized = 0;
                that._countSheetDatabaseService.update(that.countSheet).then(function () {
                    if (print) {
                        that.dataSource.countSheet = that.countSheet;
                        that.dataSource.refresh();
                        var printOptions = {
                            isKitted: false,
                            warehouseName: that.countSheet.data.warehouse_name,
                            locationName: that.countSheet.data.location_name,
                            lotNumber: that.countSheet.data.lot_number,
                            statusText: that.countSheet.data.status_text,
                            agencyName: that.audit.agency_name,
                            reference: that.countSheet.data.reference,
                            area: that.countSheet.data.area,
                            countSheet: that.countSheet,
                            redspotQuantities: that.redspotQuantities,
                            lotPoolManagedItems: that.lotPoolManagedItems,
                            kitDefinitionItems: that.rootKitDefinitionItems,
                            filters: [],
                            itemSettings: that.itemSettings,
                            exclusions: that.exclusions
                        };
                        that.printHelper.print(that.dataSource.data, printOptions);
                    }
                    that.goToCountSheets();
                });
            }
        });
    };
    UnkittedItemsCountsheetComponent.prototype.showSnackbar = function (message, success) {
        this.snackBarConfig.message = message;
        this.snackBarConfig.success = success;
        this.openSnackBar();
    };
    UnkittedItemsCountsheetComponent.prototype.goToCountSheets = function () {
        if (this.previousUrl.indexOf('siteID') !== -1) {
            this._router.navigate([this.removeQueryStrings(this.previousUrl)], { queryParams: { status: this.countSheet.data.status_text, selected_tab: 1, siteID: this.location.id } });
        }
        else {
            if (this.viewOnly) {
                this._router.navigate([this.previousUrl], { queryParams: { status: this.status, selected_tab: 1, orderType: 'consigned' } });
            }
            else {
                this._router.navigate([this.previousUrl], { queryParams: { status: this.status, selected_tab: 1, orderType: 'consigned' } });
            }
        }
    };
    UnkittedItemsCountsheetComponent.prototype.removeQueryStrings = function (url) {
        var res = '';
        var qsStart = url.indexOf('?');
        if (qsStart !== -1) {
            res = url.substr(0, qsStart);
        }
        else {
            res = url;
        }
        return res;
    };
    UnkittedItemsCountsheetComponent.prototype.goToAuditDashboard = function () {
        this._router.navigate(['audit_dashboard', this.auditDbId]);
    };
    UnkittedItemsCountsheetComponent.prototype.reloadWhenBackOnline = function () {
        this.snackBarConfig.message = 'Connected!! Reloading...';
        this.snackBarConfig.success = true;
        this.openSnackBar();
        var countSheetClientId = this._route.snapshot.paramMap.get('id');
        this._router.navigate([this.auditDbId, 'non_kitted_count_sheet', countSheetClientId, this.viewOnly], { queryParams: { status: this.status } });
    };
    UnkittedItemsCountsheetComponent.prototype.scrollToBottom = function () {
        document.getElementById('bottom').scrollIntoView();
        this.virtualScroller.scrollToIndex(this.dataSource.data.length - 1);
    };
    UnkittedItemsCountsheetComponent.prototype.ngOnDestroy = function () {
        if (this.reloadSubscription) {
            this.reloadSubscription.unsubscribe();
        }
        if (this.startReloadingSubscription) {
            this.startReloadingSubscription.unsubscribe();
        }
        if (this.isOnlineSubscription) {
            this.isOnlineSubscription.unsubscribe();
        }
        if (this.isOfflineSubscription) {
            this.isOfflineSubscription.unsubscribe();
        }
        if (this.trackLoadingSubscription) {
            this.trackLoadingSubscription.unsubscribe();
        }
        this.printer = null;
        this.virtualScroller = null;
    };
    UnkittedItemsCountsheetComponent.prototype.openSnackBar = function () {
        this._snackBar.openFromComponent(MaterialSnackbarComponent, {
            data: this.snackBarConfig,
            duration: this.snackBarConfig.duration
        });
    };
    UnkittedItemsCountsheetComponent.prototype.openWarningDialog = function (message) {
        this.countSheetItemHelper.playErrorSound(this._audioPlayerService.setting);
        var dialogRef = this._dialog.open(WarningDialogComponent, {
            width: '50%',
            disableClose: true,
            role: 'alertdialog',
            data: {
                message: message
            },
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
            }
        });
    };
    UnkittedItemsCountsheetComponent.prototype.showWarningDialogMessages = function () {
        var _this = this;
        var messages = this.countSheetItemHelper.checkForWarnings(this.currentCountSheetItem, this._recallOracleService);
        messages.warnings.forEach(function (m) {
            _this.openWarningDialog(m);
        });
    };
    UnkittedItemsCountsheetComponent.prototype.setAuditedByValue = function (countSheetItems) {
        if (countSheetItems) {
            var names = countSheetItems.map(function (csi) { return csi.data.modified_by; }).filter(function (v, i, a) { return a.indexOf(v) === i; });
            this.auditedBy = names.join(' / ');
        }
        else {
            this.auditedBy = '';
        }
    };
    UnkittedItemsCountsheetComponent.prototype.openCountSheetItemImportDialog = function () {
        var _this = this;
        var that = this;
        var dialogRef = this._dialog.open(ImportCountSheetItemComponent, {
            width: '30%',
            data: {
                countSheet: that.countSheet,
                lotPoolManagedItems: that.lotPoolManagedItems,
                redspotItems: [],
                sapItems: that.sapQuantities
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                that.getCountSheetItems(that.isOnline, that.auditDbId, that.countSheet.id).then(function (data) {
                    that.countSheetItems = data;
                    that.dataSource.countSheetItems = _this.countSheetItems;
                    that.getCountSheet(false, that.countSheet.id).then(function (cs) {
                        that.countSheet = cs;
                        that.refreshDataSource();
                    });
                });
            }
        });
    };
    UnkittedItemsCountsheetComponent.prototype.editUnkittedCountSheet = function () {
        var _this = this;
        var dialogRef = this._dialog.open(CreateUnkittedCountSheetComponent, {
            width: '50%',
            data: {
                title: 'Edit Unkitted Countsheet',
                creatingCountsheet: false,
                countsheet: this.countSheet,
                warehouses: this.warehouses,
                offline: true
            }
        });
        dialogRef.componentInstance.unkittedCountSheetEditedEvent.subscribe(function (data) {
            _this.countSheet = data;
        });
    };
    UnkittedItemsCountsheetComponent.prototype.anyUnsynched = function (row) {
        return (row.count_sheet_items || [row]).filter(function (r) { return !r.isSynchronized; }).length > 0;
    };
    UnkittedItemsCountsheetComponent.prototype.isLotPoolManaged = function (row) {
        return this.countSheetItemHelper.getIsLotPoolManaged(row.data.item_id, this.lotPoolManagedItems);
    };
    UnkittedItemsCountsheetComponent.prototype.updateJustAddedData = function (count, reference) {
        this.justAddedCount = count;
        this.justAddedReference = reference;
    };
    UnkittedItemsCountsheetComponent.prototype.cleanQuantity = function (event) {
        if (!this.positiveIntegerHelper.isNumeric(event)) {
            event.preventDefault();
        }
    };
    UnkittedItemsCountsheetComponent.prototype.exportCountsheetItems = function () {
        var exportOptions = { kit: false };
        var exportHelper = new CountSheetExportHelper(this._excelService);
        exportHelper.exportCountsheetItemsToExcel(this.dataSource.data, exportOptions);
    };
    UnkittedItemsCountsheetComponent.prototype.cancelCounting = function () {
        var _this = this;
        var dialogRef = this._dialog.open(ConfirmationDialogComponent, {
            width: '30%',
            data: Messages.cancelCounting,
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this._databaseService.add('user_actions', {
                    audit_id: _this.auditDbId.toString(),
                    auditor: _this.userData.first_name + " " + _this.userData.last_name,
                    timestamp: "" + (new Date()).toUTCString(),
                    area: "" + _this.countSheet.data.area,
                    kit_reference: '',
                    kit_lot_number: '',
                    action: 'Canceled count sheet',
                    total_items_counted: _this.countSheet.data.total_items_counted,
                    site_id: _this.countSheet.data.audit_location_id,
                    warehouse_id: _this.countSheet.data.warehouse_id
                });
                if (_this._onlineService.isOnline() && !_this._onlineService.getTestOfflineBool()) {
                    _this._countSheetService.deleteCountSheet(_this.countSheet).subscribe(function () {
                        _this.goToCountSheets();
                    });
                }
                else {
                    _this.countSheet.isSynchronized = 0;
                    _this.countSheet.data.archived = true;
                    _this._countSheetDatabaseService.update(_this.countSheet);
                    _this.goToCountSheets();
                }
            }
        });
    };
    UnkittedItemsCountsheetComponent.prototype.isAnySerialTracked = function () {
        if (this.countSheetItems) {
            var serialTracked = this.countSheetItems.find(function (csi) { return csi.data.is_serial_tracked === true; });
            if (serialTracked) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    };
    UnkittedItemsCountsheetComponent.prototype.hasItemWithSerial = function () {
        if (this.countSheetItems) {
            var serialTracked = this.countSheetItems.find(function (csi) { return csi.data.serial; });
            if (serialTracked) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    };
    UnkittedItemsCountsheetComponent.prototype.continueInTable = function (event, element, callback) {
        var lastVisible = this.virtualScroller.viewPortItems.indexOf(element) === this.virtualScroller.viewPortItems.length - 1;
        var notLast = this.dataSource.data.indexOf(element) !== this.dataSource.data.length - 1;
        event.preventDefault();
        if (lastVisible && notLast) {
            var lastAvailablePosition = this.dataSource.data.length - 1;
            var wantedPosition = this.dataSource.data.indexOf(element) + Math.ceil(this.virtualScroller.viewPortItems.length / 2) + 1;
            this.virtualScroller.scrollToIndex(Math.min(lastAvailablePosition, wantedPosition), false, 0, undefined, callback);
        }
        else {
            callback();
        }
    };
    UnkittedItemsCountsheetComponent.prototype.keyDown = function (event, element) {
        if (event.code === 'Tab') {
            var el_1 = event.srcElement;
            this.continueInTable(event, element, function () {
                while (el_1.nodeName !== 'TR') {
                    el_1 = el_1.parentElement;
                }
                var node = el_1.nextElementSibling;
                if (node) {
                    var input_1 = node.querySelector('input[type="number"]');
                    if (input_1) {
                        setTimeout(function () {
                            input_1.focus();
                            input_1.select();
                        }, 300);
                    }
                }
            });
        }
        else {
            this.cleanQuantity(event);
        }
    };
    UnkittedItemsCountsheetComponent.prototype.attachFile = function () {
        this.openAttachmentDialog();
    };
    UnkittedItemsCountsheetComponent.prototype.openAttachmentDialog = function () {
        var that = this;
        var dialogRef = that._dialog.open(AttachmentDialogComponent, {
            width: '70%',
            data: {
                countsheet: that.countSheet,
                devices: that.cameras
            }
        });
        dialogRef.afterClosed().subscribe(function () {
            that.getCountSheet(false, that.countSheet.id).then(function (cs) {
                that.countSheet = cs;
                that.dataSource.countSheet = that.countSheet;
            });
        });
    };
    return UnkittedItemsCountsheetComponent;
}());
export { UnkittedItemsCountsheetComponent };
