import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { urls } from '../helpers/urls';
import { IcountsheetItemData } from '../interfaces/icountsheet-item';
import { map } from 'rxjs/operators';
import { CountSheetItemDatabaseService } from '../services/count-sheet-item-database.service';
import { CountSheetDatabaseService } from '../services/count-sheet-database.service';
import { zip } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class CountSheetItemService {

  constructor(
    private http: HttpClient,
    private _countSheetItemDatabaseService: CountSheetItemDatabaseService,
    private _countSheetDatabaseService: CountSheetDatabaseService
  ) { }


  ClearSynchronized(auditId) {
    const that = this;
    let promise = new Promise((resolve, reject) => {
      that._countSheetItemDatabaseService.find(auditId, {isSynchronized: true}).then(function (matches: any) {
        zip(...matches.map(m => that._countSheetItemDatabaseService.delete(m.id)).concat(new Promise((r, a) => {r(null); }))).subscribe(function () {
          resolve(null);
        });
      });
    });

    return promise;
  }

  //find synced ones for the count sheet and replace em, if there is a matching unsynced one they win
  refreshOfflineDataForCountSheet(auditId, countSheetClientId, data: any[]) {
    const that = this;
    const countSheetItems = data.map(csi => {
      return that._countSheetItemDatabaseService.new(csi);
    });
    let promise = new Promise((resolve, reject) => {
      that._countSheetItemDatabaseService.find(auditId, {isSynchronized: true, count_sheet_client_id: countSheetClientId}).then(function (matches: IcountsheetItemData[]) {
        zip(
          ...matches.map(csi => that._countSheetItemDatabaseService.delete(csi.id)).concat(new Promise((r, a) => {r(null); }))
        ).subscribe(_ => {
          that._countSheetItemDatabaseService.find(auditId, {isSynchronized: false, count_sheet_client_id: countSheetClientId}).then(function (matches: IcountsheetItemData[]) {
            const unsyncedCountSheetItemClientIds = matches.map(csi => csi.id);
            const freshCountSheetItems = countSheetItems.filter(csi => unsyncedCountSheetItemClientIds.indexOf(csi.id) === -1);
            zip(
              ...freshCountSheetItems.map(csi => that._countSheetItemDatabaseService.add(csi)).concat(new Promise((r, a) => {r(null); }))
            ).subscribe(_ => {
              that._countSheetItemDatabaseService.find(auditId, {count_sheet_client_id: countSheetClientId, _destroy: false}).then(function (matches: IcountsheetItemData[]) {
                resolve(matches);
              });
            });
          });
        });
      });
    });
    return promise;
  }

  getCountSheetItemsAndClearOfflineData(auditId) {
    const that = this;
    let promise = new Promise((resolve, reject) => {
      that.getCountSheetItems(auditId).subscribe(data => {
        that._countSheetItemDatabaseService.deleteByIndex('data.audit_id', parseInt(auditId, 10)).then(function () {
          resolve(null);
        });
      });
    });

    return promise;
  }

  addOrUpdateCountSheetItemByCriteria(csi, options?) {
    options = options || {};
    const that = this;
    return new Promise((resolve, reject) => {
      that._countSheetItemDatabaseService.find(csi.data.audit_id, {count_sheet_client_id: csi.data.count_sheet_client_id, lot_number: csi.data.lot_number, serial: csi.data.serial, reference: csi.data.reference, _destroy: false}).then((matches: any[]) => {
        if (matches.length > 0) {
          const updatingCountSheetItem = matches[0];
          if (options.add_to_quantity) {
            updatingCountSheetItem.data.quantity += csi.data.quantity;
          }
          else {
            updatingCountSheetItem.data.quantity = csi.data.quantity;
          }
          updatingCountSheetItem.isSynchronized = 0;
          that._countSheetItemDatabaseService.update(updatingCountSheetItem).then(_ => {
            resolve(null);
          });
        }
        else {
          csi.isSynchronized = 0;
          that._countSheetItemDatabaseService.add(csi).then(_ => {
            resolve(null);
          });
        }
      });
    });
  }

  deleteCountSheetItemsOffline(auditId, countSheetClientId) {
    const that = this;
    return new Promise((resolve, reject) => {
      that._countSheetItemDatabaseService.find(auditId, {count_sheet_client_id: countSheetClientId}).then((matches: any[]) => {
        const deletableCountSheetItems = matches.filter(csi => !csi.dbId);
        const updatingCountSheetItems = matches.filter(csi => csi.dbId);
        updatingCountSheetItems.forEach((csi) => {
          csi.isSynchronized = 0;
          csi._destroy = true;
        });
        let promises = [new Promise((r, a) => { r(null); })];
        deletableCountSheetItems.forEach((csi) => {
          promises.push(that._countSheetItemDatabaseService.delete(csi.id));
        });
        promises.push(that._countSheetItemDatabaseService.bulkUpdate(updatingCountSheetItems));
        zip(
          ...promises
        ).subscribe(_ => {
          that._countSheetDatabaseService.get(countSheetClientId).then((cs: any) => {
            cs.isSynchronized = 0;
            cs.data.total_items_counted = 0;
            that._countSheetDatabaseService.update(cs).then(_ => {
              resolve(null);
            });
          });
        })
      });
    });
  }

  update(countSheetItem: IcountsheetItemData) {
    if (countSheetItem.id) {
      return this._countSheetItemDatabaseService.update(countSheetItem);
    }
    return this._countSheetItemDatabaseService.add(countSheetItem);
  }

  getCountSheetItems(auditId, options?) {
    options = options || {};
    let params = new HttpParams().set('audit_id', auditId);
    if (options.count_sheet_id) {
      params = params.set('count_sheet_id', options.count_sheet_id);
    }
    if (options.count_sheet_client_id) {
      params = params.set('count_sheet_client_id', options.count_sheet_client_id);
    }
    if (options.only_kitted) {
      params = params.set('only_kitted', 'true');
    }
    if (options.only_not_kitted) {
      params = params.set('only_not_kitted', 'true');
    }
    return this.http.get<any>(`${environment.serverPath}${urls.countSheetItem}`, { params });
  }

  deleteCountSheetItem(countSheetId, countSheetItemId: number) {
    const params = new HttpParams()
      .set('count_sheet_item[id]', countSheetItemId.toString())
      .set('count_sheet_item[count_sheet_id]', countSheetId.toString());
    return this.http.delete(`${environment.serverPath}${urls.countSheetItem}`, { params });
  }

  updateSingleCountSheetItem(data: IcountsheetItemData, options?: object) {
    return this.http.post(environment.serverPath + urls.countSheetItem, {
      count_sheet_item: {
        count_sheet_item_id: data.dbId,
        count_sheet_id: data.data.count_sheet_id,
        item_id: data.data.item_id,
        reference: data.data.reference,
        lot_number: data.data.lot_number,
        quantity: data.data.quantity,
        expiration_date: data.data.expiration_date,
        serial_number: data.data.serial,
        client_id: data.id,
        checked: data.data.checked,
        manually_entered: data.data.manually_entered,
        hide: data.data.hide
      },
      add_to_quantity: options && options['add_to_quantity'],
      updating_quantity: options && options['updating_quantity']
    });
  }

  updateCountSheetItemQuantity(dbId, newQuantity) {
    const params = new HttpParams()
      .set('count_sheet_item[quantity]', newQuantity)
      .set('count_sheet_item[checked]', 'true');
    const path = `${environment.serverPath}${urls.countSheetItem}/${dbId}?${params.toString()}`;
    return this.http.put<any>(path, {});
  }

  uncheckCountSheetItem(dbId) {
    const params = new HttpParams()
      .set('count_sheet_item[quantity]', '0')
      .set('count_sheet_item[checked]', 'false');
    return this.http.put<any>(`${environment.serverPath}${urls.countSheetItem}/${dbId}?${params.toString()}`, {});
  }

  sendEmailToSupport(data) {
    return this.http.post(environment.serverPath + urls.countSheetItemError, {
      count_sheet_item: data
    });
  }
}
