import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { WarehouseShortagesDataSource } from './warehouse-shortages-datasource';

@Component({
  selector: 'app-warehouse-shortages',
  templateUrl: './warehouse-shortages.component.html',
  styleUrls: ['./warehouse-shortages.component.css']
})
export class WarehouseShortagesComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() shortages;
  @Input() filters;
  @Output() onExportToExcel: EventEmitter<any> = new EventEmitter<any>();
  dataSource: WarehouseShortagesDataSource;


  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['reference','description', 'kit', 'kit_lot_number', 'lot', 'serial_number', 'actual'];

  ngOnInit() {
    this.processData(this.shortages);
    this.dataSource = new WarehouseShortagesDataSource(this.paginator, this.sort, this.shortages, this.filters);
  }

  ngOnDestroy(): void {
    
  }

  processData(shortageItems){
    shortageItems.forEach(item => {
     if(item.kit_instance){
       item['kit_reference'] = item.kit_instance.item.reference;
       item['kit_lot_number'] = item.kit_instance.lot_number ? item.kit_instance.lot_number : '' ;
     } else {
      item['kit_reference'] = '';
      item['kit_lot_number'] = '';
     }
    });
  }

  getSerial(serial_number) {
    if(serial_number){
      return serial_number;
    }
    else{
      return '-'
    }
  }

  exportToExcel() {
    this.onExportToExcel.emit(this.dataSource.filteredData());
  }

  getLot(lot_number) {
    if(lot_number){
      return lot_number;
    }
    else{
      return '-'
    }
  }

  applyFilter(){
    this.dataSource = new WarehouseShortagesDataSource(this.paginator, this.sort, this.shortages, this.filters);
  }
}
