import { Injectable } from '@angular/core';
import { merge, of, fromEvent } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OnlineService {
  goOfflineEvent: EventEmitter<any>;
  testingOfflineKey: string = "TESTING_OFFLINE";
  testingOffline:boolean =  false;
  isOnline():boolean{
    return navigator.onLine;
  }

  isOnlineSubscription(){
      return merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    )
  }

  getTestOfflineBool(){
    let testingOffline = JSON.parse(localStorage.getItem(this.testingOfflineKey));
    return testingOffline;
  }
  
  setTestOffline(){
    if(this.isOnline()){
      this.testingOffline = !this.getTestOfflineBool();
      localStorage.setItem(this.testingOfflineKey, JSON.stringify(this.testingOffline));
      if(this.testingOffline){
        this.goOfflineEvent.emit(true);
      }else{
        this.goOfflineEvent.emit(false);
      }
    }
  }
  constructor() { 
    this.goOfflineEvent = new EventEmitter();
    this.testingOffline = JSON.parse(localStorage.getItem(this.testingOfflineKey));
    if(!this.testingOffline){
      this.testingOffline = false;
      localStorage.setItem(this.testingOfflineKey, JSON.stringify(this.testingOffline));
    }
  }
}
