import { urls } from "./../helpers/urls";
import { environment } from "./../../environments/environment";
import { HttpClient, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AgencyDashboardService = /** @class */ (function () {
    function AgencyDashboardService(_http) {
        this._http = _http;
    }
    AgencyDashboardService.prototype.getAgencyDashboardData = function (audit_id) {
        var params = new HttpParams().set('audit_id', audit_id.toString());
        var path = environment.serverPath + urls.getAgencyDashboardData;
        return this._http.get(path, { params: params });
    };
    AgencyDashboardService.ngInjectableDef = i0.defineInjectable({ factory: function AgencyDashboardService_Factory() { return new AgencyDashboardService(i0.inject(i1.HttpClient)); }, token: AgencyDashboardService, providedIn: "root" });
    return AgencyDashboardService;
}());
export { AgencyDashboardService };
