import * as i0 from "@angular/core";
var FileOptionUtils = /** @class */ (function () {
    function FileOptionUtils() {
        this.FILE_ICONS = {
            image: "insert_photo",
            any: "insert_drive_file",
            pdf: "picture_as_pdf",
            text: "description"
        };
        this.START_DIRECTORIES = {
            desktop: 'desktop',
            documents: 'documents',
            downloads: 'downloads',
            music: 'music',
            pictures: 'pictures',
            videos: 'videos'
        };
        this.IMAGE_EXTENSIONS = ['.png', '.gif', '.jpeg', '.jpg', '.tif', '.tiff'];
        this.APPLICATION_EXTENSIONS = ['.pdf', '.doc', '.xls', '.xlsx', '.xml'];
        this.PDF_EXTENSIONS = ['.pdf'];
        this.TEXT_EXTENSIONS = ['.txt'];
        // types
        this.IMAGE_TYPE = {
            description: 'Images',
            accept: {
                'image/*': this.IMAGE_EXTENSIONS
            }
        };
        this.PDF_TYPE = {
            description: 'PDF',
            accept: {
                'application/pdf': this.APPLICATION_EXTENSIONS
            }
        };
        this.TEXT_TYPE = {
            description: 'Text Files',
            accept: {
                'text/*': this.TEXT_EXTENSIONS
            }
        };
        this.IMAGE_FILE = {
            types: [this.IMAGE_TYPE]
        };
        this.FILE_SYSTEM_HANDLE_KINDS = {
            file: 'file',
            directory: 'directory'
        };
        this.TEXT_FILE = {
            types: [
                {
                    description: 'Text Files',
                    accept: {
                        'text/plain': this.TEXT_EXTENSIONS
                    }
                }
            ]
        };
        this.PDF_FILE = {
            types: [
                {
                    description: 'PDF Files',
                    accept: {
                        'image/*': this.APPLICATION_EXTENSIONS
                    }
                }
            ]
        };
    }
    FileOptionUtils.prototype.textFileOption = function (multiple) {
        if (multiple === void 0) { multiple = false; }
        var opt = this.TEXT_FILE;
        opt.multiple = multiple;
        return opt;
    };
    FileOptionUtils.prototype.imageFileOption = function (multiple) {
        if (multiple === void 0) { multiple = false; }
        var opt = this.IMAGE_FILE;
        opt.multiple = multiple;
        return opt;
    };
    FileOptionUtils.prototype.pdfFileOption = function (multiple) {
        if (multiple === void 0) { multiple = false; }
        var opt = this.PDF_FILE;
        opt.multiple = multiple;
        return opt;
    };
    FileOptionUtils.prototype.getExtension = function (handle) {
        var name = handle.name;
        var pos = name.lastIndexOf('.');
        var ext = name.slice(pos);
        return ext;
    };
    FileOptionUtils.prototype.getExtensionFromName = function (name) {
        var pos = name.lastIndexOf('.');
        var ext = name.slice(pos);
        return ext;
    };
    FileOptionUtils.prototype.isImage = function (extension) {
        return this.IMAGE_EXTENSIONS.includes(extension);
    };
    FileOptionUtils.prototype.isPDF = function (extension) {
        return this.PDF_EXTENSIONS.includes(extension);
    };
    FileOptionUtils.prototype.isTextFile = function (extension) {
        return this.TEXT_EXTENSIONS.includes(extension);
    };
    FileOptionUtils.prototype.directoryOption = function (dirOpts) {
        if (dirOpts === void 0) { dirOpts = {}; }
        return {
            directoryName: dirOpts.name,
            createDirectory: dirOpts.create
        };
    };
    FileOptionUtils.prototype.fileOption = function (fileOps) {
        if (fileOps === void 0) { fileOps = {}; }
        return {
            filename: fileOps.name,
            createFile: fileOps.create
        };
    };
    FileOptionUtils.prototype.auditOptions = function () {
        return {
            multiple: false,
            types: [
                {
                    description: 'Accepted Types',
                    accept: {
                        'application/*': this.APPLICATION_EXTENSIONS,
                        'image/*': this.IMAGE_EXTENSIONS,
                        'text/*': this.TEXT_EXTENSIONS
                    }
                }
            ]
        };
    };
    FileOptionUtils.prototype.getIcon = function (filename) {
        var extension = this.getExtensionFromName(filename);
        if (this.isImage(extension)) {
            return this.FILE_ICONS.image;
        }
        else if (this.isPDF(extension)) {
            return this.FILE_ICONS.pdf;
        }
        else if (this.isTextFile(extension)) {
            return this.FILE_ICONS.text;
        }
        else {
            return this.FILE_ICONS.any;
        }
    };
    FileOptionUtils.prototype.isSupported = function (filename) {
        var extension = this.getExtensionFromName(filename);
        var supportedExtensions = this.IMAGE_EXTENSIONS.concat(this.APPLICATION_EXTENSIONS).concat(this.PDF_EXTENSIONS).concat(this.TEXT_EXTENSIONS);
        return supportedExtensions.includes(extension);
    };
    FileOptionUtils.ngInjectableDef = i0.defineInjectable({ factory: function FileOptionUtils_Factory() { return new FileOptionUtils(); }, token: FileOptionUtils, providedIn: "root" });
    return FileOptionUtils;
}());
export { FileOptionUtils };
