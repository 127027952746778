import { Injectable } from '@angular/core';
import { ItemCombinationService } from 'src/app/services/item-combination.service';
import { ItemCombinationRecallAlertService } from 'src/app/services/item-combination-recall-alert.service';
import { ItemCombinationRecallAlertExceptionService } from 'src/app/services/item-combination-recall-alert-exception.service';
import { of, zip } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecallOracleService {

  cache = {};
  itemCombinations: any[];
  itemCombinationRecallAlerts: any[];
  itemCombinationRecallAlertExceptions: any[];
  itemCombinationRecallAlertActiveStatus = 0;
  itemCombinationRecallAlertClosedStatus = 1;

  constructor(
    private _itemCombinationService: ItemCombinationService,
    private _itemCombinationRecallAlertService: ItemCombinationRecallAlertService,
    private _itemCombinationRecallAlertExceptionService: ItemCombinationRecallAlertExceptionService
  ) { }

  id(itemId, lot, serial) {
    return (itemId.toString() + "_" + lot.toString() + "_" + serial.toString()).toLowerCase();
  }

  isItemCombinationRecalled(itemCombination) {
    var that = this;
    var itemCombinationRecallAlertMatches = that.itemCombinationRecallAlerts.filter(icra => {
      return icra.item_combination_id == itemCombination.id;
    });
    var itemCombinationRecallAlertExceptionMatches = itemCombinationRecallAlertMatches.map(icra => {
      return that.itemCombinationRecallAlertExceptions.find(icrae => {
        return icrae.item_combination_recall_alert_id == icra.id;
      });
    }).filter(n => n);
    return !!itemCombinationRecallAlertMatches.find(icra => {
      var isActive = [that.itemCombinationRecallAlertActiveStatus, that.itemCombinationRecallAlertClosedStatus].indexOf(icra.status_id) != -1;
      var hasException = !!itemCombinationRecallAlertExceptionMatches.find(icrae => {
        return icrae.item_combination_recall_alert_id == icra.id;
      });
      return isActive && !hasException;
    });
  }

  getItemCombinations(alreadyCached=null) {
    if (alreadyCached) {
      return of(alreadyCached);
    }
    return this._itemCombinationService.get();
  }

  getItemCombinationRecallAlerts(alreadyCached=null) {
    if (alreadyCached) {
      return of(alreadyCached);
    }
    return this._itemCombinationRecallAlertService.get();
  }

  getItemCombinationRecallAlertExceptions(auditId, alreadyCached=null) {
    if (alreadyCached) {
      return of(alreadyCached);
    }
    return this._itemCombinationRecallAlertExceptionService.get(auditId)
  }

  prime(auditId, options: any={}) {
    var that = this;
    that.cache = {};
    return new Promise((resolve, reject) => {
      zip(
        that.getItemCombinations(options.itemCombinations),
        that.getItemCombinationRecallAlerts(options.itemCombinationRecallAlerts),
        that.getItemCombinationRecallAlertExceptions(auditId, options.itemCombinationRecallAlertExceptions)
      ).subscribe((data: any[]) => {
  
        //faster if we only keep item combination recall alerts which are active or closed, filter to just those for performance
        that.itemCombinationRecallAlerts = data[1].filter(icra => {
          return [that.itemCombinationRecallAlertActiveStatus, that.itemCombinationRecallAlertClosedStatus].indexOf(icra.status_id) != -1;
        });
  
        //faster searching if the array is smaller, recalled oracle doesnt care about item combinations which we know arent recalled
        var itemCombinationsToKeep = this.itemCombinationRecallAlerts.map(icra => {
          return icra.item_combination_id;
        });
        that.itemCombinations = data[0].filter(cs => {
          return itemCombinationsToKeep.indexOf(cs.id) != -1;
        });
  
        that.itemCombinationRecallAlertExceptions = data[2];
        resolve(true);
      });
    });
  }

  _get(itemId, lot, serial) {
    var null_lot_and_serial_item_combination = this.itemCombinations.find(ic => {
      return ic.item_id == itemId && ic.lot_number.value == null && ic.serial_number.value == null;
    });
    if (null_lot_and_serial_item_combination && this.isItemCombinationRecalled(null_lot_and_serial_item_combination)) {
      return true;
    }

    var null_serial_item_combination = this.itemCombinations.find(ic => {
      return ic.item_id == itemId && ic.lot_number.value.toString().toLowerCase() == lot.toString().toLowerCase() && ic.serial_number.value == null;
    });
    if (null_serial_item_combination && this.isItemCombinationRecalled(null_serial_item_combination)) {
      return true;
    }

    if (itemId && lot && serial) {
      var item_combination = this.itemCombinations.find(ic => {
        return ic.item_id == itemId && ic.lot_number.value.toString().toLowerCase() == lot.toString().toLowerCase() && ic.serial_number.value.toString().toLowerCase() == serial.toString().toLowerCase();
      });
      if (item_combination && this.isItemCombinationRecalled(item_combination)) {
        return true;
      }
    }
    else if (itemId && lot) {
      var lotLevelItemCombinationMatches = this.itemCombinations.filter(ic => {
        return ic.item_id == itemId && ic.lot_number.value.toString().toLowerCase() == lot.toString().toLowerCase();
      });
      return !!lotLevelItemCombinationMatches.find(ic => {
        return this.isItemCombinationRecalled(ic);
      });
    }
    else {
      var itemLevelItemCombinationMatches = this.itemCombinations.filter(ic => {
        return ic.item_id == itemId;
      });
      return !!itemLevelItemCombinationMatches.find(ic => {
        return this.isItemCombinationRecalled(ic);
      });
    }
    return false;
  }

  get(itemId, lot, serial) {
    lot = lot || '';
    serial = serial || '';
    var key = this.id(itemId, lot, serial);
    if (this.cache[key] != undefined) {
      return this.cache[key];
    }
    this.cache[key] = this._get(itemId, lot, serial);
    return this.cache[key];
  }

}
