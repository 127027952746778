import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { urls } from "../helpers/urls";

@Injectable({
  providedIn: 'root'
})
export class SalesOrderInventoryImportService {

  constructor(private http: HttpClient) { }

  upload(formData: FormData){
    return this.http.post<any>(`${environment.serverPath}${urls.unconfirmedSalesOrderInventory}/import`, formData);
  }

  getInventory(auditId) {
    return this.http.get<any>(`${environment.serverPath}${urls.unconfirmedSalesOrderInventory}?audit_id=${auditId}`);
  }
}
