import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { OnlineService } from './../../services/online.service';
import { UserDataHelper } from '../../helpers/user-data-helper';
import { MatDialog } from '@angular/material';
import { BatchValidationImportComponent } from '../batch-validation-import/batch-validation-import.component';
import { LotPoolManagedItemsImportComponent } from '../lot-pool-managed-items-import/lot-pool-managed-items-import.component';
import { OfflineDataSynchronizerService } from 'src/app/services/offline-data-synchronizer.service';
import { DatabaseService } from './../../services/database.service';
import { AuditService } from 'src/app/services/audit.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AudioPlayerService } from 'src/app/services/audio-player.service';
import { UpdateService } from 'src/app/services/update.service';
import { environment } from 'src/environments/environment';
import { zip } from 'rxjs';
import { OktaConfigService } from 'src/app/services/okta-config.service';
import { CONSTANTS } from 'src/app/helpers/constants';
import { AccessTokenService } from 'src/app/services/access-token.service';

declare var require: any;
const version = require( './../../../assets/json/version.json');
const build = require( './../../../assets/json/build.json');

@Component({
  selector: 'app-audit-material-nav',
  templateUrl: './audit-material-nav.component.html',
  styleUrls: ['./audit-material-nav.component.css']
})
export class AuditMaterialNavComponent implements OnInit, OnDestroy {
  connected = false;
  currentCountSheetAlertSetting: string;
  auditVersion = '';
  updateAvailable = false;
  unsynchronizedData = {};
  unsynchronizedDataCount = 0;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );
  userData: any = {};
  userDataHelper: UserDataHelper = new UserDataHelper();
  updateSubscription: Subscription;
  unsynchronizedDataSubscription: Subscription;
  isOnlineSubscription: Subscription;
  isInternalKey: string = "LOGIN_IS_INTERNAL"

  constructor(
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private _authService: AuthService,
    private _router: Router,
    private _onlineService: OnlineService,
    private _offlineDataSynchronizer: OfflineDataSynchronizerService,
    private _databaseService: DatabaseService,
    private _auditService: AuditService,
    private _audioPlayerService: AudioPlayerService,
    private _updateService: UpdateService,
    private _oktaConfigService: OktaConfigService,
    private _accessTokenService: AccessTokenService
  ) {
    this.userData = this.userDataHelper.getUserData();
    this.currentCountSheetAlertSetting = localStorage.getItem('currentCountSheetAlertSetting') || '';
    this.updateAvailable = this._updateService.updateIsAvailable();
    this.auditVersion = build ? version.version + '.' + build.build : version.version;
  }

  ngOnInit() {
    const that = this;

    if (environment.updateCheckEnabled === true) {
      this.updateSubscription = this._updateService.updateAvailableEmitter.subscribe(result => {
        this.updateAvailable = true;
      });
    }

    this.isOnlineSubscription = this._onlineService.isOnlineSubscription().subscribe(isOnline => {
      this.connected = isOnline && !this._onlineService.testingOffline;
    });

    this.unsynchronizedDataSubscription = this._databaseService.unsynchronizedDataCountEmitter.subscribe(data => {
      const collection = data[0];
      const count = data[1];
      this.unsynchronizedData[collection] = count;
      this.updateTotalUnsynced();
    });

    zip(
      that._databaseService.getDataByIndex('count_sheets', 'isSynchronized', 0),
      that._databaseService.getDataByIndex('count_sheet_items', 'isSynchronized', 0),
      that._databaseService.getDataByIndex('attachments', 'synchronized', 0)
    ).subscribe((data: any[]) => {
      this.unsynchronizedData['count_sheets'] = data[0].length;
      this.unsynchronizedData['count_sheet_items'] = data[1].length;
      this.unsynchronizedData['attachments'] = data[2].length;
      this.updateTotalUnsynced();
    });
  }

  ngOnDestroy() {

    //Clean up the subscriptions we make
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }

    if (this.unsynchronizedDataSubscription) {
      this.unsynchronizedDataSubscription.unsubscribe();
    }

    if (this.isOnlineSubscription) {
      this.isOnlineSubscription.unsubscribe();
    }
  }

  updateTotalUnsynced() {
    let total = 0;
    const that = this;
    Object.keys(this.unsynchronizedData).forEach(function(key) {
      total = total + that.unsynchronizedData[key];
    });
    this.unsynchronizedDataCount = total;
  }

  logout() {
    this._oktaConfigService.revokeToken().subscribe(() => { 
      this._accessTokenService.clearTokenData();
      this._router.navigate([CONSTANTS.routes.LOGIN]);
    });
  }

  openBatchValidationDialog() {
    const dialogRef = this.dialog.open(BatchValidationImportComponent, {
      width: '40%',
      data: {}
    });
  }

  openLotPoolManagedItemImportDialog() {
    const dialogRef = this.dialog.open(LotPoolManagedItemsImportComponent, {
      width: '40%',
      data: {}
    });
  }

  testOffline() {
    this._onlineService.setTestOffline();
    this.connected = this._onlineService.isOnline() && !this._onlineService.testingOffline;
    if (this.connected) {
      this._offlineDataSynchronizer.run();
    }
  }

  clearLocalStorage() {
    localStorage.clear();
    if (window.caches) {
      caches.keys().then(cs => cs.forEach(c => caches.delete(c)));
    }
    zip(
      this._databaseService.clear('count_sheets'),
      this._databaseService.clear('count_sheet_items'),
      this._databaseService.clear('audit_kit_instances'),
      this._databaseService.clear('user_actions'),
      this._databaseService.clear('audits')
    ).subscribe(_ => {
      location.reload();
    })
  }

  openClearCacheConfirmationDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '20%',
      data: 'Are you sure you want to clear your cache?',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.clearLocalStorage();
      }
    });
  }

  manuallySyncData() {
    this._offlineDataSynchronizer.run();
  }

  toggleMute(setting: string) {
    this.currentCountSheetAlertSetting = setting;
    this._audioPlayerService.changeAudioSetting(this.currentCountSheetAlertSetting);
  }

  openReloadConfirmationDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '20%',
      data: 'Are you sure you want to update right now?',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._updateService.ActivateUpdate();
      }
    });
  }
}
