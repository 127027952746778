import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from "../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var KitInstanceService = /** @class */ (function () {
    function KitInstanceService(http) {
        this.http = http;
    }
    KitInstanceService.prototype.getKitInstanceByUUID = function (uuid) {
        var params = new HttpParams().set('uuid', uuid);
        return this.http.get(environment.serverPath + "api/kit_instances", { params: params });
    };
    KitInstanceService.prototype.getKitInstanceById = function (id) {
        return this.http.get(environment.serverPath + "api/kit_instances/" + id);
    };
    KitInstanceService.ngInjectableDef = i0.defineInjectable({ factory: function KitInstanceService_Factory() { return new KitInstanceService(i0.inject(i1.HttpClient)); }, token: KitInstanceService, providedIn: "root" });
    return KitInstanceService;
}());
export { KitInstanceService };
