export const CONSTANTS = {
    http: {
        methods: {
            POST: "POST",
            GET: "GET",
            PUT: "PUT",
            DELETE: "DELETE"
        },
        headers: {
            contentType:
            {
                URL_ENCODED: "application/x-www-form-urlencoded; charset=UTF-8"
            },
            SOURCE_APP: "audit"
        },
        headerLabel: {
            CONTENT_TYPE: "Content-Type",
            AUTHORIZATION: "Authorization",
            BEARER: "Bearer ",
            TENANT: "tenant",
            SOURCE_APP: "source-app"
        }
    },
    localStorageKeys: {
        ACCESS_TOKEN_KEY: "ACCESS_TOKEN_DATA",
        PERMISSIONS: "permissions",
        USER_DATA: "USER_DATA",
        LOGIN_IS_INTERNAL: "LOGIN_IS_INTERNAL",
        OKTA_CONFIG_DATA: "OKTA_CONFIG_DATA",
        VERIFIER: "VERIFIER"
    },
    messages: {
        NO_ACCESS_TOKEN: "NO ACCESS TOKEN",
        MUST_BE_ONLINE: "You must be online to login"
    },
    routes: {
        LOGIN: 'login',
        AUDITS: 'audit'
    },
    tenants :{
        INTERNAL: "internal",
        EXTERNAL: "external"
    }
}