import { OnlineService } from './online.service';
import { CountSheetDatabaseService } from './../services/count-sheet-database.service';
import { CountSheetItemDatabaseService } from './../services/count-sheet-item-database.service';
import { AttachmentDatabaseService } from './../services/attachment-database.service';
import { FileDatabaseService } from './../services/file-database.service';
import { AuditKitInstanceDatabaseService } from './../services/audit-kit-instance-database.service';
import { CountSheetService } from './../services/count-sheet.service';
import { CountSheetItemService } from './../services/count-sheet-item.service';
import { AuditKitInstanceService } from './../services/audit-kit-instance.service';
import { CountSheetAttachmentService } from './../services/count-sheet-attachment.service';
import * as moment from 'moment';
import { EventEmitter } from '@angular/core';
import { AuthService } from './auth.service';
import { zip } from 'rxjs';
import { TrackLoadingService } from './track-loading.service';
import { FileSystemService } from './../services/file-system.service';
import * as i0 from "@angular/core";
import * as i1 from "./online.service";
import * as i2 from "./count-sheet-database.service";
import * as i3 from "./count-sheet-item-database.service";
import * as i4 from "./audit-kit-instance-database.service";
import * as i5 from "./count-sheet.service";
import * as i6 from "./count-sheet-item.service";
import * as i7 from "./audit-kit-instance.service";
import * as i8 from "./auth.service";
import * as i9 from "./track-loading.service";
import * as i10 from "./attachment-database.service";
import * as i11 from "./file-database.service";
import * as i12 from "./file-system.service";
import * as i13 from "./count-sheet-attachment.service";
var OfflineDataSynchronizerService = /** @class */ (function () {
    function OfflineDataSynchronizerService(_onlineService, _countSheetDatabaseService, _countSheetItemDatabaseService, _auditKitInstanceDatabaseService, _countSheetService, _countSheetItemService, _auditKitInstanceService, _authService, _trackLoading, _attachmentDatabaseService, _fileDatabaseService, _fileSystemService, _countSheetAttachmentService) {
        this._onlineService = _onlineService;
        this._countSheetDatabaseService = _countSheetDatabaseService;
        this._countSheetItemDatabaseService = _countSheetItemDatabaseService;
        this._auditKitInstanceDatabaseService = _auditKitInstanceDatabaseService;
        this._countSheetService = _countSheetService;
        this._countSheetItemService = _countSheetItemService;
        this._auditKitInstanceService = _auditKitInstanceService;
        this._authService = _authService;
        this._trackLoading = _trackLoading;
        this._attachmentDatabaseService = _attachmentDatabaseService;
        this._fileDatabaseService = _fileDatabaseService;
        this._fileSystemService = _fileSystemService;
        this._countSheetAttachmentService = _countSheetAttachmentService;
        this.doneProcessing = true;
        var that = this;
        that.allSynchronizedEvent = new EventEmitter();
        that.loadingErrorEvent = new EventEmitter();
        that.savingErrorEvent = new EventEmitter();
        that._onlineService.isOnlineSubscription().subscribe(function (online) {
            that.needsSyncing().then(function (needed) {
                if (that.doneProcessing && needed && online && that._authService.isLoggedIn && !that._onlineService.getTestOfflineBool()) {
                    that.run();
                }
            });
        });
        that._authService.authenticatedEvent.subscribe(function (isLoggedIn) {
            that.needsSyncing().then(function (needed) {
                if (that.doneProcessing && needed && that._onlineService.isOnline() && that._authService.isLoggedIn && !that._onlineService.getTestOfflineBool()) {
                    that.run();
                }
            });
        });
    }
    // count sheets can only be created or updated
    // count sheet items can be created, updated, or destroyed
    OfflineDataSynchronizerService.prototype.countSheetData = function () {
        var that = this;
        var promise = new Promise(function (resolve, reject) {
            zip(that._countSheetDatabaseService.getDataByIndex('isSynchronized', 0), that._countSheetItemDatabaseService.getDataByIndex('isSynchronized', 0), that._attachmentDatabaseService.getDataByIndex('synchronized', 0)).subscribe(function (data) {
                //we want archived count sheets to sync first otherwise we get an incorrect match
                var countSheets = data[0].sort(function (a, b) {
                    if (a.data.archived && !b.data.archived) {
                        return -1;
                    }
                    if (!a.data.archived && b.data.archived) {
                        return 1;
                    }
                    return 0;
                }).map(function (cs) {
                    var countSheet = new Object({
                        audit_id: cs.data.audit_id,
                        warehouse_id: cs.data.warehouse_id,
                        audit_location_id: cs.data.audit_location_id,
                        audit_kit_instance_id: cs.data.audit_kit_instance_id,
                        area: cs.data.area,
                        count_sheet_status: cs.data.count_sheet_status,
                        kit_id: cs.data.kit_id,
                        id: cs.dbId,
                        client_id: cs.id,
                        archived: cs.data.archived,
                        show_expected_item_list: cs.data.show_expected_item_list,
                        skip_regrouping_callback: true,
                        count_sheet_items: [],
                        attachments: []
                    });
                    if (cs.data.counted_by) {
                        countSheet['counted_by_id'] = cs.data.counted_by.user_id;
                        countSheet['counted_time'] = moment(cs.data.counted_time).toDate();
                    }
                    if (cs.data.verified_by) {
                        countSheet['verified_by_id'] = cs.data.verified_by.user_id;
                        countSheet['verified_time'] = moment(cs.data.verified_time).toDate();
                    }
                    return countSheet;
                });
                data[1].forEach(function (csi) {
                    var matchingCountSheet = countSheets.find(function (cs) {
                        return cs['client_id'] && cs['client_id'] === csi.data.count_sheet_client_id;
                    });
                    if (!matchingCountSheet) {
                        matchingCountSheet = new Object({
                            id: csi.data.count_sheet_id,
                            client_id: csi.data.count_sheet_client_id,
                            count_sheet_items: [],
                            attachments: []
                        });
                        countSheets.push(matchingCountSheet);
                    }
                    matchingCountSheet['count_sheet_items'].push({
                        _destroy: csi._destroy,
                        item_id: csi.data.item_id,
                        reference: csi.data.reference,
                        lot_number: csi.data.lot_number,
                        serial_number: csi.data.serial,
                        quantity: csi.data.quantity,
                        id: csi.dbId,
                        client_id: csi.id,
                        checked: csi.data.checked,
                        manually_entered: csi.data.manually_entered,
                        expiration_date: csi.data.expiration_date,
                        hide: csi.data.hide,
                        rank: csi.data.rank
                    });
                });
                zip.apply(void 0, data[2].map(function (a) { return that._fileDatabaseService.get(a.local_file_id); }).concat(new Promise(function (r, a) { r(null); }))).subscribe(function (fileLocations) {
                    fileLocations = fileLocations.filter(function (fl) { return !!fl; });
                    zip.apply(void 0, fileLocations.map(function (fl) {
                        if (fl.file) {
                            return new Promise(function (r, a) { r(fl.file); });
                        }
                        else {
                            return that._fileSystemService.getFile(fl.handle);
                        }
                    }).concat(new Promise(function (r, a) { r(null); }))).subscribe(function (files) {
                        data[2].forEach(function (a) {
                            var matchingCountSheet = countSheets.find(function (cs) {
                                return cs['client_id'] && cs['client_id'] === a.count_sheet_client_id;
                            });
                            if (!matchingCountSheet) {
                                matchingCountSheet = new Object({
                                    id: a.count_sheet_id,
                                    client_id: a.count_sheet_client_id,
                                    count_sheet_items: [],
                                    attachments: []
                                });
                                countSheets.push(matchingCountSheet);
                            }
                            var fileLocation = fileLocations.find(function (f) { return (f || {}).local_file_id === a.local_file_id; });
                            var index = fileLocations.indexOf(fileLocation);
                            var file = files[index];
                            //attachments only need updating if they are new and exist or are being deleted
                            if (!a.id && file || (a.id && a.toRemove)) {
                                var attachment = new Object({
                                    local_file_id: a.local_file_id,
                                    audit_id: a.audit_id,
                                    count_sheet_client_id: a.count_sheet_client_id,
                                    attachment: file
                                });
                                if (a['id']) {
                                    attachment['id'] = a['id'];
                                }
                                if (a['toRemove']) {
                                    attachment['_destroy'] = a['toRemove'];
                                }
                                matchingCountSheet['attachments'].push(attachment);
                            }
                        });
                        resolve(countSheets);
                    });
                });
            });
        });
        return promise;
    };
    OfflineDataSynchronizerService.prototype.needsSyncing = function () {
        var _this = this;
        var that = this;
        this._trackLoading.startLoading('offline-data-synchronizer-need-syncing', 'Checking For Unsynced Data');
        var promise = new Promise(function (resolve, reject) {
            zip(that._countSheetDatabaseService.getDataByIndex('isSynchronized', 0), that._countSheetItemDatabaseService.getDataByIndex('isSynchronized', 0), that._attachmentDatabaseService.getDataByIndex('synchronized', 0)).subscribe(function (data) {
                _this._trackLoading.stopLoading('offline-data-synchronizer-need-syncing');
                var syncingNeeded = data[0].length > 0 || data[1].length > 0 || data[2].length > 0;
                resolve(syncingNeeded);
                return syncingNeeded;
            });
        });
        return promise;
    };
    OfflineDataSynchronizerService.prototype.cleanData = function (countSheets, cleanRun) {
        if (cleanRun) {
            countSheets.forEach(function (cs) {
                cs['count_sheet_items'] = cs['count_sheet_items'].filter(function (csi) { return (csi.reference || csi.item_id); });
            });
        }
        return countSheets;
    };
    OfflineDataSynchronizerService.prototype.run = function (cleanRun) {
        if (cleanRun === void 0) { cleanRun = false; }
        this.doneProcessing = false;
        var that = this;
        that._trackLoading.startLoading('offline-data-synchronizer-run', 'Synchronizing offline Data');
        this.countSheetData().then(function (countSheets) {
            if (countSheets.length > 0) {
                that._countSheetService.bulkUpdate(that.cleanData(countSheets, cleanRun), {}).subscribe(function (updatedCountSheets) {
                    that._trackLoading.stopLoading('offline-data-synchronizer-run');
                    that._trackLoading.startLoading('offline-data-synchronizer-reloading', 'Reloading Count Sheet Data');
                    //might want to make this only clear collections for the syncing audits?
                    zip(that._countSheetDatabaseService.clear(), that._countSheetItemDatabaseService.clear(), that._auditKitInstanceDatabaseService.clear(), that._attachmentDatabaseService.clear()).subscribe(function (_) {
                        var uniqueAuditIds = Array.from(new Set(updatedCountSheets.map(function (cs) { return cs.audit_id; }))).slice();
                        var dataFetches = [];
                        uniqueAuditIds.forEach(function (auditId) {
                            dataFetches.push(that._auditKitInstanceService.getAuditKitInstancesAndPopulateOfflineData(auditId));
                            dataFetches.push(that._countSheetService.getCountSheetsAndPopulateOfflineData(auditId));
                            dataFetches.push(that._countSheetItemService.getCountSheetItemsAndClearOfflineData(auditId));
                            dataFetches.push(that._countSheetAttachmentService.getAttachmentsAndPopulateOfflineData(auditId));
                        });
                        zip.apply(void 0, dataFetches).subscribe(function (data) {
                            that._trackLoading.stopLoading('offline-data-synchronizer-reloading');
                            that.allSynchronizedEvent.emit({ ids: uniqueAuditIds });
                            that.doneProcessing = true;
                        }, function (res) {
                            that._trackLoading.stopLoading('offline-data-synchronizer-reloading');
                            that.loadingErrorEvent.emit(countSheets);
                            that.doneProcessing = true;
                        });
                    });
                }, function (res) {
                    that._trackLoading.stopLoading('offline-data-synchronizer-run');
                    that.savingErrorEvent.emit(countSheets);
                    that.doneProcessing = true;
                });
            }
            else {
                that._trackLoading.stopLoading('offline-data-synchronizer-run');
                that.doneProcessing = true;
            }
        });
    };
    OfflineDataSynchronizerService.ngInjectableDef = i0.defineInjectable({ factory: function OfflineDataSynchronizerService_Factory() { return new OfflineDataSynchronizerService(i0.inject(i1.OnlineService), i0.inject(i2.CountSheetDatabaseService), i0.inject(i3.CountSheetItemDatabaseService), i0.inject(i4.AuditKitInstanceDatabaseService), i0.inject(i5.CountSheetService), i0.inject(i6.CountSheetItemService), i0.inject(i7.AuditKitInstanceService), i0.inject(i8.AuthService), i0.inject(i9.TrackLoadingService), i0.inject(i10.AttachmentDatabaseService), i0.inject(i11.FileDatabaseService), i0.inject(i12.FileSystemService), i0.inject(i13.CountSheetAttachmentService)); }, token: OfflineDataSynchronizerService, providedIn: "root" });
    return OfflineDataSynchronizerService;
}());
export { OfflineDataSynchronizerService };
