import { Component, OnInit, Inject } from '@angular/core';
import { IAuditDetail } from 'src/app/interfaces/iaudit-detail';
import { ISnackBarConfig } from 'src/app/interfaces/isnack-bar-config';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { IDialogData } from 'src/app/interfaces/idialog-data';
import { AuditService } from 'src/app/services/audit.service';
import { ExclusionService } from 'src/app/services/exclusion.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';
import { ExportToExcelService } from 'src/app/services/export-to-excel.service';

@Component({
  selector: 'app-exclusion-list-import',
  templateUrl: './exclusion-list-import.component.html',
  styleUrls: ['./exclusion-list-import.component.css']
})
export class ExclusionListImportComponent implements OnInit {

  selectedFile = null;
  pickedFile: any = null;
  loading = false;
  audit: IAuditDetail = {
    location_name : '',
    id: 0,
    warehouse_id: 0,
    distributorship: {},
    status: ''
  };
  snackBarConfig: ISnackBarConfig = {
    message : '',
    duration: 3000,
    success: false,
    snackBarClass : ''
  };

  constructor(
    private exclusionService: ExclusionService,
    public dialogRef: MatDialogRef<ExclusionListImportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData,
    private auditService: AuditService,
    private _router: Router,
    private _route: ActivatedRoute,
    public snackBar: MatSnackBar,
    private exportToExcelService: ExportToExcelService

  ) { }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
  }

  onUpload() {
    const fd = new FormData();
    fd.append('audit_id', this.data.audit_id.toString());
    fd.append('file', this.selectedFile, this.selectedFile.name);
    this.dialogRef.disableClose = true;
    this.loading = true;
    this.exclusionService.upload(+this.data.audit_id, fd).subscribe(res => {
      if (res.success) {
        this.selectedFile = null;
        this.pickedFile = null;
        this.snackBarConfig.message = 'File Uploaded Successfully';
        this.snackBarConfig.success = true;
        this.openSnackBar();
        this.loading = false;
        setTimeout(() => {
          this.dialogRef.close(res);
        }, 2000);
      } else {
        this.loading = false;
        this.snackBarConfig.message = res.message;
        this.snackBarConfig.success = false;
        this.openSnackBar();
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  ngOnInit() {
    this.loading = true;
    this.auditService.getAuditById(this.data.audit_id).subscribe(data => {
      this.audit = data;
      this.loading = false;
    });
  }

  goToExclusions() {
    this._router.navigate(['exclusion', this._route.snapshot.paramMap.get('id')]);
  }

  templateFrontEnd() {
    this.loading = true;
    this.exportToExcelService.exportAsExcelFile([{reference: ''}], 'exclusions', {});
    this.loading = false;
  }

  openSnackBar() {
    this.snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: this.snackBarConfig,
      duration: this.snackBarConfig.duration
    });
  }

}
