import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { SalesOrderInventoryImportService } from '../../services/sales-order-inventory-import.service';
import { MatPaginator, MatSort } from '@angular/material';
import { SalesOrderInventoryImportListDataSource } from './sales-order-inventory-import-list-datasource';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-sales-order-inventory-import-list',
  templateUrl: './sales-order-inventory-import-list.component.html',
  styleUrls: ['./sales-order-inventory-import-list.component.css']
})
export class SalesOrderInventoryImportListComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: SalesOrderInventoryImportListDataSource;
  private salesOrderInventory: Array<Object> = [];
  displayedColumns = ["warehouse", "reference", "lot_number", "quantity", "serial_number", "sales_order"];
  auditId;

  constructor(
    private salesOrderInventoryImportService: SalesOrderInventoryImportService,
    private route: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit() {
    this.auditId = this.route.snapshot.paramMap.get('id');
    this.getSalesOrderInventoryImport(this.auditId);
  }

  ngOnDestroy(): void {

  }

  getSalesOrderInventoryImport(auditId) {
    this.salesOrderInventoryImportService.getInventory(auditId).subscribe(data => {
      this.salesOrderInventory = data;
      this.dataSource = new SalesOrderInventoryImportListDataSource(this.paginator, this.sort, this.salesOrderInventory);
    });
  }

  goToAuditDashboard() {
    this._router.navigate(['audit_dashboard', this.auditId]);
  }

}
