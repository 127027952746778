<div class="mat-elevation-z8 data-table">
  <div class="col-md-12 save_row">
    <mat-icon ngbTooltip="Save as XLS" class="xls pull-right" (click)="exportToExcel()" placement="top">save_alt</mat-icon>
    <mat-paginator #paginator [pageIndex]="0" [pageSize]="50" [pageSizeOptions]="[25, 50, 100, 250]">
    </mat-paginator>
  </div>
  
  <table mat-table #table [dataSource]="dataSource" matSort aria-label="Elements">
    <!-- Reference Column -->
    <ng-container matColumnDef="reference">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Reference</th>
      <td mat-cell *matCellDef="let row">{{row.item.reference}}</td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Description</th>
      <td mat-cell *matCellDef="let row">{{row.item.description}}</td>
    </ng-container>

    <!-- Kit Column -->
    <ng-container matColumnDef="kit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Kit</th>
      <td mat-cell *matCellDef="let row">{{row.kit_instance?.item?.reference || "&mdash;"}}</td>
    </ng-container>

    <!-- Kit Lot Column -->
    <ng-container matColumnDef="kit_lot_number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Kit Lot</th>
      <td mat-cell *matCellDef="let row">{{row.kit_instance?.lot_number || "&mdash;"}}</td>
    </ng-container>

    <!-- Lot Number Column -->
    <ng-container matColumnDef="lot">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Lot Number</th>
      <td mat-cell *matCellDef="let row">{{getLot(row.lot_number)}}</td>
    </ng-container>

    <!-- Serial Number Column -->
    <ng-container matColumnDef="serial_number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Serial Number</th>
      <td mat-cell *matCellDef="let row">{{getSerial(row.serial_number)}}</td>
    </ng-container>

    <!-- Missing Amount Column -->
    <ng-container matColumnDef="actual">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header quantity-header">Shortage Amount</th>
      <td mat-cell *matCellDef="let row" class="shortage-v quantity-field">{{row.variance}}</td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</div>
