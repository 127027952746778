import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ItemService } from '../../services/item.service';
import { Observable } from 'rxjs';
import { ActivatedRoute } from "@angular/router";
import { Iitem } from '../../interfaces/iitem';
import { FormControl } from '@angular/forms';
import { map} from 'rxjs/operators';


@Component({
  selector: 'app-reference-field',
  templateUrl: './reference-field.component.html',
  styleUrls: ['./reference-field.component.css']
})
export class ReferenceFieldComponent implements OnInit, OnDestroy {
  referenceControl = new FormControl();
  @ViewChild('refInput') refInput: ElementRef;
  @Output() itemIsSerialTracked = new EventEmitter<any>();
  public options: Observable<Iitem[]>;
  filteredOptions: Iitem[];
  items: Iitem[]
  auditId: number;
  reference: string;

  constructor(
    private _itemService: ItemService,
    private route: ActivatedRoute
  ) { }

  ngOnDestroy(): void {
    this.refInput = null;
  }

  ngOnInit() {
    this.auditId = +this.route.snapshot.paramMap.get('audit_id');
    this._itemService.getItems().subscribe(items => {
      this.items = items;
      this.options = this.referenceControl.valueChanges.pipe(
        map(value => this._filter(value)
        )
      )
    })
  }

  private _filter(value: string): Iitem[] {
    this.reference = value;
    const filterValue = value.toLowerCase().replace('-', '');
    let length = filterValue.length;
    if (length > 2) {
      this.filteredOptions = this.items.filter((option) => {
        let ref = option.unpunctuated_reference.toLowerCase().startsWith(filterValue)
        return ref;
      });
      return this.filteredOptions;
    }
  }

  pickTheOneOption(event) {
    if (event.code === "Tab") {
      if (this.filteredOptions) {
        if (this.filteredOptions.length === 1) {
          this.reference = this.filteredOptions[0].reference;
          this.referenceControl.setValue(this.reference);
        }
      }
      if (this.reference){
        this.checkIfItemIsSerialTracked(this.reference);
      }
    }

  }

  checkIfItemIsSerialTracked(reference){
    if (this.items.filter(item => item.reference.replace('-','') == reference.replace('-','').toUpperCase() && item.is_serial_tracked).length == 0){
      this.itemIsSerialTracked.emit(true);
    }
    else {
      this.itemIsSerialTracked.emit(false);
    }
  }
}
