<p id="top"></p>
<div class="content">
  <div class="row">
    <div class="col-md-12">
      <button mat-raised-button class="back-to-dashboard" type="button" (click)="goToAuditDashboard()"
        ngbTooltip="Go To Dashboard" placement="right">
        <mat-icon>
          dashboard
        </mat-icon>
        Dashboard
      </button>
      <button mat-raised-button class="back-to-countsheets" type="button" (click)="goToCountSheets()"
        ngbTooltip="Go To Count Sheets" placement="right">
        <mat-icon>
          view_list
        </mat-icon>
        Count Sheets
      </button>
      <app-print-page class="edit"
        [countSheet]="dataSource.countSheet"
        [dataSource]="dataSource"
        [audit]="audit"
        [lotPoolManagedItems]="dataSource.lotPoolManagedItems"
        *ngIf="dataSource && dataSource.countSheet && dataSource.lotPoolManagedItems" #printer></app-print-page>

      <span class="edit pull-right">
        <button *ngIf="dataSource && dataSource.countSheet && dataSource.countSheet.data.attachments_count > 0" mat-raised-button class="edit-button" type="button" (click)="attachFile()" color="primary" matBadgeColor="warn" [matBadge]="dataSource.countSheet.data.attachments_count"
          ngbTooltip="Attach Files" placement="left">
          <mat-icon class="material-icons">attach_file</mat-icon>
          Attach Files
        </button>

        <button *ngIf="dataSource && dataSource.countSheet && dataSource.countSheet.data.attachments_count === 0" mat-raised-button class="edit-button" type="button" (click)="attachFile()" color="primary"
          ngbTooltip="Attach Files" placement="left">
          <mat-icon class="material-icons">attach_file</mat-icon>
          Attach Files
        </button>
      </span>

    </div>
  </div>

  <div class="row info" *ngIf="dataSource && dataSource.countSheet && kitInstance">
    <div class="col-2">
      <h1 class="header-title title-names">
        Site:
      </h1>
      <h1 class="header-title title-names">
        Reference:
      </h1>
      <h1 class="header-title title-names">
        Description:
      </h1>
      <h1 class="header-title title-names">
        Lot Number:
      </h1>
      <h1 class="header-title title-names">
        Current Warehouse:
      </h1>
      <h1 class="header-title title-names">
        Auditor(s):
      </h1>
    </div>
    <div class="col-5" style="white-space:wrap; overflow:display;">
      <h1 class="header-title">
        {{dataSource.countSheet.data.location_name.toUpperCase()}}
      </h1>
      <h1 class="header-title">
        {{dataSource.countSheet.data.reference}}
      </h1>
      <h1 class="header-title">
        {{ kitInstance.data.item_instance.description}}
      </h1>
      <h1 class="header-title">
        {{dataSource.countSheet.data.lot_number}}
      </h1>
      <h1 class="header-title">
        {{kitInstance.data.warehouse_name}}
      </h1>
      <h1 class="header-title">
        {{auditedBy}}
      </h1>
    </div>
    <div class="col-5 progress-div" *ngIf="statsComplete">
      <div class="row" *ngIf="dataSource.countSheet.data.show_expected_item_list">
        <div class="col-12">
          <div class="progress-wrapper col-md-6">
            <round-progress [current]="actualItemCount" [max]="expectedItemCount"
              [color]="getColor(actualItemCount,expectedItemCount)" [background]="'#eaeaea'" [radius]="150"
              [stroke]="15" [semicircle]="true" [rounded]="true" [clockwise]="true" [responsive]="true"
              [duration]="800" [animation]="'easeOutElastic'" [animationDelay]="0">
            </round-progress>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 current-value-semi" *ngIf="dataSource.countSheet.data.show_expected_item_list">
          <div class="current" ngbTooltip="Actual Items / Expected Items" placement="bottom">
            {{ actualItemCount }}/{{ expectedItemCount }}
          </div>
        </div>
        <div class="col-12 current-value" *ngIf="!dataSource.countSheet.data.show_expected_item_list">
          <div class="current" ngbTooltip="Actual Items" placement="bottom">
            <h1 class="current" style="margin-top: 60px;margin-right: 20%;text-align: center;">
              Items Counted: <span style="color:green;">{{actualItemCount}}</span>
            </h1>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-6"></div>
</div>

<div class="row" *ngIf="dataSource.countSheetItems" style="margin-top:5%">
  <div class="col-md-6 filters">
    <mat-form-field class="small_filter" *ngIf="showOrderFilter">
      <mat-select name="Order Type" [disabled]="viewOnly" placeholder="Order Type"
        [value]="dataSource.filters.orderType" (selectionChange)="OrderFilterChanged($event)">
        <mat-option value="all">
          All
        </mat-option>
        <mat-option value="consigned">
          Consigned
        </mat-option>
        <mat-option value="owned">
          Owned
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="small_filter">
      <mat-select name="Exclusions" [disabled]="viewOnly" placeholder="Exclusions"
        [value]="dataSource.filters.exclusions" (selectionChange)="exclusionChanged($event)">
        <mat-option value="hide">
          Hide
        </mat-option>
        <mat-option value="show">
          Show
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="large_filter" *ngxPermissionsOnly="['audit_administrator', 'auditor']">
      <mat-select [(value)]="dropdownWarehouseId" [disabled]="viewOnly" name="warehouse"
        placeholder="Actual Warehouse" (selectionChange)="warehouseChanged($event)">
        <mat-option class="option" *ngFor="let w of warehouses" [value]="w.account_id">
          {{w.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="large_filter" *ngxPermissionsOnly="['audit_administrator', 'auditor']">
      <mat-select [value]="dataSource.countSheet.data.kit_id" [disabled]="viewOnly" name="BOM" placeholder="BOM"
        (selectionChange)="bomChanged($event)">
        <mat-option *ngIf="kits && dataSource.countSheet" class="option">
          None
        </mat-option>
        <mat-option class="option" *ngFor="let k of kits" [value]="k.id">
          {{k.reference}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="small_filter" *ngxPermissionsOnly="['audit_administrator', 'auditor']">
      <mat-select *ngIf="dataSource && dataSource.countSheet" [disabled]="viewOnly"
        [value]="dataSource.countSheet.data.show_expected_item_list.toString()" placeholder="View"
        (selectionChange)="viewChanged($event)">
        <mat-option class="option" value="true">
          Kit
        </mat-option>
        <mat-option class="option" value="false">
          Blank
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="col-md-6" *ngxPermissionsOnly="['audit_administrator', 'auditor']">
    <div class="count-sheet-container-top" *ngIf="dataSource.countSheetItems && dataSource.countSheet">
      <button mat-button class="count-sheet-container-cancel-buttons" type="button" ngbTooltip="Archive"
        (click)="cancelCounting()" *ngIf="([3,4].indexOf(dataSource.countSheet.data.count_sheet_status) === -1 && dataSource.countSheet.data.created_by.user_id === userData.user_id) || (dataSource.countSheet.data.count_sheet_status !== 4 && permissions[0] === 'audit_administrator')">
        Archive
      </button>
      <button mat-button class="count-sheet-container-cancel-buttons" type="button" ngbTooltip="Cancel"
        (click)="clearLocal()" *ngIf="([3,4].indexOf(dataSource.countSheet.data.count_sheet_status) === -1 && dataSource.countSheet.data.created_by.user_id === userData.user_id) || (dataSource.countSheet.data.count_sheet_status !== 4 && permissions[0] === 'audit_administrator')">
        Cancel
      </button>
      <button mat-button class="count-sheet-container-buttons" type="button"
        ngbTooltip="Mark Count Sheet As Completed" (click)="printAndComplete()" *ngIf="[3,4].indexOf(dataSource.countSheet.data.count_sheet_status) === -1">
        Finish Counting
      </button>
      <button mat-button class="count-sheet-container-buttons" type="button"
        ngbTooltip="Mark everything visible as checked" (click)="checkedAllClicked()"
        *ngIf="!viewOnly && dataSource && dataSource.countSheet && dataSource.countSheet.data.show_expected_item_list">
        Check All
      </button>
      <button mat-button class="count-sheet-container-buttons" type="button" ngbTooltip="Delete All Items"
        (click)="deleteAll()" *ngIf="!viewOnly">
        Delete All
      </button>
      <button mat-button class="count-sheet-container-buttons" type="button" ngbTooltip="Export To Excel"
        (click)="exportCountsheetItems()" *ngIf="!viewOnly">
        Export
      </button>
      <button mat-button class="count-sheet-container-buttons" type="button"
        *ngxPermissionsOnly="['audit_administrator']" ngbTooltip="Import Counted Items"
        (click)="openCountSheetItemImportDialog()" [ngClass]="{'disabled': !isOnline, 'hidden': viewOnly}">
        Import
      </button>
    </div>
  </div>
</div>
<div class="clear_both"></div>

<div class="mat-elevation-z8 data-table" disabled *ngIf="dataSource">


  <virtual-scroller #scroll [items]="dataSource.data" *ngIf="dataSource.data">
  	<table class="mat-table">
  		<thead>
        <tr class="mat-header-row">
    			<th class="mat-header-cell"></th>
    			<th class="mat-header-cell">Reference</th>
          <th class="mat-header-cell">Description</th>
          <th class="mat-header-cell">Lot Number</th>
    			<th class="mat-header-cell">Serial Number</th>
    			<th class="mat-header-cell">Quantity</th>
    			<th class="mat-header-cell"></th>
    			<th class="mat-header-cell"></th>
    			<th class="mat-header-cell"></th>
        </tr>
  		</thead>
  		<tbody #container>
  			<tr class="mat-row" *ngFor="let element of scroll.viewPortItems" [ngClass]="{'red': element.data.verification_status == 'NEEDED', 'in_definition': element.data.in_definition, 'not-synced': anyUnsynched(element)}">
          <td class="mat-cell">
            <span class="tooltipWrapper">
              <mat-icon class="material-icons" ngbTooltip="Min. Order Quantity > 1" color="warn"
                *ngIf="isMinOrderQuantityGreaterThanOne(element)" placement="right">
                warning
              </mat-icon>
            </span>
            <span class="tooltipWrapper">
              <mat-icon ngbTooltip="Expired" placement="right" *ngIf="element.data.show_expired_warning"
                style="color:red">
                hourglass_empty</mat-icon>
            </span>
            <span class="tooltipWrapper">
              <mat-icon *ngIf="element.data.show_invalid_warning" ngbTooltip="Invalid Item/Lot/Serial Combination"
                placement="right" style="color:red">close</mat-icon>
            </span>
            <span class="tooltipWrapper">
              <mat-icon ngbTooltip="This Item/Lot/Serial combination has been recalled" placement="right"
                *ngIf="element.data.show_recalled_warning" color="warn">not_interested</mat-icon>
            </span>
            <span class="tooltipWrapper">
              <mat-icon ngbTooltip="Item is Excluded" placement="right" *ngIf="element.data.is_excluded"
                color="warn">
                highlight_off</mat-icon>
            </span>
            <span class="tooltipWrapper">
              <mat-icon ngbTooltip="Item is Lot Pool Managed" placement="right" *ngIf="isLotPoolManaged(element)">
                pool</mat-icon>
            </span>
            <span class="tooltipWrapper">
              <span ngbTooltip="Item Manually Entered" placement="right" *ngIf="element.data.manually_entered"
                class='manually-entered'>
                M</span>
            </span>
          </td>
    			<td class="mat-cell">
            <mat-form-field class="text-input-in-column" *ngIf="element.data.manually_entered">
              <input matInput tabindex="-1" [disabled]="viewOnly" [value]="element.data.reference" (change)="referenceChanged($event, element)">
            </mat-form-field>
            <span *ngIf="!element.data.manually_entered">
                {{element.data.reference}}
            </span>
          </td>
    			<td class="mat-cell">{{element.data.description}}</td>
    			<td class="mat-cell">
            <div *ngIf="element.count_sheet_items; else lot_number_not_lot_pool_managed"></div>
            <ng-template #lot_number_not_lot_pool_managed>
              <div *ngxPermissionsOnly="['audit_administrator', 'auditor']">
                <mat-form-field class="text-input-in-column">
                  <input [id]="'lot_number_' + element.id" matInput [disabled]="viewOnly" [value]="element.data.lot_number"
                    (change)="lotNumberChanged($event, element)">
                </mat-form-field>
              </div>
              <div *ngxPermissionsOnly="['audit_viewer']">
                {{element.data.lot_number || "&mdash;"}}
              </div>
            </ng-template>
          </td>
          <td class="mat-cell">
            <div *ngIf="element.count_sheet_items; else serial_number_not_lot_pool_managed"></div>
            <ng-template #serial_number_not_lot_pool_managed>
              <div *ngxPermissionsOnly="['audit_administrator', 'auditor']">
                <mat-form-field class="text-input-in-column" *ngIf="!viewOnly && element.data.is_serial_tracked">
                  <input [id]="'serial_number_' + element.id" matInput [value]="element.data.serial"
                    (change)="serialNumberChanged($event, element)">
                </mat-form-field>
                <span disabled="true" *ngIf="viewOnly || !element.data.is_serial_tracked">
                  {{ element.data.serial || "&mdash;"}}
                </span>
              </div>
              <div *ngxPermissionsOnly="['audit_viewer']">
                {{ element.data.serial || "&mdash;"}}
              </div>
            </ng-template>
          </td>
    			<td class="mat-cell">
            <div *ngIf="element.count_sheet_items; then quantity_lot_pool_managed else quantity_not_lot_pool_managed">
            </div>
            <ng-template #quantity_lot_pool_managed>
              <div *ngxPermissionsOnly="['audit_administrator', 'auditor']">
                <mat-form-field class="number-input-in-column">
                  <input [id]="'quantity_' + element.id" (keydown)="keyDown($event, element)" [disabled]="viewOnly" matInput type='number' min="0"
                    (click)="$event.target.select()"
                    [value]="lotPoolManagedQuantity(element)"
                    (change)="lotPoolManagedQuantityChanged($event, element)">
                </mat-form-field>
              </div>
              <div *ngxPermissionsOnly="['audit_viewer']">
                {{ lotPoolManagedQuantity(element) }}
              </div>
            </ng-template>
            <ng-template #quantity_not_lot_pool_managed>
              <div *ngxPermissionsOnly="['audit_administrator', 'auditor']">
                <mat-form-field class="number-input-in-column">
                  <input [id]="'quantity_' + element.id" (keydown)="keyDown($event, element)" [disabled]="viewOnly" matInput type='number' min="0"
                    (click)="$event.target.select()"
                    [value]="(element.dbId || element.id) ? element.data.quantity : element.data.expected_quantity"
                    (change)="quantityChanged($event, element)">
                </mat-form-field>
              </div>
              <div *ngxPermissionsOnly="['audit_viewer']">
                {{ (element.dbId || element.id) ? element.data.quantity : element.data.expected_quantity }}
              </div>
            </ng-template>
          </td>
    			<td class="mat-cell">
            <div *ngIf="element.count_sheet_items; else copy_line_not_lot_pool_managed"></div>
            <ng-template #copy_line_not_lot_pool_managed>
              <div *ngxPermissionsOnly="['audit_administrator', 'auditor']">
                <span class="tooltipWrapper">
                  <button tabindex="-1" ngbTooltip="Copy item" [disabled]="viewOnly" placement="left"
                    mat-icon-button (click)="copyCountSheetItem(element)">
                    <mat-icon>add_circle</mat-icon>
                  </button>
                </span>
              </div>
            </ng-template>
          </td>
    			<td class="mat-cell">
            <div *ngIf="element.count_sheet_items; then checked_lot_pool_managed else checked_not_lot_pool_managed"></div>
            <ng-template #checked_lot_pool_managed>
              <div *ngxPermissionsOnly="['audit_administrator', 'auditor']">
                <mat-checkbox [color]='checkboxColor' [disabled]="viewOnly"
                  (keydown)="keyDown($event, element)" [checked]="anyChecked(element)"
                  (change)="lotPoolManagedCheckedClicked($event, element)">
                </mat-checkbox>
              </div>
            </ng-template>
            <ng-template #checked_not_lot_pool_managed>
              <div *ngxPermissionsOnly="['audit_administrator', 'auditor']">
                <mat-checkbox [color]='checkboxColor' [disabled]="viewOnly" (keydown)="keyDown($event, element)"
                  [checked]="element.data.checked" (change)="checkedClicked($event, element)">
                </mat-checkbox>
              </div>
            </ng-template>
          </td>
    			<td class="mat-cell">
            <div *ngIf="element.count_sheet_items; then remove_lot_pool_managed else remove_not_lot_pool_managed"></div>
            <ng-template #remove_lot_pool_managed>
              <div *ngxPermissionsOnly="['audit_administrator', 'auditor']">
                <span class="tooltipWrapper">
                  <button tabindex="-1" [disabled]="viewOnly" ngbTooltip="Remove added item" placement="left"
                    *ngIf="element.data.added_to_kit" (click)="lotPoolManagedItemRemoveAddedItem(element)"
                    mat-icon-button color="danger">
                    <mat-icon>remove_circle</mat-icon>
                  </button>
                </span>
              </div>
            </ng-template>
            <ng-template #remove_not_lot_pool_managed>
              <div *ngxPermissionsOnly="['audit_administrator', 'auditor']">
                <span class="tooltipWrapper">
                  <button tabindex="-1" [disabled]="viewOnly" ngbTooltip="Remove added item" placement="left"
                    *ngIf="element.data.added_to_kit" (click)="removeAddedItem(element)" mat-icon-button
                    color="danger">
                    <mat-icon>remove_circle</mat-icon>
                  </button>
                </span>
              </div>
            </ng-template>
          </td>
  			</tr>
  		</tbody>
  	</table>
  </virtual-scroller>
  <mat-table style="display: none">
    <mat-header-row *matHeaderRowDef="[]"></mat-header-row>
  </mat-table>
  <div class="row" *ngxPermissionsOnly="['audit_administrator', 'auditor']">
    <div id="add-item-div" class="col-9" *ngIf="!viewOnly">
      <app-add-count-sheet-item
        [items]="items"
        [countSheet]="dataSource.countSheet"
        [itemSettings]="dataSource.itemSettings"
        [itemCombinations]="dataSource.itemCombinations"
        [redspotInventory]="dataSource.redspotQuantities"
        [countsheetHelper]="countSheetHelper"
        [countsheetItemHelper]="countSheetItemHelper"
        [posIntegerHelper]="positiveIntegerHelper"
        (countSheetItemUpdated)=updateCountSheetItems()
        [exclusions] ="dataSource.exclusions"
        [sapQuantities]="dataSource.sapQuantities"
        [lotPoolManagedItems]="dataSource.lotPoolManagedItems"
        [_recallOracleService]="_recallOracleService"
        [currentlyDisplayedCountSheetItems] = "dataSource.expectedKitCountSheetItems"
        *ngIf="!viewOnly && dataSource && dataSource.itemSettings && dataSource.itemCombinations && dataSource.countSheet && dataSource.redspotQuantities && dataSource.sapQuantities && dataSource.lotPoolManagedItems">
      </app-add-count-sheet-item>
    </div>
    <div *ngIf="!viewOnly" class="col-3 just-added-text">
      Total: {{actualItemCount}}
    </div>
  </div>
</div>

<div *ngxPermissionsOnly="['audit_administrator', 'auditor']">
  <div class="col-md-12 count-sheet-container" *ngIf="dataSource.countSheetItems && dataSource.countSheet">
    <button mat-button class="count-sheet-container-cancel-buttons" type="button" ngbTooltip="Cancel Counting"
      (click)="cancelCounting()" *ngIf="([3,4].indexOf(dataSource.countSheet.data.count_sheet_status) === -1 && dataSource.countSheet.data.created_by.user_id === userData.user_id) || (dataSource.countSheet.data.count_sheet_status !== 4 && permissions[0] === 'audit_administrator')">
      Cancel Counting
    </button>
    <button mat-button class="count-sheet-container-buttons" type="button" ngbTooltip="Mark Count Sheet As Completed"
      (click)="printAndComplete()" *ngIf="[3,4].indexOf(dataSource.countSheet.data.count_sheet_status) === -1">
      Finish Counting
    </button>
    <button mat-button class="count-sheet-container-buttons" type="button"
      ngbTooltip="Mark everything visible as checked" (click)="checkedAllClicked()"
      *ngIf="!viewOnly && dataSource && dataSource.countSheet && dataSource.countSheet.data.show_expected_item_list">
      Check All
    </button>
    <button mat-button class="count-sheet-container-buttons" type="button" ngbTooltip="Delete All Items"
      (click)="deleteAll()" *ngIf="!viewOnly">
      Delete All
    </button>
    <button mat-button class="count-sheet-container-buttons" type="button" ngbTooltip="Export To Excel"
      (click)="exportCountsheetItems()" *ngIf="!viewOnly">
      Export
    </button>
    <button mat-button class="count-sheet-container-buttons" type="button" ngbTooltip="Import Counted Items"
      (click)="openCountSheetItemImportDialog()" *ngxPermissionsOnly="['audit_administrator']"
      [ngClass]="{'disabled': !isOnline, 'hidden': viewOnly}">
      Import
    </button>
  </div>
</div>

<p id="bottom"></p>
