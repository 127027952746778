import { Injectable } from '@angular/core';
import { DatabaseService } from './database.service'
import { IkitInstance } from '../interfaces/ikit-instance';
import * as moment from 'moment';
import { CountSheetStatusHelper } from '../helpers/count-sheet-status-helper';
import { zip } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuditKitInstanceDatabaseService {

  storeName = "audit_kit_instances";

  constructor(private _databaseService: DatabaseService) {}

  new(data: any): IkitInstance {
    const kit: IkitInstance = {
      dbId: data.id,
      id: 0,
      isSynchronized: 1,
      data: {
        audit_id: data.audit_id,
        has_consigned_items: data.has_consigned_items,
        has_count_sheet: data.has_count_sheet,
        has_owned_items: data.has_owned_items,
        item_count: data.item_count,
        status: data.status,
        statusText: CountSheetStatusHelper['NAMES'][data.status],
        warehouse_id: data.warehouse_id,
        warehouse_name: data.warehouse.name,
        item_instance_id: data.item_instance_id,
        id: data.id,
        counted_time: data.counted_time
      }
    };

    if (data.user) {
      const user = {
        first_name: data.user.first_name,
        last_name: data.user.last_name,
        name: data.user.name,
        user_id: data.user.user_id
      };
      kit.data.counted_by = user;
    }

    if (data.item_instance) {
      const item_instance = {
        address: { name: data.item_instance.address ? data.item_instance.address.name : '' },
        reference: data.item_instance.item.reference,
        disposition_type_id: data.item_instance.disposition_type_id,
        description: data.item_instance.item.description,
        lot_number: data.item_instance.lot_number,
        lot_number_id: data.item_instance.lot_number_id,
        unpunctuated_reference: data.item_instance.item.unpunctuated_reference,
        root_item_id: data.item_instance.item.root_item_id,
        item_id: data.item_instance.item_id,
        item: {
          root_item: { reference: data.item_instance.item.root_item ? data.item_instance.item.root_item.reference : '' }
        },
        type: data.item_instance.item.kit_type_name
      };

      kit.data.item_instance = item_instance;
    }

    return kit;
  }

  get(id) {
    return this._databaseService.get(this.storeName, id);
  }

  bulkAdd(datas) {
    return this._databaseService.bulkAdd(this.storeName, datas);
  }

  add(data) {
    return this._databaseService.add(this.storeName, data);
  }

  update(data) {
    return this._databaseService.update(this.storeName, data);
  }

  clear() {
    return this._databaseService.clear(this.storeName);
  }

  deleteByIndex(index, value) {
    return this._databaseService.deleteByIndex(this.storeName, index, value);
  }

  find(auditId, params) {
    return new Promise((resolve, reject) => {
      this._databaseService.getDataByIndex(this.storeName, 'data.audit_id', parseInt(auditId, 10)).then(matches => {
        resolve(matches);
      });
    });
  }
}
