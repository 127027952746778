var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter } from '@angular/core';
import Dexie from 'dexie';
import * as i0 from "@angular/core";
// Subclass it
var DexieDatabase = /** @class */ (function (_super) {
    __extends(DexieDatabase, _super);
    function DexieDatabase() {
        var _this = _super.call(this, "Audit") || this;
        _this.version(1).stores({
            audits: "id,status",
            audit_kit_instances: 'dbId,data.item_instance_id,data.item_instance.item_id,data.audit_id,data.warehouse_id',
            user_actions: '++id,audit_id',
            count_sheets: 'id,isSynchronized,data.audit_id,data.audit_kit_instance_id',
            count_sheet_items: 'id,data.count_sheet_client_id,data.count_sheet_id,data.audit_id,isSynchronized',
            attachments: 'local_file_id,count_sheet_client_id,count_sheet_id,audit_id,synchronized',
            files: 'local_file_id'
        });
        return _this;
    }
    return DexieDatabase;
}(Dexie));
var DatabaseService = /** @class */ (function () {
    function DatabaseService() {
        this.timeoutIds = {};
        this._db = new DexieDatabase();
        this.unsynchronizedDataCountEmitter = new EventEmitter();
    }
    DatabaseService.prototype.clear = function (storeName) {
        var _this = this;
        var that = this;
        return new Promise(function (resolve, reject) {
            _this._db.table(storeName).clear().then(function () {
                resolve(true);
            });
        }).then(function (result) {
            that.notifyUnsynchedDataChanged(storeName);
            return result;
        });
    };
    DatabaseService.prototype.get = function (storeName, primaryKey) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._db.table(storeName).get(primaryKey).then(function (data) {
                resolve(data);
            });
        });
    };
    DatabaseService.prototype.getDataByIndex = function (storeName, index, value) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var collection = _this._db.table(storeName).where(index).equals(value);
            collection.toArray(function (values) {
                resolve(values);
            });
        });
    };
    DatabaseService.prototype.delete = function (storeName, key) {
        var that = this;
        return new Promise(function (resolve, reject) {
            that._db.table(storeName).delete(key).then(function () {
                resolve(true);
            });
        }).then(function (result) {
            that.notifyUnsynchedDataChanged(storeName);
            return result;
        });
    };
    //make sure the value matches exactly ( "1" isn't the same as 1 )
    DatabaseService.prototype.deleteByIndex = function (storeName, index, value) {
        var _this = this;
        var that = this;
        var startAt = new Date();
        console.log('deleteByIndex for ' + storeName + ' started');
        return new Promise(function (resolve, reject) {
            var collection = _this._db.table(storeName).where(index).equals(value);
            collection.delete().then(function () {
                resolve(true);
            });
        }).then(function (result) {
            _this.notifyUnsynchedDataChanged(storeName);
            console.log('deleteByIndex for ' + storeName + ' took:', (new Date()).getTime() - startAt.getTime());
            return result;
        });
    };
    DatabaseService.prototype.update = function (storeName, data) {
        var _this = this;
        var that = this;
        return new Promise(function (resolve, reject) {
            _this._db.table(storeName).put(data).then(function (result) {
                resolve(result);
            });
        }).then(function (data) {
            _this.notifyUnsynchedDataChanged(storeName);
            return data;
        });
    };
    DatabaseService.prototype.add = function (storeName, data) {
        var _this = this;
        var that = this;
        return new Promise(function (resolve, reject) {
            _this._db.table(storeName).put(data).then(function (result) {
                resolve(result);
            });
        }).then(function (data) {
            that.notifyUnsynchedDataChanged(storeName);
            return data;
        });
    };
    DatabaseService.prototype.bulkAdd = function (storeName, data) {
        var _this = this;
        var startAt = new Date();
        console.log('bulkAdd for ' + storeName + ' started');
        return new Promise(function (resolve, reject) {
            _this._db.table(storeName).bulkPut(data).then(function () {
                console.log('bulkAdd for ' + storeName + ' took:', (new Date()).getTime() - startAt.getTime());
                resolve(true);
            });
        });
    };
    DatabaseService.prototype.notifyUnsynchedDataChanged = function (storeName) {
        var that = this;
        clearTimeout(that.timeoutIds[storeName]);
        if (['count_sheets', 'count_sheet_items'].indexOf(storeName) !== -1) {
            that.timeoutIds[storeName] = setTimeout(function () {
                new Promise(function (resolve, reject) {
                    var collection = that._db.table(storeName).where('isSynchronized').equals(0);
                    collection.count(function (value) {
                        resolve(value);
                    });
                }).then(function (count) {
                    that.unsynchronizedDataCountEmitter.emit([storeName, count]);
                });
            }, 1000);
        }
        //in its own section because the index is synchronized vs isSynchronized
        if (storeName === "attachments") {
            that.timeoutIds[storeName] = setTimeout(function () {
                new Promise(function (resolve, reject) {
                    var collection = that._db.table(storeName).where('synchronized').equals(0);
                    collection.count(function (value) {
                        resolve(value);
                    });
                }).then(function (count) {
                    that.unsynchronizedDataCountEmitter.emit([storeName, count]);
                });
            }, 1000);
        }
    };
    DatabaseService.ngInjectableDef = i0.defineInjectable({ factory: function DatabaseService_Factory() { return new DatabaseService(); }, token: DatabaseService, providedIn: "root" });
    return DatabaseService;
}());
export { DatabaseService };
