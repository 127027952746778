import { Component } from '@angular/core';
import { HistoryServiceService } from './services/history-service.service';
import { UpdateService } from './services/update.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Audit';

  /**
   *
   */
  constructor(
    private historyService: HistoryServiceService,
    private updateService: UpdateService) {
    this.historyService.loadRouting();
    this.updateService.checkForUpdates();
  }
}
