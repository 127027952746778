import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { OktaConfigService } from '../services/okta-config.service';
import { CONSTANTS } from '../helpers/constants';
import { OnlineService } from '../services/online.service';
import { IAccessTokenResponse } from '../interfaces/i-access-token-response';
import { IOktaConfig } from '../interfaces/i-okta-config';
import { IOktaLoginParams } from '../interfaces/i-okta-login-params';
import { IuserData } from '../interfaces/iuser-data';
import { UserDataHelper } from '../helpers/user-data-helper';
import { AccessTokenService } from '../services/access-token.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuardGuard implements CanActivate {

  isInternalKey: string = CONSTANTS.localStorageKeys.LOGIN_IS_INTERNAL
  configData: IOktaConfig;
  userData: IuserData
  userDataHelper: UserDataHelper = new UserDataHelper();
  loginParams: IOktaLoginParams = {};

  constructor(
    private authService: AuthService,
    private router: Router,
    private permissionsService: NgxPermissionsService,
    private _oktaConfigService: OktaConfigService,
    private _onlineService: OnlineService,
    private _accessTokenService: AccessTokenService,
  ) {

    this.configData = this._oktaConfigService.getFromLocal();
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    let accessTokenData = this._accessTokenService.getInMemoryTokenDataIfNotNull();
    let expired = this._accessTokenService.isExpired(accessTokenData);
    let isInternalValue = localStorage.getItem(this.isInternalKey);
    let isInternalNull = isInternalValue === null;
    let isInternal = isInternalValue === "true";
    let isOnline = this._onlineService.isOnline();


    if (isOnline) {
      if (accessTokenData && accessTokenData.access_token) {
        if (accessTokenData.access_token && !expired) {
          this.permissionsService.loadPermissions(JSON.parse(localStorage.getItem(CONSTANTS.localStorageKeys.PERMISSIONS)));
          return true;

        } else {
          if (accessTokenData.refresh_token) {
            this.loginParams.grant_type = "refresh_token";
            this.loginParams.refresh_token = accessTokenData.refresh_token;
            this.loginParams.client_id = this.configData.audit_internal_client_id;
            this.loginParams.scope = this.configData.audit_scope += " offline_access";
            this.loginParams.redirect_uri = this.configData.audit_login_redirect_uri
            return this.getAccessTokenVanilla(this.loginParams);
          } else {
            this.router.navigate([CONSTANTS.routes.LOGIN])
            return false;
          }
        }
      } else {
        // do some stuff with okta
        this.authService.redirectUrl = state.url;
        if (isInternal) {
          this._oktaConfigService.makeInternalLoginUri(this.authService.redirectUrl).then((url) => {
            document.location.href = url;
          })
        } else if (isInternalNull) {
          this.router.navigate([CONSTANTS.routes.LOGIN])
        } else {
          this._oktaConfigService.makeExternalLoginUri(this.authService.redirectUrl).then((url) => {
            document.location.href = url;
          })
        }
        return false;
      }
    }
    else {
      //maybe weird? perhaps move to its own guard
      this.permissionsService.loadPermissions(JSON.parse(localStorage.getItem(CONSTANTS.localStorageKeys.PERMISSIONS)));
      return true;
    }

  }

  async getAccessTokenVanilla(params: IOktaLoginParams): Promise<boolean> {
    let that = this;
    let isInternal = localStorage.getItem(this.isInternalKey) === "true"
    let url = isInternal ? this.configData.arthrex_employee_base_url : this.configData.non_arthrex_employee_base_url

    var request = new XMLHttpRequest();
    request.open(CONSTANTS.http.methods.POST, `${url}${this.configData.get_access_token}`, true);
    request.setRequestHeader(CONSTANTS.http.headerLabel.CONTENT_TYPE, CONSTANTS.http.headers.contentType.URL_ENCODED);
    return new Promise<boolean>((resolve, reject) => {
      request.onload = function () {
        let body: IAccessTokenResponse = {};
        try {
          body = JSON.parse(request.response);
        } catch (e) { }
  
        if (request.status == 200) {
            that.authService.register_token(body, isInternal ? CONSTANTS.tenants.INTERNAL : CONSTANTS.tenants.EXTERNAL).subscribe((data) => {
              if (data.success) {
                let currentUser = {
                  first_name: data.user.first_name,
                  last_name: data.user.last_name,
                  name: `${data.user.first_name} ${data.user.last_name}`,
                  email_address: data.user.email_address,
                  user_id: data.user.user_id
                }
    
                that.userDataHelper.setUserData(currentUser);
                that._accessTokenService.storeTokenData(body)
                that.authService.isLoggedIn = true;
    
                that.permissionsService.loadPermissions(JSON.parse(localStorage.getItem(CONSTANTS.localStorageKeys.PERMISSIONS)));
                resolve(true);
              }
              
            }, (error) => {
              if (error.status == 403) {
                resolve(false);
              }
            })
        } else {
         resolve(false);
        }
      }

      request.onerror = function () {
        return Promise.resolve(false);
      }
      var body = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      request.send(body);
    })
  }
}
