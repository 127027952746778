<div class="header-title">
  {{title}}
</div>
<div class="row">
  <div class="col-md-8">
    <form (ngSubmit)="creatingCountsheet? addUnkittedCountSheet(): editUnkittedCountSheet()" #createUnkittedCountSheetForm="ngForm">

      <mat-form-field class="full-width">
        <input id="area" required [(ngModel)]="countSheet.data.area" matInput name="area" placeholder="Area"
          #area="ngModel">
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-select id="warehouse" [(ngModel)]="countSheet.data.warehouse_id" name="warehouse" placeholder="Warehouse"
          required #warehouse="ngModel">
          <mat-option *ngFor="let w of warehouses" [value]="w.account_id">
            {{w.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
  <div class="col-md-4 buttons">
    <button type="button" class="cancel-button" mat-button (click)="closeDialog()">Cancel</button>
    <button type="submit" class="save-button" mat-button (click)="creatingCountsheet? addUnkittedCountSheet(): editUnkittedCountSheet()" [disabled]="!createUnkittedCountSheetForm.form.valid || saving">Save</button>
  </div>
</div>
