import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from "@angular/material";
import { ConsignmentInventoryImportComponent } from "./../consignment-inventory-import/consignment-inventory-import.component";
import { RedspotInventoryImportComponent } from "./../redspot-inventory-import/redspot-inventory-import.component";
import { ActivatedRoute, Router } from "@angular/router";
import { ExclusionListImportComponent } from '../exclusion-list-import/exclusion-list-import.component';
import { ExclusionService } from 'src/app/services/exclusion.service';
import { ConsignmentAccountDataImportComponent } from '../consignment-account-data-import/consignment-account-data-import.component';
import { AuditService } from 'src/app/services/audit.service';
import { SalesOrderInventoryImportService } from '../../services/sales-order-inventory-import.service';
import { ISnackBarConfig } from '../../interfaces/isnack-bar-config';
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';
import { MatSnackBar } from '@angular/material';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { Messages } from 'src/app/helpers/messages';


@Component({
  selector: 'app-dashboard-import',
  templateUrl: './dashboard-import.component.html',
  styleUrls: ['./dashboard-import.component.css']
})
export class DashboardImportComponent implements OnInit {
  @Input() audit;
  @Input() isOnline: boolean;
  @Output() onImportComplete: EventEmitter<any> = new EventEmitter<any>();
  auditId: number;
  exclusionCount: number = 0;
  allowSalesOrdersUpload:boolean = false;

  snackBarConfig: ISnackBarConfig = {
    message: '',
    duration: 3000,
    success: false,
    snackBarClass: ''
  };

  constructor(
    private salesOrderInventoryImportService: SalesOrderInventoryImportService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog, 
    private _route:ActivatedRoute, 
    private _exclusionService: ExclusionService,
    private _auditService: AuditService,
    private _router: Router) {}

  ngOnInit() { 
    this.auditId = +this._route.snapshot.paramMap.get('id');
    this.getExclusionsCount(this.auditId);
    this.allowSalesOrdersUpload = this.audit.audit_mode == 3
  }

  importSalesOrderItems(){

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '20%',
      data: this.audit.sales_order_imported_by 
          ? Messages.salesOrderItemsAlreadyImported
          : Messages.importNotCountedSalesOrderItems
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const fd = new FormData();
        fd.append('audit_id', this._route.snapshot.paramMap.get('id').toString());
        this.salesOrderInventoryImportService.upload(fd).subscribe(data => {
          this.snackBarConfig.message = 'Sales Order Items Imported Successfully';
          this.snackBarConfig.success = true;
          this.openSnackBar();
        }, 
        data => {
          this.snackBarConfig.message = ((data || {}).error || {}).message || 'Unexpected Error: please try again.';
          this.snackBarConfig.success = false;
          this.openSnackBar();
        });
      }
    });
  }

  openSapImportDialog(){
    const id = this._route.snapshot.paramMap.get('id');
    const dialogRef = this.dialog.open(ConsignmentInventoryImportComponent, {
      width: "35%",
      data: {
        audit_id: id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.audit.sap_inventory_imported_date = result.sap_inventory_imported_date;
        this.audit.sap_imported_by = {name: result.sap_imported_by.name};
        this.onImportComplete.emit("SAP");
      }
    });
  }

  openRedspotImportDialog(){
    const id = this._route.snapshot.paramMap.get('id');
    const dialogRef = this.dialog.open(RedspotInventoryImportComponent, {
      width: "40%",
      data: {
        audit_id: id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.audit.redspot_inventory_imported_date = result.body.audit.redspot_inventory_imported_date;
        this.audit.redspot_imported_by = {name: result.body.audit.redspot_imported_by.name};
        this.onImportComplete.emit("Redspot");
      }
    });

  }

  openConsignmentAccountDataDialog(){
    const id = this._route.snapshot.paramMap.get('id');
    const dialogRef = this.dialog.open(ConsignmentAccountDataImportComponent, {
      width: "40%",
      data: {
        audit_id: id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.audit.consignment_account_inventory_imported_by = result.audit.consignment_account_inventory_imported_by;
        this.audit.consignment_account_inventory_imported_date = result.audit.consignment_account_inventory_imported_date;
      }
    });
  }

  openExclusionDialog(){
    const id = this._route.snapshot.paramMap.get('id');
    const dialogRef = this.dialog.open(ExclusionListImportComponent, {
      width: "40%",
      data: {
        audit_id: id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.audit.exclusion_last_changed_date = result.audit.exclusion_last_changed_date;
        this.audit.exclusion_last_changed_by = result.audit.exclusion_last_changed_by;
        this.exclusionCount = result.count
        this.onImportComplete.emit("Exclusion");
      }
    });
  }

  getExclusionsCount(auditId) {
    this._exclusionService.getExclusionsCount(auditId).subscribe((data) => {
      this.exclusionCount = data;
    });
  }

  goToExclusions() {
    this._router.navigate(['exclusion', this._route.snapshot.paramMap.get('id')]);
  }

  goToConsignmentAccountItems(){
    this._router.navigate(['consignment_account_import_list', this._route.snapshot.paramMap.get('id')]);
  }

  goToSalesOrderImportItems(){
    this._router.navigate(['sales_order_inventory_import_list', this._route.snapshot.paramMap.get('id')]);
  }

  goToSAPImportItems(){
    this._router.navigate(['sap_consignment_inventory_import_list', this._route.snapshot.paramMap.get('id')]);
  }

  openSnackBar() {
    this.snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: this.snackBarConfig,
      duration: this.snackBarConfig.duration
    });
  }
}
