export const urls = {
  registerToken: "api/okta/register_token",
  invalidateToken: "api/okta/invalidate_token",
  revokeInternalToken: "api/okta/revoke_internal_token",
  login: 'authenticate_jwt',
  consignmentInvImport: 'audit_api/audits/:audit_id/sap_consignment_inventory_imports',
  consignmentAccountData: 'audit_api/consignment_account_data',
  redspotInventoryImport: 'audit_api/audits/:audit_id/redspot_inventory_imports',
  unconfirmedSalesOrderInventory: 'audit_api/audit_unconfirmed_sales_order_inventories',
  countSheet: 'audit_api/count_sheets',
  countSheetItem: 'audit_api/count_sheet_items',
  exclusion: 'audit_api/audits/:audit_id/exclusions',
  exclusionImport: 'audit_api/audits/:audit_id/exclusions/import',
  kit: 'audit_api/audit_kit_instances',
  getAudits: 'audit_api/audits',
  getAuditVersion: 'audit_api/audits/version',
  getAuditById: 'audit_api/audits/get_audit_by_id',
  createAudit: 'audit_api/audits',
  audit: 'audit_api/audits',
  distributors: 'audit_api/audits/distributors',
  itemPackages: 'audit_api/manufacturers/1/item_packages',
  getWarehouseItemsResults: 'audit_api/audit_warehouse_results/get_warehouse_items_results',
  warehouses: 'audit_api/audits/:audit_id/warehouses',
  importedWarehouses: 'audit_api/audits/:audit_id/imported_warehouses',
  auditLocations: 'audit_api/audit_locations',
  createAuditLocation: 'audit_api/audit_locations',
  updateAuditLocation: 'audit_api/audit_locations',
  deleteAuditLocation: 'audit_api/audit_locations',
  createKitCountSheet: 'audit_api/count_sheets',
  getWarehousesForSite: 'audit_api/audit_locations/get_warehouses_for_site',
  updateCountSheetStatus: 'audit_api/count_sheets/update_count_sheet_status',
  updateCountSheetStatuses: 'audit_api/count_sheets/update_count_sheet_statuses',
  updateCountSheetWarehouse: 'audit_api/count_sheets/change_count_sheet_warehouse',
  removeWarehouseFromSite: '/audit_api/audit_locations/remove_warehouse_by_id',
  items: 'audit_api/manufacturers/1/items',
  itemSettings: 'audit_api/audits/:audit_id/item_settings',
  getCountSheetStatusses: 'audit_api/audits/get_count_sheet_statusses',
  finishSite: '/audit_api/audit_locations/finish_site',
  getAgencyDashboardData: 'audit_api/agency_dashboard/get_agency_dashboard_data',
  getAgencyItemCombinationRecallAlertExceptions: 'audit_api/audits/:audit_id/item_combination_recall_alert_exceptions',
  getManufacturerItemCombinations: 'audit_api/manufacturers/1/item_combinations',
  getManufacturerItemCombinationRecallAlerts: 'audit_api/manufacturers/1/item_combination_recall_alerts',
  itemCombinationImport: 'audit_api/manufacturers/1/item_combinations/import',
  manufacturerItemCombinationCount: 'audit_api/manufacturers/1/item_combinations/count',
  countSheetItemError: 'audit_api/count_sheet_items/email_support',
  lotPoolManagedItems: 'audit_api/manufacturers/1/audit_lot_pool_managed_items',
  addCountSheetAttachment: 'audit_api/count_sheets/:id/attachments',
  countSheetAttachments: 'audit_api/audits/:id/attachments',
  downloadAttachment: 'attachments/:id',
  downloadImageAttachment: 'attachments/:id?style=medium',
  deleteAttachment: 'api/attachments/:id',
  countSheetBulkAddAttachment: 'api/attachments/count_sheet_bulk_add',
  distributorPreferences: 'api/distributor_preferences',
  oktaConfigData:'api/okta/okta_config'
};
