import { OnInit, OnDestroy } from '@angular/core';
import { AuditKitInstanceService } from '../../services/audit-kit-instance.service';
import { MatPaginator, MatSort, MatDialog, MatSnackBar } from '@angular/material';
import { AuditKitInstancesListDataSource } from './audit-kit-instances-list-datasource';
import { CountSheetService } from '../../services/count-sheet.service';
import { of } from 'rxjs';
import { OnlineService } from './../../services/online.service';
import { Router, ActivatedRoute } from '../../../../node_modules/@angular/router';
import { CountSheetHelper } from '../../helpers/countsheet-helper';
import { KitInstanceHelper } from '../../helpers/kit-instance-helper';
import { UserDataHelper } from '../../helpers/user-data-helper';
import * as moment from 'moment';
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';
import { ExportToExcelService } from 'src/app/services/export-to-excel.service';
import { RedspotInventoryImportService } from 'src/app/services/redspot-inventory-import.service';
import { ItemSettingService } from 'src/app/services/item-setting.service';
import { ItemCombinationService } from 'src/app/services/item-combination.service';
import { KitDefinitionItemService } from 'src/app/services/kit-definition-item.service';
import { map } from 'rxjs/operators';
import { zip } from 'rxjs';
import { ConsignmentInventoryImportService } from 'src/app/services/consignment-inventory-import.service';
import { ExclusionService } from 'src/app/services/exclusion.service';
import { PrintHelper } from '../../helpers/print-helper';
import { CountSheetItemService } from '../../services/count-sheet-item.service';
import { CountSheetItemHelper } from 'src/app/helpers/countsheet-item-helper';
import { LotPoolManagedItemService } from '../../services/lot-pool-managed-item.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { DatabaseService } from 'src/app/services/database.service';
import { CountSheetDatabaseService } from 'src/app/services/count-sheet-database.service';
import { CountSheetItemDatabaseService } from 'src/app/services/count-sheet-item-database.service';
import { AuditKitInstanceDatabaseService } from 'src/app/services/audit-kit-instance-database.service';
import { AuditStatus } from 'src/app/helpers/audit-status';
import { TrackLoadingService } from 'src/app/services/track-loading.service';
import { RecallOracleService } from 'src/app/services/recall-oracle.service';
var AuditKitInstancesListComponent = /** @class */ (function () {
    function AuditKitInstancesListComponent(auditKitInstanceService, excelService, countSheetService, dialog, _onlineService, _router, _route, _countSheetService, snackBar, redspotInventoryImportService, itemSettingService, kitDefinitionItemService, consignmentInventoryImportService, exclusionService, _countSheetItemService, _lotPoolManagedItemService, _databaseService, _countSheetDatabaseService, _auditKitInstanceDatabase, _countSheetItemDatabaseService, _trackLoadingService, _recallOracleService, itemCombinationService) {
        var _this = this;
        this.auditKitInstanceService = auditKitInstanceService;
        this.excelService = excelService;
        this.countSheetService = countSheetService;
        this.dialog = dialog;
        this._onlineService = _onlineService;
        this._router = _router;
        this._route = _route;
        this._countSheetService = _countSheetService;
        this.snackBar = snackBar;
        this.redspotInventoryImportService = redspotInventoryImportService;
        this.itemSettingService = itemSettingService;
        this.kitDefinitionItemService = kitDefinitionItemService;
        this.consignmentInventoryImportService = consignmentInventoryImportService;
        this.exclusionService = exclusionService;
        this._countSheetItemService = _countSheetItemService;
        this._lotPoolManagedItemService = _lotPoolManagedItemService;
        this._databaseService = _databaseService;
        this._countSheetDatabaseService = _countSheetDatabaseService;
        this._auditKitInstanceDatabase = _auditKitInstanceDatabase;
        this._countSheetItemDatabaseService = _countSheetItemDatabaseService;
        this._trackLoadingService = _trackLoadingService;
        this._recallOracleService = _recallOracleService;
        this.itemCombinationService = itemCombinationService;
        this.displayedColumns = ['verify', 'reference', 'description', 'lot_number', 'item_count', 'current_count', 'warehouse', 'site', 'counted_by', 'counted_time', 'status', 'countsheet'];
        this.viewOnly = false;
        this.areAnyKitCounted = false;
        this.verifyAllChecked = false;
        this.kitCountSheet = {
            dbId: 0,
            isSynchronized: 0,
            id: '',
            data: {
                reference: '',
                lot_number: '',
                isKitted: true,
                audit_id: 0,
                audit_location_id: 0,
                location_name: '',
                warehouse_name: '',
                warehouse_id: 0,
                area: null,
                count_sheet_status: 1,
                audit_kit_instance_id: null,
                status_text: '',
                total_items_counted: 0
            },
        };
        this.snackBarConfig = {
            message: '',
            duration: 3000,
            success: false,
            snackBarClass: ''
        };
        // helpers
        this.countSheetHelper = new CountSheetHelper();
        this.countSheetItemHelper = new CountSheetItemHelper();
        this.kitInstanceHelper = new KitInstanceHelper();
        this.userDataHelper = new UserDataHelper();
        this.printHelper = new PrintHelper();
        // observers
        this.createCountSheetObserver = {
            next: function (data) {
                _this._trackLoadingService.stopLoading('audit-kit-instances-list-createCountSheet');
                if (data.result) {
                    var that_1 = _this;
                    var countSheet_1 = that_1._countSheetDatabaseService.new(data.count_sheet);
                    that_1.currentRow.data.count_sheet = countSheet_1;
                    that_1._trackLoadingService.startLoading('audit-kit-instances-list-createCountSheet-countSheetDatabase-add', 'Saving New Count Sheet For Offline');
                    that_1._countSheetDatabaseService.add(countSheet_1).then(function () {
                        that_1._trackLoadingService.stopLoading('audit-kit-instances-list-createCountSheet-countSheetDatabase-add');
                        that_1.currentRow.data.has_count_sheet = true;
                        that_1.snackBarConfig.message = 'Kit Count Sheet Created';
                        that_1.snackBarConfig.success = true;
                        that_1.openSnackBar();
                        that_1.startCounting(that_1.currentRow, countSheet_1);
                    });
                }
                else {
                    _this.snackBarConfig.message = "Error: " + data.message;
                    _this.snackBarConfig.success = false;
                    _this.openSnackBar();
                    if (data.reason === 'offline') {
                        _this.snackBarConfig.message = 'Conflicting count sheets created by other Users, reloading';
                        _this.snackBarConfig.success = false;
                        _this.openSnackBar();
                        _this._route.queryParams.subscribe(function (params) {
                            _this._router.navigate([_this.audit.id, 'countsheets', +_this._route.snapshot.paramMap.get('id')], { queryParams: { status: params.status || 'All', selected_tab: 0 } });
                        });
                    }
                }
            },
            error: function () {
                _this._trackLoadingService.stopLoading('audit-kit-instances-list-createCountSheet');
                _this.onlineSubscription = _this._onlineService.isOnlineSubscription().subscribe(function (online) {
                    if (!online) {
                        var that_2 = _this;
                        _this._trackLoadingService.startLoading('audit-kit-instances-list-createCountSheet-IsCountSheetDuplicate', 'Checking If Count Sheet Is Duplicate');
                        _this.IsCountSheetDuplicate(_this.kitCountSheet).then(function (isDuplicate) {
                            _this._trackLoadingService.stopLoading('audit-kit-instances-list-createCountSheet-IsCountSheetDuplicate');
                            if (isDuplicate) {
                                _this.showSnackBar('Duplicate CountSheet for Kit', false);
                                return;
                            }
                            _this.kitCountSheet.dbId = 0;
                            _this.kitCountSheet.id = '';
                            _this.kitCountSheet.isSynchronized = 0;
                            _this.currentRow.data.has_count_sheet = true;
                            _this._trackLoadingService.startLoading('audit-kit-instances-list-createCountSheet-countSheetDatabase-add', 'Saving New Count Sheet For Offline');
                            _this._countSheetDatabaseService.add(_this.kitCountSheet).then(function (clientId) {
                                that_2._countSheetDatabaseService.get(clientId).then(function (cs) {
                                    that_2._trackLoadingService.stopLoading('audit-kit-instances-list-createCountSheet-countSheetDatabase-add');
                                    that_2.countsheets = that_2.countsheets.concat(cs);
                                    that_2.getAuditKitInstances(that_2.audit.id);
                                    that_2.startCounting(that_2.currentRow, cs);
                                    that_2.showSnackBar('Count Sheet Created', true);
                                });
                            });
                        });
                    }
                });
                _this.onlineSubscription.unsubscribe();
            },
            complete: function () {
            }
        };
    }
    Object.defineProperty(AuditKitInstancesListComponent.prototype, "dataSource", {
        get: function () {
            return this._dataSource;
        },
        set: function (newDataSource) {
            this.areAnyKitCounted = !!newDataSource.filteredData().find(function (kit) { return kit.data.status === 3; });
            this._dataSource = newDataSource;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuditKitInstancesListComponent.prototype, "auditKitInstances", {
        get: function () {
            return this._auditKitInstances;
        },
        set: function (newAuditKitInstances) {
            var _this = this;
            //so we don't have to have multiple copies of the same data and trying to keep them all insync
            newAuditKitInstances.forEach(function (aki) {
                var countSheet = _this.countsheets.find(function (cs) { return cs.data.audit_kit_instance_id == aki.dbId; });
                if (countSheet) {
                    aki.data.count_sheet = countSheet;
                }
            });
            this._auditKitInstances = newAuditKitInstances;
        },
        enumerable: true,
        configurable: true
    });
    AuditKitInstancesListComponent.prototype.getItemCombinations = function () {
        if (this.itemCombinations) {
            return of(this.itemCombinations);
        }
        return this.itemCombinationService.get();
    };
    AuditKitInstancesListComponent.prototype.getRedspotQuantities = function (auditId, itemInstanceId) {
        return this.redspotInventoryImportService.getInventory(auditId).pipe(map(function (data) { return data.filter(function (rq) { return rq.kit_instance_id === itemInstanceId; }); }));
    };
    AuditKitInstancesListComponent.prototype.getLotPoolManagedItems = function () {
        if (this.lotPoolManagedItems) {
            return of(this.lotPoolManagedItems);
        }
        return this._lotPoolManagedItemService.getLotPoolManagedItems();
    };
    AuditKitInstancesListComponent.prototype.getRootKitDefinitionItems = function (kitId) {
        return this.kitDefinitionItemService.getKitDefinitionItems().pipe(map(function (data) { return data.filter(function (kdi) { return kdi.kit_definition_id === kitId; }); }));
    };
    AuditKitInstancesListComponent.prototype.getItemSettingsByAudit = function (auditId) {
        if (this.itemSettings) {
            return of(this.itemSettings);
        }
        return this.itemSettingService.getItemSettingsByAudit(auditId);
    };
    AuditKitInstancesListComponent.prototype.getConsignmentQuantities = function (auditId) {
        if (this.sapQuantities) {
            return of(this.sapQuantities);
        }
        return this.consignmentInventoryImportService.getInventory(auditId, true);
    };
    AuditKitInstancesListComponent.prototype.getExclusions = function (auditId) {
        if (this.exclusions) {
            return of(this.exclusions);
        }
        return this.exclusionService.getExclusions(auditId);
    };
    AuditKitInstancesListComponent.prototype.ngOnDestroy = function () {
        if (this.onlineSubscription) {
            this.onlineSubscription.unsubscribe();
        }
        if (this.reloadSubscription) {
            this.reloadSubscription.unsubscribe();
        }
    };
    AuditKitInstancesListComponent.prototype.ngOnInit = function () {
        this.userData = this.userDataHelper.getUserData();
        this.auditId = +this._route.snapshot.paramMap.get('audit_id');
        this.permissions = JSON.parse(localStorage.getItem('permissions'));
        this.status = this.filters['status'];
        if (this.audit.status === AuditStatus.COMPLETED) {
            this.viewOnly = true;
            if (parseInt(this.siteId, 10) !== -1) {
                this.getSheets();
            }
        }
        else {
            if (parseInt(this.siteId, 10) === -1) {
                this.viewOnly = true;
            }
            else {
                this.viewOnly = this._route.snapshot.paramMap.get('isViewOnly') === 'true';
                if (this.permissions[0] === 'auditor' || this.permissions[0] === 'audit_administrator') {
                    this.viewOnly = false;
                }
                else if (this.permissions[0] === 'audit_viewer') {
                    this.viewOnly = true;
                }
            }
            this.getSheets();
        }
        this.getAuditKitInstances(this.audit.id);
    };
    AuditKitInstancesListComponent.prototype.getAuditKitInstances = function (auditId) {
        var _this = this;
        var that = this;
        this._trackLoadingService.startLoading('audit-kit-instances-list-getAuditKitInstances', 'loading Kit List');
        this.onlineSubscription = this._onlineService.isOnlineSubscription().subscribe(function (online) {
            if (online && !_this._onlineService.getTestOfflineBool()) {
                _this.auditKitInstanceService.getAuditKitInstancesAndPopulateOfflineData(auditId).then(function (results) {
                    _this.auditKitInstances = results;
                    _this.dataSource = new AuditKitInstancesListDataSource(_this.paginator, _this.sort, _this.auditKitInstances, _this.filters);
                    _this._trackLoadingService.stopLoading('audit-kit-instances-list-getAuditKitInstances');
                });
            }
            else {
                _this.auditKitInstanceService.getOfflineAuditKitInstances(auditId).then(function (akis) {
                    that.auditKitInstances = akis;
                    if (that.auditKitInstances.length === 0) {
                        that.auditKitInstanceService.getAuditKitInstancesAndPopulateOfflineData(auditId).then(function (results) {
                            that.auditKitInstances = results;
                            that.dataSource = new AuditKitInstancesListDataSource(that.paginator, that.sort, that.auditKitInstances, that.filters);
                            that._trackLoadingService.stopLoading('audit-kit-instances-list-getAuditKitInstances');
                        });
                    }
                    else {
                        that.dataSource = new AuditKitInstancesListDataSource(that.paginator, that.sort, that.auditKitInstances, that.filters);
                        that._trackLoadingService.stopLoading('audit-kit-instances-list-getAuditKitInstances');
                    }
                });
            }
        });
        this.onlineSubscription.unsubscribe();
    };
    AuditKitInstancesListComponent.prototype.getSheets = function () {
        this._trackLoadingService.startLoading('audit-kit-instances-list-getSheets', 'loading Count Sheets for Kit List');
        this.countsheets = this.kittedCountSheets;
        this._trackLoadingService.stopLoading('audit-kit-instances-list-getSheets');
    };
    /**
     * create countsheet online / offline
     * @param row
     */
    AuditKitInstancesListComponent.prototype.createCountSheet = function (row) {
        var _this = this;
        this.currentRow = row;
        this.kitCountSheet.dbId = 0;
        this.kitCountSheet.id = '';
        this.kitCountSheet.data.count_sheet_status = 2;
        this.kitCountSheet.data.created_by = this.userData;
        this.kitCountSheet.data.created_at = moment().format('lll');
        this.kitCountSheet.data.audit_id = row.data.audit_id;
        this.kitCountSheet.data.warehouse_id = row.data.warehouse_id;
        this.kitCountSheet.data.warehouse_name = row.data.warehouse_name;
        this.kitCountSheet.data.lot_number = row.data.item_instance.lot_number;
        this.kitCountSheet.data.reference = row.data.item_instance.reference;
        this.kitCountSheet.data.audit_kit_instance_id = row.dbId;
        this.kitCountSheet.data.audit_location_id = this.siteId;
        this.kitCountSheet.data.location_name = this.location.location_name;
        this.kitCountSheet.data.kit_id = row.data.item_instance.root_item_id;
        this.kitCountSheet.data.show_expected_item_list = !!row.data.item_instance.root_item_id;
        this.kitCountSheet.data.audit_kit_instance = {
            item_instance_id: row.data.item_instance_id
        };
        this._trackLoadingService.startLoading('audit-kit-instances-list-createCountSheet', 'Creating Count Sheet');
        this.onlineSubscription = this._onlineService.isOnlineSubscription().subscribe(function (online) {
            if (online && !_this._onlineService.getTestOfflineBool()) {
                _this.countSheetService.createKitCountSheet(_this.kitCountSheet).subscribe(_this.createCountSheetObserver);
            }
            else {
                _this._trackLoadingService.stopLoading('audit-kit-instances-list-createCountSheet');
                var that_3 = _this;
                _this._trackLoadingService.startLoading('audit-kit-instances-list-createCountSheet-IsCountSheetDuplicate', 'Checking If Count Sheet Is Duplicate');
                _this.IsCountSheetDuplicate(_this.kitCountSheet).then(function (isDuplicate) {
                    _this._trackLoadingService.stopLoading('audit-kit-instances-list-createCountSheet-IsCountSheetDuplicate');
                    if (isDuplicate) {
                        _this.showSnackBar('Duplicate CountSheet for Kit', false);
                        return;
                    }
                    _this.kitCountSheet.dbId = 0;
                    _this.kitCountSheet.id = '';
                    _this.kitCountSheet.isSynchronized = 0;
                    _this.currentRow.data.has_count_sheet = true;
                    _this._trackLoadingService.startLoading('audit-kit-instances-list-createCountSheet-countSheetDatabase-add', 'Saving New Count Sheet For Offline');
                    _this._countSheetDatabaseService.add(_this.kitCountSheet).then(function (clientId) {
                        that_3._countSheetDatabaseService.get(clientId).then(function (cs) {
                            _this._trackLoadingService.stopLoading('audit-kit-instances-list-createCountSheet-countSheetDatabase-add');
                            that_3.countsheets = that_3.countsheets.concat(cs);
                            that_3.getAuditKitInstances(that_3.audit.id);
                            _this.startCounting(that_3.currentRow, cs);
                            _this.showSnackBar('Count Sheet Created', true);
                        });
                    });
                });
            }
        });
        this.onlineSubscription.unsubscribe();
    };
    AuditKitInstancesListComponent.prototype.IsCountSheetDuplicate = function (countSheet) {
        var that = this;
        var promise = new Promise(function (resolve, reject) {
            that._countSheetDatabaseService.find(that.auditId, { audit_kit_instance_id: countSheet.data.audit_kit_instance_id, archived: false }).then(function (matches) {
                resolve(matches.length > 1);
            });
        });
        return promise;
    };
    AuditKitInstancesListComponent.prototype.showSnackBar = function (message, success) {
        this.snackBarConfig.message = message;
        this.snackBarConfig.success = success;
        this.openSnackBar();
    };
    /**
     * start counting online / offline
     * @param row
     */
    AuditKitInstancesListComponent.prototype.startCounting = function (row, countSheet) {
        this.rowToCount = row;
        this._databaseService.add('user_actions', {
            audit_id: this.auditId.toString(),
            auditor: this.userData.first_name + " " + this.userData.last_name,
            area: '',
            kit_reference: "" + row.data.item_instance.reference,
            kit_lot_number: "" + row.data.item_instance.lot_number,
            action: "Created count sheet",
            timestamp: "" + (new Date()).toUTCString(),
            total_items_counted: countSheet.data.total_items_counted,
            site_id: this.siteId,
            warehouse_id: countSheet.data.warehouse_id
        });
        this._router.navigate([this.audit.id, 'kit_instance_count_sheet', countSheet.id, this.viewOnly], { queryParams: { status: this.status } });
        this.onlineSubscription.unsubscribe();
    };
    AuditKitInstancesListComponent.prototype.continueCounting = function (row) {
        this.rowToCount = row;
        if (this.permissions[0] === 'auditor' || this.permissions[0] === 'audit_administrator') {
            this._router.navigate([row.data.audit_id, 'kit_instance_count_sheet', row.data.count_sheet.id, this.viewOnly], { queryParams: { status: this.status } });
        }
        else {
            this._router.navigate([row.data.audit_id, 'kit_instance_count_sheet', row.data.count_sheet.id, true], { queryParams: { status: this.status } });
        }
    };
    AuditKitInstancesListComponent.prototype.goToCompletedCountSheet = function (row) {
        if (this.permissions[0] === 'auditor' || this.permissions[0] === 'audit_administrator') {
            this._router.navigate([row.data.audit_id, 'kit_instance_count_sheet', row.data.count_sheet.id, this.viewOnly], { queryParams: { status: this.status } });
        }
        else {
            this._router.navigate([row.data.audit_id, 'kit_instance_count_sheet', row.data.count_sheet.id, true], { queryParams: { status: this.status } });
        }
    };
    AuditKitInstancesListComponent.prototype.goToVerifiedCountSheet = function (row) {
        this._router.navigate([row.data.audit_id, 'kit_instance_count_sheet', row.data.count_sheet.id, this.viewOnly], { queryParams: { status: this.status } });
    };
    AuditKitInstancesListComponent.prototype.applyFilter = function () {
        this.dataSource = new AuditKitInstancesListDataSource(this.paginator, this.sort, this.auditKitInstances, this.filters);
    };
    AuditKitInstancesListComponent.prototype.checkedAllClicked = function (event) {
        var _this = this;
        var verifiedStatus = 4;
        var verifiedMetadata = {
            verified_by_id: this.userData.user_id,
            verified_time: moment().toDate(),
            kitted: true
        };
        var dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '30%',
            data: 'Verify all kitted countsheets?',
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this._trackLoadingService.startLoading('audit-kit-instances-list-checkedAllClicked', 'Verifying All Kitted Count Sheets');
                _this.onlineSubscription = _this._onlineService.isOnlineSubscription().subscribe(function (online) {
                    if (online && !_this._onlineService.getTestOfflineBool()) {
                        _this._countSheetService.updateCountSheetStatuses(_this.dataSource.filteredData().filter(function (kit) { return kit.data.status === 3; }).map(function (kit) { return kit.data.count_sheet.dbId; }), verifiedStatus, verifiedMetadata, _this.siteId).subscribe(function (data) {
                            data.kits.forEach(function (k) {
                                _this._databaseService.add('user_actions', {
                                    audit_id: _this.auditId.toString(),
                                    auditor: _this.userData.first_name + " " + _this.userData.last_name,
                                    timestamp: "" + (new Date()).toUTCString(),
                                    area: '',
                                    kit_reference: "" + k.item_instance.item.reference,
                                    kit_lot_number: "" + k.item_instance.lot_number,
                                    action: 'Verified count sheet',
                                    total_items_counted: k.count_sheet.total_items_counted,
                                    site_id: _this.siteId,
                                    warehouse_id: k.count_sheet.warehouse_id,
                                    user: _this.userData
                                });
                            });
                            //update offline data
                            var promises = data.kits.map(function (aki) {
                                return _this._auditKitInstanceDatabase.update(_this._auditKitInstanceDatabase.new(aki));
                            }).concat(data.count_sheets.map(function (cs) {
                                return _this._countSheetDatabaseService.update(_this._countSheetDatabaseService.new(cs));
                            })).concat(new Promise(function (r, a) { r(null); }));
                            zip.apply(void 0, promises).subscribe(function (_) {
                                zip(_this._countSheetDatabaseService.find(_this.auditId, { not_null_audit_kit_instance_id: true, archived: false }), _this._auditKitInstanceDatabase.find(_this.auditId, {})).subscribe(function (data) {
                                    _this.countsheets = data[0];
                                    _this.auditKitInstances = data[1];
                                    _this.dataSource = new AuditKitInstancesListDataSource(_this.paginator, _this.sort, _this.auditKitInstances, _this.filters);
                                    event.source.checked = false;
                                    _this._trackLoadingService.stopLoading('audit-kit-instances-list-checkedAllClicked');
                                });
                            });
                        });
                    }
                    else {
                        var promises = _this.dataSource.filteredData().filter(function (kit) { return kit.data.status === 3; }).map(function (k) {
                            _this._databaseService.add('user_actions', {
                                audit_id: _this.auditId.toString(),
                                auditor: _this.userData.first_name + " " + _this.userData.last_name,
                                timestamp: "" + (new Date()).toUTCString(),
                                area: '',
                                kit_reference: "" + k.data.item_instance.reference,
                                kit_lot_number: "" + k.data.item_instance.lot_number,
                                action: 'Verified count sheet',
                                total_items_counted: k.data.count_sheet.data.total_items_counted,
                                site_id: _this.siteId,
                                warehouse_id: k.data.count_sheet.warehouse_id
                            });
                            var countSheet = k.data.count_sheet;
                            countSheet.isSynchronized = 0;
                            countSheet.data.count_sheet_status = verifiedStatus;
                            countSheet.data.verified_by = _this.userData;
                            countSheet.data.verified_time = moment().format('lll');
                            return _this._countSheetDatabaseService.update(countSheet);
                        });
                        zip.apply(void 0, promises.concat(new Promise(function (r, a) { r(null); }))).subscribe(function (_) {
                            zip(_this._countSheetDatabaseService.find(_this.auditId, { not_null_audit_kit_instance_id: true, archived: false }), _this._auditKitInstanceDatabase.find(_this.auditId, {})).subscribe(function (data) {
                                _this.countsheets = data[0];
                                _this.auditKitInstances = data[1];
                                _this.dataSource = new AuditKitInstancesListDataSource(_this.paginator, _this.sort, _this.auditKitInstances, _this.filters);
                                event.source.checked = false;
                                _this._trackLoadingService.stopLoading('audit-kit-instances-list-checkedAllClicked');
                            });
                        });
                    }
                });
                _this.onlineSubscription.unsubscribe();
            }
            else {
                _this.verifyAllChecked = false;
            }
        });
    };
    AuditKitInstancesListComponent.prototype.checkedClicked = function (element) {
        var _this = this;
        var verifiedStatus = 4;
        var verifiedMetadata = {
            verified_by_id: this.userData.user_id,
            verified_time: moment().toDate()
        };
        var dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '30%',
            data: "Verify kitted countsheet: " + element.data.item_instance.reference + "?",
        });
        var that = this;
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this._trackLoadingService.startLoading('audit-kit-instances-list-checkedClicked', 'Verifying Kit Count Sheet');
                _this._databaseService.add('user_actions', {
                    audit_id: _this.auditId.toString(),
                    auditor: _this.userData.first_name + " " + _this.userData.last_name,
                    timestamp: "" + (new Date()).toUTCString(),
                    area: '',
                    kit_reference: "" + element.data.item_instance.reference,
                    kit_lot_number: "" + element.data.item_instance.lot_number,
                    action: 'Verified count sheet',
                    total_items_counted: element.data.count_sheet.total_items_counted,
                    site_id: _this.siteId,
                    warehouse_id: element.data.count_sheet.warehouse_id
                });
                _this.onlineSubscription = _this._onlineService.isOnlineSubscription().subscribe(function (online) {
                    if (online && !_this._onlineService.getTestOfflineBool()) {
                        _this._countSheetService.updateCountSheetStatus(element.data.count_sheet.dbId, verifiedStatus, verifiedMetadata).subscribe(function (res) {
                            zip(_this._auditKitInstanceDatabase.update(_this._auditKitInstanceDatabase.new(res.kit)), _this._countSheetDatabaseService.update(_this._countSheetDatabaseService.new(res.count_sheet))).subscribe(function (data) {
                                zip(_this._countSheetDatabaseService.find(_this.auditId, { not_null_audit_kit_instance_id: true, archived: false }), _this._auditKitInstanceDatabase.find(_this.auditId, {})).subscribe(function (data) {
                                    _this.countsheets = data[0];
                                    _this.auditKitInstances = data[1];
                                    _this.dataSource = new AuditKitInstancesListDataSource(_this.paginator, _this.sort, _this.auditKitInstances, _this.filters);
                                    _this._trackLoadingService.stopLoading('audit-kit-instances-list-checkedClicked');
                                });
                            });
                        });
                    }
                    else {
                        that._countSheetDatabaseService.find(that.auditId, { audit_kit_instance_id: element.dbId, archived: false }).then(function (matches) {
                            var countSheet = matches[0];
                            countSheet.isSynchronized = 0;
                            countSheet.data.count_sheet_status = verifiedStatus;
                            countSheet.data.verified_by = that.userData;
                            countSheet.data.verified_time = moment().format('lll');
                            that._countSheetDatabaseService.update(countSheet).then(function () {
                                zip(that._countSheetDatabaseService.find(that.auditId, { not_null_audit_kit_instance_id: true, archived: false }), that._auditKitInstanceDatabase.find(that.auditId, {})).subscribe(function (data) {
                                    that.countsheets = data[0];
                                    that.auditKitInstances = data[1];
                                    that.dataSource = new AuditKitInstancesListDataSource(that.paginator, that.sort, that.auditKitInstances, that.filters);
                                    that._trackLoadingService.stopLoading('audit-kit-instances-list-checkedClicked');
                                });
                            });
                        });
                    }
                });
                _this.onlineSubscription.unsubscribe();
            }
            else {
                element.data.verify_display = false;
            }
        });
    };
    AuditKitInstancesListComponent.prototype.openSnackBar = function () {
        this.snackBar.openFromComponent(MaterialSnackbarComponent, {
            data: this.snackBarConfig,
            duration: this.snackBarConfig.duration
        });
    };
    AuditKitInstancesListComponent.prototype.exportToExcel = function () {
        //array to send to exel-export service
        var dataToExport = [];
        //represents a single row in exel sheet
        var dataRow;
        this.dataSource.getDataForExport().forEach(function (row) {
            dataRow = {
                'Unique Reference': row.data.item_instance.reference + ' - ' + row.data.item_instance.lot_number,
                'Counted At': row.data.count_sheet ? row.data.count_sheet.data.location_name : '',
                'Counted By': row.data.counted_by ? row.data.counted_by.name : '',
                'Status': row.data.statusText,
                'Manufacturer Reference': row.data.item_instance.item.root_item ? row.data.item_instance.item.root_item.reference : '',
                'Contains Implants': row.data.has_consigned_items ? 'Yes' : 'No',
                'Type': row.data.item_instance.type,
                'Description': row.data.item_instance.description,
                'Warehouse': row.data.count_sheet ? row.data.count_sheet.data.warehouse_name : row.data.warehouse_name,
                'Location': row.data.item_instance.address.name,
                'Lot Number': row.data.item_instance.lot_number,
                'Items Counted': row.data.count_sheet ? row.data.count_sheet.data.total_items_counted : 0
            };
            dataToExport.push(dataRow);
        });
        this.excelService.exportAsExcelFile(dataToExport, 'kit summary', {});
    };
    AuditKitInstancesListComponent.prototype.getCountSheetItemsInAnyState = function (online, auditId, countSheet) {
        var that = this;
        var promise = new Promise(function (resolve, reject) {
            if (online) {
                that._countSheetItemService.getCountSheetItems(auditId, { count_sheet_client_id: countSheet.id }).subscribe(function (data) {
                    that._countSheetItemService.refreshOfflineDataForCountSheet(auditId, countSheet.id, data).then(function (countSheetItems) {
                        resolve(countSheetItems);
                    });
                });
            }
            else {
                that._countSheetItemDatabaseService.find(auditId, { count_sheet_client_id: countSheet.id, _destroy: false }).then(function (existingCountSheetItems) {
                    //we must have already cached these into indexeddb
                    if (existingCountSheetItems.length > 0) {
                        return resolve(existingCountSheetItems);
                    }
                    //get the data from the cached serviceworker
                    that._countSheetItemService.getCountSheetItems(auditId).subscribe(function (auditCountSheetItems) {
                        var matches = auditCountSheetItems.filter(function (cs) { return cs.count_sheet_client_id === countSheet.id; });
                        that._countSheetItemService.refreshOfflineDataForCountSheet(auditId, countSheet.id, matches).then(function (csis) {
                            resolve(csis);
                        });
                    });
                });
            }
        });
        return promise;
    };
    AuditKitInstancesListComponent.prototype.printCountSheet = function (row) {
        var _this = this;
        this._countSheetDatabaseService.find(this.auditId, { audit_kit_instance_id: row.dbId, archived: false }).then(function (matches) {
            var countSheet = matches[0];
            _this.filters['exclusions'] = 'hide';
            zip(_this.getRedspotQuantities(row.data.audit_id, row.data.item_instance_id), _this.getRootKitDefinitionItems(row.data.item_instance.root_item_id), _this.getLotPoolManagedItems(), _this.getItemSettingsByAudit(_this.auditId), _this.getItemCombinations(), _this.getConsignmentQuantities(_this.auditId), _this.getExclusions(_this.auditId)).subscribe(function (data) {
                _this.redspotQuantities = data[0];
                _this.kitDefinitionItems = data[1];
                _this.lotPoolManagedItems = data[2];
                _this.itemSettings = data[3];
                _this.itemCombinations = data[4];
                _this.sapQuantities = data[5];
                _this.exclusions = data[6];
                _this._recallOracleService.prime(row.data.audit_id, { itemCombinations: _this.itemCombinations }).then(function (_) {
                    _this._databaseService.add('user_actions', {
                        audit_id: _this.auditId.toString(),
                        auditor: _this.userData.first_name + " " + _this.userData.last_name,
                        timestamp: "" + (new Date()).toUTCString(),
                        area: '',
                        kit_reference: "" + row.data.item_instance.reference,
                        kit_lot_number: "" + row.data.item_instance.lot_number,
                        action: 'Printed count sheet',
                        total_items_counted: (row.data.count_sheet || {}).total_items_counted || 0,
                        site_id: _this.siteId,
                        warehouse_id: (row.data.count_sheet || {}).warehouse_id || row.data.warehouse_id
                    });
                    _this.onlineSubscription = _this._onlineService.isOnlineSubscription().subscribe(function (online) {
                        var isOnline = online && !_this._onlineService.getTestOfflineBool();
                        if (countSheet) {
                            zip(_this.getCountSheetItemsInAnyState(isOnline, _this.auditId, countSheet)).subscribe(function (countsheetItemsData) {
                                var csis = countsheetItemsData[0];
                                var countsheetItems = _this.kitInstanceHelper.getKitDatasourceData(countSheet, csis, _this.lotPoolManagedItems, _this.redspotQuantities, _this.sapQuantities, _this.itemCombinations, _this._recallOracleService, _this.kitDefinitionItems, _this.itemSettings, _this.exclusions, _this.filters);
                                var printOptions = {
                                    isKitted: true,
                                    warehouseName: row.data.warehouse_name,
                                    locationName: countSheet.data.location_name,
                                    lotNumber: row.data.item_instance.lot_number,
                                    statusText: row.data.statusText,
                                    agencyName: _this.audit.agency_name,
                                    reference: row.data.item_instance.reference,
                                    countSheet: countSheet,
                                    kitDefinitionItems: _this.kitDefinitionItems,
                                    lotPoolManagedItems: _this.lotPoolManagedItems,
                                    redspotQuantities: _this.redspotQuantities,
                                    filters: _this.filters,
                                    itemSettings: _this.itemSettings,
                                    exclusions: _this.exclusions
                                };
                                _this.printHelper.print(countsheetItems, printOptions);
                            });
                        }
                        else {
                            var countsheetItems = _this.kitInstanceHelper.getKitDatasourceData(countSheet, null, _this.lotPoolManagedItems, _this.redspotQuantities, _this.sapQuantities, _this.itemCombinations, _this._recallOracleService, _this.kitDefinitionItems, _this.itemSettings, _this.exclusions, _this.filters);
                            var printOptions = {
                                isKitted: true,
                                warehouseName: row.data.warehouse_name,
                                locationName: _this.location ? _this.location.location_name : '',
                                lotNumber: row.data.item_instance.lot_number,
                                statusText: row.data.statusText,
                                agencyName: _this.audit.agency_name,
                                reference: row.data.item_instance.reference,
                                countSheet: countSheet,
                                kitDefinitionItems: _this.kitDefinitionItems,
                                lotPoolManagedItems: _this.lotPoolManagedItems,
                                redspotQuantities: _this.redspotQuantities,
                                filters: _this.filters,
                                itemSettings: _this.itemSettings,
                                exclusions: _this.exclusions
                            };
                            _this.printHelper.print(countsheetItems, printOptions);
                        }
                    });
                    _this.onlineSubscription.unsubscribe();
                });
            });
        });
    };
    return AuditKitInstancesListComponent;
}());
export { AuditKitInstancesListComponent };
