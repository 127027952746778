import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { urls } from "../helpers/urls";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ExclusionService = /** @class */ (function () {
    function ExclusionService(http) {
        this.http = http;
    }
    ExclusionService.prototype.getExclusions = function (auditId) {
        return this.http.get("" + environment.serverPath + urls.exclusion.replace(":audit_id", auditId));
    };
    ExclusionService.prototype.getExclusionsCount = function (auditId) {
        return this.http.get("" + environment.serverPath + urls.exclusion.replace(":audit_id", auditId) + "/count");
    };
    ExclusionService.prototype.createExclusion = function (auditId, formData) {
        return this.http.post("" + environment.serverPath + urls.exclusion.replace(":audit_id", auditId), formData).pipe(function (res) {
            window.caches.keys().then(function (cacheNames) {
                var match = cacheNames.find(function (cn) { return cn.includes("LongTermCountSheets"); });
                if (match) {
                    caches.open(match).then(function (cache) {
                        cache.delete("" + environment.serverPath + urls.exclusion.replace(":audit_id", auditId), { ignoreSearch: true });
                    });
                }
            });
            return res;
        });
    };
    ExclusionService.prototype.deleteExclusion = function (auditId, id) {
        return this.http.delete("" + environment.serverPath + urls.exclusion.replace(":audit_id", auditId) + "/" + id).pipe(function (res) {
            window.caches.keys().then(function (cacheNames) {
                var match = cacheNames.find(function (cn) { return cn.includes("LongTermCountSheets"); });
                if (match) {
                    caches.open(match).then(function (cache) {
                        cache.delete("" + environment.serverPath + urls.exclusion.replace(":audit_id", auditId), { ignoreSearch: true });
                    });
                }
            });
            return res;
        });
    };
    ExclusionService.prototype.upload = function (auditId, formData) {
        return this.http.post("" + environment.serverPath + urls.exclusionImport.replace(":audit_id", auditId.toString()), formData).pipe(function (res) {
            window.caches.keys().then(function (cacheNames) {
                var match = cacheNames.find(function (cn) { return cn.includes("LongTermCountSheets"); });
                if (match) {
                    caches.open(match).then(function (cache) {
                        var path = "" + environment.serverPath + urls.exclusion.replace(":audit_id", auditId.toString());
                        cache.delete(path, { ignoreSearch: true });
                    });
                }
            });
            return res;
        });
    };
    ExclusionService.ngInjectableDef = i0.defineInjectable({ factory: function ExclusionService_Factory() { return new ExclusionService(i0.inject(i1.HttpClient)); }, token: ExclusionService, providedIn: "root" });
    return ExclusionService;
}());
export { ExclusionService };
