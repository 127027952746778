export const Messages  = {
  finishWithoutAttachment: 'Are you sure you want to finish without adding attachments?',
  clearCountSheet: 'Are you sure you want to clear the countsheet?',
  cancelCounting: 'Are you sure you want to cancel counting?',
  loadingAudit: 'Loading Audit',
  loadingAudits: 'Loading Audits',
  loadingManufacturer: 'Loading Manufacturer',
  loadingDistributors: 'Loading Distributors',
  loadingItems: 'Loading Items',
  loadingItemPackages: 'Loading Item Packages',
  loadingKitDefinitions: 'Loading Kit Definitions',
  loadingKits: 'Loading Kits',
  loadingItemCombinations: 'Loading Item Combinations',
  loadingItemCombinationRecallAlerts: 'Loading Item Combination Recall Alerts',
  loadingItemCombinationRecallAlertExceptions: 'Loading Item Combination Recall Alert Exceptions',
  loadingWarehouseData: 'Loading Warehouse Data',
  loadingItemSettings: 'Loading Item Settings Data',
  loadingLotPoolManagedItems: 'Loading Lot Pool Managed Items',
  loadingExclusionsData: 'Loading Exclusions Data',
  loadingUsersData: 'Loading Users Data',
  inventorySnapchatFailed: "Redspot Inventory Snapshot Generation Failed",
  inventorySnapchatSucceeded: "Redspot Inventory Snapshot Generated Successfully",
  unsupportedFileType: 'Unsupported File Type',
  salesOrderItemsAlreadyImported: 'Open sales orders items that have not counted status have already been imported, importing them again will reset the previous import. Do you want to continue?',
  importNotCountedSalesOrderItems: 'You are about to import all open sales orders items that have not counted status.',
  userNotFoundInRedspot: 'User not found in Redspot',
  userNotFound: 'User Not Found',
  loginError: 'There was an error logging you in. Contact support for help.'
};
