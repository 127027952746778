import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '../../../../node_modules/@angular/router';
import { AuditService } from './../../services/audit.service';
import { IAuditDetail } from '../../interfaces/iaudit-detail';
import { ITableFilter } from '../../interfaces/itable-filter';
import { UserService } from '../../services/user.service';
import { zip, Subscription } from 'rxjs';
import { AuditLocationService } from 'src/app/services/audit-location.service';
import { OfflineDataSynchronizerService } from 'src/app/services/offline-data-synchronizer.service';
import { MatSnackBar } from '@angular/material';
import { ISnackBarConfig } from 'src/app/interfaces/isnack-bar-config';
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';
import { OnlineService } from 'src/app/services/online.service';
import { CountSheetService } from 'src/app/services/count-sheet.service';
import { ICountSheetData } from 'src/app/interfaces/icount-sheet-data';
import { CountSheetHelper } from 'src/app/helpers/countsheet-helper';
import { TrackLoadingService } from '../../services/track-loading.service';

@Component({
  selector: 'app-countsheet-summary-tabs',
  templateUrl: './countsheet-summary-tabs.component.html',
  styleUrls: ['./countsheet-summary-tabs.component.css']
})
export class CountsheetSummaryTabsComponent implements OnInit, OnDestroy {
  @ViewChild('kitSheets') kitSheetsComponent: any;
  @ViewChild('unkittedSheets') unkittedCountsheetComponent: any;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private auditService: AuditService,
    private userService: UserService,
    private _locationService: AuditLocationService,
    private _synchronizer: OfflineDataSynchronizerService,
    public snackBar: MatSnackBar,
    private _onlineService: OnlineService,
    private _countSheetService: CountSheetService,
    private _trackLoadingService: TrackLoadingService

  ) { }

  auditLocationWarehouseDataLoaded = false;
  @Input() auditID: string;
  filters: ITableFilter;
  selectedTab: number;
  users;
  location: any;
  unkittedCountSheets: ICountSheetData[];
  kittedCountSheets: ICountSheetData[];
  ID: number;
  warehouses: any[];

  audit: IAuditDetail = {
    location_name: '',
    id: 0,
    warehouse_id: 0,
    distributorship: {},
    status: ''
  };

  snackBarConfig: ISnackBarConfig = {
    message: '',
    duration: 3000,
    success: false,
    snackBarClass: ''
  };

  countSheetHelper: CountSheetHelper = new CountSheetHelper();

  reloadSubscription: Subscription;
  startReloadingSubscription: Subscription;

  ngOnInit() {
    const that = this;
    this.ID = +this._route.snapshot.paramMap.get('id');
    this.audit.id = +this._route.snapshot.paramMap.get('audit_id');

    this.reloadSubscription = this._synchronizer.allSynchronizedEvent.subscribe(data => {
      this.reloadWhenBackOnline();
    });

    this._synchronizer.needsSyncing().then((hasUnsynced) => {
      const wouldSync = that._onlineService.isOnline() && !that._onlineService.testingOffline;
      const currentlySyncing = !that._synchronizer.doneProcessing;
      if (!wouldSync || !hasUnsynced && !currentlySyncing) {
        that.loadData();
      } else if (wouldSync && hasUnsynced && !currentlySyncing) {
        that._synchronizer.run(false);
      }
      that._route.queryParams.subscribe(params => {
        that.filters = { site: params.siteID || 'all', auditor: 'all', orderType: params.orderType, status: params.status || 'All', recall_status: 'All', validity_status: 'All', warehouse: 'all' };
        that.selectedTab = params.selected_tab || 0;
      });
    });
  }

  ngOnDestroy(): void {
    if (this.startReloadingSubscription) {
      this.startReloadingSubscription.unsubscribe();
    }

    if (this.reloadSubscription) {
      this.reloadSubscription.unsubscribe();
    }
    this.kitSheetsComponent = null;
    this.unkittedCountsheetComponent = null;
  }

  loadData() {
    const performServerCall = this._onlineService.isOnline() && !this._onlineService.getTestOfflineBool();
    this._trackLoadingService.startLoading('countsheet-summary-audit', 'Loading Audit');
    this._trackLoadingService.startLoading('countsheet-summary-users', 'Loading Users');
    this._trackLoadingService.startLoading('countsheet-summary-locations', 'Loading Locations');
    this._trackLoadingService.startLoading('countsheet-summary-count-sheets', 'Loading Count Sheets');
    this._trackLoadingService.startLoading('countsheet-summary-warehouses', 'Loading Warehouses');
    zip(
      this.auditService.getAuditById(this.audit.id),
      this.userService.getUsers(this.audit.id),
      this._locationService.getLocation(this.audit.id, this.ID),
      this.getCountSheets(performServerCall, this.audit.id),
      this.auditService.getWarehouses(this.audit.id)
    ).subscribe((data: any[]) => {
      this._trackLoadingService.stopLoading('countsheet-summary-audit');
      this._trackLoadingService.stopLoading('countsheet-summary-users');
      this._trackLoadingService.stopLoading('countsheet-summary-locations');
      this._trackLoadingService.stopLoading('countsheet-summary-count-sheets');
      this._trackLoadingService.stopLoading('countsheet-summary-warehouses');
      this.audit = data[0];
      this.users = data[1];
      this.location = data[2];
      const countSheets = data[3].filter(cs => !cs.data.archived);
      this.kittedCountSheets = countSheets.filter(cs => {
        return cs.data.audit_kit_instance_id;
      });

      this.unkittedCountSheets = countSheets.filter(cs => {
        return !cs.data.audit_kit_instance_id;
      });
      this.warehouses = data[4];
      this.auditLocationWarehouseDataLoaded = true;
    });
  }

  getCountSheets(performServerCall: boolean, auditId: number) {
    if (performServerCall) {
      const that = this;
      return this._countSheetService.getCountSheetsAndPopulateOfflineData(auditId).then((countSheets) => {
        return countSheets;
      })
    }
    return this._countSheetService.getOfflineCountSheets(auditId);
  }

  reloadWhenBackOnline() {
    this.snackBarConfig.message = 'Back Online!! Reloading...';
    this.snackBarConfig.success = true;
    this.openSnackBar();
    this._router.navigate([this.audit.id, 'countsheets', this.ID], { queryParams: { status: this.filters.status, orderType: 'consigned' } });

  }

  barcodeScanned(value: string){
    console.log(value)
    this.filters.kitSearchString = value;
    this.applyFilter();
  }

  reset(){
    this.filters.kitSearchString = "";
    this.applyFilter();
  }

  applyFilter() {
    this.kitSheetsComponent.applyFilter();
    this.unkittedCountsheetComponent.applyFilter();
  }

  updateSelectedTab(event) {
    this.selectedTab = event;
  }

  goToAuditDashboard() {
    this._router.navigate(['audit_dashboard', this.audit.id]);
  }

  openSnackBar() {
    this.snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: this.snackBarConfig,
      duration: this.snackBarConfig.duration
    });
  }

}
