<div *ngIf="loading" class="spinner">
  <mat-spinner diameter="50" color="primary">
  </mat-spinner>
</div>
<div *ngIf="loading" class="loading-message">
  <h3>{{loadingMessage}}</h3>
</div>

<div class="div" [ngClass]="{'disabled': loading }">
  <div class="row">
    <div class="col-md-12">
      <button mat-raised-button class="back-to-dashboard" type="button" (click)="goToAuditDashboard()" ngbTooltip="Go To Dashboard"
        placement="right">
        <mat-icon>dashboard</mat-icon>
        <span>Dashboard</span>
      </button>
    </div>
  </div>
  <div class="title-section">
    <h1 class="header-title center">AGENCY DASHBOARD</h1>
    <h2 *ngIf="auditDetails" class="header-title center">{{auditDetails.location_name}},
      {{auditDetails.distributorship.name}}</h2>
  </div>

  <!-- site -->
  <div class="row">
    <div class="col-6">
      <div class="progress-card">
        <mat-card class="stat-card" *ngIf="siteStats">
          <mat-card-title>Site Progress</mat-card-title>
          <mat-card-content>
            <div class="progress-bar">
              <section>
                <mat-progress-bar class="example-margin" [color]="accent" [mode]="determinate" [value]="siteStats.fraction"
                  [bufferValue]="bufferValue">
                </mat-progress-bar>
              </section>
            </div>
            <h5 style="margin-left: 10px;">
              <mat-chip-list>
                <mat-chip class="progress-expected" selected>{{siteStats.planned}} {{siteStats.planned > 1? 'SITES': 'SITE'}} PLANNED</mat-chip>
                <mat-chip class="in-progress" selected>{{siteStats.progress}} {{siteStats.progress > 1? 'SITES': 'SITE'}} IN-PROGRESS</mat-chip>
                <mat-chip class="progress-counted" selected>
                  {{siteStats.complete}} {{siteStats.complete > 1? 'SITES': 'SITE'}} COMPLETED
                </mat-chip>
                <mat-chip class="progress-complete" selected>
                  {{siteStats.fraction}}% COMPLETE
                </mat-chip>
              </mat-chip-list>
            </h5>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <div class="col-6">
      <!-- warehouse -->
      <div class="progress-card">
        <mat-card class="stat-card" *ngIf="warehouseStats">
          <mat-card-title>Warehouse Progress</mat-card-title>
          <mat-card-content>
            <div class="progress-bar">
              <section>
                <mat-progress-bar class="example-margin" [color]="accent" [mode]="determinate" [value]="warehouseStats.fraction"
                  [bufferValue]="bufferValue">
                </mat-progress-bar>
              </section>
            </div>
            <h5 style="margin-left: 10px;">
              <mat-chip-list>
                <mat-chip class="progress-expected" selected>{{warehouseStats.planned}} {{warehouseStats.planned > 1? 'WAREHOUSES': 'WAREHOUSE'}} PLANNED</mat-chip>
                <mat-chip class="in-progress" selected>{{warehouseStats.progress}} {{warehouseStats.progress > 1? 'WAREHOUSES': 'WAREHOUSE'}} IN-PROGRESS</mat-chip>
                <mat-chip class="progress-counted" selected>
                  {{warehouseStats.complete}} {{warehouseStats.complete > 1? 'WAREHOUSES': 'WAREHOUSE'}} COMPLETED
                </mat-chip>
                <mat-chip class="progress-complete" selected>
                  {{warehouseStats.fraction}}% COMPLETE
                </mat-chip>
              </mat-chip-list>
            </h5>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <!--Kit Stats-->
      <div class="progress-card">
        <mat-card class="stat-card" *ngIf="kitStats">
          <mat-card-title>Kit Progress</mat-card-title>
          <mat-card-content>
            <div class="progress-bar">
              <section>
                <mat-progress-bar class="example-margin" [mode]="determinate" [value]="kitStats.fraction" [bufferValue]="bufferValue">
                </mat-progress-bar>
              </section>
            </div>
            <h5 style="margin-left: 10px;">
              <mat-chip-list>
                <mat-chip class="progress-expected" selected>{{kitStats.planned}} {{kitStats.planned > 1? 'KITS':'KIT'}} PLANNED</mat-chip>
                <mat-chip class="in-progress clickable"  selected>{{kitStats.progress}} {{kitStats.progress > 1? 'KITS':'KIT'}} IN-PROGRESS</mat-chip>
                <mat-chip class="progress-counted clickable"  selected>
                  {{kitStats.complete}} {{kitStats.complete > 1? 'KITS':'KIT'}} NEEDS VERIFICATION
                </mat-chip>
                <mat-chip class="progress-counted clickable" selected>
                  {{kitStats.verified}} {{kitStats.verified > 1? 'KITS':'KIT'}} VERIFIED
                </mat-chip>
                <mat-chip class="progress-complete" selected>
                  {{kitStats.fraction}}% COMPLETE
                </mat-chip>
              </mat-chip-list>
            </h5>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <div class="col-6">
      <!--Item Stats-->
      <div class="progress-card">
        <mat-card class="stat-card" *ngIf="itemStats">
          <mat-card-title>Item Progress</mat-card-title>
          <mat-card-content>
              <div class="progress-bar">
                  <section>
                    <mat-progress-bar class="example-margin" [color]="accent" [mode]="determinate" [value]="itemStats.fraction"
                      [bufferValue]="bufferValue">
                    </mat-progress-bar>
                  </section>
                </div>
            <h5 style="margin-left: 10px;">
              <mat-chip-list>
                <mat-chip class="progress-expected" selected>{{itemStats.planned}} {{itemStats.planned > 1? 'ITEMS': 'ITEM'}} PLANNED</mat-chip>
                <mat-chip class="progress-counted" selected>{{itemStats.complete}} {{itemStats.complete > 1? 'ITEMS':'ITEM'}} COUNTED</mat-chip>
                <mat-chip class="progress-complete" selected>{{itemStats.planned === 0 ? 0 : itemStats.fraction}}% COMPLETE</mat-chip>
              </mat-chip-list>
            </h5>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <!-- item -->

  </div>
  <div class="row">
    <div class="col-12">
      <app-sap-item-table (dataLoadedEvent)="loadingComplete()" [viewMode]="tableMode" #itemsTable></app-sap-item-table>
    </div>
  </div>
</div>
