var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator];
    return m ? m.call(o) : typeof __values === "function" ? __values(o) : o[Symbol.iterator]();
};
import { FileOptionUtils } from '../helpers/file-option-utils';
import * as i0 from "@angular/core";
import * as i1 from "../helpers/file-option-utils";
var FileSystemService = /** @class */ (function () {
    function FileSystemService(_fileOptionUtils) {
        this._fileOptionUtils = _fileOptionUtils;
        this.storage = navigator.storage;
        this.tree = [];
    }
    FileSystemService.prototype.save = function (contents, options) {
        return __awaiter(this, void 0, void 0, function () {
            var handle, writable;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getSaveFileHandle(options)];
                    case 1:
                        handle = _a.sent();
                        if (!handle) return [3 /*break*/, 5];
                        return [4 /*yield*/, handle.createWritable()];
                    case 2:
                        writable = _a.sent();
                        return [4 /*yield*/, writable.write(contents)];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, writable.close()];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    FileSystemService.prototype.saveContent = function (contents, fileHandle) {
        return __awaiter(this, void 0, void 0, function () {
            var writable;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fileHandle.createWritable()];
                    case 1:
                        writable = _a.sent();
                        return [4 /*yield*/, writable.write(contents)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, writable.close()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    FileSystemService.prototype.saveFile = function (file, handle) {
        return __awaiter(this, void 0, void 0, function () {
            var readableStream, writable;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        readableStream = file.stream();
                        return [4 /*yield*/, handle.createWritable()];
                    case 1:
                        writable = _a.sent();
                        return [4 /*yield*/, readableStream.pipeTo(writable)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    FileSystemService.prototype.getSaveFileHandle = function (options) {
        return __awaiter(this, void 0, void 0, function () {
            var handle, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        handle = null;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, window.showSaveFilePicker(options)];
                    case 2:
                        handle = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.log(error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, handle];
                }
            });
        });
    };
    FileSystemService.prototype.pickFile = function (options) {
        return __awaiter(this, void 0, void 0, function () {
            var fileHandle, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        fileHandle = null;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, window.showOpenFilePicker(options)];
                    case 2:
                        fileHandle = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        console.log(error_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, fileHandle];
                }
            });
        });
    };
    // returns handle of existing or created directory
    FileSystemService.prototype.getDirectoryHandle = function (baseDirectoryHandle, options) {
        return __awaiter(this, void 0, void 0, function () {
            var dirHandle;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, baseDirectoryHandle.getDirectoryHandle(options.directoryName, {
                            create: options.createDirectory
                        })];
                    case 1:
                        dirHandle = _a.sent();
                        return [2 /*return*/, dirHandle];
                }
            });
        });
    };
    FileSystemService.prototype.pickDirectory = function () {
        return __awaiter(this, void 0, void 0, function () {
            var directoryHandle, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        directoryHandle = null;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, window.showDirectoryPicker()];
                    case 2:
                        directoryHandle = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_3 = _a.sent();
                        console.log(error_3);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, directoryHandle];
                }
            });
        });
    };
    FileSystemService.prototype.verifyPermission1 = function (fileHandle, withWrite) {
        return __awaiter(this, void 0, void 0, function () {
            var opts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        opts = {};
                        if (withWrite) {
                            opts.writable = true;
                            // For Chrome 86 and later...
                            opts.mode = 'readwrite';
                        }
                        return [4 /*yield*/, fileHandle.queryPermission(opts)];
                    case 1:
                        // Check if we already have permission, if so, return true.
                        if ((_a.sent()) === 'granted') {
                            return [2 /*return*/, true];
                        }
                        return [4 /*yield*/, fileHandle.requestPermission(opts)];
                    case 2:
                        // Request permission to the file, if the user grants permission, return true.
                        if ((_a.sent()) === 'granted') {
                            return [2 /*return*/, true];
                        }
                        // The user did nt grant permission, return false.
                        return [2 /*return*/, false];
                }
            });
        });
    };
    FileSystemService.prototype.verifyPermission = function (fileHandle, withWrite) {
        return new Promise(function (resolve, reject) {
            var opts = {};
            if (withWrite) {
                opts.writable = true;
                // For Chrome 86 and later...
                opts.mode = 'readwrite';
            }
            fileHandle.queryPermission(opts).then(function (flag) {
                if (flag === 'granted') {
                    return resolve(true);
                }
                fileHandle.requestPermission(opts).then(function (flag) {
                    if (flag === 'granted') {
                        return resolve(true);
                    }
                    return resolve(false);
                });
            });
        });
    };
    FileSystemService.prototype.getFile = function (fileHandle) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.verifyPermission(fileHandle, false).then(function (granted) {
                if (granted) {
                    fileHandle.getFile().then(function (file) {
                        resolve(file);
                    });
                }
                else {
                    reject();
                }
            });
        });
    };
    FileSystemService.prototype.getFiles = function (fileHandles) {
        return __awaiter(this, void 0, void 0, function () {
            var files, that, _a, _b, _i, fh, file;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        files = [];
                        that = this;
                        _a = [];
                        for (_b in fileHandles)
                            _a.push(_b);
                        _i = 0;
                        _c.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 4];
                        fh = _a[_i];
                        if (!fh) return [3 /*break*/, 3];
                        return [4 /*yield*/, that.getFile(fh)];
                    case 2:
                        file = _c.sent();
                        if (file) {
                            files.push(file);
                        }
                        _c.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/, files];
                }
            });
        });
    };
    FileSystemService.prototype.getContents = function (fileHandle) {
        return __awaiter(this, void 0, void 0, function () {
            var file, contents;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getFile(fileHandle)];
                    case 1:
                        file = _a.sent();
                        return [4 /*yield*/, file.text()];
                    case 2:
                        contents = _a.sent();
                        return [2 /*return*/, contents];
                }
            });
        });
    };
    FileSystemService.prototype.getRoot = function () {
        return __awaiter(this, void 0, void 0, function () {
            var root;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.getDirectory()];
                    case 1:
                        root = _a.sent();
                        return [2 /*return*/, root];
                }
            });
        });
    };
    // root operations
    FileSystemService.prototype.createDirectoryFromRoot = function (options) {
        return __awaiter(this, void 0, void 0, function () {
            var root, _a, _b, v, e_1_1, directoryHandle, error_4, e_1, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, this.getRoot()];
                    case 1:
                        root = _d.sent();
                        _d.label = 2;
                    case 2:
                        _d.trys.push([2, 8, 9, 14]);
                        _a = __asyncValues(root.values());
                        _d.label = 3;
                    case 3: return [4 /*yield*/, _a.next()];
                    case 4:
                        if (!(_b = _d.sent(), !_b.done)) return [3 /*break*/, 7];
                        return [4 /*yield*/, _b.value];
                    case 5:
                        v = _d.sent();
                        console.log('entry', v);
                        _d.label = 6;
                    case 6: return [3 /*break*/, 3];
                    case 7: return [3 /*break*/, 14];
                    case 8:
                        e_1_1 = _d.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 14];
                    case 9:
                        _d.trys.push([9, , 12, 13]);
                        if (!(_b && !_b.done && (_c = _a.return))) return [3 /*break*/, 11];
                        return [4 /*yield*/, _c.call(_a)];
                    case 10:
                        _d.sent();
                        _d.label = 11;
                    case 11: return [3 /*break*/, 13];
                    case 12:
                        if (e_1) throw e_1.error;
                        return [7 /*endfinally*/];
                    case 13: return [7 /*endfinally*/];
                    case 14:
                        directoryHandle = null;
                        _d.label = 15;
                    case 15:
                        _d.trys.push([15, 17, , 18]);
                        return [4 /*yield*/, root.getDirectoryHandle(options.directoryName, { create: options.createDirectory })];
                    case 16:
                        directoryHandle = _d.sent();
                        return [3 /*break*/, 18];
                    case 17:
                        error_4 = _d.sent();
                        console.log(error_4);
                        return [3 /*break*/, 18];
                    case 18: return [2 /*return*/, directoryHandle];
                }
            });
        });
    };
    FileSystemService.prototype.createDirectoryFrom = function (directoryHandle, options) {
        return __awaiter(this, void 0, void 0, function () {
            var dirHandle, error_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        dirHandle = null;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, directoryHandle.getDirectoryHandle(options.directoryName, { create: options.createDirectory })];
                    case 2:
                        dirHandle = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_5 = _a.sent();
                        console.log(error_5);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, dirHandle];
                }
            });
        });
    };
    FileSystemService.prototype.getDirectoryFromRoot = function (options) {
        return __awaiter(this, void 0, void 0, function () {
            var root, directoryHandle, error_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getRoot()];
                    case 1:
                        root = _a.sent();
                        directoryHandle = null;
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, root.getDirectoryHandle(options.directoryName, { create: false })];
                    case 3:
                        directoryHandle = _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        error_6 = _a.sent();
                        console.log(error_6);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/, directoryHandle];
                }
            });
        });
    };
    FileSystemService.prototype.createFileFromRoot = function (options) {
        return __awaiter(this, void 0, void 0, function () {
            var root, fileHandle, error_7;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getRoot()];
                    case 1:
                        root = _a.sent();
                        fileHandle = null;
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, root.getFileHandle(options.filename, { create: options.createFile })];
                    case 3:
                        fileHandle = _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        error_7 = _a.sent();
                        console.log(error_7);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/, fileHandle];
                }
            });
        });
    };
    FileSystemService.prototype.createFileFrom = function (directoryHandle, options) {
        return __awaiter(this, void 0, void 0, function () {
            var fileHandle, error_8;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        fileHandle = null;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, directoryHandle.getFileHandle(options.filename, { create: options.createFile })];
                    case 2:
                        fileHandle = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_8 = _a.sent();
                        console.log(error_8);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, fileHandle];
                }
            });
        });
    };
    FileSystemService.prototype.removeFileFromRoot = function (options) {
        return __awaiter(this, void 0, void 0, function () {
            var root, error_9;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getRoot()];
                    case 1:
                        root = _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, root.removeEntry(options.filename)];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        error_9 = _a.sent();
                        console.log(error_9);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    FileSystemService.prototype.removeDirectoryFromRoot = function (options) {
        return __awaiter(this, void 0, void 0, function () {
            var root, error_10;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getRoot()];
                    case 1:
                        root = _a.sent();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, root.removeEntry(options.directoryName)];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        error_10 = _a.sent();
                        console.log(error_10);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    FileSystemService.prototype.getEntriesInRoot = function () {
        return __awaiter(this, void 0, void 0, function () {
            var root, entries, _a, _b, entry, e_2_1, e_2, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, this.getRoot()];
                    case 1:
                        root = _d.sent();
                        entries = [];
                        _d.label = 2;
                    case 2:
                        _d.trys.push([2, 8, 9, 14]);
                        _a = __asyncValues(root.values());
                        _d.label = 3;
                    case 3: return [4 /*yield*/, _a.next()];
                    case 4:
                        if (!(_b = _d.sent(), !_b.done)) return [3 /*break*/, 7];
                        return [4 /*yield*/, _b.value];
                    case 5:
                        entry = _d.sent();
                        entries.push(entry);
                        _d.label = 6;
                    case 6: return [3 /*break*/, 3];
                    case 7: return [3 /*break*/, 14];
                    case 8:
                        e_2_1 = _d.sent();
                        e_2 = { error: e_2_1 };
                        return [3 /*break*/, 14];
                    case 9:
                        _d.trys.push([9, , 12, 13]);
                        if (!(_b && !_b.done && (_c = _a.return))) return [3 /*break*/, 11];
                        return [4 /*yield*/, _c.call(_a)];
                    case 10:
                        _d.sent();
                        _d.label = 11;
                    case 11: return [3 /*break*/, 13];
                    case 12:
                        if (e_2) throw e_2.error;
                        return [7 /*endfinally*/];
                    case 13: return [7 /*endfinally*/];
                    case 14: return [2 /*return*/, entries];
                }
            });
        });
    };
    FileSystemService.prototype.getDirectoriesInRoot = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var rootEntries, dirs;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getEntriesInRoot()];
                    case 1:
                        rootEntries = _a.sent();
                        dirs = rootEntries.filter(function (x) {
                            return x.kind === _this._fileOptionUtils.FILE_SYSTEM_HANDLE_KINDS.directory;
                        });
                        return [2 /*return*/, dirs];
                }
            });
        });
    };
    FileSystemService.prototype.getFilesInRoot = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var rootEntries, files;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getEntriesInRoot()];
                    case 1:
                        rootEntries = _a.sent();
                        files = rootEntries.filter(function (x) {
                            return x.kind === _this._fileOptionUtils.FILE_SYSTEM_HANDLE_KINDS.file;
                        });
                        return [2 /*return*/, files];
                }
            });
        });
    };
    FileSystemService.prototype.getPath = function (fileHandle) {
        return __awaiter(this, void 0, void 0, function () {
            var root, path;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getRoot()];
                    case 1:
                        root = _a.sent();
                        return [4 /*yield*/, root.resolve(fileHandle)];
                    case 2:
                        path = _a.sent();
                        return [2 /*return*/, path];
                }
            });
        });
    };
    FileSystemService.prototype.printRootTree = function () {
        return __awaiter(this, void 0, void 0, function () {
            var root, handles;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getRoot()];
                    case 1:
                        root = _a.sent();
                        return [4 /*yield*/, this.walkDir(root, null)];
                    case 2:
                        handles = _a.sent();
                        return [2 /*return*/, handles];
                }
            });
        });
    };
    FileSystemService.prototype.walkDir = function (root, list) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var entries, _a, _b, entry, e_3_1, e_3, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        entries = [];
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 7, 8, 13]);
                        _a = __asyncValues(root.values());
                        _d.label = 2;
                    case 2: return [4 /*yield*/, _a.next()];
                    case 3:
                        if (!(_b = _d.sent(), !_b.done)) return [3 /*break*/, 6];
                        return [4 /*yield*/, _b.value];
                    case 4:
                        entry = _d.sent();
                        entries.push(entry);
                        _d.label = 5;
                    case 5: return [3 /*break*/, 2];
                    case 6: return [3 /*break*/, 13];
                    case 7:
                        e_3_1 = _d.sent();
                        e_3 = { error: e_3_1 };
                        return [3 /*break*/, 13];
                    case 8:
                        _d.trys.push([8, , 11, 12]);
                        if (!(_b && !_b.done && (_c = _a.return))) return [3 /*break*/, 10];
                        return [4 /*yield*/, _c.call(_a)];
                    case 9:
                        _d.sent();
                        _d.label = 10;
                    case 10: return [3 /*break*/, 12];
                    case 11:
                        if (e_3) throw e_3.error;
                        return [7 /*endfinally*/];
                    case 12: return [7 /*endfinally*/];
                    case 13:
                        list = list || [];
                        entries.forEach(function (h) { return __awaiter(_this, void 0, void 0, function () {
                            var baseRoot, path;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(h.kind === 'directory')) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this.walkDir(h, list)];
                                    case 1:
                                        list = _a.sent();
                                        return [3 /*break*/, 5];
                                    case 2:
                                        list.push(h);
                                        return [4 /*yield*/, this.getRoot()];
                                    case 3:
                                        baseRoot = _a.sent();
                                        return [4 /*yield*/, baseRoot.resolve(h)];
                                    case 4:
                                        path = _a.sent();
                                        console.log("root > " + path.join('/'));
                                        _a.label = 5;
                                    case 5: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [2 /*return*/, list];
                }
            });
        });
    };
    FileSystemService.ngInjectableDef = i0.defineInjectable({ factory: function FileSystemService_Factory() { return new FileSystemService(i0.inject(i1.FileOptionUtils)); }, token: FileSystemService, providedIn: "root" });
    return FileSystemService;
}());
export { FileSystemService };
