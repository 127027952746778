import { DatabaseService } from './database.service';
import * as i0 from "@angular/core";
import * as i1 from "./database.service";
var AuditDatabaseService = /** @class */ (function () {
    function AuditDatabaseService(_databaseService) {
        this._databaseService = _databaseService;
        this.storeName = "audits";
    }
    AuditDatabaseService.prototype.clear = function () {
        return this._databaseService.clear(this.storeName);
    };
    AuditDatabaseService.prototype.get = function (id) {
        return this._databaseService.get(this.storeName, parseInt(id, 10));
    };
    AuditDatabaseService.prototype.bulkAdd = function (datas) {
        return this._databaseService.bulkAdd(this.storeName, datas);
    };
    AuditDatabaseService.ngInjectableDef = i0.defineInjectable({ factory: function AuditDatabaseService_Factory() { return new AuditDatabaseService(i0.inject(i1.DatabaseService)); }, token: AuditDatabaseService, providedIn: "root" });
    return AuditDatabaseService;
}());
export { AuditDatabaseService };
