import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort, MatSnackBar } from '@angular/material';
import { LotPoolManagedItemsListDataSource } from './lot-pool-managed-items-list-datasource';
import { LotPoolManagedItemService } from '../../services/lot-pool-managed-item.service';
import { ISnackBarConfig } from '../../interfaces/isnack-bar-config';
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';
import { OnlineService } from 'src/app/services/online.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-lot-pool-managed-items-list',
  templateUrl: './lot-pool-managed-items-list.component.html',
  styleUrls: ['./lot-pool-managed-items-list.component.css']
})
export class LotPoolManagedItemsListComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  onlineSubscription: Subscription;
  goOfflineSubscription: Subscription;
  lotPoolManagedItemsUpdated: Subscription;
  isOnline: boolean;
  displayedColumns = ['reference', 'description', 'action'];
  snackBarConfig: ISnackBarConfig = {
    message: '',
    duration: 3000,
    success: false,
    snackBarClass: ''
  };
  dataSource: LotPoolManagedItemsListDataSource;
  lotPoolManagedItems: Array<object> = [];

  constructor(
    private _lotPoolManagedItemService: LotPoolManagedItemService,
    public snackBar: MatSnackBar,
    private _onlineService: OnlineService
  ) { }

  ngOnInit() {
    this.getLotPoolManagedItems();
    this.onlineSubscription = this._onlineService.isOnlineSubscription().subscribe(
      online => {
        this.isOnline = online && !this._onlineService.getTestOfflineBool();
      }
    );

    this.goOfflineSubscription = this._onlineService.goOfflineEvent.subscribe((data: boolean) => {
      this.isOnline = !data;
    });

    this._lotPoolManagedItemService.updatedEmitter.subscribe(() => {
      this.getLotPoolManagedItems();
    });
  }

  ngOnDestroy(): void {
    if (this.onlineSubscription) {
      this.onlineSubscription.unsubscribe();
    }

    if (this.goOfflineSubscription) {
      this.goOfflineSubscription.unsubscribe();
    }

    if (this.lotPoolManagedItemsUpdated) {
      this.lotPoolManagedItemsUpdated.unsubscribe();
    }
  }

  getLotPoolManagedItems() {
    this._lotPoolManagedItemService.getLotPoolManagedItems().subscribe((data: Array<object>) => {
      this.lotPoolManagedItems = data;
      this.dataSource = new LotPoolManagedItemsListDataSource(this.paginator, this.sort, this.lotPoolManagedItems);
    });
  }

  deleteLotPoolManagedItem(id: number) {
    this._lotPoolManagedItemService.delete(id).subscribe(() => {
      this.lotPoolManagedItems = this.lotPoolManagedItems.filter(e => e['id'] !== id);
      this.dataSource = new LotPoolManagedItemsListDataSource(this.paginator, this.sort, this.lotPoolManagedItems);
      this.snackBarConfig.message = 'Item Removed Successfully';
      this.snackBarConfig.success = true;
      this.openSnackBar();
    }, () => {
      this.snackBarConfig.message = 'Item Not Removed';
      this.snackBarConfig.success = false;
      this.openSnackBar();
    });
  }

  openSnackBar() {
    this.snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: this.snackBarConfig,
      duration: this.snackBarConfig.duration
    });
  }

}
