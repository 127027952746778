import { Injectable } from '@angular/core';
import { OnlineService } from './online.service';
import { CountSheetDatabaseService } from './../services/count-sheet-database.service';
import { CountSheetItemDatabaseService } from './../services/count-sheet-item-database.service';
import { AttachmentDatabaseService } from './../services/attachment-database.service';
import { FileDatabaseService } from './../services/file-database.service';
import { AuditKitInstanceDatabaseService } from './../services/audit-kit-instance-database.service';
import { CountSheetService } from './../services/count-sheet.service';
import { CountSheetItemService } from './../services/count-sheet-item.service';
import { AuditKitInstanceService } from './../services/audit-kit-instance.service';
import { CountSheetAttachmentService } from './../services/count-sheet-attachment.service';
import * as moment from 'moment';
import { EventEmitter } from '@angular/core';
import { AuthService } from './auth.service';
import { zip } from 'rxjs';
import { TrackLoadingService } from './track-loading.service';
import { FileSystemService } from './../services/file-system.service';


@Injectable({
  providedIn: 'root'
})
export class OfflineDataSynchronizerService {

  allSynchronizedEvent: EventEmitter<any>;
  loadingErrorEvent: EventEmitter<any>;
  savingErrorEvent: EventEmitter<any>;
  doneProcessing: boolean = true;

  // count sheets can only be created or updated
  // count sheet items can be created, updated, or destroyed
  countSheetData() {
    const that = this;
    const promise = new Promise((resolve, reject) => {
      zip(
        that._countSheetDatabaseService.getDataByIndex('isSynchronized', 0),
        that._countSheetItemDatabaseService.getDataByIndex('isSynchronized', 0),
        that._attachmentDatabaseService.getDataByIndex('synchronized', 0)
      ).subscribe((data: any[]) => {
        //we want archived count sheets to sync first otherwise we get an incorrect match
        const countSheets = data[0].sort(function (a, b) {
          if (a.data.archived && !b.data.archived) {
            return -1;
          }
          if (!a.data.archived && b.data.archived) {
            return 1;
          }
          return 0;
        }).map(cs => {
          const countSheet = new Object({
            audit_id: cs.data.audit_id,
            warehouse_id: cs.data.warehouse_id,
            audit_location_id: cs.data.audit_location_id,
            audit_kit_instance_id: cs.data.audit_kit_instance_id,
            area: cs.data.area,
            count_sheet_status: cs.data.count_sheet_status,
            kit_id: cs.data.kit_id,
            id: cs.dbId,
            client_id: cs.id,
            archived: cs.data.archived,
            show_expected_item_list: cs.data.show_expected_item_list,
            skip_regrouping_callback: true,
            count_sheet_items: [],
            attachments: []
          });
          if (cs.data.counted_by) {
            countSheet['counted_by_id'] = cs.data.counted_by.user_id;
            countSheet['counted_time'] = moment(cs.data.counted_time).toDate();
          }
          if (cs.data.verified_by) {
            countSheet['verified_by_id'] = cs.data.verified_by.user_id;
            countSheet['verified_time'] = moment(cs.data.verified_time).toDate();
          }
          return countSheet;
        });
        data[1].forEach(csi => {
          let matchingCountSheet = countSheets.find(function (cs) {
            return cs['client_id'] && cs['client_id'] === csi.data.count_sheet_client_id;
          });
          if (!matchingCountSheet) {
            matchingCountSheet = new Object({
              id: csi.data.count_sheet_id,
              client_id: csi.data.count_sheet_client_id,
              count_sheet_items: [],
              attachments: []
            });
            countSheets.push(matchingCountSheet);
          }
          matchingCountSheet['count_sheet_items'].push({
            _destroy: csi._destroy,
            item_id: csi.data.item_id,
            reference: csi.data.reference,
            lot_number: csi.data.lot_number,
            serial_number: csi.data.serial,
            quantity: csi.data.quantity,
            id: csi.dbId,
            client_id: csi.id,
            checked: csi.data.checked,
            manually_entered: csi.data.manually_entered,
            expiration_date: csi.data.expiration_date,
            hide: csi.data.hide,
            rank: csi.data.rank
          });
        });

        zip(
          ...data[2].map(a => that._fileDatabaseService.get(a.local_file_id)).concat(new Promise((r, a) => { r(null); }))
        ).subscribe((fileLocations: any[]) => {
          fileLocations = fileLocations.filter(fl => !!fl);

          zip(
            ...fileLocations.map((fl:any) => {
              if (fl.file){
                return new Promise((r,a) => {r(fl.file)})
              }else{
                return that._fileSystemService.getFile(fl.handle)
              }
            
            }).concat(new Promise((r, a) => { r(null); }))
          ).subscribe((files) => {
            data[2].forEach(a => {
              let matchingCountSheet = countSheets.find(function (cs) {
                return cs['client_id'] && cs['client_id'] === a.count_sheet_client_id;
              });
              if (!matchingCountSheet) {
                matchingCountSheet = new Object({
                  id: a.count_sheet_id,
                  client_id: a.count_sheet_client_id,
                  count_sheet_items: [],
                  attachments: []
                });
                countSheets.push(matchingCountSheet);
              }
              let fileLocation = fileLocations.find(f => (f || {}).local_file_id === a.local_file_id);
              let index = fileLocations.indexOf(fileLocation);
              let file = files[index];
              //attachments only need updating if they are new and exist or are being deleted
              if (!a.id && file || (a.id && a.toRemove)) {
                let attachment = new Object({
                  local_file_id: a.local_file_id,
                  audit_id: a.audit_id,
                  count_sheet_client_id: a.count_sheet_client_id,
                  attachment: file
                })
                if (a['id']) {
                  attachment['id'] = a['id'];
                }
                if (a['toRemove']) {
                  attachment['_destroy'] = a['toRemove'];
                }
                matchingCountSheet['attachments'].push(attachment);
              }
            });
            resolve(countSheets);
          });
        });


      });
    });
    return promise;
  }

  needsSyncing() {
    const that = this;
    this._trackLoading.startLoading('offline-data-synchronizer-need-syncing', 'Checking For Unsynced Data');
    let promise = new Promise((resolve, reject) => {
      zip(
        that._countSheetDatabaseService.getDataByIndex('isSynchronized', 0),
        that._countSheetItemDatabaseService.getDataByIndex('isSynchronized', 0),
        that._attachmentDatabaseService.getDataByIndex('synchronized', 0)
      ).subscribe((data: any[]) => {
        this._trackLoading.stopLoading('offline-data-synchronizer-need-syncing');
        const syncingNeeded = data[0].length > 0 || data[1].length > 0 || data[2].length > 0;
        resolve(syncingNeeded);
        return syncingNeeded;
      });
    });
    return promise;
  }

  constructor(
    private _onlineService: OnlineService,
    private _countSheetDatabaseService: CountSheetDatabaseService,
    private _countSheetItemDatabaseService: CountSheetItemDatabaseService,
    private _auditKitInstanceDatabaseService: AuditKitInstanceDatabaseService,
    private _countSheetService: CountSheetService,
    private _countSheetItemService: CountSheetItemService,
    private _auditKitInstanceService: AuditKitInstanceService,
    private _authService: AuthService,
    private _trackLoading: TrackLoadingService,
    private _attachmentDatabaseService: AttachmentDatabaseService,
    private _fileDatabaseService: FileDatabaseService,
    private _fileSystemService: FileSystemService,
    private _countSheetAttachmentService: CountSheetAttachmentService
  ) {
    const that = this;
    that.allSynchronizedEvent = new EventEmitter<any>();
    that.loadingErrorEvent = new EventEmitter<any>();
    that.savingErrorEvent = new EventEmitter<any>();
    that._onlineService.isOnlineSubscription().subscribe(online => {
      that.needsSyncing().then((needed) => {
        if (that.doneProcessing && needed && online && that._authService.isLoggedIn && !that._onlineService.getTestOfflineBool()) {
          that.run();
        }
      })
    });
    that._authService.authenticatedEvent.subscribe(isLoggedIn => {
      that.needsSyncing().then((needed) => {
        if (that.doneProcessing && needed && that._onlineService.isOnline() && that._authService.isLoggedIn && !that._onlineService.getTestOfflineBool()) {
          that.run();
        }
      })
    });
  }

  cleanData(countSheets, cleanRun) {
    if (cleanRun) {
      countSheets.forEach(cs => {
        cs['count_sheet_items'] = cs['count_sheet_items'].filter(csi => (csi.reference || csi.item_id));
      });
    }
    return countSheets;
  }

  run(cleanRun: boolean = false) {
    this.doneProcessing = false;
    const that = this;
    that._trackLoading.startLoading('offline-data-synchronizer-run', 'Synchronizing offline Data');
    this.countSheetData().then(function (countSheets: any[]) {
      if (countSheets.length > 0) {
        that._countSheetService.bulkUpdate(that.cleanData(countSheets, cleanRun), {}).subscribe(updatedCountSheets => {
          that._trackLoading.stopLoading('offline-data-synchronizer-run');
          that._trackLoading.startLoading('offline-data-synchronizer-reloading', 'Reloading Count Sheet Data');
          //might want to make this only clear collections for the syncing audits?
          zip(
            that._countSheetDatabaseService.clear(),
            that._countSheetItemDatabaseService.clear(),
            that._auditKitInstanceDatabaseService.clear(),
            that._attachmentDatabaseService.clear()
          ).subscribe(_ => {
            const uniqueAuditIds = [...Array.from(new Set(updatedCountSheets.map(cs => cs.audit_id)))];
            const dataFetches = [];
            uniqueAuditIds.forEach(auditId => {
              dataFetches.push(that._auditKitInstanceService.getAuditKitInstancesAndPopulateOfflineData(auditId));
              dataFetches.push(that._countSheetService.getCountSheetsAndPopulateOfflineData(auditId));
              dataFetches.push(that._countSheetItemService.getCountSheetItemsAndClearOfflineData(auditId));
              dataFetches.push(that._countSheetAttachmentService.getAttachmentsAndPopulateOfflineData(auditId));
            });
            zip(...dataFetches).subscribe(data => {
              that._trackLoading.stopLoading('offline-data-synchronizer-reloading');
              that.allSynchronizedEvent.emit({ids: uniqueAuditIds});
              that.doneProcessing = true;
            }, res => {
              that._trackLoading.stopLoading('offline-data-synchronizer-reloading');
              that.loadingErrorEvent.emit(countSheets);
              that.doneProcessing = true;
            });
          });
        }, res => {
          that._trackLoading.stopLoading('offline-data-synchronizer-run');
          that.savingErrorEvent.emit(countSheets);
          that.doneProcessing = true;
        });
      } else {
        that._trackLoading.stopLoading('offline-data-synchronizer-run');
        that.doneProcessing = true;
      }
    });
  }
}
