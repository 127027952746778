<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav mat-elevation-z24" fixedInViewport="true"
    [ngClass]="{hidden: !(isHandset$ | async)}" [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="!(isHandset$ | async)">
    <mat-toolbar color="primary">
      Audit
    </mat-toolbar>
    <mat-nav-list>
      <a mat-list-item routerLink="/audit">Audits</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar class="mat-elevation-z24" color="primary">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <a class="navbar-item">
        <img width="100px" src="assets/img/ArthrexAuditToolWhite.png">
      </a>

      <!-- <button mat-button>v{{auditVersion}}</button> -->
      <button *ngIf="updateAvailable" mat-button color="warn" ngbTooltip="New version available, click to update" placement="right"
        (click)="openReloadConfirmationDialog()" [ngClass]="{'disabled': !connected }">v{{auditVersion}}</button>
      <button *ngIf="!updateAvailable" mat-button>v{{auditVersion}}</button>

      <span *ngIf="!(isHandset$ | async) && userData">
        <button mat-button routerLink="/audit">Audits</button>
      </span>
      <span class="example-spacer"></span>
      <span *ngIf='userData'>
        <button *ngxPermissionsOnly="['audit_administrator']" mat-button [matMenuTriggerFor]="adminMenu"
          class="no-padding"> ADMIN </button>
        <mat-menu [overlapTrigger]="false" #adminMenu="matMenu" yPosition="below">
          <button mat-menu-item (click)="openBatchValidationDialog()" [ngClass]="{'disabled': !connected }">Batch
            Validation Import</button>
          <button mat-menu-item (click)="openLotPoolManagedItemImportDialog()" [ngClass]="{'disabled': !connected }">Lot 
            Pool Managed Item Import</button>
        </mat-menu>
      </span>

      <button *ngIf="userData" mat-button class="no-padding">{{userData.first_name}} {{userData.last_name}}</button>
      <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="userData">
        <mat-icon class="login-icon">account_circle</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="manuallySyncData()" [ngClass]="{'disabled': !connected }">Sync Data</button>
        <button mat-menu-item (click)="openClearCacheConfirmationDialog()" [ngClass]="{'disabled': !connected }">Clear Cache</button>
        <button mat-menu-item (click)="logout()" [ngClass]="{'disabled': !connected }">Log Out</button>
      </mat-menu>

      <button mat-icon-button [matMenuTriggerFor]="menu2">
        <mat-icon class="login-icon" ngbTooltip="Un-Muted" placement="left" *ngIf="currentCountSheetAlertSetting == ''">
          volume_up</mat-icon>
        <mat-icon class="login-icon" ngbTooltip="Success Alert Muted" placement="left"
          *ngIf="currentCountSheetAlertSetting == 'success'">volume_mute</mat-icon>
        <mat-icon class="login-icon" ngbTooltip="Muted" placement="left" *ngIf="currentCountSheetAlertSetting == 'all'">
          volume_off</mat-icon>
      </button>
      <mat-menu #menu2="matMenu">
        <button mat-menu-item [ngClass]="{'disabled': currentCountSheetAlertSetting == 'success' }"
          (click)="toggleMute('success')">Mute Success</button>
        <button mat-menu-item [ngClass]="{'disabled': currentCountSheetAlertSetting == 'all' }"
          (click)="toggleMute('all')">Mute All</button>
        <button mat-menu-item [ngClass]="{'disabled': currentCountSheetAlertSetting == '' }"
          (click)="toggleMute('')">Un-Mute</button>
      </mat-menu>

      <button *ngIf="connected" (click)="testOffline()" mat-icon-button>
        <mat-icon class="material-icons online-status-on" [matBadge]="unsynchronizedDataCount"
        [matBadgeHidden]="!unsynchronizedDataCount" >
          signal_wifi_4_bar
        </mat-icon>
      </button>
      <button (click)="testOffline()" *ngIf="!connected" mat-icon-button>
        <mat-icon class="material-icons offline" [matBadge]="unsynchronizedDataCount" matBadgeColor="warn"
          [matBadgeHidden]="!unsynchronizedDataCount">
          signal_wifi_off
        </mat-icon>
      </button>

    </mat-toolbar>
    <!-- Add Content Here -->
    <router-outlet>
    </router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
