import { Component, OnInit, Inject, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AuditService } from '../../services/audit.service';
import { UserDataHelper } from './../../helpers/user-data-helper';

@Component({
  selector: 'app-result-file-dialog',
  templateUrl: './result-file-dialog.component.html',
  styleUrls: ['./result-file-dialog.component.css']
})
export class ResultFileDialogComponent implements OnInit, OnDestroy {

  @ViewChild('result_file_email_address') emailAddressField: ElementRef;
  userDataHelper: UserDataHelper = new UserDataHelper();

  constructor(
    public _dialogRef: MatDialogRef<ResultFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private _auditService: AuditService
  ) { }

  ngOnInit() {
    this.emailAddressField.nativeElement.value = this.userDataHelper.getUserData().email_address;
  }

  ngOnDestroy(): void {
    this.emailAddressField = null;
  }

  email() {
    const that = this;
    this._auditService.emailOutputFile(this.data.audit_id, this.data.generate_key, this.emailAddressField.nativeElement.value).subscribe(() => {
      that._dialogRef.close();
    });
  }

}
