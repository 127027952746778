var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Injector } from '@angular/core';
import { from, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CONSTANTS } from './constants';
import { OnlineService } from '../services/online.service';
import { AccessTokenService } from '../services/access-token.service';
import { urls } from './urls';
import { environment } from 'src/environments/environment';
import { UserDataHelper } from './user-data-helper';
import { AuthService } from '../services/auth.service';
import { OktaConfigService } from '../services/okta-config.service';
var AuthInterceptor = /** @class */ (function () {
    function AuthInterceptor(_router, _onlineService, _accessTokenService, authService, _injector) {
        this._router = _router;
        this._onlineService = _onlineService;
        this._accessTokenService = _accessTokenService;
        this.authService = authService;
        this._injector = _injector;
        this.userDataHelper = new UserDataHelper();
        this.isInternalKey = CONSTANTS.localStorageKeys.LOGIN_IS_INTERNAL;
        this.configDataKey = CONSTANTS.localStorageKeys.OKTA_CONFIG_DATA;
        this.loginParams = {};
    }
    AuthInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        var accessTokenData = this._accessTokenService.getInMemoryTokenDataIfNotNull();
        var expired = this._accessTokenService.isExpired(accessTokenData);
        var isInternal = localStorage.getItem(CONSTANTS.localStorageKeys.LOGIN_IS_INTERNAL) === "true";
        var isOnline = this._onlineService.isOnline();
        if (accessTokenData && accessTokenData.access_token && !expired) {
            this._accessTokenService.storeTokenDataIfMissing(accessTokenData);
            var cloned = this.cloneRequestWithHeaders(req, accessTokenData.access_token, isInternal);
            return next.handle(cloned).pipe(catchError(function (error) { return _this.handleError(error); }));
        }
        else if (!isOnline || this.pathNeedsAuth(req.url) === false) {
            var cloned = req.clone({
                headers: req.headers.set(CONSTANTS.http.headerLabel.SOURCE_APP, CONSTANTS.http.headers.SOURCE_APP)
            });
            return next.handle(cloned).pipe(catchError(function (error) { return _this.handleError(error); }));
        }
        else {
            if (accessTokenData.refresh_token) {
                this.loginParams.grant_type = "refresh_token";
                this.loginParams.refresh_token = accessTokenData.refresh_token;
                return from(this.getAccessTokenVanilla(this.loginParams)).pipe(switchMap(function (success) {
                    if (success) {
                        var cloned = req.clone({
                            headers: req.headers.set(CONSTANTS.http.headerLabel.SOURCE_APP, CONSTANTS.http.headers.SOURCE_APP)
                                .set(CONSTANTS.http.headerLabel.AUTHORIZATION, CONSTANTS.http.headerLabel.BEARER + success.access_token)
                                .set(CONSTANTS.http.headerLabel.TENANT, isInternal ? CONSTANTS.tenants.INTERNAL : CONSTANTS.tenants.EXTERNAL)
                        });
                        return next.handle(cloned);
                    }
                    else {
                        console.log("refresh token failed");
                        _this._router.navigate([CONSTANTS.routes.LOGIN]);
                        return next.handle(req);
                    }
                }), catchError(function (error) {
                    return throwError(error);
                }));
            }
            else {
                console.log("no refresh token");
                this._router.navigate([CONSTANTS.routes.LOGIN]);
                return next.handle(req);
            }
        }
    };
    AuthInterceptor.prototype.pathNeedsAuth = function (path) {
        var exceptions = [
            environment.serverPath + urls.registerToken,
            environment.serverPath + urls.oktaConfigData,
        ];
        return !exceptions.includes(path);
    };
    AuthInterceptor.prototype.getAccessTokenVanilla = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var configData, that, isInternal, url, request;
            return __generator(this, function (_a) {
                configData = JSON.parse(localStorage.getItem(CONSTANTS.localStorageKeys.OKTA_CONFIG_DATA));
                params.client_id = configData.audit_internal_client_id;
                params.scope = configData.audit_scope += " offline_access";
                params.redirect_uri = configData.audit_login_redirect_uri;
                that = this;
                isInternal = localStorage.getItem(this.isInternalKey) === "true";
                url = isInternal ? configData.arthrex_employee_base_url : configData.non_arthrex_employee_base_url;
                request = new XMLHttpRequest();
                request.open(CONSTANTS.http.methods.POST, "" + url + configData.get_access_token, true);
                request.setRequestHeader(CONSTANTS.http.headerLabel.CONTENT_TYPE, CONSTANTS.http.headers.contentType.URL_ENCODED);
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        request.onload = function () {
                            var body = {};
                            try {
                                body = JSON.parse(request.response);
                            }
                            catch (e) { }
                            if (request.status == 200) {
                                that.authService.register_token(body, isInternal ? CONSTANTS.tenants.INTERNAL : CONSTANTS.tenants.EXTERNAL).subscribe(function (data) {
                                    if (data.success) {
                                        that._accessTokenService.storeTokenData(body);
                                        resolve(body);
                                    }
                                }, function (error) {
                                    if (error.status == 403) {
                                        resolve(null);
                                    }
                                });
                            }
                            else {
                                resolve(null);
                            }
                        };
                        request.onerror = function () {
                            reject(false);
                        };
                        var body = Object.keys(params).map(function (key) { return key + '=' + params[key]; }).join('&');
                        request.send(body);
                    })];
            });
        });
    };
    AuthInterceptor.prototype.cloneRequestWithHeaders = function (req, accessToken, isInternal) {
        return req.clone({
            headers: req.headers
                .set(CONSTANTS.http.headerLabel.AUTHORIZATION, CONSTANTS.http.headerLabel.BEARER + accessToken)
                .set(CONSTANTS.http.headerLabel.TENANT, isInternal ? CONSTANTS.tenants.INTERNAL : CONSTANTS.tenants.EXTERNAL)
                .set(CONSTANTS.http.headerLabel.SOURCE_APP, CONSTANTS.http.headers.SOURCE_APP)
        });
    };
    AuthInterceptor.prototype.handleError = function (error) {
        this._oktaConfigService = this._injector.get(OktaConfigService);
        if (error && error.status === 401) {
            localStorage.setItem(CONSTANTS.localStorageKeys.ACCESS_TOKEN_KEY, '');
            localStorage.setItem(CONSTANTS.localStorageKeys.PERMISSIONS, JSON.stringify([]));
            localStorage.setItem(CONSTANTS.localStorageKeys.USER_DATA, JSON.stringify(''));
            this._oktaConfigService.makeInternalLoginUri(document.location.href).then(function (url) {
                document.location.href = url;
            });
        }
        return throwError(error);
    };
    return AuthInterceptor;
}());
export { AuthInterceptor };
