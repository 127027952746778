import { Injectable } from '@angular/core';
import { DatabaseService } from './database.service'
import { zip } from 'rxjs';
import { FileCacheService } from './file-cache.service';

@Injectable({
  providedIn: 'root'
})
export class FileDatabaseService {

  storeName = "files";

  constructor(private _databaseService: DatabaseService, private _fileCacheService: FileCacheService) { }

  new(data) {
    return {local_file_id: data.local_file_id, handle: data.handle, file: data.file};
  }

  delete(key) {
    return this._databaseService.delete(this.storeName, key);
  }

  clear() {
    return this._databaseService.clear(this.storeName);
  }

  get(id) {
    let handle = this.getFromCache(id);
    if(handle){
      return new Promise((resolve)=>{
        resolve({local_file_id: id, handle: handle.handle, file: handle.file});
      })
    }else{
      return this._databaseService.get(this.storeName, id).then(function (data) {
        return data;
      });
    }
  }

  getFromCache(id:string){
    return this._fileCacheService.getHandle(id);
  }

  add(f) {
    const that = this;
    if (!f.local_file_id) {
      throw new Error('how can you update without a primary key?');
    }
    return new Promise((resolve, reject) => {
      this._databaseService.add(this.storeName, f).then(function (_) {
        let promises = [new Promise((r, a) => { r(null); })];

        zip(
          ...promises
        ).subscribe(_ => {
          resolve(f.local_file_id);
        });
      });
    });
  }
}
