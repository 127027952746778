<div class="dashboard-card">
  <h5 class="header-title">
    Audit Input
  </h5>
  <div class="row">
    <div class="col-11 table-div">
      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">Inventory</th>
            <th scope="col">Updated By</th>
            <th scope="col">Date</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>

          <!-- Exclusion list -->
          <tr>
            <td>
              <span>Exclusion List <span class="audit-badge">{{exclusionCount}}</span></span>
            </td>
            <td>{{ audit.exclusion_last_changed_by?.name || "&mdash;" }}</td>
            <td>{{ audit.exclusion_last_changed_date | date }}</td>
            <td class="import-column">
              <div style="float:left;margin-right: 5px;" *ngxPermissionsOnly="['audit_administrator']" [ngClass]="{'disabled': !isOnline || audit.status === 'Completed' }">
                <mat-icon class="import-icon" (click)="openExclusionDialog()" ngbTooltip="Import Exclusion List"
                  placement="top">cloud_upload</mat-icon>
              </div>
              <div>
                  <mat-icon class="import-icon" (click)="goToExclusions()" ngbTooltip="View Exclusion List" placement="top"
                  [ngClass]="{'disabled': !isOnline }">playlist_add</mat-icon>
              </div>

            </td>
          </tr>

          <!-- sap consignment -->
          <tr>
            <td>SAP</td>
            <td>{{ audit.sap_imported_by?.name || "&mdash;" }}</td>
            <td>{{ audit.sap_inventory_imported_date | date }}</td>
            <td class="import-column">
              <div style="float:left;margin-right: 5px;" *ngxPermissionsOnly="['audit_administrator']" [ngClass]="{'disabled': !isOnline || audit.status === 'Completed' }">
                <mat-icon class="import-icon" (click)="openSapImportDialog()" ngbTooltip="Import SAP Inventory"
                  placement="top">cloud_upload</mat-icon>
              </div>
              <div>
                  <mat-icon class="import-icon" (click)="goToSAPImportItems()" ngbTooltip="View SAP Import List" placement="top"
                  [ngClass]="{'disabled': !isOnline }">playlist_add</mat-icon>
                </div>
            </td>
          </tr>

          <!-- redspot import -->
          <tr>
            <td>Redspot</td>
            <td>{{ audit.redspot_imported_by?.name || "&mdash;" }}</td>
            <td>{{ audit.redspot_inventory_imported_date | date }}</td>
            <td class="import-column">
              <div *ngxPermissionsOnly="['audit_administrator']" [ngClass]="{'disabled': !isOnline  || audit.status === 'Completed' }">
                <mat-icon class="import-icon" (click)="openRedspotImportDialog()" ngbTooltip="Import Redspot Inventory"
                  placement="top">cloud_upload</mat-icon>
              </div>
            </td>
          </tr>

          <!-- Consignment Location Import-->
           <tr>
            <td>Consignment Account</td>
            <td>{{ audit.consignment_account_inventory_imported_by?.name || "&mdash;" }}</td>
            <td>{{ audit.consignment_account_inventory_imported_date | date }}</td>
            <td class="import-column">
              <div class="row">
                <div style="float:left;margin-right: 5px;" *ngxPermissionsOnly="['audit_administrator']" [ngClass]="{'disabled': !isOnline  || audit.status === 'Completed'}">
                <mat-icon class="import-icon" (click)="openConsignmentAccountDataDialog()" ngbTooltip="Import Consignment Account Inventory"
                  placement="top">cloud_upload</mat-icon>
              </div>
              <div>
                <mat-icon class="import-icon" (click)="goToConsignmentAccountItems()" ngbTooltip="View Consignment Account List" placement="top"
                [ngClass]="{'disabled': !isOnline }">playlist_add</mat-icon>
              </div>
              </div>
            </td>
          </tr>

          <!-- sales order -->
          <tr>
            <td>Open Sales Orders</td>
            <td>{{ audit.sales_order_imported_by?.name || "&mdash;" }}</td>
            <td>{{ audit.sales_order_inventory_imported_date | date }}</td>
            <td class="import-column">
              <div class="row">
                <div style="float:left;margin-right: 5px;" *ngxPermissionsOnly="['audit_administrator']" [ngClass]="{'disabled': !isOnline || !allowSalesOrdersUpload}">
                  <mat-icon class="import-icon" (click)="importSalesOrderItems()" ngbTooltip="Import Open Sales Orders"
                    placement="top">cloud_upload</mat-icon>
                </div>
                <div>
                  <mat-icon class="import-icon" (click)="goToSalesOrderImportItems()" ngbTooltip="View Imported Items" placement="top"
                  [ngClass]="{'disabled': !isOnline || !allowSalesOrdersUpload}">playlist_add</mat-icon>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
