<mat-grid-list cols="2" rowHeight="1:1">
  <mat-grid-tile class="left_tile">
    <img src="assets/img/ArthrexAuditToolWhite.png" alt="Arthrex Logo" class="logo">
  </mat-grid-tile>
  <mat-grid-tile>

    <div class="login-box center container">
      <div class="login-row">
        <h1>
          <strong>Login</strong>
        </h1>
      </div>
      
      <div class="login-row">
        <img src="assets/img/arthrex-logo.png" alt="Photo of a Shiba Inu">
      </div>

      <div class="login-row">
        <button (click)="internalLogin()" mat-raised-button>
          <mat-icon>work</mat-icon> Arthrex Employees
        </button>
      </div>

      <div class="login-row">
        <p>
          If you are experiencing login issues, please contact <a href="mailto:LoginSupport@arthrex.com">LoginSupport@arthrex.com</a> 
        </p>
      </div >
    </div>
  </mat-grid-tile>
</mat-grid-list>
