import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatSnackBar, MatSortable } from '@angular/material';
import { AuditsDataTableDataSource } from './audits-data-table-datasource';
import { AuditService } from '../../services/audit.service';
import { ManufacturerService } from '../../services/manufacturer.service';
import { Router } from '@angular/router';
import { CreateNewAuditComponent } from '../create-new-audit/create-new-audit.component';
import { ISnackBarConfig } from '../../interfaces/isnack-bar-config';
import { ItemService } from '../../services/item.service';
import { zip } from 'rxjs';
import { ItemPackageService } from '../../services/item-package.service';
import { KitDefinitionItemService } from '../../services/kit-definition-item.service';
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';
import { KitService } from '../../services/kit.service';
import { LotPoolManagedItemService } from '../../services/lot-pool-managed-item.service';
import { OnlineService } from 'src/app/services/online.service';
import { ItemCombinationService } from 'src/app/services/item-combination.service';
import { ItemCombinationRecallAlertService } from 'src/app/services/item-combination-recall-alert.service';
import { TrackLoadingService } from 'src/app/services/track-loading.service';
import { Messages } from 'src/app/helpers/messages';

@Component({
  selector: 'app-audits-data-table',
  templateUrl: './audits-data-table.component.html',
  styleUrls: ['./audits-data-table.component.css']
})
export class AuditsDataTableComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: AuditsDataTableDataSource;
  snackBarConfig: ISnackBarConfig = {
    message: '',
    duration: 3000,
    success: false,
    snackBarClass: ''
  };

  distributors: Object[] = [];
  audits: Object[] = [];

  constructor(
    private _auditService: AuditService,
    private _manufacturerService: ManufacturerService,
    private _router: Router,
    private _itemService: ItemService,
    private _itemPackageService: ItemPackageService,
    private _kitDefinitionItemService: KitDefinitionItemService,
    private _kitService: KitService,
    private _lotPoolManagedItemService: LotPoolManagedItemService,
    private _itemCombinationService: ItemCombinationService,
    private _itemCombinationRecallAlertService: ItemCombinationRecallAlertService,
    private _trackLoadingService: TrackLoadingService,
    private _onlineService: OnlineService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {

  }
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['location_name', 'distributorship_name', 'date', 'status', 'addButton'];

  ngOnDestroy(): void {

  }

  ngOnInit() {
    this.sort.sort(({id: 'date', start: 'desc'}) as MatSortable);
    this._trackLoadingService.startLoading('app-audit-table-audits', Messages.loadingAudits);
    this._trackLoadingService.startLoading('app-audit-table-distributors', Messages.loadingDistributors);
    zip(
      this._auditService.getAuditsAndPopulateOfflineData(),
      this._auditService.getDistributors()
    ).subscribe((data: any[]) => {
      this.audits = data[0];
      this.distributors = data[1];
      this._trackLoadingService.stopLoading('app-audit-table-audits');
      this._trackLoadingService.stopLoading('app-audit-table-distributors');

      this._trackLoadingService.startLoading('app-audit-table-manufacturers', Messages.loadingManufacturer);
      this._manufacturerService.getManufacturer().subscribe(
        () => {
          this._trackLoadingService.stopLoading('app-audit-table-manufacturers');
        },
        (error) => {
          this._trackLoadingService.stopLoading('app-audit-table-manufacturers');
        }
      );
      this._trackLoadingService.startLoading('app-audit-table-items', Messages.loadingItems);
      this._itemService.getItems().subscribe(
        () => {
          this._trackLoadingService.stopLoading('app-audit-table-items');
        },
        (error) => {
          this._trackLoadingService.stopLoading('app-audit-table-items');
        }
      );
      this._trackLoadingService.startLoading('app-audit-table-item-packages', Messages.loadingItemPackages);
      this._itemPackageService.get().subscribe(
        () => {
          this._trackLoadingService.stopLoading('app-audit-table-item-packages');
        },
        (error) => {
          this._trackLoadingService.stopLoading('app-audit-table-item-packages');
        }
      );
      this._trackLoadingService.startLoading('app-audit-table-kit-definition-items', Messages.loadingKitDefinitions);
      this._kitDefinitionItemService.getKitDefinitionItems().subscribe(
        () => {
          this._trackLoadingService.stopLoading('app-audit-table-kit-definition-items');
        },
        (error) => {
          this._trackLoadingService.stopLoading('app-audit-table-kit-definition-items');
        }
      );
      this._trackLoadingService.startLoading('app-audit-table-kits', Messages.loadingKits);
      this._kitService.getKits().subscribe(
        () => {
          this._trackLoadingService.stopLoading('app-audit-table-kits');
        },
        (error) => {
          this._trackLoadingService.stopLoading('app-audit-table-kits');
        }
      );
      this._trackLoadingService.startLoading('app-audit-table-lot-pool-managed-items', Messages.loadingLotPoolManagedItems);
      this._lotPoolManagedItemService.getLotPoolManagedItems().subscribe(
        () => {
          this._trackLoadingService.stopLoading('app-audit-table-lot-pool-managed-items');
        },
        (error) => {
          this._trackLoadingService.stopLoading('app-audit-table-lot-pool-managed-items');
        }
      );
      this._trackLoadingService.startLoading('app-audit-table-item-combinations', Messages.loadingItemCombinations);
      this._itemCombinationService.get().subscribe(
        () => {
          this._trackLoadingService.stopLoading('app-audit-table-item-combinations');
        },
        (error) => {
          this._trackLoadingService.stopLoading('app-audit-table-item-combinations');
        }
      );
      this._trackLoadingService.startLoading('app-audit-table-item-combination-recall-alerts', Messages.loadingItemCombinationRecallAlerts);
      this._itemCombinationRecallAlertService.get().subscribe(
        () => {
          this._trackLoadingService.stopLoading('app-audit-table-item-combination-recall-alerts');
        },
        (error) => {
          this._trackLoadingService.stopLoading('app-audit-table-item-combination-recall-alerts');
        }
      );

      this.dataSource = new AuditsDataTableDataSource(this.paginator, this.sort, this.audits);
    });
  }

  goToAuditDetails(row: any) {
    if (row.status === 'Cancelled') {
      this.snackBarConfig.message = 'Audit has been cancelled and is no longer accessible';
      this.snackBarConfig.success = false;
      this.openSnackBar();
      return;
    }
    if (!this._onlineService.isOnline() || this._onlineService.testingOffline) {
      this.snackBarConfig.message = 'Offline, cannot retrieve audit data';
      this.snackBarConfig.success = false;
      this.openSnackBar();
      return;
    }
    this._router.navigate(['audit_dashboard', row.id]);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CreateNewAuditComponent, {
      width: '50%',
      data: {
        pageTitle: 'Create Audit',
        creating: true
      }
    });
    dialogRef.componentInstance.saved.subscribe(() => {
      this._trackLoadingService.startLoading('app-audit-table-create-audit', 'Creating Audit');
      this._auditService.getAuditsAndPopulateOfflineData().then((auditsData: any[]) => {
        this.audits = auditsData;
        this.dataSource = new AuditsDataTableDataSource(this.paginator, this.sort, this.audits);
        this._trackLoadingService.stopLoading('app-audit-table-create-audit');
      });
    });
  }

  openEditDialog(row: any) {
    const dialogRef = this.dialog.open(CreateNewAuditComponent, {
      width: '50%',
      data: {
        creating: false,
        pageTitle: 'Edit Audit',
        location_name: row.location_name,
        start_date: row.start_date,
        end_date: row.end_date,
        status: row.status,
        id: row.id,
        warehouse_id: row.warehouse_id
      }
    });
    dialogRef.componentInstance.saved.subscribe(() => {
      this._trackLoadingService.startLoading('app-audit-table-edit-audit', 'Updating Audit');
      this._auditService.getAuditsAndPopulateOfflineData().then((auditsData: any[]) => {
        this.audits = auditsData;
        this.dataSource = new AuditsDataTableDataSource(this.paginator, this.sort, this.audits);
        this._trackLoadingService.stopLoading('app-audit-table-edit-audit');
      });
    });
  }

  openSnackBar() {
    this.snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: this.snackBarConfig,
      duration: this.snackBarConfig.duration
    });
  }
}
