import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { urls } from '../helpers/urls';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IAttachment } from '../interfaces/i-attachment';
import { AttachmentDatabaseService } from '../services/attachment-database.service';
import { zip } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountSheetAttachmentService {


  getAttachmentsAndPopulateOfflineData(auditId) {
    const that = this;
    let promise = new Promise((resolve, reject) => {
      that.getAttachments(auditId).subscribe(data => {
        that._attachmentDatabaseService.deleteByIndex('audit_id', parseInt(auditId, 10)).then(function () {
          const as = data.map(a => that._attachmentDatabaseService.new(a));
          that._attachmentDatabaseService.bulkAdd(as).then(_ => {
            resolve(as);
          });
        });
      });
    });

    return promise;
  }

  //find synced ones for the count sheet and replace em, if there is a matching unsynced one they win
  refreshOfflineDataForCountSheet(auditId, countSheetClientId, data: any[]) {
    const that = this;
    const attachments = data.map(a => {
      return that._attachmentDatabaseService.new(a);
    });
    let promise = new Promise((resolve, reject) => {
      that._attachmentDatabaseService.find(auditId, {synchronized: true, count_sheet_client_id: countSheetClientId}).then(function (matches: IAttachment[]) {
        zip(
          ...matches.map(a => that._attachmentDatabaseService.delete(a.local_file_id)).concat(new Promise((r, a) => {r(null); }))
        ).subscribe(_ => {
          that._attachmentDatabaseService.find(auditId, {synchronized: false, count_sheet_client_id: countSheetClientId}).then(function (matches: IAttachment[]) {
            const unsyncedAttachmentIds = matches.map(a => a.local_file_id);
            const freshAttachments = attachments.filter(a => unsyncedAttachmentIds.indexOf(a.local_file_id) === -1);
            zip(
              ...freshAttachments.map(a => that._attachmentDatabaseService.add(a)).concat(new Promise((r, a) => {r(null); }))
            ).subscribe(_ => {
              that._attachmentDatabaseService.find(auditId, {count_sheet_client_id: countSheetClientId, toRemove: false}).then(function (matches: IAttachment[]) {
                resolve(matches);
              });
            });
          });
        });
      });
    });
    return promise;
  }

  getAttachments(auditId, options?) {
    options = options || {};
    let params = new HttpParams().set('is_active', 'true');
    if (options['count_sheet_id']) {
      params = params.set('count_sheet_id', options['count_sheet_id']);
    }
    if (options['count_sheet_client_id']) {
      params = params.set('count_sheet_client_id', options['count_sheet_client_id']);
    }

    return this._http.get<any>(environment.serverPath + urls.countSheetAttachments.replace(':id', auditId), {params});
  }

  deleteAttachment(file: any) {
    const path = environment.serverPath + urls.deleteAttachment.replace(':id', file.id.toString());
    return this._http.delete(path);
  }

  constructor(
    private _http: HttpClient,
    private _attachmentDatabaseService: AttachmentDatabaseService
  ) { }

  addAttachment(file: any, countSheetClientId) {
    const fd = new FormData();
    fd.append('attachment[attachment]', file);
    const path = environment.serverPath + urls.addCountSheetAttachment.replace(':id', countSheetClientId.toString());
    return this._http.post<any>(path, fd);
  }

  getAttachment(file: any) {
    const path = environment.serverPath + urls.downloadAttachment.replace(':id', file.id.toString());
    window.open(path);
  }

  getImage(file: IAttachment) {
    const path = environment.serverPath + urls.downloadImageAttachment.replace(':id', file.id.toString());
    return this._http.get<any>(path,  {responseType: 'blob' as 'json', observe: 'response'} );
  }
}
