<div class="col-md-12">
  <button mat-raised-button class="back" type="button" (click)="goToAuditDashboard()"ngbTooltip="Go To Dashboard" placement="right">
    <mat-icon >dashboard</mat-icon>
    <span>Dashboard</span>
  </button>

  <div class="row" *ngIf="location">
    <div class="col-6">
      <h1 class="header-title title-names">
        Site:
      </h1>
    </div>
    <div class="col-6">
      <h1 class="header-title">
        {{location.location_name.toUpperCase()}}
      </h1>
    </div>
  </div>

  <div class="filters">
    <div class="right_filter">
      <mat-form-field class="medium_filter" *ngIf="filters && users">
        <mat-select name="Auditor" placeholder="Auditor" [(value)]="filters.auditor" (selectionChange)="applyFilter()">
          <mat-option value="all">
            All
          </mat-option>
          <mat-option *ngFor="let user of users" [value]="user.id">
            {{user.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="small_filter" [ngClass]="{'hidden':selectedTab == 1}" *ngIf="filters">
        <mat-select name="Order Type" placeholder="Order Type" [(value)]="filters.orderType" (selectionChange)="applyFilter()">
          <mat-option value="all">
            All
          </mat-option>
          <mat-option value="consigned">
            Consigned
          </mat-option>
          <mat-option value="owned">
            Owned
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="small_filter" [ngClass]="{'hidden':selectedTab == 1}" *ngIf="filters">
        <mat-select name="Sites" placeholder="Sites" [(value)]="filters.site" (selectionChange)="applyFilter()">
          <mat-option value="all">
            All
          </mat-option>
          <mat-option [value]="ID">
            This Site
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="medium_filter" *ngIf="filters && warehouses">
        <mat-select name="Warehouses" placeholder="Warehouses" [(value)]="filters.warehouse"  (selectionChange)="applyFilter()">
          <mat-option value="all">
            All
          </mat-option>
          <mat-option *ngFor="let w of warehouses" [value]="w.account_id">
            {{w.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="small_filter" *ngIf="filters">
        <mat-select placeholder="Item Validity" [(value)]="filters.validity_status" (selectionChange)="applyFilter()">
          <mat-option value="All">
            All
          </mat-option>
          <mat-option value="All Valid">
            All Valid
          </mat-option>
          <mat-option value="Some Invalid">
            Some Invalid
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="small_filter" *ngIf="filters">
        <mat-select placeholder="Recall Status" [(value)]="filters.recall_status" (selectionChange)="applyFilter()">
          <mat-option value="All">
            All
          </mat-option>
          <mat-option value="Recalled">
            Recalled
          </mat-option>
          <mat-option value="Not Recalled">
            Not Recalled
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="medium_filter" *ngIf="filters">
        <mat-select placeholder="Status" [(value)]="filters.status" (selectionChange)="applyFilter()">
          <mat-option value="All">
            All
          </mat-option>
          <mat-option value="Planned">
            Planned
          </mat-option>
          <mat-option value="In-Progress">
            In-Progress
          </mat-option>
          <mat-option value="Needs Verification">
            Needs Verification
          </mat-option>
          <mat-option value="Verified">
            Verified
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="medium_filter" [ngClass]="{'hidden':selectedTab == 0}" *ngIf="filters">
        <input matInput [(ngModel)]="filters.searchString" (keyup)="applyFilter()" placeholder="Search Area">
      </mat-form-field>

      <mat-form-field class="large_filter" [ngClass]="{'hidden':selectedTab == 1}" *ngIf="filters">
        <input matInput [(ngModel)]="filters.kitSearchString" (keyup)="applyFilter()" placeholder="Search Counted By | Reference | Description | Lot">
      </mat-form-field>
    </div>
  </div>
  <div class="clear_both"></div>
  <mat-tab-group *ngIf="unkittedCountSheets && kittedCountSheets && filters && auditLocationWarehouseDataLoaded" [selectedIndex]="selectedTab" (selectedIndexChange)="updateSelectedTab($event)">
    <mat-tab label="Kitted Count Sheets">
      <app-kit-instances-list [kittedCountSheets] = "kittedCountSheets" [siteId]="ID" [filters]="filters" [audit]="audit" [location]="location" #kitSheets></app-kit-instances-list>
    </mat-tab>
    <mat-tab label="Unkitted Count Sheets">
      <app-unkitted-countsheet [passedUnkittedCountSheets]="unkittedCountSheets" [siteId]="ID" [filters]="filters" [location] ="location" [audit]="audit" [warehouses]="warehouses" #unkittedSheets></app-unkitted-countsheet>
    </mat-tab>
  </mat-tab-group>
  <app-barcode-parser (referenceScanned)="barcodeScanned($event)" (itemWasNotFound)="reset()"></app-barcode-parser>
</div>
