import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { zip, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuditService } from './../../services/audit.service';
import { ConsignmentInventoryImportService } from '../../services/consignment-inventory-import.service';
import { RedspotInventoryImportService } from '../../services/redspot-inventory-import.service';
import { CountSheetService } from '../../services/count-sheet.service';
import { CountSheetItemService } from '../../services/count-sheet-item.service';
import { CountSheetAttachmentService } from '../../services/count-sheet-attachment.service';
import { AuditDashboardHelper } from '../../helpers/audit-dashboard-helper';
import { ItemSettingService } from '../../services/item-setting.service';
import { AuditKitInstanceService } from '../../services/audit-kit-instance.service';
import { ExclusionService } from '../../services/exclusion.service';
import { UserService } from '../../services/user.service';
import { OnlineService } from 'src/app/services/online.service';
import { OfflineDataSynchronizerService } from 'src/app/services/offline-data-synchronizer.service';
import { TrackLoadingService } from '../../services/track-loading.service';
import { Messages } from 'src/app/helpers/messages';
import { AuditLocationsComponent } from '../audit-locations/audit-locations.component';
import { AuditWarehousesComponent } from '../audit-warehouses/audit-warehouses.component';
import { DashboardImportComponent } from '../dashboard-import/dashboard-import.component';
import { AuditDashboardStatsComponent } from '../audit-dashboard-stats/audit-dashboard-stats.component';
import { ItemCombinationRecallAlertExceptionService } from 'src/app/services/item-combination-recall-alert-exception.service';

@Component({
  selector: 'app-audit-dashboard',
  templateUrl: './audit-dashboard.component.html',
  styleUrls: ['./audit-dashboard.component.css']
})
export class AuditDashboardComponent implements OnInit, OnDestroy {
  /** Based on the screen size, switch from standard to one column per row */
  @ViewChild('locationsTable') locationsTable: AuditLocationsComponent;
  @ViewChild('warehouseTable') warehouseTable: AuditWarehousesComponent;
  @ViewChild('auditInputs') auditInputs: DashboardImportComponent;
  @ViewChild('auditStats') auditStats: AuditDashboardStatsComponent;
  auditData: any;
  isOnline: boolean;
  onlineSubscription: Subscription;
  goOfflineSubscription: Subscription;
  reloadSubscription: Subscription;
  loading = false;
  ID: string;
  locations: any[];
  warehouses: any[];
  warehouseResults: any[];
  auditDashBoardHelper: AuditDashboardHelper = new AuditDashboardHelper();

  currentState: string;
  rowSize = 300;
  gridTileSizes = {
    auditDetails: {
      col: 1,
      row: 1
    },
    auditInput: {
      col: 2,
      row: 1
    },
    auditOutput: {
      col: 1,
      row: 1
    },
    auditProgress: {
      col: 4,
      row: 1
    },
    auditLocations: {
      col: 3,
      row: 2
    },
    auditWarehouses: {
      col: 1,
      row: 2
    }
  };
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _auditService: AuditService,
    private _countSheetService: CountSheetService,
    private _consignmentInventoryImportService: ConsignmentInventoryImportService,
    private _redspotInventoryImportService: RedspotInventoryImportService,
    private _itemSettingService: ItemSettingService,
    private _auditKitInstanceService: AuditKitInstanceService,
    private _exclusionService: ExclusionService,
    private _userService: UserService,
    private _onlineService: OnlineService,
    private _countSheetItemService: CountSheetItemService,
    private _synchronizer: OfflineDataSynchronizerService,
    private _countSheetAttachmentService: CountSheetAttachmentService,
    private _trackLoadingService: TrackLoadingService,
    private _itemCombinationRecallAlertExceptionService: ItemCombinationRecallAlertExceptionService
  ) {

  }

  ngOnInit() {
    const that = this;
    this.onResize(null);
    this.ID = this._route.snapshot.paramMap.get('id');

    this._synchronizer.needsSyncing().then((hasUnsynced) => {
      const wouldSync = that._onlineService.isOnline() && !that._onlineService.testingOffline;
      const currentlySyncing = !that._synchronizer.doneProcessing;
      if (!wouldSync || !hasUnsynced && !currentlySyncing) {
        that.loadData();
      } else if (wouldSync && hasUnsynced && !currentlySyncing) {
        that._synchronizer.run(false);
      }


      that.reloadSubscription = that._synchronizer.allSynchronizedEvent.subscribe(data => {
        that.loadData();
      });

      that.onlineSubscription = that._onlineService.isOnlineSubscription().subscribe(
        online => {
          that.isOnline = online && !that._onlineService.getTestOfflineBool();

        }
      );
      that.goOfflineSubscription = that._onlineService.goOfflineEvent.subscribe((data: boolean) => {
        that.isOnline = !data;
      });
    });
  }

  loadData() {

    const that = this;
    //for caching so we will have it for the rest of the pages
    this._trackLoadingService.startLoading('app-audit-dashboard-audit', Messages.loadingAudit);
    this._trackLoadingService.startLoading('app-audit-dashboard-warehouse', Messages.loadingWarehouseData);
    zip(
      that._auditService.getAuditById(that.ID),
      that._auditService.getWarehouses(that.ID)

    ).subscribe(data => {
      that.auditData = data[0];
      that._trackLoadingService.stopLoading('app-audit-dashboard-audit');
      that.warehouses = data[1];
      this._trackLoadingService.stopLoading('app-audit-dashboard-warehouse');

      if (this._onlineService.isOnline() && !this._onlineService.getTestOfflineBool()) {
        zip(
          that._auditKitInstanceService.getAuditKitInstancesAndPopulateOfflineData(that.ID),
          that._countSheetService.getCountSheetsAndPopulateOfflineData(that.ID),
          that._countSheetItemService.getCountSheetItemsAndClearOfflineData(that.ID),
          that._countSheetAttachmentService.getAttachmentsAndPopulateOfflineData(that.ID)
        ).subscribe(_ => {

        });
      }

      if (that.auditData.sap_inventory_imported_date) {
        window.caches.keys().then(function (cacheNames) {
          const match = cacheNames.find(cn => cn.includes('Inventory'));
          if (match) {
            caches.open(match).then(function (cache) {
              cache.match(that._consignmentInventoryImportService.getURL(that.ID)).then(function (response) {
                if (!response) {
                  that._consignmentInventoryImportService.getInventory(that.ID, true).subscribe();
                }
              });
            });
          } else {
            that._consignmentInventoryImportService.getInventory(that.ID, true).subscribe();
          }
        });
      }

      if (that.auditData.redspot_inventory_imported_date) {
        window.caches.keys().then(function (cacheNames) {
          const match = cacheNames.find(cn => cn.includes('Inventory'));
          if (match) {
            caches.open(match).then(function (cache) {
              cache.match(that._redspotInventoryImportService.getURL(that.ID)).then(function (response) {
                if (!response) {
                  that._redspotInventoryImportService.getInventory(that.ID).subscribe();
                }
              });
            });
          } else {
            that._redspotInventoryImportService.getInventory(that.ID).subscribe();
          }
        });
      }
    });

    // item_combination recall alert exceptions
    this._trackLoadingService.startLoading('app-audit-dashboard-item-combination-recall-alert_exceptions', Messages.loadingItemCombinationRecallAlertExceptions);
    this._itemCombinationRecallAlertExceptionService.get(that.ID).subscribe(
      () => {
        this._trackLoadingService.stopLoading('app-audit-dashboard-item-combination-recall-alert_exceptions');
      },
      (error) => {
        this._trackLoadingService.stopLoading('app-audit-dashboard-item-combination-recall-alert_exceptions');
      }
    );

    // item_settings
    this._trackLoadingService.startLoading('app-audit-dashboard-item-settings', Messages.loadingItemSettings);
    this._itemSettingService.getItemSettingsByAudit(+this.ID).subscribe(
      () => {
        this._trackLoadingService.stopLoading('app-audit-dashboard-item-settings');
      },
      (err) => {
        this._trackLoadingService.stopLoading('app-audit-dashboard-item-settings');
      }
    );

    // exclusions
    this._trackLoadingService.startLoading('app-audit-dashboard-exclusions', Messages.loadingExclusionsData);
    this._exclusionService.getExclusions(+this.ID).subscribe(() => {
      this._trackLoadingService.stopLoading('app-audit-dashboard-exclusions');
    }, (err) => {
      this._trackLoadingService.stopLoading('app-audit-dashboard-exclusions');
    });

    // users
    this._trackLoadingService.startLoading('app-audit-dashboard-user', Messages.loadingUsersData);
    that._userService.getUsers(that.ID).subscribe(() => {
      this._trackLoadingService.stopLoading('app-audit-dashboard-user');
    }, (err) => {
      this._trackLoadingService.stopLoading('app-audit-dashboard-user');
    });


  }

  updateLocationsTable(inventoryImported: string) {
    if (inventoryImported === 'Redspot') {
      this.refreshWarehouseTable();
      this.locationsTable.ngOnInit();
    } else {
      this.locationsTable.updateAuditImportDate(inventoryImported);
    }
  }

  refreshWarehouseTable() {
    this.warehouseTable.ngOnInit();
    this.auditStats.ngOnInit();
  }

  refreshAuditInput(status: string) {
    this.auditData.status = status;
    this.auditInputs.ngOnInit();
  }

  ngOnDestroy() {
    if (this.onlineSubscription) {
      this.onlineSubscription.unsubscribe();
    }

    if (this.goOfflineSubscription) {
      this.goOfflineSubscription.unsubscribe();
    }

    if (this.reloadSubscription) {
      this.reloadSubscription.unsubscribe();
    }
    this.locationsTable = null;
    this.warehouseTable = null;
    this.auditInputs = null;
    this.auditStats = null;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth >= 1175) {
      this.rowSize = 300;
      this.currentState = 'LARGE';
      this.gridTileSizes = {
        auditDetails: {
          col: 1,
          row: 1
        },
        auditInput: {
          col: 2,
          row: 1
        },
        auditOutput: {
          col: 1,
          row: 1
        },
        auditProgress: {
          col: 4,
          row: 1
        },
        auditLocations: {
          col: 3,
          row: 2
        },
        auditWarehouses: {
          col: 1,
          row: 2
        }
      };
    } else {
      this.currentState = 'SMALL';
      this.rowSize = 350;
      this.gridTileSizes.auditDetails = {
        col: 4,
        row: 1
      };
      this.gridTileSizes.auditInput = {
        col: 4,
        row: 1
      },
        this.gridTileSizes.auditOutput = {
          col: 4,
          row: 1
        };
      this.gridTileSizes.auditLocations = {
        col: 4,
        row: 2
      },
        this.gridTileSizes.auditWarehouses = {
          col: 4,
          row: 2
        };
    }
  }
}
