import { Component, OnInit, Inject } from '@angular/core';
import { CountSheetService } from 'src/app/services/count-sheet.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import * as XLSX from 'xlsx';
import { ISnackBarConfig } from '../../interfaces/isnack-bar-config';
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';
import { ExportToExcelService } from 'src/app/services/export-to-excel.service';
import { ItemService } from 'src/app/services/item.service';
import * as moment from 'moment';
import { TrackLoadingService } from '../../services/track-loading.service';
import { map } from 'rxjs/operators';
import { CountSheetItemHelper } from '../../helpers/countsheet-item-helper';
import { CountSheetItemDatabaseService } from '../../services/count-sheet-item-database.service';
import { CountSheetItemService } from '../../services/count-sheet-item.service';
import { zip } from 'rxjs';



@Component({
  selector: 'app-import-count-sheet-item',
  templateUrl: './import-count-sheet-item.component.html',
  styleUrls: ['./import-count-sheet-item.component.css']
})
export class ImportCountSheetItemComponent implements OnInit {
  private _countSheetItemHelper: CountSheetItemHelper = new CountSheetItemHelper();
  selectedFile = null;
  saving = false;
  items;
  lotPoolManagedItemReferences = [];
  snackBarConfig: ISnackBarConfig = {
    message: '',
    duration: 3000,
    success: false,
    snackBarClass: ''
  };

  constructor(
    private _countSheetService: CountSheetService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ImportCountSheetItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private exportToExcelService: ExportToExcelService,
    private _itemService: ItemService,
    private _trackLoading: TrackLoadingService,
    private _countSheetItemDatabaseService: CountSheetItemDatabaseService,
    private _countSheetItemService: CountSheetItemService
  ) { }

  ngOnInit() {
    this._itemService.getItems().subscribe(data => {
      this.items = data;
    });
    this.lotPoolManagedItemReferences = this.data.lotPoolManagedItems.map(lpmi => this.standardizeValue(lpmi.item.reference));
  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  templateFrontEnd() {
    this.exportToExcelService.exportAsExcelFile([{
      'reference': '',
      'lot number': '',
      'serial number': '',
      'expiration date': '',
      'quantity': ''
    }], 'count_sheet_items', {});
  }

  onUpload() {
    const that = this;
    const reader: FileReader = new FileReader();
    that.saving = true;
    that._trackLoading.startLoading('import-count-sheet-items', 'Importing Count Sheet Items');
    if (!that.items) {
      that.snackBarConfig.message = 'Item data has not finished loading, please try again shortly.';
      that.snackBarConfig.success = false;
      that.openSnackBar();
      this.saving = false;
      that._trackLoading.stopLoading('import-count-sheet-items');
      return;
    }
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true, cellNF: false, cellText: false });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      let index;

      /* save data */
      const data = <any>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      if (!['reference', 'lot number', 'serial number', 'expiration date', 'quantity'].every(v => data[0].indexOf(v) !== -1)) {
        that.snackBarConfig.message = 'A file header is missing';
        that.snackBarConfig.success = false;
        that.saving = false;
        that.openSnackBar();
        return;
      }
      const headers = data.shift();
      if (data.length === 0) {
        that.snackBarConfig.message = 'The file is empty';
        that.snackBarConfig.success = false;
        that.saving = false;
        that._trackLoading.stopLoading('import-count-sheet-items');
        that.openSnackBar();
        return;
      }
      const reference_index = headers.indexOf('reference');
      const lot_number_index = headers.indexOf('lot number');
      const serial_number_index = headers.indexOf('serial number');
      const expiration_date_index = headers.indexOf('expiration date');
      const quantity_index = headers.indexOf('quantity');
      const count_sheet_items = data.map(v => {
        return {
          reference: v[reference_index],
          lot_number: v[lot_number_index],
          serial_number: v[serial_number_index],
          expiration_date: v[expiration_date_index],
          quantity: v[quantity_index],
          checked: true,
          item_id: null,
          description: '',
          is_consigned: null
        };
      });

      //populate additional values, and find invalid references
      count_sheet_items.forEach(csi => {
        const matched = that.items.find(item => {
          return that.standardizeValue(item.reference) === that.standardizeValue(csi.reference);
        });

        if (matched) {
          csi.item_id = matched.id;
          csi.description = matched.description;
          csi.is_consigned = matched.is_consigned;
        }
      });
      index = 1;
      const unmatchedReferencePositions = count_sheet_items.map(csi => {
        return {
          item_id: csi.item_id,
          index: index += 1
        };
      }).filter(v => !v.item_id).map(v => v.index);
      if (unmatchedReferencePositions.length > 0) {
        that.snackBarConfig.message = `The reference on lines ${unmatchedReferencePositions.join(',')} do not match an item`;
        that.snackBarConfig.success = false;
        that.saving = false;
        that._trackLoading.stopLoading('import-count-sheet-items');
        that.openSnackBar();
        return;
      }

      index = 1;
      const badExpirationDate = count_sheet_items.find(csi => {
        index += 1;
        if (!csi.expiration_date) {
          return false;
        }
        return !moment.utc(csi.expiration_date).isValid();
      });
      if (badExpirationDate) {
        that.snackBarConfig.message = `The expiration date on line ${index} is not valid`;
        that.snackBarConfig.success = false;
        that.saving = false;
        that._trackLoading.stopLoading('import-count-sheet-items');
        that.openSnackBar();
        return;
      }
      const grouped_count_sheet_items: any[] = [];
      that._countSheetItemDatabaseService.find(that.data.countSheet.data.audit_id, {count_sheet_client_id: that.data.countSheet.id, _destroy: false, checked: true}).then((matches: any[]) => {
        matches.filter(csi => csi.data.checked && !csi._destroy).forEach(csi => {
          let match = grouped_count_sheet_items.find(gcsi => {
            const referenceMatch = that.standardizeValue(csi.data.reference) === that.standardizeValue(gcsi.reference);
            const lotNumberMatch = that.standardizeValue(csi.data.lot_number) === that.standardizeValue(gcsi.lot_number);
            const serialMatch = that.standardizeValue(csi.data.serial) === that.standardizeValue(gcsi.serial);
            return referenceMatch && lotNumberMatch && serialMatch;
          });
          if (!match) {
            match = {
              reference: that.standardizeValue(csi.data.reference),
              lot_number: that.standardizeValue(csi.data.lot_number),
              serial: that.standardizeValue(csi.data.serial),
              quantity: 0
            };
            grouped_count_sheet_items.push(match);
          }
          match.quantity += csi.data.quantity;
        });
        count_sheet_items.forEach(csi => {
          let match = grouped_count_sheet_items.find(gcsi => {
            const referenceMatch = that.standardizeValue(csi.reference) === that.standardizeValue(gcsi.reference);
            const lotNumberMatch = that.standardizeValue(csi.lot_number) === that.standardizeValue(gcsi.lot_number);
            const serialMatch = that.standardizeValue(csi.serial_number) === that.standardizeValue(gcsi.serial);
            return referenceMatch && lotNumberMatch && serialMatch;
          });
          if (!match) {
            match = {
              reference: that.standardizeValue(csi.reference),
              lot_number: that.standardizeValue(csi.lot_number),
              serial: that.standardizeValue(csi.serial_number),
              quantity: 0
            };
            grouped_count_sheet_items.push(match);
          }
          match.quantity += csi.quantity;
        });
        if (grouped_count_sheet_items.filter(gcsi => gcsi.serial).some(gcsi => gcsi.quantity > 1)) {
          that.snackBarConfig.message = 'The file contains items with duplicate serial numbers';
          that.snackBarConfig.success = false;
          that.saving = false;
          that._trackLoading.stopLoading('import-count-sheet-items');
          that.openSnackBar();
          return;
        }

        const negPositions = this.negativeQuantityPositions(count_sheet_items);
        if (negPositions.length > 0) {
          const message = `Negative Quantities found at lines: ${negPositions.join(',')}`;
          that.snackBarConfig.message = message;
          that.snackBarConfig.success = false;
          that.saving = false;
          that._trackLoading.stopLoading('import-count-sheet-items');
          that.openSnackBar();
          return;
        }
        const iCountSheetItems = count_sheet_items.filter(csi => csi.quantity > 0).map(v => {
          return {
            data: {
              item_id: v.item_id,
              reference: v.reference,
              description: v.description,
              lot_number: v.lot_number,
              serial: v.serial_number,
              quantity: v.quantity,
              count_sheet_id: that.data.countSheet.dbId,
              count_sheet_client_id: that.data.countSheet.id,
              audit_id: that.data.countSheet.data.audit_id,
              is_consigned: v.is_consigned,
              manually_entered: true,
              isKitted: !!that.data.countSheet.data.audit_kit_instance_id,
              checked: true

            },
            id: '',
            dbId: 0,
            _destroy: false,
            isSynchronized: 0
          };
        });

        const lotPoolManagedCountSheetItems = iCountSheetItems.filter(csi =>
          this.lotPoolManagedItemReferences.indexOf(that.standardizeValue(csi.data.reference)) !== -1
        );
        const lotPoolManagedCountSheetItemsWithLot = lotPoolManagedCountSheetItems.filter(csi => csi.data.lot_number);
        const lotPoolManagedCountSheetItemsWithoutLot = lotPoolManagedCountSheetItems.filter(csi => !csi.data.lot_number);

        const nonLotPoolManagedCountSheetItems = iCountSheetItems.filter(csi =>
          this.lotPoolManagedItemReferences.indexOf(that.standardizeValue(csi.data.reference)) === -1
        );

        let lotSpecificPromises = nonLotPoolManagedCountSheetItems.concat(lotPoolManagedCountSheetItemsWithLot).map(csi => {
          return that._countSheetItemService.addOrUpdateCountSheetItemByCriteria(csi, {add_to_quantity: true});
        });
        zip(
          ...lotSpecificPromises.concat(new Promise((r, a) => { r(null); }))
        ).subscribe(_ => {
          that._countSheetItemDatabaseService.find(that.data.countSheet.data.audit_id, {count_sheet_client_id: that.data.countSheet.id, _destroy: false}).then((matches: any[]) => {
            let lotPoolManagedPromises = [];
            lotPoolManagedCountSheetItemsWithoutLot.forEach(csi => {
              const result = that._countSheetItemHelper.lotPoolManagedQuantityChanged(
                csi.data.quantity,
                matches.filter(m => m.data.item_id === csi.data.item_id),
                that.data.redspotItems.filter(ri => ri.item_id === csi.data.item_id),
                that.data.sapItems.filter(si => si.item_id === csi.data.item_id),
                csi.data,
                that.data.countSheet,
                {isKitted: !!that.data.countSheet.data.audit_kit_instance_id}
              )[1];
              result.forEach(r => {
                if (that.data.countSheet.show_expected_item_list) {
                  lotPoolManagedPromises.push(that._countSheetItemService.addOrUpdateCountSheetItemByCriteria(csi, {}));
                } else {
                  lotPoolManagedPromises.push(that._countSheetItemDatabaseService.add(r));
                }
              });
            });
            zip(
              ...lotPoolManagedPromises.concat(new Promise((r, a) => { r(null); }))
            ).subscribe(_ => {
              that._countSheetItemDatabaseService.find(that.data.countSheet.data.audit_id, {count_sheet_client_id: that.data.countSheet.id, isSynchronized: 0}).then((matches: any[]) => {
                that._countSheetService.bulkUpdate([{
                  id: that.data.countSheet.dbId,
                  show_expected_item_list: that.data.countSheet.data.show_expected_item_list,
                  skip_regrouping_callback: true,
                  count_sheet_items: matches.map(csi => {
                    return {
                      _destroy: csi._destroy,
                      item_id: csi.data.item_id,
                      reference: csi.data.reference,
                      lot_number: csi.data.lot_number,
                      serial_number: csi.data.serial,
                      quantity: csi.data.quantity,
                      id: csi.dbId,
                      client_id: csi.id,
                      checked: csi.data.checked,
                      manually_entered: csi.data.manually_entered,
                      expiration_date: csi.data.expiration_date,
                      hide: csi.data.hide,
                      rank: csi.data.rank
                    };
                  })
                }], {}).subscribe(() => {
                  that.snackBarConfig.message = 'Imported Succeeded';
                  that.snackBarConfig.success = true;
                  that.saving = false;
                  that._trackLoading.stopLoading('import-count-sheet-items');
                  that.openSnackBar();
                  that.dialogRef.close(true);
                }, res => {
                  let message = 'Import Failed';
                  if (res.status === 409) {
                    const error = res.error;
                    if (error['class'] === 'Inventory::ItemNotFoundError') {
                      message = error['message'];
                    }
                  }
                  that.snackBarConfig.message = message;
                  that.snackBarConfig.success = false;
                  that.saving = false;
                  that._trackLoading.stopLoading('import-count-sheet-items');
                  that.openSnackBar();
                });
              });
            });

          });
        });
      });
    };
    reader.readAsBinaryString(this.selectedFile);
  }

  negativeQuantityPositions(countsheetItems: any[]) {
    const positions = [];
    for (let index = 0; index < countsheetItems.length; index++) {
      const csi = countsheetItems[index];
      if (csi.quantity < 0) {
        positions.push(index + 2);
      }
    }
    return positions;
  }

  openSnackBar() {
    this.snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: this.snackBarConfig,
      duration: this.snackBarConfig.duration
    });
  }

  standardizeValue(v) {
    return (v || '').toString().toUpperCase().trim();
  }
}
