var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var PrintHelper = /** @class */ (function () {
    function PrintHelper() {
        this.rows = [];
        this.doc = new jsPDF('l', 'mm', 'letter');
        this.filename = '';
        this.totalPagesExp = '{total_pages_count_string}';
        this.date = this.getTodaysDate();
        this.countSheetItems = [];
    }
    PrintHelper.prototype.getTodaysDate = function () {
        var date = new Date();
        var dd = date.getDate();
        var mm = date.getMonth() + 1; //January is 0!
        var yyyy = date.getFullYear();
        if (dd < 10) {
            dd = 0 + dd;
        }
        if (mm < 10) {
            mm = 0 + mm;
        }
        var today = mm + '/' + dd + '/' + yyyy;
        return today;
    };
    PrintHelper.prototype.shrinkDescriptionColumn = function (text, size) {
        if (text.length > size) {
            text = text.substr(0, size) + '...';
        }
        return text;
    };
    PrintHelper.prototype.getAuditedByValue = function (countSheetItems) {
        var auditedBy = '';
        if (countSheetItems) {
            var names = countSheetItems.map(function (csi) { return csi.data.modified_by; }).filter(function (v, i, a) { return a.indexOf(v) === i && v; });
            auditedBy = names.join(' / ');
        }
        return auditedBy;
    };
    PrintHelper.prototype.print = function (countSheetItems, options) {
        this.doc = new jsPDF('l', 'mm', 'letter');
        this.rows = [];
        this.options = options;
        this.doc.page = 1;
        this.actualItemCount = 0;
        if (options.isKitted) {
            this.filename = options.locationName + "-" + options.reference + "-" + options.lotNumber + ".pdf";
        }
        else {
            this.filename = options.locationName + "-" + options.area + ".pdf";
        }
        var totalPagesExp = '{total_pages_count_string}';
        if (options.isKitted) {
            this.countSheetItems = countSheetItems.filter(function (csi) { return csi.data.is_consigned; });
            this.auditedBy = this.getAuditedByValue(countSheetItems);
            //countsheet pdf needs to include expected items and items that are apart of the kit and not yet counted
            if (options.statusText === 'Planned' || options.statusText === 'In-Progress') {
                this.buildRows(true);
            }
            else {
                this.buildRows(false, true);
            }
            if (this.rows.length <= 33) {
                this.buildOneTable(this.doc);
            }
            else {
                this.buildTwoTables(this.doc);
            }
        }
        else {
            this.countSheetItems = countSheetItems;
            this.auditedBy = this.getAuditedByValue(countSheetItems);
            if (options.statusText === 'Planned' || options.statusText === 'In-Progress') {
                this.buildRows(false);
            }
            else {
                this.buildRows(false, true);
            }
            if (this.rows.length <= 37) {
                this.buildOneTable(this.doc);
            }
            else {
                this.buildTwoTables(this.doc);
            }
        }
        if (typeof this.doc.putTotalPages === 'function') {
            this.doc.putTotalPages(totalPagesExp);
        }
        this.doc.save(this.filename);
    };
    PrintHelper.prototype.checkForWarnings = function (item) {
        var warnings = [];
        if (item.data.show_expired_warning) {
            warnings.push('E');
        }
        if (item.data.show_invalid_warning) {
            warnings.push('I');
        }
        if ((item.count_sheet_items || [item]).filter(function (i) { return i.data.show_recalled_warning; }).length > 0) {
            warnings.push('R');
        }
        if (item.data.is_excluded) {
            warnings.push('X');
        }
        if (item.data.manually_entered) {
            warnings.push('M');
        }
        if (this.options.lotPoolManagedItems.find(function (lpmi) { return lpmi.item_id === item.data.item_id; }) !== -1) {
            warnings.push('L');
        }
        return warnings;
    };
    PrintHelper.prototype.splitRows = function (rows) {
        return rows.splice(0, Math.ceil(rows.length / 2));
    };
    PrintHelper.prototype.getColumns = function () {
        this.columns = [{ verify: 'Vfy', reference: 'Reference', lot: 'Lot', qty: 'Qty' }];
        if (this.options.statusText === 'Planned' || this.options.statusText === 'In-Progress' && this.options.isKitted) {
            this.columns = this.columns.map(function (obj) { return (__assign({}, obj, { expQty: 'Exp Qty' })); });
        }
        this.columns = this.columns.map(function (obj) { return (__assign({}, obj, { description: 'Description', info: 'Info' })); });
        return this.columns;
    };
    PrintHelper.prototype.buildRows = function (showExpected, isCountedOrVerified) {
        if (isCountedOrVerified === void 0) { isCountedOrVerified = false; }
        var that = this;
        if (showExpected) {
            this.countSheetItems.forEach(function (value) {
                var newRow;
                var warnings = [];
                var quantity = that.formatQuantity(value);
                if ((isCountedOrVerified && quantity > 0) || !isCountedOrVerified) {
                    that.actualItemCount += quantity;
                    warnings = that.checkForWarnings(value);
                    newRow = {
                        verify: '',
                        qty: quantity,
                        expQty: value.data.expected_quantity,
                        reference: value.data.reference,
                        description: value.data.description,
                        lot: value.data.lot_number != null ? value.data.lot_number : '',
                        info: warnings.join(' ')
                    };
                    that.rows.push(newRow);
                }
            });
        }
        else {
            this.countSheetItems.forEach(function (value) {
                var newRow;
                var warnings = [];
                var quantity = parseInt(that.formatQuantity(value), 10);
                if (that.options.countSheet.data.show_expected_item_list) {
                    if ((isCountedOrVerified && quantity > 0) || !isCountedOrVerified) {
                        that.actualItemCount += quantity;
                        warnings = that.checkForWarnings(value);
                        newRow = {
                            verify: '',
                            qty: quantity,
                            reference: value.data.reference,
                            description: value.data.description,
                            lot: value.data.lot_number != null ? value.data.lot_number : '',
                            info: warnings.join(' ')
                        };
                    }
                }
                else if ((value.data.quantity >= 0 && !isCountedOrVerified) || (isCountedOrVerified && value.data.quantity > 0)) {
                    that.actualItemCount += quantity;
                    warnings = that.checkForWarnings(value);
                    newRow = {
                        verify: '',
                        qty: quantity,
                        reference: value.data.reference,
                        description: value.data.description,
                        lot: value.data.lot_number != null ? value.data.lot_number : '',
                        info: warnings.join(' ')
                    };
                }
                if (newRow) {
                    that.rows.push(newRow);
                }
            });
        }
    };
    PrintHelper.prototype.getColumnStyles = function () {
        var style = {
            info: { cellWidth: 10, fontSize: 6, fontStyle: 'bold', valign: 'middle', halign: 'center', cellPadding: { left: 0.5, top: 0.5, right: 0, bottom: 0.5 } },
            reference: { cellWidth: 10, fontSize: 6, fontStyle: 'bold', valign: 'middle', halign: 'left', cellPadding: { left: 0.5, top: 0.5, right: 0, bottom: 0.5 } },
            description: { cellWidth: 35, fontSize: 6, fontStyle: 'bold', valign: 'middle', halign: 'left', cellPadding: { left: 0.5, top: 0.5, right: 0.25, bottom: 0.5 } },
            lot: { cellWidth: 20, fontSize: 6, fontStyle: 'bold', valign: 'middle', halign: 'left', cellPadding: { left: 0.5, top: 0.5, right: 0.2, bottom: 0.5 } },
            qty: { cellWidth: 5, fontSize: 6, fontStyle: 'bold', valign: 'middle', halign: 'center', cellPadding: { left: 0.5, top: 0.5, right: 0, bottom: 0.5 } },
            verify: { cellWidth: 5, fontSize: 6, fontStyle: 'bold', valign: 'middle', halign: 'center', cellPadding: { left: 0.5, top: 0.5, right: 0, bottom: 0.5 } },
            expQty: { cellWidth: 3, fontSize: 6, fontStyle: 'bold', valign: 'middle', halign: 'center', cellPadding: { left: 0.5, top: 0.5, right: 0, bottom: 0.5 } }
        };
        return style;
    };
    PrintHelper.prototype.buildOneTable = function (doc) {
        var _this = this;
        doc.autoTable({
            head: this.getColumns(),
            body: this.rows,
            didDrawPage: (function () { return _this.headerAndFooter(); }),
            startY: 60,
            theme: 'grid',
            headStyles: {
                fillColor: [97, 97, 97]
            },
            columnStyles: this.getColumnStyles(),
            styles: {
                overflow: 'ellipsize',
                lineColor: 10,
                lineWidth: .25
            },
            margin: { top: 20, right: 14, left: 14 }
        });
    };
    PrintHelper.prototype.buildTwoTables = function (doc) {
        var _this = this;
        var pageNumber = doc.internal.getNumberOfPages();
        var rowsToPopulate = this.rows;
        var _loop_1 = function () {
            // the number of rows per page depends on which page of the document the table is on. Both numbers are
            var rowsForPage = pageNumber !== 1 ? rowsToPopulate.slice(0, 86) : rowsToPopulate.slice(0, 66);
            rowsToPopulate = rowsToPopulate.filter(function (el) { return !rowsForPage.includes(el); });
            var firstSetOfRows = this_1.splitRows(rowsForPage);
            var secondSetOfRows = rowsForPage.filter(function (el) { return !firstSetOfRows.includes(el); });
            doc.autoTable({
                head: this_1.getColumns(),
                body: firstSetOfRows,
                didDrawPage: (function () { return _this.headerAndFooter(); }),
                startY: pageNumber !== 1 ? doc.autoTable.previous.finalY + 100 : 60,
                theme: 'grid',
                headStyles: {
                    fillColor: [97, 97, 97]
                },
                columnStyles: this_1.getColumnStyles(),
                styles: {
                    overflow: 'ellipsize',
                    lineColor: 10,
                    lineWidth: .25
                },
                margin: { top: 20, right: 142 }
            });
            doc.setPage(pageNumber);
            doc.autoTable({
                head: this_1.getColumns(),
                body: secondSetOfRows,
                startY: pageNumber !== 1 ? 20 : 60,
                theme: 'grid',
                headStyles: {
                    fillColor: [97, 97, 97]
                },
                columnStyles: this_1.getColumnStyles(),
                styles: {
                    overflow: 'ellipsize',
                    lineColor: 10,
                    lineWidth: .25
                },
                margin: { top: 20, left: 142 }
            });
            pageNumber++;
        };
        var this_1 = this;
        while (rowsToPopulate.length > 0) {
            _loop_1();
        }
    };
    PrintHelper.prototype.headerAndFooter = function () {
        var doc = this.doc;
        var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        //header
        var agencyName = this.options.agencyName;
        var siteName = 'Site:  ' + this.options.locationName;
        var warehouse = 'Warehouse:  ' + this.options.warehouseName;
        var creator = 'Auditor(s):  ' + this.auditedBy;
        var splitString = doc.splitTextToSize(creator, 180);
        var itemCount = 'Total Items Counted: ' + this.actualItemCount;
        if (doc.page === 1) {
            doc.setFontSize(18);
            doc.text(agencyName, 14, 22);
            doc.setFontSize(11);
            doc.text(siteName, 14, 40);
            doc.text(warehouse, 180, 40);
            doc.text(splitString, 180, 56);
            doc.text(itemCount, 180, 48);
            if (this.options.isKitted) {
                var kitReference = 'Kit Reference #:  ' + this.options.reference;
                var kitLotNumber = 'Kit Lot #:  ' + this.options.lotNumber;
                doc.text(kitReference, 14, 56);
                doc.text(kitLotNumber, 14, 48);
            }
            else {
                var area = 'Area:  ' + this.options.area;
                doc.text(area, 14, 48);
            }
        }
        else {
            doc.setFontSize(10);
            doc.text('Agency: ' + agencyName, 180, 10);
            doc.text(siteName, 14, 5);
            doc.text(warehouse, 180, 5);
            doc.text(splitString, 180, 15);
            if (this.options.isKitted) {
                var kitReference = 'Kit Reference #:  ' + this.options.reference;
                var kitLotNumber = 'Kit Lot #:  ' + this.options.lotNumber;
                doc.text(kitReference, 14, 15);
                doc.text(kitLotNumber, 14, 10);
            }
            else {
                var area = 'Area:  ' + this.options.area;
                doc.text(area, 14, 15);
            }
        }
        //footer
        doc.setFontSize(10);
        doc.text(this.date, 10, pageHeight - 5);
        var footerStr = 'page ' + doc.page;
        if (typeof doc.putTotalPages === 'function') {
            footerStr = footerStr + ' of ' + this.totalPagesExp;
        }
        var legend = 'E - Expired, R - Recalled, I - Invalid Lot/Serial, X - Excluded, M - Manually Entered, L - Lot Pool Managed';
        doc.text(legend, 60, pageHeight - 5);
        doc.text(pageWidth - 30, pageHeight - 5, footerStr);
        doc.page++;
    };
    PrintHelper.prototype.formatQuantity = function (item) {
        var formattedQty = 0;
        if (item.count_sheet_items) {
            var checkedItems = item.count_sheet_items.filter(function (csi) { return csi.data.checked; }).length > 0;
            formattedQty = (item.data.quantity === 0) && (checkedItems) ? 0 : (checkedItems) ? item.data.quantity : 0;
        }
        else {
            formattedQty = (item.data.quantity === 0) && (item.data.checked) ? 0 : (item.data.checked) ? item.data.quantity : 0;
        }
        return formattedQty;
    };
    return PrintHelper;
}());
export { PrintHelper };
