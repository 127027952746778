import { Component, OnInit, OnDestroy } from '@angular/core';
import { TrackLoadingService } from '../../services/track-loading.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-loading-feedback',
  templateUrl: './loading-feedback.component.html',
  styleUrls: ['./loading-feedback.component.css']
})
export class LoadingFeedbackComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  messages = [];
  startLoadingSubscription: Subscription;
  stopLoadingSubscription: Subscription;
  loadingUpdatedSubscription: Subscription;
  constructor(
    private _loadingTracker: TrackLoadingService
  ) { }

  ngOnInit() {
    let that = this;
    this.startLoadingSubscription = this._loadingTracker.startLoadingEvent.subscribe(() => {
      that.loading = true;
    });

    this.stopLoadingSubscription = this._loadingTracker.stopLoadingEvent.subscribe(() => {
      that.loading = false;
    });

    this.loadingUpdatedSubscription = this._loadingTracker.updateCurrentLoadingEvent.subscribe(data => {
      that.messages = Object.values(data);
    });
  }

  ngOnDestroy(): void {
    if (this.startLoadingSubscription) {
      this.startLoadingSubscription.unsubscribe();
    }

    if (this.stopLoadingSubscription) {
      this.stopLoadingSubscription.unsubscribe();
    }

    if (this.loadingUpdatedSubscription) {
      this.loadingUpdatedSubscription.unsubscribe();
    }

  }

}
