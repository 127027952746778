import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var KitDefinitionItemService = /** @class */ (function () {
    function KitDefinitionItemService(http) {
        this.http = http;
    }
    KitDefinitionItemService.prototype.getKitDefinitionItems = function () {
        return this.http.get(environment.serverPath + "audit_api/manufacturers/1/kit_definition_items");
    };
    KitDefinitionItemService.ngInjectableDef = i0.defineInjectable({ factory: function KitDefinitionItemService_Factory() { return new KitDefinitionItemService(i0.inject(i1.HttpClient)); }, token: KitDefinitionItemService, providedIn: "root" });
    return KitDefinitionItemService;
}());
export { KitDefinitionItemService };
