<div *ngIf="loading" class="spinner">
  <mat-spinner diameter="50" color="primary">
  </mat-spinner>
</div>
<div [ngClass]="{'disabled' : loading}" class="import-comp">
  <h5 class="header-title">Consignment Account Inventory Import
    <button class="pull-right" mat-icon-button color="primary" placement="auto" ngbTooltip="Pick File" (click)="fileInput.click()">
      <mat-icon>cloud_upload</mat-icon>
    </button>
  </h5>

  <div class="row label">
    <input #fileInput class="hidden-input" type="file" (change)="onFileSelected($event)" [(ngModel)]="pickedFile" name="file">
  </div>

  <div class="row">
    <div class="col-6 half-max-witdh" *ngIf="selectedFile">
      <label mat-button [title]="selectedFile.name" for="">{{selectedFile.name}}</label>
    </div>
    <div class="col-6 import-comp" *ngIf="selectedFile">
      <button class="pull-right" mat-icon-button color="accent" (click)="onUpload()" ngbTooltip="Send File" placement="auto">
        <i class="material-icons">send</i>
      </button>
    </div>
  </div>

  <div class="clear_both"></div>
  <mat-error *ngIf="audit.consignment_account_inventory_imported_date">
    Inventory has already been imported, importing new inventory will reset the previous import.
    Click <a routerLink="/consignment_account_import_list/{{audit.id}}" (click)="onNoClick()">Here</a> to view the
    previous import.
  </mat-error>
  <div>
      <div mat-dialog-actions class="pull-right">
        <button ngbTooltip="Download Template" placement="top" mat-icon-button (click)="templateFrontEnd()">
          <mat-icon>cloud_download</mat-icon>
        </button>
      </div>
    </div>
  <div mat-dialog-actions class="pull-right">
      <button mat-button (click)="onNoClick()">Cancel</button>
    </div>
</div>