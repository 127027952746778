<form #AddCountSheetItemForm="ngForm" (ngSubmit)="onSubmit(AddCountSheetItemForm.form)">
  <app-reference-field (itemIsSerialTracked)="toggleSerialNumberInput($event)"></app-reference-field>
  <mat-form-field class="form-input">
    <input ngModel matInput placeholder="Lot Number" name="lot_number" [(ngModel)]="lotNumber">
  </mat-form-field>

  <mat-form-field class="form-input" [ngClass]="{'disabled': disableSerialNumberInput }">
    <input ngModel matInput placeholder="Serial Number" name="serial_number" [(ngModel)]="serialNumber" [ngClass]="{'disabled': disableSerialNumberInput }" #serial>
  </mat-form-field>

  <mat-form-field class="form-input">
    <input ngModel matInput placeholder="Quantity" type="number" min="0" name="quantity" [(ngModel)]="quantity" (keydown)="cleanQuantity($event)">
  </mat-form-field>

  <mat-form-field class="form-input">
    <input ngModel matInput [matDatepicker]="picker" placeholder="Expiration Date" name="expiration_date" [(ngModel)]="expirationDate">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <span class="tooltipWrapper">
    <button ngbTooltip="Add Item" class="add-button" placement="top" mat-raised-button type="submit" mat-button>
      Add Item
    </button>
  </span>  
  <span class="tooltipWrapper">
    <button type="button" ngbTooltip="Clear" class="clear-button" placement="top" mat-raised-button (click)="reset()"
      mat-button>
      Clear
    </button>
  </span>  
</form>
<app-barcode-parser (referenceScanned)="setReference($event)" (lotScanned)="setLot($event)" (serialScanned)="setSerial($event)"
  (quantityScanned)="setQuantity($event)" (expirationScanned)="setExpiration($event)" (done)="submitForm(true)" (itemWasNotFound)="reset()" [countsheetItemHelper]="countsheetItemHelper" [items]="items"></app-barcode-parser>
