import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { urls } from './../helpers/urls';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AuditLocationService = /** @class */ (function () {
    function AuditLocationService(http) {
        this.http = http;
    }
    AuditLocationService.prototype.getLocation = function (auditId, auditLocationId) {
        return this.getLocations(auditId.toString()).pipe(map(function (data) {
            return data.find(function (l) { return l.id === auditLocationId; });
        }));
    };
    AuditLocationService.prototype.getLocations = function (auditId) {
        return this.http.get("" + environment.serverPath + urls.auditLocations + "?audit_id=" + auditId, {});
    };
    AuditLocationService.prototype.createLocation = function (url, newAuditLocation) {
        var formData = new FormData();
        formData.append('location', JSON.stringify(newAuditLocation));
        return this.http.post(environment.serverPath + url, formData);
    };
    AuditLocationService.prototype.updateLocation = function (url, location) {
        var formData = new FormData();
        formData.append('location', JSON.stringify(location));
        return this.http.put(environment.serverPath + url, formData);
    };
    AuditLocationService.prototype.deleteLocation = function (url, id) {
        var params = new HttpParams().set('id', id.toString());
        return this.http.delete(environment.serverPath + url, { params: params });
    };
    AuditLocationService.prototype.getWarehousesForSite = function (id) {
        var path = environment.serverPath + urls.getWarehousesForSite;
        var params = new HttpParams().set('site_id', id.toString());
        return this.http.get(path, { params: params });
    };
    AuditLocationService.prototype.removeWarehouseFromSite = function (siteWarehouseId, site_id) {
        var formData = new FormData();
        formData.append('id', JSON.stringify(siteWarehouseId));
        formData.append('site_id', JSON.stringify(site_id));
        return this.http.post(environment.serverPath + urls.removeWarehouseFromSite, formData);
    };
    AuditLocationService.prototype.finishSite = function (site_id, warehouses) {
        var formData = new FormData();
        formData.append('site_id', JSON.stringify(site_id));
        formData.append('warehouses', JSON.stringify(warehouses));
        return this.http.post(environment.serverPath + urls.finishSite, formData);
    };
    AuditLocationService.ngInjectableDef = i0.defineInjectable({ factory: function AuditLocationService_Factory() { return new AuditLocationService(i0.inject(i1.HttpClient)); }, token: AuditLocationService, providedIn: "root" });
    return AuditLocationService;
}());
export { AuditLocationService };
