/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./barcode-hibc-format.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./barcode-hibc-format.component";
import * as i3 from "@angular/material/snack-bar";
var styles_BarcodeHibcFormatComponent = [i0.styles];
var RenderType_BarcodeHibcFormatComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BarcodeHibcFormatComponent, data: {} });
export { RenderType_BarcodeHibcFormatComponent as RenderType_BarcodeHibcFormatComponent };
export function View_BarcodeHibcFormatComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_BarcodeHibcFormatComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-barcode-hibc-format", [], null, null, null, View_BarcodeHibcFormatComponent_0, RenderType_BarcodeHibcFormatComponent)), i1.ɵdid(1, 114688, null, 0, i2.BarcodeHibcFormatComponent, [i3.MatSnackBar], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BarcodeHibcFormatComponentNgFactory = i1.ɵccf("app-barcode-hibc-format", i2.BarcodeHibcFormatComponent, View_BarcodeHibcFormatComponent_Host_0, { items: "items", countsheetItemHelper: "countsheetItemHelper" }, { itemNotFound: "itemNotFound" }, []);
export { BarcodeHibcFormatComponentNgFactory as BarcodeHibcFormatComponentNgFactory };
