import { Injectable } from '@angular/core';
import { FileOptionUtils } from '../helpers/file-option-utils';
import { IFileOptions } from '../interfaces/i-file-options';

declare var window: any;
declare var navigator: any;

@Injectable({
  providedIn: 'root'
})
export class FileSystemService {

  storage: any = navigator.storage;
  tree: string[] = [];

  constructor(private _fileOptionUtils: FileOptionUtils) { }

  async save(contents: any, options: IFileOptions) {
    const handle = await this.getSaveFileHandle(options);
    if (handle) {
      const writable = await handle.createWritable();
      await writable.write(contents);
      await writable.close();
    }
  }

  async saveContent(contents: any, fileHandle: any) {
    const writable = await fileHandle.createWritable();
    await writable.write(contents);
    await writable.close();
  }

  async saveFile(file: any, handle: any){
    let readableStream = file.stream()
    const writable = await handle.createWritable();
    await readableStream.pipeTo(writable)
  }

  async getSaveFileHandle(options: IFileOptions) {
    let handle = null;
    try {
      handle = await window.showSaveFilePicker(options);
    } catch (error) {
      console.log(error);
    }
    return handle;
  }

  async pickFile(options: IFileOptions) {
    let fileHandle = null;
    try {
      fileHandle = await window.showOpenFilePicker(options);
    } catch (error) {
      console.log(error);
    }
    return fileHandle;
  }

  // returns handle of existing or created directory
  async getDirectoryHandle(baseDirectoryHandle: any, options: IFileOptions) {
    const dirHandle = await baseDirectoryHandle.getDirectoryHandle(options.directoryName, {
      create: options.createDirectory
    });

    return dirHandle;
  }

  async pickDirectory() {
    let directoryHandle = null;
    try {
      directoryHandle = await window.showDirectoryPicker();
    } catch (error) {
      console.log(error);
    }
    return directoryHandle;
  }

  async verifyPermission1(fileHandle: any, withWrite: boolean): Promise<boolean> {
    const opts: any = {};
    if (withWrite) {
      opts.writable = true;
      // For Chrome 86 and later...
      opts.mode = 'readwrite';
    }
    // Check if we already have permission, if so, return true.
    if (await fileHandle.queryPermission(opts) === 'granted') {
      return true;
    }
    // Request permission to the file, if the user grants permission, return true.
    if (await fileHandle.requestPermission(opts) === 'granted') {
      return true;
    }
    // The user did nt grant permission, return false.
    return false;
  }

  verifyPermission(fileHandle: any, withWrite: boolean): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const opts: any = {};
      if (withWrite) {
        opts.writable = true;
        // For Chrome 86 and later...
        opts.mode = 'readwrite';
      }
      fileHandle.queryPermission(opts).then((flag: string) => {
        if(flag === 'granted'){
          return resolve(true);
        }

        fileHandle.requestPermission(opts).then((flag: string) => {
          if(flag === 'granted'){
            return resolve(true);
          }
          return resolve(false);
        })
      })
    })
  }

  getFile(fileHandle: any) {
    return new Promise((resolve, reject) => {
      this.verifyPermission(fileHandle, false).then((granted) => {
        if (granted) {
          fileHandle.getFile().then((file) => {
            resolve(file);
          });
        } else {
          reject()
        }
      })
    })
  }

  async getFiles(fileHandles: any[]) {
    const files = [];
    const that = this;
    for (const fh in fileHandles) {
      if (fh) {
        const file = await that.getFile(fh);
        if (file) {
          files.push(file);
        }
      }
    }
    return files;
  }

  async getContents(fileHandle: any) {
    const file:any = await this.getFile(fileHandle);
    const contents = await file.text();
    return contents;
  }

  async getRoot() {
    const root = await this.storage.getDirectory();
    return root;
  }

  // root operations
  async createDirectoryFromRoot(options: IFileOptions) {
    const root = await this.getRoot();

    for await (const v of root.values()) {
      console.log('entry', v);
    }

    let directoryHandle = null;
    try {
      directoryHandle = await root.getDirectoryHandle(options.directoryName, { create: options.createDirectory });
    } catch (error) {
      console.log(error);
    }
    return directoryHandle;
  }

  async createDirectoryFrom(directoryHandle: any, options: IFileOptions) {
    let dirHandle = null;
    try {
      dirHandle = await directoryHandle.getDirectoryHandle(options.directoryName, { create: options.createDirectory });
    } catch (error) {
      console.log(error);
    }
    return dirHandle;
  }

  async getDirectoryFromRoot(options: IFileOptions) {
    const root = await this.getRoot();
    let directoryHandle = null;
    try {
      directoryHandle = await root.getDirectoryHandle(options.directoryName, { create: false });
    } catch (error) {
      console.log(error);
    }
    return directoryHandle;
  }

  async createFileFromRoot(options: IFileOptions) {
    const root = await this.getRoot();

    let fileHandle = null;
    try {
      fileHandle = await root.getFileHandle(options.filename, { create: options.createFile });
    } catch (error) {
      console.log(error);
    }
    return fileHandle;
  }

  async createFileFrom(directoryHandle: any, options: IFileOptions) {
    let fileHandle = null;
    try {
      fileHandle = await directoryHandle.getFileHandle(options.filename, { create: options.createFile });
    } catch (error) {
      console.log(error);
    }
    return fileHandle;
  }

  async removeFileFromRoot(options: IFileOptions) {
    const root = await this.getRoot();
    try {
      await root.removeEntry(options.filename);

    } catch (error) {
      console.log(error);
    }
  }

  async removeDirectoryFromRoot(options: IFileOptions) {
    const root = await this.getRoot();
    try {
      await root.removeEntry(options.directoryName);
    } catch (error) {
      console.log(error);
    }
  }

  async getEntriesInRoot() {
    const root = await this.getRoot();
    const entries = [];
    for await (const entry of root.values()) {
      entries.push(entry);
    }
    return entries;
  }

  async getDirectoriesInRoot() {
    const rootEntries = await this.getEntriesInRoot();
    const dirs = rootEntries.filter((x) => {
      return x.kind === this._fileOptionUtils.FILE_SYSTEM_HANDLE_KINDS.directory;
    });
    return dirs;
  }

  async getFilesInRoot() {
    const rootEntries = await this.getEntriesInRoot();
    const files = rootEntries.filter((x) => {
      return x.kind === this._fileOptionUtils.FILE_SYSTEM_HANDLE_KINDS.file;
    });
    return files;
  }

  async getPath(fileHandle: any) {
    const root = await this.getRoot();
    const path = await root.resolve(fileHandle);
    return path;

  }

  async printRootTree() {
    const root = await this.getRoot();
    const handles = await this.walkDir(root, null);
    return handles;

  }

  async walkDir(root, list) {
    const entries = [];
    for await (const entry of root.values()) {
      entries.push(entry);
    }
    list = list || [];
    entries.forEach(async (h) => {
      if (h.kind === 'directory') {
        list = await this.walkDir(h, list);
      } else {
        list.push(h);
        const baseRoot = await this.getRoot();
        const path = await baseRoot.resolve(h);
        console.log(`root > ${path.join('/')}`);
      }
    });
    return list;
  }
}
