import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { ConsignmentAccountImportListDataSource } from './consignment-account-import-list-datasource';
import { ActivatedRoute, Router } from "@angular/router";
import { ConsignmentAccountDataService } from 'src/app/services/consignment-account-data.service';

@Component({
  selector: 'app-consignment-account-import-list',
  templateUrl: './consignment-account-import-list.component.html',
  styleUrls: ['./consignment-account-import-list.component.css']
})
export class ConsignmentAccountImportListComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: ConsignmentAccountImportListDataSource;
  private consignementInventory: Array<Object> = [];
  private filters = { success: [true, false] };
  displayedColumns = ["warehouse","reference", "lot_number", "status", "quantity"];
  auditId;


  constructor(
    private _consignmentAccountService: ConsignmentAccountDataService,
    private _route: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnDestroy(): void {

  }

  ngOnInit() {
    this.auditId = this._route.snapshot.paramMap.get('id');
    this.getSalesOrderInventoryImport(this.auditId);
  }

  getSalesOrderInventoryImport(auditId) {
    this._consignmentAccountService.getInventory(auditId).subscribe(data => {
      this.consignementInventory = data;
      this.dataSource = new ConsignmentAccountImportListDataSource(this.paginator, this.sort, this.consignementInventory, this.filters);
    });
  }

  goToAuditDashboard() {
    this._router.navigate(['audit_dashboard', this.auditId]);
  }
}
