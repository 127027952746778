import * as i0 from "@angular/core";
var FileCacheService = /** @class */ (function () {
    function FileCacheService() {
        this.cache = {};
    }
    FileCacheService.prototype.hasHandle = function (key) {
        var keys = Object.keys(this.cache);
        return keys.includes(key);
    };
    FileCacheService.prototype.getHandle = function (key) {
        var handle = null;
        if (this.hasHandle(key)) {
            handle = this.cache[key];
        }
        return handle;
    };
    FileCacheService.ngInjectableDef = i0.defineInjectable({ factory: function FileCacheService_Factory() { return new FileCacheService(); }, token: FileCacheService, providedIn: "root" });
    return FileCacheService;
}());
export { FileCacheService };
