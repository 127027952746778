import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { urls } from '../helpers/urls';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ItemPackageService = /** @class */ (function () {
    function ItemPackageService(http) {
        this.http = http;
    }
    ItemPackageService.prototype.get = function () {
        return this.http.get("" + environment.serverPath + urls.itemPackages);
    };
    ItemPackageService.ngInjectableDef = i0.defineInjectable({ factory: function ItemPackageService_Factory() { return new ItemPackageService(i0.inject(i1.HttpClient)); }, token: ItemPackageService, providedIn: "root" });
    return ItemPackageService;
}());
export { ItemPackageService };
