import { Component, OnInit, OnDestroy, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatSnackBar } from '@angular/material';
import { AuditLocationsDataSource } from './audit-locations-datasource';
import { urls } from '../../helpers/urls';
import { AuditLocationService } from '../../services/audit-location.service';
import { IAuditLocation } from '../../interfaces/iaudit-location';
import { ISnackBarConfig } from '../../interfaces/isnack-bar-config';
import { EditLocationComponent } from './edit-location/edit-location.component';
import { AddLocationComponent } from './add-location/add-location.component';
import { Router } from '@angular/router';
import { NgModel } from '@angular/forms';
import { CountSheetService } from '../../services/count-sheet.service';
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';
import { ConfirmSiteCompleteDialogComponent } from '../confirm-site-complete-dialog/confirm-site-complete-dialog.component';
import { TrackLoadingService } from 'src/app/services/track-loading.service';


@Component({
  selector: 'app-audit-locations',
  templateUrl: './audit-locations.component.html',
  styleUrls: ['./audit-locations.component.css']
})
export class AuditLocationsComponent implements OnInit, OnDestroy {
  @Input() auditID: string;
  @Input() isOnline: boolean;
  @Input() audit: any;
  @Output() locationsLoadedEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() siteCreatedOrEditedEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  auditLocations: IAuditLocation[];
  @Input() warehouses: any[];
  warehouseIDList: any[];
  dataSource: AuditLocationsDataSource;
  redspotInventoryImported = false;
  sapInventoryImported = false;
  public selectedWarehouses: any = [];
  public wareHousesWithSheets: any = [];
  pageSizeOptions: number[] = [1000];
  statuses: any = ['Planned', 'Cancelled', 'Completed', 'In-Progress'];

  newLocation: any = {
    location_name: null,
    audit_id: null,
    warehouse_ids: null
  };

  locationToUpdate: any = {
    id: null,
    location_name: null,
    audit_id: null,
    warehouse_ids: null
  };

  snackBarConfig: ISnackBarConfig = {
    message: '',
    duration: 3000,
    success: false,
    snackBarClass: ''
  };


  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['site_name', 'warehouses', 'status', 'addLocation'];

  constructor(
    private _auditLocationService: AuditLocationService,
    private _countSheetService: CountSheetService,
    public dialog: MatDialog,
    private _router: Router,
    public snackBar: MatSnackBar,
    private _trackLoadingService: TrackLoadingService
  ) { }

  ngOnDestroy(): void {

  }

  ngOnInit() {
    if (this.audit.redspot_inventory_imported_date) {
      this.redspotInventoryImported = true;
    }
    if (this.audit.sap_inventory_imported_date) {
      this.sapInventoryImported = true;
    }
    this.getAuditLocations();
  }

  selectAll(select: NgModel): void {
    select.update.emit(this.warehouseIDList);
  }

  deselectAll(select: NgModel): void {
    select.update.emit(this.wareHousesWithSheets);
  }

  buildWarehouseIDList() {
    const temp = [];
    this.warehouses.forEach(function (warehouse) {
      temp.push(warehouse.account_id);
    });
    this.warehouseIDList = temp;
  }

  getAuditLocations() {
    this._trackLoadingService.startLoading('audit-locations-get-audit-locations','Loading Audit Locations');
    this._countSheetService.getCountSheets(this.auditID, { only_kitted: true }).subscribe();
    this._auditLocationService.getLocations(this.auditID).subscribe(data => {
      this._trackLoadingService.stopLoading('audit-locations-get-audit-locations');
      this.auditLocations = data;
      this.pageSizeOptions = [this.auditLocations.length, 1000];
      this.dataSource = new AuditLocationsDataSource(this.paginator, this.sort, this.auditLocations);
    });
  }

  openDialog(row): void {
    this.buildWarehouseIDList();
    this._auditLocationService.getWarehousesForSite(row.id).subscribe(result => {
      if (result) {
        const temp = [];
        const withSheetsTemp = [];
        result.warehouses.forEach(function (warehouse) {
          temp.push(warehouse.warehouse_id);
        });
        result.warehousesWithSheets.forEach(function (w) {
          withSheetsTemp.push(w.warehouse_id);
        });
        this.selectedWarehouses = temp;
        this.wareHousesWithSheets = withSheetsTemp;
        const dialogRef = this.dialog.open(EditLocationComponent, {
          width: '30%',
          data: {
            location: row.location_name,
            warehouse_ids: this.selectedWarehouses,
            warehouses: this.warehouses,
            warehousesWithSheets: this.wareHousesWithSheets,
            toggle: this.toggleSelectAll,
            listOfWarehouseIDs: this.warehouseIDList
          }
        });
        this.locationToUpdate.id = row.id;
        this.locationToUpdate.audit_id = row.audit_id;
        dialogRef.afterClosed().subscribe(res => {
          this._trackLoadingService.startLoading('audit-locations-update-locatin', 'Updating Audit Location');
          if (res) {
            this.locationToUpdate.location_name = res.location;
            this.locationToUpdate.warehouse_ids = res.warehouse_ids;
            this._auditLocationService.updateLocation(urls.updateAuditLocation, this.locationToUpdate).subscribe(locChangeResult => {
              this._trackLoadingService.stopLoading('audit-locations-update-locatin');
              if (locChangeResult.result) {
                this.snackBarConfig.message = 'Site Updated Successfully';
                this.snackBarConfig.success = true;
                this.openSnackBar();
                this.siteCreatedOrEditedEvent.emit();
                this.getAuditLocations();

              } else {
                this.snackBarConfig.message = locChangeResult.message;
                this.snackBarConfig.success = false;
                this.openSnackBar();
              }
            });
          } else {
            this._trackLoadingService.stopLoading('audit-locations-update-locatin');
          }

        });
      }
    });
  }

  openAddDialog() {
    this.buildWarehouseIDList();
    const dialogRef = this.dialog.open(AddLocationComponent, {
      width: '50%',
      data: {
        site: null,
        warehouse_id: 0,
        warehouses: this.warehouses,
        toggle: this.toggleSelectAll,
        listOfWarehouseIDs: this.warehouseIDList
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this._trackLoadingService.startLoading('audit-location-create', 'Creating Audit Location');
      if (result) {
        this.newLocation.location_name = result.site;
        this.newLocation.audit_id = parseInt(this.auditID, 10);
        this.newLocation.warehouse_ids = result.warehouse_ids;
        this._auditLocationService.createLocation(urls.createAuditLocation, this.newLocation).subscribe(locCreatedResult => {
          this._trackLoadingService.stopLoading('audit-location-create');
          if (locCreatedResult.result) {
            this.snackBarConfig.message = 'Site Created Successfully';
            this.snackBarConfig.success = true;
            this.openSnackBar();
            this.siteCreatedOrEditedEvent.emit();
            this.getAuditLocations();

          } else {
            this.snackBarConfig.message = locCreatedResult.message;
            this.snackBarConfig.success = false;
            this.openSnackBar();
          }
        });
      } else {
        this._trackLoadingService.stopLoading('audit-location-create');
      }
    });
  }

  toggleSelectAll(select: NgModel, event, values, warehouseToKeep) {
    if (event.checked) {
      select.update.emit(values);
    } else {
      select.update.emit(warehouseToKeep);
    }
  }

  deleteLocation(row: any) {
    this._auditLocationService.deleteLocation(urls.deleteAuditLocation, row.id).subscribe(result => {
      if (result.result) {
        this.snackBarConfig.message = 'Site Removed Successfully';
        this.snackBarConfig.success = true;
        this.openSnackBar();
        this.ngOnInit();
      } else {
        this.snackBarConfig.message = result.message;
        this.snackBarConfig.success = false;
        this.openSnackBar();
      }
    });
  }

  goToCountSheets(row: any) {
    this._router.navigate([row.audit_id, 'countsheets', row.id], { queryParams: {status: 'All', orderType: 'consigned' } });
  }

  removeWarehouse(warehouse: any) {
    if (!warehouse.has_count_sheet) {
      this._auditLocationService.removeWarehouseFromSite(warehouse.id, warehouse.site_id).subscribe(result => {
        if (result.result) {
          this.snackBarConfig.message = 'Warehouse Removed Successfully';
          this.snackBarConfig.success = true;
          this.openSnackBar();
          this.ngOnInit();
        } else {
          this.snackBarConfig.message = result.message;
          this.snackBarConfig.success = false;
          this.openSnackBar();
        }
      });
    }
  }

  finishLocation(row: any) {
    const unVerifiedCountSheets = row.count_sheets.filter(cs => {
      return cs.count_sheet_status !== 4 && cs.count_sheet_status !== 5;
    });
    const countSheets = [];
    unVerifiedCountSheets.forEach(cs => {
      let key = '';
      if (cs.count_sheet_status === 3) {
        key = 'Counted';
      } else if (cs.count_sheet_status === 2) {
        key = 'In-Progress';
      }
      countSheets[key] = countSheets[key] || { kittedQty: 0, nonKittedQty: 0 };
      if (cs.audit_kit_instance_id) {
        countSheets[key].kittedQty += 1;
      } else {
        countSheets[key].nonKittedQty += 1;
      }

    });

    if (unVerifiedCountSheets.length > 0) {
      this.openConfirmSiteCompleteDialog(countSheets, row);
    } else {
      this._auditLocationService.finishSite(row.id, row.site_warehouses).subscribe(result => {
        if (result.result) {
          this.snackBarConfig.message = 'Success';
          this.snackBarConfig.success = true;
          this.openSnackBar();
          this.siteCreatedOrEditedEvent.emit();
          this.ngOnInit();
        } else {
          this.snackBarConfig.message = result.message;
          this.snackBarConfig.success = false;
          this.openSnackBar();
          this.ngOnInit();
        }
      });
    }
  }

  showInventoryWarningMessage() {
    this.snackBarConfig.message = 'Please Import Redspot and SAP Inventory First';
    this.snackBarConfig.success = false;
    this.openSnackBar();
  }

  updateAuditImportDate(inventoryImported: any) {
    if (inventoryImported === 'Redspot') {
      this.redspotInventoryImported = true;
    } else if (inventoryImported === 'SAP') {
      this.sapInventoryImported = true;
    }
  }

  openSnackBar() {
    this.snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: this.snackBarConfig,
      duration: this.snackBarConfig.duration
    });
  }

  openConfirmSiteCompleteDialog(countSheets, row) {
    const dialogRef = this.dialog.open(ConfirmSiteCompleteDialogComponent, {
      width: '30%',
      data: {
        countSheets: countSheets,
        auditId: this.auditID,
        siteId: row.id
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this._auditLocationService.finishSite(row.id, row.site_warehouses).subscribe(result => {
          if (result.result) {
            this.snackBarConfig.message = 'Success';
            this.snackBarConfig.success = true;
            this.openSnackBar();
            this.siteCreatedOrEditedEvent.emit();
            this.ngOnInit();
          } else {
            this.snackBarConfig.message = result.message;
            this.snackBarConfig.success = false;
            this.openSnackBar();
            this.ngOnInit();
          }
        });
      }
    });
  }
}
