<div class="mat-elevation-z8 data-table">
  <table mat-table #audit [dataSource]="dataSource" matSort>
    <!-- Id Column -->
    <ng-container matColumnDef="location_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Location</th>
      <td mat-cell *matCellDef="let row" (click)="goToAuditDetails(row)" class="auditName">{{row.location_name}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="distributorship_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Agency</th>
      <td mat-cell *matCellDef="let row" (click)="goToAuditDetails(row)">{{row.distributorship.name}}</td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Dates</th>
      <td mat-cell *matCellDef="let row" (click)="goToAuditDetails(row)">{{row.start_date | date:'mediumDate'}} -
        {{row.end_date | date:'mediumDate'}}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Status</th>
      <td mat-cell *matCellDef="let row" (click)="goToAuditDetails(row)">{{row.status}}</td>
    </ng-container>

    <ng-container matColumnDef="addButton">
      <th mat-header-cell *matHeaderCellDef class="table-header">
        <span class="tooltipWrapper">
          <button *ngxPermissionsOnly="['audit_administrator']" mat-icon-button type="button" ngbTooltip="Add Audit"
            placement="right" (click)="openDialog()">
            <mat-icon class="material-icons">add_circle</mat-icon>
          </button>
        </span>
      </th>
      <td mat-cell *matCellDef="let row">
        <div *ngxPermissionsOnly="['audit_administrator']">
            <span class="tooltipWrapper">
          <button mat-icon-button type="button" (click)="openEditDialog(row)" ngbTooltip="Edit Audit" placement="right" *ngIf="row.status !== 'Cancelled'">           
            <mat-icon class="material-icons">edit</mat-icon>          
          </button>
          </span>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="auditRow"></tr>
  </table>

  <mat-paginator #paginator [pageSizeOptions]="[10, 20, 50]">
  </mat-paginator>
</div>
