import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '../../../../../node_modules/@angular/material';
import { ISnackBarConfig } from 'src/app/interfaces/isnack-bar-config';
import { MaterialSnackbarComponent } from '../../material-snackbar/material-snackbar.component';

@Component({
  selector: 'app-edit-location',
  templateUrl: './edit-location.component.html',
  styleUrls: ['./edit-location.component.css']
})
export class EditLocationComponent implements OnInit {
  snackBarConfig: ISnackBarConfig = {
    message: "",
    duration: 3000,
    success: false,
    snackBarClass: ""
  }
  constructor(
    public dialogRef: MatDialogRef<EditLocationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public snackBar: MatSnackBar) {}


  ngOnInit() {
  }
  validateName(data) {
    if (data.location.length > 50) {
      this.snackBarConfig.message = 'Location Name Exceeds Max Length of 50 Characters';
      this.snackBarConfig.success = false;
      this.openSnackBar();
    } else {
      this.dialogRef.close(data);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openSnackBar() {
    this.snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: this.snackBarConfig,
      duration: this.snackBarConfig.duration
    })
  }

}
