import { HttpClient } from '@angular/common/http';
import { urls } from '../helpers/urls';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ConsignmentAccountDataService = /** @class */ (function () {
    function ConsignmentAccountDataService(http) {
        this.http = http;
    }
    ConsignmentAccountDataService.prototype.upload = function (formData) {
        return this.http.post("" + environment.serverPath + urls.consignmentAccountData + "/import", formData);
    };
    ConsignmentAccountDataService.prototype.getInventory = function (auditId) {
        return this.http.get("" + environment.serverPath + urls.consignmentAccountData + "?audit_id=" + auditId);
    };
    ConsignmentAccountDataService.ngInjectableDef = i0.defineInjectable({ factory: function ConsignmentAccountDataService_Factory() { return new ConsignmentAccountDataService(i0.inject(i1.HttpClient)); }, token: ConsignmentAccountDataService, providedIn: "root" });
    return ConsignmentAccountDataService;
}());
export { ConsignmentAccountDataService };
