import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileCacheService {
  cache: any = {}

  constructor() { }

  hasHandle(key:string){
    let keys = Object.keys(this.cache);
    return keys.includes(key);
  }

  getHandle(key: string){
    let handle = null;
    if(this.hasHandle(key)){
      handle = this.cache[key];
    }
    return handle
  }
}
