<div *ngIf="loading" class="spinner">
  <mat-spinner diameter="50" color="primary"></mat-spinner>
</div>

<div class="grid-container" [ngClass]="{'disabled' : loading}">
  <div class="row" *ngIf="!loading">
    <div class="col-md-12">
      <button mat-raised-button class="back-to-dashboard" type="button" (click)="goToAuditDashboard()" ngbTooltip="Go To Dashboard"
        placement="right">
        <mat-icon>dashboard</mat-icon>
        Dashboard
      </button>
    </div>
  </div>

  <div class="title-section" *ngIf="!loading">
    <h1 class="header-title center">WAREHOUSE DASHBOARD</h1>
    <h2 class="header-title center">{{warehouse?.name}}</h2>
  </div>

  <div class="row" *ngIf="!loading">
    <!--Items Progress-->
    <div class="col-md-6">
      <div class="progress-card full-size">
        <mat-card class="full-size">
          <mat-card-title>ITEMS</mat-card-title>
          <mat-card-content>
            <div class="bar">
              <section>
                <mat-progress-bar [mode]="mode" [value]="itemPercentage">
                </mat-progress-bar>
              </section>
            </div>
            <h5 style="margin-left: 10px;">
              <mat-chip-list>
                <mat-chip class="progress-expected" selected>
                  {{warehouse?.expected_item_count}} {{warehouse?.expected_item_count > 1? 'ITEMS': 'ITEM'}} PLANNED
                </mat-chip>
                <mat-chip class="progress-counted" selected>{{warehouse?.actual_item_count}} {{warehouse?.actual_item_count > 1? 'ITEMS': 'ITEM'}} COUNTED</mat-chip>

                <mat-chip class="progress-complete" selected>
                  {{itemPercentage}}% COMPLETE
                </mat-chip>
              </mat-chip-list>
            </h5>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <!--Kit Progress-->
    <div class="col-md-6">
      <div class="progress-card full-size">
        <mat-card class="full-size">
          <mat-card-title>KITS</mat-card-title>
          <mat-card-content>
            <div class="bar">
              <section >
                <mat-progress-bar class="example-margin" [mode]="mode" [value]="kitPercentage">
                </mat-progress-bar>
              </section>
            </div>
            <h5 style="margin-left: 10px;">
              <mat-chip-list>
                <mat-chip class="progress-expected" selected>{{warehouse?.expected_kit_count}} {{warehouse?.expected_kit_count > 1? 'KITS': 'KIT'}} PLANNED</mat-chip>
                <mat-chip class="in-progress" selected>{{warehouse?.kits_inprogress_count}} {{warehouse?.kits_inprogress_count > 1? 'KITS': 'KIT'}} IN-PROGRESS</mat-chip>
                <mat-chip class="progress-counted" selected>{{warehouse?.kit_count_counted}} {{warehouse?.kit_count_counted > 1? 'KITS NEED': 'KIT NEEDS'}} VERIFICATION</mat-chip>
                <mat-chip class="progress-counted" selected>{{warehouse?.kit_count_verified}} {{warehouse?.kit_count_verified > 1? 'KITS': 'KIT'}} VERIFIED</mat-chip>
                <mat-chip class="progress-complete" selected>
                  {{kitPercentage}}% COMPLETE
                </mat-chip>
              </mat-chip-list>
            </h5>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>


  <div class="toggles" *ngIf="!loading">
    <div class="row">
      <div class="col-md-12 toggle-row">
        <mat-button-toggle-group [value]="'counted'" #group="matButtonToggleGroup">
          <mat-button-toggle class="counted" value="counted" (click)="changeTable('counted')">
            Overall Count ({{lengths?.counted}})
          </mat-button-toggle>
          <mat-button-toggle class="overage" value="overage" (click)="changeTable('overage')">
            Extra Items In Warehouse ({{lengths?.overages}})
          </mat-button-toggle>
          <mat-button-toggle class="shortage" value="shortage" (click)="changeTable('shortage')">
            Missing From Warehouse ({{lengths?.shortages}})
          </mat-button-toggle>
        </mat-button-toggle-group>
   
        <mat-form-field >
          <input matInput [(ngModel)]="filters.kitSearchString" (keyup)="applyFilter()" placeholder="Search By Reference | Kit ">
        </mat-form-field>
      
      </div>
    </div>
  </div>
  <app-warehouse-counted (onExportToExcel)="exportToExcel($event)" *ngIf="showCounted && counted" [filters]="filters" [counted]="counted" #countedTable></app-warehouse-counted>
  <app-warehouse-overages (onExportToExcel)="exportToExcel($event)" *ngIf="showOverages && overages" [filters]="filters" [overages]="overages" #overagesTable></app-warehouse-overages>
  <app-warehouse-shortages (onExportToExcel)="exportToExcel($event)" *ngIf="showShortages && shortages" [filters]="filters" [shortages]="shortages" #shortagesTable></app-warehouse-shortages>
</div>
