export class PositiveIntegerHelper {

  isNumeric(e: KeyboardEvent) {
    const key = e.which ? e.which : e.keyCode;
    if (!e.shiftKey && !e.ctrlKey && !e.altKey) {
      if ([48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105].includes(key) || [8, 13, 9, 17, 18, 37, 39].indexOf(key) !== -1) {
        return true;
      }
    }
    return false;
  }
}
