import { ICountSheetData } from './../interfaces/icount-sheet-data';
import { collectionNames } from './collection-names';
import { CountSheetStatusHelper } from './count-sheet-status-helper';
import { IcountsheetItemData } from '../interfaces/icountsheet-item';
import { CountSheetItemHelper } from './countsheet-item-helper';
import { ITableFilter } from '../interfaces/itable-filter';
import { IColumnOptions } from '../interfaces/i-column-options';
import { RecallOracleService } from '../services/recall-oracle.service';


export class CountSheetHelper {

  countSheetItemHelper: CountSheetItemHelper = new CountSheetItemHelper();

  constructor() {
  }

  getCountSheetStatusText(value: number): string {
    return CountSheetStatusHelper['NAMES'][value];
  }

  matchingRootKitDefinitionItem(itemId: number, kitDefinitionItems: any) {
    return kitDefinitionItems.find(function (kdi) {
      return kdi.item_id === itemId;
    });
  }

  getDataSourceData(countSheet: ICountSheetData, csis: IcountsheetItemData[], lotPoolManagedItems, redspotQuantities: any, sapQuantities: any, itemSettings: any, itemCombinations: any, recallOracleService: RecallOracleService, kitDefinitionItems: any, exclusions: any, filters: ITableFilter) {
    const now = new Date();
    const that = this;
    const countSheetItems = csis.slice().filter(csi => !csi._destroy);

    if (kitDefinitionItems) {
      kitDefinitionItems.forEach(rkdi => {
        if (!countSheetItems.find(d => d.data.item_id === rkdi.item_id)) {
          const row = {
            data: {
              item_id: rkdi.item.id,
              reference: rkdi.item.reference,
              description: rkdi.item.description,
              lot_number: '',
              serial: '',
              quantity: null,
              expiration_date: null,
              redspot_expiration_date: null,
              count_sheet_id: countSheet.dbId,
              count_sheet_client_id: countSheet.id,
              is_consigned: rkdi.item.is_consigned,
              isKitted: false,
              min_order_quantity: rkdi.item.minimum_order_quantity,
              is_lot_tracked: rkdi.item.is_lot_number_tracked,
              is_serial_tracked: rkdi.item.is_serial_tracked,
              checked: false
            },
            id: '',
            dbId: 0,
            isSynchronized: 1
          };
          countSheetItems.push(row);
        }
      });
    }


     //final processing
    countSheetItems.forEach(function (kics) {
      const isLotPooled = lotPoolManagedItems.find(lpmi => lpmi.item_id === kics.data.item_id);
      if (kics.data.show_recalled_warning === undefined) {
        kics.data.show_recalled_warning = recallOracleService.get(kics.data.item_id, kics.data.lot_number, kics.data.serial);
      }
      if (kics.data.show_invalid_warning === undefined) {
        kics.data.show_invalid_warning = !that.countSheetItemHelper.checkIfValidItemCombination(kics.data.item_id, kics.data.lot_number, kics.data.serial, isLotPooled, kics.data.is_lot_tracked, kics.data.is_serial_tracked, itemSettings, itemCombinations);
      }

      kics.data.is_excluded = !!exclusions.find(e => e.item_id === kics.data.item_id);
      kics.data.in_definition = !!that.matchingRootKitDefinitionItem(kics.data.item_id, kitDefinitionItems);
      const countSheetItem = countSheetItems.find(function (csi) {
        return kics.data.item_id === csi.data.item_id && (kics.data.lot_number || '') === (csi.data.lot_number);
      });
      const redspotQuantity = redspotQuantities.find(function (eq) {
        return kics.data.item_id === eq['item_id'] && (kics.data.lot_number || '') === (eq['lot_number'].value || '');
      });
      const sapQuantity = sapQuantities.find(function (sq) {
        return kics.data.item_id === sq['item_id'] && (kics.data.lot_number || '') === (sq['lot_number'] || '') && (kics.data.serial || '') === (sq['serial_number'] || '');
      });
      if (countSheetItem) {
        if (countSheetItem.data.expiration_date) {
          kics.data.expiration_date = countSheetItem.data.expiration_date;
          kics.data.show_expired_warning = new Date(countSheetItem.data.expiration_date) < now;
        } else if (sapQuantity && sapQuantity.expiry_date) {
          kics.data.expiration_date = sapQuantity.expiry_date;
          kics.data.show_expired_warning = new Date(sapQuantity.expiry_date) < now;
        } else if (redspotQuantity && redspotQuantity.expiry_date) {
          kics.data.expiration_date = redspotQuantity.expiry_date;
          kics.data.show_expired_warning = new Date(redspotQuantity.expiry_date) < now;
        }
      }
    });

    const sortFunction = function (a: IcountsheetItemData, b: IcountsheetItemData) {
      const aMatch = that.matchingRootKitDefinitionItem(a.data.item_id, kitDefinitionItems);
      const bMatch = that.matchingRootKitDefinitionItem(b.data.item_id, kitDefinitionItems);

      if (aMatch && bMatch) {
        if (aMatch['position'] > bMatch['position']) {
          return 1;
        } else if (aMatch['position'] < bMatch['position']) {
          return -1;
        } else {
          return 0;
        }
      } else if (aMatch) {
        return -1;
      } else if (bMatch) {
        return 1;
      } else if (a.data.reference.toLowerCase() > b.data.reference.toLowerCase()) {
        return 1;
      } else if (a.data.reference.toLowerCase() < b.data.reference.toLowerCase()) {
        return -1;
      } else if (a.data.reference.toLowerCase() === b.data.reference.toLowerCase()) {
        if (a.data.expected_quantity < b.data.expected_quantity) {
          return 1;
        } else if (a.data.expected_quantity > b.data.expected_quantity) {
          return -1;
        } else {
          if (a.data.rank > b.data.rank) {
            return 1;
          } else if (a.data.rank < b.data.rank) {
            return -1;
          }
        }

      } else {
        return 0;
      }
    };

    return countSheetItems.filter(csi => !csi.data.hide).sort(sortFunction);
  }

  getColumnOptions(kitted: boolean): IColumnOptions[] {
    const unkittedExcludedColumns = ['actions', 'buttons'];
    const options = localStorage.getItem(collectionNames.columnOptions);
    if (!options) {
      const columns: IColumnOptions[] = this.createInitialColumnOptions();
      this.updateColumnOptions(columns, kitted);
      return kitted ? columns : columns.filter(c => !unkittedExcludedColumns.includes(c.code));
    } else {
      const opts = JSON.parse(options);
      if (kitted) {
        if (opts.length < 4) {
          const newKittedOptions = this.createInitialColumnOptions();
          opts.forEach(c => {
            const optionIndex = newKittedOptions.findIndex(o => o.code === c.code);
            newKittedOptions[optionIndex] = c;
          });
          localStorage.setItem(collectionNames.columnOptions, JSON.stringify(newKittedOptions));
          return newKittedOptions;
        } else {
          return opts;
        }
      } else {
        return opts.filter(c => !unkittedExcludedColumns.includes(c.code));
      }
    }
  }

  updateColumnOptions(columns: IColumnOptions[], kitted: boolean) {
    if (kitted) {

      const columnOptions = JSON.parse(localStorage.getItem(collectionNames.columnOptions));
      if (columnOptions) {
        columns.forEach(c => {
          const index = columnOptions.findIndex(co => co.code === c.code);
          columnOptions[index].selected = c.selected;
        });

        localStorage.setItem(collectionNames.columnOptions, JSON.stringify(columnOptions));
      } else {
        localStorage.setItem(collectionNames.columnOptions, JSON.stringify(columns));

      }

    } else {
      const options: IColumnOptions[] = this.getColumnOptions(true);
      columns.forEach(c => {
        const optionIndex = options.findIndex(o => o.code === c.code);
        options[optionIndex] = c;
      });
      localStorage.setItem(collectionNames.columnOptions, JSON.stringify(options));
    }
  }

  createInitialColumnOptions() {
    const columns: IColumnOptions[] = [
      {title: 'Description', code: 'description', show: false, index: 2, selected: false, left: 'reference'},
      {title: 'Serial Number', code: 'serial_number', show: false, index: 4, selected: false, left: 'lot_number'},
      {title: 'Actions', code: 'actions', show: true, index: 6, selected: true, left: 'actual_quantity'},
      {title: 'Buttons', code: 'buttons', show: true, index: 8, selected: true, left: 'checked'}
    ];
    return columns;
  }

}
