import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { urls } from '../helpers/urls';
import { CountSheetItemDatabaseService } from '../services/count-sheet-item-database.service';
import { CountSheetDatabaseService } from '../services/count-sheet-database.service';
import { zip } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./count-sheet-item-database.service";
import * as i3 from "./count-sheet-database.service";
var CountSheetItemService = /** @class */ (function () {
    function CountSheetItemService(http, _countSheetItemDatabaseService, _countSheetDatabaseService) {
        this.http = http;
        this._countSheetItemDatabaseService = _countSheetItemDatabaseService;
        this._countSheetDatabaseService = _countSheetDatabaseService;
    }
    CountSheetItemService.prototype.ClearSynchronized = function (auditId) {
        var that = this;
        var promise = new Promise(function (resolve, reject) {
            that._countSheetItemDatabaseService.find(auditId, { isSynchronized: true }).then(function (matches) {
                zip.apply(void 0, matches.map(function (m) { return that._countSheetItemDatabaseService.delete(m.id); }).concat(new Promise(function (r, a) { r(null); }))).subscribe(function () {
                    resolve(null);
                });
            });
        });
        return promise;
    };
    //find synced ones for the count sheet and replace em, if there is a matching unsynced one they win
    CountSheetItemService.prototype.refreshOfflineDataForCountSheet = function (auditId, countSheetClientId, data) {
        var that = this;
        var countSheetItems = data.map(function (csi) {
            return that._countSheetItemDatabaseService.new(csi);
        });
        var promise = new Promise(function (resolve, reject) {
            that._countSheetItemDatabaseService.find(auditId, { isSynchronized: true, count_sheet_client_id: countSheetClientId }).then(function (matches) {
                zip.apply(void 0, matches.map(function (csi) { return that._countSheetItemDatabaseService.delete(csi.id); }).concat(new Promise(function (r, a) { r(null); }))).subscribe(function (_) {
                    that._countSheetItemDatabaseService.find(auditId, { isSynchronized: false, count_sheet_client_id: countSheetClientId }).then(function (matches) {
                        var unsyncedCountSheetItemClientIds = matches.map(function (csi) { return csi.id; });
                        var freshCountSheetItems = countSheetItems.filter(function (csi) { return unsyncedCountSheetItemClientIds.indexOf(csi.id) === -1; });
                        zip.apply(void 0, freshCountSheetItems.map(function (csi) { return that._countSheetItemDatabaseService.add(csi); }).concat(new Promise(function (r, a) { r(null); }))).subscribe(function (_) {
                            that._countSheetItemDatabaseService.find(auditId, { count_sheet_client_id: countSheetClientId, _destroy: false }).then(function (matches) {
                                resolve(matches);
                            });
                        });
                    });
                });
            });
        });
        return promise;
    };
    CountSheetItemService.prototype.getCountSheetItemsAndClearOfflineData = function (auditId) {
        var that = this;
        var promise = new Promise(function (resolve, reject) {
            that.getCountSheetItems(auditId).subscribe(function (data) {
                that._countSheetItemDatabaseService.deleteByIndex('data.audit_id', parseInt(auditId, 10)).then(function () {
                    resolve(null);
                });
            });
        });
        return promise;
    };
    CountSheetItemService.prototype.addOrUpdateCountSheetItemByCriteria = function (csi, options) {
        options = options || {};
        var that = this;
        return new Promise(function (resolve, reject) {
            that._countSheetItemDatabaseService.find(csi.data.audit_id, { count_sheet_client_id: csi.data.count_sheet_client_id, lot_number: csi.data.lot_number, serial: csi.data.serial, reference: csi.data.reference, _destroy: false }).then(function (matches) {
                if (matches.length > 0) {
                    var updatingCountSheetItem = matches[0];
                    if (options.add_to_quantity) {
                        updatingCountSheetItem.data.quantity += csi.data.quantity;
                    }
                    else {
                        updatingCountSheetItem.data.quantity = csi.data.quantity;
                    }
                    updatingCountSheetItem.isSynchronized = 0;
                    that._countSheetItemDatabaseService.update(updatingCountSheetItem).then(function (_) {
                        resolve(null);
                    });
                }
                else {
                    csi.isSynchronized = 0;
                    that._countSheetItemDatabaseService.add(csi).then(function (_) {
                        resolve(null);
                    });
                }
            });
        });
    };
    CountSheetItemService.prototype.deleteCountSheetItemsOffline = function (auditId, countSheetClientId) {
        var that = this;
        return new Promise(function (resolve, reject) {
            that._countSheetItemDatabaseService.find(auditId, { count_sheet_client_id: countSheetClientId }).then(function (matches) {
                var deletableCountSheetItems = matches.filter(function (csi) { return !csi.dbId; });
                var updatingCountSheetItems = matches.filter(function (csi) { return csi.dbId; });
                updatingCountSheetItems.forEach(function (csi) {
                    csi.isSynchronized = 0;
                    csi._destroy = true;
                });
                var promises = [new Promise(function (r, a) { r(null); })];
                deletableCountSheetItems.forEach(function (csi) {
                    promises.push(that._countSheetItemDatabaseService.delete(csi.id));
                });
                promises.push(that._countSheetItemDatabaseService.bulkUpdate(updatingCountSheetItems));
                zip.apply(void 0, promises).subscribe(function (_) {
                    that._countSheetDatabaseService.get(countSheetClientId).then(function (cs) {
                        cs.isSynchronized = 0;
                        cs.data.total_items_counted = 0;
                        that._countSheetDatabaseService.update(cs).then(function (_) {
                            resolve(null);
                        });
                    });
                });
            });
        });
    };
    CountSheetItemService.prototype.update = function (countSheetItem) {
        if (countSheetItem.id) {
            return this._countSheetItemDatabaseService.update(countSheetItem);
        }
        return this._countSheetItemDatabaseService.add(countSheetItem);
    };
    CountSheetItemService.prototype.getCountSheetItems = function (auditId, options) {
        options = options || {};
        var params = new HttpParams().set('audit_id', auditId);
        if (options.count_sheet_id) {
            params = params.set('count_sheet_id', options.count_sheet_id);
        }
        if (options.count_sheet_client_id) {
            params = params.set('count_sheet_client_id', options.count_sheet_client_id);
        }
        if (options.only_kitted) {
            params = params.set('only_kitted', 'true');
        }
        if (options.only_not_kitted) {
            params = params.set('only_not_kitted', 'true');
        }
        return this.http.get("" + environment.serverPath + urls.countSheetItem, { params: params });
    };
    CountSheetItemService.prototype.deleteCountSheetItem = function (countSheetId, countSheetItemId) {
        var params = new HttpParams()
            .set('count_sheet_item[id]', countSheetItemId.toString())
            .set('count_sheet_item[count_sheet_id]', countSheetId.toString());
        return this.http.delete("" + environment.serverPath + urls.countSheetItem, { params: params });
    };
    CountSheetItemService.prototype.updateSingleCountSheetItem = function (data, options) {
        return this.http.post(environment.serverPath + urls.countSheetItem, {
            count_sheet_item: {
                count_sheet_item_id: data.dbId,
                count_sheet_id: data.data.count_sheet_id,
                item_id: data.data.item_id,
                reference: data.data.reference,
                lot_number: data.data.lot_number,
                quantity: data.data.quantity,
                expiration_date: data.data.expiration_date,
                serial_number: data.data.serial,
                client_id: data.id,
                checked: data.data.checked,
                manually_entered: data.data.manually_entered,
                hide: data.data.hide
            },
            add_to_quantity: options && options['add_to_quantity'],
            updating_quantity: options && options['updating_quantity']
        });
    };
    CountSheetItemService.prototype.updateCountSheetItemQuantity = function (dbId, newQuantity) {
        var params = new HttpParams()
            .set('count_sheet_item[quantity]', newQuantity)
            .set('count_sheet_item[checked]', 'true');
        var path = "" + environment.serverPath + urls.countSheetItem + "/" + dbId + "?" + params.toString();
        return this.http.put(path, {});
    };
    CountSheetItemService.prototype.uncheckCountSheetItem = function (dbId) {
        var params = new HttpParams()
            .set('count_sheet_item[quantity]', '0')
            .set('count_sheet_item[checked]', 'false');
        return this.http.put("" + environment.serverPath + urls.countSheetItem + "/" + dbId + "?" + params.toString(), {});
    };
    CountSheetItemService.prototype.sendEmailToSupport = function (data) {
        return this.http.post(environment.serverPath + urls.countSheetItemError, {
            count_sheet_item: data
        });
    };
    CountSheetItemService.ngInjectableDef = i0.defineInjectable({ factory: function CountSheetItemService_Factory() { return new CountSheetItemService(i0.inject(i1.HttpClient), i0.inject(i2.CountSheetItemDatabaseService), i0.inject(i3.CountSheetDatabaseService)); }, token: CountSheetItemService, providedIn: "root" });
    return CountSheetItemService;
}());
export { CountSheetItemService };
