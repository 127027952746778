import { Component, OnInit, Input } from '@angular/core';
import { AuditDashboardHelper } from 'src/app/helpers/audit-dashboard-helper';
import { ICountSheetData } from 'src/app/interfaces/icount-sheet-data';
import { PrintHelper } from '../../helpers/print-helper';
import { DatabaseService } from 'src/app/services/database.service';
import { UserDataHelper } from '../../helpers/user-data-helper';
import { AuditDatabaseService } from 'src/app/services/audit-database.service';
import { ActivatedRoute } from '@angular/router';


declare let jsPDF: any;

@Component({
  selector: 'app-print-page',
  templateUrl: './print-page.component.html',
  styleUrls: ['./print-page.component.css']
})
export class PrintPageComponent implements OnInit {
  color = 'white';
  @Input() dataSource;
  @Input() countSheet: ICountSheetData;
  @Input() audit: any;
  @Input() countSheetItems: any;
  @Input() lotPoolManagedItems: any[];
  auditDashBoardHelper: AuditDashboardHelper = new AuditDashboardHelper();
  userDataHelper: UserDataHelper = new UserDataHelper();
  printHelper: PrintHelper = new PrintHelper();
  columns: object[];
  rows: object[] = [];
  descriptionColumnSize = 30;
  actualItemCount;
  auditedBy: any;
  doc = new jsPDF('l', 'mm', 'letter');
  filename: string;
  totalPagesExp = '{total_pages_count_string}';
  date = this.printHelper.getTodaysDate();

  constructor(
    private _databaseService: DatabaseService,
    private _auditDatabaseService: AuditDatabaseService,
    private _route: ActivatedRoute
  ) { }

  ngOnInit() {
    const that = this;
    that._auditDatabaseService.get(+that._route.snapshot.paramMap.get('audit_id')).then(data => {
      that.audit = data;
    })
  }

  print() {

    this.doc = new jsPDF('l', 'mm', 'letter');
    this.rows = [];
    this.doc.page = 1;
    this.actualItemCount = 0;

    if (this.countSheet.data.audit_kit_instance_id) { // kitted count sheet
      this._databaseService.add('user_actions', {
        audit_id: this.countSheet.data.audit_id.toString(),
        auditor: `${this.userDataHelper.getUserData().first_name} ${this.userDataHelper.getUserData().last_name}`,
        timestamp: `${(new Date()).toUTCString()}`,
        area: '',
        kit_reference: `${this.countSheet.data.reference}`,
        kit_lot_number: `${this.countSheet.data.lot_number}`,
        action: 'Printed count sheet',
        total_items_counted: this.countSheet.data.total_items_counted,
        site_id: this.countSheet.data.audit_location_id,
        warehouse_id: this.countSheet.data.warehouse_id
      });
      this.dataSource.refresh();
      this.countSheetItems = this.dataSource.data;
      this.auditedBy = this.printHelper.getAuditedByValue(this.countSheetItems);
      this.filename = `${this.countSheet.data.location_name}-${this.countSheet.data.reference}-${this.countSheet.data.lot_number}.pdf`;

      //countsheet pdf needs to include expected items and items that are apart of the kit and not yet counted
      if (this.countSheet.data.status_text === 'Planned' || this.countSheet.data.status_text === 'In-Progress') {
        this.buildRows(true);
      } else {
        this.buildRows(false, true);
      }
      // this is how many rows currently fit on the first page. this number will need to be updated if the font/cell width/table margins are changed
      if (this.rows.length <= 33) {
        this.buildOneTable(this.doc);
      } else {
        this.buildTwoTables(this.doc);
      }

    } else { // unkitted count sheet
      this._databaseService.add('user_actions', {
        audit_id: this.countSheet.data.audit_id.toString(),
        auditor: `${this.userDataHelper.getUserData().first_name} ${this.userDataHelper.getUserData().last_name}`,
        timestamp: `${(new Date()).toUTCString()}`,
        area: `${this.countSheet.data.area}`,
        kit_reference: '',
        kit_lot_number: '',
        action: 'Printed count sheet',
        total_items_counted: this.countSheet.data.total_items_counted,
        site_id: this.countSheet.data.audit_location_id,
        warehouse_id: this.countSheet.data.warehouse_id
      });
      this.auditedBy = this.printHelper.getAuditedByValue(this.countSheetItems);
      this.filename = `${this.countSheet.data.location_name}-${this.countSheet.data.area}.pdf`;

      if (this.countSheet.data.status_text === 'Planned' || this.countSheet.data.status_text === 'In-Progress') {
        this.buildRows(false);
      } else {
        this.buildRows(false, true);
      }

      if (this.rows.length <= 37) {
        this.buildOneTable(this.doc);
      } else {
        this.buildTwoTables(this.doc);
      }

    }

    if (typeof this.doc.putTotalPages === 'function') {
      this.doc.putTotalPages(this.totalPagesExp);
    }

    this.doc.save(this.filename);
  }

  setAuditedByValue(countSheetItems) {
    if (countSheetItems) {
      const names = countSheetItems.map(csi => csi.data.modified_by).filter((v, i, a) => a.indexOf(v) === i);
      this.auditedBy = names.join(' / ');
    } else {
      this.auditedBy = '';
    }
  }

  getTodaysDate() {
    const date = new Date();
    let dd = date.getDate();
    let mm = date.getMonth() + 1; //January is 0!
    const yyyy = date.getFullYear();

    if (dd < 10) {
      dd = 0 + dd;
    }

    if (mm < 10) {
      mm = 0 + mm;
    }

    const today = mm + '/' + dd + '/' + yyyy;
    return today;
  }

  shrinkDescriptionColumn(text: string, size: number): string {
    if (text.length > size) {
      text = text.substr(0, size) + '...';
    }
    return text;
  }

  checkForWarnings(item): string[] {
    const warnings = [];
    if (item.data.show_expired_warning) {
      warnings.push('E');
    }
    if (item.data.show_invalid_warning) {
      warnings.push('I');
    }
    if (item.data.show_recalled_warning) {
      warnings.push('R');
    }
    if (item.data.is_excluded) {
      warnings.push('X');
    }
    if (item.data.manually_entered) {
      warnings.push('M');
    }
    if (this.lotPoolManagedItems.find(lpmi => lpmi.item_id === item.data.item_id)) {
      warnings.push('L');
    }
    return warnings;
  }

  splitRows(rows) {
    return rows.splice(0, Math.ceil(rows.length / 2));
  }

  getColumns() {
    this.columns = [{ verify: 'Vfy', reference: 'Reference', lot: 'Lot', qty: 'Qty' }];

    if (this.countSheet.data.status_text === 'Planned' || this.countSheet.data.status_text === 'In-Progress' && this.countSheet.data.isKitted) {
      this.columns = this.columns.map(obj => ({ ...obj, expQty: 'Exp Qty' }));
    }

    this.columns = this.columns.map(obj => ({ ...obj, description: 'Description', info: 'Info' }));
    return this.columns;
  }

  buildRows(showExpectedQty, isCountedOrVerified = false) {
    const that = this;
    let newRow;
    if (showExpectedQty) {
      this.countSheetItems.forEach(function (value) {
        let warnings = [];
        const quantity = that.formatQuantity(value);
        that.actualItemCount += quantity;
        warnings = that.checkForWarnings(value);
        newRow = {
          verify: '',
          qty: quantity,
          expQty: value.data.expected_quantity,
          reference: value.data.reference,
          description: value.data.description,
          lot: value.data.lot_number != null ? value.data.lot_number : '',
          info: warnings.join(' ')
        };

        that.rows.push(newRow);
      });
    } else {
      this.countSheetItems.forEach(function (value) {
        let warnings = [];
        if (that.countSheet.data.show_expected_item_list) {
          const quantity = that.formatQuantity(value);
          if ((isCountedOrVerified && quantity > 0) || !isCountedOrVerified) {
            that.actualItemCount += quantity;
            warnings = that.checkForWarnings(value);
            newRow = {
              verify: '',
              qty: quantity,
              reference: value.data.reference,
              description: value.data.description,
              lot: value.data.lot_number != null ? value.data.lot_number : '',
              info: warnings.join(' ')
            };
            that.rows.push(newRow);
          }
        } else if ((value.data.quantity >= 0 && !isCountedOrVerified) || (isCountedOrVerified && value.data.quantity > 0)) {
          that.actualItemCount += (value.data.quantity === 0) && (value.data.checked) ? 0 : (value.data.checked) ? value.data.quantity : 0;
          warnings = that.checkForWarnings(value);
          newRow = {
            verify: '',
            qty: (value.data.quantity === 0) && (value.data.checked) ? 0 : (value.data.checked) ? value.data.quantity : '',
            reference: value.data.reference,
            description: value.data.description,
            lot: value.data.lot_number != null ? value.data.lot_number : '',
            info: warnings.join(' ')
          };
          that.rows.push(newRow);
        }
      });
    }
  }

  getColumnStyles() {
    const style = {
      info: { cellWidth: 10, fontSize: 6, fontStyle: 'bold', valign: 'middle', halign: 'center', cellPadding: { left: 0.5, top: 0.5, right: 0, bottom: 0.5 } },
      reference: { cellWidth: 10, fontSize: 6, fontStyle: 'bold', valign: 'middle', halign: 'left', cellPadding: { left: 0.5, top: 0.5, right: 0, bottom: 0.5 } },
      description: { cellWidth: 35, fontSize: 6, fontStyle: 'bold', valign: 'middle', halign: 'left', cellPadding: { left: 0.5, top: 0.5, right: 0.25, bottom: 0.5 } },
      lot: { cellWidth: 20, fontSize: 6, fontStyle: 'bold', valign: 'middle', halign: 'left', cellPadding: { left: 0.5, top: 0.5, right: 0.2, bottom: 0.5 } },
      qty: { cellWidth: 5, fontSize: 6, fontStyle: 'bold', valign: 'middle', halign: 'center', cellPadding: { left: 0.5, top: 0.5, right: 0, bottom: 0.5 } },
      verify: { cellWidth: 5, fontSize: 6, fontStyle: 'bold', valign: 'middle', halign: 'center', cellPadding: { left: 0.5, top: 0.5, right: 0, bottom: 0.5 } },
      expQty: { cellWidth: 3, fontSize: 6, fontStyle: 'bold', valign: 'middle', halign: 'center', cellPadding: { left: 0.5, top: 0.5, right: 0, bottom: 0.5 } }
    };

    return style;
  }

  buildOneTable(doc) {
    doc.autoTable({
      head: this.getColumns(),
      body: this.rows,
      didDrawPage: (() => this.headerAndFooter()),

      startY: 60,
      theme: 'grid',
      headStyles: {
        fillColor: [97, 97, 97]
      },
      columnStyles: this.getColumnStyles(),
      styles: {
        overflow: 'ellipsize',
        lineColor: 0,
        lineWidth: .25
      },
      margin: { top: 20, right: 14, left: 14 }
    });
  }

  buildTwoTables(doc) {
    let pageNumber = doc.internal.getNumberOfPages();
    let rowsToPopulate = this.rows;


    while (rowsToPopulate.length > 0) {
      // the number of rows per page depends on which page of the document the table is on.the first is how
      // many rows currently fit on the first page. this number will need to be updated if the font/cell width/table
      //margins are changed. the second is for pages 2+.
      const rowsForPage = pageNumber !== 1 ? rowsToPopulate.slice(0, 86) : rowsToPopulate.slice(0, 66);
      rowsToPopulate = rowsToPopulate.filter((el) => !rowsForPage.includes(el));
      const firstSetOfRows = this.splitRows(rowsForPage);
      const secondSetOfRows = rowsForPage.filter((el) => !firstSetOfRows.includes(el));

      doc.autoTable({
        head: this.getColumns(),
        body: firstSetOfRows,
        didDrawPage: (() => this.headerAndFooter()),
        startY: pageNumber !== 1 ? doc.autoTable.previous.finalY + 100 : 60,
        theme: 'grid',
        headStyles: {
          fillColor: [97, 97, 97]
        },
        columnStyles: this.getColumnStyles(),
        styles: {
          overflow: 'ellipsize',
          lineColor: 0,
          lineWidth: .25
        },
        margin: { top: 20, right: 142 }
      });
      doc.setPage(pageNumber);
      doc.autoTable({
        head: this.getColumns(),
        body: secondSetOfRows,
        startY: pageNumber !== 1 ? 20 : 60,
        theme: 'grid',
        headStyles: {
          fillColor: [97, 97, 97]
        },
        columnStyles: this.getColumnStyles(),
        styles: {
          overflow: 'ellipsize',
          lineColor: 0,
          lineWidth: .25
        },
        margin: { top: 20, left: 142 }
      });

      pageNumber++;

    }

  }

  headerAndFooter() {
    const doc = this.doc;
    const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    //header
    const agencyName: string = this.audit.agency_name;
    const siteName = 'Site:  ' + this.countSheet.data.location_name;
    const warehouse = 'Warehouse:  ' + this.countSheet.data.warehouse_name;
    const creator = 'Auditor(s):  ' + this.auditedBy;
    const splitString = doc.splitTextToSize(creator, 180);

    const itemCount = 'Total Items Counted: ' + this.actualItemCount;

    if (doc.page === 1) {
      doc.setFontSize(18);
      doc.text(agencyName, 14, 22);
      doc.setFontSize(11);
      doc.text(siteName, 14, 40);
      doc.text(warehouse, 180, 40);
      doc.text(splitString, 180, 56);
      doc.text(itemCount, 180, 48);
      if (this.countSheet.data.isKitted) {
        const kitReference = 'Kit Reference #:  ' + this.countSheet.data.reference;
        const kitLotNumber = 'Kit Lot #:  ' + this.countSheet.data.lot_number;
        doc.text(kitReference, 14, 56);
        doc.text(kitLotNumber, 14, 48);
      } else {
        const area = 'Area:  ' + this.countSheet.data.area;
        doc.text(area, 14, 48);
      }
    } else {
      doc.setFontSize(10);
      doc.text('Agency: ' + agencyName, 180, 10);
      doc.text(siteName, 14, 5);
      doc.text(warehouse, 180, 5);
      doc.text(splitString, 180, 15);
      if (this.countSheet.data.isKitted) {
        const kitReference = 'Kit Reference #:  ' + this.countSheet.data.reference;
        const kitLotNumber = 'Kit Lot #:  ' + this.countSheet.data.lot_number;
        doc.text(kitReference, 14, 15);
        doc.text(kitLotNumber, 14, 10);
      } else {
        const area = 'Area:  ' + this.countSheet.data.area;
        doc.text(area, 14, 15);
      }
    }

    //footer
    doc.setFontSize(10);
    doc.text(this.date, 10, pageHeight - 5);

    let footerStr = 'page ' + doc.page;
    if (typeof doc.putTotalPages === 'function') {
      footerStr = footerStr + ' of ' + this.totalPagesExp;
    }
    const legend = 'E - Expired, R - Recalled, I - Invalid Lot/Serial, X - Excluded, M - Manually Entered, L - Lot Pool Managed';
    doc.text(legend, 60, pageHeight - 5);
    doc.text(pageWidth - 30, pageHeight - 5, footerStr);
    doc.page++;
  }

  formatQuantity(item): number {
    let formattedQty = 0;
    if (item.count_sheet_items) {
      const checkedItems = item.count_sheet_items.filter(csi => csi.data.checked).length > 0;
      formattedQty = (item.data.quantity === 0) && (checkedItems) ? 0 : (checkedItems) ? item.data.quantity : 0;
    } else {
      formattedQty = (item.data.quantity === 0) && (item.data.checked) ? 0 : (item.data.checked) ? item.data.quantity : 0;
    }

    return formattedQty;
  }
}
