<div class="dashboard-card">
  <div class="container">
    <div>
      <h5 class="header-title">
        Audit Output
      </h5>
      <div class="buttons" [ngClass]="{'disabled': !isOnline }">
        <button mat-button (click)="openSAPReconciliationProcessFileDialog()" ngbTooltip="Pull Report" placement="top">
          <i class="material-icons icon">cloud_download</i>
          <div class="text">Consolidated Inventory File</div>
        </button>
        <div class="clear_both"></div>
        <button mat-button (click)="openRedspotUpdateProcessFileDialog()" ngbTooltip="Pull Report" placement="top">
          <i class="material-icons icon">cloud_download</i>
          <div class="text">Redspot Update Process Files</div>
        </button>
        <div class="clear_both"></div>
        <button mat-button (click)="openSAPCompareFileDialog()" ngbTooltip="Pull Report" placement="top">
          <i class="material-icons icon">cloud_download</i>
          <div class="text">Inventory Variances File</div>
        </button>
        <div class="clear_both"></div>
        <button mat-button (click)="openAuditTrailDialog()" ngbTooltip="Pull Report" placement="top">
          <i class="material-icons icon">cloud_download</i>
          <div class="text">Audit Trail File</div>
        </button>
      </div>
    </div>
  </div>
</div>
