export const CountSheetStatusHelper = {
  "PLANNED": 1,
  "INPROGRESS": 2,
  "COUNTED": 3,
  "VERIFIED": 4,
  "CANCELLED": 5,
  "NAMES": {
    1: "Planned",
    2: "In-Progress",
    3: "Needs Verification",
    4: "Verified",
    5:  "Cancelled"
  }
}
