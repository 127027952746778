import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { IAuditDetail } from '../../interfaces/iaudit-detail';
import { AuditService } from "../../services/audit.service"
import { ISnackBarConfig } from '../../interfaces/isnack-bar-config';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '../../../../node_modules/@angular/material';
import * as moment from 'moment';
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';

@Component({
  selector: 'app-create-new-audit',
  templateUrl: './create-new-audit.component.html',
  styleUrls: ['./create-new-audit.component.css']
})
export class CreateNewAuditComponent implements OnInit {
  permissions: string[];
  saved = new EventEmitter<any>();
  show: boolean = false;
  title: string;
  creating: boolean = false;
  saving: boolean = false;
  newAudit: IAuditDetail = {
    location_name: "",
    warehouse_id: null,
    status: null,
    start_date: null,
    end_date: null,
    id: null
  };
  snackBarConfig: ISnackBarConfig = {
    message: "",
    duration: 3000,
    success: false,
    snackBarClass: ""
  }

  statuses = ['Planned', 'Cancelled', 'Completed', 'In-Progress'];

  distributors: Object[] = [];

  constructor(
    public dialogRef: MatDialogRef<CreateNewAuditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private auditService: AuditService,
    public snackBar: MatSnackBar
  ) {
    this.title = this.data.pageTitle;
    this.creating = this.data.creating;
    this.newAudit.location_name = data.location_name;
    this.newAudit.warehouse_id = data.warehouse_id;
    this.newAudit.status = data.status;
    this.newAudit.id = data.id;
    if (data.start_date) {
      this.newAudit.start_date = moment(data.start_date).toDate();
    }
    else {
      this.newAudit.start_date = null;
    }
    if (data.end_date) {
      this.newAudit.end_date = moment(data.end_date).toDate();
    }
    else {
      this.newAudit.end_date = null;
    }
  }

  saveClicked(form) {
    if (form.valid) {
      this.saving = true;
      if (this.newAudit.id) {
        this.auditService.updateAudit(this.newAudit).subscribe(() => {
          this.saved.emit();
          this.saving = false;
          this.onNoClick();
          this.snackBarConfig.message = "Audit Updated Successfully";
          this.snackBarConfig.success = true;
          this.openSnackBar();
        }, res => {
          this.saving = false;
          this.snackBarConfig.message = res.error;
          this.snackBarConfig.success = false;
          this.openSnackBar();
        });
      }
      else {
        this.auditService.createAudit(this.newAudit).subscribe(() => {
          this.saved.emit();
          this.saving = false;
          this.onNoClick();
          this.snackBarConfig.message = "Audit Created Successfully";
          this.snackBarConfig.success = true;
          this.openSnackBar();
        }, res => {
          this.saving = false;
          this.snackBarConfig.message = res.error;
          this.snackBarConfig.success = false;
          this.openSnackBar();
        });
      }
    }
    else {
      this.saving = false;
      this.snackBarConfig.message = "Audit Validation Failed";
      this.snackBarConfig.success = false;
      this.openSnackBar();
    }
  }

  archiveAudit() {
    this.auditService.archiveAudit(this.newAudit).subscribe(() => {
      this.saved.emit();
      this.onNoClick();
      this.snackBarConfig.message = "Audit Archived";
      this.snackBarConfig.success = true;
      this.openSnackBar();
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this.permissions = JSON.parse(localStorage.getItem("permissions"))
    this.auditService.getDistributors().subscribe(result => {
      this.distributors = result.distributors;
    });
  }

  openSnackBar() {
    this.snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: this.snackBarConfig,
      duration: this.snackBarConfig.duration
    })
  }

}
