import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CONSTANTS } from 'src/app/helpers/constants';
import { AccessTokenService } from 'src/app/services/access-token.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-okta-logout',
  templateUrl: './okta-logout.component.html',
  styleUrls: ['./okta-logout.component.css']
})
export class OktaLogoutComponent implements OnInit {

  constructor(private _router: Router, private _authService: AuthService, private _accessTokenService: AccessTokenService) { }

  ngOnInit() {

    let tokenData = this._accessTokenService.getTokenData()
    this._authService.invalidateAccessToken(tokenData).subscribe((_data)=>{
      this._accessTokenService.clearTokenData();
      this._router.navigate([CONSTANTS.routes.LOGIN])
    })
  }
}
