import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { WarehouseOveragesDataSource } from './warehouse-overages-datasource';

@Component({
  selector: 'app-warehouse-overages',
  templateUrl: './warehouse-overages.component.html',
  styleUrls: ['./warehouse-overages.component.css']
})
export class WarehouseOveragesComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() overages;
  @Input() filters;
  @Output() onExportToExcel: EventEmitter<any> = new EventEmitter<any>();
  dataSource: WarehouseOveragesDataSource;


  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['reference','description', 'kit', 'kit_lot_number', 'lot', 'serial_number','expected', 'actual', 'amount'];

  ngOnInit() {
    this.processData(this.overages);
    this.dataSource = new WarehouseOveragesDataSource(this.paginator, this.sort, this.overages, this.filters);

  }

  ngOnDestroy(): void {
    
  }

  processData(overageItems){
    overageItems.forEach(item => {
     if(item.kit_instance){
       item['kit_reference'] = item.kit_instance.item.reference;
       item['kit_lot_number'] = item.kit_instance.lot_number ? item.kit_instance.lot_number : '' ;
     } else {
      item['kit_reference'] = '';
      item['kit_lot_number'] = '';
     }
    });
  }

  getSerial(serial_number) {
    if(serial_number){
      return serial_number;
    }
    else{
      return '-'
    }
  }

  exportToExcel() {
    this.onExportToExcel.emit(this.dataSource.filteredData());
  }

  getLot(lot_number) {
    if(lot_number){
      return lot_number;
    }
    else{
      return '-'
    }
  }

  applyFilter(){
    this.dataSource = new WarehouseOveragesDataSource(this.paginator, this.sort, this.overages, this.filters);
  }
}
