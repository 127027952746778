import { Component, OnInit, Inject } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { ExportToExcelService } from 'src/app/services/export-to-excel.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AuditService } from './../../services/audit.service';
import { AuditLocationService } from './../../services/audit-location.service';
import { TrackLoadingService } from '../../services/track-loading.service';
import { zip } from 'rxjs';

@Component({
  selector: 'app-audit-user-actions-log-dialog',
  templateUrl: './audit-user-actions-log-dialog.component.html',
  styleUrls: ['./audit-user-actions-log-dialog.component.css']
})
export class AuditUserActionsLogDialogComponent implements OnInit {
  audit;
  sites: any[];
  warehouses: any[];
  constructor(
    public _dialogRef: MatDialogRef<AuditUserActionsLogDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private _databaseService: DatabaseService,
    private _excelService: ExportToExcelService,
    private _trackLoading: TrackLoadingService,
    private _auditService: AuditService,
    private _auditLocationService: AuditLocationService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    const that = this;
    setTimeout(function () {
      that._trackLoading.startLoading('audit-user-actions-log-dialog-audit', 'Loading Audit');
      that._trackLoading.startLoading('audit-user-actions-log-dialog-sites', 'Loading Sites');
      that._trackLoading.startLoading('audit-user-actions-log-dialog-warehouses', 'Loading Warehouses');
      zip(
        that._auditService.getAuditById(that.data.audit_id.toString()),
        that._auditLocationService.getLocations(that.data.audit_id.toString()),
        that._auditService.getWarehouses(that.data.audit_id.toString())
      ).subscribe(data => {
        that.audit = data[0];
        that.sites = data[1];
        that.warehouses = data[2];
        that._trackLoading.stopLoading('audit-user-actions-log-dialog-audit');
        that._trackLoading.stopLoading('audit-user-actions-log-dialog-sites');
        that._trackLoading.stopLoading('audit-user-actions-log-dialog-warehouses');
      });
    }, 0);
  }

  delete() {
    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '30%',
      data: 'Are you sure you want to delete the audit log?',
    });

    confirmationDialogRef.afterClosed().subscribe(result => {
      if (result) {
        const that = this;
        this._databaseService.deleteByIndex('user_actions', 'audit_id', that.data.audit_id.toString()).then(function () { that._dialogRef.close(); });
      }
    });
  }

  download() {
    const that = this;
    this._databaseService.getDataByIndex('user_actions', 'audit_id', this.data.audit_id.toString()).then(function (data: any) {
      that._excelService.exportAsExcelFile(data.map(function (user_action) {
        //make the data more user friendly (get rid of some IDs, and give them the name instead)
        let siteName = (<any>(that.sites.find(s => s.id == user_action.site_id) || {})).location_name || "UNKNOWN";
        let warehouseName = (<any>(that.warehouses.find(s => s.id == user_action.warehouse_id) || {})).name || "UNKNOWN";
        return JSON.parse(JSON.stringify(Object.assign({}, user_action, { warehouse_id: undefined, site_id: undefined, id: undefined, location: that.audit.location_name, site: siteName, warehouse: warehouseName })));
      }), `audit_log_${that.data.audit_id}`, {});
      that._dialogRef.close();
    });
  }

}
