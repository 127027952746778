<mat-card class="dashboard-card" [ngClass]="[statusClass]">
  <mat-card-header>
    <mat-card-title>
      <h5 class="header-title">
        {{auditDetails.distributorship.name}}
      </h5>
      <div *ngxPermissionsOnly="['audit_administrator']" [ngClass]="{'disabled': !isOnline }">
        <div class='more-button'>
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Toggle menu">
            <mat-icon>more_vert</mat-icon>
          </button>
        </div>
        <mat-menu #menu="matMenu" xPosition="before">
          <div class="div">
            <button mat-button (click)="openEditModal()"> Edit </button>
          </div>
          <div class="div">
            <button mat-button class="cancel" (click)="openConfirmationDialog()" *ngIf="auditDetails.status !== 'Completed'">
              Cancel Audit </button>
          </div>
        </mat-menu>
      </div>
    </mat-card-title>
  </mat-card-header>

  <mat-card-content class="dashboard-card-content">
    <div class="card-content">
      <div>
        <p> {{auditDetails.location_name}} </p>
        <p>{{auditDetails.start_date | date:'mediumDate'}} - {{auditDetails.end_date | date:'mediumDate'}}</p>
        <p [ngClass]="{
                'Completed': auditDetails.status === 'Completed',
                'Canceled': auditDetails.status === 'Cancelled',
                'Planned': auditDetails.status === 'Planned',
                'In-Progress': auditDetails.status === 'In-Progress' }">

          {{auditDetails.status.toUpperCase()}}
        </p>
      </div>
    </div>
  </mat-card-content>

  <mat-card-actions>
    <!-- <button [ngClass]="{'disabled': !isOnline}" class="agencyDashboard" mat-button (click)="goToAgencyDashBoard()"
      ngbTooltip="View Dashboard" placement="top">Agency Dashboard</button> -->

    <button [ngClass]="{'disabled': !isOnline || disableCompleteButton}" *ngIf="auditDetails.status !== 'Completed' && auditDetails.status != 'Planned' && auditDetails.status != 'Cancelled' && permissions[0] ==='audit_administrator' && !disableCompleteButton"
      class="mark-complete" mat-button (click)="completeAudit('Completed')" ngbTooltip="{{completeAuditToolTip}}"
      placement="right">Audit Complete</button>

    <button *ngIf="auditDetails.status !== 'Completed' && auditDetails.status != 'Planned' && auditDetails.status != 'Cancelled' && permissions[0] ==='audit_administrator' && disableCompleteButton"
      class="un-finished-count-sheets" mat-button ngbTooltip="{{completeAuditToolTip}}" placement="top">
      Audit Complete
    </button>
  </mat-card-actions>
</mat-card>
