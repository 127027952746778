import { DatabaseService } from './database.service';
import { UUID } from 'angular2-uuid';
import { zip } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./database.service";
var AttachmentDatabaseService = /** @class */ (function () {
    function AttachmentDatabaseService(_databaseService) {
        this._databaseService = _databaseService;
        this.storeName = "attachments";
    }
    AttachmentDatabaseService.prototype.new = function (data) {
        var attachment = {
            attachment_content_type: data.attachment_content_type,
            attachment_file_name: data.attachment_file_name,
            attachment_file_size: data.attachment_file_size,
            attachment_updated_at: data.attachment_updated_at,
            formatted_created_at: data.formatted_created_at,
            formatted_created_at_date: data.formatted_created_at_date,
            count_sheet_id: data.count_sheet_id,
            count_sheet_client_id: data.count_sheet_client_id,
            created_at: data.created_at,
            audit_id: data.audit_id,
            is_active: true,
            synchronized: 1,
            id: data.id,
            local_file_id: data.local_file_id
        };
        if (data.synchronized !== undefined) {
            attachment.synchronized = data.synchronized;
        }
        return attachment;
    };
    AttachmentDatabaseService.prototype.initializeValues = function (a) {
        if (!a.local_file_id) {
            a.local_file_id = this.createLocalFileId();
        }
        return a;
    };
    AttachmentDatabaseService.prototype.syncValues = function (a) {
        return a;
    };
    AttachmentDatabaseService.prototype.delete = function (key) {
        var that = this;
        return new Promise(function (resolve, reject) {
            that._databaseService.get(that.storeName, key).then(function (oldAttachment) {
                if (!oldAttachment) {
                    return resolve();
                }
                that._databaseService.delete(that.storeName, key).then(function (value) {
                    var promises = [];
                    promises.push(that.denormalizeCountSheet(oldAttachment.audit_id, oldAttachment.count_sheet_client_id));
                    zip.apply(void 0, promises).subscribe(function (_) {
                        resolve(true);
                    });
                });
            });
        });
    };
    AttachmentDatabaseService.prototype.clear = function () {
        return this._databaseService.clear(this.storeName);
    };
    AttachmentDatabaseService.prototype.get = function (id) {
        return this._databaseService.get(this.storeName, id);
    };
    AttachmentDatabaseService.prototype.add = function (a) {
        var _this = this;
        var that = this;
        a = that.syncValues(that.initializeValues(a));
        return new Promise(function (resolve, reject) {
            _this._databaseService.add(_this.storeName, a).then(function (_) {
                var promises = [];
                promises.push(that.denormalizeCountSheet(a.audit_id, a.count_sheet_client_id));
                zip.apply(void 0, promises).subscribe(function (_) {
                    resolve(a.local_file_id);
                });
            });
        });
    };
    AttachmentDatabaseService.prototype.bulkAdd = function (datas) {
        var that = this;
        return new Promise(function (resolve, reject) {
            if (datas.length === 0) {
                return resolve();
            }
            var attachments = datas.map(function (data) {
                return that.syncValues(that.initializeValues(data));
            });
            that._databaseService.bulkAdd(that.storeName, attachments).then(function () {
                return resolve();
            });
        });
    };
    AttachmentDatabaseService.prototype.deleteByIndex = function (index, value) {
        return this._databaseService.deleteByIndex(this.storeName, index, value);
    };
    AttachmentDatabaseService.prototype.getDataByIndex = function (index, value) {
        return this._databaseService.getDataByIndex(this.storeName, index, value);
    };
    AttachmentDatabaseService.prototype.update = function (a) {
        var _this = this;
        var that = this;
        return new Promise(function (resolve, reject) {
            if (!a.local_file_id) {
                console.log(a);
                throw new Error('how can you update without a primary key?');
            }
            a = that.syncValues(a);
            _this.get(a.local_file_id).then(function (oldAttachment) {
                var archiveChanged = a.toRemove && !oldAttachment.toRemove;
                that._databaseService.update(that.storeName, a).then(function (storeData) {
                    var promises = [];
                    if (archiveChanged) {
                        promises.push(that.denormalizeCountSheet(a.audit_id, a.count_sheet_client_id));
                    }
                    zip.apply(void 0, promises.concat(new Promise(function (r, a) { r(); }))).subscribe(function (_) {
                        resolve(storeData);
                    });
                });
            });
        }).then(function (data) {
            return data;
        });
    };
    AttachmentDatabaseService.prototype.find = function (auditId, params) {
        var _this = this;
        var promise = new Promise(function (resolve, reject) {
            _this._databaseService.getDataByIndex(_this.storeName, 'audit_id', parseInt(auditId, 10)).then(function (matches) {
                if (params.toRemove !== undefined) {
                    matches = matches.filter(function (a) {
                        return !!a.toRemove === !!params.toRemove;
                    });
                }
                if (params.count_sheet_client_id) {
                    matches = matches.filter(function (a) {
                        return parseInt(a.count_sheet_client_id, 10) === parseInt(params.count_sheet_client_id, 10);
                    });
                }
                if (params.synchronized !== undefined) {
                    matches = matches.filter(function (a) {
                        return !!a.synchronized === !!params.synchronized;
                    });
                }
                resolve(matches);
            });
        }).then(function (data) {
            return data;
        });
        return promise;
    };
    AttachmentDatabaseService.prototype.denormalizeCountSheet = function (auditId, countSheetClientId) {
        var that = this;
        return new Promise(function (resolve, reject) {
            that.find(auditId, { count_sheet_client_id: countSheetClientId, toRemove: false }).then(function (attachments) {
                that._databaseService.get('count_sheets', countSheetClientId).then(function (cs) {
                    var count = 0;
                    var synched = cs.isSynchronized;
                    attachments.forEach(function (a) {
                        if (!a.synchronized) {
                            synched = 0;
                        }
                        count = count + 1;
                    });
                    cs.data.attachments_count = count;
                    cs.isSynchronized = synched;
                    that._databaseService.update('count_sheets', cs).then(function (_) {
                        resolve();
                    });
                });
            });
        });
    };
    AttachmentDatabaseService.prototype.createLocalFileId = function () {
        return UUID.UUID();
    };
    AttachmentDatabaseService.ngInjectableDef = i0.defineInjectable({ factory: function AttachmentDatabaseService_Factory() { return new AttachmentDatabaseService(i0.inject(i1.DatabaseService)); }, token: AttachmentDatabaseService, providedIn: "root" });
    return AttachmentDatabaseService;
}());
export { AttachmentDatabaseService };
