import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { urls } from '../helpers/urls';

@Injectable({
  providedIn: 'root'
})
export class ItemPackageService {

  constructor(private http: HttpClient) { }


    get() {
      return this.http.get<any>(`${environment.serverPath}${urls.itemPackages}`);
    }
}
