import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '../../../../node_modules/@angular/material';
import { ConsignmentInventoryImportComponent } from '../consignment-inventory-import/consignment-inventory-import.component';
import { IDialogData } from '../../interfaces/idialog-data';
import { ICountSheetData } from '../../interfaces/icount-sheet-data';
import { CountSheetService } from '../../services/count-sheet.service';
import { ISnackBarConfig } from '../../interfaces/isnack-bar-config';
import { ValidationResult } from '../../interfaces/validation-result';
import { Observer } from 'rxjs';
import { OnlineService } from './../../services/online.service';
import { CountSheetHelper } from '../../helpers/countsheet-helper';
import * as moment from 'moment';
import { UserDataHelper } from '../../helpers/user-data-helper';
import { IuserData } from '../../interfaces/iuser-data';
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';
import { CountSheetDatabaseService } from '../../services/count-sheet-database.service';


@Component({
  selector: 'app-create-unkitted-count-sheet',
  templateUrl: './create-unkitted-count-sheet.component.html',
  styleUrls: ['./create-unkitted-count-sheet.component.css']
})
export class CreateUnkittedCountSheetComponent implements OnInit {

  countSheetHelper: CountSheetHelper = new CountSheetHelper();
  UserDataHelper: UserDataHelper = new UserDataHelper();

  @Output() unkittedCountSheetAddedEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() unkittedCountSheetEditedEvent: EventEmitter<any> = new EventEmitter<any>();

  countSheet: ICountSheetData;
  userData: IuserData;
  saving = false;
  warehouses: any[] = [];
  location: string;
  title: string;
  creatingCountsheet: boolean;
  snackBarConfig: ISnackBarConfig = {
    message: '',
    duration: 3000,
    success: false,
    snackBarClass: ''
  };

  addUnKittedCountSheetObserver: Observer<any> = {
    next: data => {
      if (data.result) {
        const countSheet = this._countSheetDatabaseService.new(data.count_sheet);
        this._countSheetDatabaseService.add(countSheet).then((_) => {
          this.unkittedCountSheetAddedEvent.emit(countSheet);
          this.showSnackBar('Unkitted Count Sheet Created', true);
          this.dialogRef.close();
        });
      } else {
        this.showSnackBar(data.message, false);
      }
    },
    error: () => {
      const that = this;
      const online = this._onlineService.isOnline() && !this._onlineService.getTestOfflineBool() && !this.data.offline;
      if (!online) {
        this.IsUnkittedCountSheetDuplicate(this.countSheet).then((isDuplicate) => {
          if (isDuplicate) {
            that.showSnackBar('ValidationFailed: Duplicate Area', false);
            return;
          }
          that._countSheetDatabaseService.add(this.countSheet).then((_) => {
            this.unkittedCountSheetAddedEvent.emit(this.countSheet);

            this.showSnackBar('Unkitted Count Sheet Created', true);
            setTimeout(() => {
              this.dialogRef.close();
            }, 1000);
          });
        });
      }
    },
    complete: () => {
      this.saving = false;
    }
  };

  constructor(
    public dialogRef: MatDialogRef<ConsignmentInventoryImportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData,
    private _countSheetService: CountSheetService,
    private _onlineService: OnlineService,
    public snackBar: MatSnackBar,
    private _countSheetDatabaseService: CountSheetDatabaseService
  ) {

  }

  ngOnInit() {
    this.userData = this.UserDataHelper.getUserData();
    this.countSheet = {
      data: {
        isKitted: false,
        audit_id: this.data.audit_id,
        audit_location_id: this.data.audit_location_id,
        warehouse_id: null,
        area: '',
        warehouse_name: '',
        location_name: this.data.location_name,
        count_sheet_status: 1,
        audit_kit_instance_id: null,
        status_text: '',
        total_items_counted: 0
      },
      dbId: 0,
      id: this._countSheetDatabaseService.createClientId(),
      isSynchronized: 0
    };
    if (this.data.countsheet) {
      this.countSheet = JSON.parse(JSON.stringify(this.data.countsheet)); //so it is referencing a different instance of the object.  Otherwise changes update on the edit page in real time and stay even if you hit cancel
    }
    this.warehouses = this.data.warehouses;
    this.title = this.data.title;
    this.creatingCountsheet = this.data.creatingCountsheet;
  }

  validateUnkittedCountSheetData(data: ICountSheetData) {
    const result: ValidationResult = {
      success: true,
      message: '',
      fields: []
    };

    if (data.data.area.length > 50) {
      result.success = false;
      result.message = 'Area Name Exceeds Max Limit of 50 Characters',
      result.fields.push('area');
    }
    if (data && data.data.area && data.data.warehouse_id) {
      return new Promise((resolve, reject) => { resolve(result); });
    } else {
      if (!data.data.area) {
        result.success = false;
        result.message = 'Please, provide required field "Area"',
          result.fields.push('area');
      }
      if (!data.data.warehouse_id) {
        result.success = false;
        result.message = !result.message ? 'Please, provide required field "Warehouse"' : 'Please, provide all required fields',
          result.fields.push('warehouse');
      }
      return new Promise((resolve, reject) => { resolve(result); });
    }
  }

  addUnkittedCountSheet() {
    const that = this;
    that.saving = true;
    that.countSheet.data.warehouse_name = that.getWarehouseName(that.countSheet.data.warehouse_id);
    that.countSheet.data.status_text = that.countSheetHelper.getCountSheetStatusText(that.countSheet.data.count_sheet_status);
    that.countSheet.data.created_by = that.userData;
    that.countSheet.data.created_at = moment().format('lll');

    that.validateUnkittedCountSheetData(that.countSheet).then((validation: any) => {
      if (!validation.success) {
        that.snackBarConfig.message = validation.message;
        that.snackBarConfig.success = false;
        that.openSnackBar();
        that.saving = false;
        return;
      }
      const online = that._onlineService.isOnline() && !that._onlineService.getTestOfflineBool() && !that.data.offline;
      if (online) {
        that._countSheetService.createKitCountSheet(that.countSheet).subscribe(that.addUnKittedCountSheetObserver);
      } else {
        that.IsUnkittedCountSheetDuplicate(that.countSheet).then((isDuplicate) => {
          if (!isDuplicate) {
            that._countSheetDatabaseService.add(that.countSheet).then(_ => {
              that.unkittedCountSheetAddedEvent.emit(that.countSheet);
              that.saving = false;
              that.showSnackBar('Unkitted Count Sheet Created', true);
              that.dialogRef.close();
            });
          } else {
            that.saving = false;
            that.showSnackBar('Validation Failed: Duplicate Area', false);
          }
        });
      }
    });
  }

  editUnkittedCountSheet() {
    const that = this;
    if (that.countSheet.data.area.length > 50) {
      that.showSnackBar('Area Name Exceeds Max Limit of 50 Characters', false);
      return;
    }
    that.IsUnkittedCountSheetDuplicate(that.countSheet).then((isDuplicate) => {
      if (isDuplicate) {
        this.showSnackBar('Validation Failed: Duplicate Area', false);
        return;
      }
      that.countSheet.data.warehouse_name = that.getWarehouseName(that.countSheet.data.warehouse_id);
      const online = that._onlineService.isOnline() && !that._onlineService.getTestOfflineBool() && !that.data.offline;
      if (online) {
        that._countSheetService.updateCountSheet(that.countSheet.dbId, {area: that.countSheet.data.area, warehouse_id: that.countSheet.data.warehouse_id}).subscribe(data => {
          that._countSheetDatabaseService.update(that.countSheet).then(_ => {
            that.unkittedCountSheetEditedEvent.emit(that.countSheet);
            that.dialogRef.close();
          });
        });
      } else {
        that.countSheet.isSynchronized = 0;
        that._countSheetDatabaseService.update(that.countSheet).then(_ => {
          that.unkittedCountSheetEditedEvent.emit(that.countSheet);
          that.dialogRef.close();
        });
      }
    });
  }




  IsUnkittedCountSheetDuplicate(countSheet: ICountSheetData) {
    const promise = new Promise((resolve, reject) => {
      this._countSheetDatabaseService.find(countSheet.data.audit_id, {null_audit_kit_instance_id: true, area: countSheet.data.area, not_client_id: countSheet.id, archived: false}).then((matches: any[]) => {
        resolve(matches.length !== 0);
      });
    });
    return promise;
  }

  getWarehouseName(warehouse_id: number): string {
    let name = '';
    if (warehouse_id) {
      const index = this.warehouses.map(w => {
        return w.account_id;
      }).indexOf(warehouse_id);
      name = this.warehouses[index].name;
    }

    return name;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  showSnackBar(message: string, success: boolean): void {
    this.snackBarConfig.message = message;
    this.snackBarConfig.success = success;
    this.openSnackBar();
  }

  openSnackBar() {
    this.snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: this.snackBarConfig,
      duration: this.snackBarConfig.duration
    });
  }
}
