var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { OktaConfigService } from '../services/okta-config.service';
import { CONSTANTS } from '../helpers/constants';
import { OnlineService } from '../services/online.service';
import { UserDataHelper } from '../helpers/user-data-helper';
import { AccessTokenService } from '../services/access-token.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth.service";
import * as i2 from "@angular/router";
import * as i3 from "ngx-permissions";
import * as i4 from "../services/okta-config.service";
import * as i5 from "../services/online.service";
import * as i6 from "../services/access-token.service";
var LoginGuardGuard = /** @class */ (function () {
    function LoginGuardGuard(authService, router, permissionsService, _oktaConfigService, _onlineService, _accessTokenService) {
        this.authService = authService;
        this.router = router;
        this.permissionsService = permissionsService;
        this._oktaConfigService = _oktaConfigService;
        this._onlineService = _onlineService;
        this._accessTokenService = _accessTokenService;
        this.isInternalKey = CONSTANTS.localStorageKeys.LOGIN_IS_INTERNAL;
        this.userDataHelper = new UserDataHelper();
        this.loginParams = {};
        this.configData = this._oktaConfigService.getFromLocal();
    }
    LoginGuardGuard.prototype.canActivate = function (next, state) {
        var accessTokenData = this._accessTokenService.getInMemoryTokenDataIfNotNull();
        var expired = this._accessTokenService.isExpired(accessTokenData);
        var isInternalValue = localStorage.getItem(this.isInternalKey);
        var isInternalNull = isInternalValue === null;
        var isInternal = isInternalValue === "true";
        var isOnline = this._onlineService.isOnline();
        if (isOnline) {
            if (accessTokenData && accessTokenData.access_token) {
                if (accessTokenData.access_token && !expired) {
                    this.permissionsService.loadPermissions(JSON.parse(localStorage.getItem(CONSTANTS.localStorageKeys.PERMISSIONS)));
                    return true;
                }
                else {
                    if (accessTokenData.refresh_token) {
                        this.loginParams.grant_type = "refresh_token";
                        this.loginParams.refresh_token = accessTokenData.refresh_token;
                        this.loginParams.client_id = this.configData.audit_internal_client_id;
                        this.loginParams.scope = this.configData.audit_scope += " offline_access";
                        this.loginParams.redirect_uri = this.configData.audit_login_redirect_uri;
                        return this.getAccessTokenVanilla(this.loginParams);
                    }
                    else {
                        this.router.navigate([CONSTANTS.routes.LOGIN]);
                        return false;
                    }
                }
            }
            else {
                // do some stuff with okta
                this.authService.redirectUrl = state.url;
                if (isInternal) {
                    this._oktaConfigService.makeInternalLoginUri(this.authService.redirectUrl).then(function (url) {
                        document.location.href = url;
                    });
                }
                else if (isInternalNull) {
                    this.router.navigate([CONSTANTS.routes.LOGIN]);
                }
                else {
                    this._oktaConfigService.makeExternalLoginUri(this.authService.redirectUrl).then(function (url) {
                        document.location.href = url;
                    });
                }
                return false;
            }
        }
        else {
            //maybe weird? perhaps move to its own guard
            this.permissionsService.loadPermissions(JSON.parse(localStorage.getItem(CONSTANTS.localStorageKeys.PERMISSIONS)));
            return true;
        }
    };
    LoginGuardGuard.prototype.getAccessTokenVanilla = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var that, isInternal, url, request;
            return __generator(this, function (_a) {
                that = this;
                isInternal = localStorage.getItem(this.isInternalKey) === "true";
                url = isInternal ? this.configData.arthrex_employee_base_url : this.configData.non_arthrex_employee_base_url;
                request = new XMLHttpRequest();
                request.open(CONSTANTS.http.methods.POST, "" + url + this.configData.get_access_token, true);
                request.setRequestHeader(CONSTANTS.http.headerLabel.CONTENT_TYPE, CONSTANTS.http.headers.contentType.URL_ENCODED);
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        request.onload = function () {
                            var body = {};
                            try {
                                body = JSON.parse(request.response);
                            }
                            catch (e) { }
                            if (request.status == 200) {
                                that.authService.register_token(body, isInternal ? CONSTANTS.tenants.INTERNAL : CONSTANTS.tenants.EXTERNAL).subscribe(function (data) {
                                    if (data.success) {
                                        var currentUser = {
                                            first_name: data.user.first_name,
                                            last_name: data.user.last_name,
                                            name: data.user.first_name + " " + data.user.last_name,
                                            email_address: data.user.email_address,
                                            user_id: data.user.user_id
                                        };
                                        that.userDataHelper.setUserData(currentUser);
                                        that._accessTokenService.storeTokenData(body);
                                        that.authService.isLoggedIn = true;
                                        that.permissionsService.loadPermissions(JSON.parse(localStorage.getItem(CONSTANTS.localStorageKeys.PERMISSIONS)));
                                        resolve(true);
                                    }
                                }, function (error) {
                                    if (error.status == 403) {
                                        resolve(false);
                                    }
                                });
                            }
                            else {
                                resolve(false);
                            }
                        };
                        request.onerror = function () {
                            return Promise.resolve(false);
                        };
                        var body = Object.keys(params).map(function (key) { return key + '=' + params[key]; }).join('&');
                        request.send(body);
                    })];
            });
        });
    };
    LoginGuardGuard.ngInjectableDef = i0.defineInjectable({ factory: function LoginGuardGuard_Factory() { return new LoginGuardGuard(i0.inject(i1.AuthService), i0.inject(i2.Router), i0.inject(i3.NgxPermissionsService), i0.inject(i4.OktaConfigService), i0.inject(i5.OnlineService), i0.inject(i6.AccessTokenService)); }, token: LoginGuardGuard, providedIn: "root" });
    return LoginGuardGuard;
}());
export { LoginGuardGuard };
