import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { SapItemTableDataSource } from './sap-item-table-datasource';
import { AgencyDashboardService } from '../../services/agency-dashboard.service';
import { ExclusionService } from '../../services/exclusion.service';
import { ActivatedRoute } from '@angular/router';
import { IexcelExport } from '../../interfaces/iexcel-export';
import { ExportToExcelService } from '../../services/export-to-excel.service';
import { ITableFilter } from 'src/app/interfaces/itable-filter';

@Component({
  selector: 'app-sap-item-table',
  templateUrl: './sap-item-table.component.html',
  styleUrls: ['./sap-item-table.component.css']
})
export class SapItemTableComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Output() dataLoadedEvent: EventEmitter<any> = new EventEmitter<any>();
  dataSource: SapItemTableDataSource;
  @Input() viewMode: string;
  agencyName: string;
  lengths: any = {};
  auditId: string;
  filters: ITableFilter;
  counted;
  overages;
  shortages;

  ngOnDestroy(): void {
    
  }

  constructor(
    private _agencyDashboardService: AgencyDashboardService,
    private _exclusionService: ExclusionService,
    private _route: ActivatedRoute,
    private excelService: ExportToExcelService
  ) {

  }

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['part_number', 'description', 'lot_number', 'serial_number', 'overage', 'shortage'];

  ngOnInit() {
    this.filters = { searchString: '' };
    this.auditId = this._route.snapshot.paramMap.get('audit_id');

    this._agencyDashboardService.getAgencyDashboardData(+this.auditId).subscribe(data => {
      this.counted = data.filter(c => c['actual_quantity'] > 0);
      this.overages = data.filter(c => c['actual_quantity'] > c['expected_quantity']);
      this.shortages = data.filter(c => c['expected_quantity'] > c['actual_quantity']);
      this.lengths = {
        counted: this.counted.map(c => c['actual_quantity']).reduce((total, quantity) => total + quantity, 0),
        overages: this.overages.map(c => c['actual_quantity'] - c['expected_quantity']).reduce((total, quantity) => total + quantity, 0),
        shortages: this.shortages.map(c => c['expected_quantity'] - c['actual_quantity']).reduce((total, quantity) => total + quantity, 0)
      };
      this.changeData(this.viewMode);
      this.dataLoadedEvent.emit();
    });
  }

  setAgencyName(name) {
    this.agencyName = name;
  }

  changeData(value) {
    switch (value) {
    case 'counted':
      this.displayedColumns = ['part_number', 'description', 'lot_number', 'serial_number', 'expected_quantity', 'actual_quantity', 'overage', 'shortage'];
      this.dataSource = new SapItemTableDataSource(this.paginator, this.sort, this.counted, this.filters);
      break;
    case 'overage':
      this.displayedColumns = ['part_number', 'description', 'lot_number', 'serial_number', 'overage'];
      this.dataSource = new SapItemTableDataSource(this.paginator, this.sort, this.overages, this.filters);
      break;
    default:
      this.displayedColumns = ['part_number', 'description', 'lot_number', 'serial_number', 'shortage'];
      this.dataSource = new SapItemTableDataSource(this.paginator, this.sort, this.shortages, this.filters);
      break;
    }
    this.viewMode = value;
  }

  exportToExcel() {
    const data = this.dataSource.getFilteredData(this.dataSource.data);
    //array to send to exel-export service
    //array to send to exel-export service
    const bothTabs = [];
    const tabOneData = [];
    const tabTwoData = [];
    const grouped_data = this.getGroupedData(data);
    //represents a single row in exel sheet
    let dataRow: IexcelExport;
    let groupedDataRow: IexcelExport;

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });
    //switching the data to be exported based on the current table that is displayed
    switch (this.viewMode) {
    case 'counted':
      data.forEach(item => {
        const variance = item.actual_quantity - item.expected_quantity;
        const price = parseFloat(item.stocking_price + '0');

        dataRow = {
          reference: item.item.reference,
          description: item.item.description,
          lot_number: item.lot_number,
          serial_number: item.serial_number,
          expected_qty: item.expected_quantity,
          actual_qty: item.actual_quantity,
          overage: variance > 0 ? variance : 0,
          shortage: variance < 0 ? variance : 0,
          stocking_price: formatter.format(price)
        };

        tabOneData.push(dataRow);
      });
      for (const [key, value] of Object.entries(grouped_data)) {
        groupedDataRow = {
          reference: value[0].reference,
          description: value[0].description,
          expected_qty: value[0].expected_qty,
          actual_qty: value[0].actual_qty,
          overage: value[0].overage,
          shortage: value[0].shortage,
          stocking_price: formatter.format(value[0].stocking_price)
        };
        tabTwoData.push(groupedDataRow);
      }
      break;
    case 'shortage':
      this.dataSource.getFilteredData(this.dataSource.data).forEach(item => {
        const variance = item.actual_quantity - item.expected_quantity;
        const price = parseFloat(item.stocking_price + '0');
        dataRow = {
          reference: item.item.reference,
          description: item.item.description,
          lot_number: item.lot_number,
          serial_number: item.serial_number,
          overage: variance > 0 ? variance : 0,
          shortage: variance < 0 ? variance : 0,
          stocking_price: formatter.format(price)
        };

        tabOneData.push(dataRow);

      });
      for (const [key, value] of Object.entries(grouped_data)) {
        groupedDataRow = {
          reference: value[0].reference,
          description: value[0].description,
          expected_qty: value[0].expected_qty,
          actual_qty: value[0].actual_qty,
          overage: value[0].overage,
          shortage: value[0].shortage,
          stocking_price: formatter.format(value[0].stocking_price)
        };
        tabTwoData.push(groupedDataRow);
      }

      break;
    case 'overage':
      this.dataSource.getFilteredData(this.dataSource.data).forEach(item => {
        const variance = item.actual_quantity - item.expected_quantity;
        const price = parseFloat(item.stocking_price + '0');
        dataRow = {
          reference: item.item.reference,
          description: item.item.description,
          lot_number: item.lot_number,
          serial_number: item.serial_number,
          overage: variance > 0 ? variance : 0,
          shortage: variance < 0 ? variance : 0,
          stocking_price: formatter.format(price)
        };

        tabOneData.push(dataRow);
      });

      for (const [key, value] of Object.entries(grouped_data)) {
        groupedDataRow = {
          reference: value[0].reference,
          description: value[0].description,
          expected_qty: value[0].expected_qty,
          actual_qty: value[0].actual_qty,
          overage: value[0].overage,
          shortage: value[0].shortage,
          stocking_price: formatter.format(value[0].stocking_price)
        };
        tabTwoData.push(groupedDataRow);
      }

      break;
    default:
    }
    // passing data to export excel service
    bothTabs.push(tabOneData);
    bothTabs.push(tabTwoData);
    // passing data to export excel service
    this.excelService.exportDataAsExcelFile(bothTabs, this.agencyName + '_Agency_Dashboard_' + this.viewMode, {});
  }

  getGroupedData(data) {
    const grouped_data = [];
    data.forEach(function (o) {
      const key = o.item.reference.toLowerCase();
      grouped_data[key] = grouped_data[key] || [];
      if (grouped_data[key].length === 0) {
        const variance = o.actual_quantity - o.expected_quantity;
        const row = {
          reference: o.item.reference,
          description: o.item.description,
          actual_qty: o.actual_quantity,
          expected_qty: o.expected_quantity,
          overage: variance > 0 ? variance : 0,
          shortage: variance < 0 ? variance : 0,
          stocking_price: parseFloat(o.stocking_price + '0')
        };
        grouped_data[key].push(row);
      } else {
        grouped_data[key][0].actual_qty += o.actual_quantity;
        grouped_data[key][0].expected_qty += o.expected_quantity;
        const variance = grouped_data[key][0].actual_qty - grouped_data[key][0].expected_qty;
        grouped_data[key][0].overage = variance > 0 ? variance : 0;
        grouped_data[key][0].shortage = variance < 0 ? variance : 0;
      }
    });
    return grouped_data;
  }
}
