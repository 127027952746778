import { OnInit, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatSnackBar } from '@angular/material';
import { UnkittedCountsheetDataSource } from './unkitted-countsheet-datasource';
import { CreateUnkittedCountSheetComponent } from '../create-unkitted-count-sheet/create-unkitted-count-sheet.component';
import { Router, ActivatedRoute } from '@angular/router';
import { CountSheetService } from '../../services/count-sheet.service';
import { AuditService } from '../../services/audit.service';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { OnlineService } from './../../services/online.service';
import { of, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { CountSheetHelper } from './../../helpers/countsheet-helper';
import { UserDataHelper } from '../../helpers/user-data-helper';
import * as moment from 'moment';
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';
import { CountSheetItemHelper } from 'src/app/helpers/countsheet-item-helper';
import { PrintHelper } from '../../helpers/print-helper';
import { CountSheetItemService } from '../../services/count-sheet-item.service';
import { ItemSettingService } from 'src/app/services/item-setting.service';
import { ItemCombinationService } from 'src/app/services/item-combination.service';
import { KitDefinitionItemService } from 'src/app/services/kit-definition-item.service';
import { ConsignmentInventoryImportService } from 'src/app/services/consignment-inventory-import.service';
import { ExclusionService } from 'src/app/services/exclusion.service';
import { RedspotInventoryImportService } from 'src/app/services/redspot-inventory-import.service';
import { LotPoolManagedItemService } from '../../services/lot-pool-managed-item.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { DatabaseService } from 'src/app/services/database.service';
import { CountSheetItemDatabaseService } from 'src/app/services/count-sheet-item-database.service';
import { CountSheetDatabaseService } from 'src/app/services/count-sheet-database.service';
import { AuditStatus } from 'src/app/helpers/audit-status';
import { RecallOracleService } from 'src/app/services/recall-oracle.service';
var UnkittedCountsheetComponent = /** @class */ (function () {
    function UnkittedCountsheetComponent(dialog, _countSheetService, _router, _onlineService, _route, snackBar, _auditService, _countSheetItemService, itemSettingService, itemCombinationService, kitDefinitionItemService, consignmentInventoryImportService, exclusionService, redspotInventoryImportService, _lotPoolManagedItemService, _databaseService, _countSheetItemDatabaseService, _countSheetDatabaseService, _recallOracleService) {
        var _this = this;
        this.dialog = dialog;
        this._countSheetService = _countSheetService;
        this._router = _router;
        this._onlineService = _onlineService;
        this._route = _route;
        this.snackBar = snackBar;
        this._auditService = _auditService;
        this._countSheetItemService = _countSheetItemService;
        this.itemSettingService = itemSettingService;
        this.itemCombinationService = itemCombinationService;
        this.kitDefinitionItemService = kitDefinitionItemService;
        this.consignmentInventoryImportService = consignmentInventoryImportService;
        this.exclusionService = exclusionService;
        this.redspotInventoryImportService = redspotInventoryImportService;
        this._lotPoolManagedItemService = _lotPoolManagedItemService;
        this._databaseService = _databaseService;
        this._countSheetItemDatabaseService = _countSheetItemDatabaseService;
        this._countSheetDatabaseService = _countSheetDatabaseService;
        this._recallOracleService = _recallOracleService;
        this.anyCountSheetsCounted = false;
        /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
        this.displayedColumns = ['verify', 'area', 'site', 'warehouse', 'count', 'status', 'createdBy', 'dateCreated', 'addUnkitted'];
        this.viewOnly = false;
        this.loading = false;
        this.snackBarConfig = {
            message: '',
            duration: 3000,
            success: false,
            snackBarClass: ''
        };
        this.verifyAllChecked = false;
        // helpers
        this.countSheetHelper = new CountSheetHelper();
        this.userDataHelper = new UserDataHelper();
        this.countSheetItemHelper = new CountSheetItemHelper();
        this.printHelper = new PrintHelper();
        // observers
        this.startCountObserver = {
            next: function (data) {
                _this._router.navigate([_this.audit.id, 'non_kitted_count_sheet', _this.rowToCount.id, _this.viewOnly], { queryParams: { status: _this.status } });
            },
            error: function (err) {
                _this.onlineSubscription = _this._onlineService.isOnlineSubscription().subscribe(function (online) {
                    if (!online) {
                        var countSheetStatus = 2;
                        _this.rowToCount.isSynchronized = 0;
                        _this.rowToCount._destroy = false;
                        _this.rowToCount.data.count_sheet_status = countSheetStatus;
                        _this.rowToCount.data.status_text = _this.countSheetHelper.getCountSheetStatusText(countSheetStatus);
                        _this._countSheetDatabaseService.update(_this.rowToCount).then(function (_) {
                            _this._router.navigate([_this.auditId, 'non_kitted_count_sheet', _this.rowToCount.id, _this.viewOnly], { queryParams: { status: _this.status } });
                        });
                    }
                });
                _this.onlineSubscription.unsubscribe();
            },
            complete: function () {
            }
        };
    }
    Object.defineProperty(UnkittedCountsheetComponent.prototype, "dataSource", {
        get: function () {
            return this._dataSource;
        },
        set: function (newDataSource) {
            this.anyCountSheetsCounted = !!newDataSource.filteredData().find(function (cs) { return cs.data.count_sheet_status === 3; });
            this._dataSource = newDataSource;
        },
        enumerable: true,
        configurable: true
    });
    UnkittedCountsheetComponent.prototype.ngOnDestroy = function () {
        if (this.onlineSubscription) {
            this.onlineSubscription.unsubscribe();
        }
        this.snack = null;
    };
    UnkittedCountsheetComponent.prototype.ngOnInit = function () {
        this.auditId = +this._route.snapshot.paramMap.get('audit_id');
        this.userData = this.userDataHelper.getUserData();
        this.permissions = JSON.parse(localStorage.getItem('permissions'));
        this.status = this.filters['status'];
        if (this.audit.status === AuditStatus.COMPLETED) {
            this.viewOnly = true;
        }
        else {
            if (this.siteId === -1) {
                this.viewOnly = true;
            }
            else {
                this.viewOnly = this._route.snapshot.paramMap.get('isViewOnly') === 'true';
                if (this.permissions[0] === 'auditor' || this.permissions[0] === 'audit_administrator') {
                    this.viewOnly = false;
                }
                else if (this.permissions[0] === 'audit_viewer') {
                    this.viewOnly = true;
                }
            }
        }
        this.getUnkittedCountSheets();
    };
    UnkittedCountsheetComponent.prototype.getUnkittedCountSheets = function () {
        var _this = this;
        if (this.siteId !== -1) {
            this.unkittedCountSheets = this.passedUnkittedCountSheets.filter(function (puc) {
                return puc.data.audit_location_id === +_this.siteId;
            });
        }
        else {
            this.unkittedCountSheets = this.passedUnkittedCountSheets;
        }
        this.dataSource = new UnkittedCountsheetDataSource(this.paginator, this.sort, this.unkittedCountSheets, this.filters);
        this.loading = false;
    };
    UnkittedCountsheetComponent.prototype.addUnkittedCountSheet = function () {
        var _this = this;
        var dialogRef = this.dialog.open(CreateUnkittedCountSheetComponent, {
            width: '50%',
            data: {
                audit_location_id: this.siteId,
                audit_id: this.auditId,
                warehouses: this.warehouses,
                location_name: this.location.location_name,
                title: 'Create Unkitted Countsheet',
                creatingCountsheet: true
            }
        });
        dialogRef.componentInstance.unkittedCountSheetAddedEvent.subscribe(function (data) {
            _this._databaseService.add('user_actions', {
                audit_id: _this.auditId.toString(),
                auditor: _this.userData.first_name + " " + _this.userData.last_name,
                timestamp: "" + (new Date()).toUTCString(),
                area: "" + data.data.area,
                kit_reference: '',
                kit_lot_number: '',
                action: 'Created count sheet',
                total_items_counted: data.data.total_items_counted,
                site_id: _this.siteId,
                warehouse_id: data.data.warehouse_id
            });
            _this.startCounting(data);
        });
    };
    UnkittedCountsheetComponent.prototype.editUnkittedCountSheet = function (countsheet) {
        var _this = this;
        var dialogRef = this.dialog.open(CreateUnkittedCountSheetComponent, {
            width: '50%',
            data: {
                audit_location_id: this.siteId,
                audit_id: this.auditId,
                warehouses: this.warehouses,
                location_name: this.location.location_name,
                title: 'Edit Unkitted Countsheet',
                creatingCountsheet: false,
                countsheet: countsheet
            }
        });
        dialogRef.componentInstance.unkittedCountSheetEditedEvent.subscribe(function (data) {
            zip(_this._countSheetDatabaseService.find(_this.auditId, { audit_location_id: _this.siteId, null_audit_kit_instance_id: true, archived: false })).subscribe(function (data) {
                _this.unkittedCountSheets = data[0];
                _this.dataSource = new UnkittedCountsheetDataSource(_this.paginator, _this.sort, _this.unkittedCountSheets, _this.filters);
            });
        });
    };
    UnkittedCountsheetComponent.prototype.startCounting = function (row) {
        var _this = this;
        this.rowToCount = row;
        var countSheetStatus = 2;
        this.onlineSubscription = this._onlineService.isOnlineSubscription().subscribe(function (online) {
            if (online && !_this._onlineService.getTestOfflineBool()) {
                _this._countSheetService.updateCountSheetStatus(row.dbId, 2).subscribe(_this.startCountObserver);
            }
            else {
                _this.rowToCount.isSynchronized = 0;
                _this.rowToCount._destroy = false;
                _this.rowToCount.data.count_sheet_status = countSheetStatus;
                _this.rowToCount.data.status_text = _this.countSheetHelper.getCountSheetStatusText(countSheetStatus);
                _this._countSheetDatabaseService.update(_this.rowToCount).then(function (_) {
                    _this._router.navigate([_this.auditId, 'non_kitted_count_sheet', _this.rowToCount.id, _this.viewOnly], { queryParams: { status: _this.status } });
                });
            }
        });
        this.onlineSubscription.unsubscribe();
    };
    UnkittedCountsheetComponent.prototype.continueCounting = function (row) {
        this.rowToCount = row;
        this._router.navigate([this.audit.id, 'non_kitted_count_sheet', this.rowToCount.id, this.viewOnly], { queryParams: { status: this.status } });
    };
    UnkittedCountsheetComponent.prototype.goToCompletedCountSheet = function (row) {
        this.rowToCount = row;
        this._router.navigate([this.audit.id, 'non_kitted_count_sheet', this.rowToCount.id, this.viewOnly], { queryParams: { status: this.status } });
    };
    UnkittedCountsheetComponent.prototype.goToVerifiedCountSheet = function (row) {
        this.rowToCount = row;
        this._router.navigate([this.audit.id, 'non_kitted_count_sheet', this.rowToCount.id, this.viewOnly], { queryParams: { status: this.status } });
    };
    UnkittedCountsheetComponent.prototype.checkedClicked = function (row) {
        var _this = this;
        var verifiedStatus = 4;
        var verifiedMetadata = {
            verified_by_id: this.userData.user_id,
            verified_time: moment().toDate()
        };
        var dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '30%',
            data: "Verify countsheet for Area: " + row.data.area + "?",
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this._databaseService.add('user_actions', {
                    audit_id: _this.audit.id.toString(),
                    auditor: _this.userData.first_name + " " + _this.userData.last_name,
                    timestamp: "" + (new Date()).toUTCString(),
                    area: "" + row.data.area,
                    kit_reference: '',
                    kit_lot_number: '',
                    action: 'Verified count sheet',
                    total_items_counted: row.data.total_items_counted,
                    site_id: _this.siteId,
                    warehouse_id: row.data.warehouse_id
                });
                _this.onlineSubscription = _this._onlineService.isOnlineSubscription().subscribe(function (online) {
                    if (online && !_this._onlineService.getTestOfflineBool()) {
                        _this._countSheetService.updateCountSheetStatus(row.dbId, verifiedStatus, verifiedMetadata).subscribe(function (res) {
                            _this._countSheetDatabaseService.update(_this._countSheetDatabaseService.new(res.count_sheet)).then(function (_) {
                                zip(_this._countSheetDatabaseService.find(_this.auditId, { audit_location_id: _this.siteId, null_audit_kit_instance_id: true, archived: false })).subscribe(function (data) {
                                    _this.unkittedCountSheets = data[0];
                                    _this.dataSource = new UnkittedCountsheetDataSource(_this.paginator, _this.sort, _this.unkittedCountSheets, _this.filters);
                                });
                            });
                        });
                    }
                    else {
                        row.isSynchronized = 0;
                        row.data.count_sheet_status = verifiedStatus;
                        row.data.status_text = _this.countSheetHelper.getCountSheetStatusText(verifiedStatus);
                        row.data.verified_by = _this.userData;
                        row.data.verified_time = moment().format('lll');
                        _this._countSheetDatabaseService.update(row).then(function (_) {
                            zip(_this._countSheetDatabaseService.find(_this.auditId, { audit_location_id: _this.siteId, null_audit_kit_instance_id: true, archived: false })).subscribe(function (data) {
                                _this.unkittedCountSheets = data[0];
                                _this.dataSource = new UnkittedCountsheetDataSource(_this.paginator, _this.sort, _this.unkittedCountSheets, _this.filters);
                            });
                        });
                    }
                });
                _this.onlineSubscription.unsubscribe();
            }
            else {
                row.data.verify_display = false;
            }
        });
    };
    UnkittedCountsheetComponent.prototype.applyFilter = function () {
        this.dataSource = new UnkittedCountsheetDataSource(this.paginator, this.sort, this.unkittedCountSheets, this.filters);
    };
    UnkittedCountsheetComponent.prototype.checkAllClicked = function (event) {
        var _this = this;
        var dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '30%',
            data: 'Verify all unkitted countsheets?',
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                var verifiedStatus_1 = 4;
                var verifiedMetadata_1 = {
                    verified_by_id: _this.userData.user_id,
                    verified_time: moment().toDate()
                };
                var updatingCountSheets_1 = _this.dataSource.filteredData().filter(function (cs) { return cs.data.count_sheet_status === 3; });
                _this.onlineSubscription = _this._onlineService.isOnlineSubscription().subscribe(function (online) {
                    if (online && !_this._onlineService.getTestOfflineBool()) {
                        _this._countSheetService.updateCountSheetStatuses(updatingCountSheets_1.map(function (cs) { return cs.dbId; }), verifiedStatus_1, verifiedMetadata_1, _this.siteId).subscribe(function (data) {
                            updatingCountSheets_1.forEach(function (cs) {
                                _this._databaseService.add('user_actions', {
                                    audit_id: _this.audit.id.toString(),
                                    auditor: _this.userData.first_name + " " + _this.userData.last_name,
                                    timestamp: "" + (new Date()).toUTCString(),
                                    area: "" + cs.data.area,
                                    kit_reference: '',
                                    kit_lot_number: '',
                                    action: 'Verified count sheet',
                                    total_items_counted: cs.data.total_items_counted,
                                    site_id: cs.siteId,
                                    warehouse_id: cs.data.warehouse_id
                                });
                            });
                            //update offline data
                            var promises = data.count_sheets.map(function (cs) {
                                return _this._countSheetDatabaseService.update(_this._countSheetDatabaseService.new(cs));
                            }).concat(new Promise(function (r, a) { r(true); }));
                            zip.apply(void 0, promises).subscribe(function (_) {
                                zip(_this._countSheetDatabaseService.find(_this.auditId, { audit_location_id: _this.siteId, null_audit_kit_instance_id: true, archived: false })).subscribe(function (data) {
                                    _this.unkittedCountSheets = data[0];
                                    _this.dataSource = new UnkittedCountsheetDataSource(_this.paginator, _this.sort, _this.unkittedCountSheets, _this.filters);
                                });
                            });
                        });
                    }
                    else {
                        var promises = updatingCountSheets_1.map(function (countSheet) {
                            _this._databaseService.add('user_actions', {
                                audit_id: _this.audit.id.toString(),
                                auditor: _this.userData.first_name + " " + _this.userData.last_name,
                                timestamp: "" + (new Date()).toUTCString(),
                                area: "" + countSheet.data.area,
                                kit_reference: '',
                                kit_lot_number: '',
                                action: 'Verified count sheet',
                                total_items_counted: countSheet.data.total_items_counted,
                                site_id: countSheet.siteId,
                                warehouse_id: countSheet.data.warehouse_id
                            });
                            countSheet.isSynchronized = 0;
                            countSheet.data.count_sheet_status = verifiedStatus_1;
                            countSheet.data.verified_by = _this.userData;
                            countSheet.data.verified_time = moment().format('lll');
                            return _this._countSheetDatabaseService.update(countSheet);
                        });
                        zip.apply(void 0, promises.concat(new Promise(function (r, a) { r(true); }))).subscribe(function (_) {
                            zip(_this._countSheetDatabaseService.find(_this.auditId, { audit_location_id: _this.siteId, null_audit_kit_instance_id: true, archived: false })).subscribe(function (data) {
                                _this.unkittedCountSheets = data[0];
                                _this.dataSource = new UnkittedCountsheetDataSource(_this.paginator, _this.sort, _this.unkittedCountSheets, _this.filters);
                            });
                        });
                    }
                });
                _this.onlineSubscription.unsubscribe();
            }
            else {
                _this.verifyAllChecked = false;
            }
        });
    };
    UnkittedCountsheetComponent.prototype.openSnackBar = function () {
        this.snackBar.openFromComponent(MaterialSnackbarComponent, {
            data: this.snackBarConfig,
            duration: this.snackBarConfig.duration
        });
    };
    UnkittedCountsheetComponent.prototype.refreshDatasource = function () {
        this.dataSource = new UnkittedCountsheetDataSource(this.paginator, this.sort, this.unkittedCountSheets, this.filters);
    };
    UnkittedCountsheetComponent.prototype.getLotPoolManagedItems = function () {
        if (this.lotPoolManagedItems) {
            return of(this.lotPoolManagedItems);
        }
        return this._lotPoolManagedItemService.getLotPoolManagedItems();
    };
    UnkittedCountsheetComponent.prototype.getCountSheetItems = function (online, auditId, countSheet) {
        var that = this;
        return new Promise(function (resolve, reject) {
            if (online && !that._onlineService.getTestOfflineBool() && countSheet.isSynchronized) {
                return that._countSheetItemService.getCountSheetItems(auditId, { count_sheet_client_id: countSheet.id }).subscribe(function (data) {
                    that._countSheetItemService.refreshOfflineDataForCountSheet(auditId, countSheet.id, data).then(function (matches) {
                        resolve(matches);
                    });
                });
            }
            else {
                that._countSheetItemDatabaseService.find(auditId, { count_sheet_client_id: countSheet.id }).then(function (matches) {
                    resolve(matches);
                });
            }
        });
    };
    UnkittedCountsheetComponent.prototype.getExclusions = function (auditId) {
        if (this.exclusions) {
            return of(this.exclusions);
        }
        return this.exclusionService.getExclusions(auditId);
    };
    UnkittedCountsheetComponent.prototype.getRedspotQuantities = function (auditId, warehouseId) {
        return this.redspotInventoryImportService.getInventory(auditId).pipe(map(function (data) { return data.filter(function (rq) { return rq.warehouse_id === warehouseId && !rq.kit_instance_id; }); }));
    };
    UnkittedCountsheetComponent.prototype.getSapQuantities = function (auditId) {
        if (this.sapQuantities) {
            return of(this.sapQuantities);
        }
        return this.consignmentInventoryImportService.getInventory(auditId, true);
    };
    UnkittedCountsheetComponent.prototype.getItemCombinations = function () {
        if (this.itemCombinations) {
            return of(this.itemCombinations);
        }
        return this.itemCombinationService.get();
    };
    UnkittedCountsheetComponent.prototype.getItemSettingsByAudit = function (auditId) {
        if (this.itemSettings) {
            return of(this.itemSettings);
        }
        return this.itemSettingService.getItemSettingsByAudit(auditId);
    };
    UnkittedCountsheetComponent.prototype.getRootKitDefinitionItems = function (kitId) {
        return this.kitDefinitionItemService.getKitDefinitionItems().pipe(map(function (data) {
            return data.filter(function (kdi) { return kdi.kit_definition_id === kitId; });
        }));
    };
    UnkittedCountsheetComponent.prototype.printCountSheet = function (row) {
        var _this = this;
        this.filters['exclusions'] = 'hide';
        zip(this.getRedspotQuantities(row.data.audit_id, row.data.warehouse_id), this.getRootKitDefinitionItems(row.data.kit_id), this.getLotPoolManagedItems(), this.getItemSettingsByAudit(this.auditId), this.getItemCombinations(), this.getSapQuantities(this.auditId), this.getExclusions(this.auditId)).subscribe(function (data) {
            _this.redspotQuantities = data[0];
            _this.kitDefinitionItems = data[1];
            _this.lotPoolManagedItems = data[2];
            _this.itemSettings = data[3];
            _this.itemCombinations = data[4];
            _this.sapQuantities = data[5];
            _this.exclusions = data[6];
            _this._databaseService.add('user_actions', {
                audit_id: _this.audit.id.toString(),
                auditor: _this.userData.first_name + " " + _this.userData.last_name,
                timestamp: "" + (new Date()).toUTCString(),
                area: "" + row.data.area,
                kit_reference: '',
                kit_lot_number: '',
                action: 'Printed count sheet',
                total_items_counted: row.data.total_items_counted,
                site_id: _this.siteId,
                warehouse_id: row.data.warehouse_id
            });
            _this._recallOracleService.prime(_this.audit.id, { itemCombinations: _this.itemCombinations }).then(function (_) {
                _this.onlineSubscription = _this._onlineService.isOnlineSubscription().subscribe(function (online) {
                    if (online && !_this._onlineService.getTestOfflineBool()) {
                        zip(_this.getCountSheetItems(true, _this.auditId, row)).subscribe(function (countsheetItemsData) {
                            var csis = countsheetItemsData[0];
                            var countsheetItems = _this.countSheetHelper.getDataSourceData(row, csis, _this.lotPoolManagedItems, _this.redspotQuantities, _this.sapQuantities, _this.itemSettings, _this.itemCombinations, _this._recallOracleService, _this.kitDefinitionItems, _this.exclusions, _this.filters);
                            var printOptions = {
                                isKitted: false,
                                warehouseName: row.data.warehouse_name,
                                locationName: _this.location.location_name,
                                lotNumber: row.data.lot_number,
                                statusText: row.data.status_text,
                                agencyName: _this.audit.agency_name,
                                reference: row.data.reference,
                                area: row.data.area,
                                countSheet: row,
                                redspotQuantities: _this.redspotQuantities,
                                lotPoolManagedItems: _this.lotPoolManagedItems,
                                kitDefinitionItems: _this.kitDefinitionItems,
                                filters: _this.filters,
                                itemSettings: _this.itemSettings,
                                exclusions: _this.exclusions
                            };
                            _this.printHelper.print(countsheetItems, printOptions);
                        });
                    }
                    else {
                        zip(_this.getCountSheetItems(false, _this.auditId, row)).subscribe(function (countsheetItemsData) {
                            var csis = countsheetItemsData[0];
                            var countsheetItems = _this.countSheetHelper.getDataSourceData(row, csis, _this.lotPoolManagedItems, _this.redspotQuantities, _this.sapQuantities, _this.itemSettings, _this.itemCombinations, _this._recallOracleService, _this.kitDefinitionItems, _this.exclusions, _this.filters);
                            var printOptions = {
                                isKitted: false,
                                warehouseName: row.data.warehouse_name,
                                locationName: _this.location.location_name,
                                lotNumber: row.data.lot_number,
                                statusText: row.data.status_text,
                                agencyName: _this.audit.agency_name,
                                reference: row.data.reference,
                                area: row.data.area,
                                countSheet: row,
                                redspotQuantities: _this.redspotQuantities,
                                lotPoolManagedItems: _this.lotPoolManagedItems,
                                kitDefinitionItems: _this.kitDefinitionItems,
                                filters: _this.filters,
                                itemSettings: _this.itemSettings,
                                exclusions: _this.exclusions
                            };
                            _this.printHelper.print(countsheetItems, printOptions);
                        });
                    }
                });
                _this.onlineSubscription.unsubscribe();
            });
        });
    };
    return UnkittedCountsheetComponent;
}());
export { UnkittedCountsheetComponent };
