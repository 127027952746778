<div class="sap-item-table">
  <div class="row">

    <div class="col-12">
      <mat-button-toggle-group [value]="viewMode" (change)="changeData(group.value)" #group="matButtonToggleGroup">
        <mat-button-toggle class="counted" value="counted">
          Overall Count ({{lengths.counted}})
        </mat-button-toggle>
        <mat-button-toggle class="overage" value="overage">
          Overage Qty ({{lengths.overages}})
        </mat-button-toggle>
        <mat-button-toggle class="shortage" value="shortage">
          Shortage Qty ({{lengths.shortages}})
        </mat-button-toggle>
      </mat-button-toggle-group>
    
      <mat-form-field>
        <input [(ngModel)]="filters.kitSearchString" (keyup)="changeData(group.value)" matInput placeholder="Search By Reference">
      </mat-form-field>
    </div>
  </div>


  <div class="mat-elevation-z8">
    <div class="col-md-12">
      <mat-icon class="xls pull-right" (click)="exportToExcel()" ngbTooltip="Save as XLS" placement="top" [ngClass]="{
          'overage-v': viewMode == 'overage',
          'shortage-v': viewMode == 'shortage',
          'neither': viewMode == 'counted'
        }">save_alt</mat-icon>
      <mat-paginator #paginator [pageIndex]="0" [pageSize]="50" [pageSizeOptions]="[25, 50, 100, 250]">
      </mat-paginator>
    </div>



    <table mat-table #table [dataSource]="dataSource" matSort aria-label="Elements">
      <!-- Part Number Column -->
      <ng-container matColumnDef="part_number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Reference</th>
        <td mat-cell *matCellDef="let row">{{row.item.reference}}</td>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let row">{{row.item.description}}</td>
      </ng-container>

      <!-- Lot Number Column -->
      <ng-container matColumnDef="lot_number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Lot Number</th>
        <td mat-cell *matCellDef="let row">{{row.lot_number}}</td>
      </ng-container>

      <!-- serial Number Column -->
      <ng-container matColumnDef="serial_number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Serial Number</th>
        <td mat-cell *matCellDef="let row">{{row.serial_number}}</td>
      </ng-container>

      <!-- Exp. Quant. Column -->
      <ng-container matColumnDef="expected_quantity">
        <th mat-header-cell class="quantity-header" *matHeaderCellDef mat-sort-header>Expected Quantity</th>
        <td mat-cell class="quantity-field" *matCellDef="let row">{{row.expected_quantity}}</td>
      </ng-container>

      <!-- Actuan Quant. Column -->
      <ng-container matColumnDef="actual_quantity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="quantity-header">Actual Quantity</th>
        <td mat-cell class="quantity-field" *matCellDef="let row">{{row.actual_quantity}}</td>
      </ng-container>

      <!-- overage Column -->
      <ng-container matColumnDef="overage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="quantity-header">Overage Amount</th>
        <td mat-cell *matCellDef="let row" class="quantity-field" [ngClass]="{'overage-v': (row.actual_quantity - row.expected_quantity) > 0 }">
          {{ (row.actual_quantity - row.expected_quantity) > 0 ? (row.actual_quantity - row.expected_quantity) :  "&mdash;" }}
        </td>
      </ng-container>
      
      <!-- shortage Column -->
      <ng-container matColumnDef="shortage">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="quantity-header">Shortage Amount</th>
          <td mat-cell *matCellDef="let row" class="quantity-field" [ngClass]="{'shortage-v': (row.actual_quantity - row.expected_quantity) < 0}">
            {{ (row.actual_quantity - row.expected_quantity) < 0 ? (row.actual_quantity - row.expected_quantity) :  "&mdash;"}}
          </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
