/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./retry-fetching-audit-count-sheet-data.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./retry-fetching-audit-count-sheet-data.component";
import * as i9 from "../../services/count-sheet.service";
import * as i10 from "../../services/count-sheet-item.service";
import * as i11 from "../../services/audit-kit-instance.service";
import * as i12 from "../../services/count-sheet-attachment.service";
var styles_RetryFetchingAuditCountSheetDataComponent = [i0.styles];
var RenderType_RetryFetchingAuditCountSheetDataComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RetryFetchingAuditCountSheetDataComponent, data: {} });
export { RenderType_RetryFetchingAuditCountSheetDataComponent as RenderType_RetryFetchingAuditCountSheetDataComponent };
export function View_RetryFetchingAuditCountSheetDataComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Sync Problem"])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Fetching count sheet data did not work correctly, please make sure you have a stable internet connection and try again."])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refetch() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Retry"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 10).disabled || null); var currVal_2 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_1, currVal_2); }); }
export function View_RetryFetchingAuditCountSheetDataComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-retry-fetching-audit-count-sheet-data", [], null, null, null, View_RetryFetchingAuditCountSheetDataComponent_0, RenderType_RetryFetchingAuditCountSheetDataComponent)), i1.ɵdid(1, 114688, null, 0, i8.RetryFetchingAuditCountSheetDataComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA, i9.CountSheetService, i10.CountSheetItemService, i11.AuditKitInstanceService, i12.CountSheetAttachmentService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RetryFetchingAuditCountSheetDataComponentNgFactory = i1.ɵccf("app-retry-fetching-audit-count-sheet-data", i8.RetryFetchingAuditCountSheetDataComponent, View_RetryFetchingAuditCountSheetDataComponent_Host_0, {}, {}, []);
export { RetryFetchingAuditCountSheetDataComponentNgFactory as RetryFetchingAuditCountSheetDataComponentNgFactory };
