import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { zip } from 'rxjs';
import Dexie from 'dexie';

// Subclass it
class DexieDatabase extends Dexie {

    constructor () {
      super("Audit");
      this.version(1).stores({
        audits: "id,status",
        audit_kit_instances: 'dbId,data.item_instance_id,data.item_instance.item_id,data.audit_id,data.warehouse_id',
        user_actions: '++id,audit_id',
        count_sheets: 'id,isSynchronized,data.audit_id,data.audit_kit_instance_id',
        count_sheet_items: 'id,data.count_sheet_client_id,data.count_sheet_id,data.audit_id,isSynchronized',
        attachments: 'local_file_id,count_sheet_client_id,count_sheet_id,audit_id,synchronized',
        files: 'local_file_id'
      });
    }
}

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  unsynchronizedDataCountEmitter: EventEmitter<any>;
  timeoutIds = {};
  _db = new DexieDatabase();

  constructor() {
    this.unsynchronizedDataCountEmitter = new EventEmitter<any>();
  }

  clear(storeName) {
    const that = this;
    return new Promise((resolve, reject) => {
      this._db.table(storeName).clear().then(() => {
        resolve(true);
      });
    }).then((result) => {
      that.notifyUnsynchedDataChanged(storeName);
      return result;
    });
  }

  get(storeName, primaryKey) {
    return new Promise((resolve, reject) => {
      this._db.table(storeName).get(primaryKey).then((data) => {
        resolve(data);
      });
    });
  }

  getDataByIndex(storeName, index, value) {
    return new Promise((resolve, reject) => {
      const collection = this._db.table(storeName).where(index).equals(value);
      collection.toArray((values) => {
        resolve(values);
      });
    });
  }

  delete(storeName, key) {
    const that = this;
    return new Promise((resolve, reject) => {
      that._db.table(storeName).delete(key).then(() => {
        resolve(true);
      });
    }).then((result) => {
      that.notifyUnsynchedDataChanged(storeName);
      return result;
    });
  }

  //make sure the value matches exactly ( "1" isn't the same as 1 )
  deleteByIndex(storeName, index, value) {
    const that = this;
    const startAt = new Date();
    console.log('deleteByIndex for ' + storeName + ' started');
    return new Promise((resolve, reject) => {
      const collection = this._db.table(storeName).where(index).equals(value);
      collection.delete().then(() => {
        resolve(true);
      });
    }).then(result => {
      this.notifyUnsynchedDataChanged(storeName);
      console.log('deleteByIndex for ' + storeName + ' took:', (new Date()).getTime() - startAt.getTime());
      return result;
    });
  }

  update(storeName, data) {
    const that = this;
    return new Promise((resolve, reject) => {
      this._db.table(storeName).put(data).then((result) => {
        resolve(result);
      });
    }).then(data => {
      this.notifyUnsynchedDataChanged(storeName);
      return data;
    });
  }

  add(storeName, data) {
    const that = this;
    return new Promise((resolve, reject) => {
      this._db.table(storeName).put(data).then((result) => {
        resolve(result);
      });
    }).then((data) => {
      that.notifyUnsynchedDataChanged(storeName);
      return data;
    });
  }

  bulkAdd(storeName, data) {
    const startAt = new Date();
    console.log('bulkAdd for ' + storeName + ' started');
    return new Promise((resolve, reject) => {
      this._db.table(storeName).bulkPut(data).then(() => {
        console.log('bulkAdd for ' + storeName + ' took:', (new Date()).getTime() - startAt.getTime());
        resolve(true);
      });
    });
  }

  notifyUnsynchedDataChanged(storeName) {
    const that = this;
    clearTimeout(that.timeoutIds[storeName]);
if (['count_sheets', 'count_sheet_items'].indexOf(storeName) !== -1) {
      that.timeoutIds[storeName] = setTimeout(function () {
        new Promise((resolve, reject) => {
          const collection = that._db.table(storeName).where('isSynchronized').equals(0);
          collection.count((value) => {
            resolve(value);
          });
        }).then((count) => {
          that.unsynchronizedDataCountEmitter.emit([storeName, count]);
        });
      }, 1000);
    }
    //in its own section because the index is synchronized vs isSynchronized
    if (storeName === "attachments") {
      that.timeoutIds[storeName] = setTimeout(function () {
        new Promise((resolve, reject) => {
          const collection = that._db.table(storeName).where('synchronized').equals(0);
          collection.count((value) => {
            resolve(value);
          });
        }).then((count) => {
          that.unsynchronizedDataCountEmitter.emit([storeName, count]);
        });
      }, 1000);
    }
  }
}
