import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { urls } from '../helpers/urls';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ItemCombinationRecallAlertService = /** @class */ (function () {
    function ItemCombinationRecallAlertService(http) {
        this.http = http;
    }
    ItemCombinationRecallAlertService.prototype.get = function () {
        return this.http.get("" + environment.serverPath + urls.getManufacturerItemCombinationRecallAlerts);
    };
    ItemCombinationRecallAlertService.ngInjectableDef = i0.defineInjectable({ factory: function ItemCombinationRecallAlertService_Factory() { return new ItemCombinationRecallAlertService(i0.inject(i1.HttpClient)); }, token: ItemCombinationRecallAlertService, providedIn: "root" });
    return ItemCombinationRecallAlertService;
}());
export { ItemCombinationRecallAlertService };
