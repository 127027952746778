var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DataSource } from '@angular/cdk/collections';
import { map } from 'rxjs/operators';
import { of as observableOf, merge } from 'rxjs';
/**
 * Data source for the AuditKitInstancesList view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
var AuditKitInstancesListDataSource = /** @class */ (function (_super) {
    __extends(AuditKitInstancesListDataSource, _super);
    function AuditKitInstancesListDataSource(paginator, sort, _auditKitInstances, filters) {
        var _this = _super.call(this) || this;
        _this.paginator = paginator;
        _this.sort = sort;
        _this._auditKitInstances = _auditKitInstances;
        _this.filters = filters;
        _this.data = [];
        _this.dataAfterFilter = [];
        _this.data = _this._auditKitInstances;
        return _this;
    }
    /**
     * Connect this data source to the table. The table will only update when
     * the returned stream emits new items.
     * @returns A stream of the items to be rendered.
     */
    AuditKitInstancesListDataSource.prototype.connect = function () {
        var _this = this;
        // Combine everything that affects the rendered data into one update
        // stream for the data-table to consume.
        var dataMutations = [
            observableOf(this.data),
            this.paginator.page,
            this.sort.sortChange
        ];
        // Set the paginators length
        this.paginator.length = this.data.length;
        return merge.apply(void 0, dataMutations).pipe(map(function () {
            var filteredData = _this.getPagedData(_this.getSortedData(_this.getFilteredData(_this.data.slice())));
            _this.dataAfterFilter = filteredData;
            return filteredData;
        }));
    };
    /**
     *  Called when the table is being destroyed. Use this function, to clean up
     * any open connections or free any held resources that were set up during connect.
     */
    AuditKitInstancesListDataSource.prototype.disconnect = function () {
    };
    /**
     * Paginate the data (client-side). If you're using server-side pagination,
     * this would be replaced by requesting the appropriate data from the server.
     */
    AuditKitInstancesListDataSource.prototype.getPagedData = function (data) {
        var startIndex = this.paginator.pageIndex * this.paginator.pageSize;
        return data.splice(startIndex, this.paginator.pageSize);
    };
    /**
     * Sort the data (client-side). If you're using server-side sorting,
     * this would be replaced by requesting the appropriate data from the server.
     */
    AuditKitInstancesListDataSource.prototype.getSortedData = function (data) {
        var _this = this;
        if (!this.sort.active) {
            return data;
        }
        return data.sort(function (a, b) {
            var isAsc = _this.sort.direction === 'asc';
            switch (_this.sort.active) {
                case 'reference': return compare(a.data.item_instance.reference.toLowerCase(), b.data.item_instance.reference.toLowerCase(), isAsc);
                case 'description': return compare(a.data.item_instance.description.toLowerCase(), b.data.item_instance.description.toLowerCase(), isAsc);
                case 'lot_number': return compare((a.data.item_instance.lot_number || '').toLowerCase(), (b.data.item_instance.lot_number || '').toLowerCase(), isAsc);
                case 'item_count': return compare(+a.data.item_count, +b.data.item_count, isAsc);
                case 'current_count': return compare(a.data.count_sheet ? +a.data.count_sheet.data.total_items_counted : -1, b.data.count_sheet ? +b.data.count_sheet.data.total_items_counted : -1, isAsc);
                case 'warehouse': return compare(a.data.warehouse_name.toLowerCase(), b.data.warehouse_name.toLowerCase(), isAsc);
                case 'site': return compare((((a.data.count_sheet || {}).data || {}).location_name || '').toLowerCase(), (((b.data.count_sheet || {}).data || {}).location_name || '').toLowerCase(), isAsc);
                case 'status': return compare(a.data.status, b.data.status, isAsc);
                default: return 0;
            }
        });
    };
    AuditKitInstancesListDataSource.prototype.filteredData = function () {
        return this.getFilteredData(this.data);
    };
    AuditKitInstancesListDataSource.prototype.getFilteredData = function (data) {
        var _this = this;
        var filteredData = data.filter(function (el) {
            var condition = true;
            if (_this.filters.kitSearchString) {
                condition = el.data.item_instance.reference.toLowerCase().includes(_this.filters.kitSearchString.toLowerCase()) ||
                    el.data.item_instance.lot_number.toLowerCase().includes(_this.filters.kitSearchString.toLowerCase()) ||
                    el.data.item_instance.description.toLowerCase().includes(_this.filters.kitSearchString.toLowerCase()) ||
                    el.data.item_instance.unpunctuated_reference.toLowerCase().includes(_this.filters.kitSearchString.toLowerCase());
                if (el.data.counted_by) {
                    condition = condition || el.data.counted_by.name.toLowerCase().includes(_this.filters.kitSearchString.toLowerCase());
                }
            }
            if (_this.filters.status && _this.filters.status !== 'All') {
                condition = condition && el.data.statusText.toLowerCase().startsWith(_this.filters.status.toLowerCase());
            }
            if (_this.filters.recall_status && _this.filters.recall_status !== 'All') {
                condition = condition && (_this.filters.recall_status === 'Recalled' && el.data.count_sheet && el.data.count_sheet.data.has_recalled_items || _this.filters.recall_status === 'Not Recalled' && (!el.data.count_sheet || !el.data.count_sheet.data.has_recalled_items));
            }
            if (_this.filters.orderType && _this.filters.orderType !== 'all') {
                condition = condition && (_this.filters.orderType === 'consigned' && el.data.has_consigned_items || _this.filters.orderType === 'owned' && el.data.has_owned_items);
            }
            if (_this.filters.validity_status && _this.filters.validity_status !== 'All') {
                var allValid = !el.data.count_sheet || !el.data.count_sheet.data.has_invalid_items;
                var SomeInvalid = el.data.count_sheet && el.data.count_sheet.data.has_invalid_items;
                condition = condition && (_this.filters.validity_status === 'All Valid' && allValid || _this.filters.validity_status === 'Some Invalid' && SomeInvalid);
            }
            if (_this.filters.auditor && _this.filters.auditor !== 'all') {
                condition = condition && el.data.count_sheet && el.data.count_sheet.data.created_by_id.toString() === _this.filters.auditor.toString();
            }
            if (_this.filters.site && _this.filters.site !== 'all') {
                condition = condition && el.data.count_sheet && el.data.count_sheet.data.audit_location_id.toString() === _this.filters.site.toString();
            }
            if (_this.filters.warehouse && !['all', 'All'].includes(_this.filters.warehouse)) {
                if (el.data.count_sheet) {
                    condition = condition && el.data.count_sheet.data.warehouse_id.toString() === _this.filters.warehouse.toString();
                }
                else {
                    condition = condition && el.data.warehouse_id.toString() === _this.filters.warehouse.toString();
                }
            }
            return condition;
        });
        this.paginator.length = filteredData.length;
        return filteredData;
    };
    AuditKitInstancesListDataSource.prototype.getDataForExport = function () {
        return this.getSortedData(this.getFilteredData(this.data.slice()));
    };
    return AuditKitInstancesListDataSource;
}(DataSource));
export { AuditKitInstancesListDataSource };
function compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
