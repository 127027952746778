<div class="mat-elevation-z8 data-table">
  <div class="row">
    <div class="col-md-12">
      <button mat-raised-button class="back" type="button" (click)="goToAuditDashboard()" ngbTooltip="Go To Dashboard"
        placement="right">
        <mat-icon>dashboard</mat-icon>
        <span>Dashboard</span>
      </button>
    </div>
  </div>
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="reference">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Reference</th>
      <td mat-cell *matCellDef="let row">{{row.item.reference}}</td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Description</th>
      <td mat-cell *matCellDef="let row">{{row.item.description}}</td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header"></th>
      <td mat-cell *matCellDef="let row">
        <button [ngClass]="{'disabled': !isOnline || !currentAudit || currentAudit.status === 'Completed' }" mat-icon-button (click)="deleteExclusion(row.id)" *ngxPermissionsOnly="['audit_administrator']">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator #paginator [pageSizeOptions]="[20, 50]">
  </mat-paginator>

  <form #exclusionForm="ngForm" (ngSubmit)="createExclusion();" *ngxPermissionsOnly="['audit_administrator']">
    <app-reference-field [ngClass]="{'disabled': !isOnline || !currentAudit || currentAudit.status === 'Completed' }"></app-reference-field>
    <button type="submit" [ngClass]="{'disabled': !isOnline || !currentAudit || currentAudit.status === 'Completed' }" mat-button>Add</button>
    <button type="button" [ngClass]="{'disabled': !isOnline || !currentAudit || currentAudit.status === 'Completed' }" (click)="openExclusionDialog()" mat-button>Import Excel</button>
  </form>
</div>
