import { DatabaseService } from './database.service';
import { zip } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./database.service";
var CountSheetItemDatabaseService = /** @class */ (function () {
    function CountSheetItemDatabaseService(_databaseService) {
        this._databaseService = _databaseService;
        this.storeName = "count_sheet_items";
        this.rank = 1;
    }
    CountSheetItemDatabaseService.prototype.new = function (data) {
        return {
            dbId: data.id,
            isSynchronized: 1,
            _destroy: false,
            id: data.client_id,
            data: {
                is_consigned: data.item.is_consigned,
                reference: data.item.reference,
                description: data.item.description,
                lot_number: data.lot_number,
                serial: data.serial_number,
                quantity: data.quantity,
                isKitted: false,
                expiration_date: data.expiration_date,
                item_id: data.item_id,
                audit_id: data.audit_id,
                count_sheet_id: data.count_sheet_id,
                count_sheet_client_id: data.count_sheet_client_id,
                min_order_quantity: data.item.minimum_order_quantity,
                is_lot_tracked: data.item.is_lot_number_tracked,
                is_serial_tracked: data.item.is_serial_tracked,
                modified_by: data.modified_by.name,
                modified_at: data.modified_at,
                checked: data.checked,
                manually_entered: data.manually_entered,
                hide: data.hide,
                rank: data.rank
            }
        };
    };
    CountSheetItemDatabaseService.prototype.get = function (clientId) {
        return this._databaseService.get(this.storeName, clientId);
    };
    CountSheetItemDatabaseService.prototype.bulkAdd = function (datas) {
        var that = this;
        return new Promise(function (resolve, reject) {
            if (datas.length === 0) {
                return resolve(null);
            }
            that.rank = Math.max.apply(Math, datas.map(function (d) {
                if (d.data.rank) {
                    return d.data.rank;
                }
                return 0;
            }).concat([that.rank]));
            var countSheetItems = datas.map(function (data) {
                if (!data.id || data.id === "0") {
                    data.id = that.createClientId();
                }
                if (!data.data.rank) {
                    data.data.rank = (that.rank += 1);
                }
                return data;
            });
            that._databaseService.bulkAdd(that.storeName, countSheetItems).then(function () {
                return resolve(true);
            });
        });
    };
    CountSheetItemDatabaseService.prototype.add = function (data) {
        var _this = this;
        if (!data.id) {
            data.id = this.createClientId();
        }
        if (!data.data.rank) {
            data.data.rank = (this.rank += 1);
        }
        else {
            this.rank = Math.max(this.rank, data.data.rank);
        }
        var countSheetClientId = data.data.count_sheet_client_id;
        var auditId = data.data.audit_id;
        var that = this;
        var syncJustAdded = data.data.justAdded;
        return new Promise(function (resolve, reject) {
            _this._databaseService.add(_this.storeName, data).then(function (_) {
                var promises = [];
                promises.push(that.denormalizeCountSheet(auditId, countSheetClientId));
                if (syncJustAdded) {
                    promises.push(that.syncJustAdded(data));
                }
                zip.apply(void 0, promises).subscribe(function (_) {
                    resolve(data.id);
                });
            });
        });
    };
    CountSheetItemDatabaseService.prototype.bulkUpdate = function (datas) {
        var that = this;
        return new Promise(function (resolve, reject) {
            that.rank = Math.max.apply(Math, datas.map(function (d) {
                if (d.data.rank) {
                    return d.data.rank;
                }
                return 0;
            }).concat([that.rank]));
            var promises = datas.map(function (data) {
                if (!data.id) {
                    console.log(data);
                    throw new Error('how can you update without a primary key?');
                }
                if (!data.data.rank) {
                    data.data.rank = (that.rank += 1);
                }
                return that._databaseService.update(that.storeName, data);
            });
            zip.apply(void 0, promises.concat(new Promise(function (r, a) { r(null); }))).subscribe(function (_) {
                resolve(_);
            });
        });
    };
    CountSheetItemDatabaseService.prototype.update = function (data) {
        if (!data.id) {
            console.log(data);
            throw new Error('how can you update without a primary key?');
        }
        if (!data.data.rank) {
            data.data.rank = (this.rank += 1);
        }
        else {
            this.rank = Math.max(this.rank, data.data.rank);
        }
        var countSheetClientId = data.data.count_sheet_client_id;
        var auditId = data.data.audit_id;
        var that = this;
        return this._databaseService.update(this.storeName, data).then(function (data) {
            that.denormalizeCountSheet(auditId, countSheetClientId);
            return data;
        });
    };
    CountSheetItemDatabaseService.prototype.delete = function (key) {
        return this._databaseService.delete(this.storeName, key);
    };
    CountSheetItemDatabaseService.prototype.deleteByIndex = function (index, value) {
        return this._databaseService.deleteByIndex(this.storeName, index, value);
    };
    CountSheetItemDatabaseService.prototype.getDataByIndex = function (index, value) {
        return this._databaseService.getDataByIndex(this.storeName, index, value);
    };
    CountSheetItemDatabaseService.prototype.find = function (auditId, params) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this._databaseService.getDataByIndex(_this.storeName, 'data.audit_id', parseInt(auditId, 10)).then(function (matches) {
                if (params.count_sheet_id) {
                    matches = matches.filter(function (csi) {
                        return parseInt(csi.data.count_sheet_id, 10) === parseInt(params.count_sheet_id, 10);
                    });
                }
                if (params.count_sheet_client_id) {
                    matches = matches.filter(function (csi) {
                        return csi.data.count_sheet_client_id === params.count_sheet_client_id;
                    });
                }
                if (params.isSynchronized !== undefined) {
                    matches = matches.filter(function (csi) {
                        return !!csi.isSynchronized === !!params.isSynchronized;
                    });
                }
                if (params.checked !== undefined) {
                    matches = matches.filter(function (csi) {
                        return !!csi.data.checked === !!params.checked;
                    });
                }
                if (params._destroy !== undefined) {
                    matches = matches.filter(function (csi) {
                        return !!csi._destroy === !!params._destroy;
                    });
                }
                if (params.reference) {
                    matches = matches.filter(function (csi) {
                        return csi.data.reference.toLowerCase() === params.reference.toLowerCase();
                    });
                }
                if (params.lot_number) {
                    matches = matches.filter(function (csi) {
                        return (csi.data.lot_number || '').toString().toLowerCase() === params.lot_number.toString().toLowerCase();
                    });
                }
                if (params.serial) {
                    matches = matches.filter(function (csi) {
                        return (csi.data.serial || '').toString().toLowerCase() === params.serial.toString().toLowerCase();
                    });
                }
                if (params.quantity_greater_than) {
                    matches = matches.filter(function (csi) {
                        return csi.data.quantity > params.quantity_greater_than;
                    });
                }
                resolve(matches);
            });
        });
    };
    CountSheetItemDatabaseService.prototype.clear = function () {
        return this._databaseService.clear(this.storeName);
    };
    CountSheetItemDatabaseService.prototype.createClientId = function () {
        return "1" + Math.random().toString().substring(2, 10) + Math.random().toString().substring(2, 10);
    };
    CountSheetItemDatabaseService.prototype.syncJustAdded = function (countSheetItem) {
        var that = this;
        return new Promise(function (resolve, reject) {
            that.find(countSheetItem.data.audit_id, { count_sheet_client_id: countSheetItem.data.count_sheet_client_id, _destroy: false }).then(function (csis) {
                var oldJustAdded = csis.filter(function (csi) { return csi.data.justAdded && parseInt(csi.data.item_id, 10) !== parseInt(countSheetItem.data.item_id, 10); });
                if (oldJustAdded.length === 0) {
                    return resolve(null);
                }
                var promises = [new Promise(function (r, a) { r(null); })];
                oldJustAdded.forEach(function (csi) {
                    csi.data.justAdded = false;
                    promises.push(that._databaseService.update(that.storeName, csi));
                });
                zip.apply(void 0, promises).subscribe(function (_) {
                    return resolve(null);
                });
            });
        });
    };
    ;
    CountSheetItemDatabaseService.prototype.denormalizeCountSheet = function (auditId, countSheetClientId) {
        var that = this;
        return new Promise(function (resolve, reject) {
            that.find(auditId, { count_sheet_client_id: countSheetClientId, _destroy: false }).then(function (csis) {
                that._databaseService.get('count_sheets', countSheetClientId).then(function (cs) {
                    var count = 0;
                    var synched = cs.isSynchronized;
                    csis.forEach(function (csi) {
                        if (!csi.isSynchronized) {
                            synched = 0;
                        }
                        if (csi.data.checked) {
                            count = count + csi.data.quantity;
                        }
                    });
                    cs.data.total_items_counted = count;
                    cs.isSynchronized = synched;
                    that._databaseService.update('count_sheets', cs).then(function (_) {
                        resolve(null);
                    });
                });
            });
        });
    };
    CountSheetItemDatabaseService.ngInjectableDef = i0.defineInjectable({ factory: function CountSheetItemDatabaseService_Factory() { return new CountSheetItemDatabaseService(i0.inject(i1.DatabaseService)); }, token: CountSheetItemDatabaseService, providedIn: "root" });
    return CountSheetItemDatabaseService;
}());
export { CountSheetItemDatabaseService };
