import { IkitInstance } from '../interfaces/ikit-instance';
import * as moment from 'moment';
import { UserDataHelper } from './user-data-helper';
import { IcountsheetItemData, ILotPoolManagedCountsheetItemGroup } from '../interfaces/icountsheet-item';
import { CountSheetStatusHelper } from './count-sheet-status-helper';
import { ICountSheetData } from '../interfaces/icount-sheet-data';
import { CountSheetItemHelper } from './countsheet-item-helper';
import { ITableFilter } from '../interfaces/itable-filter';
import { IItemCombinationLookupOptions } from '../interfaces/i-item-combination-lookup-options';
import { RecallOracleService } from '../services/recall-oracle.service';

export class KitInstanceHelper {

  userDataHelper: UserDataHelper = new UserDataHelper();
  countSheetItemHelper: CountSheetItemHelper;
  constructor() {
    this.countSheetItemHelper = new CountSheetItemHelper();
  }

  getKitDatasourceData(countSheet: ICountSheetData, csis: IcountsheetItemData[] = [], lotPoolManagedItems: any[], redspotQuantities: any, sapQuantities: any, itemCombinations: any, recallOracleService: RecallOracleService, rootKitDefinitionItems: any, itemSettings: any, exclusions: any, filters: ITableFilter) {
    const data = this.refresh(countSheet, csis, lotPoolManagedItems, redspotQuantities, sapQuantities, itemCombinations, recallOracleService, rootKitDefinitionItems, itemSettings, exclusions, filters);
    return data;
  }

  matchingKitExpectedItem(csi: IcountsheetItemData, redspotQuantities: any) {
    return redspotQuantities.find(function (rq) {
      const itemIdMatch = rq.item_id === csi.data.item_id;
      const lotNumberMatch = (rq.lot_number.value || '').toLowerCase() === (csi.data.lot_number || '').toLowerCase();
      const serialNumberMatch = '' === (csi.data.serial || '').toLowerCase();
      return itemIdMatch && lotNumberMatch && serialNumberMatch;
    });
  }

  matchingRootKitDefinitionItem(itemId, rootKitDefinitionItems: any) {
    return rootKitDefinitionItems.find(function (kdi) {
      return kdi.item_id === itemId;
    });
  }

  fillFunction(data: any, exclusions: any[], redspotQuantities: any[], sapQuantities: any[], rootKitDefinitionItems: any[], itemSettings: any[], itemCombinations: any[], recallOracleService: RecallOracleService) {
    data.map((l) => {
      const now = new Date();
      if (l.count_sheet_items) {
        l.data.added_to_kit = l.available_redspot_inventory.length === 0 && !this.countSheetItemHelper.matchingRootKitDefinitionItem(l.data.item_id, rootKitDefinitionItems);
        l.data.is_excluded = this.countSheetItemHelper.getExclusion(l.data.item_id, exclusions);
        l.data.in_definition = !!this.countSheetItemHelper.matchingRootKitDefinitionItem(l.data.item_id, rootKitDefinitionItems);
        l.data.show_recalled_warning = recallOracleService.get(l.data.item_id, null, null);
      } else {
        if (l.data.checked) {
          if (l.data.show_expired_warning === undefined || l.data.show_expired_warning === null) {
            if (!l.data.expiration_date) {
              const lookupOpt: IItemCombinationLookupOptions = { itemId: l.data.item_id, lotNumber: l.data.lot_number, serialNumber: l.data.serial };
              const sqMatch = this.countSheetItemHelper.getSapQuantity(lookupOpt, sapQuantities);
              if (sqMatch) {
                l.data.expiration_date = sqMatch.expiry_date;
              } else {
                l.data.expiration_date = l.data.redspot_expiration_date;
              }
              if (l.data.expiration_date) {
                l.data.show_expired_warning = new Date(l.data.expiration_date) < now ? true : false;
              } else {
                l.data.show_expired_warning = false;
              }
            } else {
              l.data.show_expired_warning = new Date(l.data.expiration_date) < now ? true : false;
            }
          }

          if (l.data.show_recalled_warning === undefined) {
            l.data.show_recalled_warning = recallOracleService.get(l.data.item_id, l.data.lot_number, l.data.serial);
          }

          if (l.data.show_invalid_warning === undefined) {
            l.data.show_invalid_warning = !this.countSheetItemHelper.checkIfValidItemCombination(l.data.item_id, l.data.lot_number, l.data.serial, false, l.data.is_lot_tracked, l.data.is_serial_tracked, itemSettings, itemCombinations);
          }
        }
        l.data.expected_quantity = l.data.expected_quantity || 0;
        l.data.in_definition = !!this.countSheetItemHelper.matchingRootKitDefinitionItem(l.data.item_id, rootKitDefinitionItems);
        l.data.is_excluded = this.countSheetItemHelper.getExclusion(l.data.item_id, exclusions);

        if (l.id || l.dbId) {
          l.data.added_to_kit = !this.matchingKitExpectedItem(l, redspotQuantities) && !this.countSheetItemHelper.matchingRootKitDefinitionItem(l.data.item_id, rootKitDefinitionItems);
        }
      }
    });
    return data;
  }

  refresh(countSheet: ICountSheetData, csis: IcountsheetItemData[] = [], lotPoolManagedItems: any[], redspotQuantities: any, sapQuantities: any, itemCombinations: any, recallOracleService: RecallOracleService, rootKitDefinitionItems: any, itemSettings: any, exclusions: any, filters: ITableFilter) {
    let data = [];
    const that = this;
    const lotPoolManagedItemIds = lotPoolManagedItems.map(lpmi => lpmi.item_id);
    let currentCountSheetItems = (csis || []).slice().filter(csi => !csi._destroy);

    const filterFunction = function (unfilteredData: IcountsheetItemData) {
      let orderTypeMatches = true;
      let exclusionMatches = true;
      if (filters['orderType'] !== 'all') {
        const itemSetting = that.countSheetItemHelper.getItemSettings(unfilteredData.data.item_id, itemSettings);
        orderTypeMatches = (filters['orderType'] === 'owned' && !(itemSetting || unfilteredData.data).is_consigned) || (filters['orderType'] === 'consigned' && (itemSetting || unfilteredData.data).is_consigned);
      }
      if (filters['exclusions'] === 'hide') {
        exclusionMatches = !unfilteredData.data.is_excluded;
      }
      return orderTypeMatches && exclusionMatches;
    };

    const sortFunction = function (a: IcountsheetItemData, b: IcountsheetItemData) {
      const aMatch = that.countSheetItemHelper.matchingRootKitDefinitionItem(a.data.item_id, rootKitDefinitionItems);
      const bMatch = that.countSheetItemHelper.matchingRootKitDefinitionItem(b.data.item_id, rootKitDefinitionItems);

      if (aMatch && bMatch) {
        if (aMatch['position'] > bMatch['position']) {
          return 1;
        } else if (aMatch['position'] < bMatch['position']) {
          return -1;
        } else {
          return 0;
        }
      } else if (aMatch) {
        return -1;
      } else if (bMatch) {
        return 1;
      } else if (a.data.reference.toLowerCase() > b.data.reference.toLowerCase()) {
        return 1;
      } else if (a.data.reference.toLowerCase() < b.data.reference.toLowerCase()) {
        return -1;
      } else if (a.data.reference.toLowerCase() === b.data.reference.toLowerCase()) {
        if (a.data.expected_quantity < b.data.expected_quantity) {
          return 1;
        } else if (a.data.expected_quantity > b.data.expected_quantity) {
          return -1;
        } else {
          if (a.data.rank > b.data.rank) {
            return 1;
          } else if (a.data.rank < b.data.rank) {
            return -1;
          }
        }

      } else {
        return 0;
      }
    };

    if (countSheet) {
      if (countSheet.data.show_expected_item_list) {
        const lotPoolManagedCountSheetItems = currentCountSheetItems.filter(csi => lotPoolManagedItemIds.indexOf(csi.data.item_id) !== -1);
        const notLotPoolManagedCountSheetItems = currentCountSheetItems.filter(csi => lotPoolManagedItemIds.indexOf(csi.data.item_id) === -1);

        const lotPoolManagedCountSheetItemGroup = [];
        lotPoolManagedCountSheetItems.filter(csi => !csi.data.hide).forEach(csi => {
          let match: ILotPoolManagedCountsheetItemGroup = lotPoolManagedCountSheetItemGroup.find(csig => {
            return csig.data.item_id === csi.data.item_id;
          });
          if (!match) {
            const matchingRedspotInventory = redspotQuantities.filter(rq => rq.item_id === csi.data.item_id);
            match = {
              count_sheet_items: [],
              available_redspot_inventory: matchingRedspotInventory,
              data: {
                item_id: csi.data.item_id,
                reference: csi.data.reference,
                description: csi.data.description,
                is_consigned: csi.data.is_consigned,
                expected_quantity: matchingRedspotInventory.reduce((a, b) => a + b.quantity, 0),
                quantity: 0
              }
            };
            lotPoolManagedCountSheetItemGroup.push(match);
          }
          match.count_sheet_items.push(csi);
          if (csi.data.checked) {
            match.data.quantity += csi.data.quantity;
          }
        });

        redspotQuantities.forEach(rq => {
          if (lotPoolManagedItemIds.indexOf(rq.item_id) !== -1) {
            if (!lotPoolManagedCountSheetItemGroup.find(csig => csig.data.item_id === rq.item_id)) {
              const matchingRedspotInventory = redspotQuantities.filter(lrq => lrq.item_id === rq.item_id);
              lotPoolManagedCountSheetItemGroup.push({
                count_sheet_items: [],
                available_redspot_inventory: matchingRedspotInventory,
                data: {
                  item_id: rq.item_id,
                  reference: rq.item.reference,
                  description: rq.item.description,
                  is_consigned: rq.item.is_consigned,
                  expected_quantity: matchingRedspotInventory.reduce((a, b) => a + b.quantity, 0),
                  quantity: 0
                }
              });
            }

          } else if (lotPoolManagedItemIds.indexOf(rq.item_id) === -1) {
            const matches = notLotPoolManagedCountSheetItems.filter(function (l) {
              const itemIdMatch = l.data.item_id === rq.item_id;
              const lotNumberMatch = (l.data.lot_number || '') === (rq.lot_number.value || '');
              return itemIdMatch && lotNumberMatch;
            });

            const withSerial = matches.filter(i => {
              return i.data.serial;
            });

            const withoutSerial = matches.filter(i => {
              return !i.data.serial;
            });


            if (withSerial.length > 0) {
              let totalQty = rq.quantity;
              withSerial.forEach(function (csi) {
                if (totalQty > 0) {
                  const lowerQty = Math.min(totalQty, csi.data.quantity);
                  csi.data.redspot_expiration_date = rq.expiry_date;
                  csi.data.expected_quantity = lowerQty;
                  totalQty -= lowerQty;
                } else {
                  csi.data.expected_quantity = 0;
                }
              });

              if (withoutSerial.length > 0) {
                withoutSerial[0].data.expected_quantity = totalQty;
              } else if (totalQty > 0) {
                const match = {
                  data: {
                    item_id: rq.item.id,
                    reference: rq.item.reference,
                    description: rq.item.description,
                    lot_number: rq.lot_number.value,
                    serial: '',
                    expected_quantity: totalQty,
                    quantity: 0,
                    expiration_date: null,
                    redspot_expiration_date: rq.expiry_date,
                    count_sheet_id: 0,
                    is_consigned: rq.item.is_consigned

                  },
                  id: '',
                  dbId: 0,
                  isSynchronized: 1
                };
                notLotPoolManagedCountSheetItems.push(match);
              }

            } else if (matches.length > 0) {
              matches[0].data.expected_quantity = rq.quantity;
              matches[0].data.redspot_expiration_date = rq.expiry_date;

            } else {
              const match: IcountsheetItemData = {
                data: {
                  item_id: rq.item.id,
                  reference: rq.item.reference,
                  description: rq.item.description,
                  lot_number: rq.lot_number.value,
                  serial: '',
                  expected_quantity: rq.quantity,
                  quantity: 0,
                  expiration_date: null,
                  redspot_expiration_date: rq.expiry_date,
                  count_sheet_id: 0,
                  is_consigned: rq.item.is_consigned,
                  manually_entered: rq.item.manually_entered

                },
                id: '',
                dbId: 0,
                isSynchronized: 1
              };
              notLotPoolManagedCountSheetItems.push(match);
            }
          }
        });

        rootKitDefinitionItems.forEach(rkdi => {
          if (lotPoolManagedItemIds.indexOf(rkdi.item.id) !== -1) {
            if (!lotPoolManagedCountSheetItemGroup.find(d => d.data.item_id === rkdi.item_id)) {
              const matchingRedspotInventory = redspotQuantities.filter(rq => rq.item_id === rkdi.item_id);
              const row: ILotPoolManagedCountsheetItemGroup = {
                count_sheet_items: [],
                available_redspot_inventory: matchingRedspotInventory,
                data: {
                  item_id: rkdi.item.id,
                  reference: rkdi.item.reference,
                  description: rkdi.item.description,
                  is_consigned: rkdi.item.is_consigned,
                  expected_quantity: matchingRedspotInventory.reduce((a, b) => a + b.quantity, 0),
                  quantity: 0
                }
              };
              lotPoolManagedCountSheetItemGroup.push(row);
            }
          } else if (lotPoolManagedItemIds.indexOf(rkdi.item.id) === -1) {
            if (!notLotPoolManagedCountSheetItems.find(d => d.data.item_id === rkdi.item_id)) {
              const row: IcountsheetItemData = {
                data: {
                  item_id: rkdi.item.id,
                  reference: rkdi.item.reference,
                  description: rkdi.item.description,
                  lot_number: '',
                  serial: '',
                  expected_quantity: 0,
                  quantity: 0,
                  expiration_date: null,
                  redspot_expiration_date: null,
                  count_sheet_id: 0,
                  is_consigned: rkdi.item.is_consigned,
                  manually_entered: rkdi.item.manually_entered

                },
                id: '',
                dbId: 0,
                isSynchronized: 1
              };
              notLotPoolManagedCountSheetItems.push(row);
            }
          }
        });

        currentCountSheetItems = notLotPoolManagedCountSheetItems.concat(lotPoolManagedCountSheetItemGroup);
      }
    } else {
      const lotPoolManagedCountSheetItems = currentCountSheetItems.filter(csi => lotPoolManagedItemIds.indexOf(csi.data.item_id) !== -1);
      const notLotPoolManagedCountSheetItems = currentCountSheetItems.filter(csi => lotPoolManagedItemIds.indexOf(csi.data.item_id) === -1);

      const lotPoolManagedCountSheetItemGroup = [];
      lotPoolManagedCountSheetItems.filter(csi => !csi.data.hide).forEach(csi => {
        let match: ILotPoolManagedCountsheetItemGroup = lotPoolManagedCountSheetItemGroup.find(csig => {
          return csig.data.item_id === csi.data.item_id;
        });
        if (!match) {
          const matchingRedspotInventory = redspotQuantities.filter(rq => rq.item_id === csi.data.item_id);
          match = {
            count_sheet_items: [],
            available_redspot_inventory: matchingRedspotInventory,
            data: {
              item_id: csi.data.item_id,
              reference: csi.data.reference,
              description: csi.data.description,
              is_consigned: csi.data.is_consigned,
              expected_quantity: matchingRedspotInventory.reduce((a, b) => a + b.quantity, 0),
              quantity: 0
            }
          };
          lotPoolManagedCountSheetItemGroup.push(match);
        }
        match.count_sheet_items.push(csi);
        if (csi.data.checked) {
          match.data.quantity += csi.data.quantity;
        }
      });

      redspotQuantities.forEach(rq => {
        if (lotPoolManagedItemIds.indexOf(rq.item_id) !== -1) {
          if (!lotPoolManagedCountSheetItemGroup.find(csig => csig.data.item_id === rq.item_id)) {
            const matchingRedspotInventory = redspotQuantities.filter(lrq => lrq.item_id === rq.item_id);
            lotPoolManagedCountSheetItemGroup.push({
              count_sheet_items: [],
              available_redspot_inventory: matchingRedspotInventory,
              data: {
                item_id: rq.item_id,
                reference: rq.item.reference,
                description: rq.item.description,
                is_consigned: rq.item.is_consigned,
                expected_quantity: matchingRedspotInventory.reduce((a, b) => a + b.quantity, 0),
                quantity: 0
              }
            });
          }

        } else if (lotPoolManagedItemIds.indexOf(rq.item_id) === -1) {
          const matches = notLotPoolManagedCountSheetItems.filter(function (l) {
            const itemIdMatch = l.data.item_id === rq.item_id;
            const lotNumberMatch = (l.data.lot_number || '') === (rq.lot_number.value || '');
            return itemIdMatch && lotNumberMatch;
          });

          const withSerial = matches.filter(i => {
            return i.data.serial;
          });

          const withoutSerial = matches.filter(i => {
            return !i.data.serial;
          });


          if (withSerial.length > 0) {
            let totalQty = rq.quantity;
            withSerial.forEach(function (csi) {
              if (totalQty > 0) {
                const lowerQty = Math.min(totalQty, csi.data.quantity);
                csi.data.redspot_expiration_date = rq.expiry_date;
                csi.data.expected_quantity = lowerQty;
                totalQty -= lowerQty;
              } else {
                csi.data.expected_quantity = 0;
              }
            });

            if (withoutSerial.length > 0) {
              withoutSerial[0].data.expected_quantity = totalQty;
            } else if (totalQty > 0) {
              const match = {
                data: {
                  item_id: rq.item.id,
                  reference: rq.item.reference,
                  description: rq.item.description,
                  lot_number: rq.lot_number.value,
                  serial: '',
                  expected_quantity: totalQty,
                  quantity: 0,
                  expiration_date: null,
                  redspot_expiration_date: rq.expiry_date,
                  count_sheet_id: 0,
                  is_consigned: rq.item.is_consigned

                },
                id: '',
                dbId: 0,
                isSynchronized: 1
              };
              notLotPoolManagedCountSheetItems.push(match);
            }

          } else if (matches.length > 0) {
            matches[0].data.expected_quantity = rq.quantity;
            matches[0].data.redspot_expiration_date = rq.expiry_date;

          } else {
            const match: IcountsheetItemData = {
              data: {
                item_id: rq.item.id,
                reference: rq.item.reference,
                description: rq.item.description,
                lot_number: rq.lot_number.value,
                serial: '',
                expected_quantity: rq.quantity,
                quantity: 0,
                expiration_date: null,
                redspot_expiration_date: rq.expiry_date,
                count_sheet_id: 0,
                is_consigned: rq.item.is_consigned,
                manually_entered: rq.item.manually_entered

              },
              id: '',
              dbId: 0,
              isSynchronized: 1
            };
            notLotPoolManagedCountSheetItems.push(match);
          }
        }
      });

      rootKitDefinitionItems.forEach(rkdi => {
        if (lotPoolManagedItemIds.indexOf(rkdi.item.id) !== -1) {
          if (!lotPoolManagedCountSheetItemGroup.find(d => d.data.item_id === rkdi.item_id)) {
            const matchingRedspotInventory = redspotQuantities.filter(rq => rq.item_id === rkdi.item_id);
            const row: ILotPoolManagedCountsheetItemGroup = {
              count_sheet_items: [],
              available_redspot_inventory: matchingRedspotInventory,
              data: {
                item_id: rkdi.item.id,
                reference: rkdi.item.reference,
                description: rkdi.item.description,
                is_consigned: rkdi.item.is_consigned,
                expected_quantity: matchingRedspotInventory.reduce((a, b) => a + b.quantity, 0),
                quantity: 0
              }
            };
            lotPoolManagedCountSheetItemGroup.push(row);
          }
        } else if (lotPoolManagedItemIds.indexOf(rkdi.item.id) === -1) {
          if (!notLotPoolManagedCountSheetItems.find(d => d.data.item_id === rkdi.item_id)) {
            const row: IcountsheetItemData = {
              data: {
                item_id: rkdi.item.id,
                reference: rkdi.item.reference,
                description: rkdi.item.description,
                lot_number: '',
                serial: '',
                expected_quantity: 0,
                quantity: 0,
                expiration_date: null,
                redspot_expiration_date: null,
                count_sheet_id: 0,
                is_consigned: rkdi.item.is_consigned,
                manually_entered: rkdi.item.manually_entered

              },
              id: '',
              dbId: 0,
              isSynchronized: 1
            };
            notLotPoolManagedCountSheetItems.push(row);
          }
        }
      });

      currentCountSheetItems = notLotPoolManagedCountSheetItems.concat(lotPoolManagedCountSheetItemGroup);
    }

    if (countSheet) {
      if (countSheet.data.show_expected_item_list) {
        data = currentCountSheetItems.filter(filterFunction);
        data = that.fillFunction(data, exclusions, redspotQuantities, sapQuantities, rootKitDefinitionItems, itemSettings, itemCombinations, recallOracleService);
        data = data.sort(sortFunction);
      } else {
        data = currentCountSheetItems.filter(filterFunction);
        data = that.fillFunction(data, exclusions, redspotQuantities, sapQuantities, rootKitDefinitionItems, itemSettings, itemCombinations, recallOracleService);
      }
    } else {
      data = currentCountSheetItems.filter(filterFunction);
      data = that.fillFunction(data, exclusions, redspotQuantities, sapQuantities, rootKitDefinitionItems, itemSettings, itemCombinations, recallOracleService);
      data = data.sort(sortFunction);
    }

    const exclusionIds = exclusions.map(e => {
      return e.item_id;
    });

    data = data.filter(csi => {
      return exclusionIds.indexOf(csi.data.item_id) === -1;
    });


    data = data.filter(d => {
      return !d.data.hide;
    });
    return data;
  }
}
