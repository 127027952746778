import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { urls } from '../helpers/urls';
import { map } from 'rxjs/operators';
import { KitInstanceHelper } from '../helpers/kit-instance-helper';
import { AuditKitInstanceDatabaseService } from '../services/audit-kit-instance-database.service';


@Injectable({
  providedIn: 'root'
})
export class AuditKitInstanceService {

  kitInstanceHelper: KitInstanceHelper = new KitInstanceHelper();

  constructor(
    private http: HttpClient,
    private _auditKitInstanceDatabaseService: AuditKitInstanceDatabaseService
  ) { }

  getAuditKitInstances(auditId) {
    const params = new HttpParams().set('audit_id', auditId);
    return this.http.get<any>(environment.serverPath + urls.kit , {params});
  }

  getOfflineAuditKitInstances(auditId) {
    return this._auditKitInstanceDatabaseService.find(auditId, {});
  }

  getAuditKitInstancesAndPopulateOfflineData(auditId) {
    const that = this;
    let promise = new Promise((resolve, reject) => {
      this.getAuditKitInstances(auditId).subscribe(data => {
        that._auditKitInstanceDatabaseService.deleteByIndex('data.audit_id', parseInt(auditId, 10)).then(function () {
          const akis = data.map(csi => that._auditKitInstanceDatabaseService.new(csi));
          that._auditKitInstanceDatabaseService.bulkAdd(akis).then(_ => resolve(akis));
        });
      });
    });
    return promise;
  }

  updateKitInstanceStatus(kitId, status) {
    const formData = new FormData();
    formData.append('id', JSON.stringify(kitId));
    formData.append('status', JSON.stringify(status));
    return this.http.put<any>(environment.serverPath + urls.kit, formData);
  }
}
