import { Injectable } from '@angular/core';
import { detect } from 'detect-browser'
import { BrowserNames } from '../helpers/browser-names';



declare var window: any;
declare var navigator: any;


@Injectable({
  providedIn: 'root'
})
export class FeatureSupportService {

  browserData: any = detect()

  constructor() {
  }

  isFireFox(){
    return this.browserData.name === BrowserNames.FIREFOX
  }

  isChrome(){
    return this.browserData.name === BrowserNames.CHROME
  }
}
