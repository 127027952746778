import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { urls } from '../helpers/urls';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConsignmentAccountDataService {

  constructor(private http: HttpClient) { }

  upload(formData: FormData){
    return this.http.post<any>(`${environment.serverPath}${urls.consignmentAccountData}/import`, formData);
  }

  getInventory(auditId) {
    return this.http.get<any>(`${environment.serverPath}${urls.consignmentAccountData}?audit_id=${auditId}`);
  }
}
