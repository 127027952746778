import { DatabaseService } from './database.service';
import { zip } from 'rxjs';
import { FileCacheService } from './file-cache.service';
import * as i0 from "@angular/core";
import * as i1 from "./database.service";
import * as i2 from "./file-cache.service";
var FileDatabaseService = /** @class */ (function () {
    function FileDatabaseService(_databaseService, _fileCacheService) {
        this._databaseService = _databaseService;
        this._fileCacheService = _fileCacheService;
        this.storeName = "files";
    }
    FileDatabaseService.prototype.new = function (data) {
        return { local_file_id: data.local_file_id, handle: data.handle, file: data.file };
    };
    FileDatabaseService.prototype.delete = function (key) {
        return this._databaseService.delete(this.storeName, key);
    };
    FileDatabaseService.prototype.clear = function () {
        return this._databaseService.clear(this.storeName);
    };
    FileDatabaseService.prototype.get = function (id) {
        var handle = this.getFromCache(id);
        if (handle) {
            return new Promise(function (resolve) {
                resolve({ local_file_id: id, handle: handle.handle, file: handle.file });
            });
        }
        else {
            return this._databaseService.get(this.storeName, id).then(function (data) {
                return data;
            });
        }
    };
    FileDatabaseService.prototype.getFromCache = function (id) {
        return this._fileCacheService.getHandle(id);
    };
    FileDatabaseService.prototype.add = function (f) {
        var _this = this;
        var that = this;
        if (!f.local_file_id) {
            throw new Error('how can you update without a primary key?');
        }
        return new Promise(function (resolve, reject) {
            _this._databaseService.add(_this.storeName, f).then(function (_) {
                var promises = [new Promise(function (r, a) { r(null); })];
                zip.apply(void 0, promises).subscribe(function (_) {
                    resolve(f.local_file_id);
                });
            });
        });
    };
    FileDatabaseService.ngInjectableDef = i0.defineInjectable({ factory: function FileDatabaseService_Factory() { return new FileDatabaseService(i0.inject(i1.DatabaseService), i0.inject(i2.FileCacheService)); }, token: FileDatabaseService, providedIn: "root" });
    return FileDatabaseService;
}());
export { FileDatabaseService };
