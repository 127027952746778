var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DataSource } from '@angular/cdk/collections';
import { of as observableOf, merge } from 'rxjs';
var KitInstanceCountSheetDataSource = /** @class */ (function (_super) {
    __extends(KitInstanceCountSheetDataSource, _super);
    function KitInstanceCountSheetDataSource(countSheetItemHelper) {
        var _this = _super.call(this) || this;
        _this.countSheetItemHelper = countSheetItemHelper;
        _this.data = [];
        _this.expectedKitCountSheetItems = [];
        _this.redspotInventoryIds = [];
        // caches
        _this.matchingKitExpectedItemCache = {};
        _this.matchingRootKitDefinitionItemCache = {};
        return _this;
    }
    Object.defineProperty(KitInstanceCountSheetDataSource.prototype, "recallOracleService", {
        get: function () {
            return this._recallOracleService;
        },
        set: function (value) {
            this._recallOracleService = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KitInstanceCountSheetDataSource.prototype, "lotPoolManagedItems", {
        get: function () {
            return this._lotPoolManagedItems;
        },
        set: function (value) {
            this._lotPoolManagedItems = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KitInstanceCountSheetDataSource.prototype, "exclusions", {
        get: function () {
            return this._exclusions;
        },
        set: function (value) {
            this._exclusions = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KitInstanceCountSheetDataSource.prototype, "itemCombinations", {
        get: function () {
            return this._itemCombinations;
        },
        set: function (value) {
            this._itemCombinations = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KitInstanceCountSheetDataSource.prototype, "filters", {
        get: function () {
            return this._filters;
        },
        set: function (value) {
            this._filters = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KitInstanceCountSheetDataSource.prototype, "itemSettings", {
        get: function () {
            return this._itemSettings;
        },
        set: function (value) {
            this._itemSettings = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KitInstanceCountSheetDataSource.prototype, "rootKitDefinitionItems", {
        get: function () {
            return this._rootKitDefinitionItems;
        },
        set: function (value) {
            this._rootKitDefinitionItems = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KitInstanceCountSheetDataSource.prototype, "sapQuantities", {
        get: function () {
            return this._sapQuantities;
        },
        set: function (value) {
            this._sapQuantities = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KitInstanceCountSheetDataSource.prototype, "redspotQuantities", {
        get: function () {
            return this._redspotQuantities;
        },
        set: function (value) {
            this._redspotQuantities = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KitInstanceCountSheetDataSource.prototype, "countSheet", {
        get: function () {
            return this._countSheet;
        },
        set: function (value) {
            this._countSheet = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KitInstanceCountSheetDataSource.prototype, "countSheetItems", {
        get: function () {
            return this._countSheetItems;
        },
        set: function (value) {
            this._countSheetItems = value;
        },
        enumerable: true,
        configurable: true
    });
    KitInstanceCountSheetDataSource.prototype.matchingKitExpectedItem = function (csi) {
        var cacheId = this.countSheetItemHelper.makeCombinationCacheIdFromCountsheetItem(csi);
        if (this.matchingKitExpectedItemCache[cacheId]) {
            return this.matchingKitExpectedItemCache[cacheId];
        }
        else {
            var result = this.redspotQuantities.find(function (rq) {
                var itemIdMatch = rq.item_id === csi.data.item_id;
                var lotNumberMatch = (rq.lot_number.value || '').toLowerCase() === (csi.data.lot_number || '').toLowerCase();
                var serialNumberMatch = '' === (csi.data.serial || '').toLowerCase();
                return itemIdMatch && lotNumberMatch && serialNumberMatch;
            });
            this.matchingKitExpectedItemCache[cacheId] = result;
            return result;
        }
    };
    KitInstanceCountSheetDataSource.prototype.matchingRootKitDefinitionItem = function (itemId) {
        var cacheId = this.countSheetItemHelper.makeCombinationCacheId({ itemId: itemId });
        if (this.matchingRootKitDefinitionItemCache[cacheId]) {
            return this.matchingRootKitDefinitionItemCache[cacheId];
        }
        else {
            var result = this.rootKitDefinitionItems.find(function (kdi) {
                return kdi.item_id === itemId;
            });
            this.matchingRootKitDefinitionItemCache[cacheId] = result;
            return result;
        }
    };
    KitInstanceCountSheetDataSource.prototype.fillFunction = function (data) {
        var _this = this;
        data.map(function (l) {
            var now = new Date();
            if (l.count_sheet_items) {
                l.data.added_to_kit = l.available_redspot_inventory.length === 0 && !_this.countSheetItemHelper.matchingRootKitDefinitionItem(l.data.item_id, _this.rootKitDefinitionItems);
                l.data.is_excluded = _this.countSheetItemHelper.getExclusion(l.data.item_id, _this.exclusions);
                l.data.in_definition = !!_this.countSheetItemHelper.matchingRootKitDefinitionItem(l.data.item_id, _this.rootKitDefinitionItems);
                l.data.show_recalled_warning = _this.recallOracleService.get(l.data.item_id, null, null);
            }
            else {
                if (l.data.checked) {
                    if (!l.data.expiration_date) {
                        var lookupOpt = { itemId: l.data.item_id, lotNumber: l.data.lot_number, serialNumber: l.data.serial };
                        var sqMatch = _this.countSheetItemHelper.getSapQuantity(lookupOpt, _this.sapQuantities);
                        if (sqMatch) {
                            l.data.expiration_date = sqMatch.expiry_date;
                        }
                        else {
                            l.data.expiration_date = l.data.redspot_expiration_date;
                        }
                        if (l.data.expiration_date) {
                            l.data.show_expired_warning = new Date(l.data.expiration_date) < now ? true : false;
                        }
                        else {
                            l.data.show_expired_warning = false;
                        }
                    }
                    else {
                        l.data.show_expired_warning = new Date(l.data.expiration_date) < now ? true : false;
                    }
                    l.data.show_recalled_warning = _this.recallOracleService.get(l.data.item_id, l.data.lot_number, l.data.serial);
                    l.data.show_invalid_warning = !_this.countSheetItemHelper.checkIfValidItemCombination(l.data.item_id, l.data.lot_number, l.data.serial, false, l.data.is_lot_tracked, l.data.is_serial_tracked, _this._itemSettings, _this._itemCombinations);
                }
                l.data.expected_quantity = l.data.expected_quantity || 0;
                l.data.in_definition = !!_this.countSheetItemHelper.matchingRootKitDefinitionItem(l.data.item_id, _this.rootKitDefinitionItems);
                l.data.is_excluded = _this.countSheetItemHelper.getExclusion(l.data.item_id, _this.exclusions);
                if (l.id || l.dbId) {
                    l.data.added_to_kit = !_this.matchingKitExpectedItem(l) && !_this.countSheetItemHelper.matchingRootKitDefinitionItem(l.data.item_id, _this.rootKitDefinitionItems);
                }
            }
        });
        return data;
    };
    KitInstanceCountSheetDataSource.prototype.refresh = function () {
        var _this = this;
        var that = this;
        var currentCountSheetItems = this.countSheetItems.slice().filter(function (csi) { return !csi._destroy; });
        var filterFunction = function (data) {
            var orderTypeMatches = true;
            var exclusionMatches = true;
            if (that.filters['orderType'] !== 'all') {
                var itemSetting = that.countSheetItemHelper.getItemSettings(data.data.item_id, that.itemSettings);
                orderTypeMatches = (that.filters['orderType'] === 'owned' && !(itemSetting || data.data).is_consigned) || (that.filters['orderType'] === 'consigned' && (itemSetting || data.data).is_consigned);
            }
            if (that.filters['exclusions'] === 'hide') {
                exclusionMatches = !data.data.is_excluded;
            }
            return orderTypeMatches && exclusionMatches;
        };
        var sortFunction = function (a, b) {
            var aMatch = that.matchingRootKitDefinitionItem(a.data.item_id);
            var bMatch = that.matchingRootKitDefinitionItem(b.data.item_id);
            if (aMatch && bMatch) {
                if (aMatch['position'] > bMatch['position']) {
                    return 1;
                }
                else if (aMatch['position'] < bMatch['position']) {
                    return -1;
                }
                else {
                    if (a.data.rank > b.data.rank) {
                        return 1;
                    }
                    else if (a.data.rank < b.data.rank) {
                        return -1;
                    }
                }
            }
            else if (aMatch) {
                return -1;
            }
            else if (bMatch) {
                return 1;
            }
            else if (a.data.reference.toLowerCase() > b.data.reference.toLowerCase()) {
                return 1;
            }
            else if (a.data.reference.toLowerCase() < b.data.reference.toLowerCase()) {
                return -1;
            }
            else if (a.data.reference.toLowerCase() === b.data.reference.toLowerCase()) {
                if (a.data.expected_quantity < b.data.expected_quantity) {
                    return 1;
                }
                else if (a.data.expected_quantity > b.data.expected_quantity) {
                    return -1;
                }
                else {
                    if (a.data.rank > b.data.rank) {
                        return 1;
                    }
                    else if (a.data.rank < b.data.rank) {
                        return -1;
                    }
                }
            }
            else {
                return 0;
            }
        };
        var blankSortFunction = function (a, b) {
            if (a.data.rank > b.data.rank) {
                return 1;
            }
            else if (a.data.rank < b.data.rank) {
                return -1;
            }
            else {
                return 0;
            }
        };
        if (this.countSheet.data.show_expected_item_list) {
            var lotPoolManagedCountSheetItems = currentCountSheetItems.filter(function (csi) { return that.countSheetItemHelper.getIsLotPoolManaged(csi.data.item_id, that.lotPoolManagedItems); });
            var notLotPoolManagedCountSheetItems_1 = currentCountSheetItems.filter(function (csi) { return !that.countSheetItemHelper.getIsLotPoolManaged(csi.data.item_id, that.lotPoolManagedItems); });
            var lotPoolManagedCountSheetItemGroup_1 = [];
            lotPoolManagedCountSheetItems.filter(function (csi) { return !csi.data.hide; }).forEach(function (csi) {
                var lpmi = that.countSheetItemHelper.getLotPoolManagedItem(csi.data.item_id, that.lotPoolManagedItems);
                var redspotId = _this.countSheet.data.audit_kit_instance_id.toString() + "-lpm-" + lpmi.id.toString();
                _this.redspotInventoryIds.push(redspotId);
                var match = lotPoolManagedCountSheetItemGroup_1.find(function (csig) {
                    return csig.data.item_id === csi.data.item_id;
                });
                if (!match) {
                    var matchingRedspotInventory = that.redspotQuantities.filter(function (rq) { return rq.item_id === csi.data.item_id; });
                    match = {
                        count_sheet_items: [],
                        available_redspot_inventory: matchingRedspotInventory,
                        data: {
                            item_id: csi.data.item_id,
                            reference: csi.data.reference,
                            description: csi.data.description,
                            is_consigned: csi.data.is_consigned,
                            expected_quantity: matchingRedspotInventory.reduce(function (a, b) { return a + b.quantity; }, 0),
                            quantity: 0,
                            redspot_inventory_id: redspotId
                        }
                    };
                    lotPoolManagedCountSheetItemGroup_1.push(match);
                }
                match.count_sheet_items.push(csi);
                if (csi.data.checked) {
                    match.data.quantity += csi.data.quantity;
                }
            });
            this.redspotQuantities.forEach(function (rq) {
                if (that.countSheetItemHelper.getIsLotPoolManaged(rq.item_id, that.lotPoolManagedItems)) {
                    if (!lotPoolManagedCountSheetItemGroup_1.find(function (csig) { return csig.data.item_id === rq.item_id; })) {
                        var matchingRedspotInventory = that.redspotQuantities.filter(function (lrq) { return lrq.item_id === rq.item_id; });
                        var redspotId = _this.countSheet.data.audit_kit_instance_id.toString() + "-rq-" + rq.id.toString();
                        _this.redspotInventoryIds.push(redspotId);
                        lotPoolManagedCountSheetItemGroup_1.push({
                            count_sheet_items: [],
                            available_redspot_inventory: matchingRedspotInventory,
                            data: {
                                item_id: rq.item_id,
                                reference: rq.item.reference,
                                description: rq.item.description,
                                is_consigned: rq.item.is_consigned,
                                expected_quantity: matchingRedspotInventory.reduce(function (a, b) { return a + b.quantity; }, 0),
                                quantity: 0,
                                redspot_inventory_id: redspotId
                            }
                        });
                    }
                }
                else if (!that.countSheetItemHelper.getIsLotPoolManaged(rq.item_id, that.lotPoolManagedItems)) {
                    var matches = notLotPoolManagedCountSheetItems_1.filter(function (l) {
                        var itemIdMatch = l.data.item_id === rq.item_id;
                        var lotNumberMatch = (l.data.lot_number || '') === (rq.lot_number.value || '');
                        return itemIdMatch && lotNumberMatch;
                    });
                    var withSerial = matches.filter(function (i) {
                        return i.data.serial;
                    });
                    var withoutSerial = matches.filter(function (i) {
                        return !i.data.serial;
                    });
                    if (withSerial.length > 0) {
                        var totalQty_1 = rq.quantity;
                        withSerial.forEach(function (csi) {
                            if (totalQty_1 > 0) {
                                var lowerQty = Math.min(totalQty_1, csi.data.quantity);
                                csi.data.redspot_expiration_date = rq.expiry_date;
                                csi.data.expected_quantity = lowerQty;
                                totalQty_1 -= lowerQty;
                            }
                            else {
                                csi.data.expected_quantity = 0;
                            }
                        });
                        if (withoutSerial.length > 0) {
                            withoutSerial[0].data.expected_quantity = totalQty_1;
                        }
                        else if (totalQty_1 > 0) {
                            var redspotId = _this.countSheet.data.audit_kit_instance_id.toString() + "-rq-" + rq.id.toString();
                            _this.redspotInventoryIds.push(redspotId);
                            var match = {
                                data: {
                                    item_id: rq.item.id,
                                    reference: rq.item.reference,
                                    description: rq.item.description,
                                    lot_number: rq.lot_number.value,
                                    serial: '',
                                    expected_quantity: totalQty_1,
                                    quantity: 0,
                                    expiration_date: null,
                                    redspot_expiration_date: rq.expiry_date,
                                    count_sheet_id: 0,
                                    is_consigned: rq.item.is_consigned,
                                    redspot_inventory_id: redspotId
                                },
                                id: '',
                                dbId: 0,
                                isSynchronized: 1
                            };
                            notLotPoolManagedCountSheetItems_1.push(match);
                        }
                    }
                    else if (matches.length > 0) {
                        matches[0].data.expected_quantity = rq.quantity;
                        matches[0].data.redspot_expiration_date = rq.expiry_date;
                    }
                    else {
                        var redspotId = _this.countSheet.data.audit_kit_instance_id.toString() + "-rq-" + rq.id.toString();
                        _this.redspotInventoryIds.push(redspotId);
                        var match = {
                            data: {
                                item_id: rq.item.id,
                                reference: rq.item.reference,
                                description: rq.item.description,
                                lot_number: rq.lot_number.value,
                                serial: '',
                                expected_quantity: rq.quantity,
                                quantity: 0,
                                expiration_date: null,
                                redspot_expiration_date: rq.expiry_date,
                                count_sheet_id: 0,
                                is_consigned: rq.item.is_consigned,
                                manually_entered: rq.item.manually_entered,
                                redspot_inventory_id: redspotId
                            },
                            id: '',
                            dbId: 0,
                            isSynchronized: 1
                        };
                        notLotPoolManagedCountSheetItems_1.push(match);
                    }
                }
            });
            this.rootKitDefinitionItems.forEach(function (rkdi) {
                if (that.countSheetItemHelper.getIsLotPoolManaged(rkdi.item.id, that.lotPoolManagedItems)) {
                    if (!lotPoolManagedCountSheetItemGroup_1.find(function (d) { return d.data.item_id === rkdi.item_id; })) {
                        var matchingRedspotInventory = that.redspotQuantities.filter(function (rq) { return rq.item_id === rkdi.item_id; });
                        var redspotId = _this.countSheet.data.audit_kit_instance_id.toString() + "-rkdi-" + rkdi.id.toString();
                        _this.redspotInventoryIds.push(redspotId);
                        var row = {
                            count_sheet_items: [],
                            available_redspot_inventory: matchingRedspotInventory,
                            data: {
                                item_id: rkdi.item.id,
                                reference: rkdi.item.reference,
                                description: rkdi.item.description,
                                is_consigned: rkdi.item.is_consigned,
                                expected_quantity: matchingRedspotInventory.reduce(function (a, b) { return a + b.quantity; }, 0),
                                quantity: 0,
                                redspot_inventory_id: redspotId
                            }
                        };
                        lotPoolManagedCountSheetItemGroup_1.push(row);
                    }
                }
                else if (!that.countSheetItemHelper.getIsLotPoolManaged(rkdi.item.id, that.lotPoolManagedItems)) {
                    if (!notLotPoolManagedCountSheetItems_1.find(function (d) { return d.data.item_id === rkdi.item_id; })) {
                        var redspotId = _this.countSheet.data.audit_kit_instance_id.toString() + "-rkdi-" + rkdi.id.toString();
                        _this.redspotInventoryIds.push(redspotId);
                        var row = {
                            data: {
                                item_id: rkdi.item.id,
                                reference: rkdi.item.reference,
                                description: rkdi.item.description,
                                lot_number: '',
                                serial: '',
                                expected_quantity: 0,
                                quantity: 0,
                                expiration_date: null,
                                redspot_expiration_date: null,
                                count_sheet_id: 0,
                                is_consigned: rkdi.item.is_consigned,
                                manually_entered: rkdi.item.manually_entered,
                                redspot_inventory_id: redspotId
                            },
                            id: '',
                            dbId: 0,
                            isSynchronized: 1
                        };
                        notLotPoolManagedCountSheetItems_1.push(row);
                    }
                }
            });
            currentCountSheetItems = notLotPoolManagedCountSheetItems_1.concat(lotPoolManagedCountSheetItemGroup_1);
            this.assignRank(currentCountSheetItems);
            this.expectedKitCountSheetItems = currentCountSheetItems;
        }
        else {
            var currentCountSheetItems_1 = this.countSheetItems.slice().filter(function (csi) { return !csi._destroy; });
            this.expectedKitCountSheetItems = this.getExpectedKitCountSheetItems(currentCountSheetItems_1);
        }
        if (this.countSheet.data.show_expected_item_list) {
            this.data = that.fillFunction(currentCountSheetItems);
            this.data = this.data.filter(filterFunction);
            this.data = this.data.sort(sortFunction);
        }
        else {
            this.data = that.fillFunction(currentCountSheetItems);
            this.data = this.data.filter(filterFunction);
            this.data = this.data.sort(blankSortFunction);
        }
        this.data = this.data.filter(function (d) {
            return !d.data.hide;
        });
    };
    /**
     * Connect this data source to the table. The table will only update when
     * the returned stream emits new items.
     * @returns A stream of the items to be rendered.
     */
    KitInstanceCountSheetDataSource.prototype.connect = function () {
        // Combine everything that affects the rendered data into one update
        // stream for the data-table to consume.
        var dataMutations = [
            observableOf(this.data)
        ];
        return merge.apply(void 0, dataMutations);
    };
    /**
     *  Called when the table is being destroyed. Use this function, to clean up
     * any open connections or free any held resources that were set up during connect.
     */
    KitInstanceCountSheetDataSource.prototype.disconnect = function () { };
    KitInstanceCountSheetDataSource.prototype.getExpectedKitCountSheetItems = function (currentCountSheetItems) {
        var _this = this;
        var that = this;
        var filterFunction = function (data) {
            var orderTypeMatches = true;
            var exclusionMatches = true;
            if (that.filters['orderType'] !== 'all') {
                var itemSetting = that.countSheetItemHelper.getItemSettings(data.data.item_id, that.itemSettings);
                orderTypeMatches = (that.filters['orderType'] === 'owned' && !(itemSetting || data.data).is_consigned) || (that.filters['orderType'] === 'consigned' && (itemSetting || data.data).is_consigned);
            }
            if (that.filters['exclusions'] === 'hide') {
                exclusionMatches = !data.data.is_excluded;
            }
            return orderTypeMatches && exclusionMatches;
        };
        var lotPoolManagedCountSheetItems = currentCountSheetItems.filter(function (csi) { return that.countSheetItemHelper.getIsLotPoolManaged(csi.data.item_id, that.lotPoolManagedItems); });
        var notLotPoolManagedCountSheetItems = currentCountSheetItems.filter(function (csi) { return !that.countSheetItemHelper.getIsLotPoolManaged(csi.data.item_id, that.lotPoolManagedItems); });
        var lotPoolManagedCountSheetItemGroup = [];
        lotPoolManagedCountSheetItems.filter(function (csi) { return !csi.data.hide; }).forEach(function (csi) {
            var match = lotPoolManagedCountSheetItemGroup.find(function (csig) {
                return csig.data.item_id === csi.data.item_id;
            });
            if (!match) {
                var matchingRedspotInventory = that.redspotQuantities.filter(function (rq) { return rq.item_id === csi.data.item_id; });
                var lpmi = that.countSheetItemHelper.getLotPoolManagedItem(csi.data.item_id, that.lotPoolManagedItems);
                var redspotId = _this.countSheet.data.audit_kit_instance_id.toString() + "-lpm-" + lpmi.id.toString();
                _this.redspotInventoryIds.push(redspotId);
                match = {
                    count_sheet_items: [],
                    available_redspot_inventory: matchingRedspotInventory,
                    data: {
                        item_id: csi.data.item_id,
                        reference: csi.data.reference,
                        description: csi.data.description,
                        is_consigned: csi.data.is_consigned,
                        expected_quantity: matchingRedspotInventory.reduce(function (a, b) { return a + b.quantity; }, 0),
                        quantity: 0,
                        redspot_inventory_id: redspotId
                    }
                };
                lotPoolManagedCountSheetItemGroup.push(match);
            }
            match.count_sheet_items.push(csi);
            if (csi.data.checked) {
                match.data.quantity += csi.data.quantity;
            }
        });
        this.redspotQuantities.forEach(function (rq) {
            if (that.countSheetItemHelper.getIsLotPoolManaged(rq.item_id, that.lotPoolManagedItems)) {
                if (!lotPoolManagedCountSheetItemGroup.find(function (csig) { return csig.data.item_id === rq.item_id; })) {
                    var matchingRedspotInventory = that.redspotQuantities.filter(function (lrq) { return lrq.item_id === rq.item_id; });
                    var redspotId = _this.countSheet.data.audit_kit_instance_id.toString() + "-rq-" + rq.id.toString();
                    _this.redspotInventoryIds.push(redspotId);
                    lotPoolManagedCountSheetItemGroup.push({
                        count_sheet_items: [],
                        available_redspot_inventory: matchingRedspotInventory,
                        data: {
                            item_id: rq.item_id,
                            reference: rq.item.reference,
                            description: rq.item.description,
                            is_consigned: rq.item.is_consigned,
                            expected_quantity: matchingRedspotInventory.reduce(function (a, b) { return a + b.quantity; }, 0),
                            quantity: 0,
                            redspot_inventory_id: redspotId
                        }
                    });
                }
            }
            else if (!that.countSheetItemHelper.getIsLotPoolManaged(rq.item_id, that.lotPoolManagedItems)) {
                var matches = notLotPoolManagedCountSheetItems.filter(function (l) {
                    var itemIdMatch = l.data.item_id === rq.item_id;
                    var lotNumberMatch = (l.data.lot_number || '') === (rq.lot_number.value || '');
                    return itemIdMatch && lotNumberMatch;
                });
                var withSerial = matches.filter(function (i) {
                    return i.data.serial;
                });
                var withoutSerial = matches.filter(function (i) {
                    return !i.data.serial;
                });
                if (withSerial.length > 0) {
                    var totalQty_2 = rq.quantity;
                    withSerial.forEach(function (csi) {
                        if (totalQty_2 > 0) {
                            var lowerQty = Math.min(totalQty_2, csi.data.quantity);
                            csi.data.redspot_expiration_date = rq.expiry_date;
                            csi.data.expected_quantity = lowerQty;
                            totalQty_2 -= lowerQty;
                        }
                        else {
                            csi.data.expected_quantity = 0;
                        }
                    });
                    if (withoutSerial.length > 0) {
                        withoutSerial[0].data.expected_quantity = totalQty_2;
                    }
                    else if (totalQty_2 > 0) {
                        var redspotId = _this.countSheet.data.audit_kit_instance_id.toString() + "-rq-" + rq.id.toString();
                        _this.redspotInventoryIds.push(redspotId);
                        var match = {
                            data: {
                                item_id: rq.item.id,
                                reference: rq.item.reference,
                                description: rq.item.description,
                                lot_number: rq.lot_number.value,
                                serial: '',
                                expected_quantity: totalQty_2,
                                quantity: 0,
                                expiration_date: null,
                                redspot_expiration_date: rq.expiry_date,
                                count_sheet_id: 0,
                                is_consigned: rq.item.is_consigned,
                                redspot_inventory_id: redspotId
                            },
                            id: '',
                            dbId: 0,
                            isSynchronized: 1
                        };
                        notLotPoolManagedCountSheetItems.push(match);
                    }
                }
                else if (matches.length > 0) {
                    matches[0].data.expected_quantity = rq.quantity;
                    matches[0].data.redspot_expiration_date = rq.expiry_date;
                }
                else {
                    var redspotId = _this.countSheet.data.audit_kit_instance_id.toString() + "-rq-" + rq.id.toString();
                    _this.redspotInventoryIds.push(redspotId);
                    var match = {
                        data: {
                            item_id: rq.item.id,
                            reference: rq.item.reference,
                            description: rq.item.description,
                            lot_number: rq.lot_number.value,
                            serial: '',
                            expected_quantity: rq.quantity,
                            quantity: 0,
                            expiration_date: null,
                            redspot_expiration_date: rq.expiry_date,
                            count_sheet_id: 0,
                            is_consigned: rq.item.is_consigned,
                            manually_entered: rq.item.manually_entered,
                            redspot_inventory_id: redspotId
                        },
                        id: '',
                        dbId: 0,
                        isSynchronized: 1
                    };
                    notLotPoolManagedCountSheetItems.push(match);
                }
            }
        });
        this.rootKitDefinitionItems.forEach(function (rkdi) {
            if (that.countSheetItemHelper.getIsLotPoolManaged(rkdi.item.id, that.lotPoolManagedItems)) {
                if (!lotPoolManagedCountSheetItemGroup.find(function (d) { return d.data.item_id === rkdi.item_id; })) {
                    var matchingRedspotInventory = that.redspotQuantities.filter(function (rq) { return rq.item_id === rkdi.item_id; });
                    var redspotId = _this.countSheet.data.audit_kit_instance_id.toString() + "-rkdi-" + rkdi.id.toString();
                    _this.redspotInventoryIds.push(redspotId);
                    var row = {
                        count_sheet_items: [],
                        available_redspot_inventory: matchingRedspotInventory,
                        data: {
                            item_id: rkdi.item.id,
                            reference: rkdi.item.reference,
                            description: rkdi.item.description,
                            is_consigned: rkdi.item.is_consigned,
                            expected_quantity: matchingRedspotInventory.reduce(function (a, b) { return a + b.quantity; }, 0),
                            quantity: 0,
                            redspot_inventory_id: redspotId
                        }
                    };
                    lotPoolManagedCountSheetItemGroup.push(row);
                }
            }
            else if (!that.countSheetItemHelper.getIsLotPoolManaged(rkdi.item.id, that.lotPoolManagedItems)) {
                if (!notLotPoolManagedCountSheetItems.find(function (d) { return d.data.item_id === rkdi.item_id; })) {
                    var redspotId = _this.countSheet.data.audit_kit_instance_id.toString() + "-rkdi-" + rkdi.id.toString();
                    _this.redspotInventoryIds.push(redspotId);
                    var row = {
                        data: {
                            item_id: rkdi.item.id,
                            reference: rkdi.item.reference,
                            description: rkdi.item.description,
                            lot_number: '',
                            serial: '',
                            expected_quantity: 0,
                            quantity: 0,
                            expiration_date: null,
                            redspot_expiration_date: null,
                            count_sheet_id: 0,
                            is_consigned: rkdi.item.is_consigned,
                            manually_entered: rkdi.item.manually_entered,
                            redspot_inventory_id: redspotId
                        },
                        id: '',
                        dbId: 0,
                        isSynchronized: 1
                    };
                    notLotPoolManagedCountSheetItems.push(row);
                }
            }
        });
        var countSheetItems = notLotPoolManagedCountSheetItems.concat(lotPoolManagedCountSheetItemGroup);
        this.assignRank(countSheetItems);
        return countSheetItems.filter(filterFunction);
    };
    KitInstanceCountSheetDataSource.prototype.assignRank = function (countSheetItems) {
        var rank = 0;
        countSheetItems.forEach(function (csi) {
            if (csi.data.redspot_inventory_id && !csi.data.rank && csi.data.in_definition) {
                var existingRank = JSON.parse(localStorage.getItem(csi.data.redspot_inventory_id));
                rank += 1;
                csi.data.rank = existingRank || rank;
                localStorage.setItem(csi.data.redspot_inventory_id, JSON.stringify(csi.data.rank));
            }
        });
        countSheetItems.forEach(function (csi) {
            if (csi.data.redspot_inventory_id && !csi.data.rank) {
                var existingRank = JSON.parse(localStorage.getItem(csi.data.redspot_inventory_id));
                rank += 1;
                csi.data.rank = existingRank || rank;
                localStorage.setItem(csi.data.redspot_inventory_id, JSON.stringify(csi.data.rank));
            }
        });
    };
    return KitInstanceCountSheetDataSource;
}(DataSource));
export { KitInstanceCountSheetDataSource };
