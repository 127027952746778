<div class="clear_both"></div>
<div class="col-md-12 full">
  <div class="mat-elevation-z1 data-table">
    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="verify">
        <th mat-header-cell class="table-header" *matHeaderCellDef>
          <div *ngxPermissionsOnly="['audit_administrator', 'auditor']">
            <mat-checkbox [ngClass]="{'display-when-counted': !areAnyKitCounted}" [(ngModel)]="verifyAllChecked" tabindex="-1"
              (change)="checkedAllClicked($event)">Verify
              All</mat-checkbox>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">
          <div *ngxPermissionsOnly="['audit_administrator', 'auditor']">
            <mat-checkbox *ngIf="element.data.status == 3" tabindex="-1" [(ngModel)]="element.data.verify_display" (change)="checkedClicked(element)">
            </mat-checkbox>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Reference</th>
        <td mat-cell *matCellDef="let row">{{row.data.item_instance.reference}}</td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Description</th>
        <td mat-cell *matCellDef="let row">{{row.data.item_instance.description}}</td>
      </ng-container>

      <ng-container matColumnDef="lot_number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Lot Number</th>
        <td mat-cell *matCellDef="let row">{{row.data.item_instance.lot_number}}</td>
      </ng-container>

      <ng-container matColumnDef="item_count">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Expected</th>
        <td mat-cell *matCellDef="let row">{{row.data.item_count}}</td>
      </ng-container>

      <ng-container matColumnDef="current_count">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Actual</th>
        <td mat-cell *matCellDef="let row">{{ row.data.count_sheet? row.data.count_sheet.data.total_items_counted: "&mdash;"}}</td>
      </ng-container>

      <ng-container matColumnDef="warehouse">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Warehouse</th>
        <td mat-cell *matCellDef="let row">{{row.data.count_sheet? row.data.count_sheet.data.warehouse_name: row.data.warehouse_name}}</td>
      </ng-container>

      <ng-container matColumnDef="site">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Site</th>
        <td mat-cell *matCellDef="let row">{{row.data.count_sheet?.data?.location_name || "&mdash;"}}</td>
      </ng-container>

      <ng-container matColumnDef="counted_by">
        <th mat-header-cell *matHeaderCellDef class="table-header">Counted By</th>
        <td mat-cell *matCellDef="let row">{{row.data.counted_by? row.data.counted_by.name : "&mdash;"}}</td>
      </ng-container>

      <ng-container matColumnDef="counted_time">
        <th mat-header-cell *matHeaderCellDef class="table-header">Counted Date</th>
        <td mat-cell *matCellDef="let row">{{row.data.counted_time | date:'medium' || "&mdash;"}}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-header">Status</th>
        <td mat-cell *matCellDef="let row">{{row.data.statusText}}</td>
      </ng-container>

      <ng-container matColumnDef="countsheet">
        <th mat-header-cell *matHeaderCellDef class="table-header">
          <mat-icon ngbTooltip="Save as XLS" class="export-button" (click)="exportToExcel()" placement="left">save_alt
          </mat-icon>
        </th>
        <td mat-cell *matCellDef="let row">
          <div class="row buttons">
            <span class="tooltipWrapper"  *ngIf="audit.sap_inventory_imported_date">
              <div *ngxPermissionsOnly="['audit_administrator', 'auditor']">
                <button mat-icon-button type="button" ngbTooltip="New Count Sheet" placement="left"
                  (click)="createCountSheet(row)" *ngIf="row.data.has_count_sheet == false && !viewOnly">
                  <mat-icon class="material-icons">note_add</mat-icon>
                </button>
              </div>
            </span>

            <span class="tooltipWrapper">
              <button *ngIf="row.data.status === 2 && !viewOnly" ngbTooltip="Continue Count" placement="left"
                (click)="continueCounting(row)" mat-icon-button>
                <i class="material-icons">queue_play_next</i>
              </button>
            </span>

            <span class="tooltipWrapper">
              <button mat-icon-button type="button" ngbTooltip="Needs Verification" placement="left"
                (click)="goToCompletedCountSheet(row)" *ngIf="row.data.status == 3 && !viewOnly">
                <mat-icon class="material-icons">check_outline</mat-icon>
              </button>
            </span>

            <span class="tooltipWrapper">
              <button mat-icon-button type="button" ngbTooltip="Verified" placement="left"
                (click)="goToVerifiedCountSheet(row)" *ngIf="row.data.status == 4 && !viewOnly">
                <mat-icon style="color:green" class="material-icons">check_circle</mat-icon>
              </button>
            </span>

            <span class="tooltipWrapper">
              <button mat-icon-button type="button" ngbTooltip="View" placement="left"
                (click)="goToCompletedCountSheet(row)"
                *ngIf="viewOnly && row.data.status !== 2 && row.data.has_count_sheet">
                <mat-icon class="material-icons">remove_red_eye</mat-icon>
              </button>
            </span>
            <span class="tooltipWrapper">
              <button mat-icon-button type="button" ngbTooltip="Print" placement="left" (click)="printCountSheet(row)">
                <mat-icon class="material-icons">print</mat-icon>
              </button>
            </span>
          </div>
        </td>
      </ng-container>



      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'not-synced': !row.isSynchronized}"></tr>
    </table>

    <mat-paginator #paginator showFirstLastButtons [pageSize]="50" [pageSizeOptions]="[50,100,250,1000]">
    </mat-paginator>
  </div>
</div>
