var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnInit, EventEmitter } from '@angular/core';
import { MaterialSnackbarComponent } from '../../material-snackbar/material-snackbar.component';
import { MatSnackBar } from '@angular/material';
import { CountSheetItemHelper } from 'src/app/helpers/countsheet-item-helper';
import { Assets } from 'src/app/helpers/assets';
var BarcodeHibcFormatComponent = /** @class */ (function () {
    function BarcodeHibcFormatComponent(_snackBar) {
        this._snackBar = _snackBar;
        this.PRIMARY_REGEX = /^(\+([A-Z][A-Z0-9]{3})([A-Z0-9]{1,18})(\d))(.)$/;
        this.SECONDARY_REGEX = /^(\+([^A-Z].{1,35})(.))(.)$/;
        this.CHARACTERS = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ-. $/+%";
        this.itemNotFound = new EventEmitter();
        this.snackBarConfig = {
            message: "",
            duration: 3000,
            success: false,
            snackBarClass: ""
        };
    }
    BarcodeHibcFormatComponent.prototype.ngOnInit = function () {
    };
    BarcodeHibcFormatComponent.prototype.Mod43Checksum = function (value) {
        var that = this;
        var sum = (value || "").toUpperCase().split("").map(function (l) {
            return that.CHARACTERS.indexOf(l);
        }).reduce(function (total, num) {
            return total + num;
        });
        return this.CHARACTERS[sum % 43];
    };
    BarcodeHibcFormatComponent.prototype.parse = function (currentScan, previousScan) {
        var data = this.decode(currentScan, previousScan);
        var values = this.determinePrimaryAndSecondary(currentScan, previousScan);
        var done = false;
        var reset = false;
        if (values.length == 2 && values[0] && values[1]) {
            if (this.paired(values[0], values[1])) {
                done = true;
            }
            else {
                reset = true;
            }
        }
        var item;
        if (data["pcn"]) {
            item = this.countsheetItemHelper.getItemForReference(data["pcn"], this.items);
            if (!item) {
                this.playErrorSound();
                this.itemNotFound.emit();
                this.snackBarConfig.message = 'Matching Item could not be found';
                this.snackBarConfig.success = false;
                this.openSnackBar();
                return {};
            }
        }
        return {
            "serial": data["serial"],
            "expiration": data["date"],
            "lot": data["lot"],
            "quantity": data["quantity"],
            "reference": item ? item.reference : null,
            "done": done,
            "reset": reset
        };
    };
    BarcodeHibcFormatComponent.prototype.match = function (value) {
        return this.isPrimary(value) || this.isSecondary(value);
    };
    BarcodeHibcFormatComponent.prototype.decode = function (firstScan, secondScan) {
        var values = this.determinePrimaryAndSecondary(firstScan, secondScan);
        var p = values[0];
        var s = values[1];
        var data = {};
        if (s && !this.paired(p, s)) {
            data = __assign({}, data, (this.parse_secondary(s)));
        }
        else if (s) {
            data = __assign({}, data, (this.parse_primary(p)));
            data = __assign({}, data, (this.parse_secondary(s)));
        }
        else if (p) {
            data = __assign({}, data, (this.parse_primary(p)));
        }
        return data;
    };
    BarcodeHibcFormatComponent.prototype.parse_primary = function (value) {
        var matches = value.match(this.PRIMARY_REGEX);
        return {
            labeler: matches[2],
            pcn: matches[3],
            unit_of_measure_id: parseInt(matches[4], 10),
            primary_check_character: matches[5]
        };
    };
    BarcodeHibcFormatComponent.prototype.parse_secondary = function (value) {
        var matches = value.match(this.SECONDARY_REGEX);
        var data = {
            link_character: matches[3],
            secondary_check_character: matches[4]
        };
        var valueArray = matches[2].split("");
        var c1 = valueArray.shift();
        switch (c1) {
            case "$":
                var c2 = valueArray.shift();
                switch (c2) {
                    case "$":
                        var c3 = valueArray.shift();
                        var serial = false;
                        if (c3 === "+") {
                            serial = true;
                            c3 = valueArray.shift();
                        }
                        else if (c3 === "8") {
                            data["quantity"] = parseInt([valueArray.shift(), valueArray.shift()].join(""), 10);
                            c3 = valueArray.shift();
                        }
                        else if (c3 === "9") {
                            data["quantity"] = parseInt([valueArray.shift(), valueArray.shift(), valueArray.shift(), valueArray.shift(), valueArray.shift()].join(""), 10);
                            c3 = valueArray.shift();
                        }
                        var month, day, year, julianDays;
                        switch (c3) {
                            //notes:
                            //HIBC barcode month dates are 1 index based, javascript month dates are 0 index based
                            //when creating a javascript date if you set the day to 0, it will make the date go back to the last day of the previous month
                            case "0":
                                month = c3 + valueArray.shift();
                                year = [valueArray.shift(), valueArray.shift()].join("");
                                data["date"] = new Date(2000 + parseInt(year, 10), parseInt(month, 10), 0);
                                break;
                            case "1":
                                month = c3 + valueArray.shift();
                                year = [valueArray.shift(), valueArray.shift()].join("");
                                data["date"] = new Date(2000 + parseInt(year, 10), parseInt(month, 10), 0);
                                break;
                            case "2":
                                month = [valueArray.shift(), valueArray.shift()].join("");
                                year = [valueArray.shift(), valueArray.shift()].join("");
                                day = [valueArray.shift(), valueArray.shift()].join("");
                                data["date"] = new Date(2000 + parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
                                break;
                            case "3":
                                year = [valueArray.shift(), valueArray.shift()].join("");
                                month = [valueArray.shift(), valueArray.shift()].join("");
                                day = [valueArray.shift(), valueArray.shift()].join("");
                                data["date"] = new Date(2000 + parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
                                break;
                            case "4":
                                year = [valueArray.shift(), valueArray.shift()].join("");
                                month = [valueArray.shift(), valueArray.shift()].join("");
                                day = [valueArray.shift(), valueArray.shift()].join("");
                                data["date"] = new Date(2000 + parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
                                break;
                            case "5":
                                year = [valueArray.shift(), valueArray.shift()].join("");
                                julianDays = [valueArray.shift(), valueArray.shift(), valueArray.shift()].join("");
                                data["date"] = new Date(2000 + parseInt(year, 10), 0, parseInt(julianDays, 10));
                                break;
                            case "6":
                                year = [valueArray.shift(), valueArray.shift()].join("");
                                julianDays = [valueArray.shift(), valueArray.shift(), valueArray.shift()].join("");
                                data["date"] = new Date(2000 + parseInt(year, 10), 0, parseInt(julianDays, 10));
                                break;
                            case "7":
                                //no date
                                break;
                        }
                        if (valueArray.length > 0) {
                            var remainder = valueArray.join("");
                            if (remainder.indexOf('/S') != -1) {
                                var lotSerial = remainder.split('/S');
                                data["lot"] = lotSerial[0];
                                data["serial"] = lotSerial[1];
                                data["quantity"] = 1;
                            }
                            else if (serial) {
                                data["serial"] = remainder;
                                data["quantity"] = 1;
                            }
                            else {
                                data["lot"] = remainder;
                            }
                        }
                        break;
                    case "+":
                        data["serial"] = valueArray.join("");
                        data["quantity"] = 1;
                        break;
                    default:
                        var cAndRemainder = c2 + valueArray.join("");
                        if (cAndRemainder.indexOf("/S") != -1) {
                            var lotSerial = cAndRemainder.split('/S');
                            data["lot"] = lotSerial[0];
                            data["serial"] = lotSerial[1];
                            data["quantity"] = 1;
                        }
                        else {
                            data["lot"] = cAndRemainder;
                        }
                }
                break;
            default:
                data["date"] = new Date(2000 + parseInt(c1 + [valueArray.shift(), valueArray.shift()].join(""), 10), 0, parseInt([valueArray.shift(), valueArray.shift()].join(""), 10) + 1);
        }
        return data;
    };
    BarcodeHibcFormatComponent.prototype.isPrimary = function (value) {
        return this.PRIMARY_REGEX.test(value);
    };
    BarcodeHibcFormatComponent.prototype.isSecondary = function (value) {
        return this.SECONDARY_REGEX.test(value);
    };
    BarcodeHibcFormatComponent.prototype.primary = function (value) {
        if (this.isPrimary(value)) {
            var matches = value.match(this.PRIMARY_REGEX);
            return this.Mod43Checksum(matches[1]) === matches[5];
        }
        return false;
    };
    BarcodeHibcFormatComponent.prototype.secondary = function (value) {
        if (this.isSecondary(value)) {
            var matches = value.match(this.SECONDARY_REGEX);
            return this.Mod43Checksum(matches[1]) === matches[4];
        }
        return false;
    };
    BarcodeHibcFormatComponent.prototype.determinePrimaryAndSecondary = function (firstScan, secondScan) {
        var p, s;
        if (this.primary(firstScan)) {
            p = firstScan;
        }
        else if (this.primary(secondScan)) {
            p = secondScan;
        }
        if (this.secondary(firstScan)) {
            s = firstScan;
        }
        else if (this.secondary(secondScan)) {
            s = secondScan;
        }
        return [p, s];
    };
    BarcodeHibcFormatComponent.prototype.paired = function (p, s) {
        return this.primary(p) && this.secondary(s) && this.parse_primary(p).primary_check_character === this.parse_secondary(s).link_character;
    };
    BarcodeHibcFormatComponent.prototype.playErrorSound = function () {
        var audio = new Audio();
        audio.src = Assets.sounds.scanError;
        audio.play();
    };
    BarcodeHibcFormatComponent.prototype.openSnackBar = function () {
        this._snackBar.openFromComponent(MaterialSnackbarComponent, {
            data: this.snackBarConfig,
            duration: this.snackBarConfig.duration
        });
    };
    return BarcodeHibcFormatComponent;
}());
export { BarcodeHibcFormatComponent };
