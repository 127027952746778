import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { urls } from '../helpers/urls';

@Injectable({
  providedIn: 'root'
})
export class ItemCombinationService {

  constructor(private http: HttpClient) { }

  get() {
    return this.http.get<any>(`${environment.serverPath}${urls.getManufacturerItemCombinations}`);
  }

  getManufacturerItemCombinations() {
    return this.http.get<any>(`${environment.serverPath}${urls.getManufacturerItemCombinations}`);

  }

  upload(formData: FormData){
    return this.http.post<any>(`${environment.serverPath}${urls.itemCombinationImport}`, formData).pipe(res => {
      window.caches.keys().then(function(cacheNames) {
        var match = cacheNames.find(cn => cn.includes("LongTermCountSheets"));
        if (match) {
          caches.open(match).then(function (cache) {
            cache.delete(`${environment.serverPath}${urls.manufacturerItemCombinationCount}`, {ignoreSearch: true});
            cache.delete(`${environment.serverPath}${urls.getManufacturerItemCombinations}`, {ignoreSearch: true});
          });
        }
      })
      return res;
    });
  }

  getManufacturerItemCombinationCount(){
    return this.http.get<any>(`${environment.serverPath}${urls.manufacturerItemCombinationCount}`);
  }
}
