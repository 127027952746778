/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./result-file-dialog.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@angular/material/form-field";
import * as i4 from "@angular/material/input";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/forms";
import * as i7 from "@angular/material/core";
import * as i8 from "@angular/cdk/text-field";
import * as i9 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i10 from "@angular/material/button";
import * as i11 from "@angular/cdk/a11y";
import * as i12 from "@angular/platform-browser/animations";
import * as i13 from "./result-file-dialog.component";
import * as i14 from "../../services/audit.service";
var styles_ResultFileDialogComponent = [i0.styles];
var RenderType_ResultFileDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ResultFileDialogComponent, data: {} });
export { RenderType_ResultFileDialogComponent as RenderType_ResultFileDialogComponent };
export function View_ResultFileDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { emailAddressField: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h1", [["class", "full-width header-title mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(3, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["The file will be created and then sent to the address provided."])), (_l()(), i1.ɵeld(9, 0, [[1, 0], ["result_file_email_address", 1]], null, 2, "input", [["class", "mat-input-element mat-form-field-autofill-control"], ["matInput", ""], ["placeholder", "email address"]], [[2, "mat-input-server", null], [1, "id", 0], [1, "placeholder", 0], [8, "disabled", 0], [8, "required", 0], [8, "readOnly", 0], [1, "aria-describedby", 0], [1, "aria-invalid", 0], [1, "aria-required", 0]], [[null, "blur"], [null, "focus"], [null, "input"]], function (_v, en, $event) { var ad = true; if (("blur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11)._focusChanged(false) !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11)._focusChanged(true) !== false);
        ad = (pd_1 && ad);
    } if (("input" === en)) {
        var pd_2 = (i1.ɵnov(_v, 11)._onInput() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵprd(6144, null, i3.MatFormFieldControl, null, [i4.MatInput]), i1.ɵdid(11, 999424, null, 0, i4.MatInput, [i1.ElementRef, i5.Platform, [8, null], [2, i6.NgForm], [2, i6.FormGroupDirective], i7.ErrorStateMatcher, [8, null], i8.AutofillMonitor, i1.NgZone], { placeholder: [0, "placeholder"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(13, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 2, "button", [["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.email() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_MatButton_0, i9.RenderType_MatButton)), i1.ɵdid(15, 180224, null, 0, i10.MatButton, [i1.ElementRef, i5.Platform, i11.FocusMonitor, [2, i12.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Email"]))], function (_ck, _v) { _ck(_v, 3, 0); var currVal_11 = "email address"; _ck(_v, 11, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).id; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 11)._isServer; var currVal_3 = i1.ɵnov(_v, 11).id; var currVal_4 = i1.ɵnov(_v, 11).placeholder; var currVal_5 = i1.ɵnov(_v, 11).disabled; var currVal_6 = i1.ɵnov(_v, 11).required; var currVal_7 = i1.ɵnov(_v, 11).readonly; var currVal_8 = (i1.ɵnov(_v, 11)._ariaDescribedby || null); var currVal_9 = i1.ɵnov(_v, 11).errorState; var currVal_10 = i1.ɵnov(_v, 11).required.toString(); _ck(_v, 9, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_12 = (i1.ɵnov(_v, 15).disabled || null); var currVal_13 = (i1.ɵnov(_v, 15)._animationMode === "NoopAnimations"); _ck(_v, 14, 0, currVal_12, currVal_13); }); }
export function View_ResultFileDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-result-file-dialog", [], null, null, null, View_ResultFileDialogComponent_0, RenderType_ResultFileDialogComponent)), i1.ɵdid(1, 245760, null, 0, i13.ResultFileDialogComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA, i14.AuditService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ResultFileDialogComponentNgFactory = i1.ɵccf("app-result-file-dialog", i13.ResultFileDialogComponent, View_ResultFileDialogComponent_Host_0, {}, {}, []);
export { ResultFileDialogComponentNgFactory as ResultFileDialogComponentNgFactory };
