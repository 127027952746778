import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { ActivatedRoute, Router } from "@angular/router";
import { SapConsignmentInventoryImportListDataSource } from './sap-consignment-inventory-import-list-datasource';
import { ConsignmentInventoryImportService } from "./../../services/consignment-inventory-import.service";
import { SapConsignmentInventoryHelper } from '../../helpers/sap-consignment-inventory-helper';
import { IsapConsignmentInventoryItem } from '../../interfaces/isap-consignment-inventory-item';

@Component({
  selector: 'app-components/sap-consignment-inventory-import-list',
  templateUrl: './sap-consignment-inventory-import-list.component.html',
  styleUrls: ['./sap-consignment-inventory-import-list.component.css']
})
export class SapConsignmentInventoryImportListComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: SapConsignmentInventoryImportListDataSource;
  audit_id: number;
  sapConsignmentInventoryItems: IsapConsignmentInventoryItem[]
  consignmentItemHelper: SapConsignmentInventoryHelper = new SapConsignmentInventoryHelper();
  /**
   *
   */
  constructor(
    private _consignmentInvImpService: ConsignmentInventoryImportService,
    private _route: ActivatedRoute,
    private _router: Router) {

  }
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ["part_number", "description", "lot_number", "serial_number", "quantity", "status"];

  ngOnDestroy(): void {

  }
  ngOnInit() {

    this.audit_id = +this._route.snapshot.paramMap.get("audit_id");
    this._consignmentInvImpService.getInventory(this.audit_id, false).subscribe(data => {
      this.sapConsignmentInventoryItems = this.consignmentItemHelper.populateItems(data)
      this.dataSource = new SapConsignmentInventoryImportListDataSource(this.paginator, this.sort, this.sapConsignmentInventoryItems);

    })
  }

  goToAuditDashboard() {
    this._router.navigate(['audit_dashboard', this.audit_id]);
  }
}
