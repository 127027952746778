import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
var AudioPlayerService = /** @class */ (function () {
    function AudioPlayerService() {
        this.settingsKey = 'currentCountSheetAlertSetting';
        this.setting = localStorage.getItem(this.settingsKey);
    }
    AudioPlayerService.prototype.changeAudioSetting = function (setting) {
        this.setting = setting;
        localStorage.setItem(this.settingsKey, this.setting);
    };
    AudioPlayerService.ngInjectableDef = i0.defineInjectable({ factory: function AudioPlayerService_Factory() { return new AudioPlayerService(); }, token: AudioPlayerService, providedIn: "root" });
    return AudioPlayerService;
}());
export { AudioPlayerService };
