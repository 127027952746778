import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { urls } from '../helpers/urls';
import { IOktaConfig } from '../interfaces/i-okta-config';
import { UUID } from 'angular2-uuid';
import { IAccessTokenResponse } from '../interfaces/i-access-token-response';
import { AccessTokenService } from './access-token.service';

@Injectable({
  providedIn: 'root'
})
export class OktaConfigService {

  alphabet = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  configDataKey = 'OKTA_CONFIG_DATA';
  verifierKey = 'VERIFIER';
  isInternalKey = 'LOGIN_IS_INTERNAL';
  configData: IOktaConfig;
  currentTokenData: IAccessTokenResponse;

  constructor(private _http: HttpClient, private _accesstokenService: AccessTokenService) {
    if (!this.configData) {
      this.getAndStoreConfigData();
    }
  }

  getConfigData() {
    let path = `${environment.serverPath}${urls.oktaConfigData}`;
    return this._http.get<any>(path);
  }

  getAndStoreConfigData() {
    this._http.get<any>(`${environment.serverPath}${urls.oktaConfigData}`).subscribe((data: IOktaConfig) => {
      this.configData = data;
      this.saveToLocal(data);
    });
  }

  getFromLocal() {
    if (this.configData) {
      return this.configData;
    } else {
      const configString = localStorage.getItem(this.configDataKey);
      if (configString) {
        this.configData = JSON.parse(configString);
      }
      return this.configData;
    }
  }

  saveToLocal(data: IOktaConfig) {
    if (data) {
      this.configData = data;
      localStorage.setItem(this.configDataKey, JSON.stringify(data));
    }
  }

  async makeExternalLoginUri(state?: string) {
    state = state ? state : document.location.origin;
    let verifier = this.generateRandomString();
    var challenge = await this.pkceChallengeFromVerifier(verifier);
    if(this.configData === null || this.configData === undefined) {
      this.configData = this.getFromLocal();
    }
    let scope =  this.configData.audit_scope;
    if (this.configData.refresh_token_enabled) {
      scope = scope + ' offline_access';
    }

    let login_form_url = `${this.configData.non_arthrex_employee_base_url}${this.configData.get_id_token}?client_id=${encodeURIComponent(this.configData.audit_external_client_id)}&redirect_uri=${encodeURIComponent(this.configData.audit_login_redirect_uri)}&response_type=${encodeURIComponent(this.configData.response_type)}&code_challenge_method=${encodeURIComponent(this.configData.audit_code_challenge_method)}&code_challenge=${encodeURIComponent(challenge)}&scope=${encodeURIComponent(scope)}&state=${encodeURIComponent(state)}`;

    this.storeVerifier(verifier);
    return login_form_url;
  }

  storeVerifier(verifier: string) {
    localStorage.setItem(this.verifierKey, verifier);
  }

  getVerifier() {
    return localStorage.getItem(this.verifierKey);
  }

  async makeInternalLoginUri(state?: string) {
    state = state ? state : document.location.origin;
    let verifier = this.generateRandomString();
    var challenge = await this.pkceChallengeFromVerifier(verifier);
    if(this.configData === null || this.configData === undefined) {
      this.configData = this.getFromLocal();
    }

    let scope =  this.configData.audit_scope;
    if (this.configData.refresh_token_enabled) {
      scope = scope + ' offline_access';
    }

    let login_form_url = `${this.configData.arthrex_employee_base_url}${this.configData.get_id_token}?client_id=${encodeURIComponent(this.configData.audit_internal_client_id)}&redirect_uri=${encodeURIComponent(this.configData.audit_login_redirect_uri)}&response_type=${encodeURIComponent(this.configData.response_type)}&code_challenge_method=${encodeURIComponent(this.configData.audit_code_challenge_method)}&code_challenge=${encodeURIComponent(challenge)}&scope=${encodeURIComponent(scope)}&state=${encodeURIComponent(state)}`;
    this.storeVerifier(verifier);
    return login_form_url;
  }

  generateNonce() {
    return UUID.UUID();
  }

  generateRandomString() {
    var array = new Uint32Array(28);
    window.crypto.getRandomValues(array);
    return Array.from(array, dec => ('0' + dec.toString(16)).slice(-2)).join('');
  }

  // Calculate the SHA256 hash of the input text. 
  // Returns a promise that resolves to an ArrayBuffer
  sha256(plain: string) {
    const encoder = new (window as any).TextEncoder();
    const data = encoder.encode(plain);
    return window.crypto.subtle.digest('SHA-256', data);
  }

  // Base64-urlencodes the input string
  base64urlencode(str: ArrayBuffer) {
    // Convert the ArrayBuffer to string using Uint8 array to conver to what btoa accepts.
    // btoa accepts chars only within ascii 0-255 and base64 encodes them.
    // Then convert the base64 encoded to base64url encoded
    //   (replace + with -, replace / with _, trim trailing =)
    return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
      .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  }

  // Return the base64-urlencoded sha256 hash for the PKCE challenge
  async pkceChallengeFromVerifier(v: string) {
    let hashed = await this.sha256(v);
    return this.base64urlencode(hashed);
  }

  makeInternalLogoutUrl() {
    let tokenData = this._accesstokenService.getTokenData()
    if (!this.configData) {
      this.configData = this.getFromLocal();
    }
    let logout_url = `${this.configData.arthrex_employee_base_url}${this.configData.logout_endpoint}?id_token_hint=${tokenData.id_token}&post_logout_redirect_uri=${this.configData.audit_logout_redirect_uri}`;
    return logout_url;
  }

  makeExternalLogoutUrl() {
    let tokenData = this._accesstokenService.getTokenData()
    if (!this.configData) {
      this.configData = this.getFromLocal()
    }
    let logout_url = `${this.configData.non_arthrex_employee_base_url}${this.configData.logout_endpoint}?id_token_hint=${tokenData.id_token}&post_logout_redirect_uri=${this.configData.audit_logout_redirect_uri}`;
    return logout_url;
  }

  revokeToken() {
    let tokenData = this._accesstokenService.getTokenData()
    let path = `${environment.serverPath}${urls.revokeInternalToken}`;
    let body = { access_token: tokenData.access_token };
    return this._http.post(path, body);
  }
}
