var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { UserDataHelper } from "./../../helpers/user-data-helper";
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';
import { MatSnackBar } from '@angular/material';
import { OnlineService } from "./../../services/online.service";
import { OktaConfigService } from '../../services/okta-config.service';
import { CONSTANTS } from 'src/app/helpers/constants';
import { Router } from '@angular/router';
import { AccessTokenService } from 'src/app/services/access-token.service';
var AuditLoginComponent = /** @class */ (function () {
    function AuditLoginComponent(_onlineService, _router, snackBar, _oktaConfigService, _accessTokenService) {
        this._onlineService = _onlineService;
        this._router = _router;
        this.snackBar = snackBar;
        this._oktaConfigService = _oktaConfigService;
        this._accessTokenService = _accessTokenService;
        this.userDataHelper = new UserDataHelper();
        this.snackBarConfig = {
            message: "",
            duration: 3000,
            success: false,
            snackBarClass: ""
        };
        this.failedState = false;
        this.isLoggedIn = false;
    }
    AuditLoginComponent.prototype.ngOnInit = function () {
        var tokenData = this._accessTokenService.getTokenData();
        if (tokenData.access_token && !this._accessTokenService.isExpired(tokenData)) {
            this._router.navigate([CONSTANTS.routes.AUDITS]);
        }
    };
    AuditLoginComponent.prototype.externalLogin = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                if (this._onlineService.isOnline()) {
                    this._oktaConfigService.getConfigData().subscribe(function (oktaConfigData) { return __awaiter(_this, void 0, void 0, function () {
                        var state, url;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    this._oktaConfigService.saveToLocal(oktaConfigData);
                                    state = document.location.origin;
                                    return [4 /*yield*/, this._oktaConfigService.makeExternalLoginUri(state)];
                                case 1:
                                    url = _a.sent();
                                    // get the router to navigate to the url
                                    localStorage.setItem(this._oktaConfigService.isInternalKey, false.toString());
                                    document.location.href = url;
                                    return [2 /*return*/];
                            }
                        });
                    }); });
                }
                else {
                    this.failedState = true;
                    this.snackBarConfig.message = CONSTANTS.messages.MUST_BE_ONLINE;
                    this.snackBarConfig.success = false;
                    this.openSnackBar();
                }
                return [2 /*return*/];
            });
        });
    };
    AuditLoginComponent.prototype.internalLogin = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                if (this._onlineService.isOnline()) {
                    this._oktaConfigService.getConfigData().subscribe(function (oktaConfigData) { return __awaiter(_this, void 0, void 0, function () {
                        var state, url;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    this._oktaConfigService.saveToLocal(oktaConfigData);
                                    state = document.location.origin;
                                    return [4 /*yield*/, this._oktaConfigService.makeInternalLoginUri(state)];
                                case 1:
                                    url = _a.sent();
                                    // get the router to navigate to the url
                                    localStorage.setItem(this._oktaConfigService.isInternalKey, true.toString());
                                    document.location.href = url;
                                    return [2 /*return*/];
                            }
                        });
                    }); });
                }
                else {
                    this.failedState = true;
                    this.snackBarConfig.message = CONSTANTS.messages.MUST_BE_ONLINE;
                    this.snackBarConfig.success = false;
                    this.openSnackBar();
                }
                return [2 /*return*/];
            });
        });
    };
    AuditLoginComponent.prototype.openSnackBar = function () {
        this.snackBar.openFromComponent(MaterialSnackbarComponent, {
            data: this.snackBarConfig,
            duration: this.snackBarConfig.duration
        });
    };
    return AuditLoginComponent;
}());
export { AuditLoginComponent };
