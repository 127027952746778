var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { FileSystemService } from 'src/app/services/file-system.service';
import { CountSheetAttachmentService } from 'src/app/services/count-sheet-attachment.service';
import { FileOptionUtils } from 'src/app/helpers/file-option-utils';
import { environment } from 'src/environments/environment';
import { AttachmentFileListComponent } from '../attachment-file-list/attachment-file-list.component';
import { OnlineService } from 'src/app/services/online.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TrackLoadingService } from 'src/app/services/track-loading.service';
import { MaterialIcons } from 'src/app/helpers/material-icons';
import { FileDatabaseService } from 'src/app/services/file-database.service';
import { AttachmentDatabaseService } from 'src/app/services/attachment-database.service';
import * as moment from 'moment';
import { FileCacheService } from 'src/app/services/file-cache.service';
import { CameraService } from 'src/app/services/camera.service';
import { FeatureSupportService } from 'src/app/services/feature-support.service';
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';
import { Messages } from 'src/app/helpers/messages';
import { ErrorMessages } from 'src/app/helpers/error-messages';
var AttachmentDialogComponent = /** @class */ (function () {
    function AttachmentDialogComponent(dialogRef, data, _fs, _countSheetAttachmentService, _fileOptionUtils, _onlineService, _trackLoadingService, _sanitizer, _changeDetector, _fileDatabaseService, _attachmentDatabaseService, _fileCacheService, _cameraService, _featureSupport, snackBar) {
        this.dialogRef = dialogRef;
        this.data = data;
        this._fs = _fs;
        this._countSheetAttachmentService = _countSheetAttachmentService;
        this._fileOptionUtils = _fileOptionUtils;
        this._onlineService = _onlineService;
        this._trackLoadingService = _trackLoadingService;
        this._sanitizer = _sanitizer;
        this._changeDetector = _changeDetector;
        this._fileDatabaseService = _fileDatabaseService;
        this._attachmentDatabaseService = _attachmentDatabaseService;
        this._fileCacheService = _fileCacheService;
        this._cameraService = _cameraService;
        this._featureSupport = _featureSupport;
        this.snackBar = snackBar;
        this.attachments = [];
        this.cameraDevices = [];
        this.showFileList = false;
        this.showFileView = false;
        this.showSingleFileAttacher = true;
        this.noLocalHandle = false;
        this.fileNotViewable = false;
        this.currentFileHasLocalHandle = false;
        this.cameraEnabled = false;
        this.canTakePicture = false;
        this.camerasAvailable = false;
        this.zoomingEnabled = false;
        this.zoom = 2;
        this.pdfOptions = {
            icon: MaterialIcons.fileDownload,
            text: 'Download'
        };
        this.topButtonOptions = {
            icon: MaterialIcons.fileDownload,
            text: 'Download'
        };
        var that = this;
        this.cameraDevices = data.devices;
        if (this.cameraDevices.length > 0) {
            this.camerasAvailable = true;
        }
        that.countSheet = data.countsheet;
        that._trackLoadingService.startLoading('attachment-dialog', 'Loading Attachments');
        that.getAttachments(that.countSheet.data.audit_id, that.countSheet.id).then(function (attachments) {
            that._trackLoadingService.stopLoading('attachment-dialog');
            that.attachments = attachments;
            that.setLayout();
            that.attachments.forEach(function (f, index) {
                if (index === 0) {
                    f.selected = true;
                }
                else {
                    f.selected = false;
                }
            });
        });
    }
    AttachmentDialogComponent.prototype.getAttachments = function (auditId, countSheetClientId) {
        var that = this;
        return new Promise(function (resolve, reject) {
            if (that._onlineService.isOnline() && !that._onlineService.getTestOfflineBool()) {
                that._countSheetAttachmentService.getAttachments(auditId, { count_sheet_client_id: countSheetClientId }).subscribe(function (data) {
                    resolve(data);
                    that._countSheetAttachmentService.refreshOfflineDataForCountSheet(auditId, countSheetClientId, data).then(function (attachments) {
                        that.attachments = attachments;
                    });
                });
            }
            else {
                that._attachmentDatabaseService.find(that.countSheet.data.audit_id, { count_sheet_client_id: that.countSheet.id, toRemove: false }).then(function (attachments) {
                    resolve(attachments);
                });
            }
        });
    };
    AttachmentDialogComponent.prototype.ngOnDestroy = function () {
        if (this.onlineSubscription) {
            this.onlineSubscription.unsubscribe();
        }
        if (this.getImageSubscription) {
            this.getImageSubscription.unsubscribe();
        }
        this._cameraService.closeCamera();
    };
    AttachmentDialogComponent.prototype.setLayout = function () {
        if (!this.cameraEnabled) {
            if (this.attachments.length === 0) {
                this.showFileList = false;
                this.showFileView = false;
                this.showSingleFileAttacher = true;
                this.currentFile = null;
            }
            else {
                this.showFileList = true;
                this.showFileView = true;
                this.showSingleFileAttacher = false;
            }
        }
        else {
            this.showFileList = false;
            this.showFileView = true;
            this.showSingleFileAttacher = false;
            this.currentFile = null;
            this.pdfUrl = null;
            this.imageUrl = null;
        }
        if (this.zoomingEnabled) {
            this.zoomingEnabled = false;
            this._changeDetector.detectChanges();
        }
    };
    AttachmentDialogComponent.prototype.createFileInput = function () {
        var input = document.createElement('input');
        input.type = 'file';
        return input;
    };
    AttachmentDialogComponent.prototype.openFilePicker = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var that, options, fileInput, fileHandle, file;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        that = this;
                        options = this._fileOptionUtils.auditOptions();
                        fileInput = this.createFileInput();
                        fileHandle = null;
                        if (!this._featureSupport.isFireFox()) return [3 /*break*/, 1];
                        fileInput.onchange = function (event) { return __awaiter(_this, void 0, void 0, function () {
                            var file;
                            return __generator(this, function (_a) {
                                fileHandle = fileInput.files;
                                if (!fileHandle) {
                                    return [2 /*return*/];
                                }
                                if (fileHandle.length === 0) {
                                    return [2 /*return*/];
                                }
                                file = event.target.files[0];
                                if (!file) {
                                    return [2 /*return*/];
                                }
                                this.uploadFile(fileHandle, file);
                                return [2 /*return*/];
                            });
                        }); };
                        fileInput.click();
                        return [3 /*break*/, 4];
                    case 1: return [4 /*yield*/, that._fs.pickFile(options)];
                    case 2:
                        fileHandle = _a.sent();
                        if (!fileHandle) {
                            return [2 /*return*/];
                        }
                        if (fileHandle.length === 0) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, that._fs.getFile(fileHandle[0])];
                    case 3:
                        file = _a.sent();
                        if (!file) {
                            return [2 /*return*/];
                        }
                        this.uploadFile(fileHandle, file);
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AttachmentDialogComponent.prototype.uploadFile = function (fileHandle, file) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var that;
            return __generator(this, function (_a) {
                that = this;
                that._trackLoadingService.startLoading('attachment-dialog', 'Adding Attachment');
                that.onlineSubscription = that._onlineService.isOnlineSubscription().subscribe(function (online) {
                    that.online = online && !that._onlineService.getTestOfflineBool();
                    if (that.online) {
                        that._countSheetAttachmentService.addAttachment(file, that.countSheet.id).subscribe(function (data) {
                            var attachment = that._attachmentDatabaseService.new(data);
                            that._attachmentDatabaseService.add(attachment).then(function (id) {
                                that._fileDatabaseService.add({ local_file_id: id, fileHandle: fileHandle[0], file: file }).then(function () {
                                    // cache filehandle
                                    that._fileCacheService.cache[id.toString()] = { handle: fileHandle[0], file: file };
                                    that.attachments.push(attachment);
                                    that.setLayout();
                                    that._changeDetector.detectChanges();
                                    that.attachmentList.attachments = _this.attachments;
                                    that.attachmentList.ngOnInit();
                                    that._trackLoadingService.stopLoading('attachment-dialog');
                                    _this.viewFile(attachment);
                                });
                            });
                        }, function (error) {
                            that._trackLoadingService.stopLoading('attachment-dialog');
                            if (error.error.toLowerCase() === ErrorMessages.badFileType) {
                                that.openSnackBar(that.makeConfig(Messages.unsupportedFileType, false));
                            }
                        });
                    }
                    else {
                        if (_this._fileOptionUtils.isSupported(file.name)) {
                            var now = moment();
                            var created_date = now.format();
                            var formatted_created_at = now.format('MM/DD/YYYY LT');
                            var formatted_created_at_date = now.format('MM/DD/YYYY');
                            var attachment_1 = that._attachmentDatabaseService.new({
                                attachment_content_type: file.type,
                                attachment_file_name: file.name,
                                attachment_file_size: file.size,
                                attachment_updated_at: file.lastModified.toString(),
                                formatted_created_at: formatted_created_at,
                                formatted_created_at_date: formatted_created_at_date,
                                count_sheet_id: that.countSheet.dbId,
                                count_sheet_client_id: that.countSheet.id,
                                audit_id: that.countSheet.data.audit_id,
                                created_at: created_date,
                                is_active: true,
                                synchronized: 0
                            });
                            that._attachmentDatabaseService.add(attachment_1).then(function (id) {
                                that._fileDatabaseService.add({ local_file_id: id, fileHandle: fileHandle[0], file: file }).then(function () {
                                    // cache filehandle
                                    that._fileCacheService.cache[id] = { handle: fileHandle[0], file: file };
                                    that.attachments.push(attachment_1);
                                    that.setLayout();
                                    that._changeDetector.detectChanges();
                                    that.attachmentList.attachments = _this.attachments;
                                    that.attachmentList.ngOnInit();
                                    that._trackLoadingService.stopLoading('attachment-dialog');
                                    _this.viewFile(attachment_1);
                                });
                            });
                        }
                        else {
                            _this._trackLoadingService.stopLoading('attachment-dialog');
                            _this.openSnackBar(_this.makeConfig(Messages.unsupportedFileType, false));
                        }
                    }
                });
                this.onlineSubscription.unsubscribe();
                return [2 /*return*/];
            });
        });
    };
    AttachmentDialogComponent.prototype.ngOnInit = function () {
        this._changeDetector.detectChanges();
        if (this.camerasAvailable && !this.cameraEnabled) {
            this.openCamera();
        }
    };
    AttachmentDialogComponent.prototype.viewFile = function (file) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var extension, isImage, isPdf;
            return __generator(this, function (_a) {
                this.setFileListSelection(file);
                this.zoomingEnabled = false;
                this.noLocalHandle = false;
                this.fileNotViewable = false;
                this.currentFile = file;
                extension = this._fileOptionUtils.getExtensionFromName(file.attachment_file_name);
                isImage = this._fileOptionUtils.isImage(extension);
                isPdf = this._fileOptionUtils.isPDF(extension);
                this.onlineSubscription = this._onlineService.isOnlineSubscription().subscribe(function (online) { return __awaiter(_this, void 0, void 0, function () {
                    var _this = this;
                    var that, handle, fileBlob, downloadUrl, that, handle, fileBlob, that;
                    return __generator(this, function (_a) {
                        this.online = online && !this._onlineService.getTestOfflineBool();
                        if (online && !this._onlineService.getTestOfflineBool()) {
                            this.topButtonOptions.icon = MaterialIcons.fileDownload;
                            this.topButtonOptions.text = 'Download';
                            if (isImage) {
                                this.pdfUrl = '';
                                this.currentlySelectedFileName = file.attachment_file_name;
                                this._trackLoadingService.startLoading('attachment-dialog', "Loading " + file.attachment_file_name);
                                this.getImageSubscription = this._countSheetAttachmentService.getImage(file).subscribe(function (response) {
                                    _this.imageUrl = _this._sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(response.body));
                                    _this._trackLoadingService.stopLoading('attachment-dialog');
                                });
                            }
                            else {
                                this.imageUrl = '';
                                this.pdfOptions.icon = MaterialIcons.fileDownload;
                                this.pdfOptions.text = 'Download';
                                this.currentlySelectedFileName = file.attachment_file_name;
                                this.pdfUrl = environment.serverPath + 'attachments/' + file.id;
                            }
                        }
                        else {
                            this.topButtonOptions.icon = MaterialIcons.removeRedEye;
                            this.topButtonOptions.text = 'View';
                            this.hasLocalHandle();
                            if (isImage) {
                                this.pdfUrl = '';
                                this.currentlySelectedFileName = file.attachment_file_name;
                                that = this;
                                if (file.local_file_id) {
                                    this._trackLoadingService.startLoading('attachment-dialog', "Loading " + file.attachment_file_name);
                                    handle = this._fileCacheService.getHandle(file.local_file_id);
                                    if (handle) {
                                        fileBlob = handle.file;
                                        downloadUrl = URL.createObjectURL(fileBlob);
                                        this.imageUrl = this._sanitizer.bypassSecurityTrustResourceUrl(downloadUrl);
                                        this._trackLoadingService.stopLoading('attachment-dialog');
                                    }
                                    else {
                                        this._fileDatabaseService.get(file.local_file_id).then(function (fileHandle) { return __awaiter(_this, void 0, void 0, function () {
                                            var fileBlob, downloadUrl;
                                            return __generator(this, function (_a) {
                                                if (fileHandle) {
                                                    fileBlob = fileHandle.file;
                                                    downloadUrl = URL.createObjectURL(fileBlob);
                                                    this.imageUrl = this._sanitizer.bypassSecurityTrustResourceUrl(downloadUrl);
                                                    this._trackLoadingService.stopLoading('attachment-dialog');
                                                }
                                                else {
                                                    this.pdfUrl = '';
                                                    this.imageUrl = '';
                                                    this.noLocalHandle = true;
                                                    this._trackLoadingService.stopLoading('attachment-dialog');
                                                }
                                                return [2 /*return*/];
                                            });
                                        }); }).catch(function (error) {
                                            _this.pdfUrl = '';
                                            _this.imageUrl = '';
                                            _this.noLocalHandle = true;
                                            _this._trackLoadingService.stopLoading('attachment-dialog');
                                        });
                                    }
                                }
                                else {
                                    this.pdfUrl = '';
                                    this.imageUrl = '';
                                    this.noLocalHandle = true;
                                }
                            }
                            else if (isPdf) {
                                this.imageUrl = '';
                                this.currentlySelectedFileName = file.attachment_file_name;
                                that = this;
                                this._trackLoadingService.startLoading('attachment-dialog', "Loading " + file.attachment_file_name);
                                if (file.local_file_id) {
                                    handle = this._fileCacheService.getHandle(file.local_file_id);
                                    if (handle) {
                                        this.pdfOptions.icon = MaterialIcons.removeRedEye;
                                        this.pdfOptions.text = 'View';
                                        fileBlob = handle.file;
                                        this.pdfUrl = URL.createObjectURL(fileBlob);
                                        this._trackLoadingService.stopLoading('attachment-dialog');
                                    }
                                    else {
                                        this._fileDatabaseService.get(file.local_file_id).then(function (fileHandle) { return __awaiter(_this, void 0, void 0, function () {
                                            var fileBlob;
                                            return __generator(this, function (_a) {
                                                if (fileHandle) {
                                                    this.pdfOptions.icon = MaterialIcons.removeRedEye;
                                                    this.pdfOptions.text = 'View';
                                                    fileBlob = fileHandle.file;
                                                    this.pdfUrl = URL.createObjectURL(fileBlob);
                                                    this._trackLoadingService.stopLoading('attachment-dialog');
                                                }
                                                else {
                                                    this.pdfUrl = '';
                                                    this.imageUrl = '';
                                                    this.noLocalHandle = true;
                                                    this._trackLoadingService.stopLoading('attachment-dialog');
                                                }
                                                return [2 /*return*/];
                                            });
                                        }); }).catch(function (error) {
                                            _this.pdfUrl = '';
                                            _this.imageUrl = '';
                                            _this.noLocalHandle = true;
                                            _this._trackLoadingService.stopLoading('attachment-dialog');
                                        });
                                    }
                                }
                                else {
                                    this.pdfUrl = '';
                                    this.imageUrl = '';
                                    this.noLocalHandle = true;
                                }
                            }
                            else {
                                this.imageUrl = '';
                                this.pdfUrl = '';
                                this.currentlySelectedFileName = file.attachment_file_name;
                                that = this;
                                if (file.local_file_id) {
                                    this.fileNotViewable = true;
                                }
                                else {
                                    this.noLocalHandle = true;
                                    this._trackLoadingService.stopLoading('attachment-dialog');
                                }
                            }
                        }
                        return [2 /*return*/];
                    });
                }); });
                this.onlineSubscription.unsubscribe();
                return [2 /*return*/];
            });
        });
    };
    AttachmentDialogComponent.prototype.download = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.onlineSubscription = this._onlineService.isOnlineSubscription().subscribe(function (online) { return __awaiter(_this, void 0, void 0, function () {
                    var _this = this;
                    var extension, isImage, isPdf, handle, fileBlob, downloadUrl, handle, imageBlob, downloadUrl;
                    return __generator(this, function (_a) {
                        this.online = online && !this._onlineService.getTestOfflineBool();
                        if (online && !this._onlineService.getTestOfflineBool()) {
                            this._countSheetAttachmentService.getAttachment(this.currentFile);
                        }
                        else {
                            // Offline, if no handle, cannot download
                            if (this.currentFile.local_file_id) {
                                extension = this._fileOptionUtils.getExtensionFromName(this.currentFile.attachment_file_name);
                                isImage = this._fileOptionUtils.isImage(extension);
                                isPdf = this._fileOptionUtils.isPDF(extension);
                                if (isPdf) {
                                    handle = this._fileCacheService.getHandle(this.currentFile.local_file_id.toString());
                                    if (handle) {
                                        fileBlob = handle.file;
                                        downloadUrl = URL.createObjectURL(fileBlob);
                                        window.open(downloadUrl, '_blank');
                                    }
                                    else {
                                        this._fileDatabaseService.get(this.currentFile.local_file_id).then(function (fileHandle) { return __awaiter(_this, void 0, void 0, function () {
                                            var fileBlob, downloadUrl;
                                            return __generator(this, function (_a) {
                                                if (fileHandle) {
                                                    fileBlob = fileHandle.file;
                                                    downloadUrl = URL.createObjectURL(fileBlob);
                                                    window.open(downloadUrl, '_blank');
                                                }
                                                else {
                                                }
                                                return [2 /*return*/];
                                            });
                                        }); });
                                    }
                                }
                                if (isImage) {
                                    handle = this._fileCacheService.getHandle(this.currentFile.local_file_id);
                                    if (handle) {
                                        imageBlob = handle.file;
                                        downloadUrl = URL.createObjectURL(imageBlob);
                                        window.open(downloadUrl, '_blank');
                                    }
                                    else {
                                        this._fileDatabaseService.get(this.currentFile.local_file_id).then(function (fileHandle) { return __awaiter(_this, void 0, void 0, function () {
                                            var imageBlob, downloadUrl;
                                            return __generator(this, function (_a) {
                                                if (fileHandle) {
                                                    imageBlob = fileHandle.file;
                                                    downloadUrl = URL.createObjectURL(imageBlob);
                                                    window.open(downloadUrl, '_blank');
                                                }
                                                else {
                                                }
                                                return [2 /*return*/];
                                            });
                                        }); });
                                    }
                                }
                                else {
                                }
                            }
                        }
                        return [2 /*return*/];
                    });
                }); });
                this.onlineSubscription.unsubscribe();
                return [2 /*return*/];
            });
        });
    };
    AttachmentDialogComponent.prototype.deleteAttachment = function (file) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var that;
            return __generator(this, function (_a) {
                that = this;
                that.onlineSubscription = that._onlineService.isOnlineSubscription().subscribe(function (online) { return __awaiter(_this, void 0, void 0, function () {
                    var _this = this;
                    return __generator(this, function (_a) {
                        that.online = online && !that._onlineService.getTestOfflineBool();
                        if (online && !that._onlineService.getTestOfflineBool()) {
                            that._countSheetAttachmentService.deleteAttachment(file).subscribe(function (d) {
                                that.attachments = _this.attachments.filter(function (a) {
                                    return a.local_file_id !== file.local_file_id;
                                });
                                that._attachmentDatabaseService.delete(file.local_file_id).then(function () {
                                    that._fileDatabaseService.delete(file.local_file_id).then(function () {
                                        that.attachmentList.attachments = that.attachments;
                                        that.attachmentList.ngOnInit();
                                        that.setLayout();
                                        if (_this.attachments.length > 0) {
                                            _this.viewFile(_this.attachments[0]);
                                        }
                                    });
                                });
                            });
                        }
                        else if (file.id) {
                            that.attachments = this.attachments.filter(function (a) {
                                return a.local_file_id !== file.local_file_id;
                            });
                            file.toRemove = true;
                            file.synchronized = 0;
                            that._attachmentDatabaseService.update(file).then(function () {
                                that.attachmentList.ngOnInit();
                                that.setLayout();
                                var canbeViewed = _this.attachments.filter(function (a) {
                                    return !a.toRemove;
                                });
                                if (canbeViewed.length > 0) {
                                    _this.viewFile(canbeViewed[0]);
                                }
                            });
                        }
                        else {
                            that.attachments = this.attachments.filter(function (a) {
                                return a.local_file_id !== file.local_file_id;
                            });
                            that._attachmentDatabaseService.delete(file.local_file_id).then(function () {
                                that._fileDatabaseService.delete(file.local_file_id).then(function () {
                                    that.attachmentList.attachments = that.attachments;
                                    that.attachmentList.ngOnInit();
                                    that.setLayout();
                                    var canbeViewed = _this.attachments.filter(function (a) {
                                        return !a.toRemove;
                                    });
                                    if (canbeViewed.length > 0) {
                                        _this.viewFile(canbeViewed[0]);
                                    }
                                });
                            });
                        }
                        return [2 /*return*/];
                    });
                }); });
                this.onlineSubscription.unsubscribe();
                return [2 /*return*/];
            });
        });
    };
    AttachmentDialogComponent.prototype.hasLocalHandle = function () {
        var _this = this;
        if (this.currentFile.local_file_id) {
            this._fileDatabaseService.get(this.currentFile.local_file_id).then(function (fileHandle) {
                if (fileHandle) {
                    _this.currentFileHasLocalHandle = true;
                }
                else {
                    _this.currentFileHasLocalHandle = false;
                }
            }).catch(function (error) {
                _this.currentFileHasLocalHandle = false;
            });
        }
        else {
            this.currentFileHasLocalHandle = false;
        }
    };
    AttachmentDialogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    AttachmentDialogComponent.prototype.openCamera = function () {
        var _this = this;
        this.pdfUrl = null;
        this.imageUrl = null;
        this.currentFile = null;
        this.cameraEnabled = true;
        this.noLocalHandle = false;
        this.fileNotViewable = false;
        this.setLayout();
        this._changeDetector.detectChanges();
        this.video = this.videoElement.nativeElement;
        this._cameraService.initCamera(this.video, function (device) {
            _this.selectedCamera = device;
            _this.canTakePicture = true;
        });
    };
    AttachmentDialogComponent.prototype.closeCamera = function (attachment) {
        var _this = this;
        if (attachment === void 0) { attachment = null; }
        this.cameraEnabled = false;
        this.canTakePicture = false;
        this.setLayout();
        this._cameraService.closeCamera(function () {
            if (attachment) {
                _this.viewFile(attachment);
            }
            else {
                var canbeViewed = _this.attachments.filter(function (a) {
                    return !a.toRemove;
                });
                if (canbeViewed.length > 0) {
                    _this.viewFile(canbeViewed[0]);
                }
            }
        });
    };
    AttachmentDialogComponent.prototype.dec2hex = function (dec) {
        return dec.toString(16).padStart(2, "0");
    };
    AttachmentDialogComponent.prototype.makeImageFileName = function () {
        var auditId = this.countSheet.data.audit_id;
        var arr = new Uint8Array((8 || 40) / 2);
        var randomArray = window.crypto.getRandomValues(arr);
        var id = Array.from(randomArray, this.dec2hex).join('');
        var name = "";
        if (this.countSheet.data.isKitted) {
            var kitReference = this.countSheet.data.reference;
            var kitLot = this.countSheet.data.lot_number;
            if (kitLot) {
                kitReference += "_" + kitLot;
            }
            // AUDITID_KITREF_KITLOT_RANDOMSTRING
            name += auditId + "_" + kitReference + "_" + id + ".png";
        }
        else {
            // AUDITID_AREA_RANDOMSTRING
            name += auditId + "_" + this.countSheet.data.area + "_" + id + ".png";
        }
        return name.toLowerCase();
    };
    AttachmentDialogComponent.prototype.takePicture = function () {
        var _this = this;
        var imageName = this.makeImageFileName();
        this._cameraService.takePic(imageName, function (file) {
            if (file) {
                _this.imageTaken = true;
                _this.takenImageFile = file;
            }
        });
    };
    AttachmentDialogComponent.prototype.saveImage = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var that;
            return __generator(this, function (_a) {
                that = this;
                this._trackLoadingService.startLoading('attachment-dialog', 'Adding Attachment');
                this.onlineSubscription = this._onlineService.isOnlineSubscription().subscribe(function (online) {
                    _this.online = online && !_this._onlineService.getTestOfflineBool();
                    if (_this.online) {
                        that._countSheetAttachmentService.addAttachment(_this.takenImageFile, that.countSheet.id).subscribe(function (data) {
                            var attachment = that._attachmentDatabaseService.new(data);
                            that._attachmentDatabaseService.add(attachment).then(function (id) {
                                that._fileDatabaseService.add({ local_file_id: id, file: _this.takenImageFile }).then(function () {
                                    // cache filehandle
                                    that._fileCacheService.cache[id.toString()] = { file: _this.takenImageFile };
                                    that.attachments.push(attachment);
                                    that.setLayout();
                                    that._changeDetector.detectChanges();
                                    that._trackLoadingService.stopLoading('attachment-dialog');
                                    // close camera
                                    _this.closeCamera(attachment);
                                    _this.imageTaken = false;
                                });
                            });
                        });
                    }
                    else {
                        var now = moment();
                        var created_date = now.format();
                        var formatted_created_at = now.format('MM/DD/YYYY LT');
                        var formatted_created_at_date = now.format('MM/DD/YYYY');
                        var attachment_2 = that._attachmentDatabaseService.new({
                            attachment_content_type: _this.takenImageFile.type,
                            attachment_file_name: _this.takenImageFile.name,
                            attachment_file_size: _this.takenImageFile.size,
                            attachment_updated_at: _this.takenImageFile.lastModified.toString(),
                            formatted_created_at: formatted_created_at,
                            formatted_created_at_date: formatted_created_at_date,
                            count_sheet_id: that.countSheet.dbId,
                            count_sheet_client_id: that.countSheet.id,
                            audit_id: that.countSheet.data.audit_id,
                            created_at: created_date,
                            is_active: true,
                            synchronized: 0,
                        });
                        that._attachmentDatabaseService.add(attachment_2).then(function (id) {
                            that._fileDatabaseService.add({ local_file_id: id, file: _this.takenImageFile }).then(function () {
                                // cache filehandle
                                that._fileCacheService.cache[id] = { file: _this.takenImageFile };
                                that.attachments.push(attachment_2);
                                that.setLayout();
                                that._changeDetector.detectChanges();
                                that._trackLoadingService.stopLoading('attachment-dialog');
                                _this.closeCamera(attachment_2);
                                _this.imageTaken = false;
                            });
                        });
                    }
                });
                this.onlineSubscription.unsubscribe();
                return [2 /*return*/];
            });
        });
    };
    AttachmentDialogComponent.prototype.retakeImage = function () {
        this.imageTaken = false;
        this.takenImageFile = null;
        this._cameraService.play();
    };
    AttachmentDialogComponent.prototype.pickCamera = function (camera) {
        var _this = this;
        var config = this._cameraService.getConfigforCamera(camera);
        this._cameraService.initSpecificCamera(this.video, config, function (device) {
            _this.selectedCamera = device;
            _this.canTakePicture = true;
        });
    };
    AttachmentDialogComponent.prototype.setFileListSelection = function (row) {
        if (this.attachmentList) {
            this.attachmentList.resetFileAttachmentSelection(row);
        }
    };
    AttachmentDialogComponent.prototype.makeConfig = function (message, success) {
        return {
            message: message,
            duration: 3000,
            success: success,
            snackBarClass: ""
        };
    };
    AttachmentDialogComponent.prototype.openSnackBar = function (config) {
        this.snackBar.openFromComponent(MaterialSnackbarComponent, {
            data: config,
            duration: config.duration
        });
    };
    AttachmentDialogComponent.prototype.getCursorPos = function (img, e) {
        /*get the x and y positions of the image:*/
        var a = img.getBoundingClientRect();
        return {
            x: e.pageX - a.left - window.pageXOffset,
            y: e.pageY - a.top - window.pageYOffset
        };
    };
    AttachmentDialogComponent.prototype.showLens = function () {
        var _this = this;
        if (this.zoomingEnabled) {
            this.zoomingEnabled = false;
        }
        else {
            this.zoomingEnabled = true;
            setTimeout(function () {
                _this.imageBox = document.getElementById('image-box');
                _this.glass = document.getElementById('img-magnifier-glass');
            }, 1000);
        }
    };
    AttachmentDialogComponent.prototype.handleMouseMovement = function (event) {
        var target = event.target;
        if (['img-magnifier-glass', 'image-box'].includes(target.id) && this.zoomingEnabled) {
            if (this.imageBox) {
                var pos = this.getCursorPos(this.imageBox, event);
                // paint magnifier
                this.glass.style.backgroundImage = "url('" + this.imageBox.src + "')";
                this.glass.style.backgroundSize = (this.imageBox.width * this.zoom) + "px " + (this.imageBox.height * this.zoom) + "px";
                var bw = 3;
                var w = this.glass.offsetWidth / 2;
                var h = this.glass.offsetHeight / 2;
                var widthOverZoom = w / this.zoom;
                var heightOverZoom = h / this.zoom;
                var possibleX = this.imageBox.width - widthOverZoom;
                var possibleY = this.imageBox.height - heightOverZoom;
                /*prevent the magnifier glass from being positioned outside the image:*/
                if (pos.x > possibleX) {
                    pos.x = possibleX;
                }
                if (pos.x < widthOverZoom) {
                    pos.x = widthOverZoom;
                }
                if (pos.y > possibleY) {
                    pos.y = possibleY;
                }
                if (pos.y < heightOverZoom) {
                    pos.y = heightOverZoom;
                }
                /*set the position of the magnifier glass:*/
                this.glass.style.left = (pos.x - w) + "px";
                this.glass.style.top = (pos.y - h) + "px";
                /*display what the magnifier glass "sees":*/
                this.glass.style.backgroundPosition = "-" + ((pos.x * this.zoom) - w + bw) + "px -" + ((pos.y * this.zoom) - h + bw) + "px";
            }
        }
    };
    return AttachmentDialogComponent;
}());
export { AttachmentDialogComponent };
