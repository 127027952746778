
import { ExportToExcelService } from 'src/app/services/export-to-excel.service';
import { ICountsheetExportOptions } from '../interfaces/i-countsheet-export-options';


export class CountSheetExportHelper {

  /**
   *
   */
  constructor(private excelService: ExportToExcelService) {
  }

  exportCountsheetItemsToExcel(data: any, options: ICountsheetExportOptions) {
    //array to send to exel-export service
    const dataToExport = [];
    //represents a single row in exel sheet

    if (options.kit) {
      if (!options.blank) {
        data.forEach(row => {
          (row.count_sheet_items || [row]).forEach(csi => {
            let expected_quantity = csi.data.expected_quantity || 0;
            if (row.count_sheet_items) {
              expected_quantity = (row.available_redspot_inventory.find(ari => {
                return ari.item_id === csi.data.item_id && (ari.lot_number.value || '').toString() === (csi.data.lot_number || '').toString();
              }) || {}).quantity || 0;
            }
            const dataRow = {
              'reference': csi.data.reference,
              'lot number': csi.data.lot_number,
              'serial number': csi.data.serial,
              'expiration date': csi.data.expiration_date ? new Date(csi.data.expiration_date).toLocaleDateString('en-US', {timeZone: 'UTC'}) : '',
              'exp. qty': expected_quantity,
              'quantity': csi.data.quantity || 0,
            };
            dataToExport.push(dataRow);
          });
        });
      } else {
        data.forEach(csi => {
          const dataRow = {
            'reference': csi.data.reference,
            'lot number': csi.data.lot_number,
            'serial number': csi.data.serial,
            'expiration date': csi.data.expiration_date ? new Date(csi.data.expiration_date).toLocaleDateString('en-US', {timeZone: 'UTC'}) : '',
            'quantity': csi.data.quantity || 0,
          };
          dataToExport.push(dataRow);
        });
      }
    } else {
      //data can be one count sheet item, or an array due to lot pool management
      data.forEach(row => {
        (row.count_sheet_items || [row]).forEach(csi => {
          const dataRow = {
            'reference': csi.data.reference,
            'lot number': csi.data.lot_number,
            'serial number': csi.data.serial,
            'expiration date': csi.data.expiration_date ? new Date(csi.data.expiration_date).toLocaleDateString('en-US', {timeZone: 'UTC'}) : '',
            'quantity': csi.data.quantity || 0,
          };
          dataToExport.push(dataRow);
        });
      });
    }


    this.excelService.exportAsExcelFile(dataToExport, 'count_sheet_items', {});
  }
}
