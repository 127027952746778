import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CONSTANTS } from './constants';
import { OnlineService } from '../services/online.service';
import { AccessTokenService } from '../services/access-token.service';
import { urls } from './urls';
import { environment } from 'src/environments/environment';
import { UserDataHelper } from './user-data-helper';
import { IuserData } from '../interfaces/iuser-data';
import { IOktaLoginParams } from '../interfaces/i-okta-login-params';
import { IAccessTokenResponse } from '../interfaces/i-access-token-response';
import { AuthService } from '../services/auth.service';
import { OktaConfigService } from '../services/okta-config.service';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  userDataHelper: UserDataHelper = new UserDataHelper();
  isInternalKey: string = CONSTANTS.localStorageKeys.LOGIN_IS_INTERNAL
  configDataKey: string = CONSTANTS.localStorageKeys.OKTA_CONFIG_DATA
  userData: IuserData
  loginParams: IOktaLoginParams = {};
  private _oktaConfigService: OktaConfigService;

  constructor(
    private _router: Router,
    private _onlineService: OnlineService,
    private _accessTokenService: AccessTokenService,
    private authService: AuthService,
    private _injector: Injector) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessTokenData = this._accessTokenService.getInMemoryTokenDataIfNotNull();
    let expired = this._accessTokenService.isExpired(accessTokenData);
    let isInternal = localStorage.getItem(CONSTANTS.localStorageKeys.LOGIN_IS_INTERNAL) === "true";
    let isOnline = this._onlineService.isOnline();
  
    if (accessTokenData && accessTokenData.access_token && !expired) {
      this._accessTokenService.storeTokenDataIfMissing(accessTokenData);
      const cloned = this.cloneRequestWithHeaders(req, accessTokenData.access_token, isInternal);
      return next.handle(cloned).pipe(catchError(error => this.handleError(error)));
    } else if (!isOnline || this.pathNeedsAuth(req.url) === false) {
      const cloned = req.clone({
        headers: req.headers.set(CONSTANTS.http.headerLabel.SOURCE_APP, CONSTANTS.http.headers.SOURCE_APP)
      });
      return next.handle(cloned).pipe(catchError(error => this.handleError(error)));
    } else {
      if (accessTokenData.refresh_token) {
        this.loginParams.grant_type = "refresh_token";
        this.loginParams.refresh_token = accessTokenData.refresh_token;
        return from(this.getAccessTokenVanilla(this.loginParams)).pipe(
          switchMap((success: IAccessTokenResponse) => {
            if (success) {
  
              const cloned = req.clone({
                headers: req.headers.set(CONSTANTS.http.headerLabel.SOURCE_APP, CONSTANTS.http.headers.SOURCE_APP)
                  .set(CONSTANTS.http.headerLabel.AUTHORIZATION, CONSTANTS.http.headerLabel.BEARER + success.access_token)
                  .set(CONSTANTS.http.headerLabel.TENANT, isInternal ? CONSTANTS.tenants.INTERNAL : CONSTANTS.tenants.EXTERNAL)
              });
  
              return next.handle(cloned);
            } else {
              console.log("refresh token failed")
              this._router.navigate([CONSTANTS.routes.LOGIN]);
              return next.handle(req);
            }
          }),
          catchError((error: any) => {
            return throwError(error);
          })
        );
  
      } else {
        console.log("no refresh token")
        this._router.navigate([CONSTANTS.routes.LOGIN]);
        return next.handle(req);
      }
    }
  }

  pathNeedsAuth(path: string): boolean {
    let exceptions = [
      environment.serverPath + urls.registerToken,
      environment.serverPath + urls.oktaConfigData,
    ];
    return !exceptions.includes(path);
  }

  async getAccessTokenVanilla(params: IOktaLoginParams): Promise<any> {
    let configData = JSON.parse(localStorage.getItem(CONSTANTS.localStorageKeys.OKTA_CONFIG_DATA));
    params.client_id = configData.audit_internal_client_id;
    params.scope = configData.audit_scope += " offline_access";
    params.redirect_uri = configData.audit_login_redirect_uri

    let that = this;
    let isInternal = localStorage.getItem(this.isInternalKey) === "true"
    let url = isInternal ? configData.arthrex_employee_base_url : configData.non_arthrex_employee_base_url

    var request = new XMLHttpRequest();
    request.open(CONSTANTS.http.methods.POST, `${url}${configData.get_access_token}`, true);
    request.setRequestHeader(CONSTANTS.http.headerLabel.CONTENT_TYPE, CONSTANTS.http.headers.contentType.URL_ENCODED);
    return new Promise<any>((resolve, reject) => {
      request.onload = function () {
        let body: IAccessTokenResponse = {};
        try {
          body = JSON.parse(request.response);
        } catch (e) { }

        if (request.status == 200) {
          that.authService.register_token(body, isInternal ? CONSTANTS.tenants.INTERNAL : CONSTANTS.tenants.EXTERNAL).subscribe((data) => {
            if (data.success) {
              that._accessTokenService.storeTokenData(body)

              resolve(body);
            }

          }, (error) => {
            if (error.status == 403) {
              resolve(null);
            }
          })
        } else {
          resolve(null);
        }
      }

      request.onerror = function () {
        reject(false);
      }
      var body = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      request.send(body);
    })
  }

  cloneRequestWithHeaders(req: HttpRequest<any>, accessToken: string, isInternal: boolean): HttpRequest<any> {
    return req.clone({
      headers: req.headers
        .set(CONSTANTS.http.headerLabel.AUTHORIZATION, CONSTANTS.http.headerLabel.BEARER + accessToken)
        .set(CONSTANTS.http.headerLabel.TENANT, isInternal ? CONSTANTS.tenants.INTERNAL : CONSTANTS.tenants.EXTERNAL)
        .set(CONSTANTS.http.headerLabel.SOURCE_APP, CONSTANTS.http.headers.SOURCE_APP)
    });
  }

  handleError(error: any): Observable<never> {
    this._oktaConfigService = this._injector.get(OktaConfigService);

    if (error && error.status === 401) {
      localStorage.setItem(CONSTANTS.localStorageKeys.ACCESS_TOKEN_KEY, '');
      localStorage.setItem(CONSTANTS.localStorageKeys.PERMISSIONS, JSON.stringify([]));
      localStorage.setItem(CONSTANTS.localStorageKeys.USER_DATA, JSON.stringify(''));
      this._oktaConfigService.makeInternalLoginUri(document.location.href).then((url) => {
        document.location.href = url;
      })
    }
    return throwError(error);
  }
}
