import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { urls } from "../helpers/urls";
import { IFileUploadResponse } from '../interfaces/file-upload-response';

@Injectable({
  providedIn: 'root'
})
export class ExclusionService {

  constructor(private http: HttpClient) { }

    getExclusions(auditId) {
      return this.http.get<any>(`${environment.serverPath}${urls.exclusion.replace(":audit_id", auditId)}`);
    }
    getExclusionsCount(auditId) {
      return this.http.get<any>(`${environment.serverPath}${urls.exclusion.replace(":audit_id", auditId)}/count`);
    }
    createExclusion(auditId, formData) {
      return this.http.post(`${environment.serverPath}${urls.exclusion.replace(":audit_id", auditId)}`, formData).pipe(res => {
        window.caches.keys().then(function(cacheNames) {
          var match = cacheNames.find(cn => cn.includes("LongTermCountSheets"));
          if (match) {
            caches.open(match).then(function (cache) {
              cache.delete(`${environment.serverPath}${urls.exclusion.replace(":audit_id", auditId)}`, {ignoreSearch: true});
            });
          }
        });
        return res;
      });
    }
    deleteExclusion(auditId, id) {
      return this.http.delete(`${environment.serverPath}${urls.exclusion.replace(":audit_id", auditId)}/${id}`).pipe(res => {
        window.caches.keys().then(function(cacheNames) {
          var match = cacheNames.find(cn => cn.includes("LongTermCountSheets"));
          if (match) {
            caches.open(match).then(function (cache) {
              cache.delete(`${environment.serverPath}${urls.exclusion.replace(":audit_id", auditId)}`, {ignoreSearch: true});
            });
          }
        });
        return res;
      });
    }
    upload(auditId: number, formData: FormData){
      return this.http.post<IFileUploadResponse>(`${environment.serverPath}${urls.exclusionImport.replace(":audit_id", auditId.toString())}`, formData).pipe(res => {
        window.caches.keys().then(function(cacheNames) {
          var match = cacheNames.find(cn => cn.includes("LongTermCountSheets"));
          if (match) {
            caches.open(match).then(function (cache) {
              let path = `${environment.serverPath}${urls.exclusion.replace(":audit_id", auditId.toString())}`;
              cache.delete(path, {ignoreSearch: true});
            });
          }
        });
        return res;
      })
    }
  }
