import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatSnackBar } from '@angular/material';
import { ExclusionDataSource } from './exclusion-datasource';
import { ExclusionService } from '../../services/exclusion.service';
import { ActivatedRoute, Router } from "@angular/router";
import { ISnackBarConfig } from '../../interfaces/isnack-bar-config';
import { ReferenceFieldComponent } from '../reference-field/reference-field.component';
import { ExclusionListImportComponent } from '../exclusion-list-import/exclusion-list-import.component';
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';
import { AuditDashboardHelper } from 'src/app/helpers/audit-dashboard-helper';
import { IAuditDetail } from 'src/app/interfaces/iaudit-detail';
import { OnlineService } from 'src/app/services/online.service';
import { Subscription } from 'rxjs';
import { AuditDatabaseService } from '../../services/audit-database.service';

@Component({
  selector: 'app-exclusion',
  templateUrl: './exclusion.component.html',
  styleUrls: ['./exclusion.component.css']
})
export class ExclusionComponent implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    if (this.onlineSubscription) {
      this.onlineSubscription.unsubscribe();
    }

    if (this.goOfflineSubscription) {
      this.goOfflineSubscription.unsubscribe();
    }
    this.referenceField = null;
  }

  @ViewChild(ReferenceFieldComponent) referenceField: ReferenceFieldComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  auditId: number;
  currentAudit: IAuditDetail;
  dataSource: ExclusionDataSource;
  isOnline: boolean;
  exclusions: Array<object> = [];
  displayedColumns = ['reference', 'description', 'action'];
  snackBarConfig: ISnackBarConfig = {
    message: "",
    duration: 3000,
    success: false,
    snackBarClass: ""
  }
  dashboardHelper: AuditDashboardHelper;
  onlineSubscription: Subscription;
  goOfflineSubscription: Subscription;
  constructor(public dialog: MatDialog,
    private exclusionService: ExclusionService,
    private _route: ActivatedRoute,
    private _router: Router,
    public snackBar: MatSnackBar,
    private _onlineService: OnlineService,
    private _auditDatabaseService: AuditDatabaseService
  ) { }

  ngOnInit() {
    this.dashboardHelper = new AuditDashboardHelper();
    this.auditId = +this._route.snapshot.paramMap.get('id');
    this.getExclusions();
    this._auditDatabaseService.get(this.auditId).then(data => {
      this.currentAudit = data;
    });

    this.onlineSubscription = this._onlineService.isOnlineSubscription().subscribe(
      online => {
        this.isOnline = online && !this._onlineService.getTestOfflineBool();
      }
    );

    this.goOfflineSubscription = this._onlineService.goOfflineEvent.subscribe((data: boolean) => {
      this.isOnline = !data;
    })
  }

  getExclusions() {
    this.exclusionService.getExclusions(this.auditId).subscribe((data: Array<object>) => {
      this.exclusions = data;
      this.dataSource = new ExclusionDataSource(this.paginator, this.sort, this.exclusions);
    });
  }

  createExclusion() {
    if (this.currentAudit.status !== "Completed") {
      this.exclusionService.createExclusion(this.auditId, { audit_id: this.auditId, real_reference: this.referenceField.reference }).subscribe(data => {
        if (!this.exclusions.map(e => e["id"]).includes(data["id"])) {
          this.exclusions.push(data);
          this.dataSource = new ExclusionDataSource(this.paginator, this.sort, this.exclusions);
        }

        this.referenceField.referenceControl.setValue('');
        this.snackBarConfig.message = "Item Added Successfully";
        this.snackBarConfig.success = true;
        this.openSnackBar();
      },
        () => {
          this.snackBarConfig.message = "Item Not Found";
          this.snackBarConfig.success = false;
          this.openSnackBar();
        });
    } else {
      this.referenceField.referenceControl.setValue('');
      this.snackBarConfig.message = "Audit is complete, Item not excluded";
      this.snackBarConfig.success = false;
      this.openSnackBar();
    }

  }

  deleteExclusion(id: number) {
    this.exclusionService.deleteExclusion(this.auditId, id).subscribe(() => {
      this.exclusions = this.exclusions.filter(e => e["id"] !== id);
      this.dataSource = new ExclusionDataSource(this.paginator, this.sort, this.exclusions);
      this.snackBarConfig.message = "Item Removed Successfully";
      this.snackBarConfig.success = true;
      this.openSnackBar();
    },
      () => {
        this.snackBarConfig.message = "Item Not Removed";
        this.snackBarConfig.success = false;
        this.openSnackBar();
      });
  }

  goToAuditDashboard() {
    this._router.navigate(['audit_dashboard', this.auditId]);
  }


  openExclusionDialog() {
    const dialogRef = this.dialog.open(ExclusionListImportComponent, {
      width: "40%",
      data: {
        audit_id: this.auditId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ngOnInit()
      }
    });
  }

  openSnackBar() {
    this.snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: this.snackBarConfig,
      duration: this.snackBarConfig.duration
    })
  }
}
