import { ViewChild, Component, OnInit, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
import { CountSheetItemHelper } from 'src/app/helpers/countsheet-item-helper';
import { Iitem } from 'src/app/interfaces/iitem';
import { BarcodeGs1FormatComponent } from '../barcode-gs1-format/barcode-gs1-format.component'
import { BarcodeHibcFormatComponent } from '../barcode-hibc-format/barcode-hibc-format.component'
import { BarcodeArthrexNewFormatComponent } from '../barcode-arthrex-new-format/barcode-arthrex-new-format.component'
import { BarcodeKitUuidFormatComponent } from '../barcode-kit-uuid-format/barcode-kit-uuid-format.component'

@Component({
  selector: 'app-barcode-parser',
  templateUrl: './barcode-parser.component.html',
  styleUrls: ['./barcode-parser.component.css']
})
export class BarcodeParserComponent implements OnInit, OnDestroy {

  @Input() countsheetItemHelper: CountSheetItemHelper
  @Input() items: Iitem[]
  @Output() referenceScanned = new EventEmitter<any>();
  @Output() lotScanned = new EventEmitter<any>();
  @Output() serialScanned = new EventEmitter<any>();
  @Output() expirationScanned = new EventEmitter<any>();
  @Output() quantityScanned = new EventEmitter<any>();
  @Output() done = new EventEmitter<any>();
  @Output() itemWasNotFound = new EventEmitter<any>();
  @ViewChild(BarcodeGs1FormatComponent) gs1: BarcodeGs1FormatComponent;
  @ViewChild(BarcodeHibcFormatComponent) hibc: BarcodeHibcFormatComponent;
  @ViewChild(BarcodeArthrexNewFormatComponent) arthrexNew: BarcodeArthrexNewFormatComponent;
  @ViewChild(BarcodeKitUuidFormatComponent) uuid: BarcodeKitUuidFormatComponent;
  previousScan;
  constructor() { }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.gs1 = null;
    this.hibc = null;
    this.arthrexNew = null;
    this.uuid = null;
  }

  barcodeScanned(value) {
    var values = {};
    if (this.gs1.match(value)) {
      values = this.gs1.parse(value);
    }
    if (this.hibc.match(value)) {
      values = this.hibc.parse(value, this.previousScan);
      //previous scan is only used by hibc
      if (values["done"]) {
        this.previousScan = null;
      }
      else {
        this.previousScan = value;
      }
    }
    if (this.uuid.match(value)) {
      this.uuid.parse(value, vals => this.emitEvent(vals));
    }
    if (this.arthrexNew.match(value)) {
      this.arthrexNew.parse(value, vals => this.emitEvent(vals));
    }
    this.emitEvent(values);
  }

  emitEvent(values: {}) {
    if (values["reset"]) {
      this.referenceScanned.emit('');
      this.lotScanned.emit('');
      this.serialScanned.emit('');
      this.expirationScanned.emit('');
      this.quantityScanned.emit('');
    }

    if (values["reference"]) {
      this.referenceScanned.emit(values["reference"]);
    }
    if (values["lot"]) {
      this.lotScanned.emit(values["lot"]);
    }
    if (values["serial"]) {
      this.serialScanned.emit(values["serial"]);
    }
    if (values["expiration"]) {
      this.expirationScanned.emit(values["expiration"]);
    }
    if (values["quantity"]) {
      this.quantityScanned.emit(values["quantity"]);
    }

    if (values["done"]) {
      this.done.emit();
    }
  }

  itemNotFound() {
    this.itemWasNotFound.emit();
  }
}
