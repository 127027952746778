import { Component, OnInit } from '@angular/core';
import { ISnackBarConfig } from '../../interfaces/isnack-bar-config';
import { IuserData } from '../../interfaces/iuser-data';
import { UserDataHelper } from "./../../helpers/user-data-helper";
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';
import { MatSnackBar } from '@angular/material';
import { OnlineService } from "./../../services/online.service";
import { OktaConfigService } from '../../services/okta-config.service';
import { IOktaConfig } from '../../interfaces/i-okta-config';
import { CONSTANTS } from 'src/app/helpers/constants';
import { Router } from '@angular/router';
import { AccessTokenService } from 'src/app/services/access-token.service';

@Component({
  selector: 'app-audit-login',
  templateUrl: './audit-login.component.html',
  styleUrls: ['./audit-login.component.css']
})
export class AuditLoginComponent implements OnInit {

  userDataHelper: UserDataHelper = new UserDataHelper();

  snackBarConfig: ISnackBarConfig = {
    message: "",
    duration: 3000,
    success: false,
    snackBarClass: ""
  }

  failedState: boolean = false;
  redirectUrl: string;
  currentUser: IuserData;
  isLoggedIn: boolean = false;
  constructor(
    private _onlineService: OnlineService,
    private _router: Router,
    public snackBar: MatSnackBar,
    private _oktaConfigService: OktaConfigService,
    private _accessTokenService: AccessTokenService
  ) { }

  ngOnInit() {
    let tokenData = this._accessTokenService.getTokenData();
    if(tokenData.access_token && !this._accessTokenService.isExpired(tokenData)){
      this._router.navigate([CONSTANTS.routes.AUDITS]);
    }
  }

  async externalLogin() {
    if (this._onlineService.isOnline()) {
      this._oktaConfigService.getConfigData().subscribe(async (oktaConfigData: IOktaConfig) => {

        this._oktaConfigService.saveToLocal(oktaConfigData);
        let state = document.location.origin;
        let url = await this._oktaConfigService.makeExternalLoginUri(state);
        // get the router to navigate to the url
        localStorage.setItem(this._oktaConfigService.isInternalKey, false.toString());
        document.location.href = url;
      })


    } else {
      this.failedState = true;
      this.snackBarConfig.message = CONSTANTS.messages.MUST_BE_ONLINE;
      this.snackBarConfig.success = false;
      this.openSnackBar();
    }

  }

  async internalLogin() {
    if (this._onlineService.isOnline()) {
      this._oktaConfigService.getConfigData().subscribe(async (oktaConfigData: IOktaConfig) => {

        this._oktaConfigService.saveToLocal(oktaConfigData)
        let state = document.location.origin;
        let url = await this._oktaConfigService.makeInternalLoginUri(state);
        // get the router to navigate to the url
        localStorage.setItem(this._oktaConfigService.isInternalKey, true.toString());
        document.location.href = url;
      })
    } else {
      this.failedState = true;
      this.snackBarConfig.message = CONSTANTS.messages.MUST_BE_ONLINE;
      this.snackBarConfig.success = false;
      this.openSnackBar();
    }
  }

  openSnackBar() {
    this.snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: this.snackBarConfig,
      duration: this.snackBarConfig.duration
    })
  }
}
