import { Assets } from '../helpers/assets';
import * as i0 from "@angular/core";
var CameraService = /** @class */ (function () {
    function CameraService() {
        this.streaming = false;
        this.configs = [];
        this.devices = [];
        this.lastUsedCameraKey = "LAST_USED_CAMERA";
        this.config = {
            video: { facingMode: 'environment' },
            audio: false
        };
        this.getDevices();
    }
    CameraService.prototype.initCamera = function (videoElement, callback) {
        var _this = this;
        var storedCamera = this.getLastUsedCamera();
        if (storedCamera) {
            this.config = this.getConfigforCamera(storedCamera) || {
                video: {
                    facingMode: 'environment'
                },
                audio: false
            };
            this.initSpecificCamera(videoElement, this.config, callback);
        }
        else {
            this.selectedDevice = this.selectedDevice || this.devices[0];
            this.config = this.getConfigforCamera(this.selectedDevice) || {
                video: {
                    facingMode: 'environment'
                },
                audio: false
            };
            var browser = navigator;
            this.video = videoElement;
            browser.getUserMedia = (browser.getUserMedia ||
                browser.webkitGetUserMedia ||
                browser.mozGetUserMedia ||
                browser.msGetUserMedia);
            var that_1 = this;
            browser.mediaDevices.getUserMedia(this.config).then(function (stream) {
                _this.videoStream = stream;
                videoElement.srcObject = stream;
                videoElement.onloadedmetadata = function (e) {
                    callback(that_1.selectedDevice);
                    that_1.rememberLastCamera(that_1.selectedDevice);
                    videoElement.play();
                };
            }).catch(function (err) {
                console.log(err);
            });
        }
    };
    CameraService.prototype.initSpecificCamera = function (video, config, callback) {
        var _this = this;
        this.selectedDevice = this.devices.find(function (d) {
            return d.deviceId === config.video.deviceId;
        });
        this.closeCamera();
        var browser = navigator;
        this.video = video;
        browser.getUserMedia = (browser.getUserMedia ||
            browser.webkitGetUserMedia ||
            browser.mozGetUserMedia ||
            browser.msGetUserMedia);
        var that = this;
        browser.mediaDevices.getUserMedia(config).then(function (stream) {
            _this.videoStream = stream;
            video.srcObject = stream;
            video.onloadedmetadata = function (e) {
                callback(that.selectedDevice);
                that.rememberLastCamera(that.selectedDevice);
                video.play();
            };
        }).catch(function (err) {
            console.log(err);
        });
    };
    CameraService.prototype.closeCamera = function (callback) {
        if (callback === void 0) { callback = null; }
        if (this.videoStream) {
            var tracks = this.videoStream.getTracks();
            tracks.forEach(function (track) {
                track.stop();
            });
        }
        if (callback) {
            callback();
        }
    };
    CameraService.prototype.takePicChrome = function () {
        var tracks = this.videoStream.getTracks();
        var imageCapture = new ImageCapture(tracks[0]);
        this.pauseVideo();
        imageCapture.grabFrame().then(function (bitmap) {
        }).catch(function (err) {
            console.log(err);
        });
    };
    CameraService.prototype.takePic = function (imageName, callback) {
        if (callback === void 0) { callback = null; }
        this.pauseVideo();
        this.playShutterSound();
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        var dims = this.getVideoDimensions();
        canvas.width = dims.width;
        canvas.height = dims.height;
        context.drawImage(this.video, 0, 0, dims.width, dims.height);
        canvas.toBlob(function (blob) {
            var file = new File([blob], imageName, { type: 'image/png' });
            if (callback) {
                callback(file);
            }
        }, 'image/png');
    };
    CameraService.prototype.pauseVideo = function () {
        this.video.pause();
    };
    CameraService.prototype.play = function () {
        this.video.play();
    };
    CameraService.prototype.getVideoDimensions = function () {
        if (this.video) {
            return {
                height: this.video.videoHeight,
                width: this.video.videoWidth
            };
        }
        else {
            return {
                height: 0,
                width: 0
            };
        }
    };
    CameraService.prototype.getDevices = function (callback) {
        var _this = this;
        if (callback === void 0) { callback = null; }
        var browser = navigator;
        browser.mediaDevices.enumerateDevices()
            .then(function (devices) {
            var cameras = devices.filter(function (d) {
                return d.kind === 'videoinput';
            });
            if (callback) {
                callback(cameras);
            }
            _this.makeCameraConfigs(cameras);
        }).catch(function (error) {
            console.log(error);
        });
    };
    CameraService.prototype.makeCameraConfigs = function (devices) {
        this.devices = devices;
        var configs = [];
        devices.forEach(function (d) {
            var config = {
                audio: false,
                video: {
                    deviceId: d.deviceId
                }
            };
            configs.push(config);
        });
        this.configs = configs;
    };
    CameraService.prototype.getConfigforCamera = function (camera) {
        return this.configs.find(function (co) {
            return co.video.deviceId === camera.deviceId;
        });
    };
    CameraService.prototype.playShutterSound = function () {
        var audio = new Audio();
        audio.src = Assets.sounds.cameraShutter;
        audio.play();
    };
    CameraService.prototype.rememberLastCamera = function (camera) {
        localStorage.setItem(this.lastUsedCameraKey, JSON.stringify(camera));
    };
    CameraService.prototype.getLastUsedCamera = function () {
        var localStorageItem = localStorage.getItem(this.lastUsedCameraKey);
        var camera = localStorageItem ? JSON.parse(localStorageItem) : null;
        return camera;
    };
    CameraService.ngInjectableDef = i0.defineInjectable({ factory: function CameraService_Factory() { return new CameraService(); }, token: CameraService, providedIn: "root" });
    return CameraService;
}());
export { CameraService };
