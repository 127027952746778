import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HistoryServiceService {

  historyKey = 'HISTORY';
  private history: string[] = [];
  historySubscription: Subscription;
  constructor(private _router: Router) { }

  loadRouting() {
    this.history = this.getHistory();
    this.historySubscription = this._router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({urlAfterRedirects}: NavigationEnd) => {
        this.addHistory(urlAfterRedirects);
      });
  }

  getHistory(): string[] {
    return this.getLocalHistory();
  }

  findLastCountSheetSummaryLink(): string {
    return this.findMostRecentLinkOf('countsheets');
  }

  findLastDashboardLink() {
    return this.findMostRecentLinkOf('audit_dashboard');
  }

  findLastNonKittedCountSheetLink() {
    return this.findMostRecentLinkOf('non_kitted_count_sheet');
  }

  findLastKittedCountSheetLink() {
    return this.findMostRecentLinkOf('kit_instance_count_sheet');
  }

  findMostRecentLinkOf(linkPart: string): string {
    const reverseHistory = this.getHistory().reverse();
    const foundLink = reverseHistory.find(h => {
      return h.indexOf(linkPart) !== -1;
    });
    return foundLink;
  }

  removeQueryStrings(url: string) {
    let res = '';
    const qsStart = url.indexOf('?');
    if (qsStart !== -1) {
      res = url.substr(0, qsStart);
    } else {
      res = url;
    }
    return res;
  }

  addHistory(url: string) {
    if (url.indexOf('keepSameSite') === -1) {
      url = this.removeQueryStrings(url);
    }
    this.history = this.getHistory();
    if (this.history.length >= 20) {
      this.history.shift();
      this.history.push(url);
    } else {
      this.history.push(url);
    }
    this.saveHistory(this.history);
  }

  createHistory() {
    const history: string[] = [];
    this.saveHistory(history);
    return history;
  }

  saveHistory(history: string[]) {
    localStorage.setItem(this.historyKey, JSON.stringify(history));
  }

  getLocalHistory() {
    const history = JSON.parse(localStorage.getItem(this.historyKey));
    if (history) {
      return history;
    } else {
      return this.createHistory();
    }
  }
}
