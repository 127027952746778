import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { urls } from "../helpers/urls";
import { ICredential } from '../interfaces/icredential';
import { environment } from "../../environments/environment";
import { Router } from '@angular/router';
import { UserDataHelper } from '../helpers/user-data-helper';
import { EventEmitter } from '@angular/core';
import { IAccessTokenResponse } from '../interfaces/i-access-token-response';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  _isLoggedIn: boolean = false;
  redirectUrl: string;
  userDataHelper: UserDataHelper = new UserDataHelper();
  authenticatedEvent: EventEmitter<any>;

  public set isLoggedIn(newIsLoggedIn) {
    this._isLoggedIn = newIsLoggedIn;
    this.authenticatedEvent.emit(newIsLoggedIn);
  }

  public get isLoggedIn() {
    return this._isLoggedIn;
  }

  constructor(private _http: HttpClient, private _router: Router) {
    this.authenticatedEvent = new EventEmitter<any>();
    this.isLoggedIn = !!localStorage.getItem('auth_token');
  }

  userLogin(credentials: ICredential){

    const fd = new FormData();

    fd.append('email', credentials.email);
    fd.append('password', credentials.password);

    return this._http.post<any>(environment.serverPath + urls.login , fd);
  }

  userLogout(){
    this.isLoggedIn = false;
    localStorage.setItem('auth_token', '');
    localStorage.setItem('permissions',JSON.stringify([]));
    this.userDataHelper.setUserData('');
  }

  register_token(tokenData: IAccessTokenResponse, tenant: string){
    const fd = new FormData();
    fd.append('access_token', tokenData.access_token);
    fd.append('id_token', tokenData.id_token);
    fd.append('expires_in', tokenData.expires_in.toString());
    fd.append('refresh_token', tokenData.refresh_token);
    fd.append('tenant', tenant);


    console.log("register_token: ", tokenData)
    return this._http.post<any>(environment.serverPath + urls.registerToken, fd);
  }

  invalidateAccessToken(tokenData: IAccessTokenResponse){
    let data = {id_token: tokenData.id_token};
    return this._http.delete<any>(environment.serverPath + urls.invalidateToken, {params: data});
  }
}
