import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '../../../../../node_modules/@angular/material';


@Component({
  selector: 'app-create-kit-countsheet',
  templateUrl: './create-kit-countsheet.component.html',
  styleUrls: ['./create-kit-countsheet.component.css']
})
export class CreateKitCountsheetComponent implements OnInit {
  
  constructor(public dialogRef: MatDialogRef<CreateKitCountsheetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
