import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuditLoginComponent } from '../../components/audit-login/audit-login.component';
import { AuditMaterialNavComponent } from '../../components/audit-material-nav/audit-material-nav.component';
import { HomeComponent } from '../../components/home/home.component';
import { LoginGuardGuard } from '../../guards/login-guard.guard';
import { ConsignmentInventoryImportComponent } from '../../components/consignment-inventory-import/consignment-inventory-import.component';
import { RedspotInventoryImportComponent } from '../../components/redspot-inventory-import/redspot-inventory-import.component';
import { ExclusionComponent } from '../../components/exclusion/exclusion.component';
import { AuditDashboardComponent } from '../../components/audit-dashboard/audit-dashboard.component';
import { PageNotFoundComponent } from '../../components/page-not-found/page-not-found.component';
import { KitInstanceCountSheetComponent } from '../../components/kit-instance-count-sheet/kit-instance-count-sheet.component';
import { UnkittedItemsCountsheetComponent } from '../../components/count/unkitted-items-countsheet/unkitted-items-countsheet.component';
import { CountsheetSummaryTabsComponent } from '../../components/countsheet-summary-tabs/countsheet-summary-tabs.component';
import { WarehouseDashboardComponent } from '../../components/warehouse-dashboard/warehouse-dashboard.component';
import { CopyCountSheetItemComponent } from '../../components/copy-count-sheet-item/copy-count-sheet-item.component';
import { AgencyDashboardComponent } from '../../components/agency-dashboard/agency-dashboard.component';
import { SalesOrderInventoryImportListComponent } from '../../components/sales-order-inventory-import-list/sales-order-inventory-import-list.component';
import { ResultFilesComponent } from '../../components/result-files/result-files.component';
import { SapConsignmentInventoryImportListComponent } from '../../components/sap-consignment-inventory-import-list/sap-consignment-inventory-import-list.component';
import { ConsignmentAccountImportListComponent } from 'src/app/components/consignment-account-import-list/consignment-account-import-list.component';
import { LotPoolManagedItemsListComponent } from '../../components/lot-pool-managed-items-list/lot-pool-managed-items-list.component';
import { OktaLoginResponseComponent } from 'src/app/components/okta-login-response/okta-login-response.component';
import { OktaLogoutComponent } from 'src/app/components/okta-logout/okta-logout.component';



const appRoutes: Routes = [

  {
    path: '',
    component: AuditMaterialNavComponent,
    children: [
      {
        path: 'okta/okta_login_response',
        component: OktaLoginResponseComponent
      },
      {
        path: 'okta/logout',
        component: OktaLogoutComponent
      },
      {
        path: 'login',
        component: AuditLoginComponent
      },
      {
        path: '',
        redirectTo: 'audit',
        pathMatch: 'full'
      },
      {
        path: 'audit',
        component: HomeComponent,
        canActivate: [LoginGuardGuard]
      },
      {
        path: 'import_consignment_inventory',
        component: ConsignmentInventoryImportComponent,
        canActivate: [LoginGuardGuard]
      },
      {
        path: 'import_redspot_inventory',
        component: RedspotInventoryImportComponent,
        canActivate: [LoginGuardGuard]
      },
      {
        path: 'sales_order_inventory_import_list/:id',
        component: SalesOrderInventoryImportListComponent,
        canActivate: [LoginGuardGuard]
      },
      {
        path: 'consignment_account_import_list/:id',
        component: ConsignmentAccountImportListComponent,
        canActivate: [LoginGuardGuard]
      },
      {
        path: 'result_files/:id',
        component: ResultFilesComponent,
        canActivate: [LoginGuardGuard]
      },
      {
        path: 'copy_count_sheet_item',
        component: CopyCountSheetItemComponent,
        canActivate: [LoginGuardGuard]
      },
      {
        path: 'exclusion/:id',
        component: ExclusionComponent,
        canActivate: [LoginGuardGuard]
      },
      {
        path: 'lot_pool_managed_items',
        component: LotPoolManagedItemsListComponent,
        canActivate: [LoginGuardGuard]
      },
      {
        path: 'audit_dashboard/:id',
        component: AuditDashboardComponent,
        canActivate: [LoginGuardGuard]
      },
      {
        path: ':audit_id/countsheets/:id',
        component: CountsheetSummaryTabsComponent,
        canActivate: [LoginGuardGuard]
      },
      {
        path: ':audit_id/kit_instance_count_sheet/:id/:isViewOnly',
        component: KitInstanceCountSheetComponent,
        canActivate: [LoginGuardGuard]
      },
      {
        path: ':audit_id/non_kitted_count_sheet/:id/:isViewOnly',
        component: UnkittedItemsCountsheetComponent,
        canActivate: [LoginGuardGuard]
      },
      {

        path: ':audit_id/warehouse_dashboard/:warehouse_id',
        component: WarehouseDashboardComponent,
        canActivate: [LoginGuardGuard]
      },
      {
        path: ':audit_id/agency_dashboard',
        component: AgencyDashboardComponent,
        canActivate: [LoginGuardGuard]
      },
      {
        path: 'sap_consignment_inventory_import_list/:audit_id',
        component: SapConsignmentInventoryImportListComponent,
        canActivate: [LoginGuardGuard]
      },
      { path: '**', component: PageNotFoundComponent }
    ]
  }
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' })
  ],
  declarations: [],
  exports: [RouterModule]
})
export class RoutingModule { }
