/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirmation-dialog.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./confirmation-dialog.component";
var styles_ConfirmationDialogComponent = [i0.styles];
var RenderType_ConfirmationDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmationDialogComponent, data: {} });
export { RenderType_ConfirmationDialogComponent as RenderType_ConfirmationDialogComponent };
export function View_ConfirmationDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [["align", "center"], ["class", "full-width header-title mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Please Confirm"])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["align", "center"], ["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 8, "div", [["align", "center"], ["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["class", "no"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNoClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(11, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["No"])), (_l()(), i1.ɵeld(13, 0, null, null, 3, "button", [["class", "yes"], ["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).dialogRef.close(i1.ɵnov(_v, 15).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(14, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(15, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Yes"]))], function (_ck, _v) { _ck(_v, 2, 0); var currVal_7 = "yes"; _ck(_v, 15, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.message; _ck(_v, 7, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 11).disabled || null); var currVal_3 = (i1.ɵnov(_v, 11)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_2, currVal_3); var currVal_4 = (i1.ɵnov(_v, 14).disabled || null); var currVal_5 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); var currVal_6 = i1.ɵnov(_v, 15).ariaLabel; _ck(_v, 13, 0, currVal_4, currVal_5, currVal_6); }); }
export function View_ConfirmationDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirmation-dialog", [], null, null, null, View_ConfirmationDialogComponent_0, RenderType_ConfirmationDialogComponent)), i1.ɵdid(1, 114688, null, 0, i8.ConfirmationDialogComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmationDialogComponentNgFactory = i1.ɵccf("app-confirmation-dialog", i8.ConfirmationDialogComponent, View_ConfirmationDialogComponent_Host_0, {}, {}, []);
export { ConfirmationDialogComponentNgFactory as ConfirmationDialogComponentNgFactory };
