import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class DefaultHeaderInterceptor implements HttpInterceptor {

    constructor(){

    }
    intercept(req: HttpRequest<any>, next: HttpHandler):Observable <HttpEvent<any>> {
      const cloned = req.clone({
        headers: req.headers.set("X-Requested-With", "XMLHttpRequest")
      });
      return next.handle(cloned);
    }
}
