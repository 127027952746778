import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IFileUploadResponse } from '../interfaces/file-upload-response';
import { environment } from "../../environments/environment";
import { urls } from "../helpers/urls";
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ConsignmentInventoryImportService {

constructor(
  private http: HttpClient
) { }

  upload(auditId, formData: FormData){
    var that = this;
    return this.http.post<IFileUploadResponse>(`${environment.serverPath}${urls.consignmentInvImport.replace(":audit_id", auditId)}/import`, formData).pipe(res => {
      window.caches.keys().then(function(cacheNames) {
        var match = cacheNames.find(cn => cn.includes("Inventory"));
        if (match) {
          caches.open(match).then(function (cache) {
            cache.delete(that.getURL(auditId), {ignoreSearch: true});
          });
        }
      });
      return res;
    });
  }

  getURL(auditId) {
    return `${environment.serverPath}${urls.consignmentInvImport.replace(":audit_id", auditId)}`;
  }

  getInventory(auditId, success: boolean) {
    let that = this;
    return this.http.get<any>(that.getURL(auditId)).pipe(map(function (data) {
      if (success) {
        return data.filter(i => i.success);
      }
      return data;
    }));
  }
}
