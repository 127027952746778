import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { urls } from '../helpers/urls';
import { CountSheetDatabaseService } from '../services/count-sheet-database.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./count-sheet-database.service";
var CountSheetService = /** @class */ (function () {
    function CountSheetService(http, _countSheetDatabaseService) {
        this.http = http;
        this._countSheetDatabaseService = _countSheetDatabaseService;
    }
    CountSheetService.prototype.getCountSheets = function (auditId, options) {
        options = options || {};
        var params = new HttpParams().set('audit_id', auditId);
        if (options['site_id']) {
            params = params.set('site_id', options['site_id']);
        }
        if (options['only_kitted']) {
            params = params.set('only_kitted', options['only_kitted']);
        }
        if (options['only_unkitted']) {
            params = params.set('only_unkitted', options['only_unkitted']);
        }
        return this.http.get(environment.serverPath + urls.countSheet, { params: params });
    };
    CountSheetService.prototype.getCountSheet = function (countSheetId) {
        return this.http.get(environment.serverPath + urls.countSheet + '/' + countSheetId);
    };
    CountSheetService.prototype.getOfflineCountSheets = function (auditId) {
        return this._countSheetDatabaseService.find(auditId, {});
    };
    CountSheetService.prototype.getCountSheetsAndPopulateOfflineData = function (auditId) {
        var that = this;
        var promise = new Promise(function (resolve, reject) {
            that.getCountSheets(auditId).subscribe(function (data) {
                that._countSheetDatabaseService.deleteByIndex('data.audit_id', parseInt(auditId, 10)).then(function () {
                    var css = data.map(function (cs) { return that._countSheetDatabaseService.new(cs); });
                    that._countSheetDatabaseService.bulkAdd(css).then(function (_) {
                        resolve(css);
                    });
                });
            });
        });
        return promise;
    };
    CountSheetService.prototype.updateCountSheet = function (countSheetId, params) {
        var formData = new FormData();
        //add other params as needed
        if (params['kit_id']) {
            formData.append('count_sheet[kit_id]', params['kit_id'].toString());
        }
        if (params.area) {
            formData.append('count_sheet[area]', params.area);
        }
        if (params.warehouse_id) {
            formData.append('count_sheet[warehouse_id]', params.warehouse_id);
        }
        if (params['show_expected_item_list']) {
            formData.append('count_sheet[show_expected_item_list]', params['show_expected_item_list'].toString());
        }
        return this.http.put("" + environment.serverPath + urls.countSheet + "/" + countSheetId, formData);
    };
    CountSheetService.prototype.uncountAll = function (countSheetId) {
        var path = environment.serverPath + urls.countSheet + '/' + countSheetId + '/uncount_all';
        return this.http.post(path, {});
    };
    CountSheetService.prototype.bulkUpdate = function (countSheets, options) {
        var formData = new FormData();
        if (options['add_to_quantity']) {
            formData.append('add_to_quantity', options['add_to_quantity']);
        }
        countSheets.forEach(function (cs, countSheetIndex) {
            Object.entries(cs).forEach(function (v) {
                var key = v[0];
                var value = v[1];
                if (key === "attachments" || key === "count_sheet_items") {
                    (value || []).forEach(function (nestedObject, nestedIndex) {
                        Object.entries(nestedObject).forEach(function (nestedV) {
                            var nestedKey = nestedV[0];
                            var nestedValue = nestedV[1];
                            if (nestedValue instanceof File) {
                                formData.append('count_sheets[' + countSheetIndex + '][' + key + '][' + nestedIndex + '][' + nestedKey + ']', nestedValue);
                            }
                            else {
                                formData.append('count_sheets[' + countSheetIndex + '][' + key + '][' + nestedIndex + '][' + nestedKey + ']', String(nestedValue));
                            }
                        });
                    });
                }
                else {
                    formData.append('count_sheets[' + countSheetIndex + '][' + key + ']', String(value));
                }
            });
        });
        return this.http.put("" + environment.serverPath + urls.countSheet + "/bulk_update", formData);
    };
    CountSheetService.prototype.createKitCountSheet = function (newCountSheet) {
        var formData = new FormData();
        formData.append('count_sheet', JSON.stringify(newCountSheet));
        return this.http.post(environment.serverPath + urls.countSheet, formData);
    };
    CountSheetService.prototype.getRootKitDefinitionItems = function (countSheetId) {
        return this.http.get(environment.serverPath + urls.countSheet + '/' + countSheetId + '/root_kit_definition_items');
    };
    CountSheetService.prototype.updateCountSheetStatus = function (id, newStatus, statusMetadata) {
        var formData = new FormData();
        formData.append('count_sheet', JSON.stringify({
            count_sheet_id: id,
            status: newStatus,
            metadata: statusMetadata
        }));
        var url = urls.updateCountSheetStatus;
        return this.http.post(environment.serverPath + url, formData);
    };
    CountSheetService.prototype.updateCountSheetStatuses = function (ids, status, statusMetadata, siteId) {
        var formData = new FormData();
        formData.append('count_sheet', JSON.stringify({
            count_sheet_ids: ids,
            status: status,
            metadata: statusMetadata,
            site_id: siteId
        }));
        var url = environment.serverPath + urls.updateCountSheetStatuses;
        return this.http.post(url, formData);
    };
    CountSheetService.prototype.updateCountSheetWareHouse = function (countSheetId, warehouseId) {
        var formData = new FormData();
        formData.append('data', JSON.stringify({
            count_sheet_id: countSheetId,
            warehouse_id: warehouseId
        }));
        var path = environment.serverPath + urls.updateCountSheetWarehouse;
        return this.http.post(path, formData);
    };
    CountSheetService.prototype.deleteCountSheet = function (countSheet) {
        var params = new HttpParams()
            .set('count_sheet[id]', countSheet.dbId.toString());
        return this.http.delete("" + environment.serverPath + urls.countSheet, { params: params });
    };
    CountSheetService.ngInjectableDef = i0.defineInjectable({ factory: function CountSheetService_Factory() { return new CountSheetService(i0.inject(i1.HttpClient), i0.inject(i2.CountSheetDatabaseService)); }, token: CountSheetService, providedIn: "root" });
    return CountSheetService;
}());
export { CountSheetService };
