import { EventEmitter, NgZone, ApplicationRef } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/service-worker";
var UpdateService = /** @class */ (function () {
    function UpdateService(appRef, swUpdate, ngZone) {
        this.appRef = appRef;
        this.swUpdate = swUpdate;
        this.updateAvailable = false;
        this.updateAvailableEmitter = new EventEmitter();
        //Make sure the app is stable before starting the interval
        // const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
        // const timer$ = interval(60 * 1000);
        // const timerOnceAppIsStable$ = concat(appIsStable$, timer$);
        // if (swUpdate.isEnabled === true && environment.updateCheckEnabled === true) {
        //   ngZone.runOutsideAngular(() => {
        //     timerOnceAppIsStable$.subscribe(() => {
        //       console.log('Checking for updates');
        //       ngZone.run(() => swUpdate.checkForUpdate());
        //     });
        //   });
        // }
    }
    UpdateService.prototype.checkForUpdates = function () {
        var _this = this;
        if (environment.updateCheckEnabled === true) {
            this.swUpdate.available.subscribe(function () {
                _this.updateAvailable = true;
                _this.updateAvailableEmitter.emit(true);
            });
        }
    };
    UpdateService.prototype.updateIsAvailable = function () {
        return this.updateAvailable;
    };
    UpdateService.prototype.ActivateUpdate = function () {
        //SwUpdate is fickle and if you wait too long the activateUpdate will never return... sigh
        //this.swUpdate.activateUpdate().then(() => window.location.reload());
        window.location.reload();
    };
    UpdateService.ngInjectableDef = i0.defineInjectable({ factory: function UpdateService_Factory() { return new UpdateService(i0.inject(i0.ApplicationRef), i0.inject(i1.SwUpdate), i0.inject(i0.NgZone)); }, token: UpdateService, providedIn: "root" });
    return UpdateService;
}());
export { UpdateService };
