import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { IAuditDetail } from '../../interfaces/iaudit-detail';
import { CreateNewAuditComponent } from '../create-new-audit/create-new-audit.component';
import { MatDialog, MatSnackBar } from '../../../../node_modules/@angular/material';
import { AuditService } from '../../services/audit.service';
import { ISnackBarConfig } from '../../interfaces/isnack-bar-config';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';


@Component({
  selector: 'app-audit-details',
  templateUrl: './audit-details.component.html',
  styleUrls: ['./audit-details.component.css']
})
export class AuditDetailsComponent implements OnInit, OnDestroy {

  auditDetails: IAuditDetail = {
    location_name : '',
    id: 0,
    warehouse_id: 0,
    distributorship: {},
    status: '',
    start_date: null,
    end_date: null
  };
  auditId: number;
  @Input() auditData: any;
  @Input() isOnline: boolean;
  @Output() auditWasEdited = new EventEmitter<any>();
  statusClass = '';
  permissions: string[];
  auditServiceSubscription: Subscription;
  completeAuditToolTip = 'Mark Audit As Complete';
  disableCompleteButton = false;
  snackBarConfig: ISnackBarConfig = {
    message : '',
    duration: 3000,
    success: false,
    snackBarClass : ''
  };
  constructor(
    private _auditService: AuditService,
    public dialog: MatDialog,
    private _router: Router,
    private _route: ActivatedRoute,
    public snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.permissions = JSON.parse(localStorage.getItem('permissions'));
    this.auditId = +this._route.snapshot.paramMap.get('id');
    this.auditDetails = this.auditData;
    this.statusClass = this.getStatusClass();
  }

  ngOnDestroy(): void {
    if (this.auditServiceSubscription) {
      this.auditServiceSubscription.unsubscribe();
    }
  }

  openEditModal() {
    const dialogRef = this.dialog.open(CreateNewAuditComponent, {
      width: '50%',
      data: {
        title: 'Edit Audit',
        location_name: this.auditDetails.location_name,
        start_date: this.auditDetails.start_date,
        end_date: this.auditDetails.end_date,
        status: this.auditDetails.status,
        warehouse_id: this.auditDetails.warehouse_id,
        id: this.auditDetails.id
      }
    });
    dialogRef.componentInstance.saved.subscribe(() => {
      this.auditServiceSubscription = this._auditService.getAuditById(this.auditId).subscribe(data => {
        this.auditDetails = data;
        this.statusClass = this.getStatusClass();
        this.auditWasEdited.emit(this.auditDetails.status);
      });
    });
  }

  getStatusClass() {
    if (this.auditDetails.status === 'Completed') {
      return 'audit-completed';
    } else if (this.auditDetails.status === 'Cancelled') {
      return 'audit-canceled';
    } else if (this.auditDetails.status === 'In-Progress') {
      return 'audit-in-progress';
    } else if (this.auditDetails.status === 'Planned') {
      return 'audit-planned';
    }
  }

  goToAgencyDashBoard() {
    this._router.navigate([this.auditId, 'agency_dashboard']);
  }

  cancelAudit() {
    this.auditDetails.status = 'Cancelled';
    this._auditService.updateAudit(this.auditDetails).subscribe(data => {
      this.auditWasEdited.emit(this.auditDetails.status);
      this.snackBarConfig.message = 'Audit Updated Successfully';
      this.snackBarConfig.success = true;
      this.openSnackBar();
      this._router.navigate(['audit']);

    }, res => {
      this.snackBarConfig.message = res.error;
      this.snackBarConfig.success = false;
      this.openSnackBar();
      this.ngOnInit();
    });
  }

  openConfirmationDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '30%',
      data: 'Are you sure you want to cancel this audit?',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.cancelAudit();
      }
    });
  }



  completeAudit(newStatus) {
    if (newStatus) {
      this._auditService.completeAudit(this.auditId).subscribe(data => {
        this.auditDetails.status = newStatus;
        this.statusClass = this.getStatusClass();
        this.auditWasEdited.emit(this.auditDetails.status);
        this.snackBarConfig.message = 'Audit Updated Successfully';
        this.snackBarConfig.success = true;
        this.openSnackBar();
      }, res => {
        this.snackBarConfig.message = res.error;
        this.snackBarConfig.success = false;
        this.openSnackBar();
      });
    }
  }

  openSnackBar() {
    this.snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: this.snackBarConfig,
      duration: this.snackBarConfig.duration
    });
  }
}
