import { Component, OnInit, ViewChild, OnDestroy, Input } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { UnkittedItemsCountsheetDataSource } from './unkitted-items-countsheet-datasource';
import { ActivatedRoute, Router } from '@angular/router';
import { CountSheetService } from '../../../services/count-sheet.service';
import { CountSheetItemService } from '../../../services/count-sheet-item.service';
import { RedspotInventoryImportService } from '../../../services/redspot-inventory-import.service';
import { ConsignmentInventoryImportService } from '../../../services/consignment-inventory-import.service';
import { of, zip, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { OnlineService } from './../../../services/online.service';
import { CountSheetItemHelper } from '../../../helpers/countsheet-item-helper';
import { IStorageItem } from '../../../interfaces/i-storage-item';
import { ISnackBarConfig } from '../../../interfaces/isnack-bar-config';
import { IcountsheetItemData, ILotPoolManagedCountsheetItemGroup } from '../../../interfaces/icountsheet-item';
import { ItemCombinationService } from '../../../services/item-combination.service';
import { ItemSettingService } from '../../../services/item-setting.service';
import { IcountMetadata } from '../../../interfaces/icount-metadata';
import { UserDataHelper } from '../../../helpers/user-data-helper';
import { IuserData } from '../../../interfaces/iuser-data';
import * as moment from 'moment';
import { ExclusionService } from '../../../services/exclusion.service';
import { PrintPageComponent } from '../../print-page/print-page.component';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { ItemService } from 'src/app/services/item.service';
import { MaterialSnackbarComponent } from '../../material-snackbar/material-snackbar.component';
import { HistoryServiceService } from 'src/app/services/history-service.service';
import { OfflineDataSynchronizerService } from 'src/app/services/offline-data-synchronizer.service';
import { WarningDialogComponent } from '../../warning-dialog/warning-dialog.component';
import { KitService } from '../../../services/kit.service';
import { KitDefinitionItemService } from '../../../services/kit-definition-item.service';
import { TrackLoadingService } from '../../../services/track-loading.service';
import { ICountSheetData } from 'src/app/interfaces/icount-sheet-data';
import { ImportCountSheetItemComponent } from '../../import-count-sheet-item/import-count-sheet-item.component';
import { CreateUnkittedCountSheetComponent } from '../../create-unkitted-count-sheet/create-unkitted-count-sheet.component';
import { AuditService } from 'src/app/services/audit.service';
import { PrintHelper } from '../../../helpers/print-helper';
import { IPrintOptions } from 'src/app/interfaces/i-print-options';
import { PositiveIntegerHelper } from '../../../helpers/positive-integer-helper';
import { LotPoolManagedItemService } from '../../../services/lot-pool-managed-item.service';
import { CountSheetExportHelper } from 'src/app/helpers/countsheet-export-helper';
import { ExportToExcelService } from 'src/app/services/export-to-excel.service';
import { ICountsheetExportOptions } from 'src/app/interfaces/i-countsheet-export-options';
import { AudioPlayerService } from 'src/app/services/audio-player.service';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';
import { DatabaseService } from '../../../services/database.service';
import { CountSheetItemDatabaseService } from '../../../services/count-sheet-item-database.service';
import { CountSheetDatabaseService } from '../../../services/count-sheet-database.service';
import { AuditDatabaseService } from '../../../services/audit-database.service';
import { AttachmentDialogComponent } from '../../attachment-dialog/attachment-dialog.component';
import { Messages } from 'src/app/helpers/messages';
import { Iitem } from 'src/app/interfaces/iitem';
import { IDeviceInfo } from 'src/app/interfaces/i-device-info';
import { CameraService } from 'src/app/services/camera.service';
import { RecallOracleService } from 'src/app/services/recall-oracle.service';

@Component({
  selector: 'app-unkitted-items-countsheet',
  templateUrl: './unkitted-items-countsheet.component.html',
  styleUrls: ['./unkitted-items-countsheet.component.css']
})
export class UnkittedItemsCountsheetComponent implements OnInit, OnDestroy {
  @ViewChild('printer') printer: PrintPageComponent;
  @Input() lodcation: any;
  @ViewChild(VirtualScrollerComponent) private virtualScroller: VirtualScrollerComponent;

  dataSource: UnkittedItemsCountsheetDataSource;
  snackBarConfig: ISnackBarConfig = {
    message: '',
    duration: 3000,
    success: false,
    snackBarClass: ''
  };
  permissions: string[];
  previousUrl: string;
  justAddedReference = '';
  justAddedCount = 0;
  countSheet: ICountSheetData;
  currentQuantity: number;
  countSheetItems: any[];
  currentCountSheetItem: IcountsheetItemData;
  redspotQuantities: any;
  itemSettings: any;
  sapQuantities: any;
  itemCombinations: any;
  lotPoolManagedItems: any[];
  exclusions: any;
  kitted = false;
  auditDbId: any;
  onlineSubscription: Subscription;
  audit: any;
  location: any;
  createdBy: any;
  status: string;
  viewOnly = false;
  userData: IuserData;
  items: Iitem[];
  actualItemCount = 0;
  kits: any[];
  rootKitDefinitionItems: any[];
  auditedBy: any;
  isOnline: boolean;
  warehouses: any;
  cameras: IDeviceInfo[];

  // helpers
  countSheetItemHelper: CountSheetItemHelper = new CountSheetItemHelper();
  userDataHelper: UserDataHelper = new UserDataHelper();
  printHelper: PrintHelper = new PrintHelper();
  positiveIntegerHelper: PositiveIntegerHelper = new PositiveIntegerHelper();

  // subscription
  reloadSubscription: Subscription;
  startReloadingSubscription: Subscription;
  isOnlineSubscription: Subscription;
  isOfflineSubscription: Subscription;
  trackLoadingSubscription: Subscription;

  constructor(
    private _route: ActivatedRoute,
    private _historyService: HistoryServiceService,
    private _router: Router,
    public _dialog: MatDialog,
    private _auditService: AuditService,
    private _countSheetService: CountSheetService,
    private _countSheetItemService: CountSheetItemService,
    private _redspotInventoryImportService: RedspotInventoryImportService,
    private _consignmentInventoryImportService: ConsignmentInventoryImportService,
    private _onlineService: OnlineService,
    private _itemCombinationService: ItemCombinationService,
    private _itemSettingService: ItemSettingService,
    private _exclusionService: ExclusionService,
    private _itemService: ItemService,
    public _snackBar: MatSnackBar,
    private _synchronizer: OfflineDataSynchronizerService,
    private _kitService: KitService,
    private _kitDefinitionItemService: KitDefinitionItemService,
    private _trackLoadingService: TrackLoadingService,
    private _lotPoolManagedItemService: LotPoolManagedItemService,
    private _excelService: ExportToExcelService,
    private _audioPlayerService: AudioPlayerService,
    private _databaseService: DatabaseService,
    private _countSheetItemDatabaseService: CountSheetItemDatabaseService,
    private _countSheetDatabaseService: CountSheetDatabaseService,
    private _auditDatabaseService: AuditDatabaseService,
    private _cameraService: CameraService,
    private _recallOracleService: RecallOracleService
  ) {
    this.dataSource = new UnkittedItemsCountsheetDataSource(this.countSheetItemHelper, this._countSheetItemDatabaseService);
    this._itemService.getItems().subscribe((data) => {
      this.items = data;
      this.countSheetItemHelper.cacheItems(this.items);
    });

    this._cameraService.getDevices((devices: IDeviceInfo[]) => {
      this.cameras = devices;
    })
  }

  ngOnInit() {
    const that = this;
    this.auditDbId = this._route.snapshot.paramMap.get('audit_id');
    this.previousUrl = this._historyService.findLastCountSheetSummaryLink();
    this.userData = this.userDataHelper.getUserData();
    this.viewOnly = this._route.snapshot.paramMap.get('isViewOnly') === 'true';
    this.status = 'All';
    this.permissions = JSON.parse(localStorage.getItem('permissions'));
    this._route.queryParams.subscribe(params => {
      if (params.status) {
        this.status = params.status;
      }
    });

    this.reloadSubscription = this._synchronizer.allSynchronizedEvent.subscribe(() => {
      this.reloadWhenBackOnline();
    });

    //keep this.online up to date
    this.isOnline = this._onlineService.isOnline() && !this._onlineService.testingOffline;
    this.isOnlineSubscription = this._onlineService.isOnlineSubscription().subscribe(
      online => {
        this.isOnline = online && !this._onlineService.getTestOfflineBool();
      }
    );
    this.isOfflineSubscription = this._onlineService.goOfflineEvent.subscribe((data: boolean) => {
      this.isOnline = !data;
    });
    //end of keep this.online up to date

    this._synchronizer.needsSyncing().then((hasUnsynced) => {
      const wouldSync = that._onlineService.isOnline() && !that._onlineService.testingOffline;
      const currentlySyncing = !that._synchronizer.doneProcessing;
      if (wouldSync && (hasUnsynced || currentlySyncing)) {
        return;
      }
      this.getKits();
      this._trackLoadingService.startLoading('unkitted-count-sheet-items', 'Loading Items');
      this._trackLoadingService.startLoading('unkitted-count-sheet-lot-pool-managed-items', 'Loading Lot Pool Managed Items');
      zip(
        this._lotPoolManagedItemService.getLotPoolManagedItems(),
        of(this._onlineService.isOnline()),
        this._auditDatabaseService.get(this.auditDbId)
      ).subscribe(data => {
        this._trackLoadingService.stopLoading('unkitted-count-sheet-items');
        this._trackLoadingService.stopLoading('unkitted-count-sheet-lot-pool-managed-items');
        this.lotPoolManagedItems = data[0];
        const online = data[1];
        this.audit = data[2];
        this.dataSource.lotPoolManagedItems = this.lotPoolManagedItems;
        this._trackLoadingService.startLoading('unkitted-count-sheet-item-count-sheet', 'Loading Count Sheet');
        this.getCountSheet(online, this._route.snapshot.paramMap.get('id')).then((countsheetData: ICountSheetData) => {

          this._trackLoadingService.stopLoading('unkitted-count-sheet-item-count-sheet');

          this.countSheet = countsheetData;
          this.dataSource.countSheet = this.countSheet;

          this._trackLoadingService.startLoading('unkitted-count-sheet-item-redspot-quantities', 'Loading Redspot Inventory');
          this._trackLoadingService.startLoading('unkitted-count-sheet-item-sap-quantities', 'Loading SAP Inventory');
          this._trackLoadingService.startLoading('unkitted-count-sheet-item-item-settings', 'Loading Item Settings');
          this._trackLoadingService.startLoading('unkitted-count-sheet-item-exclusions', 'Loading Exclusions');
          this._trackLoadingService.startLoading('unkitted-count-sheet-item-count-sheet-items', 'Loading Count Sheet Items');
          this._trackLoadingService.startLoading('unkitted-count-sheet-item-kit-boms', 'Loading Kit BOMs');
          this._trackLoadingService.startLoading('unkitted-count-sheet-item-combinations', 'Loading Item Batch Verification');

          zip(
            this.getRedspotQuantities(this.countSheet.data.audit_id, this.countSheet.data.warehouse_id).toPromise().then((data) => { that._trackLoadingService.stopLoading('unkitted-count-sheet-item-redspot-quantities'); return data; }),
            this.getSapQuantities(this.countSheet.data.audit_id).toPromise().then((data) => { this._trackLoadingService.stopLoading('unkitted-count-sheet-item-sap-quantities'); return data; }),
            this.getItemSettingsByAudit(this.countSheet.data.audit_id).toPromise().then((data) => { this._trackLoadingService.stopLoading('unkitted-count-sheet-item-item-settings'); return data; }),
            this.getExclusions(this.countSheet.data.audit_id).toPromise().then((data) => { this._trackLoadingService.stopLoading('unkitted-count-sheet-item-exclusions'); return data; }),
            this.getCountSheetItems(online, this.auditDbId, this.countSheet.id).then((data) => { this._trackLoadingService.stopLoading('unkitted-count-sheet-item-count-sheet-items'); return data; }),
            this.getRootKitDefinitionItems(this.countSheet.data.kit_id).toPromise().then((data) => { this._trackLoadingService.stopLoading('unkitted-count-sheet-item-kit-boms'); return data; }),
            this._auditService.getWarehouses(this.countSheet.data.audit_id),
            this.getItemCombinations().toPromise().then((data) => { this._trackLoadingService.stopLoading('unkitted-count-sheet-item-combinations'); return data; }),
          ).subscribe(allUnkittedCountsheetData => {

            this.redspotQuantities = allUnkittedCountsheetData[0];
            this.dataSource.redspotQuantities = this.redspotQuantities;

            this.sapQuantities = allUnkittedCountsheetData[1];
            this.dataSource.sapQuantities = this.sapQuantities;

            this.itemSettings = allUnkittedCountsheetData[2];
            this.dataSource.itemSettings = this.itemSettings;

            this.exclusions = allUnkittedCountsheetData[3];
            this.dataSource.exclusions = this.exclusions;

            this.countSheetItems = allUnkittedCountsheetData[4];
            this.dataSource.countSheetItems = this.countSheetItems;

            this.rootKitDefinitionItems = allUnkittedCountsheetData[5];
            this.dataSource.rootKitDefinitionItems = this.rootKitDefinitionItems;

            this.warehouses = allUnkittedCountsheetData[6];

            this.itemCombinations = allUnkittedCountsheetData[7];
            this.dataSource.itemCombinations = this.itemCombinations;
            
            this.countSheetItemHelper.cacheItemsFromSapQuantities(this.sapQuantities);
            this.countSheetItemHelper.cacheItemsForExclusions(this.exclusions);
            this.countSheetItemHelper.cacheItemsForItemSettings(this.itemSettings);
            this._trackLoadingService.startLoading('unkitted-count-sheet-recall-oracle', 'Loading Recall Oracle');
            this._recallOracleService.prime(this.auditDbId, {itemCombinations: this.itemCombinations}).then(_ => {
              this._trackLoadingService.stopLoading('unkitted-count-sheet-recall-oracle');
              this.dataSource.recallOracleService = this._recallOracleService;
              this.refreshDataSource();
            });
          });
        });
      });
    });

    this.trackLoadingSubscription = this._trackLoadingService.stopLoadingEvent.subscribe(() => {
      if ('scrollRestoration' in history) {
        history.scrollRestoration = 'manual';
      }
      document.getElementById('top').scrollIntoView();
    })
  }

  bomChanged(event) {
    this._trackLoadingService.startLoading('unkitted-count-sheet-item-bom-changed', 'Updating BOM');
    this.updateBom(+event.value).subscribe(data => {
      this.countSheet = data;
      this.dataSource.countSheet = this.countSheet;
      zip(
        this.getRootKitDefinitionItems(+event.value)
      ).subscribe((kitDefinitionData: any[]) => {
        this.rootKitDefinitionItems = kitDefinitionData[0];
        this.dataSource.rootKitDefinitionItems = this.rootKitDefinitionItems;
        this.refreshDataSource();
        this._trackLoadingService.stopLoading('unkitted-count-sheet-item-bom-changed');
      });
    });
  }

  getRootKitDefinitionItems(kitId) {
    return this._kitDefinitionItemService.getKitDefinitionItems().pipe(map(data => {
      return data.filter(kdi => kdi.kit_definition_id === kitId);
    }));
  }

  updateTotalCount() {
    const itemQuantities = this.countSheetItems.map(csi => parseInt(csi.data.quantity, 10));
    if (itemQuantities.length > 0) {
      this.actualItemCount = itemQuantities.reduce((a: number, b: number) => a + b);
    } else {
      this.actualItemCount = 0;
    }
  }

  refreshDataSource() {
    this.dataSource.refresh();
    this.updateTotalCount();
    this.setAuditedByValue(this.countSheetItems);
    const height = 58;
    (<HTMLElement>document.getElementsByTagName('virtual-scroller')[0]).style.height = Math.min(height + height * this.dataSource.data.length, height * 11).toString() + 'px';
  }

  updateBom(v) {
    const that = this;
    const value = v || 'null'; //so we have a set value, but it will get set to nil in the backend
    const online = this._onlineService.isOnline();
    if (online && !this._onlineService.getTestOfflineBool()) {
      return this._countSheetService.updateCountSheet(this.countSheet.dbId, { kit_id: value, show_expected_item_list: (!!v).toString() }).pipe(map(function (data) {
        const updatedCountSheet = that._countSheetDatabaseService.new(data);
        that._countSheetDatabaseService.update(updatedCountSheet);
        return updatedCountSheet;
      }));
    } else {
      const updatedCountSheet = that.countSheet;
      updatedCountSheet.data.kit_id = value;
      updatedCountSheet.data.show_expected_item_list = !!v
      updatedCountSheet.isSynchronized = 0;
      that._countSheetDatabaseService.update(updatedCountSheet);
      return of(updatedCountSheet);
    }
  }

  getKits() {
    this._kitService.getKits().subscribe(data => {
      this.kits = data;
    });
  }

  getCountSheet(online, countSheetClientId) {
    const that = this;
    const promise = new Promise((resolve) => {
      if (online && !this._onlineService.getTestOfflineBool()) {
        this._countSheetService.getCountSheet(countSheetClientId).subscribe(function (data) {
          that.audit = data.audit;
          that.createdBy = data.created_by;
          that.location = data.audit_location;
          const updatedCountSheet = that._countSheetDatabaseService.new(data);
          that._countSheetDatabaseService.update(updatedCountSheet);
          resolve(updatedCountSheet);
        });
      } else {
        that._countSheetDatabaseService.get(countSheetClientId).then(function (cs) {
          resolve(cs);
        });
      }
    });
    return promise;
  }

  getCountSheetItems(online, auditId, countSheetClientId) {
    const that = this;
    return new Promise((resolve) => {
      if (online && !this._onlineService.getTestOfflineBool()) {
        this._countSheetItemService.getCountSheetItems(auditId, { count_sheet_client_id: countSheetClientId }).subscribe(function (data) {
          that._countSheetItemService.refreshOfflineDataForCountSheet(auditId, countSheetClientId, data).then(function (csis) {
            resolve(csis);
          });
        });
      } else {
        that._countSheetItemDatabaseService.find(auditId, { count_sheet_client_id: countSheetClientId, _destroy: false }).then(function (existingCountSheetItems: IcountsheetItemData[]) {

          //we must have already cached these into indexeddb
          if (existingCountSheetItems.length > 0) {
            return resolve(existingCountSheetItems);
          }
          //get the data from the cached serviceworker
          that._countSheetItemService.getCountSheetItems(auditId).subscribe((auditCountSheetItems) => {
            const matches = auditCountSheetItems.filter(cs => cs.count_sheet_client_id === countSheetClientId);
            that._countSheetItemService.refreshOfflineDataForCountSheet(auditId, countSheetClientId, matches).then(function (csis) {
              resolve(csis);
            });
          });
        });
      }
    });
  }



  quantityChanged(event, element) {
    const that = this;
    this.currentCountSheetItem = element;
    this.currentCountSheetItem.data.modified_by = this.userData.name;
    this.currentCountSheetItem.data.quantity = event.target.value;
    this.currentCountSheetItem.data.checked = true;
    this.currentCountSheetItem.data.quantity = +event.target.value;
    this.currentCountSheetItem.isSynchronized = 0;
    if (this.currentCountSheetItem.data.quantity > 1 && this.currentCountSheetItem.data.serial) {
      this.showSnackbar('Quantity must be less than 2', false);
      return;
    }
    this._countSheetItemService.update(this.currentCountSheetItem).then(function () {
      that.showWarningDialogMessages();
      zip(
        that.getCountSheet(false, that.countSheet.id),
        that.getCountSheetItems(false, that.auditDbId, that.countSheet.id)
      ).subscribe((data: any[]) => {
        that.countSheet = data[0];
        that.countSheetItems = data[1];
        that.setAuditedByValue(that.countSheetItems);
        that.updateTotalCount();
      });
    });
  }

  deleteAll() {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '30%',
      data: Messages.clearCountSheet,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const that = this;
        that._trackLoadingService.startLoading('delete-all-clicked', 'Updating Count Sheet');
        that._countSheetItemService.deleteCountSheetItemsOffline(that.auditDbId, that.countSheet.id).then(_ => {
          that.countSheetItems = [];
          that.dataSource.countSheetItems = this.countSheetItems;
          that.updateJustAddedData(0, '');
          that.refreshDataSource();
          that.countSheet.data.total_items_counted = 0;
          this._trackLoadingService.stopLoading('delete-all-clicked');
        });
      }
    });
  }


  lotPoolManagedQuantityChanged(event, element: ILotPoolManagedCountsheetItemGroup) {
    const newTotalQty = +event.target.value;
    const oldTotalQty = element.count_sheet_items.reduce((total, csi) => total + csi.data.quantity, 0);
    element.count_sheet_items = this.countSheetItemHelper.lotPoolManagedQuantityChanged(
      newTotalQty - oldTotalQty,
      element.count_sheet_items,
      element.available_redspot_inventory,
      this.sapQuantities.filter(sap => sap.item_id === element.data.item_id),
      element.data,
      this.countSheet,
      { isKitted: false }
    )[0];

    element.data.quantity = element.count_sheet_items.reduce((total, csi) => total + csi.data.quantity, 0);
    this.updateCountSheetItem(element.count_sheet_items);
  }

  lotNumberChanged(event, element: IcountsheetItemData) {
    let secondaryElement;
    let newlotNumber = (event.target.value || '').toUpperCase();
    if (!newlotNumber && this.isLotPoolManaged(element)) {
      newlotNumber = this.countSheetItemHelper.getAvailablelotPoolManagedLotNumber(
        this.countSheetItems.filter(cs => cs.data.item_id === element.data.item_id),
        [],
        this.sapQuantities.filter(sap => sap.item_id === element.data.item_id)
      );
      event.target.value = newlotNumber;
    }

    //if row wasn't checked they changed the lot and expect the quantity to stay the same, as that is what it is visually
    if (!element.data.checked) {
      element.data.quantity = element.data.expected_quantity ? element.data.expected_quantity : 0;
    }
    element._destroy = false;
    element.data.checked = true;
    element.data.manually_entered = true;
    element.data.modified_by = this.userData.name;

    secondaryElement = JSON.parse(JSON.stringify(element));
    secondaryElement.data.quantity = 0;
    secondaryElement.data.hide = true;

    element.id = '';
    element.dbId = 0;
    element.data.lot_number = newlotNumber;
    this.updateCountSheetItem(element, secondaryElement);
  }

  serialNumberChanged(event, element: IcountsheetItemData) {
    let secondaryElement;
    if (!element.data.checked) {
      element.data.quantity = element.data.expected_quantity;
    }
    element._destroy = false;
    element.data.isKitted = true;
    element.data.checked = true;
    element.data.manually_entered = true;
    element.data.modified_by = this.userData.name;

    secondaryElement = JSON.parse(JSON.stringify(element));
    secondaryElement.data.quantity = 0;
    secondaryElement.data.hide = true;

    element.id = '';
    element.dbId = 0;
    element.data.serial = event.target.value ? event.target.value.toUpperCase() : event.target.value;
    this.updateCountSheetItem(element, secondaryElement);
  }

  /**
   * change reference for manually entered items online/ offline
   * @param event
   * @param element
  */
  referenceChanged(event, element: IcountsheetItemData) {
    let secondaryElement;

    const match = this.items.filter(item =>
      (item.reference.toUpperCase() === event.target.value.toUpperCase()) || (item.unpunctuated_reference.toUpperCase() === event.target.value.toUpperCase())
    );
    if (match.length === 0) {
      this.showSnackbar('\'' + event.target.value.toUpperCase() + '\' is not a valid reference.', false);
      event.target.value = element.data.reference;
    } else {
      secondaryElement = JSON.parse(JSON.stringify(element));
      secondaryElement.data.quantity = 0;
      secondaryElement.data.hide = true;

      element.id = '';
      element.dbId = 0;
      element.data.reference = match[0].reference;
      element.data.item_id = match[0].id;
      element.data.description = match[0].description;
      element.data.is_lot_tracked = match[0].is_lot_number_tracked;
      element.data.is_lot_pool_managed = match[0].is_lot_pool_managed;
      element.data.is_serial_tracked = match[0].is_serial_tracked;
      element.data.min_order_quantity = match[0].minimum_order_quantity;
      element.data.is_consigned = match[0].is_consigned;

      this.updateCountSheetItem(element, secondaryElement);
    }
  }

  updateCountSheetItem(primaryElements, secondaryElements = [], callback = null) {
    primaryElements = [].concat(...[primaryElements]);
    secondaryElements = [].concat(...[secondaryElements]);
    primaryElements.forEach((e) => {
      e.data.count_sheet_id = this.countSheet.dbId;
      e.data.count_sheet_client_id = this.countSheet.id;
      e.data.audit_id = this.countSheet.data.audit_id;
      e.isSynchronized = 0;
    });
    secondaryElements.forEach((e) => {
      e.data.count_sheet_id = this.countSheet.dbId;
      e.data.count_sheet_client_id = this.countSheet.id;
      e.data.audit_id = this.countSheet.data.audit_id;
      e.isSynchronized = 0;
    });
    const elements = [].concat(...[primaryElements, secondaryElements]);
    callback = callback || function () { };
    const that = this;
    const online = this._onlineService.isOnline() && !this._onlineService.getTestOfflineBool();

    const errors = primaryElements.map(e => {
      return that.countSheetItemHelper.validateCountSheetItem(e);
    }).reduce(function (total, internalErrors) { return { ...total, ...internalErrors }; });
    if (Object.keys(errors).length > 0) {
      that.showSnackbar(Object.values(errors).join('.  '), false);
      this.getCountSheetItems(online, this.auditDbId, this.countSheet.id).then((data: any[]) => {
        this.countSheetItems = data;
        this.dataSource.countSheetItems = this.countSheetItems;
        this.refreshDataSource();
      });
      return;
    }
    zip(...elements.map(function (e) {
      return that._countSheetItemService.update(e);
    })).subscribe(function () {
      zip(
        that.getCountSheet(false, that.countSheet.id),
        that.getCountSheetItems(false, that.auditDbId, that.countSheet.id)
      ).subscribe(function (data: any[]) {
        that.countSheetItems = data[1];
        that.countSheet = data[0];
        that.setAuditedByValue(this.countSheetItems);
        that.updateTotalCount();
        [...Array.from(new Set(
          primaryElements.map(
            e => that.countSheetItemHelper.checkForWarnings(e, that._recallOracleService)
          ).reduce((total, message) => {
            return total.concat(message.warnings);
          }, [])
        ))].forEach(m => {
          that.openWarningDialog(m.toString());
        });
        callback();
      });
    });
  }

  updateCountSheetItems() {
    const that = this;
    zip(
      this.getCountSheet(false, that.countSheet.id),
      this.getCountSheetItems(false, that.auditDbId, that.countSheet.id)
    ).subscribe((data: any[]) => {
      that.countSheet = data[0];
      that.countSheetItems = data[1];
      that.dataSource.countSheetItems = that.countSheetItems;
      that.refreshDataSource();
      const justAdded = that.countSheetItems.filter(cs => cs.data.justAdded);
      that.updateJustAddedData(justAdded.reduce((a, b) => a + b.data.quantity, 0), (((justAdded[0] || {}).data || {}).reference || ''));
      this.scrollToBottom();
    });
  }

  isMinOrderQuantityGreaterThanOne(countSheetItem) {
    const minOrderQuantity = countSheetItem.data.min_order_quantity;
    if (minOrderQuantity > 1) {
      return true;
    } return false;
  }

  getExclusions(auditId) {
    return this._exclusionService.getExclusions(auditId);
  }

  getRedspotQuantities(auditId, warehouseId) {
    return this._redspotInventoryImportService.getInventory(auditId).pipe(map(data => data.filter(rq => rq.warehouse_id === warehouseId && !rq.kit_instance_id)));
  }

  getSapQuantities(auditId) {
    return this._consignmentInventoryImportService.getInventory(auditId, true);
  }

  getItemCombinations() {
    return this._itemCombinationService.get();
  }

  getItemSettingsByAudit(auditId: number) {
    return this._itemSettingService.getItemSettingsByAudit(auditId);
  }

  printAndComplete() {
    const that = this;
    new Promise((resolve, reject) => {
      if (that.countSheet.data.attachments_count > 0) {
        return resolve(null);
      }
      const dialogRef = that._dialog.open(ConfirmationDialogComponent, {
        width: '30%',
        data: Messages.finishWithoutAttachment,
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          return resolve(null);
        }
        reject();
      });
    }).then(() => {
      that._databaseService.add('user_actions', {
        audit_id: that.auditDbId.toString(),
        auditor: `${that.userData.first_name} ${that.userData.last_name}`,
        timestamp: `${(new Date()).toUTCString()}`,
        area: `${that.countSheet.data.area}`,
        kit_reference: '',
        kit_lot_number: '',
        action: 'Printed count sheet',
        total_items_counted: that.countSheet.data.total_items_counted,
        site_id: that.countSheet.data.audit_location_id,
        warehouse_id: that.countSheet.data.warehouse_id
      });

      that._databaseService.add('user_actions', {
        audit_id: this.auditDbId.toString(),
        auditor: `${this.userData.first_name} ${that.userData.last_name}`,
        timestamp: `${(new Date()).toUTCString()}`,
        area: `${that.countSheet.data.area}`,
        kit_reference: '',
        kit_lot_number: '',
        action: 'Finished count sheet',
        total_items_counted: that.countSheet.data.total_items_counted,
        site_id: that.countSheet.data.audit_location_id,
        warehouse_id: that.countSheet.data.warehouse_id
      });
      that.completeCountSheet(true);
    }, () => {
      console.log('they didnt want to finish without an attachment');
    });
  }

  completeCountSheet(print: boolean = false) {
    const countedMetadata: IcountMetadata = {
      counted_by_id: this.userData.user_id,
      counted_time: moment().toDate()
    };
    const countedStatus = 3;
    const that = this;
    of(that._onlineService.isOnline()).subscribe(online => {
      if (online && !that._onlineService.getTestOfflineBool()) {
        //set status to counted
        that._countSheetService.updateCountSheetStatus(that.countSheet.dbId, countedStatus, countedMetadata).subscribe(data => {
          if (data.success) {
            that.countSheet.data.counted_by = this.userData;
            that.countSheet.data.counted_by_id = that.userData.user_id;
            that.countSheet.data.counted_time = moment().format('lll');
            that.countSheet.data.count_sheet_status = countedStatus;
            that._countSheetDatabaseService.update(that.countSheet).then(function () {
              if (print) {
                that.dataSource.countSheet = that.countSheet;
                that.dataSource.refresh();
                const printOptions: IPrintOptions = {
                  isKitted: false,
                  warehouseName: that.countSheet.data.warehouse_name,
                  locationName: that.countSheet.data.location_name,
                  lotNumber: that.countSheet.data.lot_number,
                  statusText: that.countSheet.data.status_text,
                  agencyName: that.audit.agency_name,
                  reference: that.countSheet.data.reference,
                  area: that.countSheet.data.area,
                  countSheet: that.countSheet,
                  redspotQuantities: that.redspotQuantities,
                  lotPoolManagedItems: that.lotPoolManagedItems,
                  kitDefinitionItems: that.rootKitDefinitionItems,
                  filters: [],
                  itemSettings: that.itemSettings,
                  exclusions: that.exclusions
                };
                that.printHelper.print(that.dataSource.data, printOptions);
              }
              that.goToCountSheets();
            });
          } else {
            that.snackBarConfig.message = 'Failed';
            that.snackBarConfig.success = false;
            that.openSnackBar();
          }
        });
      } else {
        that.countSheet.data.counted_by = this.userData;
        that.countSheet.data.counted_by_id = that.userData.user_id;
        that.countSheet.data.counted_time = moment().format('lll');
        that.countSheet.data.count_sheet_status = countedStatus;
        that.countSheet.isSynchronized = 0;
        that._countSheetDatabaseService.update(that.countSheet).then(function () {
          if (print) {
            that.dataSource.countSheet = that.countSheet;
            that.dataSource.refresh();
            const printOptions: IPrintOptions = {
              isKitted: false,
              warehouseName: that.countSheet.data.warehouse_name,
              locationName: that.countSheet.data.location_name,
              lotNumber: that.countSheet.data.lot_number,
              statusText: that.countSheet.data.status_text,
              agencyName: that.audit.agency_name,
              reference: that.countSheet.data.reference,
              area: that.countSheet.data.area,
              countSheet: that.countSheet,
              redspotQuantities: that.redspotQuantities,
              lotPoolManagedItems: that.lotPoolManagedItems,
              kitDefinitionItems: that.rootKitDefinitionItems,
              filters: [],
              itemSettings: that.itemSettings,
              exclusions: that.exclusions
            };
            that.printHelper.print(that.dataSource.data, printOptions);
          }
          that.goToCountSheets();
        });
      }
    });

  }

  showSnackbar(message: string, success: boolean) {
    this.snackBarConfig.message = message;
    this.snackBarConfig.success = success;
    this.openSnackBar();
  }

  goToCountSheets() {
    if (this.previousUrl.indexOf('siteID') !== -1) {
      this._router.navigate([this.removeQueryStrings(this.previousUrl)], { queryParams: { status: this.countSheet.data.status_text, selected_tab: 1, siteID: this.location.id } });
    } else {
      if (this.viewOnly) {
        this._router.navigate([this.previousUrl], { queryParams: { status: this.status, selected_tab: 1, orderType: 'consigned' } });
      } else {
        this._router.navigate([this.previousUrl], { queryParams: { status: this.status, selected_tab: 1, orderType: 'consigned' } });
      }
    }
  }

  removeQueryStrings(url: string) {
    let res = '';
    const qsStart = url.indexOf('?');
    if (qsStart !== -1) {
      res = url.substr(0, qsStart);
    } else {
      res = url;
    }
    return res;
  }

  goToAuditDashboard() {
    this._router.navigate(['audit_dashboard', this.auditDbId]);
  }

  reloadWhenBackOnline() {
    this.snackBarConfig.message = 'Connected!! Reloading...';
    this.snackBarConfig.success = true;
    this.openSnackBar();

    const countSheetClientId = this._route.snapshot.paramMap.get('id');
    this._router.navigate([this.auditDbId, 'non_kitted_count_sheet', countSheetClientId, this.viewOnly], { queryParams: { status: this.status } });
  }

  scrollToBottom() {
    document.getElementById('bottom').scrollIntoView();
    this.virtualScroller.scrollToIndex(this.dataSource.data.length - 1);
  }

  ngOnDestroy() {
    if (this.reloadSubscription) {
      this.reloadSubscription.unsubscribe();
    }

    if (this.startReloadingSubscription) {
      this.startReloadingSubscription.unsubscribe();
    }

    if (this.isOnlineSubscription) {
      this.isOnlineSubscription.unsubscribe();
    }

    if (this.isOfflineSubscription) {
      this.isOfflineSubscription.unsubscribe();
    }

    if(this.trackLoadingSubscription){
      this.trackLoadingSubscription.unsubscribe();
    }
    
    this.printer = null;
    this.virtualScroller = null;
  }

  openSnackBar() {
    this._snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: this.snackBarConfig,
      duration: this.snackBarConfig.duration
    });
  }

  openWarningDialog(message: string) {
    this.countSheetItemHelper.playErrorSound(this._audioPlayerService.setting);
    const dialogRef = this._dialog.open(WarningDialogComponent, {
      width: '50%',
      disableClose: true,
      role: 'alertdialog',
      data: {
        message: message
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  showWarningDialogMessages() {
    const messages = this.countSheetItemHelper.checkForWarnings(this.currentCountSheetItem, this._recallOracleService);
    messages.warnings.forEach(m => {
      this.openWarningDialog(m);
    });
  }

  setAuditedByValue(countSheetItems) {
    if (countSheetItems) {
      const names = countSheetItems.map(csi => csi.data.modified_by).filter((v, i, a) => a.indexOf(v) === i);
      this.auditedBy = names.join(' / ');
    } else {
      this.auditedBy = '';
    }
  }

  openCountSheetItemImportDialog() {
    const that = this;
    const dialogRef = this._dialog.open(ImportCountSheetItemComponent, {
      width: '30%',
      data: {
        countSheet: that.countSheet,
        lotPoolManagedItems: that.lotPoolManagedItems,
        redspotItems: [],
        sapItems: that.sapQuantities
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        that.getCountSheetItems(that.isOnline, that.auditDbId, that.countSheet.id).then((data: any[]) => {
          that.countSheetItems = data;
          that.dataSource.countSheetItems = this.countSheetItems;
          that.getCountSheet(false, that.countSheet.id).then((cs: ICountSheetData) => { //to get the updated total_items_counted
            that.countSheet = cs;
            that.refreshDataSource();
          });
        });
      }
    });
  }

  editUnkittedCountSheet() {
    const dialogRef = this._dialog.open(CreateUnkittedCountSheetComponent, {
      width: '50%',
      data: {
        title: 'Edit Unkitted Countsheet',
        creatingCountsheet: false,
        countsheet: this.countSheet,
        warehouses: this.warehouses,
        offline: true
      }
    });

    dialogRef.componentInstance.unkittedCountSheetEditedEvent.subscribe((data: ICountSheetData) => {
      this.countSheet = data;
    });
  }

  anyUnsynched(row) {
    return (row.count_sheet_items || [row]).filter(r => !r.isSynchronized).length > 0;
  }


  isLotPoolManaged(row) {
    return this.countSheetItemHelper.getIsLotPoolManaged(row.data.item_id, this.lotPoolManagedItems);
  }

  updateJustAddedData(count, reference) {
    this.justAddedCount = count;
    this.justAddedReference = reference;
  }

  cleanQuantity(event: KeyboardEvent) {
    if (!this.positiveIntegerHelper.isNumeric(event)) {
      event.preventDefault();
    }
  }

  exportCountsheetItems() {
    const exportOptions: ICountsheetExportOptions = { kit: false };
    const exportHelper = new CountSheetExportHelper(this._excelService);
    exportHelper.exportCountsheetItemsToExcel(this.dataSource.data, exportOptions);
  }

  cancelCounting() {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '30%',
      data: Messages.cancelCounting,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._databaseService.add('user_actions', {
          audit_id: this.auditDbId.toString(),
          auditor: `${this.userData.first_name} ${this.userData.last_name}`,
          timestamp: `${(new Date()).toUTCString()}`,
          area: `${this.countSheet.data.area}`,
          kit_reference: '',
          kit_lot_number: '',
          action: 'Canceled count sheet',
          total_items_counted: this.countSheet.data.total_items_counted,
          site_id: this.countSheet.data.audit_location_id,
          warehouse_id: this.countSheet.data.warehouse_id
        });

        if (this._onlineService.isOnline() && !this._onlineService.getTestOfflineBool()) {
          this._countSheetService.deleteCountSheet(this.countSheet).subscribe(() => {
            this.goToCountSheets();
          });
        } else {
          this.countSheet.isSynchronized = 0;
          this.countSheet.data.archived = true;
          this._countSheetDatabaseService.update(this.countSheet);
          this.goToCountSheets();
        }
      }
    });

  }

  isAnySerialTracked(): boolean {
    if (this.countSheetItems) {
      const serialTracked = this.countSheetItems.find((csi: IcountsheetItemData) => csi.data.is_serial_tracked === true);
      if (serialTracked) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  hasItemWithSerial(): boolean {
    if (this.countSheetItems) {
      const serialTracked = this.countSheetItems.find((csi: IStorageItem) => csi.data.serial);
      if (serialTracked) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  continueInTable(event, element, callback) {
    const lastVisible = this.virtualScroller.viewPortItems.indexOf(element) === this.virtualScroller.viewPortItems.length - 1;
    const notLast = this.dataSource.data.indexOf(element) !== this.dataSource.data.length - 1;
    event.preventDefault();
    if (lastVisible && notLast) {
      const lastAvailablePosition = this.dataSource.data.length - 1;
      const wantedPosition = this.dataSource.data.indexOf(element) + Math.ceil(this.virtualScroller.viewPortItems.length / 2) + 1;
      this.virtualScroller.scrollToIndex(
        Math.min(lastAvailablePosition, wantedPosition),
        false,
        0,
        undefined,
        callback
      );
    } else {
      callback();
    }
  }

  keyDown(event: KeyboardEvent, element: IcountsheetItemData) {
    if (event.code === 'Tab') {
      let el = <HTMLElement>event.srcElement;
      this.continueInTable(event, element, function () {
        while (el.nodeName !== 'TR') {
          el = el.parentElement;
        }
        const node = el.nextElementSibling;
        if (node) {
          const input = <HTMLInputElement>node.querySelector('input[type="number"]');
          if (input) {
            setTimeout(() => {
              input.focus();
              input.select();
            }, 300);
          }
        }
      });
    } else {
      this.cleanQuantity(event);
    }
  }

  attachFile() {
    this.openAttachmentDialog();
  }

  openAttachmentDialog() {
    const that = this;
    const dialogRef = that._dialog.open(AttachmentDialogComponent, {
      width: '70%',
      data: {
        countsheet: that.countSheet,
        devices: that.cameras
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      that.getCountSheet(false, that.countSheet.id).then((cs: ICountSheetData) => {
        that.countSheet = cs;
        that.dataSource.countSheet = that.countSheet;
      });
    });
  }
}
