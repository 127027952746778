import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { urls } from "../helpers/urls";
import { AuditKitInstanceService } from "../services/audit-kit-instance.service";
import { zip } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./audit-kit-instance.service";
var RedspotInventoryImportService = /** @class */ (function () {
    function RedspotInventoryImportService(http, _auditKitInstanceService) {
        this.http = http;
        this._auditKitInstanceService = _auditKitInstanceService;
    }
    RedspotInventoryImportService.prototype.generate = function (auditId, data) {
        var _this = this;
        var that = this;
        return new Promise(function (resolve, reject) {
            _this.http.post("" + environment.serverPath + urls.redspotInventoryImport.replace(":audit_id", auditId) + "/generate", data, { observe: 'response' }).subscribe(function (res) {
                window.caches.keys().then(function (cacheNames) {
                    var cacheOpeningPromises = [];
                    var longTermCountSheetMatch = cacheNames.find(function (cn) { return cn.includes("Inventory"); });
                    var kitsMatch = cacheNames.find(function (cn) { return cn.includes("kits"); });
                    var countSheetsMatch = cacheNames.find(function (cn) { return cn.includes("countsheets"); });
                    if (longTermCountSheetMatch) {
                        cacheOpeningPromises.push(caches.open(longTermCountSheetMatch));
                    }
                    else {
                        cacheOpeningPromises.push(new Promise(function (r, a) { r(false); }));
                    }
                    if (kitsMatch) {
                        cacheOpeningPromises.push(caches.open(longTermCountSheetMatch));
                    }
                    else {
                        cacheOpeningPromises.push(new Promise(function (r, a) { r(false); }));
                    }
                    if (countSheetsMatch) {
                        cacheOpeningPromises.push(caches.open(countSheetsMatch));
                    }
                    else {
                        cacheOpeningPromises.push(new Promise(function (r, a) { r(false); }));
                    }
                    zip.apply(void 0, cacheOpeningPromises).subscribe(function (openCaches) {
                        var longTermCountSheetCache = openCaches[0];
                        var kitCache = openCaches[1];
                        var countSheetCache = openCaches[2];
                        var deletingCachePromises = [];
                        if (longTermCountSheetCache) {
                            deletingCachePromises.push(longTermCountSheetCache.delete("" + environment.serverPath + urls.redspotInventoryImport.replace(":audit_id", auditId), { ignoreSearch: true }));
                        }
                        if (kitCache) {
                            deletingCachePromises.push(kitCache.delete("" + environment.serverPath + urls.kit, { ignoreSearch: true }));
                        }
                        if (countSheetCache) {
                            deletingCachePromises.push(countSheetCache.delete("" + environment.serverPath + urls.countSheet, { ignoreSearch: true }));
                            deletingCachePromises.push(countSheetCache.delete("" + environment.serverPath + urls.countSheetItem, { ignoreSearch: true }));
                        }
                        zip.apply(void 0, deletingCachePromises.concat(new Promise(function (r, a) { r(); }))).subscribe(function (_) {
                            that._auditKitInstanceService.getAuditKitInstancesAndPopulateOfflineData(auditId).then(function (_) {
                                resolve(res);
                            });
                        });
                    });
                });
            });
        });
    };
    RedspotInventoryImportService.prototype.getURL = function (auditId) {
        return "" + environment.serverPath + urls.redspotInventoryImport.replace(":audit_id", auditId);
    };
    RedspotInventoryImportService.prototype.getInventory = function (auditId) {
        var that = this;
        return this.http.get(that.getURL(auditId));
    };
    RedspotInventoryImportService.ngInjectableDef = i0.defineInjectable({ factory: function RedspotInventoryImportService_Factory() { return new RedspotInventoryImportService(i0.inject(i1.HttpClient), i0.inject(i2.AuditKitInstanceService)); }, token: RedspotInventoryImportService, providedIn: "root" });
    return RedspotInventoryImportService;
}());
export { RedspotInventoryImportService };
