import { Injectable } from '@angular/core';
import { IAccessTokenResponse } from '../interfaces/i-access-token-response';
import { CONSTANTS } from '../helpers/constants';

@Injectable({
  providedIn: 'root'
})
export class AccessTokenService {
  currentTokenData: IAccessTokenResponse;
  constructor() { }

  storeTokenData(data: IAccessTokenResponse) {
    if (data) {
      data.expiration_date = this.calculateExpiration(data).toString();
      this.currentTokenData = data;
      localStorage.setItem(CONSTANTS.localStorageKeys.ACCESS_TOKEN_KEY, JSON.stringify(data));
    }
  }

  getTokenData(): IAccessTokenResponse {
    let dataString = localStorage.getItem(CONSTANTS.localStorageKeys.ACCESS_TOKEN_KEY);
    if (dataString) {
      let data = JSON.parse(dataString);
      this.currentTokenData = data;
      return data;
    } else {
      return { message: CONSTANTS.messages.NO_ACCESS_TOKEN };
    }
  }

  clearTokenData() {
    this.currentTokenData = null;
    localStorage.removeItem(CONSTANTS.localStorageKeys.ACCESS_TOKEN_KEY);
  }

  calculateExpiration(tokenData: IAccessTokenResponse) {
    let duration = tokenData.expires_in;
    let now = new Date();
    now.setUTCSeconds(now.getUTCSeconds() + duration);
    return now;
  }

  isExpired(tokenData: IAccessTokenResponse): boolean {
    let expired = false;
    let now = new Date();
    if (tokenData) {
      let expirationDateString = tokenData.expiration_date;
      let expirationDate = new Date(expirationDateString);
      expired = now.getTime() > expirationDate.getTime()
    } else {
      tokenData = this.getTokenData();
      let expirationDateString = tokenData.expiration_date;
      let expirationDate = new Date(expirationDateString);
      expired = now.getTime() > expirationDate.getTime()
    }
    return expired
  }

  getInMemoryTokenDataIfNotNull(): IAccessTokenResponse {
    if(this.currentTokenData) {
    return this.currentTokenData;
    } else {
      return this.getTokenData();
    }
  }

  storeTokenDataIfMissing(data: IAccessTokenResponse) { 
    let dataString = localStorage.getItem(CONSTANTS.localStorageKeys.ACCESS_TOKEN_KEY);
    if(!dataString) {
      this.storeTokenData(data);
    }
  }
}
