<div class="wrapper">


<div class="mat-elevation-z8 data-table">
  <table mat-table #table [dataSource]="dataSource" matSort aria-label="Elements">
    <!-- Id Column -->
    <ng-container matColumnDef="icons">
      <th mat-header-cell *matHeaderCellDef ></th>
      <td mat-cell *matCellDef="let row" (click)="pickFile(row)">

        <mat-icon *ngIf="row.icon" mat-list-icon>{{row.icon}}</mat-icon>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="filename">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" (click)="pickFile(row)">{{row.attachment_file_name}}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" (click)="deleteAttachment(row)">
        <mat-icon class="delete-icon" mat-list-icon>delete</mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'selected-file-item': row.selected === true, 'file-item': row.selected === false}"></tr>
  </table>

  <mat-paginator #paginator
    [hidden]="true"
    [length]="dataSource.data.length"
    [pageIndex]="0"
    [pageSize]="dataSource.data.length"
    [pageSizeOptions]="[10, 15]">
  </mat-paginator>
</div>
</div>