<div *ngIf="loading" class="spinner">
  <mat-spinner diameter="50" color="primary">
  </mat-spinner>
</div>
<div class="stat-container" [ngClass]="{'disabled': loading }">
  <div class="row stat-cards">

    <!-- site -->
    <div class="col-md-4">
      <mat-card class="stat-card" *ngIf="agencyDashBoardData.siteStats">
        <mat-card-title><span>Site Progress</span></mat-card-title>
        <mat-card-content>
          <div class="progress-bar">
            <section>
              <mat-progress-bar class="example-margin" [color]="accent" [mode]="determinate" [value]="agencyDashBoardData.siteStats.fraction"
                [bufferValue]="bufferValue">
              </mat-progress-bar>
            </section>
          </div>
          <mat-chip-list>
            <mat-chip class="progress-expected" selected>{{agencyDashBoardData.siteStats.planned}} {{agencyDashBoardData.siteStats.planned > 1? 'SITES': 'SITE'}} PLANNED</mat-chip>
            <mat-chip class="in-progress" selected>{{agencyDashBoardData.siteStats.progress}} {{agencyDashBoardData.siteStats.progress > 1? 'SITES': 'SITE'}} IN-PROGRESS</mat-chip>
            <mat-chip class="progress-counted" selected>
              {{agencyDashBoardData.siteStats.complete}} {{agencyDashBoardData.siteStats.complete > 1? 'SITES': 'SITE'}} COMPLETED
            </mat-chip>
            <mat-chip class="progress-complete" selected>
              {{agencyDashBoardData.siteStats.fraction}}% COMPLETE
            </mat-chip>
          </mat-chip-list>
          <button mat-button></button>

        </mat-card-content>
      </mat-card>
    </div>

    <!--Kit Stats-->
    <div class="col-md-4">
      <mat-card class="stat-card" *ngIf="agencyDashBoardData.kitStats">
        <mat-card-title><span>Kit Progress</span></mat-card-title>
        <mat-card-content>
          <div class="progress-bar">
            <section>
              <mat-progress-bar class="example-margin" [mode]="determinate" [value]="agencyDashBoardData.kitStats.fraction" [bufferValue]="bufferValue">
              </mat-progress-bar>
            </section>
          </div>
          <mat-chip-list>
            <mat-chip class="progress-expected clickable" (click)="viewKits('Planned')" selected>{{agencyDashBoardData.kitStats.planned}} {{agencyDashBoardData.kitStats.planned > 1? 'KITS': KIT}} PLANNED</mat-chip>
            <mat-chip class="in-progress clickable" (click)="viewKits('In-Progress')" selected>{{agencyDashBoardData.kitStats.progress}}
              {{agencyDashBoardData.kitStats.progress > 1? 'KITS': 'KIT'}} IN-PROGRESS</mat-chip>
            <mat-chip class="progress-counted clickable" (click)="viewKits('Needs Verification')" selected>{{agencyDashBoardData.kitStats.complete}}
                {{agencyDashBoardData.kitStats.complete > 1? 'KITS NEED VERIFICATION': 'KIT NEEDS VERIFICATION'  }}</mat-chip>
            <mat-chip class="progress-counted clickable" (click)="viewKits('Verified')" selected>{{agencyDashBoardData.kitStats.verified}}
              {{agencyDashBoardData.kitStats.verified > 1? 'KITS': 'KIT'}} VERIFIED</mat-chip>
            <mat-chip class="progress-complete" selected>
              {{agencyDashBoardData.kitStats.fraction}}% COMPLETE
            </mat-chip>
          </mat-chip-list>
          
        </mat-card-content>
      </mat-card>
    </div>
    <!-- Warehouse Progress-->
    <div class="col-md-4">
      <mat-card class="stat-card" *ngIf="agencyDashBoardData.warehouseStats">
        <mat-card-title>Warehouse Progress</mat-card-title>
        <mat-card-content>
            <div class="progress-bar">
                <section class="example-section">
                  <mat-progress-bar class="example-margin" [color]="accent" [mode]="determinate" [value]="agencyDashBoardData.warehouseStats.fraction"
                    [bufferValue]="bufferValue">
                  </mat-progress-bar>
                </section>
              </div>
          <mat-chip-list>
            <mat-chip class="progress-expected" selected>{{agencyDashBoardData.warehouseStats.planned}} {{agencyDashBoardData.warehouseStats.planned > 1? 'WAREHOUSES': 'WAREHOUSE'}} PLANNED</mat-chip>
            <mat-chip class="in-progress" selected>{{agencyDashBoardData.warehouseStats.progress}} {{agencyDashBoardData.warehouseStats.progress > 1? 'WAREHOUSES': 'WAREHOUSE'}} IN-PROGRESS</mat-chip>
            <mat-chip class="progress-counted" selected>
              {{agencyDashBoardData.warehouseStats.complete}} {{agencyDashBoardData.warehouseStats.complete > 1? 'WAREHOUSES': 'WAREHOUSE'}} COMPLETED
            </mat-chip>
            <mat-chip class="progress-complete" selected>
              {{agencyDashBoardData.warehouseStats.fraction}}% COMPLETE
            </mat-chip>
          </mat-chip-list>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

</div>
