import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AudioPlayerService {
  audioSettingChangeEvent: EventEmitter<any>;
  setting: string;
  settingsKey = 'currentCountSheetAlertSetting';
  constructor() {
    this.setting = localStorage.getItem(this.settingsKey);
  }

  changeAudioSetting(setting: string) {
    this.setting = setting;
    localStorage.setItem(this.settingsKey, this.setting);
  }
}
