import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { KitInstanceService } from 'src/app/services/kit-instance.service';
import { MatSnackBar } from '@angular/material';
import { ISnackBarConfig } from '../../../interfaces/isnack-bar-config';
import { MaterialSnackbarComponent } from '../../material-snackbar/material-snackbar.component';
import { Assets } from 'src/app/helpers/assets';

@Component({
  selector: 'app-barcode-kit-uuid-format',
  templateUrl: './barcode-kit-uuid-format.component.html',
  styleUrls: ['./barcode-kit-uuid-format.component.css']
})
export class BarcodeKitUuidFormatComponent implements OnInit, OnDestroy {

  UUID_REGEX = /\b(rkl:[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})\b/;
  @Output() itemNotFound = new EventEmitter<any>();

  snackBarConfig: ISnackBarConfig = {
    message: "",
    duration: 3000,
    success: false,
    snackBarClass: ""
  }

  constructor(
    private kitInstanceService: KitInstanceService,
    public snackBar: MatSnackBar
  ) { }
  
  match(value) {
    return this.UUID_REGEX.test(value)
  }

  parse(value, callback) {
    this.kitInstanceService.getKitInstanceByUUID(value).subscribe({
      next: kitInstances => {
        if (kitInstances.length == 0) {
          this.playErrorSound();
          this.itemNotFound.emit();
          this.snackBarConfig.message = 'Matching Item could not be found';
          this.snackBarConfig.success = false;
          this.openSnackBar();
          callback({
            "reset": true
          })
        }
        else {
          callback({
            "reference": kitInstances[0].item.reference,
            "lot": kitInstances[0].lot_number,
            "done": true
          });
        };
      },
      error: err => {
        callback({
          "reset": true
        });
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  playErrorSound() {
    let audio = new Audio();
    audio.src = Assets.sounds.scanError;
    audio.play();
  }

  openSnackBar() {
    this.snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: this.snackBarConfig,
      duration: this.snackBarConfig.duration
    })
  }
}
