<div mat-dialog-title>
  <div class="row">
    <div class="col-md-12 text-center">
      <h1> <span>
          <mat-icon class="warning-icon">warning</mat-icon>
        </span> Warning</h1>
    </div>
  </div>
</div>
<mat-dialog-content>
  <div class="row message-container">
    <p class="message">{{message}}</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button [mat-dialog-close]="true">OK</button>
</mat-dialog-actions>
