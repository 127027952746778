import { Component, OnInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { ConsignmentAccountDataService } from 'src/app/services/consignment-account-data.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { IDialogData } from 'src/app/interfaces/idialog-data';
import { AuditService } from 'src/app/services/audit.service';
import { ExportToExcelService } from 'src/app/services/export-to-excel.service';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { IAuditDetail } from 'src/app/interfaces/iaudit-detail';
import { ISnackBarConfig } from 'src/app/interfaces/isnack-bar-config';
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';

@Component({
  selector: 'app-consignment-account-data-import',
  templateUrl: './consignment-account-data-import.component.html',
  styleUrls: ['./consignment-account-data-import.component.css']
})
export class ConsignmentAccountDataImportComponent implements OnInit, OnDestroy {
  @ViewChild(SnackbarComponent) snack: SnackbarComponent;

  selectedFile = null;
  pickedFile: any = null;
  loading = false;
  audit: IAuditDetail = {
    location_name : '',
    id: 0,
    warehouse_id: 0,
    distributorship: {},
    status: ''
  };
  snackBarConfig: ISnackBarConfig = {
    message : '',
    duration: 3000,
    success: false,
    snackBarClass : ''
  };

  constructor(
    private consignmentAccountDataImportService: ConsignmentAccountDataService,
    public dialogRef: MatDialogRef<ConsignmentAccountDataImportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData,
    private auditService: AuditService,
    public snackBar: MatSnackBar,
    private exportToExcelService: ExportToExcelService
  ) { }

  ngOnDestroy(): void {
    this.snack = null;
  }

  ngOnInit() {
    this.loading = true;
    this.auditService.getAuditById(this.data.audit_id).subscribe(data => {
      this.audit = data;
      this.loading = false;
    });
  }

  openSnackBar() {
    this.snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: this.snackBarConfig,
      duration: this.snackBarConfig.duration
    });
  }


  onUpload() {
    const fd = new FormData();
    fd.append('audit_id', this.data.audit_id.toString());
    fd.append('file', this.selectedFile, this.selectedFile.name);
    this.dialogRef.disableClose = true;
    this.loading = true;

    this.consignmentAccountDataImportService.upload(fd).subscribe(data => {
      this.selectedFile = null;
      this.pickedFile = null;
      this.snackBarConfig.message = 'File Uploaded Successfully';
      this.snackBarConfig.success = true;
      this.openSnackBar();
      this.loading = false;
      setTimeout(() => {
        this.dialogRef.close(data);
      }, 2000);
    }, data => {
      this.loading = false;
      this.snackBarConfig.message = ((data || {}).error || {}).message || 'Unexpected Error: please try again.';
      this.snackBarConfig.success = false;
      this.openSnackBar();
    });
  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  templateFrontEnd() {
    this.loading = true;
    this.exportToExcelService.exportAsExcelFile([{'reference': '', 'lot': '', qty: '', 'warehouse': '', 'kit reference': '', 'kit lot': '', 'Arthrex Ship to #': ''}], 'consignment_account_import', {});
    this.loading = false;
  }
}
