import { Component, OnInit, Input } from '@angular/core';
import { IAgencyStatData } from '../../interfaces/i-stat-data';
import { AuditService } from '../../services/audit.service';
import { Router } from '@angular/router';
import { AuditDashboardHelper } from 'src/app/helpers/audit-dashboard-helper';

@Component({
  selector: 'app-audit-dashboard-stats',
  templateUrl: './audit-dashboard-stats.component.html',
  styleUrls: ['./audit-dashboard-stats.component.css']
})
export class AuditDashboardStatsComponent implements OnInit {
  // local variables
  @Input() auditId: number;
  agencyDashBoardData: IAgencyStatData = {};
  percentColor: string;
  loading: boolean;
  auditDashBoardHelper: AuditDashboardHelper;

  constructor(private _auditService: AuditService,  private _router: Router) {
    this.auditDashBoardHelper = new AuditDashboardHelper();
  }

  ngOnInit() {
    this.loading = true;
    this._auditService.getStats(this.auditId).subscribe(data => {
      this.agencyDashBoardData = this.auditDashBoardHelper.parseAgencyDashboardData(data);
      this.loading = false;
      this.auditDashBoardHelper.saveAgencyDashbordDataLocally(this.agencyDashBoardData);
    });
  }

  viewKits(status: string) {
    this._router.navigate([this.auditId, 'countsheets', -1], { queryParams: { status: status, orderType: 'consigned' }});
  }
}
