import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { IAuditItem } from '../interfaces/iaudit';
import { urls } from '../helpers/urls';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuditDatabaseService } from '../services/audit-database.service';


@Injectable({
  providedIn: 'root'
})
export class AuditService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private _auditDatabaseService: AuditDatabaseService
  ) { }

  getAuditsAndPopulateOfflineData() {
    const that = this;
    let promise = new Promise((resolve, reject) => {
      that.getAudits().subscribe(data => {
        that._auditDatabaseService.clear().then(_ => {
          that._auditDatabaseService.bulkAdd(data).then(_ => {
            resolve(data);
          });
        });
      });
    });

    return promise;
  }

  getAudits() {
    const path = environment.serverPath + urls.getAudits + '.json';
    return this.http.get<IAuditItem[]>(path);
  }

  getAuditVersion() {
    const path = environment.serverPath + urls.getAuditVersion;
    return this.http.get<any>(path);
  }

  getAuditById(id: string | number) {
    const url = environment.serverPath + urls.getAuditById;
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const params = new HttpParams().set('id', id.toString());

    return this.http.get<any>(
      url,
      {
        headers: headers,
        params: params
      }).pipe(
        tap(audit => {
          if (audit['redspot_inventory_imported_date']) {
            const currentStamp = new Date(audit['redspot_inventory_imported_date']).getTime();
            const previousStamp = localStorage.getItem(`audit[${audit['id']}]redspot_inventory`);
            if (previousStamp && currentStamp.toString() !== previousStamp.toString()) {
              window.caches.keys().then(function (cacheNames) {
                const match = cacheNames.find(cn => cn.includes('Inventory'));
                if (match) {
                  caches.open(match).then(function (cache) {
                    cache.delete(`${environment.serverPath}${urls.redspotInventoryImport.replace(':audit_id', audit['id'])}`, { ignoreSearch: true });
                  });
                }
              });
            }
            localStorage.setItem(`audit[${audit['id']}]redspot_inventory`, currentStamp.toString());
          }
          if (audit['sap_inventory_imported_date']) {
            const currentStamp = new Date(audit['sap_inventory_imported_date']).getTime();
            const previousStamp = localStorage.getItem(`audit[${audit['id']}]sap_inventory`);
            if (previousStamp && currentStamp.toString() !== previousStamp.toString()) {
              window.caches.keys().then(function (cacheNames) {
                const match = cacheNames.find(cn => cn.includes('Inventory'));
                if (match) {
                  caches.open(match).then(function (cache) {
                    cache.delete(`${environment.serverPath}${urls.consignmentInvImport.replace(':audit_id', audit['id'])}`, { ignoreSearch: true });
                  });
                }
              });
            }
            localStorage.setItem(`audit[${audit['id']}]sap_inventory`, currentStamp.toString());
          }
          if (audit['exclusion_last_changed_date']) {
            const currentStamp = new Date(audit['exclusion_last_changed_date']).getTime();
            const previousStamp = localStorage.getItem(`audit[${audit['id']}]exclusion`);
            if (previousStamp && currentStamp.toString() !== previousStamp.toString()) {
              window.caches.keys().then(function (cacheNames) {
                const match = cacheNames.find(cn => cn.includes('LongTermCountSheets'));
                if (match) {
                  caches.open(match).then(function (cache) {
                    cache.delete(`${environment.serverPath}${urls.exclusion.replace(':audit_id', audit['id'])}`, { ignoreSearch: true });
                  });
                }
              });
            }
            localStorage.setItem(`audit[${audit['id']}]exclusion`, currentStamp.toString());
          }
        })
      );
  }

  createAudit(newAudit) {
    const params = new HttpParams()
      .set('audit[location_name]', newAudit.location_name)
      .set('audit[warehouse_id]', newAudit.warehouse_id)
      .set('audit[start_date]', newAudit.start_date)
      .set('audit[end_date]', newAudit.end_date);
    return this.http.post<any>(`${environment.serverPath}${urls.audit}?${params.toString()}`, {});
  }

  getDistributors() {
    return this.http.get<any>(`${environment.serverPath}${urls.distributors}?manufacturer_id=1`);
  }

  getWarehouseItemsResults(auditId, warehouseId) {
    return this.http.get<any>(`${environment.serverPath}${urls.getWarehouseItemsResults}?audit_id=${auditId}&warehouse_id=${warehouseId}`);
  }

  getWarehouses(auditId) {
    return this.http.get<any>(`${environment.serverPath}${urls.warehouses.replace(':audit_id', auditId)}`);
  }

  getImportedWarehouseIds(auditId: number){
    return this.http.get<any>(`${environment.serverPath}${urls.importedWarehouses.replace(':audit_id', auditId.toString())}`);
  }

  getUsedWarehouses(auditId) {
    return this.http.get<any>(`${environment.serverPath}audit_api/audits/used_warehouses?audit_id=${auditId}`);
  }

  getItemPackages(auditId) {
    return this.http.get<any>(`${environment.serverPath}${urls.itemPackages}?audit_id=${auditId}`);
  }

  getStats(auditId: number) {
    return this.http.get<any>(`${environment.serverPath}${urls.getAudits}/${auditId}/stats`);
  }

  updateAudit(updatedAudit) {
    const params = new HttpParams()
      .set('audit[location_name]', updatedAudit.location_name)
      .set('audit[start_date]', updatedAudit.start_date)
      .set('audit[status]', updatedAudit.status)
      .set('audit[end_date]', updatedAudit.end_date);
    return this.http.put<any>(`${environment.serverPath}${urls.audit}/${updatedAudit.id}?${params.toString()}`, {});
  }

  archiveAudit(audit) {
    return this.http.delete(`${environment.serverPath}${urls.audit}/${audit.id}?`);
  }
  completeAudit(auditId) {
    return this.http.put<any>(`${environment.serverPath}${urls.audit}/${auditId}/complete`, {});
  }

  getCountSheetStatusses() {
    const path = environment.serverPath + urls.getCountSheetStatusses;
    return this.http.get<any>(path);
  }

  emailOutputFile(auditId, action, emailAddress) {
    return this.http.put<any>(`${environment.serverPath}${urls.audit}/${auditId}/${action}`, {email_address: emailAddress});
  }
}
