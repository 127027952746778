import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { urls } from '../helpers/urls';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuditDatabaseService } from '../services/audit-database.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "./audit-database.service";
var AuditService = /** @class */ (function () {
    function AuditService(http, router, _auditDatabaseService) {
        this.http = http;
        this.router = router;
        this._auditDatabaseService = _auditDatabaseService;
    }
    AuditService.prototype.getAuditsAndPopulateOfflineData = function () {
        var that = this;
        var promise = new Promise(function (resolve, reject) {
            that.getAudits().subscribe(function (data) {
                that._auditDatabaseService.clear().then(function (_) {
                    that._auditDatabaseService.bulkAdd(data).then(function (_) {
                        resolve(data);
                    });
                });
            });
        });
        return promise;
    };
    AuditService.prototype.getAudits = function () {
        var path = environment.serverPath + urls.getAudits + '.json';
        return this.http.get(path);
    };
    AuditService.prototype.getAuditVersion = function () {
        var path = environment.serverPath + urls.getAuditVersion;
        return this.http.get(path);
    };
    AuditService.prototype.getAuditById = function (id) {
        var url = environment.serverPath + urls.getAuditById;
        var headers = new HttpHeaders().append('Content-Type', 'application/json');
        var params = new HttpParams().set('id', id.toString());
        return this.http.get(url, {
            headers: headers,
            params: params
        }).pipe(tap(function (audit) {
            if (audit['redspot_inventory_imported_date']) {
                var currentStamp = new Date(audit['redspot_inventory_imported_date']).getTime();
                var previousStamp = localStorage.getItem("audit[" + audit['id'] + "]redspot_inventory");
                if (previousStamp && currentStamp.toString() !== previousStamp.toString()) {
                    window.caches.keys().then(function (cacheNames) {
                        var match = cacheNames.find(function (cn) { return cn.includes('Inventory'); });
                        if (match) {
                            caches.open(match).then(function (cache) {
                                cache.delete("" + environment.serverPath + urls.redspotInventoryImport.replace(':audit_id', audit['id']), { ignoreSearch: true });
                            });
                        }
                    });
                }
                localStorage.setItem("audit[" + audit['id'] + "]redspot_inventory", currentStamp.toString());
            }
            if (audit['sap_inventory_imported_date']) {
                var currentStamp = new Date(audit['sap_inventory_imported_date']).getTime();
                var previousStamp = localStorage.getItem("audit[" + audit['id'] + "]sap_inventory");
                if (previousStamp && currentStamp.toString() !== previousStamp.toString()) {
                    window.caches.keys().then(function (cacheNames) {
                        var match = cacheNames.find(function (cn) { return cn.includes('Inventory'); });
                        if (match) {
                            caches.open(match).then(function (cache) {
                                cache.delete("" + environment.serverPath + urls.consignmentInvImport.replace(':audit_id', audit['id']), { ignoreSearch: true });
                            });
                        }
                    });
                }
                localStorage.setItem("audit[" + audit['id'] + "]sap_inventory", currentStamp.toString());
            }
            if (audit['exclusion_last_changed_date']) {
                var currentStamp = new Date(audit['exclusion_last_changed_date']).getTime();
                var previousStamp = localStorage.getItem("audit[" + audit['id'] + "]exclusion");
                if (previousStamp && currentStamp.toString() !== previousStamp.toString()) {
                    window.caches.keys().then(function (cacheNames) {
                        var match = cacheNames.find(function (cn) { return cn.includes('LongTermCountSheets'); });
                        if (match) {
                            caches.open(match).then(function (cache) {
                                cache.delete("" + environment.serverPath + urls.exclusion.replace(':audit_id', audit['id']), { ignoreSearch: true });
                            });
                        }
                    });
                }
                localStorage.setItem("audit[" + audit['id'] + "]exclusion", currentStamp.toString());
            }
        }));
    };
    AuditService.prototype.createAudit = function (newAudit) {
        var params = new HttpParams()
            .set('audit[location_name]', newAudit.location_name)
            .set('audit[warehouse_id]', newAudit.warehouse_id)
            .set('audit[start_date]', newAudit.start_date)
            .set('audit[end_date]', newAudit.end_date);
        return this.http.post("" + environment.serverPath + urls.audit + "?" + params.toString(), {});
    };
    AuditService.prototype.getDistributors = function () {
        return this.http.get("" + environment.serverPath + urls.distributors + "?manufacturer_id=1");
    };
    AuditService.prototype.getWarehouseItemsResults = function (auditId, warehouseId) {
        return this.http.get("" + environment.serverPath + urls.getWarehouseItemsResults + "?audit_id=" + auditId + "&warehouse_id=" + warehouseId);
    };
    AuditService.prototype.getWarehouses = function (auditId) {
        return this.http.get("" + environment.serverPath + urls.warehouses.replace(':audit_id', auditId));
    };
    AuditService.prototype.getImportedWarehouseIds = function (auditId) {
        return this.http.get("" + environment.serverPath + urls.importedWarehouses.replace(':audit_id', auditId.toString()));
    };
    AuditService.prototype.getUsedWarehouses = function (auditId) {
        return this.http.get(environment.serverPath + "audit_api/audits/used_warehouses?audit_id=" + auditId);
    };
    AuditService.prototype.getItemPackages = function (auditId) {
        return this.http.get("" + environment.serverPath + urls.itemPackages + "?audit_id=" + auditId);
    };
    AuditService.prototype.getStats = function (auditId) {
        return this.http.get("" + environment.serverPath + urls.getAudits + "/" + auditId + "/stats");
    };
    AuditService.prototype.updateAudit = function (updatedAudit) {
        var params = new HttpParams()
            .set('audit[location_name]', updatedAudit.location_name)
            .set('audit[start_date]', updatedAudit.start_date)
            .set('audit[status]', updatedAudit.status)
            .set('audit[end_date]', updatedAudit.end_date);
        return this.http.put("" + environment.serverPath + urls.audit + "/" + updatedAudit.id + "?" + params.toString(), {});
    };
    AuditService.prototype.archiveAudit = function (audit) {
        return this.http.delete("" + environment.serverPath + urls.audit + "/" + audit.id + "?");
    };
    AuditService.prototype.completeAudit = function (auditId) {
        return this.http.put("" + environment.serverPath + urls.audit + "/" + auditId + "/complete", {});
    };
    AuditService.prototype.getCountSheetStatusses = function () {
        var path = environment.serverPath + urls.getCountSheetStatusses;
        return this.http.get(path);
    };
    AuditService.prototype.emailOutputFile = function (auditId, action, emailAddress) {
        return this.http.put("" + environment.serverPath + urls.audit + "/" + auditId + "/" + action, { email_address: emailAddress });
    };
    AuditService.ngInjectableDef = i0.defineInjectable({ factory: function AuditService_Factory() { return new AuditService(i0.inject(i1.HttpClient), i0.inject(i2.Router), i0.inject(i3.AuditDatabaseService)); }, token: AuditService, providedIn: "root" });
    return AuditService;
}());
export { AuditService };
