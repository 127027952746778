var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { urls } from '../helpers/urls';
import { UUID } from 'angular2-uuid';
import { AccessTokenService } from './access-token.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./access-token.service";
var OktaConfigService = /** @class */ (function () {
    function OktaConfigService(_http, _accesstokenService) {
        this._http = _http;
        this._accesstokenService = _accesstokenService;
        this.alphabet = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        this.configDataKey = 'OKTA_CONFIG_DATA';
        this.verifierKey = 'VERIFIER';
        this.isInternalKey = 'LOGIN_IS_INTERNAL';
        if (!this.configData) {
            this.getAndStoreConfigData();
        }
    }
    OktaConfigService.prototype.getConfigData = function () {
        var path = "" + environment.serverPath + urls.oktaConfigData;
        return this._http.get(path);
    };
    OktaConfigService.prototype.getAndStoreConfigData = function () {
        var _this = this;
        this._http.get("" + environment.serverPath + urls.oktaConfigData).subscribe(function (data) {
            _this.configData = data;
            _this.saveToLocal(data);
        });
    };
    OktaConfigService.prototype.getFromLocal = function () {
        if (this.configData) {
            return this.configData;
        }
        else {
            var configString = localStorage.getItem(this.configDataKey);
            if (configString) {
                this.configData = JSON.parse(configString);
            }
            return this.configData;
        }
    };
    OktaConfigService.prototype.saveToLocal = function (data) {
        if (data) {
            this.configData = data;
            localStorage.setItem(this.configDataKey, JSON.stringify(data));
        }
    };
    OktaConfigService.prototype.makeExternalLoginUri = function (state) {
        return __awaiter(this, void 0, void 0, function () {
            var verifier, challenge, scope, login_form_url;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        state = state ? state : document.location.origin;
                        verifier = this.generateRandomString();
                        return [4 /*yield*/, this.pkceChallengeFromVerifier(verifier)];
                    case 1:
                        challenge = _a.sent();
                        if (this.configData === null || this.configData === undefined) {
                            this.configData = this.getFromLocal();
                        }
                        scope = this.configData.audit_scope;
                        if (this.configData.refresh_token_enabled) {
                            scope = scope + ' offline_access';
                        }
                        login_form_url = "" + this.configData.non_arthrex_employee_base_url + this.configData.get_id_token + "?client_id=" + encodeURIComponent(this.configData.audit_external_client_id) + "&redirect_uri=" + encodeURIComponent(this.configData.audit_login_redirect_uri) + "&response_type=" + encodeURIComponent(this.configData.response_type) + "&code_challenge_method=" + encodeURIComponent(this.configData.audit_code_challenge_method) + "&code_challenge=" + encodeURIComponent(challenge) + "&scope=" + encodeURIComponent(scope) + "&state=" + encodeURIComponent(state);
                        this.storeVerifier(verifier);
                        return [2 /*return*/, login_form_url];
                }
            });
        });
    };
    OktaConfigService.prototype.storeVerifier = function (verifier) {
        localStorage.setItem(this.verifierKey, verifier);
    };
    OktaConfigService.prototype.getVerifier = function () {
        return localStorage.getItem(this.verifierKey);
    };
    OktaConfigService.prototype.makeInternalLoginUri = function (state) {
        return __awaiter(this, void 0, void 0, function () {
            var verifier, challenge, scope, login_form_url;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        state = state ? state : document.location.origin;
                        verifier = this.generateRandomString();
                        return [4 /*yield*/, this.pkceChallengeFromVerifier(verifier)];
                    case 1:
                        challenge = _a.sent();
                        if (this.configData === null || this.configData === undefined) {
                            this.configData = this.getFromLocal();
                        }
                        scope = this.configData.audit_scope;
                        if (this.configData.refresh_token_enabled) {
                            scope = scope + ' offline_access';
                        }
                        login_form_url = "" + this.configData.arthrex_employee_base_url + this.configData.get_id_token + "?client_id=" + encodeURIComponent(this.configData.audit_internal_client_id) + "&redirect_uri=" + encodeURIComponent(this.configData.audit_login_redirect_uri) + "&response_type=" + encodeURIComponent(this.configData.response_type) + "&code_challenge_method=" + encodeURIComponent(this.configData.audit_code_challenge_method) + "&code_challenge=" + encodeURIComponent(challenge) + "&scope=" + encodeURIComponent(scope) + "&state=" + encodeURIComponent(state);
                        this.storeVerifier(verifier);
                        return [2 /*return*/, login_form_url];
                }
            });
        });
    };
    OktaConfigService.prototype.generateNonce = function () {
        return UUID.UUID();
    };
    OktaConfigService.prototype.generateRandomString = function () {
        var array = new Uint32Array(28);
        window.crypto.getRandomValues(array);
        return Array.from(array, function (dec) { return ('0' + dec.toString(16)).slice(-2); }).join('');
    };
    // Calculate the SHA256 hash of the input text. 
    // Returns a promise that resolves to an ArrayBuffer
    OktaConfigService.prototype.sha256 = function (plain) {
        var encoder = new window.TextEncoder();
        var data = encoder.encode(plain);
        return window.crypto.subtle.digest('SHA-256', data);
    };
    // Base64-urlencodes the input string
    OktaConfigService.prototype.base64urlencode = function (str) {
        // Convert the ArrayBuffer to string using Uint8 array to conver to what btoa accepts.
        // btoa accepts chars only within ascii 0-255 and base64 encodes them.
        // Then convert the base64 encoded to base64url encoded
        //   (replace + with -, replace / with _, trim trailing =)
        return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
            .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    };
    // Return the base64-urlencoded sha256 hash for the PKCE challenge
    OktaConfigService.prototype.pkceChallengeFromVerifier = function (v) {
        return __awaiter(this, void 0, void 0, function () {
            var hashed;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.sha256(v)];
                    case 1:
                        hashed = _a.sent();
                        return [2 /*return*/, this.base64urlencode(hashed)];
                }
            });
        });
    };
    OktaConfigService.prototype.makeInternalLogoutUrl = function () {
        var tokenData = this._accesstokenService.getTokenData();
        if (!this.configData) {
            this.configData = this.getFromLocal();
        }
        var logout_url = "" + this.configData.arthrex_employee_base_url + this.configData.logout_endpoint + "?id_token_hint=" + tokenData.id_token + "&post_logout_redirect_uri=" + this.configData.audit_logout_redirect_uri;
        return logout_url;
    };
    OktaConfigService.prototype.makeExternalLogoutUrl = function () {
        var tokenData = this._accesstokenService.getTokenData();
        if (!this.configData) {
            this.configData = this.getFromLocal();
        }
        var logout_url = "" + this.configData.non_arthrex_employee_base_url + this.configData.logout_endpoint + "?id_token_hint=" + tokenData.id_token + "&post_logout_redirect_uri=" + this.configData.audit_logout_redirect_uri;
        return logout_url;
    };
    OktaConfigService.prototype.revokeToken = function () {
        var tokenData = this._accesstokenService.getTokenData();
        var path = "" + environment.serverPath + urls.revokeInternalToken;
        var body = { access_token: tokenData.access_token };
        return this._http.post(path, body);
    };
    OktaConfigService.ngInjectableDef = i0.defineInjectable({ factory: function OktaConfigService_Factory() { return new OktaConfigService(i0.inject(i1.HttpClient), i0.inject(i2.AccessTokenService)); }, token: OktaConfigService, providedIn: "root" });
    return OktaConfigService;
}());
export { OktaConfigService };
