import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { AuditKitInstanceService } from '../../services/audit-kit-instance.service';
import { MatPaginator, MatSort, MatDialog, MatSnackBar } from '@angular/material';
import { AuditKitInstancesListDataSource } from './audit-kit-instances-list-datasource';
import { CountSheetService } from '../../services/count-sheet.service';
import { ISnackBarConfig } from '../../interfaces/isnack-bar-config';
import { ICountSheetData } from '../../interfaces/icount-sheet-data';
import { Observer, Subscription, of } from 'rxjs';
import { OnlineService } from './../../services/online.service';
import { Router, ActivatedRoute } from '../../../../node_modules/@angular/router';
import { CountSheetHelper } from '../../helpers/countsheet-helper';
import { ITableFilter } from '../../interfaces/itable-filter';
import { KitInstanceHelper } from '../../helpers/kit-instance-helper';
import { IkitInstance } from '../../interfaces/ikit-instance';
import { IcountMetadata } from '../../interfaces/icount-metadata';
import { UserDataHelper } from '../../helpers/user-data-helper';
import { IuserData } from '../../interfaces/iuser-data';
import * as moment from 'moment';
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';
import { ExportToExcelService } from 'src/app/services/export-to-excel.service';
import { RedspotInventoryImportService } from 'src/app/services/redspot-inventory-import.service';
import { ItemSettingService } from 'src/app/services/item-setting.service';
import { ItemCombinationService } from 'src/app/services/item-combination.service';
import { KitDefinitionItemService } from 'src/app/services/kit-definition-item.service';
import { map } from 'rxjs/operators';
import { zip } from 'rxjs';
import { ConsignmentInventoryImportService } from 'src/app/services/consignment-inventory-import.service';
import { ExclusionService } from 'src/app/services/exclusion.service';
import { IPrintOptions } from 'src/app/interfaces/i-print-options';
import { PrintHelper } from '../../helpers/print-helper';
import { CountSheetItemService } from '../../services/count-sheet-item.service';
import { CountSheetItemHelper } from 'src/app/helpers/countsheet-item-helper';
import { LotPoolManagedItemService } from '../../services/lot-pool-managed-item.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { DatabaseService } from 'src/app/services/database.service';
import { CountSheetDatabaseService } from 'src/app/services/count-sheet-database.service';
import { CountSheetItemDatabaseService } from 'src/app/services/count-sheet-item-database.service';
import { AuditKitInstanceDatabaseService } from 'src/app/services/audit-kit-instance-database.service';
import { IAuditDetail } from 'src/app/interfaces/iaudit-detail';
import { AuditStatus } from 'src/app/helpers/audit-status';
import { TrackLoadingService } from 'src/app/services/track-loading.service';
import { RecallOracleService } from 'src/app/services/recall-oracle.service';



@Component({
  selector: 'app-kit-instances-list',
  templateUrl: './audit-kit-instances-list.component.html',
  styleUrls: ['./audit-kit-instances-list.component.css']
})
export class AuditKitInstancesListComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() audit: IAuditDetail;
  @Input() siteId;
  @Input() filters: ITableFilter;
  @Input() kittedCountSheets: ICountSheetData[];
  auditId: number;
  permissions: string[];
  currentRow: IkitInstance;
  _dataSource: any;
  _auditKitInstances: IkitInstance[];
  userData: IuserData;
  displayedColumns = ['verify', 'reference', 'description', 'lot_number', 'item_count', 'current_count', 'warehouse', 'site', 'counted_by', 'counted_time', 'status', 'countsheet'];
  viewOnly = false;
  status: string;
  areAnyKitCounted = false;
  itemCombinations: any;
  itemSettings: any;
  redspotQuantities: any;
  lotPoolManagedItems: any;
  kitDefinitionItems: any;
  sapQuantities: any;
  exclusions: any;
  verifyAllChecked = false;

  @Input() location: any;
  countsheets: ICountSheetData[];
  kitCountSheet: ICountSheetData = {
    dbId: 0,
    isSynchronized: 0,
    id: '',
    data: {
      reference: '',
      lot_number: '',
      isKitted: true,
      audit_id: 0,
      audit_location_id: 0,
      location_name: '',
      warehouse_name: '',
      warehouse_id: 0,
      area: null,
      count_sheet_status: 1,
      audit_kit_instance_id: null,
      status_text: '',
      total_items_counted: 0
    },

  };

  rowToCount: IkitInstance;

  snackBarConfig: ISnackBarConfig = {
    message: '',
    duration: 3000,
    success: false,
    snackBarClass: ''
  };

  // helpers
  countSheetHelper: CountSheetHelper = new CountSheetHelper();
  countSheetItemHelper: CountSheetItemHelper = new CountSheetItemHelper();
  kitInstanceHelper: KitInstanceHelper = new KitInstanceHelper();
  userDataHelper: UserDataHelper = new UserDataHelper();
  printHelper: PrintHelper = new PrintHelper();

  // subscription
  onlineSubscription: Subscription;
  reloadSubscription: Subscription;

  // observers
  createCountSheetObserver: Observer<any> = {
    next: data => {
      this._trackLoadingService.stopLoading('audit-kit-instances-list-createCountSheet');
      if (data.result) {
        const that = this;
        const countSheet = that._countSheetDatabaseService.new(data.count_sheet);
        that.currentRow.data.count_sheet = countSheet;
        that._trackLoadingService.startLoading('audit-kit-instances-list-createCountSheet-countSheetDatabase-add','Saving New Count Sheet For Offline');
        that._countSheetDatabaseService.add(countSheet).then(function () {
          that._trackLoadingService.stopLoading('audit-kit-instances-list-createCountSheet-countSheetDatabase-add');
          that.currentRow.data.has_count_sheet = true;
          that.snackBarConfig.message = 'Kit Count Sheet Created';
          that.snackBarConfig.success = true;
          that.openSnackBar();
          that.startCounting(that.currentRow, countSheet);
        });
      } else {
        this.snackBarConfig.message = `Error: ${data.message}`;
        this.snackBarConfig.success = false;
        this.openSnackBar();

        if (data.reason === 'offline') {
          this.snackBarConfig.message = 'Conflicting count sheets created by other Users, reloading';
          this.snackBarConfig.success = false;
          this.openSnackBar();
          this._route.queryParams.subscribe(params => {
            this._router.navigate([this.audit.id, 'countsheets', +this._route.snapshot.paramMap.get('id')], { queryParams: { status: params.status || 'All', selected_tab: 0 } });
          });
        }
      }

    },
    error: () => {
      this._trackLoadingService.stopLoading('audit-kit-instances-list-createCountSheet');
      this.onlineSubscription = this._onlineService.isOnlineSubscription().subscribe(online => {
        if (!online) {
          const that = this;
          this._trackLoadingService.startLoading('audit-kit-instances-list-createCountSheet-IsCountSheetDuplicate','Checking If Count Sheet Is Duplicate');
          this.IsCountSheetDuplicate(this.kitCountSheet).then((isDuplicate) => {
            this._trackLoadingService.stopLoading('audit-kit-instances-list-createCountSheet-IsCountSheetDuplicate');
            if (isDuplicate) {
              this.showSnackBar('Duplicate CountSheet for Kit', false);
              return;
            }
            this.kitCountSheet.dbId = 0;
            this.kitCountSheet.id = '';
            this.kitCountSheet.isSynchronized = 0;
            this.currentRow.data.has_count_sheet = true;
            this._trackLoadingService.startLoading('audit-kit-instances-list-createCountSheet-countSheetDatabase-add','Saving New Count Sheet For Offline');
            this._countSheetDatabaseService.add(this.kitCountSheet).then((clientId) => {
              that._countSheetDatabaseService.get(clientId).then((cs: ICountSheetData) => {
                that._trackLoadingService.stopLoading('audit-kit-instances-list-createCountSheet-countSheetDatabase-add');
                that.countsheets = that.countsheets.concat(cs);
                that.getAuditKitInstances(that.audit.id);
                that.startCounting(that.currentRow, cs);
                that.showSnackBar('Count Sheet Created', true);
              })
            });
          });
        }
      });
      this.onlineSubscription.unsubscribe();
    },
    complete: () => {

    }
  };

  public set dataSource(newDataSource) {
    this.areAnyKitCounted = !!newDataSource.filteredData().find(kit => kit.data.status === 3);
    this._dataSource = newDataSource;
  }

  public get dataSource() {
    return this._dataSource;
  }

  public set auditKitInstances(newAuditKitInstances) {
    //so we don't have to have multiple copies of the same data and trying to keep them all insync
    newAuditKitInstances.forEach((aki) => {
      const countSheet = this.countsheets.find(cs => cs.data.audit_kit_instance_id == aki.dbId);
      if (countSheet) {
        aki.data.count_sheet = countSheet;
      }
    });
    this._auditKitInstances = newAuditKitInstances;
  }

  public get auditKitInstances() {
    return this._auditKitInstances;
  }

  constructor(
    private auditKitInstanceService: AuditKitInstanceService,
    private excelService: ExportToExcelService,
    private countSheetService: CountSheetService,
    public dialog: MatDialog,
    private _onlineService: OnlineService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _countSheetService: CountSheetService,
    public snackBar: MatSnackBar,
    private redspotInventoryImportService: RedspotInventoryImportService,
    private itemSettingService: ItemSettingService,
    private kitDefinitionItemService: KitDefinitionItemService,
    private consignmentInventoryImportService: ConsignmentInventoryImportService,
    private exclusionService: ExclusionService,
    private _countSheetItemService: CountSheetItemService,
    private _lotPoolManagedItemService: LotPoolManagedItemService,
    private _databaseService: DatabaseService,
    private _countSheetDatabaseService: CountSheetDatabaseService,
    private _auditKitInstanceDatabase: AuditKitInstanceDatabaseService,
    private _countSheetItemDatabaseService: CountSheetItemDatabaseService,
    private _trackLoadingService: TrackLoadingService,
    private _recallOracleService: RecallOracleService,
    private itemCombinationService: ItemCombinationService
  ) { }

  getItemCombinations() {
    if (this.itemCombinations) {
      return of(this.itemCombinations);
    }
    return this.itemCombinationService.get();
  }

  getRedspotQuantities(auditId, itemInstanceId) {
    return this.redspotInventoryImportService.getInventory(auditId).pipe(map(data => data.filter(rq => rq.kit_instance_id === itemInstanceId)));
  }

  getLotPoolManagedItems() {
    if (this.lotPoolManagedItems) {
      return of(this.lotPoolManagedItems);
    }
    return this._lotPoolManagedItemService.getLotPoolManagedItems();
  }

  getRootKitDefinitionItems(kitId) {
    return this.kitDefinitionItemService.getKitDefinitionItems().pipe(map(data => data.filter(kdi => kdi.kit_definition_id === kitId)));
  }

  getItemSettingsByAudit(auditId: number) {
    if (this.itemSettings) {
      return of(this.itemSettings);
    }
    return this.itemSettingService.getItemSettingsByAudit(auditId);
  }

  getConsignmentQuantities(auditId) {
    if (this.sapQuantities) {
      return of(this.sapQuantities);
    }
    return this.consignmentInventoryImportService.getInventory(auditId, true);
  }

  getExclusions(auditId) {
    if (this.exclusions) {
      return of(this.exclusions);
    }
    return this.exclusionService.getExclusions(auditId);
  }

  ngOnDestroy(): void {
    if (this.onlineSubscription) {
      this.onlineSubscription.unsubscribe();
    }
    if (this.reloadSubscription) {
      this.reloadSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.userData = this.userDataHelper.getUserData();
    this.auditId = +this._route.snapshot.paramMap.get('audit_id');
    this.permissions = JSON.parse(localStorage.getItem('permissions'));
    this.status = this.filters['status'];

    if (this.audit.status === AuditStatus.COMPLETED) {
      this.viewOnly = true;
      if (parseInt(this.siteId, 10) !== -1) {
        this.getSheets();
      }
    } else {
      if (parseInt(this.siteId, 10) === -1) {
        this.viewOnly = true;
      } else {
        this.viewOnly = this._route.snapshot.paramMap.get('isViewOnly') === 'true';
        if (this.permissions[0] === 'auditor' || this.permissions[0] === 'audit_administrator') {
          this.viewOnly = false;
        } else if (this.permissions[0] === 'audit_viewer') {
          this.viewOnly = true;
        }
      }
      this.getSheets();
    }

    this.getAuditKitInstances(this.audit.id);
  }

  getAuditKitInstances(auditId) {
    const that = this;
    this._trackLoadingService.startLoading('audit-kit-instances-list-getAuditKitInstances', 'loading Kit List');
    this.onlineSubscription = this._onlineService.isOnlineSubscription().subscribe(online => {
      if (online && !this._onlineService.getTestOfflineBool()) {
        this.auditKitInstanceService.getAuditKitInstancesAndPopulateOfflineData(auditId).then((results: any[]) => {
          this.auditKitInstances = results;
          this.dataSource = new AuditKitInstancesListDataSource(this.paginator, this.sort, this.auditKitInstances, this.filters);
          this._trackLoadingService.stopLoading('audit-kit-instances-list-getAuditKitInstances');
        });

      } else {
        this.auditKitInstanceService.getOfflineAuditKitInstances(auditId).then(function (akis: any[]) {
          that.auditKitInstances = akis;
          if (that.auditKitInstances.length === 0) {
            that.auditKitInstanceService.getAuditKitInstancesAndPopulateOfflineData(auditId).then((results: any[]) => {
              that.auditKitInstances = results;
              that.dataSource = new AuditKitInstancesListDataSource(that.paginator, that.sort, that.auditKitInstances, that.filters);
              that._trackLoadingService.stopLoading('audit-kit-instances-list-getAuditKitInstances');
            });
          } else {
            that.dataSource = new AuditKitInstancesListDataSource(that.paginator, that.sort, that.auditKitInstances, that.filters);
            that._trackLoadingService.stopLoading('audit-kit-instances-list-getAuditKitInstances');
          }
        });
      }
    });

    this.onlineSubscription.unsubscribe();
  }

  getSheets() {
    this._trackLoadingService.startLoading('audit-kit-instances-list-getSheets', 'loading Count Sheets for Kit List');
    this.countsheets = this.kittedCountSheets;
    this._trackLoadingService.stopLoading('audit-kit-instances-list-getSheets');
  }

  /**
   * create countsheet online / offline
   * @param row
   */
  createCountSheet(row: IkitInstance) {
    this.currentRow = row;
    this.kitCountSheet.dbId = 0;
    this.kitCountSheet.id = '';
    this.kitCountSheet.data.count_sheet_status = 2;
    this.kitCountSheet.data.created_by = this.userData;
    this.kitCountSheet.data.created_at = moment().format('lll');
    this.kitCountSheet.data.audit_id = row.data.audit_id;
    this.kitCountSheet.data.warehouse_id = row.data.warehouse_id;
    this.kitCountSheet.data.warehouse_name = row.data.warehouse_name;
    this.kitCountSheet.data.lot_number = row.data.item_instance.lot_number;
    this.kitCountSheet.data.reference = row.data.item_instance.reference;
    this.kitCountSheet.data.audit_kit_instance_id = row.dbId;
    this.kitCountSheet.data.audit_location_id = this.siteId;
    this.kitCountSheet.data.location_name = this.location.location_name;
    this.kitCountSheet.data.kit_id = row.data.item_instance.root_item_id;
    this.kitCountSheet.data.show_expected_item_list = !!row.data.item_instance.root_item_id;
    this.kitCountSheet.data.audit_kit_instance = {
      item_instance_id: row.data.item_instance_id
    };

    this._trackLoadingService.startLoading('audit-kit-instances-list-createCountSheet','Creating Count Sheet');
    this.onlineSubscription = this._onlineService.isOnlineSubscription().subscribe(online => {
      if (online && !this._onlineService.getTestOfflineBool()) {
        this.countSheetService.createKitCountSheet(this.kitCountSheet).subscribe(this.createCountSheetObserver);
      } else {
        this._trackLoadingService.stopLoading('audit-kit-instances-list-createCountSheet');
        const that = this;
        this._trackLoadingService.startLoading('audit-kit-instances-list-createCountSheet-IsCountSheetDuplicate','Checking If Count Sheet Is Duplicate');
        this.IsCountSheetDuplicate(this.kitCountSheet).then((isDuplicate) => {
          this._trackLoadingService.stopLoading('audit-kit-instances-list-createCountSheet-IsCountSheetDuplicate');
          if (isDuplicate) {
            this.showSnackBar('Duplicate CountSheet for Kit', false);
            return;
          }
          this.kitCountSheet.dbId = 0;
          this.kitCountSheet.id = '';
          this.kitCountSheet.isSynchronized = 0;
          this.currentRow.data.has_count_sheet = true;
          this._trackLoadingService.startLoading('audit-kit-instances-list-createCountSheet-countSheetDatabase-add','Saving New Count Sheet For Offline');
          this._countSheetDatabaseService.add(this.kitCountSheet).then((clientId) => {
            that._countSheetDatabaseService.get(clientId).then((cs: ICountSheetData) => {
              this._trackLoadingService.stopLoading('audit-kit-instances-list-createCountSheet-countSheetDatabase-add');
              that.countsheets = that.countsheets.concat(cs);
              that.getAuditKitInstances(that.audit.id);
              this.startCounting(that.currentRow, cs);
              this.showSnackBar('Count Sheet Created', true);
            })
          });
        });
      }
    });

    this.onlineSubscription.unsubscribe();

  }

  IsCountSheetDuplicate(countSheet: ICountSheetData) {
    const that = this;
    let promise = new Promise((resolve, reject) => {
      that._countSheetDatabaseService.find(that.auditId, {audit_kit_instance_id: countSheet.data.audit_kit_instance_id, archived: false}).then((matches: any[]) => {
        resolve(matches.length > 1);
      });
    });
    return promise;
  }

  showSnackBar(message: string, success: boolean): void {
    this.snackBarConfig.message = message;
    this.snackBarConfig.success = success;
    this.openSnackBar();
  }

  /**
   * start counting online / offline
   * @param row
   */
  startCounting(row: IkitInstance, countSheet: ICountSheetData) {
    this.rowToCount = row;
    this._databaseService.add('user_actions', {
      audit_id: this.auditId.toString(),
      auditor: `${this.userData.first_name} ${this.userData.last_name}`,
      area: '',
      kit_reference: `${row.data.item_instance.reference}`,
      kit_lot_number: `${row.data.item_instance.lot_number}`,
      action: `Created count sheet`,
      timestamp: `${(new Date()).toUTCString()}`,
      total_items_counted: countSheet.data.total_items_counted,
      site_id: this.siteId,
      warehouse_id: countSheet.data.warehouse_id
    });
    this._router.navigate([this.audit.id, 'kit_instance_count_sheet', countSheet.id, this.viewOnly], { queryParams: { status: this.status } });
    this.onlineSubscription.unsubscribe();
  }

  continueCounting(row: IkitInstance) {
    this.rowToCount = row;

    if (this.permissions[0] === 'auditor' || this.permissions[0] === 'audit_administrator') {
      this._router.navigate([row.data.audit_id, 'kit_instance_count_sheet', row.data.count_sheet.id, this.viewOnly], { queryParams: { status: this.status } });
    } else {
      this._router.navigate([row.data.audit_id, 'kit_instance_count_sheet', row.data.count_sheet.id, true], { queryParams: { status: this.status } });
    }


  }

  goToCompletedCountSheet(row: IkitInstance) {
    if (this.permissions[0] === 'auditor' || this.permissions[0] === 'audit_administrator') {
      this._router.navigate([row.data.audit_id, 'kit_instance_count_sheet', row.data.count_sheet.id, this.viewOnly], { queryParams: { status: this.status } });

    } else {
      this._router.navigate([row.data.audit_id, 'kit_instance_count_sheet', row.data.count_sheet.id, true], { queryParams: { status: this.status } });

    }
  }

  goToVerifiedCountSheet(row: IkitInstance) {
    this._router.navigate([row.data.audit_id, 'kit_instance_count_sheet', row.data.count_sheet.id, this.viewOnly], { queryParams: { status: this.status } });

  }

  applyFilter() {
    this.dataSource = new AuditKitInstancesListDataSource(this.paginator, this.sort, this.auditKitInstances, this.filters);
  }

  checkedAllClicked(event) {
    const verifiedStatus = 4;
    const verifiedMetadata: IcountMetadata = {
      verified_by_id: this.userData.user_id,
      verified_time: moment().toDate(),
      kitted: true
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '30%',
      data: 'Verify all kitted countsheets?',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._trackLoadingService.startLoading('audit-kit-instances-list-checkedAllClicked', 'Verifying All Kitted Count Sheets');
        this.onlineSubscription = this._onlineService.isOnlineSubscription().subscribe(online => {
          if (online && !this._onlineService.getTestOfflineBool()) {
            this._countSheetService.updateCountSheetStatuses(
              this.dataSource.filteredData().filter(kit => kit.data.status === 3).map(kit => kit.data.count_sheet.dbId),
              verifiedStatus,
              verifiedMetadata,
              this.siteId
            ).subscribe(data => {
              data.kits.forEach(k => {
                this._databaseService.add('user_actions', {
                  audit_id: this.auditId.toString(),
                  auditor: `${this.userData.first_name} ${this.userData.last_name}`,
                  timestamp: `${(new Date()).toUTCString()}`,
                  area: '',
                  kit_reference: `${k.item_instance.item.reference}`,
                  kit_lot_number: `${k.item_instance.lot_number}`,
                  action: 'Verified count sheet',
                  total_items_counted: k.count_sheet.total_items_counted,
                  site_id: this.siteId,
                  warehouse_id: k.count_sheet.warehouse_id,
                  user: this.userData
                });
              });

              //update offline data
              const promises = data.kits.map((aki) => {
                return this._auditKitInstanceDatabase.update(this._auditKitInstanceDatabase.new(aki));
              }).concat(data.count_sheets.map((cs) => {
                return this._countSheetDatabaseService.update(this._countSheetDatabaseService.new(cs));
              })).concat(new Promise((r, a) => { r(null); }));
              zip(...promises).subscribe(_ => {
                zip(
                  this._countSheetDatabaseService.find(this.auditId, {not_null_audit_kit_instance_id: true, archived: false}),
                  this._auditKitInstanceDatabase.find(this.auditId, {})
                ).subscribe((data: any[]) => {
                  this.countsheets = data[0];
                  this.auditKitInstances = data[1];
                  this.dataSource = new AuditKitInstancesListDataSource(this.paginator, this.sort, this.auditKitInstances, this.filters);
                  event.source.checked = false;
                  this._trackLoadingService.stopLoading('audit-kit-instances-list-checkedAllClicked');
                });
              })
            });
          } else {
            const promises = this.dataSource.filteredData().filter(kit => kit.data.status === 3).map(k => {
              this._databaseService.add('user_actions', {
                audit_id: this.auditId.toString(),
                auditor: `${this.userData.first_name} ${this.userData.last_name}`,
                timestamp: `${(new Date()).toUTCString()}`,
                area: '',
                kit_reference: `${k.data.item_instance.reference}`,
                kit_lot_number: `${k.data.item_instance.lot_number}`,
                action: 'Verified count sheet',
                total_items_counted: k.data.count_sheet.data.total_items_counted,
                site_id: this.siteId,
                warehouse_id: k.data.count_sheet.warehouse_id
              });
              let countSheet = k.data.count_sheet;
              countSheet.isSynchronized = 0;
              countSheet.data.count_sheet_status = verifiedStatus;
              countSheet.data.verified_by = this.userData;
              countSheet.data.verified_time = moment().format('lll');
              return this._countSheetDatabaseService.update(countSheet);
            });
            zip(
              ...promises.concat(new Promise((r, a) => { r(null); }))
            ).subscribe(_ => {

              zip(
                this._countSheetDatabaseService.find(this.auditId, {not_null_audit_kit_instance_id: true, archived: false}),
                this._auditKitInstanceDatabase.find(this.auditId, {})
              ).subscribe((data: any[]) => {
                this.countsheets = data[0];
                this.auditKitInstances = data[1];
                this.dataSource = new AuditKitInstancesListDataSource(this.paginator, this.sort, this.auditKitInstances, this.filters);
                event.source.checked = false;
                this._trackLoadingService.stopLoading('audit-kit-instances-list-checkedAllClicked');
              });
            })


          }
        });

        this.onlineSubscription.unsubscribe();
      } else {
        this.verifyAllChecked = false;
      }
    });

  }

  checkedClicked(element: IkitInstance) {
    const verifiedStatus = 4;
    const verifiedMetadata: IcountMetadata = {
      verified_by_id: this.userData.user_id,
      verified_time: moment().toDate()
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '30%',
      data: `Verify kitted countsheet: ${element.data.item_instance.reference}?`,
    });
    const that = this;
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._trackLoadingService.startLoading('audit-kit-instances-list-checkedClicked', 'Verifying Kit Count Sheet');

        this._databaseService.add('user_actions', {
          audit_id: this.auditId.toString(),
          auditor: `${this.userData.first_name} ${this.userData.last_name}`,
          timestamp: `${(new Date()).toUTCString()}`,
          area: '',
          kit_reference: `${element.data.item_instance.reference}`,
          kit_lot_number: `${element.data.item_instance.lot_number}`,
          action: 'Verified count sheet',
          total_items_counted: element.data.count_sheet.total_items_counted,
          site_id: this.siteId,
          warehouse_id: element.data.count_sheet.warehouse_id
        });
        this.onlineSubscription = this._onlineService.isOnlineSubscription().subscribe(online => {
          if (online && !this._onlineService.getTestOfflineBool()) {
            this._countSheetService.updateCountSheetStatus(element.data.count_sheet.dbId, verifiedStatus, verifiedMetadata).subscribe(res => {
              zip(
                this._auditKitInstanceDatabase.update(this._auditKitInstanceDatabase.new(res.kit)),
                this._countSheetDatabaseService.update(this._countSheetDatabaseService.new(res.count_sheet))
              ).subscribe((data: any[]) => {
                zip(
                  this._countSheetDatabaseService.find(this.auditId, {not_null_audit_kit_instance_id: true, archived: false}),
                  this._auditKitInstanceDatabase.find(this.auditId, {})
                ).subscribe((data: any[]) => {
                  this.countsheets = data[0];
                  this.auditKitInstances = data[1];
                  this.dataSource = new AuditKitInstancesListDataSource(this.paginator, this.sort, this.auditKitInstances, this.filters);
                  this._trackLoadingService.stopLoading('audit-kit-instances-list-checkedClicked');
                });
              });
            });
          } else {

            that._countSheetDatabaseService.find(that.auditId, {audit_kit_instance_id: element.dbId, archived: false}).then((matches: any[]) => {
              let countSheet = matches[0];
              countSheet.isSynchronized = 0;
              countSheet.data.count_sheet_status = verifiedStatus;
              countSheet.data.verified_by = that.userData;
              countSheet.data.verified_time = moment().format('lll');
              that._countSheetDatabaseService.update(countSheet).then(function () {
                zip(
                  that._countSheetDatabaseService.find(that.auditId, {not_null_audit_kit_instance_id: true, archived: false}),
                  that._auditKitInstanceDatabase.find(that.auditId, {})
                ).subscribe((data: any[]) => {
                  that.countsheets = data[0];
                  that.auditKitInstances = data[1];
                  that.dataSource = new AuditKitInstancesListDataSource(that.paginator, that.sort, that.auditKitInstances, that.filters);
                  that._trackLoadingService.stopLoading('audit-kit-instances-list-checkedClicked');
                });
              });
            });
          }
        });

        this.onlineSubscription.unsubscribe();
      } else {
        element.data.verify_display = false;
      }
    });
  }

  openSnackBar() {
    this.snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: this.snackBarConfig,
      duration: this.snackBarConfig.duration
    });
  }

  exportToExcel() {
    //array to send to exel-export service
    const dataToExport = [];
    //represents a single row in exel sheet
    let dataRow: {};
    this.dataSource.getDataForExport().forEach(row => {
      dataRow = {
        'Unique Reference': row.data.item_instance.reference + ' - ' + row.data.item_instance.lot_number,
        'Counted At': row.data.count_sheet ? row.data.count_sheet.data.location_name : '',
        'Counted By': row.data.counted_by ? row.data.counted_by.name : '',
        'Status': row.data.statusText,
        'Manufacturer Reference': row.data.item_instance.item.root_item ? row.data.item_instance.item.root_item.reference : '',
        'Contains Implants': row.data.has_consigned_items ? 'Yes' : 'No',
        'Type': row.data.item_instance.type,
        'Description': row.data.item_instance.description,
        'Warehouse': row.data.count_sheet ? row.data.count_sheet.data.warehouse_name : row.data.warehouse_name,
        'Location': row.data.item_instance.address.name,
        'Lot Number': row.data.item_instance.lot_number,
        'Items Counted': row.data.count_sheet ? row.data.count_sheet.data.total_items_counted : 0
      };

      dataToExport.push(dataRow);
    });
    this.excelService.exportAsExcelFile(dataToExport, 'kit summary', {});

  }

  getCountSheetItemsInAnyState(online: boolean, auditId: number, countSheet: ICountSheetData) {
    const that = this;
    let promise = new Promise((resolve, reject) => {
      if (online) {
        that._countSheetItemService.getCountSheetItems(auditId, {count_sheet_client_id: countSheet.id}).subscribe(data => {
          that._countSheetItemService.refreshOfflineDataForCountSheet(auditId, countSheet.id, data).then((countSheetItems: any[]) => {
            resolve(countSheetItems);
          });
        })
      }
      else {
        that._countSheetItemDatabaseService.find(auditId, {count_sheet_client_id: countSheet.id, _destroy: false}).then((existingCountSheetItems: any[]) => {
          //we must have already cached these into indexeddb
          if (existingCountSheetItems.length > 0) {
            return resolve(existingCountSheetItems);
          }
          //get the data from the cached serviceworker
          that._countSheetItemService.getCountSheetItems(auditId).subscribe((auditCountSheetItems) => {
            const matches = auditCountSheetItems.filter(cs => cs.count_sheet_client_id === countSheet.id);
            that._countSheetItemService.refreshOfflineDataForCountSheet(auditId, countSheet.id, matches).then(function (csis) {
              resolve(csis);
            });
          });
        });
      }
    });
    return promise;
  }

  printCountSheet(row: IkitInstance) {
    this._countSheetDatabaseService.find(this.auditId, {audit_kit_instance_id: row.dbId, archived: false}).then((matches: any[]) => {
      const countSheet = matches[0];
      this.filters['exclusions'] = 'hide';
      zip(
        this.getRedspotQuantities(row.data.audit_id, row.data.item_instance_id),
        this.getRootKitDefinitionItems(row.data.item_instance.root_item_id),
        this.getLotPoolManagedItems(),
        this.getItemSettingsByAudit(this.auditId),
        this.getItemCombinations(),
        this.getConsignmentQuantities(this.auditId),
        this.getExclusions(this.auditId)
      ).subscribe(data => {
        this.redspotQuantities = data[0];
        this.kitDefinitionItems = data[1];
        this.lotPoolManagedItems = data[2];
        this.itemSettings = data[3];
        this.itemCombinations = data[4];
        this.sapQuantities = data[5];
        this.exclusions = data[6];
        this._recallOracleService.prime(row.data.audit_id, {itemCombinations: this.itemCombinations}).then(_ => {
          this._databaseService.add('user_actions', {
            audit_id: this.auditId.toString(),
            auditor: `${this.userData.first_name} ${this.userData.last_name}`,
            timestamp: `${(new Date()).toUTCString()}`,
            area: '',
            kit_reference: `${row.data.item_instance.reference}`,
            kit_lot_number: `${row.data.item_instance.lot_number}`,
            action: 'Printed count sheet',
            total_items_counted: (<any>(row.data.count_sheet || {})).total_items_counted || 0,
            site_id: this.siteId,
            warehouse_id: (<any>(row.data.count_sheet || {})).warehouse_id || row.data.warehouse_id
          });
          this.onlineSubscription = this._onlineService.isOnlineSubscription().subscribe(online => {
            const isOnline = online && !this._onlineService.getTestOfflineBool();
            if (countSheet) {
              zip(this.getCountSheetItemsInAnyState(isOnline, this.auditId, countSheet)).subscribe((countsheetItemsData: any[]) => {
                const csis = countsheetItemsData[0];
                const countsheetItems = this.kitInstanceHelper.getKitDatasourceData(countSheet, csis, this.lotPoolManagedItems, this.redspotQuantities, this.sapQuantities, this.itemCombinations, this._recallOracleService, this.kitDefinitionItems, this.itemSettings, this.exclusions, this.filters);
  
                const printOptions: IPrintOptions = {
                  isKitted: true,
                  warehouseName: row.data.warehouse_name,
                  locationName: countSheet.data.location_name,
                  lotNumber: row.data.item_instance.lot_number,
                  statusText: row.data.statusText,
                  agencyName: this.audit.agency_name,
                  reference: row.data.item_instance.reference,
                  countSheet: countSheet,
                  kitDefinitionItems: this.kitDefinitionItems,
                  lotPoolManagedItems: this.lotPoolManagedItems,
                  redspotQuantities: this.redspotQuantities,
                  filters: this.filters,
                  itemSettings: this.itemSettings,
                  exclusions: this.exclusions
                };
                this.printHelper.print(countsheetItems, printOptions);
              });
  
            } else {
              const countsheetItems = this.kitInstanceHelper.getKitDatasourceData(countSheet, null, this.lotPoolManagedItems, this.redspotQuantities, this.sapQuantities, this.itemCombinations, this._recallOracleService, this.kitDefinitionItems, this.itemSettings, this.exclusions, this.filters);
  
              const printOptions: IPrintOptions = {
                isKitted: true,
                warehouseName: row.data.warehouse_name,
                locationName: this.location ? this.location.location_name : '',
                lotNumber: row.data.item_instance.lot_number,
                statusText: row.data.statusText,
                agencyName: this.audit.agency_name,
                reference: row.data.item_instance.reference,
                countSheet: countSheet,
                kitDefinitionItems: this.kitDefinitionItems,
                lotPoolManagedItems: this.lotPoolManagedItems,
                redspotQuantities: this.redspotQuantities,
                filters: this.filters,
                itemSettings: this.itemSettings,
                exclusions: this.exclusions
              };
              this.printHelper.print(countsheetItems, printOptions);
  
            }
          });
          this.onlineSubscription.unsubscribe();
        });
      });
    });
  }
}
