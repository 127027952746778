import { Injectable } from '@angular/core';
import { L } from '@angular/core/src/render3';
import { Assets } from '../helpers/assets';
import { ICameraConfig } from '../interfaces/i-camera-config';
import { IDeviceInfo } from '../interfaces/i-device-info';

declare var ImageCapture: any

@Injectable({
  providedIn: 'root'
})
export class CameraService {
  videoStream: any
  streaming: boolean = false
  video: any;
  configs: ICameraConfig[] = []
  devices: IDeviceInfo[] = []
  selectedDevice: IDeviceInfo
  lastUsedCameraKey: string = "LAST_USED_CAMERA"

  config = {
    video: { facingMode: 'environment' },
    audio: false
  }

  constructor() {
    this.getDevices()
  }

  initCamera(videoElement: any, callback: Function) {
    let storedCamera = this.getLastUsedCamera();
    if (storedCamera) {
      this.config = this.getConfigforCamera(storedCamera) || {
        video: {
          facingMode: 'environment'
        },
        audio: false
      }
      this.initSpecificCamera(videoElement, this.config, callback)
    } else {
      this.selectedDevice = this.selectedDevice || this.devices[0]
      this.config = this.getConfigforCamera(this.selectedDevice) || {
        video: {
          facingMode: 'environment'
        },
        audio: false
      }

      var browser = <any>navigator;
      this.video = videoElement
      browser.getUserMedia = (browser.getUserMedia ||
        browser.webkitGetUserMedia ||
        browser.mozGetUserMedia ||
        browser.msGetUserMedia);

      let that = this
      browser.mediaDevices.getUserMedia(this.config).then(stream => {
        this.videoStream = stream
        videoElement.srcObject = stream
        videoElement.onloadedmetadata = function (e) {
          callback(that.selectedDevice)
          that.rememberLastCamera(that.selectedDevice)

          videoElement.play();
        };
      }).catch((err) => {
        console.log(err)
      });
    }
  }

  initSpecificCamera(video: any, config: ICameraConfig, callback: Function) {

    this.selectedDevice = this.devices.find((d) => {
      return d.deviceId === config.video.deviceId
    })

    this.closeCamera();
    var browser = <any>navigator;
    this.video = video
    browser.getUserMedia = (browser.getUserMedia ||
      browser.webkitGetUserMedia ||
      browser.mozGetUserMedia ||
      browser.msGetUserMedia);

    let that = this;
    browser.mediaDevices.getUserMedia(config).then(stream => {
      this.videoStream = stream
      video.srcObject = stream
      video.onloadedmetadata = function (e) {
        callback(that.selectedDevice)
        that.rememberLastCamera(that.selectedDevice)
        video.play();
      };
    }).catch((err) => {
      console.log(err)
    });

  }

  closeCamera(callback = null) {
    if (this.videoStream) {
      let tracks = this.videoStream.getTracks()
      tracks.forEach((track) => {
        track.stop()
      })
    }
    if (callback) {
      callback()
    }
  }

  takePicChrome() {
    let tracks = this.videoStream.getTracks()
    let imageCapture = new ImageCapture(tracks[0])
    this.pauseVideo()
    imageCapture.grabFrame().then((bitmap) => {
    }).catch((err) => {
      console.log(err)
    })
  }

  takePic(imageName: string, callback = null) {
    this.pauseVideo()
    this.playShutterSound();
    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d')
    let dims = this.getVideoDimensions()
    canvas.width = dims.width
    canvas.height = dims.height
    context.drawImage(this.video, 0, 0, dims.width, dims.height)
    canvas.toBlob((blob) => {
      const file = new File([blob], imageName, { type: 'image/png' })
      if (callback) {
        callback(file)
      }
    }, 'image/png')

  }

  pauseVideo() {
    this.video.pause()
  }

  play() {
    this.video.play()
  }

  getVideoDimensions() {
    if (this.video) {
      return {
        height: this.video.videoHeight,
        width: this.video.videoWidth
      }
    } else {
      return {
        height: 0,
        width: 0
      }
    }
  }

  getDevices(callback = null) {
    var browser = <any>navigator;
    browser.mediaDevices.enumerateDevices()
      .then((devices: IDeviceInfo[]) => {
        let cameras = devices.filter((d) => {
          return d.kind === 'videoinput'
        });
        if (callback) {
          callback(cameras)
        }
        this.makeCameraConfigs(cameras)
      }).catch((error) => {
        console.log(error)
      })
  }

  makeCameraConfigs(devices: IDeviceInfo[]) {
    this.devices = devices;
    let configs: ICameraConfig[] = []
    devices.forEach((d) => {
      let config = {
        audio: false,
        video: {
          deviceId: d.deviceId
        }
      }
      configs.push(config)
    })
    this.configs = configs
  }

  getConfigforCamera(camera: IDeviceInfo) {
    return this.configs.find((co) => {
      return co.video.deviceId === camera.deviceId
    })
  }

  playShutterSound() {
    const audio = new Audio();
    audio.src = Assets.sounds.cameraShutter;
    audio.play();
  }

  rememberLastCamera(camera: IDeviceInfo) {
    localStorage.setItem(this.lastUsedCameraKey, JSON.stringify(camera));
  }

  getLastUsedCamera(): IDeviceInfo {
    let localStorageItem = localStorage.getItem(this.lastUsedCameraKey)
    let camera: IDeviceInfo = localStorageItem? JSON.parse(localStorageItem): null
    return camera;
  }
}
