import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialogRef } from '@angular/material';
import { ISnackBarConfig } from 'src/app/interfaces/isnack-bar-config';
import { LotPoolManagedItemService } from 'src/app/services/lot-pool-managed-item.service';
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';
import { TrackLoadingService } from '../../services/track-loading.service';
import { ExportToExcelService } from 'src/app/services/export-to-excel.service';

@Component({
  selector: 'app-lot-pool-managed-items-import',
  templateUrl: './lot-pool-managed-items-import.component.html',
  styleUrls: ['./lot-pool-managed-items-import.component.css']
})
export class LotPoolManagedItemsImportComponent implements OnInit {
  selectedFile = null;
  snackBarConfig: ISnackBarConfig = {
    message: '',
    duration: 3000,
    success: false,
    snackBarClass: ''
  };
  lotPoolManagedItemCount = 0;


  constructor(
    public snackBar: MatSnackBar,
    private _lotPoolManagedItemService: LotPoolManagedItemService,
    private _trackLoading: TrackLoadingService,
    public dialogRef: MatDialogRef<LotPoolManagedItemsImportComponent>,
    private _exportToExcelService: ExportToExcelService
  ) { }

  ngOnInit() {

    this._lotPoolManagedItemService.getLotPoolManagedItemCount().subscribe(count => {
      this.lotPoolManagedItemCount = count;
    });
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
  }

  onUpload() {
    const fd = new FormData();
    fd.append('file', this.selectedFile, this.selectedFile.name);

    this._trackLoading.startLoading('lot-pool-managed-items-import', 'Importing Lot Pool Managed Items');
    this._lotPoolManagedItemService.import(fd).subscribe(res => {
      this._trackLoading.stopLoading('lot-pool-managed-items-import');
      this.snackBarConfig.message = 'File Uploaded Successfully';
      this.snackBarConfig.success = true;
      this.openSnackBar();
      this.dialogRef.close();
    }, res => {
      this._trackLoading.stopLoading('lot-pool-managed-items-import');
      this.snackBarConfig.message = 'File Upload Failed';
      this.snackBarConfig.success = false;
      this.openSnackBar();
    });
  }

  openSnackBar() {
    this.snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: this.snackBarConfig,
      duration: this.snackBarConfig.duration
    });
  }


  templateFrontEnd() {
    this._exportToExcelService.exportAsExcelFile([{reference: ''}], 'lot_pool_managed_items', {});
  }

}
