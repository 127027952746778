<div *ngIf="loading" class="spinner">
  <mat-spinner diameter="50" color="primary">
  </mat-spinner>
</div>
<div [ngClass]="{'disabled':loading}" class="mat-elevation-z4 data-table">
  <table mat-table #table [dataSource]="dataSource" matSort aria-label="Elements">
    <!-- area Column -->
    <ng-container matColumnDef="area">
      <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Area</th>
      <td mat-cell *matCellDef="let row">{{row.data.area}}</td>
    </ng-container>

    <ng-container matColumnDef="verify">
      <th mat-header-cell class="table-header" *matHeaderCellDef>
        <div *ngxPermissionsOnly="['audit_administrator', 'auditor']">
          <mat-checkbox [ngClass]="{'display-when-counted': !anyCountSheetsCounted}" [(ngModel)]="verifyAllChecked" tabindex="-1"
            (change)="checkAllClicked($event)">Verify All</mat-checkbox>
        </div>
      </th>
      <td mat-cell *matCellDef="let row">
        <div *ngxPermissionsOnly="['audit_administrator', 'auditor']">
          <mat-checkbox *ngIf="row.data.count_sheet_status == 3" [(ngModel)]="row.data.verify_display" tabindex="-1" (change)="checkedClicked(row)">
          </mat-checkbox>
        </div>
      </td>
    </ng-container>

    <!-- location Column -->
    <ng-container matColumnDef="site">
      <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Site</th>
      <td mat-cell *matCellDef="let row">{{row.data.location_name}}</td>
    </ng-container>

    <!-- warehouse Column -->
    <ng-container matColumnDef="warehouse">
      <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Warehouse</th>
      <td mat-cell *matCellDef="let row">{{row.data.warehouse_name}}</td>
    </ng-container>

    <ng-container matColumnDef="count">
      <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Count</th>
      <td mat-cell *matCellDef="let row">{{row.data.total_items_counted}}</td>
    </ng-container>

    <!-- status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let row">
        {{row.data.status_text}}
      </td>
    </ng-container>

    <!-- Created By Column -->
    <ng-container matColumnDef="createdBy">
      <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Created By</th>
      <td mat-cell *matCellDef="let row">
        {{row.data.created_by.first_name}} {{row.data.created_by.last_name}}
      </td>
    </ng-container>

    <!-- Date Created Column -->
    <ng-container matColumnDef="dateCreated">
      <th mat-header-cell class="table-header" *matHeaderCellDef mat-sort-header>Date Created</th>
      <td mat-cell *matCellDef="let row">
        {{row.data.created_at | date:'mediumDate'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="addUnkitted">
      <th mat-header-cell class="table-header" *matHeaderCellDef>

        <div *ngIf="audit.redspot_inventory_imported_date">
          <div *ngIf="audit.sap_inventory_imported_date">
            <div *ngxPermissionsOnly="['audit_administrator', 'auditor']" class="button-column">
              <span class="tooltipWrapper">
                <button *ngIf="warehouses" mat-icon-button ngbTooltip="Add Unkitted Count Sheet" placement="left"
                  (click)="addUnkittedCountSheet()">
                  <mat-icon class="material-icons">add_circle</mat-icon>
                </button>
              </span>
            </div>
          </div>
        </div>
      </th>
      <td mat-cell *matCellDef="let row">
        <span class="tooltipWrapper" *ngxPermissionsOnly="['audit_administrator', 'auditor']">
          <button *ngIf="row.data.count_sheet_status === 1 && !viewOnly" ngbTooltip="Start Count" placement="left"
            (click)="startCounting(row)" mat-icon-button>
            <mat-icon class="material-icons">play_circle_outline</mat-icon>
          </button>
        </span>
        <span class="tooltipWrapper">
          <button mat-icon-button (click)="continueCounting(row)" ngbTooltip="Continue Count" placement="left"
            *ngIf="row.data.count_sheet_status === 2 && !viewOnly">
            <mat-icon class="material-icons">queue_play_next</mat-icon>
          </button>
        </span>
        <span class="tooltipWrapper">
          <button mat-icon-button (click)="goToCompletedCountSheet(row)" ngbTooltip="Needs Verification"
            placement="left" *ngIf="row.data.count_sheet_status === 3 && !viewOnly">
            <mat-icon class="material-icons">check_outline</mat-icon>
          </button>
        </span>
        <span class="tooltipWrapper">
          <button mat-icon-button (click)="goToCompletedCountSheet(row)" ngbTooltip="View" placement="left"
            *ngIf="viewOnly">
            <mat-icon class="material-icons">remove_red_eye</mat-icon>
          </button>
        </span>
        <span class="tooltipWrapper">
          <button mat-icon-button (click)="goToVerifiedCountSheet(row)" ngbTooltip="Verified" placement="left"
            *ngIf="row.data.count_sheet_status == 4 && !viewOnly">
            <mat-icon style="color:green" class="material-icons">check_circle</mat-icon>
          </button>
        </span>
        <span class="tooltipWrapper">
          <button mat-icon-button (click)="editUnkittedCountSheet(row)" ngbTooltip="Edit" placement="left"
            *ngIf="row.data.count_sheet_status == 2 && !viewOnly">
            <mat-icon class="material-icons">edit</mat-icon>
          </button>
        </span>
        |
        <span class="tooltipWrapper">
          <button mat-icon-button type="button" ngbTooltip="Print" placement="left" (click)="printCountSheet(row)">
            <mat-icon class="material-icons">print</mat-icon>
          </button>
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns">
    </tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'not-synced': !row.isSynchronized}">
    </tr>
  </table>

  <mat-paginator #paginator showFirstLastButtons [pageSize]="50" [pageSizeOptions]="[50,100,250,1000]">
  </mat-paginator>
</div>
