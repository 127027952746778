import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { urls } from '../helpers/urls';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ManufacturerService {

  constructor(private http: HttpClient) { }

  getManufacturer() {
    return this.http.get<any>(`${environment.serverPath}api/manufacturers/1`, {}).pipe(tap(manufacturer => {
      if (manufacturer['audit_lot_pool_managed_last_changed_date']) {
        const currentStamp = new Date(manufacturer['audit_lot_pool_managed_last_changed_date']).getTime();
        const previousStamp = localStorage.getItem(`manufacturer[${manufacturer['id']}]lot_pool_managed_items`);
        if (previousStamp && currentStamp.toString() !== previousStamp.toString()) {
          window.caches.keys().then(function(cacheNames) {
            const match = cacheNames.find(cn => cn.includes('LongTermCountSheets'));
            if (match) {
              caches.open(match).then(function (cache) {
                cache.delete(`${environment.serverPath}${urls.lotPoolManagedItems}`, {ignoreSearch: true});
              });
            }
          });
        }
        localStorage.setItem(`manufacturer[${manufacturer['id']}]lot_pool_managed_items`, currentStamp.toString());
      }
    }));
  }
}
