/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./barcode-gs1-format.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./barcode-gs1-format.component";
import * as i3 from "../../../services/item-package.service";
import * as i4 from "@angular/material/snack-bar";
import * as i5 from "../../../services/track-loading.service";
var styles_BarcodeGs1FormatComponent = [i0.styles];
var RenderType_BarcodeGs1FormatComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BarcodeGs1FormatComponent, data: {} });
export { RenderType_BarcodeGs1FormatComponent as RenderType_BarcodeGs1FormatComponent };
export function View_BarcodeGs1FormatComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_BarcodeGs1FormatComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-barcode-gs1-format", [], null, null, null, View_BarcodeGs1FormatComponent_0, RenderType_BarcodeGs1FormatComponent)), i1.ɵdid(1, 114688, null, 0, i2.BarcodeGs1FormatComponent, [i3.ItemPackageService, i4.MatSnackBar, i5.TrackLoadingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BarcodeGs1FormatComponentNgFactory = i1.ɵccf("app-barcode-gs1-format", i2.BarcodeGs1FormatComponent, View_BarcodeGs1FormatComponent_Host_0, {}, { itemNotFound: "itemNotFound" }, []);
export { BarcodeGs1FormatComponentNgFactory as BarcodeGs1FormatComponentNgFactory };
