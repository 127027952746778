<div class="col-md-12">
  <h1 mat-dialog-title class="full-width header-title">Audit Log</h1>
  <div mat-dialog-content>
    <p>Click download to obtain logged user actions.</p>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="delete()">Clear Log</button>
    <button mat-button (click)="download()">Download</button>
  </div>
</div>
