import { DataSource } from '@angular/cdk/collections';
import { Observable, of as observableOf, merge } from 'rxjs';
import { CountSheetItemHelper } from './../../helpers/countsheet-item-helper';
import { IcountsheetItemData, ILotPoolManagedCountsheetItemGroup } from '../../interfaces/icountsheet-item';
import { ICountSheetData } from 'src/app/interfaces/icount-sheet-data';
import { IItemCombinationLookupOptions } from 'src/app/interfaces/i-item-combination-lookup-options';
import { RecallOracleService } from 'src/app/services/recall-oracle.service';


export class KitInstanceCountSheetDataSource extends DataSource<any> {
  data: any[] = [];
  expectedKitCountSheetItems: IcountsheetItemData[] = []
  redspotInventoryIds: string[] = []
  _countSheet: ICountSheetData;
  _redspotQuantities: any;
  _sapQuantities: any;
  _rootKitDefinitionItems: any;
  _itemSettings: any;
  _filters: any;
  _itemCombinations: any;
  _exclusions: any;
  _countSheetItems: any;
  _lotPoolManagedItems: any;
  _recallOracleService: RecallOracleService;

  // caches
  matchingKitExpectedItemCache = {};
  matchingRootKitDefinitionItemCache = {};

  public set recallOracleService(value) {
    this._recallOracleService = value;
  }

  public get recallOracleService() {
    return this._recallOracleService;
  }

  public set lotPoolManagedItems(value) {
    this._lotPoolManagedItems = value;
  }

  public get lotPoolManagedItems() {
    return this._lotPoolManagedItems;
  }

  public set exclusions(value) {
    this._exclusions = value;
  }

  public get exclusions() {
    return this._exclusions;
  }

  public set itemCombinations(value) {
    this._itemCombinations = value;
  }

  public get itemCombinations() {
    return this._itemCombinations;
  }

  public set filters(value) {
    this._filters = value;
  }

  public get filters() {
    return this._filters;
  }

  public set itemSettings(value) {
    this._itemSettings = value;
  }

  public get itemSettings() {
    return this._itemSettings;
  }

  public set rootKitDefinitionItems(value) {
    this._rootKitDefinitionItems = value;
  }

  public get rootKitDefinitionItems() {
    return this._rootKitDefinitionItems;
  }

  public set sapQuantities(value) {
    this._sapQuantities = value;
  }

  public get sapQuantities() {
    return this._sapQuantities;
  }

  public set redspotQuantities(value) {
    this._redspotQuantities = value;
  }

  public get redspotQuantities() {
    return this._redspotQuantities;
  }

  public set countSheet(value) {
    this._countSheet = value;
  }

  public get countSheet() {
    return this._countSheet;
  }

  public set countSheetItems(value) {
    this._countSheetItems = value;
  }

  public get countSheetItems() {
    return this._countSheetItems;
  }

  constructor(private countSheetItemHelper: CountSheetItemHelper) {
    super();
  }

  matchingKitExpectedItem(csi: IcountsheetItemData) {
    const cacheId = this.countSheetItemHelper.makeCombinationCacheIdFromCountsheetItem(csi);
    if (this.matchingKitExpectedItemCache[cacheId]) {
      return this.matchingKitExpectedItemCache[cacheId];
    } else {
      const result = this.redspotQuantities.find(function (rq: any) {
        const itemIdMatch = rq.item_id === csi.data.item_id;
        const lotNumberMatch = (rq.lot_number.value || '').toLowerCase() === (csi.data.lot_number || '').toLowerCase();
        const serialNumberMatch = '' === (csi.data.serial || '').toLowerCase();
        return itemIdMatch && lotNumberMatch && serialNumberMatch;
      });
      this.matchingKitExpectedItemCache[cacheId] = result;
      return result;
    }
  }

  matchingRootKitDefinitionItem(itemId: number) {
    const cacheId = this.countSheetItemHelper.makeCombinationCacheId({ itemId: itemId });
    if (this.matchingRootKitDefinitionItemCache[cacheId]) {
      return this.matchingRootKitDefinitionItemCache[cacheId];
    } else {
      const result = this.rootKitDefinitionItems.find(function (kdi: any) {
        return kdi.item_id === itemId;
      });
      this.matchingRootKitDefinitionItemCache[cacheId] = result;
      return result;
    }
  }

  fillFunction(data: any) {
    data.map((l) => {
      const now = new Date();
      if (l.count_sheet_items) {
        l.data.added_to_kit = l.available_redspot_inventory.length === 0 && !this.countSheetItemHelper.matchingRootKitDefinitionItem(l.data.item_id, this.rootKitDefinitionItems);
        l.data.is_excluded = this.countSheetItemHelper.getExclusion(l.data.item_id, this.exclusions);
        l.data.in_definition = !!this.countSheetItemHelper.matchingRootKitDefinitionItem(l.data.item_id, this.rootKitDefinitionItems);
        l.data.show_recalled_warning = this.recallOracleService.get(l.data.item_id, null, null);
      } else {
        if (l.data.checked) {
          if (!l.data.expiration_date) {
            const lookupOpt: IItemCombinationLookupOptions = { itemId: l.data.item_id, lotNumber: l.data.lot_number, serialNumber: l.data.serial };
            const sqMatch = this.countSheetItemHelper.getSapQuantity(lookupOpt, this.sapQuantities);
            if (sqMatch) {
              l.data.expiration_date = sqMatch.expiry_date;
            } else {
              l.data.expiration_date = l.data.redspot_expiration_date;
            }
            if (l.data.expiration_date) {
              l.data.show_expired_warning = new Date(l.data.expiration_date) < now ? true : false;
            } else {
              l.data.show_expired_warning = false;
            }
          } else {
            l.data.show_expired_warning = new Date(l.data.expiration_date) < now ? true : false;
          }

          l.data.show_recalled_warning = this.recallOracleService.get(l.data.item_id, l.data.lot_number, l.data.serial);

          l.data.show_invalid_warning = !this.countSheetItemHelper.checkIfValidItemCombination(l.data.item_id, l.data.lot_number, l.data.serial, false, l.data.is_lot_tracked, l.data.is_serial_tracked, this._itemSettings, this._itemCombinations);
        }
        l.data.expected_quantity = l.data.expected_quantity || 0;
        l.data.in_definition = !!this.countSheetItemHelper.matchingRootKitDefinitionItem(l.data.item_id, this.rootKitDefinitionItems);
        l.data.is_excluded = this.countSheetItemHelper.getExclusion(l.data.item_id, this.exclusions);

        if (l.id || l.dbId) {
          l.data.added_to_kit = !this.matchingKitExpectedItem(l) && !this.countSheetItemHelper.matchingRootKitDefinitionItem(l.data.item_id, this.rootKitDefinitionItems);
        }
      }
    });
    return data;
  }

  refresh() {
    const that = this;
    let currentCountSheetItems = this.countSheetItems.slice().filter(csi => !csi._destroy);
    
    const filterFunction = function (data: IcountsheetItemData) {
      let orderTypeMatches = true;
      let exclusionMatches = true;
      if (that.filters['orderType'] !== 'all') {
        const itemSetting = that.countSheetItemHelper.getItemSettings(data.data.item_id, that.itemSettings);
        orderTypeMatches = (that.filters['orderType'] === 'owned' && !(itemSetting || data.data).is_consigned) || (that.filters['orderType'] === 'consigned' && (itemSetting || data.data).is_consigned);
      }
      if (that.filters['exclusions'] === 'hide') {
        exclusionMatches = !data.data.is_excluded;
      }
      return orderTypeMatches && exclusionMatches;
    };

    const sortFunction = function (a: IcountsheetItemData, b: IcountsheetItemData){
      const aMatch = that.matchingRootKitDefinitionItem(a.data.item_id);
      const bMatch = that.matchingRootKitDefinitionItem(b.data.item_id);

      if (aMatch && bMatch) {
        if (aMatch['position'] > bMatch['position']) {
          return 1;
        } else if (aMatch['position'] < bMatch['position']) {
          return -1;
        } else {
          if (a.data.rank > b.data.rank) {
            return 1;
          } else if (a.data.rank < b.data.rank) {
            return -1;
          }
        }
      } else if (aMatch) {
        return -1;
      } else if (bMatch) {
        return 1;
      } else if (a.data.reference.toLowerCase() > b.data.reference.toLowerCase()) {
        return 1;
      } else if (a.data.reference.toLowerCase() < b.data.reference.toLowerCase()) {
        return -1;
      } else if (a.data.reference.toLowerCase() === b.data.reference.toLowerCase()) {
        if (a.data.expected_quantity < b.data.expected_quantity) {
          return 1;
        } else if (a.data.expected_quantity > b.data.expected_quantity) {
          return -1;
        } else {
          if (a.data.rank > b.data.rank) {
            return 1;
          } else if (a.data.rank < b.data.rank) {
            return -1;
          }
        }

      } else {
        return 0;
      }
    }

    const blankSortFunction = function(a: IcountsheetItemData, b: IcountsheetItemData){
      if (a.data.rank > b.data.rank) {
        return 1;
      } else if (a.data.rank < b.data.rank) {
        return -1;
      }else{
        return 0;
      }
    }

    if (this.countSheet.data.show_expected_item_list) {
      const lotPoolManagedCountSheetItems = currentCountSheetItems.filter(csi => that.countSheetItemHelper.getIsLotPoolManaged(csi.data.item_id, that.lotPoolManagedItems));
      const notLotPoolManagedCountSheetItems = currentCountSheetItems.filter(csi => !that.countSheetItemHelper.getIsLotPoolManaged(csi.data.item_id, that.lotPoolManagedItems));
      
      const lotPoolManagedCountSheetItemGroup = [];
      lotPoolManagedCountSheetItems.filter(csi => !csi.data.hide).forEach(csi => {
        let lpmi = that.countSheetItemHelper.getLotPoolManagedItem(csi.data.item_id, that.lotPoolManagedItems);
        let redspotId = this.countSheet.data.audit_kit_instance_id.toString() + "-lpm-" + lpmi.id.toString()
        this.redspotInventoryIds.push(redspotId);

        let match: ILotPoolManagedCountsheetItemGroup = lotPoolManagedCountSheetItemGroup.find(csig => {
          return csig.data.item_id === csi.data.item_id;
        });
        if (!match) {
          const matchingRedspotInventory = that.redspotQuantities.filter(rq => rq.item_id === csi.data.item_id);
          match = {
            count_sheet_items: [],
            available_redspot_inventory: matchingRedspotInventory,
            data: {
              item_id: csi.data.item_id,
              reference: csi.data.reference,
              description: csi.data.description,
              is_consigned: csi.data.is_consigned,
              expected_quantity: matchingRedspotInventory.reduce((a, b) => a + b.quantity, 0),
              quantity: 0,
              redspot_inventory_id: redspotId

            }
          };
          lotPoolManagedCountSheetItemGroup.push(match);
        }
        match.count_sheet_items.push(csi);
        if (csi.data.checked) {
          match.data.quantity += csi.data.quantity;
        }
      });

      this.redspotQuantities.forEach(rq => {
        if (that.countSheetItemHelper.getIsLotPoolManaged(rq.item_id, that.lotPoolManagedItems)) {
          if (!lotPoolManagedCountSheetItemGroup.find(csig => csig.data.item_id === rq.item_id)) {
            const matchingRedspotInventory = that.redspotQuantities.filter(lrq => lrq.item_id === rq.item_id);
            let redspotId = this.countSheet.data.audit_kit_instance_id.toString() + "-rq-" + rq.id.toString()
            this.redspotInventoryIds.push(redspotId);
            lotPoolManagedCountSheetItemGroup.push({
              count_sheet_items: [],
              available_redspot_inventory: matchingRedspotInventory,
              data: {
                item_id: rq.item_id,
                reference: rq.item.reference,
                description: rq.item.description,
                is_consigned: rq.item.is_consigned,
                expected_quantity: matchingRedspotInventory.reduce((a, b) => a + b.quantity, 0),
                quantity: 0,
                redspot_inventory_id: redspotId
              }
            });
          }

        } else if (!that.countSheetItemHelper.getIsLotPoolManaged(rq.item_id, that.lotPoolManagedItems)) {
          const matches = notLotPoolManagedCountSheetItems.filter(function (l) {
            const itemIdMatch = l.data.item_id === rq.item_id;
            const lotNumberMatch = (l.data.lot_number || '') === (rq.lot_number.value || '');
            return itemIdMatch && lotNumberMatch;
          });

          const withSerial = matches.filter(i => {
            return i.data.serial;
          });

          const withoutSerial = matches.filter(i => {
            return !i.data.serial;
          });


          if (withSerial.length > 0) {
            let totalQty = rq.quantity;
            withSerial.forEach(function (csi) {
              if (totalQty > 0) {
                const lowerQty = Math.min(totalQty, csi.data.quantity);
                csi.data.redspot_expiration_date = rq.expiry_date;
                csi.data.expected_quantity = lowerQty;
                totalQty -= lowerQty;
              } else {
                csi.data.expected_quantity = 0;
              }
            });

            if (withoutSerial.length > 0) {
              withoutSerial[0].data.expected_quantity = totalQty;
            } else if (totalQty > 0) {
              let redspotId = this.countSheet.data.audit_kit_instance_id.toString() + "-rq-" + rq.id.toString();
              this.redspotInventoryIds.push(redspotId);
              const match = {
                data: {
                  item_id: rq.item.id,
                  reference: rq.item.reference,
                  description: rq.item.description,
                  lot_number: rq.lot_number.value,
                  serial: '',
                  expected_quantity: totalQty,
                  quantity: 0,
                  expiration_date: null,
                  redspot_expiration_date: rq.expiry_date,
                  count_sheet_id: 0,
                  is_consigned: rq.item.is_consigned,
                  redspot_inventory_id: redspotId

                },
                id: '',
                dbId: 0,
                isSynchronized: 1
              };
              notLotPoolManagedCountSheetItems.push(match);
            }

          } else if (matches.length > 0) {
            matches[0].data.expected_quantity = rq.quantity;
            matches[0].data.redspot_expiration_date = rq.expiry_date;

          } else {
            let redspotId = this.countSheet.data.audit_kit_instance_id.toString() + "-rq-" + rq.id.toString()
            this.redspotInventoryIds.push(redspotId);
            const match: IcountsheetItemData = {
              data: {
                item_id: rq.item.id,
                reference: rq.item.reference,
                description: rq.item.description,
                lot_number: rq.lot_number.value,
                serial: '',
                expected_quantity: rq.quantity,
                quantity: 0,
                expiration_date: null,
                redspot_expiration_date: rq.expiry_date,
                count_sheet_id: 0,
                is_consigned: rq.item.is_consigned,
                manually_entered: rq.item.manually_entered,
                redspot_inventory_id: redspotId

              },
              id: '',
              dbId: 0,
              isSynchronized: 1
            };
            notLotPoolManagedCountSheetItems.push(match);
          }
        }
      });

      this.rootKitDefinitionItems.forEach(rkdi => {
        if (that.countSheetItemHelper.getIsLotPoolManaged(rkdi.item.id, that.lotPoolManagedItems)) {
          if (!lotPoolManagedCountSheetItemGroup.find(d => d.data.item_id === rkdi.item_id)) {
            const matchingRedspotInventory = that.redspotQuantities.filter(rq => rq.item_id === rkdi.item_id);
            let redspotId = this.countSheet.data.audit_kit_instance_id.toString() + "-rkdi-" + rkdi.id.toString()
            this.redspotInventoryIds.push(redspotId);
            const row: ILotPoolManagedCountsheetItemGroup = {
              count_sheet_items: [],
              available_redspot_inventory: matchingRedspotInventory,
              data: {
                item_id: rkdi.item.id,
                reference: rkdi.item.reference,
                description: rkdi.item.description,
                is_consigned: rkdi.item.is_consigned,
                expected_quantity: matchingRedspotInventory.reduce((a, b) => a + b.quantity, 0),
                quantity: 0,
                redspot_inventory_id: redspotId
                

              }
            };
            lotPoolManagedCountSheetItemGroup.push(row);
          }
        } else if (!that.countSheetItemHelper.getIsLotPoolManaged(rkdi.item.id, that.lotPoolManagedItems)) {
          if (!notLotPoolManagedCountSheetItems.find(d => d.data.item_id === rkdi.item_id)) {
            let redspotId = this.countSheet.data.audit_kit_instance_id.toString() + "-rkdi-" + rkdi.id.toString()
            this.redspotInventoryIds.push(redspotId);
            const row: IcountsheetItemData = {
              data: {
                item_id: rkdi.item.id,
                reference: rkdi.item.reference,
                description: rkdi.item.description,
                lot_number: '',
                serial: '',
                expected_quantity: 0,
                quantity: 0,
                expiration_date: null,
                redspot_expiration_date: null,
                count_sheet_id: 0,
                is_consigned: rkdi.item.is_consigned,
                manually_entered: rkdi.item.manually_entered,
                redspot_inventory_id: redspotId

              },
              id: '',
              dbId: 0,
              isSynchronized: 1
            };
            notLotPoolManagedCountSheetItems.push(row);
          }
        }
      });
      
      currentCountSheetItems = notLotPoolManagedCountSheetItems.concat(lotPoolManagedCountSheetItemGroup);
      this.assignRank(currentCountSheetItems);
      this.expectedKitCountSheetItems = currentCountSheetItems;

    } else {
      let currentCountSheetItems = this.countSheetItems.slice().filter(csi => !csi._destroy);

      this.expectedKitCountSheetItems = this.getExpectedKitCountSheetItems(currentCountSheetItems)
    }

    if(this.countSheet.data.show_expected_item_list){
      this.data = that.fillFunction(currentCountSheetItems);
      this.data = this.data.filter(filterFunction);
      this.data = this.data.sort(sortFunction);
    }else{
      this.data = that.fillFunction(currentCountSheetItems);
      this.data = this.data.filter(filterFunction);
      this.data = this.data.sort(blankSortFunction);
    }    
    
    this.data = this.data.filter(d => {
      return !d.data.hide;
    });
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<any[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      observableOf(this.data)
    ];

    return merge(...dataMutations);
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() { }

  getExpectedKitCountSheetItems(currentCountSheetItems: IcountsheetItemData[]): IcountsheetItemData[] {
    let that = this;
    const filterFunction = function (data: IcountsheetItemData) {
      let orderTypeMatches = true;
      let exclusionMatches = true;
      if (that.filters['orderType'] !== 'all') {
        const itemSetting = that.countSheetItemHelper.getItemSettings(data.data.item_id, that.itemSettings);
        orderTypeMatches = (that.filters['orderType'] === 'owned' && !(itemSetting || data.data).is_consigned) || (that.filters['orderType'] === 'consigned' && (itemSetting || data.data).is_consigned);
      }
      if (that.filters['exclusions'] === 'hide') {
        exclusionMatches = !data.data.is_excluded;
      }
      return orderTypeMatches && exclusionMatches;
    };

    const lotPoolManagedCountSheetItems = currentCountSheetItems.filter(csi => that.countSheetItemHelper.getIsLotPoolManaged(csi.data.item_id, that.lotPoolManagedItems));
    const notLotPoolManagedCountSheetItems = currentCountSheetItems.filter(csi => !that.countSheetItemHelper.getIsLotPoolManaged(csi.data.item_id, that.lotPoolManagedItems));

    const lotPoolManagedCountSheetItemGroup = [];
    lotPoolManagedCountSheetItems.filter(csi => !csi.data.hide).forEach(csi => {
      let match: ILotPoolManagedCountsheetItemGroup = lotPoolManagedCountSheetItemGroup.find(csig => {
        return csig.data.item_id === csi.data.item_id;
      });
      if (!match) {
        const matchingRedspotInventory = that.redspotQuantities.filter(rq => rq.item_id === csi.data.item_id);
        let lpmi = that.countSheetItemHelper.getLotPoolManagedItem(csi.data.item_id, that.lotPoolManagedItems);
        let redspotId = this.countSheet.data.audit_kit_instance_id.toString() + "-lpm-" + lpmi.id.toString()
        this.redspotInventoryIds.push(redspotId);
        match = {
          count_sheet_items: [],
          available_redspot_inventory: matchingRedspotInventory,
          data: {
            item_id: csi.data.item_id,
            reference: csi.data.reference,
            description: csi.data.description,
            is_consigned: csi.data.is_consigned,
            expected_quantity: matchingRedspotInventory.reduce((a, b) => a + b.quantity, 0),
            quantity: 0, 
            redspot_inventory_id: redspotId
          }
        };
        lotPoolManagedCountSheetItemGroup.push(match);
      }
      match.count_sheet_items.push(csi);
      if (csi.data.checked) {
        match.data.quantity += csi.data.quantity;
      }
    });

    this.redspotQuantities.forEach(rq => {
      if (that.countSheetItemHelper.getIsLotPoolManaged(rq.item_id, that.lotPoolManagedItems)) {
        if (!lotPoolManagedCountSheetItemGroup.find(csig => csig.data.item_id === rq.item_id)) {
          const matchingRedspotInventory = that.redspotQuantities.filter(lrq => lrq.item_id === rq.item_id);
          let redspotId = this.countSheet.data.audit_kit_instance_id.toString() + "-rq-" + rq.id.toString()
          this.redspotInventoryIds.push(redspotId);
          lotPoolManagedCountSheetItemGroup.push({
            count_sheet_items: [],
            available_redspot_inventory: matchingRedspotInventory,
            data: {
              item_id: rq.item_id,
              reference: rq.item.reference,
              description: rq.item.description,
              is_consigned: rq.item.is_consigned,
              expected_quantity: matchingRedspotInventory.reduce((a, b) => a + b.quantity, 0),
              quantity: 0,
              redspot_inventory_id: redspotId
            }
          });
        }

      } else if (!that.countSheetItemHelper.getIsLotPoolManaged(rq.item_id, that.lotPoolManagedItems)) {
        const matches = notLotPoolManagedCountSheetItems.filter(function (l) {
          const itemIdMatch = l.data.item_id === rq.item_id;
          const lotNumberMatch = (l.data.lot_number || '') === (rq.lot_number.value || '');
          return itemIdMatch && lotNumberMatch;
        });

        const withSerial = matches.filter(i => {
          return i.data.serial;
        });

        const withoutSerial = matches.filter(i => {
          return !i.data.serial;
        });


        if (withSerial.length > 0) {
          let totalQty = rq.quantity;
          withSerial.forEach(function (csi) {
            if (totalQty > 0) {
              const lowerQty = Math.min(totalQty, csi.data.quantity);
              csi.data.redspot_expiration_date = rq.expiry_date;
              csi.data.expected_quantity = lowerQty;
              totalQty -= lowerQty;
            } else {
              csi.data.expected_quantity = 0;
            }
          });

          if (withoutSerial.length > 0) {
            withoutSerial[0].data.expected_quantity = totalQty;
          } else if (totalQty > 0) {
            let redspotId = this.countSheet.data.audit_kit_instance_id.toString() + "-rq-" + rq.id.toString();
            this.redspotInventoryIds.push(redspotId);
            const match = {
              data: {
                item_id: rq.item.id,
                reference: rq.item.reference,
                description: rq.item.description,
                lot_number: rq.lot_number.value,
                serial: '',
                expected_quantity: totalQty,
                quantity: 0,
                expiration_date: null,
                redspot_expiration_date: rq.expiry_date,
                count_sheet_id: 0,
                is_consigned: rq.item.is_consigned,
                redspot_inventory_id: redspotId

              },
              id: '',
              dbId: 0,
              isSynchronized: 1
            };
            notLotPoolManagedCountSheetItems.push(match);
          }

        } else if (matches.length > 0) {
          matches[0].data.expected_quantity = rq.quantity;
          matches[0].data.redspot_expiration_date = rq.expiry_date;

        } else {
          let redspotId = this.countSheet.data.audit_kit_instance_id.toString() + "-rq-" + rq.id.toString()
          this.redspotInventoryIds.push(redspotId);
          const match: IcountsheetItemData = {
            data: {
              item_id: rq.item.id,
              reference: rq.item.reference,
              description: rq.item.description,
              lot_number: rq.lot_number.value,
              serial: '',
              expected_quantity: rq.quantity,
              quantity: 0,
              expiration_date: null,
              redspot_expiration_date: rq.expiry_date,
              count_sheet_id: 0,
              is_consigned: rq.item.is_consigned,
              manually_entered: rq.item.manually_entered,
              redspot_inventory_id: redspotId

            },
            id: '',
            dbId: 0,
            isSynchronized: 1
          };
          notLotPoolManagedCountSheetItems.push(match);
        }
      }
    });

    this.rootKitDefinitionItems.forEach(rkdi => {
      if (that.countSheetItemHelper.getIsLotPoolManaged(rkdi.item.id, that.lotPoolManagedItems)) {
        if (!lotPoolManagedCountSheetItemGroup.find(d => d.data.item_id === rkdi.item_id)) {
          const matchingRedspotInventory = that.redspotQuantities.filter(rq => rq.item_id === rkdi.item_id);
          let redspotId = this.countSheet.data.audit_kit_instance_id.toString() + "-rkdi-" + rkdi.id.toString()
          this.redspotInventoryIds.push(redspotId);
          const row: ILotPoolManagedCountsheetItemGroup = {
            count_sheet_items: [],
            available_redspot_inventory: matchingRedspotInventory,
            data: {
              item_id: rkdi.item.id,
              reference: rkdi.item.reference,
              description: rkdi.item.description,
              is_consigned: rkdi.item.is_consigned,
              expected_quantity: matchingRedspotInventory.reduce((a, b) => a + b.quantity, 0),
              quantity: 0,
              redspot_inventory_id: redspotId

            }
          };
          lotPoolManagedCountSheetItemGroup.push(row);
        }
      } else if (!that.countSheetItemHelper.getIsLotPoolManaged(rkdi.item.id, that.lotPoolManagedItems)) {
        if (!notLotPoolManagedCountSheetItems.find(d => d.data.item_id === rkdi.item_id)) {
          let redspotId = this.countSheet.data.audit_kit_instance_id.toString() + "-rkdi-" + rkdi.id.toString()
          this.redspotInventoryIds.push(redspotId);
          const row: IcountsheetItemData = {
            data: {
              item_id: rkdi.item.id,
              reference: rkdi.item.reference,
              description: rkdi.item.description,
              lot_number: '',
              serial: '',
              expected_quantity: 0,
              quantity: 0,
              expiration_date: null,
              redspot_expiration_date: null,
              count_sheet_id: 0,
              is_consigned: rkdi.item.is_consigned,
              manually_entered: rkdi.item.manually_entered,
              redspot_inventory_id: redspotId

            },
            id: '',
            dbId: 0,
            isSynchronized: 1
          };
          notLotPoolManagedCountSheetItems.push(row);
        }
      }
    });

    let countSheetItems = notLotPoolManagedCountSheetItems.concat(lotPoolManagedCountSheetItemGroup);
    this.assignRank(countSheetItems);
    return countSheetItems.filter(filterFunction);

  }

  assignRank(countSheetItems: IcountsheetItemData[]) {
    let rank = 0;

    countSheetItems.forEach((csi: IcountsheetItemData) => {
      if (csi.data.redspot_inventory_id && !csi.data.rank && csi.data.in_definition){
        let existingRank = JSON.parse(localStorage.getItem(csi.data.redspot_inventory_id))
        rank += 1
        csi.data.rank = existingRank || rank;
        localStorage.setItem(csi.data.redspot_inventory_id, JSON.stringify(csi.data.rank))
      }
    })
    countSheetItems.forEach((csi: IcountsheetItemData) => {
      if (csi.data.redspot_inventory_id && !csi.data.rank) {
        let existingRank = JSON.parse(localStorage.getItem(csi.data.redspot_inventory_id))
        rank += 1
        csi.data.rank = existingRank || rank;
        localStorage.setItem(csi.data.redspot_inventory_id, JSON.stringify(csi.data.rank))
      }
    })
  }
}
