<div class="col-md-12">
  <h1 mat-dialog-title class="full-width header-title">{{title}}</h1>
  
  <div mat-dialog-content>
    <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">Status</th>
            <th scope="col">Kits</th>
            <th scope="col">Non-Kits</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="kittedNeedsVerificationCount > 0 || nonKittedNeedsVerificationCount > 0">
            <td>
              <span class="column">Needs Verification</span>
            </td>
            <td [ngClass]="{'qty': kittedNeedsVerificationCount > 0, 'disabled': kittedNeedsVerificationCount === 0 }" (click)="goToCountSheetSummaryPage('Needs Verification', false)">{{ kittedNeedsVerificationCount > 0 ? kittedNeedsVerificationCount : "&mdash;" }}</td>
            <td [ngClass]="{'qty': nonKittedNeedsVerificationCount > 0 , 'disabled': nonKittedNeedsVerificationCount === 0}" (click)="goToCountSheetSummaryPage('Needs Verification', true)">{{ nonKittedNeedsVerificationCount > 0 ? nonKittedNeedsVerificationCount : "&mdash;" }}</td>
          </tr> 
          <tr *ngIf="kittedInProgressCount > 0 || nonKittedInProgressCount > 0">
              <td>
                <span class="column">In-Progress</span>
              </td>
              <td [ngClass]="{'qty': kittedInProgressCount > 0, 'disabled': kittedInProgressCount === 0}" (click)="goToCountSheetSummaryPage('In-Progress', false)">{{ kittedInProgressCount > 0 ? kittedInProgressCount : "&mdash;" }}</td>
              <td [ngClass]="{'qty': nonKittedInProgressCount > 0, 'disabled': nonKittedInProgressCount === 0}" (click)="goToCountSheetSummaryPage('In-Progress', true)">{{ nonKittedInProgressCount > 0 ? nonKittedInProgressCount : "&mdash;" }}</td>
            </tr> 
        </tbody>
    </table>
    <span class="warning-message">{{message}}</span>
  </div>
  <div mat-dialog-actions class="buttons">
    <button *ngIf="!siteHasUnFinishedCountSheets" class="no" mat-button (click)="onNoClick()">No</button>
    <button *ngIf="!siteHasUnFinishedCountSheets" class="yes" mat-button [mat-dialog-close]="'yes'">Yes</button>
    <button *ngIf="siteHasUnFinishedCountSheets" class="ok" mat-button [mat-dialog-close]="">Ok</button>
  </div>
</div>
