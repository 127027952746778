import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { HomeComponent } from './components/home/home.component';
import { ConsignmentInventoryImportComponent } from './components/consignment-inventory-import/consignment-inventory-import.component';
import { RedspotInventoryImportComponent } from './components/redspot-inventory-import/redspot-inventory-import.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExclusionComponent } from './components/exclusion/exclusion.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CreateNewAuditComponent } from './components/create-new-audit/create-new-audit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuditLoginComponent } from './components/audit-login/audit-login.component';
import { AuthInterceptor } from './helpers/auth-interceptor';
import { DefaultHeaderInterceptor } from './helpers/default-header-interceptor';
import { AuditKitInstancesListComponent } from './components/audit-kit-instances-list/audit-kit-instances-list.component';
import { AuditMaterialNavComponent } from './components/audit-material-nav/audit-material-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MaterialModule } from './modules/material/material.module';
import { AuditsDataTableComponent } from './components/audits-data-table/audits-data-table.component';
import { AuditDashboardComponent } from './components/audit-dashboard/audit-dashboard.component';
import { AuditDetailsComponent } from './components/audit-details/audit-details.component';
import { DashboardImportComponent } from './components/dashboard-import/dashboard-import.component';
import { AuditLocationsComponent } from './components/audit-locations/audit-locations.component';
import { EditLocationComponent } from './components/audit-locations/edit-location/edit-location.component';
import { ExclusionSummaryComponent } from './components/exclusion-summary/exclusion-summary.component';
import { CountsheetSummaryTabsComponent } from './components/countsheet-summary-tabs/countsheet-summary-tabs.component';
import { KitInstanceCountSheetComponent } from './components/kit-instance-count-sheet/kit-instance-count-sheet.component';
import { RoutingModule } from './modules/routing/routing.module';
import { UnkittedCountsheetComponent } from './components/unkitted-countsheet/unkitted-countsheet.component';
import { CreateKitCountsheetComponent } from './components/audit-kit-instances-list/create-kit-countsheet/create-kit-countsheet.component';
import { CreateUnkittedCountSheetComponent } from './components/create-unkitted-count-sheet/create-unkitted-count-sheet.component';
import { AddLocationComponent } from './components/audit-locations/add-location/add-location.component';
import { AddCountSheetItemComponent } from './components/add-count-sheet-item/add-count-sheet-item.component';
import { UnkittedItemsCountsheetComponent } from './components/count/unkitted-items-countsheet/unkitted-items-countsheet.component';
import { ReferenceFieldComponent } from './components/reference-field/reference-field.component';
import { AuditWarehousesComponent } from './components/audit-warehouses/audit-warehouses.component';
import { MatTableModule, MatPaginatorModule, MatSortModule, MatGridListModule, MatCardModule, MatMenuModule, MatIconModule, MatButtonModule } from '@angular/material';
import { WarehouseDashboardComponent } from './components/warehouse-dashboard/warehouse-dashboard.component';
import { WarehouseCountedComponent } from './components/warehouse-dashboard/warehouse-counted/warehouse-counted.component';
import { CopyCountSheetItemComponent } from './components/copy-count-sheet-item/copy-count-sheet-item.component';
import { AgencyDashboardComponent } from './components/agency-dashboard/agency-dashboard.component';
import { SapItemTableComponent } from './components/sap-item-table/sap-item-table.component';
import { WarehouseOveragesComponent } from './components/warehouse-dashboard/warehouse-overages/warehouse-overages.component';
import { WarehouseShortagesComponent } from './components/warehouse-dashboard/warehouse-shortages/warehouse-shortages.component';
import { SalesOrderInventoryImportListComponent } from './components/sales-order-inventory-import-list/sales-order-inventory-import-list.component';
import { PrintPageComponent } from './components/print-page/print-page.component';

import { ResultFilesComponent } from './components/result-files/result-files.component';
import { RouteReuseStrategy } from '@angular/router';
import { AuditRouteStrategy } from './helpers/audit-route-strategy';
import { BarcodeListenerComponent } from './components/barcodes/barcode-listener/barcode-listener.component';
import { BarcodeParserComponent } from './components/barcodes/barcode-parser/barcode-parser.component';
import { BarcodeHibcFormatComponent } from './components/barcodes/barcode-hibc-format/barcode-hibc-format.component';
import { BarcodeGs1FormatComponent } from './components/barcodes/barcode-gs1-format/barcode-gs1-format.component';
import { BarcodeKitUuidFormatComponent } from './components/barcodes/barcode-kit-uuid-format/barcode-kit-uuid-format.component';
import { BarcodeArthrexNewFormatComponent } from './components/barcodes/barcode-arthrex-new-format/barcode-arthrex-new-format.component';
import { SapConsignmentInventoryImportListComponent } from './components/sap-consignment-inventory-import-list/sap-consignment-inventory-import-list.component';
import { FocusDirective } from './directives/focus.directive';
import { AuditDashboardStatsComponent } from './components/audit-dashboard-stats/audit-dashboard-stats.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ExclusionListImportComponent } from './components/exclusion-list-import/exclusion-list-import.component';
import { WarningDialogComponent } from './components/warning-dialog/warning-dialog.component';
import { MaterialSnackbarComponent } from './components/material-snackbar/material-snackbar.component';

import { NgxPermissionsModule } from 'ngx-permissions';
import { BatchValidationImportComponent } from './components/batch-validation-import/batch-validation-import.component';
import { WebWorkerService } from 'src/web-worker';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { ConsignmentAccountDataImportComponent } from './components/consignment-account-data-import/consignment-account-data-import.component';
import { ConsignmentAccountImportListComponent } from './components/consignment-account-import-list/consignment-account-import-list.component';
import { ImportCountSheetItemComponent } from './components/import-count-sheet-item/import-count-sheet-item.component';
import { OfflineDataSynchronizerComponent } from './components/offline-data-synchronizer/offline-data-synchronizer.component';
import { RetryFetchingAuditCountSheetDataComponent } from './components/retry-fetching-audit-count-sheet-data/retry-fetching-audit-count-sheet-data.component';
import { JsonDataViewerComponent } from './components/json-data-viewer/json-data-viewer.component';
import { LoadingFeedbackComponent } from './components/loading-feedback/loading-feedback.component';
import { ResultFileDialogComponent } from './components/result-file-dialog/result-file-dialog.component';
import { LotPoolManagedItemsListComponent } from './components/lot-pool-managed-items-list/lot-pool-managed-items-list.component';
import { LotPoolManagedItemsImportComponent } from './components/lot-pool-managed-items-import/lot-pool-managed-items-import.component';
import { ConfirmSiteCompleteDialogComponent } from './components/confirm-site-complete-dialog/confirm-site-complete-dialog.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { AuditUserActionsLogDialogComponent } from './components/audit-user-actions-log-dialog/audit-user-actions-log-dialog.component';
import { AttachmentDialogComponent } from './components/attachment-dialog/attachment-dialog.component';
import { AttachmentFileListComponent } from './components/attachment-file-list/attachment-file-list.component';
import { APP_BASE_HREF } from '@angular/common';
import { OktaLoginResponseComponent } from './components/okta-login-response/okta-login-response.component';
import { OktaLogoutComponent } from './components/okta-logout/okta-logout.component';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    HomeComponent,
    ConsignmentInventoryImportComponent,
    SnackbarComponent,
    AuditLoginComponent,
    RedspotInventoryImportComponent,
    ExclusionComponent,
    CreateNewAuditComponent,
    AuditMaterialNavComponent,
    AuditsDataTableComponent,
    AuditDashboardComponent,
    AuditKitInstancesListComponent,
    AuditDetailsComponent,
    DashboardImportComponent,
    AuditLocationsComponent,
    EditLocationComponent,
    ExclusionSummaryComponent,
    CountsheetSummaryTabsComponent,
    CreateKitCountsheetComponent,
    KitInstanceCountSheetComponent,
    UnkittedCountsheetComponent,
    CreateKitCountsheetComponent,
    CreateUnkittedCountSheetComponent,
    CreateKitCountsheetComponent,
    AddLocationComponent,
    AddCountSheetItemComponent,
    UnkittedItemsCountsheetComponent,
    ReferenceFieldComponent,
    AuditWarehousesComponent,
    WarehouseDashboardComponent,
    WarehouseCountedComponent,
    CopyCountSheetItemComponent,
    AgencyDashboardComponent,
    SapItemTableComponent,
    WarehouseOveragesComponent,
    WarehouseShortagesComponent,
    SalesOrderInventoryImportListComponent,
    PrintPageComponent,
    ResultFilesComponent,
    BarcodeListenerComponent,
    BarcodeParserComponent,
    BarcodeHibcFormatComponent,
    BarcodeGs1FormatComponent,
    SapConsignmentInventoryImportListComponent,
    FocusDirective,
    AuditDashboardStatsComponent,
    ConfirmationDialogComponent,
    ExclusionListImportComponent,
    WarningDialogComponent,
    MaterialSnackbarComponent,
    BatchValidationImportComponent,
    ConsignmentAccountDataImportComponent,
    ConsignmentAccountImportListComponent,
    ImportCountSheetItemComponent,
    OfflineDataSynchronizerComponent,
    RetryFetchingAuditCountSheetDataComponent,
    JsonDataViewerComponent,
    LoadingFeedbackComponent,
    ResultFileDialogComponent,
    LotPoolManagedItemsListComponent,
    LotPoolManagedItemsImportComponent,
    ConfirmSiteCompleteDialogComponent,
    AuditUserActionsLogDialogComponent,
    AttachmentDialogComponent,
    AttachmentFileListComponent,
    OktaLoginResponseComponent,
    OktaLogoutComponent,
    BarcodeKitUuidFormatComponent,
    BarcodeArthrexNewFormatComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    NgbModule.forRoot(),
    RoutingModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    LayoutModule,
    MaterialModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    RoundProgressModule,
    NgxPermissionsModule.forRoot(),
    VirtualScrollerModule,
  ],
  entryComponents: [
    AuditLocationsComponent,
    EditLocationComponent,
    CreateNewAuditComponent,
    CreateKitCountsheetComponent,
    CreateUnkittedCountSheetComponent,
    ConfirmationDialogComponent,
    ExclusionListImportComponent,
    WarningDialogComponent,
    AddLocationComponent,
    MaterialSnackbarComponent,
    BatchValidationImportComponent,
    ConsignmentAccountDataImportComponent,
    ImportCountSheetItemComponent,
    RetryFetchingAuditCountSheetDataComponent,
    JsonDataViewerComponent,
    ResultFileDialogComponent,
    LotPoolManagedItemsImportComponent,
    ConfirmSiteCompleteDialogComponent,
    AuditUserActionsLogDialogComponent,
    AttachmentDialogComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultHeaderInterceptor,
      multi: true
    },
    {
      provide: RouteReuseStrategy,
      useClass: AuditRouteStrategy
    },
    WebWorkerService,
    {
      provide:APP_BASE_HREF,
      useValue: '/'
    }
  ],
  schemas: [NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
