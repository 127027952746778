import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { IAttachment } from 'src/app/interfaces/i-attachment';
import { AttachmentFileListDataSource } from './attachment-file-list-datasource';

@Component({
  selector: 'app-attachment-file-list',
  templateUrl: './attachment-file-list.component.html',
  styleUrls: ['./attachment-file-list.component.css']
})
export class AttachmentFileListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: AttachmentFileListDataSource;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['icons', 'filename', 'actions'];
  @Output() fileSelected = new EventEmitter<any>();
  @Output() fileDeleted = new EventEmitter<any>();
  @Input() attachments: IAttachment[];
  ngOnInit() {
    this.dataSource = new AttachmentFileListDataSource(this.attachments, this.paginator, this.sort);
  }

  /**
   *
   */
  constructor(private _changeDetector: ChangeDetectorRef) {
  }

  pickFile(row: IAttachment) {
    this.resetFileAttachmentSelection(row);
    this._changeDetector.detectChanges()
    this.fileSelected.emit(row);
  }

  deleteAttachment(row: IAttachment) {
    this.fileDeleted.emit(row);
  }

  resetFileAttachmentSelection(row:IAttachment) {
    this.dataSource.data.forEach((f: IAttachment) => {
      if(f.id === row.id || f.local_file_id === row.local_file_id){
        f.selected = true

      }else{
        f.selected = false;
      }
    });
  }
}
