import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { urls } from "../helpers/urls";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SalesOrderInventoryImportService = /** @class */ (function () {
    function SalesOrderInventoryImportService(http) {
        this.http = http;
    }
    SalesOrderInventoryImportService.prototype.upload = function (formData) {
        return this.http.post("" + environment.serverPath + urls.unconfirmedSalesOrderInventory + "/import", formData);
    };
    SalesOrderInventoryImportService.prototype.getInventory = function (auditId) {
        return this.http.get("" + environment.serverPath + urls.unconfirmedSalesOrderInventory + "?audit_id=" + auditId);
    };
    SalesOrderInventoryImportService.ngInjectableDef = i0.defineInjectable({ factory: function SalesOrderInventoryImportService_Factory() { return new SalesOrderInventoryImportService(i0.inject(i1.HttpClient)); }, token: SalesOrderInventoryImportService, providedIn: "root" });
    return SalesOrderInventoryImportService;
}());
export { SalesOrderInventoryImportService };
