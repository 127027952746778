import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { urls } from '../helpers/urls';
import { EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var LotPoolManagedItemService = /** @class */ (function () {
    function LotPoolManagedItemService(http) {
        this.http = http;
        this.updatedEmitter = new EventEmitter();
    }
    LotPoolManagedItemService.prototype.getLotPoolManagedItems = function () {
        return this.http.get("" + environment.serverPath + urls.lotPoolManagedItems);
    };
    LotPoolManagedItemService.prototype.getLotPoolManagedItemCount = function () {
        return this.http.get("" + environment.serverPath + urls.lotPoolManagedItems + "/count");
    };
    LotPoolManagedItemService.prototype.import = function (formData) {
        var _this = this;
        return this.http.post("" + environment.serverPath + urls.lotPoolManagedItems + "/import", formData).pipe(map(function (res) {
            window.caches.keys().then(function (cacheNames) {
                var match = cacheNames.find(function (cn) { return cn.includes('LongTermCountSheets'); });
                if (match) {
                    caches.open(match).then(function (cache) {
                        cache.delete("" + environment.serverPath + urls.lotPoolManagedItems, { ignoreSearch: true });
                    });
                }
            });
            _this.updatedEmitter.emit();
            return res;
        }));
    };
    LotPoolManagedItemService.prototype.delete = function (id) {
        return this.http.delete("" + environment.serverPath + urls.lotPoolManagedItems + "/" + id).pipe(map(function (res) {
            window.caches.keys().then(function (cacheNames) {
                var match = cacheNames.find(function (cn) { return cn.includes('LongTermCountSheets'); });
                if (match) {
                    caches.open(match).then(function (cache) {
                        cache.delete("" + environment.serverPath + urls.lotPoolManagedItems, { ignoreSearch: true });
                    });
                }
            });
            return res;
        }));
    };
    LotPoolManagedItemService.ngInjectableDef = i0.defineInjectable({ factory: function LotPoolManagedItemService_Factory() { return new LotPoolManagedItemService(i0.inject(i1.HttpClient)); }, token: LotPoolManagedItemService, providedIn: "root" });
    return LotPoolManagedItemService;
}());
export { LotPoolManagedItemService };
