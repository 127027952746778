import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material'
import { IDialogData } from '../../../interfaces/idialog-data';
import { MaterialSnackbarComponent } from '../../material-snackbar/material-snackbar.component';
import { ISnackBarConfig } from 'src/app/interfaces/isnack-bar-config';

@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.css']
})


export class AddLocationComponent implements OnInit {

  snackBarConfig: ISnackBarConfig = {
    message: "",
    duration: 3000,
    success: false,
    snackBarClass: ""
  }

  constructor(
    public dialogRef: MatDialogRef<AddLocationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData,
    public snackBar: MatSnackBar) {}

  ngOnInit() {
  }
  
  validateName(data) {
    if (data.site.length > 50) {
      this.snackBarConfig.message = 'Location Name Exceeds Max Length of 50 Characters';
      this.snackBarConfig.success = false;
      this.openSnackBar();
    } else {
      this.dialogRef.close(data);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openSnackBar() {
    this.snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: this.snackBarConfig,
      duration: this.snackBarConfig.duration
    })
  }
}
