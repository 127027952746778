import { Injectable } from '@angular/core';
import { DatabaseService } from './database.service'
import { zip } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuditDatabaseService {

  storeName = "audits";

  constructor(private _databaseService: DatabaseService) { }

  clear() {
    return this._databaseService.clear(this.storeName);
  }

  get(id) {
    return this._databaseService.get(this.storeName, parseInt(id, 10));
  }

  bulkAdd(datas) {
    return this._databaseService.bulkAdd(this.storeName, datas);
  }
}
