<div class="grid-container">
  <div class="row dashboard-title">
    <h1 class=" centered">Audit Dashboard</h1>
  </div>

  <mat-grid-list gutterSize="10px" cols="4" [rowHeight]="rowSize + 'px'" *ngIf="auditData">
    <!--Audit Details-->
    <mat-grid-tile class="mat-elevation-z4" [colspan]="gridTileSizes.auditDetails.col" [rowspan]="gridTileSizes.auditDetails.row">
      <app-audit-details [auditData]="auditData" [isOnline]="isOnline" (auditWasEdited)="refreshAuditInput($event)"></app-audit-details>
    </mat-grid-tile>
    <!--Audit Inputs-->
    <mat-grid-tile class="mat-elevation-z4" [colspan]="gridTileSizes.auditInput.col" [rowspan]="gridTileSizes.auditInput.row" *ngIf="auditData">
      <app-dashboard-import [audit]="auditData" [isOnline]="isOnline" (onImportComplete)="updateLocationsTable($event)" #auditInputs></app-dashboard-import>
    </mat-grid-tile>
    <!--Audit Outputs-->
    <mat-grid-tile class="mat-elevation-z4" [colspan]="gridTileSizes.auditOutput.col" [rowspan]="gridTileSizes.auditOutput.row">
      <app-result-files [isOnline]="isOnline"></app-result-files>
    </mat-grid-tile>

    <!--Dashboard Stats-->
    <mat-grid-tile [colspan]="gridTileSizes.auditProgress.col" [rowspan]="gridTileSizes.auditProgress.row">
      <app-audit-dashboard-stats #auditStats [auditId]="ID"></app-audit-dashboard-stats>
    </mat-grid-tile>
    <!--Audit Sites-->
    <mat-grid-tile class="mat-elevation-z4" [colspan]="gridTileSizes.auditLocations.col" [rowspan]="gridTileSizes.auditLocations.row">
      <app-audit-locations [isOnline]="isOnline" [warehouses]="warehouses" [auditID]="ID" [audit]="auditData" (onSiteCreatedOrEdited)="refreshWarehouseTable()" #locationsTable></app-audit-locations>
    </mat-grid-tile>
    <!--Audit Warehouses-->
    <mat-grid-tile class="mat-elevation-z4" [colspan]="gridTileSizes.auditWarehouses.col" [rowspan]="gridTileSizes.auditWarehouses.row">
      <app-audit-warehouses [isOnline]="isOnline" [auditID]="ID" #warehouseTable></app-audit-warehouses>
    </mat-grid-tile>
  </mat-grid-list>
</div>
