import { IsapConsignmentInventoryItem } from "../interfaces/isap-consignment-inventory-item";

export class SapConsignmentInventoryHelper {
    
    populateSingleItem(data: any): IsapConsignmentInventoryItem {
        let item: IsapConsignmentInventoryItem = {
            part_number: data.part_number,
            description: data.description,
            lot_number: data.lot_number,
            serial_number: data.serial_number,
            stock_price : data.stock_price,
            quantity: data.quantity,
            product_hierarchy: data.product_hierarchy,
            status: this.getSapItemStatus(data.success),
            unit_of_measure: data.unit_of_measure
        }
        return item;
    }

    getSapItemStatus(success: boolean): string {
        let res = ''
        if(success){
            res = "Success"
        }else{
            res = "Failure"
        }
        return res;
    }

    populateItems(data: any[]): IsapConsignmentInventoryItem[]
    {
        let items: IsapConsignmentInventoryItem[] = [] 
        data.forEach(i => {
            let item = this.populateSingleItem(i);
            items.push(item)
        })
        return items;
    }
}