/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./barcode-kit-uuid-format.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./barcode-kit-uuid-format.component";
import * as i3 from "../../../services/kit-instance.service";
import * as i4 from "@angular/material/snack-bar";
var styles_BarcodeKitUuidFormatComponent = [i0.styles];
var RenderType_BarcodeKitUuidFormatComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BarcodeKitUuidFormatComponent, data: {} });
export { RenderType_BarcodeKitUuidFormatComponent as RenderType_BarcodeKitUuidFormatComponent };
export function View_BarcodeKitUuidFormatComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_BarcodeKitUuidFormatComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-barcode-kit-uuid-format", [], null, null, null, View_BarcodeKitUuidFormatComponent_0, RenderType_BarcodeKitUuidFormatComponent)), i1.ɵdid(1, 245760, null, 0, i2.BarcodeKitUuidFormatComponent, [i3.KitInstanceService, i4.MatSnackBar], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BarcodeKitUuidFormatComponentNgFactory = i1.ɵccf("app-barcode-kit-uuid-format", i2.BarcodeKitUuidFormatComponent, View_BarcodeKitUuidFormatComponent_Host_0, {}, { itemNotFound: "itemNotFound" }, []);
export { BarcodeKitUuidFormatComponentNgFactory as BarcodeKitUuidFormatComponentNgFactory };
