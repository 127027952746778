import { merge, of, fromEvent } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
var OnlineService = /** @class */ (function () {
    function OnlineService() {
        this.testingOfflineKey = "TESTING_OFFLINE";
        this.testingOffline = false;
        this.goOfflineEvent = new EventEmitter();
        this.testingOffline = JSON.parse(localStorage.getItem(this.testingOfflineKey));
        if (!this.testingOffline) {
            this.testingOffline = false;
            localStorage.setItem(this.testingOfflineKey, JSON.stringify(this.testingOffline));
        }
    }
    OnlineService.prototype.isOnline = function () {
        return navigator.onLine;
    };
    OnlineService.prototype.isOnlineSubscription = function () {
        return merge(of(navigator.onLine), fromEvent(window, 'online').pipe(mapTo(true)), fromEvent(window, 'offline').pipe(mapTo(false)));
    };
    OnlineService.prototype.getTestOfflineBool = function () {
        var testingOffline = JSON.parse(localStorage.getItem(this.testingOfflineKey));
        return testingOffline;
    };
    OnlineService.prototype.setTestOffline = function () {
        if (this.isOnline()) {
            this.testingOffline = !this.getTestOfflineBool();
            localStorage.setItem(this.testingOfflineKey, JSON.stringify(this.testingOffline));
            if (this.testingOffline) {
                this.goOfflineEvent.emit(true);
            }
            else {
                this.goOfflineEvent.emit(false);
            }
        }
    };
    OnlineService.ngInjectableDef = i0.defineInjectable({ factory: function OnlineService_Factory() { return new OnlineService(); }, token: OnlineService, providedIn: "root" });
    return OnlineService;
}());
export { OnlineService };
