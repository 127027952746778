/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./barcode-listener.component";
var styles_BarcodeListenerComponent = [];
var RenderType_BarcodeListenerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BarcodeListenerComponent, data: {} });
export { RenderType_BarcodeListenerComponent as RenderType_BarcodeListenerComponent };
export function View_BarcodeListenerComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_BarcodeListenerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-barcode-listener", [], null, [["document", "keypress"]], function (_v, en, $event) { var ad = true; if (("document:keypress" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).handleKeyboardEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_BarcodeListenerComponent_0, RenderType_BarcodeListenerComponent)), i0.ɵdid(1, 114688, null, 0, i1.BarcodeListenerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BarcodeListenerComponentNgFactory = i0.ɵccf("app-barcode-listener", i1.BarcodeListenerComponent, View_BarcodeListenerComponent_Host_0, {}, { barcodeScanned: "barcodeScanned" }, []);
export { BarcodeListenerComponentNgFactory as BarcodeListenerComponentNgFactory };
