import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { urls } from "../helpers/urls";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ItemService = /** @class */ (function () {
    function ItemService(http) {
        this.http = http;
    }
    ItemService.prototype.getItems = function () {
        return this.http.get("" + environment.serverPath + urls.items);
    };
    ItemService.ngInjectableDef = i0.defineInjectable({ factory: function ItemService_Factory() { return new ItemService(i0.inject(i1.HttpClient)); }, token: ItemService, providedIn: "root" });
    return ItemService;
}());
export { ItemService };
