import { Injectable } from '@angular/core';
import { IFileOptions } from '../interfaces/i-file-options';
import { IFileType } from '../interfaces/i-file-type';

@Injectable({
  providedIn: 'root'
})
export class FileOptionUtils {

  FILE_ICONS = {
    image: "insert_photo",
    any: "insert_drive_file",
    pdf: "picture_as_pdf",
    text: "description"
  }
  START_DIRECTORIES = {
    desktop: 'desktop',
    documents: 'documents',
    downloads: 'downloads',
    music: 'music',
    pictures: 'pictures',
    videos: 'videos'
  };
  IMAGE_EXTENSIONS: string[] = ['.png', '.gif', '.jpeg', '.jpg', '.tif', '.tiff'];
  APPLICATION_EXTENSIONS: string[] = ['.pdf', '.doc', '.xls', '.xlsx', '.xml'];
  PDF_EXTENSIONS: string[] = ['.pdf'];
  TEXT_EXTENSIONS: string[] = ['.txt'];

  // types
  IMAGE_TYPE: IFileType = {
    description: 'Images',
    accept: {
      'image/*': this.IMAGE_EXTENSIONS
    }
  };

  PDF_TYPE: IFileType = {
    description: 'PDF',
    accept: {
      'application/pdf': this.APPLICATION_EXTENSIONS
    }
  };

  TEXT_TYPE: IFileType = {
    description: 'Text Files',
    accept: {
      'text/*': this.TEXT_EXTENSIONS
    }
  };

  IMAGE_FILE: IFileOptions = {
    types: [ this.IMAGE_TYPE]
  };

  FILE_SYSTEM_HANDLE_KINDS = {
    file: 'file',
    directory: 'directory'
  };

  TEXT_FILE: IFileOptions = {
    types: [
      {
        description: 'Text Files',
        accept: {
          'text/plain': this.TEXT_EXTENSIONS
        }
      }
    ]
  };

  PDF_FILE: IFileOptions = {
    types: [
      {
        description: 'PDF Files',
        accept: {
          'image/*': this.APPLICATION_EXTENSIONS
        }
      }
    ]
  };


  textFileOption(multiple: boolean = false): IFileOptions {
    const opt = this.TEXT_FILE;
    opt.multiple = multiple;
    return opt;
  }

  imageFileOption(multiple: boolean = false): IFileOptions {
    const opt = this.IMAGE_FILE;
    opt.multiple = multiple;
    return opt;
  }

  pdfFileOption(multiple: boolean = false): IFileOptions {
    const opt = this.PDF_FILE;
    opt.multiple = multiple;
    return opt;
  }

  getExtension(handle: any): string {
    const name = handle.name;
    const pos = name.lastIndexOf('.');
    const ext = name.slice(pos);
    return ext;
  }

  getExtensionFromName(name: string) {
    const pos = name.lastIndexOf('.');
    const ext = name.slice(pos);
    return ext;
  }

  isImage(extension: string) {
    return this.IMAGE_EXTENSIONS.includes(extension);
  }

  isPDF(extension: string) {
    return this.PDF_EXTENSIONS.includes(extension);
  }

  isTextFile(extension: string){
    return this.TEXT_EXTENSIONS.includes(extension);
  }

  directoryOption(dirOpts: any = {}): IFileOptions {
    return {
      directoryName: dirOpts.name,
      createDirectory: dirOpts.create
    };
  }

  fileOption(fileOps: any = {}): IFileOptions {
    return {
      filename: fileOps.name,
      createFile: fileOps.create
    };
  }

  auditOptions(): IFileOptions {
    return {
      multiple: false,
      types: [
        {
          description: 'Accepted Types',
          accept: {
            'application/*': this.APPLICATION_EXTENSIONS,
            'image/*': this.IMAGE_EXTENSIONS,
            'text/*': this.TEXT_EXTENSIONS
          }
        }
      ]
    };
  }

  getIcon(filename:string): string{
    let extension = this.getExtensionFromName(filename);
    if(this.isImage(extension)){
      return this.FILE_ICONS.image;
    }else if(this.isPDF(extension)){
      return this.FILE_ICONS.pdf;
    }else if(this.isTextFile(extension)){
      return this.FILE_ICONS.text
    }else{
      return this.FILE_ICONS.any;
    }
  }

  isSupported(filename: string): boolean {
    let extension = this.getExtensionFromName(filename);
    let supportedExtensions = this.IMAGE_EXTENSIONS.concat(this.APPLICATION_EXTENSIONS).concat(this.PDF_EXTENSIONS).concat(this.TEXT_EXTENSIONS);
    return supportedExtensions.includes(extension);
  }
}
