<div class="col-md-12">
  <h1 mat-dialog-title class="full-width header-title">Edit Site</h1>
  <div mat-dialog-content>
    <mat-form-field class="full-width">
      <input matInput [(ngModel)]="data.location" placeholder="Site">
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-select [(ngModel)]="data.warehouse_ids" name="warehouse" placeholder="Warehouse" #warehouseSelector="ngModel" multiple>
          <mat-checkbox class="mat-option" [disableRipple]="true" 
          (change)="data.toggle(warehouseSelector, $event, data.listOfWarehouseIDs, data.warehousesWithSheets)">
          Select All
        </mat-checkbox>
        <mat-option *ngFor="let wh of data.warehouses" [value]="wh.account_id" [disabled]="data.warehousesWithSheets.indexOf(wh.account_id) != -1" >
          {{wh.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="validateName(data)" cdkFocusInitial>Save</button>
  </div>
</div>
