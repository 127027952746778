import { Injectable } from '@angular/core';
import { urls } from "./../helpers/urls";
import { environment } from "./../../environments/environment";
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AgencyDashboardService {

  constructor(private _http: HttpClient) { }

  getAgencyDashboardData(audit_id: number){
    let params = new HttpParams().set('audit_id', audit_id.toString())
    let path = environment.serverPath + urls.getAgencyDashboardData ;
    return this._http.get<any>(path, {params});
  }
}
