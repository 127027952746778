import { Component, OnInit, Inject } from '@angular/core';
import { RedspotInventoryImportService } from '../../services/redspot-inventory-import.service';
import { AuditService } from '../../services/audit.service';
import { ISnackBarConfig } from '../../interfaces/isnack-bar-config';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatCheckboxChange } from '@angular/material';
import { IDialogData } from '../../interfaces/idialog-data';
import { zip } from 'rxjs';
import { IAuditDetail } from '../../interfaces/iaudit-detail';
import { MaterialSnackbarComponent } from '../material-snackbar/material-snackbar.component';
import { IWarehouse } from 'src/app/interfaces/i-warehouse';
import { Messages } from 'src/app/helpers/messages';

@Component({
  selector: 'app-redspot-inventory-import',
  templateUrl: './redspot-inventory-import.component.html',
  styleUrls: ['./redspot-inventory-import.component.css']
})
export class RedspotInventoryImportComponent implements OnInit {

  loading: boolean = false;
  allSelected: boolean = false
  snackBarConfig: ISnackBarConfig = {
    message : "",
    duration: 3000,
    success: false,
    snackBarClass : ""
  }

  warehouses: IWarehouse[] = [];
  selectedWarehouseIds: number[] = []
  selectedItems: number[] = [];
  audit: IAuditDetail = {
    location_name : '',
    id: 0,
    warehouse_id: 0,
    distributorship: {},
    status:''
  };

  constructor(
    private _redspotInventoryImportService: RedspotInventoryImportService,
    private _auditService: AuditService,
    public dialogRef: MatDialogRef<RedspotInventoryImportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData,
    public snackBar: MatSnackBar
  ) { }

  createRedspotInventory() {
    this.dialogRef.disableClose = true;
    this.loading = true;
    this._redspotInventoryImportService.generate(this.data.audit_id, {audit_id: this.data.audit_id, account_id: this.selectedItems}).then(
      res => {
        this.snackBarConfig.message = Messages.inventorySnapchatSucceeded;
        this.snackBarConfig.success = true;
        this.openSnackBar();
        this.loading = false;
        setTimeout(() => {
          this.dialogRef.close(res);
        }, 2000);
      },
      () => {
        this.loading = false;
        this.snackBarConfig.message = Messages.inventorySnapchatFailed;
        this.snackBarConfig.success = false;
        this.openSnackBar();
      }
    )
  }

  ngOnInit() {
    this.loading = true;
    zip(
      this._auditService.getWarehouses(this.data.audit_id), 
      this._auditService.getAuditById(this.data.audit_id),
      this._auditService.getImportedWarehouseIds(this.data.audit_id)
      ).subscribe(data => {
        this.loading = false;
        this.warehouses = data[0];
        this.audit = data[1];
        this.selectedWarehouseIds = data[2]
        this.selectedItems = this.selectedWarehouseIds
        this.allSelected = this.warehouses.length === this.selectedItems.length
    });
  }

  toggleSelection(event: MatCheckboxChange) {
    
    if(event.checked){
      this.selectedItems = this.warehouses.map(w => w.id);
      this.allSelected = true
    }
    else {
      this.selectedItems = []
      this.allSelected = false
    }
  }

  toggleSingleSelection(event: MatCheckboxChange){
      this.selectedItems = this.selectedItems.filter((i:number)=>{
        return i.toString() !== event.source.value
      })
      this.allSelected = this.warehouses.length === this.selectedItems.length
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openSnackBar() {
    this.snackBar.openFromComponent(MaterialSnackbarComponent, {
      data: this.snackBarConfig,
      duration: this.snackBarConfig.duration
    })
  }
}
