<div class="col-md-12">
    <h1 mat-dialog-title class="full-width header-title">{{ data.title }}</h1>
    <div mat-dialog-content>
      <p>The file will be created and then sent to the address provided.</p>
      <input matInput placeholder="email address" #result_file_email_address>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="email()">Email</button>
    </div>
  </div>
