import { IuserData } from "./../interfaces/iuser-data";

export class UserDataHelper {
    localStorageKey: string = "USER_DATA";

    /**
     * get user data from local storage
     */
    getUserData(): IuserData{
        let userData: IuserData = JSON.parse(localStorage.getItem(this.localStorageKey))
        return userData;
    }

    /**
     * add user data to local storage
     * @param userData 
     */
    setUserData(userData: IuserData | string){
        localStorage.setItem(this.localStorageKey, JSON.stringify(userData));
    }
}