import { HttpClient } from "@angular/common/http";
import { urls } from "../helpers/urls";
import { environment } from "../../environments/environment";
import { Router } from '@angular/router';
import { UserDataHelper } from '../helpers/user-data-helper';
import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
var AuthService = /** @class */ (function () {
    function AuthService(_http, _router) {
        this._http = _http;
        this._router = _router;
        this._isLoggedIn = false;
        this.userDataHelper = new UserDataHelper();
        this.authenticatedEvent = new EventEmitter();
        this.isLoggedIn = !!localStorage.getItem('auth_token');
    }
    Object.defineProperty(AuthService.prototype, "isLoggedIn", {
        get: function () {
            return this._isLoggedIn;
        },
        set: function (newIsLoggedIn) {
            this._isLoggedIn = newIsLoggedIn;
            this.authenticatedEvent.emit(newIsLoggedIn);
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.userLogin = function (credentials) {
        var fd = new FormData();
        fd.append('email', credentials.email);
        fd.append('password', credentials.password);
        return this._http.post(environment.serverPath + urls.login, fd);
    };
    AuthService.prototype.userLogout = function () {
        this.isLoggedIn = false;
        localStorage.setItem('auth_token', '');
        localStorage.setItem('permissions', JSON.stringify([]));
        this.userDataHelper.setUserData('');
    };
    AuthService.prototype.register_token = function (tokenData, tenant) {
        var fd = new FormData();
        fd.append('access_token', tokenData.access_token);
        fd.append('id_token', tokenData.id_token);
        fd.append('expires_in', tokenData.expires_in.toString());
        fd.append('refresh_token', tokenData.refresh_token);
        fd.append('tenant', tenant);
        console.log("register_token: ", tokenData);
        return this._http.post(environment.serverPath + urls.registerToken, fd);
    };
    AuthService.prototype.invalidateAccessToken = function (tokenData) {
        var data = { id_token: tokenData.id_token };
        return this._http.delete(environment.serverPath + urls.invalidateToken, { params: data });
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.HttpClient), i0.inject(i2.Router)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
