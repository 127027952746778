/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./warning-dialog.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "./warning-dialog.component";
var styles_WarningDialogComponent = [i0.styles];
var RenderType_WarningDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WarningDialogComponent, data: {} });
export { RenderType_WarningDialogComponent as RenderType_WarningDialogComponent };
export function View_WarningDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "col-md-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "mat-icon", [["class", "warning-icon mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(7, 638976, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["warning"])), (_l()(), i1.ɵted(-1, null, [" Warning"])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(11, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "row message-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "p", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), (_l()(), i1.ɵeld(15, 0, null, null, 5, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(16, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(17, 0, null, null, 3, "button", [["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).dialogRef.close(i1.ɵnov(_v, 19).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(18, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(19, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["OK"]))], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 7, 0); var currVal_6 = true; _ck(_v, 19, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 7).inline; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.message; _ck(_v, 14, 0, currVal_2); var currVal_3 = (i1.ɵnov(_v, 18).disabled || null); var currVal_4 = (i1.ɵnov(_v, 18)._animationMode === "NoopAnimations"); var currVal_5 = i1.ɵnov(_v, 19).ariaLabel; _ck(_v, 17, 0, currVal_3, currVal_4, currVal_5); }); }
export function View_WarningDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-warning-dialog", [], null, null, null, View_WarningDialogComponent_0, RenderType_WarningDialogComponent)), i1.ɵdid(1, 114688, null, 0, i10.WarningDialogComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WarningDialogComponentNgFactory = i1.ɵccf("app-warning-dialog", i10.WarningDialogComponent, View_WarningDialogComponent_Host_0, {}, {}, []);
export { WarningDialogComponentNgFactory as WarningDialogComponentNgFactory };
