import { DataSource } from '@angular/cdk/collections';
import { MatPaginator, MatSort } from '@angular/material';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge } from 'rxjs';
import { ICountSheetData } from '../../interfaces/icount-sheet-data';
import { ITableFilter } from '../../interfaces/itable-filter';

/**
 * Data source for the UnkittedCountsheet view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class UnkittedCountsheetDataSource extends DataSource<ICountSheetData> {
  data: ICountSheetData[] = [];

  constructor(
    private paginator: MatPaginator,
    private sort: MatSort,
    private _data: any[],
    private filters: ITableFilter) {

    super();
    this.data = this._data.filter((csi) => csi.data.archived === false);
    this.paginator.length = this.data.length;

  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<ICountSheetData[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      observableOf(this.data),
      this.paginator.page,
      this.sort.sortChange
    ];

    // Set the paginators length
    this.paginator.length = this.data.length;

    return merge(...dataMutations)
      .pipe(map(() => {
        const filteredData = this.getPagedData(this.getSortedData(this.getFilteredData([...this.data])));
        return filteredData;
      }));
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {}

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: ICountSheetData[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: ICountSheetData[]) {

    if (!this.sort.direction) {
      this.sort.direction = 'asc';
    }

    if (!this.sort.active) {
      return data;
    }
    // "status", 'CreatedBy', 'DateCreated'
    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
      case 'area': return compare(a.data.area.toLowerCase(), b.data.area.toLowerCase(), isAsc);
      case 'count': return compare(a.data.total_items_counted, b.data.total_items_counted, isAsc);
      case 'location': return compare(a.data.location_name.toLowerCase(), b.data.location_name.toLowerCase(), isAsc);
      case 'warehouse': return compare(a.data.warehouse_name.toLowerCase(), b.data.warehouse_name.toLowerCase(), isAsc);
      case 'status': return compare(a.data.status_text.toLowerCase(), b.data.status_text.toLowerCase(), isAsc);
      case 'createdBy': return compare(`${a.data.created_by.first_name} ${a.data.created_by.last_name}`.toLowerCase() , `${b.data.created_by.first_name} ${b.data.created_by.last_name}`.toLowerCase(), isAsc);
      case 'dateCreated': return compare(a.data.created_at, b.data.created_at, isAsc);

      default: return 0;
      }
    });
  }

  public filteredData() {
    return this.getFilteredData(this.data);
  }

  private getFilteredData(data: any[]) {
    const filteredData =  data.filter(el => {
      let condition = true;

      if (this.filters.searchString) {
        condition = condition && el.data.area.toLowerCase().includes(this.filters.searchString.toLowerCase());
      }

      if (this.filters.status && this.filters.status !== 'All') {
        condition = condition && el.data.status_text.toLowerCase().startsWith(this.filters.status.toLowerCase());
      }
      if (this.filters.recall_status && this.filters.recall_status !== 'All') {
        condition = condition && (this.filters.recall_status === 'Recalled' && el.data.has_recalled_items || this.filters.recall_status === 'Not Recalled' && !el.data.has_recalled_items);
      }

      if (this.filters.validity_status && this.filters.validity_status !== 'All') {
        const allValid = !el.data.has_invalid_items;
        const SomeInvalid = el.data.has_invalid_items;
        condition = condition && (this.filters.validity_status === 'All Valid' && allValid || this.filters.validity_status === 'Some Invalid' && SomeInvalid);
      }

      if (this.filters.auditor && this.filters.auditor !== 'all') {
        condition = condition && el.data.created_by && el.data.created_by.user_id.toString() === this.filters.auditor.toString();
      }

      if (this.filters.warehouse && !['all', 'All'].includes(this.filters.warehouse)) {
        condition = condition && el.data.warehouse_id.toString() === this.filters.warehouse.toString();
      }

      return condition;
    });

    this.paginator.length = filteredData.length;
    return filteredData;
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
