import * as fileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as JSZip from 'jszip';
import * as i0 from "@angular/core";
var EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
var EXCEL_EXTENSION = '.xlsx';
var ExportToExcelService = /** @class */ (function () {
    function ExportToExcelService() {
    }
    ExportToExcelService.prototype.exportAsExcelFile = function (json, excelFileName, options) {
        if (options === void 0) { options = null; }
        var worksheet = XLSX.utils.json_to_sheet(json, options.options);
        var workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        if (options && options.zip) {
            var filename = excelFileName + new Date().getTime();
            this.saveAsZip(excelBuffer, filename, EXCEL_EXTENSION);
        }
        else {
            this.saveAsExcelFile(excelBuffer, excelFileName, options);
        }
    };
    ExportToExcelService.prototype.exportDataAsExcelFile = function (worksheets, excelFileName, options) {
        if (options === void 0) { options = null; }
        var worksheetOne = XLSX.utils.json_to_sheet(worksheets[0], options.options);
        var worksheetTwo = XLSX.utils.json_to_sheet(worksheets[1], options.options);
        var workbook = { Sheets: { 'individual_items': worksheetOne, 'grouped_items': worksheetTwo }, SheetNames: ['individual_items', 'grouped_items'] };
        var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        if (options && options.zip) {
            var filename = excelFileName + new Date().getTime();
            this.saveAsZip(excelBuffer, filename, EXCEL_EXTENSION);
        }
        else {
            this.saveAsExcelFile(excelBuffer, excelFileName, options);
        }
    };
    ExportToExcelService.prototype.saveAsZip = function (buffer, filename, extension) {
        var zip = new JSZip();
        zip.file(filename + extension, buffer);
        zip.generateAsync({ type: 'blob', compression: 'DEFLATE' }).then(function (data) {
            fileSaver.saveAs(data, "" + filename);
        });
    };
    ExportToExcelService.prototype.saveAsExcelFile = function (buffer, fileName, options) {
        if (options === void 0) { options = null; }
        var data = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        if (options && options.addExport === false) {
            fileSaver.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
        }
        else {
            fileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        }
    };
    ExportToExcelService.ngInjectableDef = i0.defineInjectable({ factory: function ExportToExcelService_Factory() { return new ExportToExcelService(); }, token: ExportToExcelService, providedIn: "root" });
    return ExportToExcelService;
}());
export { ExportToExcelService };
