import { Injectable } from '@angular/core';

import * as fileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as JSZip from 'jszip';
import { IExcelExportOptions } from '../interfaces/i-excel-export-options';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({providedIn: 'root'})
export class ExportToExcelService {

  constructor() { }

  public exportAsExcelFile(json: any[], excelFileName: string, options: IExcelExportOptions = null): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, options.options);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    if (options && options.zip) {
      const filename = excelFileName + new Date().getTime();
      this.saveAsZip(excelBuffer, filename, EXCEL_EXTENSION);
    } else {
      this.saveAsExcelFile(excelBuffer, excelFileName, options);
    }
  }

  public exportDataAsExcelFile(worksheets: any[], excelFileName: string, options: IExcelExportOptions = null): void {

    const worksheetOne: XLSX.WorkSheet = XLSX.utils.json_to_sheet(worksheets[0], options.options);
    const worksheetTwo: XLSX.WorkSheet = XLSX.utils.json_to_sheet(worksheets[1], options.options);
    const workbook: XLSX.WorkBook = { Sheets: { 'individual_items': worksheetOne, 'grouped_items': worksheetTwo}, SheetNames: ['individual_items', 'grouped_items'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    if (options && options.zip) {
      const filename = excelFileName + new Date().getTime();
      this.saveAsZip(excelBuffer, filename, EXCEL_EXTENSION);
    } else {
      this.saveAsExcelFile(excelBuffer, excelFileName, options);
    }
  }

  private saveAsZip(buffer: any, filename: string, extension: string): void {
    const zip = new JSZip();
    zip.file(filename + extension, buffer);
    zip.generateAsync({type: 'blob', compression: 'DEFLATE'}).then((data) => {
      fileSaver.saveAs(data, `${filename}`);
    });
  }

  private saveAsExcelFile(buffer: any, fileName: string, options: IExcelExportOptions = null): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });

    if (options && options.addExport === false) {
      fileSaver.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
    } else {
      fileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }
  }

}
